import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import styles from './HorizontalMenu.module.css';

const classNames = require('classnames');

/*
 * ScrollMenu Component
 */
const Menu = ({ menuItems, activeItem, setActiveItem }) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const menuContainerRef = useRef(null);

  // handle the scroll event for the menu list container, determine if scroll arrows should show
  const handleMenuScroll = useCallback(() => {
    let targetDom = menuContainerRef.current;
    const isMenuAtStart = targetDom.scrollLeft < 11;
    const isMenuAtEnd = targetDom.scrollLeft + targetDom.clientWidth >= targetDom.scrollWidth;

    if (!isMenuAtStart && !showLeftArrow) setShowLeftArrow(true);
    if (isMenuAtStart && showLeftArrow) setShowLeftArrow(false);

    if (!isMenuAtEnd && !showRightArrow) setShowRightArrow(true);
    if (isMenuAtEnd && showRightArrow) setShowRightArrow(false);

  }, [showLeftArrow, showRightArrow]);

  // setup the menu scrolling listener so arrows can be shown/hidden dynamically
  useEffect(() => {
    let targetDom = menuContainerRef.current;
    targetDom.addEventListener('scroll', handleMenuScroll);
    handleMenuScroll(); // do an init on initial arrow button states
    return () => targetDom.removeEventListener('scroll', handleMenuScroll);
  }, [handleMenuScroll]);


  // handler for horizontal menu arrow clicks. will scroll the container to the left or right
  const handleMenuArrowClick = (direction) => {
    const startingPos = menuContainerRef.current.scrollLeft;
    let x = direction === 'left' ? startingPos - 5 : startingPos + 5;
    let delay = 10;
    const limit = menuContainerRef.current.clientWidth * .92;

    if (direction === 'left') {
      const left = setInterval(function () {
        menuContainerRef.current.scroll(x, 0);
        if (menuContainerRef.current.scrollLeft < startingPos - limit || x < 0) clearInterval(left);
        x = x - 5;
      }, delay);
    } else { // right arrow clicked
      const right = setInterval(function () {
        menuContainerRef.current.scroll(x, 0);
        if ((menuContainerRef.current.scrollLeft > startingPos + limit)
          || (menuContainerRef.current.scrollLeft + menuContainerRef.current.clientWidth >= menuContainerRef.current.scrollWidth)) {
          clearInterval(right);
        }
        x = x + 5;
      }, delay);
    }
  }

  const menuList = menuItems.map(item => {
    const displayName = item.split('_').join(' ');
    return (
      <div className={item === activeItem ? styles.active : null} key={item}>
        <a href={`#${item}`}
          onClick={() => setActiveItem(item)}
          className={item === activeItem ? styles.active : null}
          aria-label={`Select ${item}`}>
          {displayName}
        </a>
      </div>
    );
  });

  return (
    <div id="menuContainer" className={classNames(styles.menuContainer, 'position-relative')}>
      {showLeftArrow ?
        <span className={classNames(styles.scrollLeft, 'position-absolute d-flex align-items-center justify-content-center')} onClick={() => handleMenuArrowClick('left')}>
          <FiChevronLeft />
        </span> : null}
      <div ref={menuContainerRef} className={classNames(styles.menuItems, 'd-flex')} >
        {menuList}
      </div>
      {showRightArrow ?
        <span className={classNames(styles.scrollRight, 'position-absolute d-flex align-items-center justify-content-center')} onClick={() => handleMenuArrowClick('right')}>
          <FiChevronRight />
        </span>
        : null}
    </div>
  )
};

export default Menu;

