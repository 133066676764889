import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import DefaultFooter from './DefaultFooter';
import styles from './AdminModal.module.css';

/** Admin Modal component using bootstrap */
const AdminModal = ({
  centered,
  children,
  customFooterStyle = "",
  customSaveLabel,
  customCloseLabel = "",
  customCancellationLabel = "",
  customSavingLabel,
  deleteButtonText,
  deleteMessage,
  deleteModeOverride,
  dialogClassName,
  footer,
  isSaving,
  noSave,
  onDelete,
  onExited,
  onHide,
  onSave = () => { },
  readOnly,
  show,
  style,
  title,
  darkMode,
  preventDismiss
}) => {
  const intl = useIntl();
  const t = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const [deleteMode, setDeleteMode] = useState(false);
  const handleDelete = () => {
    setDeleteMode(true);
  }

  // if show has changed, clear deleteMode if its active
  useEffect(() => {
    if (show) setDeleteMode(false);
  }, [show]);

  const footerCloseButtonLabel = customCloseLabel || t('ui.close') || 'Close';
  const footerDeleteButtonLabel = t('ui.delete') || 'Delete';
  const footerDeleteCancellationButtonLabel = customCancellationLabel || t('ui.cancel') || 'Cancel';
  const footerIsSaveButtonDisabled = useMemo(() => isSaving || readOnly, [isSaving, readOnly]);
  const footerHandleCancellation = onHide || (() => setDeleteMode(false));
  const footerShowDelete = onDelete && !(deleteMode || deleteModeOverride);
  const footerShowSave = !noSave;

  const footerDeleteConfirmationButtonLabel = useMemo(() => {
    if (isSaving) {
      return t('ui.deleting') ? `${t('ui.deleting')}...` : 'Deleting...';
    }

    return (
      deleteButtonText ||
      t('AdminModal.yesDelete') ||
      'Yes, Delete'
    );
  }, [deleteButtonText, isSaving, t]);

  const footerSaveButtonLabel = useMemo(() => {
    if (isSaving) {
      return (
        customSavingLabel ||
        `${t('ui.saving')}...` ||
        'Saving...'
      );
    }

    return (
      customSaveLabel || t('ui.save') || 'Save'
    );
  }, [customSaveLabel, customSavingLabel, isSaving, t]);

  const preferredFooterComponent = footer ? footer : (
    <DefaultFooter
      darkMode={darkMode}
      closeButtonLabel={footerCloseButtonLabel}
      isCloseButtonDisabled={isSaving}
      deleteButtonLabel={footerDeleteButtonLabel}
      deleteCancellationButtonLabel={footerDeleteCancellationButtonLabel}
      deleteConfirmationButtonLabel={footerDeleteConfirmationButtonLabel}
      isDeleteButtonDisabled={isSaving}
      isDeleteConfirmationButtonDisabled={isSaving}
      isOnDeleteMode={deleteMode || deleteModeOverride}
      isSaveButtonDisabled={footerIsSaveButtonDisabled}
      onCloseButtonClick={onHide}
      onDeleteButtonClick={handleDelete}
      onDeleteCancellationButtonClick={footerHandleCancellation}
      onDeleteConfirmationButtonClick={onDelete}
      onSaveButtonClick={onSave}
      showDeleteButton={footerShowDelete}
      showSaveButton={footerShowSave}
      saveButtonLabel={footerSaveButtonLabel}
    />
  );

  return (
    <Modal
      backdrop={preventDismiss ? 'static' : true}
      keyboard={!preventDismiss}
      data-testid="AdminModal"
      show={show}
      onHide={onHide}
      className={`${styles.isAdminModal} ${styles.isAdminModalContainer} ${darkMode ? styles.darkMode : ''}`}
      onExited={onExited}
      style={style}
      dialogClassName={dialogClassName}
      centered={centered}
      datacy="admin-modal"
    >
      {title && <Modal.Header className={styles.isAdminModalHeader} datacy="admin-modal-header">
        <Modal.Title className={styles.isAdminModalHeaderTitle} datacy="admin-modal-title">{title}</Modal.Title>
      </Modal.Header>}
      <Modal.Body className={styles.isAdminModal}>
        {deleteMode ? <div>{deleteMessage || t('AdminModal.deleteMessage') || "Are you sure you want to do this?"}</div> : children}
      </Modal.Body>
      <Modal.Footer
        className={`${styles.isAdminModalFooter} ${customFooterStyle}`}
      >
        {preferredFooterComponent}
      </Modal.Footer>
    </Modal>
  );
};

AdminModal.propTypes = {
  /** Vertical + horizontal centering */
  centered: PropTypes.bool,
  /** Boolean indicating whether the button should render as disabled */
  show: PropTypes.bool.isRequired,
  /** button label. */
  title: PropTypes.string,
  /** onClick handler */
  onHide: PropTypes.func.isRequired,
  /** onClick handler */
  onSave: PropTypes.func.isRequired,
  /** Tracks if data is saving */
  isSaving: PropTypes.bool,
  /** Read only mode */
  readOnly: PropTypes.bool,
  /** Delete action handler */
  onDelete: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool
  ]),
  /** Message to show for delete mode */
  deleteMessage: PropTypes.string,
  /** Text to show for delete message */
  deleteButtonText: PropTypes.string,
  /**
   * Custom classname for the internal React-Bootstrap Modal component.
   * @see https://react-bootstrap-v4.netlify.app/components/modal/#modal-custom-sizing
   */
  dialogClassName: PropTypes.string,
  /** Action handler when closing modal */
  onExited: PropTypes.func,
  /** stop user form dismissing modal, by mouse, keyboard, or esc key */
  preventDismiss: PropTypes.bool,
  /** Custom footer */
  footer: PropTypes.element,
  /** Dark Mode version */
  darkMode: PropTypes.bool
};

export default AdminModal;