import {useState, useEffect} from 'react';
import { useIntl } from 'react-intl';
import axios from '../../utils/axios';
import { get } from '../../utils/utils';
import DefaultProfilePicture from '../../assets/images/unknown-unit.png';

export const useVendors = ({setErrors, setLoading}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [state, setState] = useState();

  useEffect(() => {
    const fetch = async () => {
      try {
        setErrors({});
        setLoading(true);

        const results = await axios.get(`/vendors`);
        if (results.status === 200) {
          let vendors = get(results, 'data.records', [])
          vendors = vendors.map(vendor => {
            return {...vendor, profilePicture: vendor.profilePicture || DefaultProfilePicture, company: '', ...get(vendor, 'vendorCompanyInformation', {})}
          })
          setState(vendors);
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return state;
}