import React from 'react'
import SelectWithCustomInput from '../SelectWithCustomInput/SelectWithCustomInput'
import { components } from 'react-select'
import { AiOutlineDollar } from 'react-icons/ai'
import { useTranslations } from '../utils/hooks'
import PropTypes from 'prop-types'

const Control = ({
  children,
  ...props
}) => (
  <components.Control {...props}>
    <div className="ml-2" style={{ fontSize: '20px', marginTop: '-1px' }}><AiOutlineDollar /></div>{children}
  </components.Control>
);

/*
  SelectWithCustomInput (see component) that allows only 23.00 or 23.00-25.99 format as well displays dollar icon
*/
const MoneyInput = ({
  range = false,
  ...props
}) => {
  const [t] = useTranslations()
  const validator = range ? /^\d*\.?\d{0,2}(-\d*\.?\d{0,2})?$/ : /^\d*\.?\d{0,2}$/
  return <div data-testid="MoneyInput">
    <SelectWithCustomInput
      id="moneyInput"
      data-testid="MoneyInput"
      validationCheck={(value) => (validator.test(value))}
      customComponents={{ Control }}
      customModePlaceholder={t('MoneyInput.customPlaceholder')}
      placeholder={t('MoneyInput.placeholder')}
      {...props}
    />
  </div>
}

MoneyInput.propTypes = {
  //Allow range of values as input (i.e. 23-56)
  range: PropTypes.bool
}

MoneyInput.defaultProps = {
  range: false
}

export default MoneyInput