import React from 'react';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import CheckBox from '../../CheckBox/CheckBox';
import styles from './ActionBox.module.css';

const ActionBox = ({
  deleteHandler, 
  selectHandler, 
  selected, 
  unselectHandler
}) => {
  if (!selectHandler && !deleteHandler) return null;

  if (selectHandler) { 
    return <div className={styles.checkbox}>
      <CheckBox select={selectHandler} unselect={unselectHandler} checked={selected} />
    </div>;
  }

  if (deleteHandler) {
    return <div className={`${styles.delete} d-flex align-items-start`}>
      <div data-testid="CardRemove" className='d-flex justify-content-center' onClick={deleteHandler}>
        <IoMdRemoveCircleOutline />
      </div>
    </div>
  }
}

export default ActionBox;