import React from 'react';

const Down = () => {
  return (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L4 4L8 0L0 0Z" fill="currentColor" />
    </svg>
  );
}

export default Down;