import React, { useState, useMemo } from 'react';
import styles from './DragDropImageUpload.module.css';
import { useIntl } from 'react-intl';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import FileUpload from '../assets/images/file-upload.svg';
import { Button, Spinner } from 'react-bootstrap';
import Remove from '../assets/images/times-circle-solid.svg';
import ChevronLeft from "../assets/images/chevron-left";
import ChevronRight from "../assets/images/chevron-right";
import { AiFillFilePdf, AiFillFile } from "react-icons/ai";
import Lightbox from '../Lightbox/Lightbox';
import { FILE_TYPE_LABELS } from '../MultipleMediaUpload/constants';

const nonImageFileTypeIcons = {
  'pdf': <AiFillFilePdf />,
  'dwg': <AiFillFile />
}

const DragDropImageUpload = ({
  files,
  setFiles,
  saveFilesHandler,
  fileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/heic'],
  numberFiles = 1,
  maxSize = 80000000,
  removeImageButton = true,
  style = {},
  onRemove = () => { },
  thumbnailSlide,
  hideButtons,
  firstImageNote,
  nonImageFileType,
  loading,
  buttonsLight,
  isReadOnly
}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const [slideIndex, setSlideIndex] = useState(0);
  const [showLightBoxImage, setShowLightBoxImage] = useState(null)

  const hiddenFileInput = React.useRef(null);

  const dropHandler = (newFiles) => {
    updateFiles(newFiles);
  }

  const handleClick = event => {
    if (isReadOnly) return;
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    if (event.target.files.length === 0) return;
    let newFiles = event.target.files;
    updateFiles(newFiles);
  };

  const updateFiles = (newFiles) => {
    newFiles = [...newFiles].filter((file) => (fileTypes.includes(file.type) || file.name.split('.').pop() === nonImageFileType) && file.size / maxSize < 1)

    //Replace single file logic
    if (files.length && newFiles.length > 0 && numberFiles === 1) {
      onRemove(files[0], 0, files, true);
    }

    if (setFiles) {
      setFiles((oldFiles) => {
        let numberSpots = numberFiles - oldFiles.length;
        if (numberSpots === 0 && newFiles.length) {
          oldFiles[oldFiles.length - 1] = newFiles[0];
          return [...oldFiles];
        }
        if (newFiles.length > numberSpots) {
          newFiles = newFiles.splice(0, numberSpots);
        }
        return oldFiles.concat(newFiles);
      })
    }

    if (saveFilesHandler) {
      saveFilesHandler(newFiles)
    }
  }

  const removeFileHandler = (i) => {
    onRemove(files[i], i, files);
    if (setFiles) {
      setFiles((oldFiles) => {
        oldFiles.splice(i, 1)
        return [...oldFiles];
      });
    }
  }

  const images = useMemo(() => {
    const thumbnails = files.map((file, i) => {
      const url = typeof file === 'string' ? file : URL.createObjectURL(file);
      const image = <div className={styles.uploadedImg} key={url + i} onClick={() => setShowLightBoxImage(url)}>
        {removeImageButton && !isReadOnly &&
          <div className={styles.removeImage} onClickCapture={() => removeFileHandler(i)} ><img src={Remove} alt={t('DragDropImageUpload.removeImage')} /></div>
        }
        <img src={`${url}${typeof file === 'string' ? `?${new Date().getTime()}` : ''}`} alt={file.name} />
      </div>
      return (i === 0) && firstImageNote && image ?
        <div key={file + i} className={styles.firstImage}>
          {image}
          <div className={styles.firstImageNote}>{firstImageNote}</div>
        </div>
        :
        image;
    })

    //Push empty placeholders for remaining files
    for (let i = 0; i < numberFiles - files.length; i++) {
      thumbnails.push(<div className={styles.uploadedImg} key={i}></div>)
    }

    return thumbnails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const getFileTypeLabels = fileTypes => {
    return fileTypes.map(fileType => FILE_TYPE_LABELS[fileType] || fileType).join(', ');
  };
  let fileTypesLabel = getFileTypeLabels(fileTypes);
  let fileSizeLabel = `${maxSize / 8000000}MB`;

  return (
    <>
      <DragAndDrop dropHandler={dropHandler} isReadOnly={isReadOnly}>
        <div
          className={`${styles.dragAndDrop} ${isReadOnly ? styles.isReadOnly : ''} d-flex flex-column justify-content-center align-items-center`}
          style={{ ...style, border: files.length && numberFiles === 1 ? '2px solid #d1d5db' : '2px dashed #d1d5db' }}>
          {loading ?
            <div><Spinner animation="border" role="status" /></div>
            :
            numberFiles === 1 && files.length ?
              <div className={styles.uploadedSingleImg}>
                {!nonImageFileType ?
                  <img src={typeof files[0] === 'string' ? files[0] : URL.createObjectURL(files[0])} alt={t('DragDropImageUpload.upload')} /> :
                  <div>{nonImageFileTypeIcons[nonImageFileType]}<a href={typeof files[0] === 'string' ? files[0] : URL.createObjectURL(files[0])} target="_blank" rel="noopener noreferrer">{`${t('ui.view')} ${nonImageFileType.toUpperCase()}`}</a></div>
                }
              </div>
              : !isReadOnly ?
                <>
                  <div><img src={FileUpload} alt={t('DragDropImageUpload.upload')} /></div>
                  <div className={styles.uploadLabel}><span onClick={handleClick}>{t('DragDropImageUpload.upload')}</span> {t('DragDropImageUpload.dragDrop')}</div>
                  <div className={styles.fileTypes}>{intl.formatMessage({ id: 'DragDropImageUpload.fileTypes' }, { fileTypes: fileTypesLabel, fileSize: fileSizeLabel })}</div>
                </> : <>{t("ui.uploadedFiles")}</>
          }
        </div>
      </DragAndDrop>
      {!hideButtons && !isReadOnly &&
        <div className="d-block mt-2">
          <Button className={`${styles.uploadImgButton} mr-2 ${buttonsLight ? styles.uploadImgButtonLight : ''}`} onClick={handleClick}>{files.length < numberFiles ? t('DragDropImageUpload.addPhoto') : t('DragDropImageUpload.replaceImage')}</Button>
          {removeImageButton && files.length === 1 && numberFiles === 1 &&
            <Button className={`${buttonsLight ? styles.removeImgButtonLight : ''}`} variant="outline-danger" onClick={() => removeFileHandler(0)}>{t('DragDropImageUpload.removeImage')}</Button>}
        </div>
      }
      {numberFiles > 1 && thumbnailSlide &&
        <div className='d-flex position-relative'>
          {slideIndex > 0 &&
            <div className={`${styles.thumbnailSlideArrow} ${styles.left} position-absolute`} onClick={() => setSlideIndex(prevIndex => slideIndex * 94 > 0 ? prevIndex - 1 : prevIndex)}>
              <div>
                <ChevronLeft />
              </div>
            </div>
          }
          {thumbnailSlide &&
            <div id='#dragDropThumbnailSlidesWrapper' className={`${styles.slideImages}`}>
              <div id='#dragDropThumbnailSlides' style={{ transform: `translateX(-${slideIndex * 94}px)` }} className={`${styles.images} no-flex`} data-testid="dragDropMultiImage">{images}</div>
            </div>
          }
          {(Math.max(numberFiles || 0, files.length) * 94 - slideIndex * 94) > (document.getElementById('#dragDropThumbnailSlidesWrapper') && document.getElementById('#dragDropThumbnailSlidesWrapper').offsetWidth) &&
            <div className={`${styles.thumbnailSlideArrow} ${styles.right} position-absolute`}
              onClick={() => setSlideIndex(prevIndex => (Math.max(numberFiles || 0, files.length) * 94 - slideIndex * 94) > document.getElementById('#dragDropThumbnailSlidesWrapper').offsetWidth ? prevIndex + 1 : prevIndex)}>
              <div>
                <ChevronRight />
              </div>
            </div>}
        </div>
      }
      {numberFiles > 1 && !thumbnailSlide &&
        <div className={styles.images} data-testid="dragDropMultiImage">
          {images}
        </div>
      }
      {!isReadOnly ?
        <input
          data-testid="dragDropFileUpload"
          type="file"
          accept={fileTypes}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          multiple={numberFiles > 1} /> : null}
      {showLightBoxImage && (
        <Lightbox
          mainSrc={showLightBoxImage}
          imageLoadErrorMessage="Please wait..."
          onCloseRequest={() => setShowLightBoxImage(null)}
          zIndex={10001}
        />
      )}
    </>
  );
}

export default DragDropImageUpload;