import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useTranslations from './useTranslations'
import PropTypes from 'prop-types'

let source;

//Fetch work worders
const useWorkOrders = ({
  companyGuid,
  propertyGuid,
  setIsLoading = () => { },
  redirectOnFail,
  toastError = () => { },
  axios,
  CancelToken,
  isCancel,
  filter,
  setPagination,
  queryParams,
  isAdmin,
  disabled,
  allowExternalWorkOrderAltering = false
}) => {
  const [t] = useTranslations();

  const [workOrders, setWorkOrders] = useState([])

  const navigate = useNavigate();

  const {
    page,
    orderBy,
    orderAscending,
    search
  } = queryParams

  useEffect(() => {

    const fetchWorkOrders = async () => {
      let cancelToken
      if (source) source.cancel()
      source = CancelToken.source()
      cancelToken = source.token

      try {
        setIsLoading(true);

        let formattedSort = orderBy ? `&sort=${orderAscending ? '' : '-'}${orderBy === 'property' ? 'Property.displayName' : orderBy}` : ''
        let formattedSearch = search ? `&search=${search}` : ''
        let formattedProperty = propertyGuid ? `&propertyGuid=${propertyGuid}` : ''

        const result = await axios.get(`/work-orders?companyGuid=${companyGuid}${formattedProperty}&page=${page + 1}${formattedSort}${formattedSearch}&type=${filter}&isAdmin=${isAdmin}`, {
          cancelToken
        });

        if (result.success || result?.data?.success) {
          setWorkOrders(result.records || result?.data?.records)
          setPagination({
            count: parseInt(result?.headers?.['pagination-count'])
          })
        } else {
          toastError(t('hooks.useWorkOrders.error'))
          if (redirectOnFail) navigate(redirectOnFail)
        }
      } catch (e) {
        if (!isCancel(cancelToken.reason)) {
          toastError(t('hooks.useWorkOrders.error'));
          if (redirectOnFail) navigate(redirectOnFail);
        }
      } finally {
        if (!isCancel(cancelToken.reason)) setIsLoading(false);
      }
    }

    if (!disabled && companyGuid && filter) {
      fetchWorkOrders();
    }

    return () => {
      if (source) source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyGuid, propertyGuid, setIsLoading, filter, page, orderAscending, orderBy, search])

  return allowExternalWorkOrderAltering ? [workOrders, setWorkOrders] : workOrders
}

useWorkOrders.propTypes = {
  companyGuid: PropTypes.string,
  propertyGuid: PropTypes.string,
  assetGuid: PropTypes.string,
  setIsLoading: PropTypes.func,
  redirectOnFail: PropTypes.string,
  toastError: PropTypes.func,
  axios: PropTypes.func,
  CancelToken: PropTypes.func,
  isCancel: PropTypes.func
}

useWorkOrders.defaultProps = {
  setIsLoading: () => { },
  toastError: () => { }
}

export default useWorkOrders