import axios from './../../src/utils/axios';
import { getDimensionInPixels } from './measurementService'

/**
 * Generate a renderer request payload for rendereing the iddigital section
 * @param {Object} propertyInfo the property object
 * @param {String} companySlug the company slug
 * @param {String} propertySlug the property slug
 * @param {Boolean} showLandscape flag to hide/show landscape
 */
const getRendererRequestPayload = async (propertyInfo, companySlug, propertySlug, showLandscape) => {
  try {
    const pageObj = await requestPageObject(companySlug, propertySlug);
    const payload = {
      data: {
        company_name: propertyInfo.company,
        property_name: propertyInfo.name,
        sections: [{
          sectionKey: 'land', page: pageObj.land
        }, {
          sectionKey: 'space', page: pageObj.space
        }, {
          sectionKey: 'bg', page: pageObj.bg
        }, {
          sectionKey: 'iddigital', page: pageObj.land
        }],
        assetsForRendering: ['iddigital']
      },
      meta: {
        page: pageObj.land,
        idCloudRequest: true,
        propertyWidth: getDimensionInPixels(propertyInfo, 'width'),
        propertyHeight: getDimensionInPixels(propertyInfo, 'height'),
        disableCache: true // TODO: This can be removed when cache invalidation is working
      }
    }

    if (!showLandscape) {
      const landIndex = payload.data.sections.findIndex(item => item.sectionKey === 'land')
      payload.data.sections.splice(landIndex, 1)
    }
    return payload
  } catch (e) {
    return null;
  }
 
}

/**
 * Request page numbers for doing the renderer request
 * @param {String} company the company
 * @param {String} property the property
 */
const requestPageObject = async (company, property) => {
  const res = await axios.get(`${process.env.REACT_APP_API_HOST}public/pages/${company}/${property}`);
  let filteredData = res.data.data.filter(_ => _.section === 'land' || _.section === 'space');
  let landObj = filteredData.filter(_ => _.section === 'land').sort((a, b) => a.index - b.index);
  landObj = landObj.shift();
  const landIndex = +landObj.index;
  const spaceIndex = +landObj.background.split('_')[3];
  const spaceObj = filteredData.find(_ => _.section === 'space' && +_.index === spaceIndex);
  const bgIndex = +spaceObj.background.split('_')[3];
  return {
    'land': landIndex,
    'space': spaceIndex,
    'bg': bgIndex
  };
}

export { getRendererRequestPayload };