import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import Option from '../Option/Option';
import styles from './OptionsList.module.css';

//Options to select from in a list
const OptionsList = ({
  active,
  isLoadingOptions,
  options,
  setSelectedOption,
  selectedOptionValue,
  isSearching,
  searchValue,
  showIcon,
  append,
  setSearchValue,
  setActive,
  sort
}) => {
  const optionSelectedClickHandler = (option) => {
    setSelectedOption(option);
    setSearchValue(option.label)
  };

  //Filter options by search value after user stars typing.
  //Removes currently selected option. Handles sorting.
  let optionsSortedFiltered = useMemo(() => {
    if (!searchValue) {
      return options;
    }
    const optionsFiltered = options
      .filter(_ => (_.value !== selectedOptionValue) && (!isSearching || _.label.toLowerCase().includes(searchValue.toLowerCase()) || _.value.toLowerCase().includes(searchValue.toLowerCase())))
    if (sort) optionsFiltered.sort((a, b) => a.label.localeCompare(b.label))
    return optionsFiltered
  }, [isSearching, options, searchValue, selectedOptionValue, sort]);

  if (!active) return null

  return <div className={`${styles.optionsContainer} w-100 position-absolute`}>
    <div>
      {isLoadingOptions ?
        <div className='w-100 py-3 d-flex justify-content-center'><Spinner animation="border" role="status" /></div> :
        optionsSortedFiltered.map(option =>
          <Option
            key={option.value}
            option={option}
            showIcon={showIcon}
            click={() => optionSelectedClickHandler(option)}
            setActive={setActive}
            append={append}
            large={true}
            className={option.className}
          />)
      }
    </div>
  </div>
}

export default OptionsList;