import React, { useState, useMemo } from 'react'
import AdminInput from '../AdminInput/AdminInput'
import { MdAdd as DefaultAddIcon } from 'react-icons/md'
import styles from './List.module.css'
import { useEffect } from 'react'
import { useTranslations } from '../utils/hooks'
import PropTypes from 'prop-types'

const AddListItem = ({
  value,
  setValue,
  onBlur = () => {},
  addItem,
  readOnly = false,
  t
}) => {
  return <div className='d-flex justify-content-between align-items-center mb-3' data-testid="ListInput">
    <AdminInput 
      readOnly={readOnly}
      onBlur={onBlur}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      hidelabel="true"
      customstyle={`${styles.Input} mb-0`}
    />
    <div className={styles.AddIcon} onClick={addItem}>
      {t('ui.add')}
    </div>
  </div>

}

const ListItem = ({
  value,
  deleteItem,
  t,
  formatListItem
}) => {
  return <div className='d-flex justify-content-between align-items-center mb-3'>
    <div>{formatListItem ? formatListItem(value) : value}</div>
    <div className={styles.DeleteIcon} onClick={deleteItem}>
      {t('ui.remove')}
    </div>
  </div>
}


/*
  Allows displaying and adding/removing to a list
*/
const List = ({
  list = [],
  setList,
  label,
  addIcon,
  addRemoveSubstring = '',
  formatListItem,
  disabled = false
}) => {
  const [t] = useTranslations()
  const [newListValue, setNewListValue] = useState('')
  const [showAddValue, setShowAddValue] = useState(false)

  //Add item to list
  //Replace necessary substring with ''
  //Disallow empty values
  const addListItem = () => {
    let tmpValue = newListValue.replace(addRemoveSubstring, '')
    if (tmpValue === '') return
    const tempList = [...list]
    tempList.push(tmpValue)
    setList(tempList)
    setNewListValue('')
  }

  useEffect(() => {
    //Show add value input if no items in list
    if (list.length === 0) setShowAddValue(true)
  }, [list, setShowAddValue])

  //Hide add list item input onBlur
  const blurHandler = () => {
    if (newListValue === '' && list.length > 0) {
      setShowAddValue(false)
    }
  }

  const listDisplay = useMemo(() => {
    const deleteListItem = (index) => {
      const tempList = [...list].filter((_, i) => i !== index)
      setList(tempList)
    }
    return list.map((item, i) => (
        <ListItem 
          key={i}
          value={item} 
          deleteItem={() => deleteListItem(i)} 
          t={t}
          formatListItem={formatListItem}
        />
      ))
  }, [formatListItem, list, t, setList])

  return <>
    <div className={`${styles.InputContainer} d-flex justify-content-between align-items-center mb-2`} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      <div className={styles.Label}>{label || t('ui.list')}</div>
      <div 
        onClick={() => setShowAddValue(true)} 
        className={styles.AddLinkIcon}>
          {addIcon || <DefaultAddIcon />}
      </div>
    </div>
    <div className={styles.ListContainer}>
      {showAddValue &&
        <AddListItem 
          readOnly={disabled}
          value={newListValue} 
          setValue={(value) => setNewListValue(value)}
          addItem={addListItem}
          onBlur={blurHandler}
          t={t}
        />
      }
      {listDisplay}
    </div>
  </>
}

List.propTypes = {
  list: PropTypes.array,
  setList: PropTypes.func.isRequired,
  label: PropTypes.string,
  addIcon: PropTypes.any,
  /*
    Removes given value from list item before adding to list.
    (i.e. removes 'https://' from input links)
  */
  addRemoveSubstring: PropTypes.string,
  //Format List item for display purposes. If not provided, value is shown
  //without formatting
  formatListItem: PropTypes.func
}

List.defaultProps = {
  list: []
}

export default List