import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import MenuItem from './ScrollMenuItem';
import styles from './ScrollMenu.module.css';

const classNames = require('classnames');

/*
 * ScrollMenu Component
 */
const Menu = ({ target, sections, labelMap = {} }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [scrollTarget, setScrollTarget] = useState(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const menuContainerRef = useRef(null);
  const [menuItems, setMenuItems] = useState({});

  // after the dom has loaded, determine the anchor positions for the content sections
  useLayoutEffect(() => {

    let targetEl = document.getElementById(target);

    const getAnchorPoints = () => {
      let offset;
      for (const key in menuItems) {
        const rect = targetEl.querySelector(`#${key}`).getBoundingClientRect();
        if (!offset) offset = rect.y;
        menuItems[key] = rect.top - offset;
      }
    };

    // resets the anchor points if the content DOM changes
    const observer = new MutationObserver(getAnchorPoints);
    observer.observe(document.getElementById(target), {
      childList: true,
      subtree: true,
    });
    getAnchorPoints();
    setActiveItem(Object.keys(menuItems)[0]);

    const resizeHandler = getAnchorPoints.bind(this);
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler)
    };

  }, [menuItems, target]);

  useEffect(() => {

    const setup = () => {
      let tmp = sections.reduce((acc, section) => {
        acc[section] = section;
        return acc;
      }, {})
      setMenuItems(tmp)
    }

    if (sections?.length) setup()
  }, [sections])

  // determine the position of the anchor points

  // handle the scroll for the content container
  const handleScroll = useCallback((e) => {
    if (!e) return;
    const curPos = e.target.scrollTop;

    // iterate the sections to determine which should be active
    let tmpSection = null;
    for (const section in menuItems) {
      if (menuItems[section] > curPos) {
        tmpSection = tmpSection || section;
        break;
      }
      tmpSection = section;
    }

    // if the derived section is not the currently active menu item, then switch it
    if (tmpSection && tmpSection !== activeItem) {
      if (!scrollTarget) setActiveItem(tmpSection);

      // handle scrolling the menu if the user is manually scrolling the containe
      if (!scrollTarget) {
        const targetNode = menuContainerRef.current;
        const startingPos = targetNode.scrollLeft;
        let x = startingPos;
        const targetPos = menuContainerRef.current.querySelector(`a[href="#${tmpSection}"]`).offsetLeft - targetNode.offsetLeft - 8;
        const offset = targetPos - startingPos;

        // if the item is visible in the container, no adjustment necessary
        if (offset >= 0 && offset < targetNode.clientWidth) return;

        // scroll to the menu item
        const scroll = setInterval(function () {
          targetNode.scroll(x, 0);
          if (Math.abs(x - targetPos) <= 10) {
            targetNode.scroll(targetPos - 22, 0); // get it to the beginning of the anchor text
            if (targetPos < 20) targetNode.scroll(0, 0); // we're near the start, take it to the exact start
            clearInterval(scroll);
          }
          x = x + (targetPos - startingPos > 0 ? 5 : -5);
        }, 5);
      }
    }

    // if the scrolltarget is set (by directly clicking on a menu item), then clear it once the target section is reached
    if (scrollTarget === tmpSection) {
      setScrollTarget(null);
      setActiveItem(tmpSection);
    }
  }, [activeItem, scrollTarget, menuItems]);

  // create (and/or teardown) the scroll event handler
  useEffect(() => {
    let targetDom = document.getElementById(target);
    targetDom.addEventListener('scroll', handleScroll);
    return () => targetDom.removeEventListener('scroll', handleScroll);
  }, [activeItem, target, handleScroll]);

  //menu items to build
  const menuList = Object.keys(menuItems).map((key, i) =>
    <MenuItem itemName={key} key={`menuitem_${i}`} active={key === activeItem} setActiveItem={setActiveItem} targetPosition={menuItems[key]} target={target} setScrollTarget={setScrollTarget} labelMap={labelMap} />
  )

  // handle the scroll event for the menu list container, determine if scroll arrows should show
  const handleMenuScroll = useCallback(() => {
    let targetDom = menuContainerRef.current;
    const isMenuAtStart = targetDom.scrollLeft < 11;
    const isMenuAtEnd = targetDom.scrollLeft + targetDom.clientWidth >= targetDom.scrollWidth;

    if (!isMenuAtStart && !showLeftArrow) setShowLeftArrow(true);
    if (isMenuAtStart && showLeftArrow) setShowLeftArrow(false);

    if (!isMenuAtEnd && !showRightArrow) setShowRightArrow(true);
    if (isMenuAtEnd && showRightArrow) setShowRightArrow(false);

  }, [showLeftArrow, showRightArrow]);

  // setup the menu scrolling listener so arrows can be shown/hidden dynamically
  useEffect(() => {
    let targetDom = menuContainerRef.current;
    targetDom.addEventListener('scroll', handleMenuScroll);
    handleMenuScroll(); // do an init on initial arrow button states
    return () => targetDom.removeEventListener('scroll', handleMenuScroll);
  }, [handleMenuScroll]);


  // handler for horizontal menu arrow clicks. will scroll the container to the left or right
  const handleMenuArrowClick = (direction) => {
    const startingPos = menuContainerRef.current.scrollLeft;
    let x = direction === 'left' ? startingPos - 5 : startingPos + 5;
    let delay = 10;
    const limit = menuContainerRef.current.clientWidth * .92;

    if (direction === 'left') {
      const left = setInterval(function () {
        menuContainerRef.current.scroll(x, 0);
        if (menuContainerRef.current.scrollLeft < startingPos - limit || x < 0) clearInterval(left);
        x = x - 5;
      }, delay);
    } else { // right arrow clicked
      const right = setInterval(function () {
        menuContainerRef.current.scroll(x, 0);
        if ((menuContainerRef.current.scrollLeft > startingPos + limit)
          || (menuContainerRef.current.scrollLeft + menuContainerRef.current.clientWidth >= menuContainerRef.current.scrollWidth)) {
          clearInterval(right);
        }
        x = x + 5;
      }, delay);
    }
  }

  if (!target) return;

  /*
   * Return the JSX Menu, complete with nested MenuItems
   */
  return (
    <div id="menuContainer" className={classNames(styles.menuContainer, 'position-relative ml-4')}>
      {showLeftArrow ?
        <span className={classNames(styles.scrollLeft, 'position-absolute d-flex align-items-center justify-content-center')} onClick={() => handleMenuArrowClick('left')}>
          <FiChevronLeft />
        </span> : null}
      <div ref={menuContainerRef} className={classNames(styles.menuItems, 'd-flex')} >
        {menuList}
      </div>
      {showRightArrow ?
        <span className={classNames(styles.scrollRight, 'position-absolute d-flex align-items-center justify-content-center')} onClick={() => handleMenuArrowClick('right')}>
          <FiChevronRight />
        </span>
        : null}
    </div>
  );
}

export default Menu;