import React from 'react';
import PropTypes from 'prop-types';
import SlideContainer from './SlideContainer';

const VideoSlide = ({ url }) => (
  <SlideContainer>
    <video
      src={url}
      controls
      style={{ objectFit: 'contain' }}
    />
  </SlideContainer>
);

VideoSlide.propTypes = {
  /** URL of a video. */
  url: PropTypes.string.isRequired
};

export default VideoSlide;