import { useState } from "react"
import { useDelete } from "./hooks/useDelete"
import { useUpload } from "./hooks/useUpload"
import { useRotate } from "./hooks/useRotate"

export const useMediaUploadButtonActions = ({
  axiosInstance, axiosCancelTokenFactory, rotateRoute, deleteRoute, uploadRoute,
  media, ownerId, ownerType, type, extraPayloadData,
  onUpdate, onUpload, onDelete, formatResponse,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileToRotate, setFileToRotate] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [mediaCache, setMediaCache] = useState(media);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /** Upload handler */
  useUpload({
    axiosInstance, axiosCancelTokenFactory, filesToUpload, mediaCache, onUpdate,
    onUpload, ownerId, ownerType, type, uploadRoute, extraPayloadData, formatResponse,
    setIsUploading, setErrorMessage, setFilesToUpload, setMediaCache
  })

  /** Rotate handler */
  useRotate({
    setIsRotating, setErrorMessage, type, mediaCache, fileToRotate,
    ownerId, ownerType, extraPayloadData, axiosInstance, rotateRoute,
    setMediaCache, onUpdate, setFileToRotate
  })

  /** Delete handler */
  useDelete({
    axiosInstance, axiosCancelTokenFactory, deleteRoute, fileToDelete, mediaCache, onDelete, onUpdate,
    setIsDeleting, setErrorMessage, setFileToDelete, setMediaCache
  })

  return {
    errorMessage,
    setErrorMessage,
    setFileToDelete,
    setFileToRotate,
    setFilesToUpload,
    isUploading,
    isDeleting,
    isRotating,
    isModalOpen,
    setIsModalOpen,
    mediaCache,
  }
}