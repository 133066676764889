import React, { useCallback } from 'react'
import {
  WORK_ORDER_PRIORITY,
  PRIORITY_COLORS,
  PRIORITY_BACKGROUND_COLORS,
  INSPECT_TYPE,
  UPLOADED_FILE_CATEGORY
} from '../../../utils/constants'
import { formatDueDate, isExpiring, slugify } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import FileIcon from '../../../FileIcon/FileIcon';

/**
   * This is container for props that will be passed to the Table component
   * to handle fields, filters, sizing, etc
   * @param {string} type inspection or work-order
   * @param {any} statusField Holds schema for status field
   * @param {(s: string) => string} t translator
   * @param {list: any[]} tableActionsList list of actions for action list on row
   * @param {boolean} external is table found outside idinspect
   * @param {boolean} mobileView whether table has passed the mobileView size breakpoint
   * @param {boolean} largeView whether table has passed the largeView size breakpoint
   * @param {boolean} xLargeView whether table has passed the xLargeView size breakpoint
   * @param {func} showDetailPane callback function to render the detail pane when clicking the item name
   * @returns {any} Props for tables
   */
const useTableProps = ({
  type,
  statusField,
  t,
  isAdmin = false,
  tableActionsList,
  external,
  mobileView,
  largeView,
  xLargeView,
  showDetailPane
}) => {
  const workOrderProps = useCallback((tab) => {
    const priorityField = () => ({
      id: 'priority',
      label: t('WorkOrders.table.dueDate.label'),
      type: 'status',
      statusColors: [
        { condition: _ => _.priority === WORK_ORDER_PRIORITY.LOW, color: { backgroundColor: PRIORITY_BACKGROUND_COLORS[WORK_ORDER_PRIORITY.LOW], color: PRIORITY_COLORS[WORK_ORDER_PRIORITY.LOW] } },
        { condition: _ => _.priority === WORK_ORDER_PRIORITY.MEDIUM, color: { backgroundColor: PRIORITY_BACKGROUND_COLORS[WORK_ORDER_PRIORITY.MEDIUM], color: PRIORITY_COLORS[WORK_ORDER_PRIORITY.MEDIUM] } },
        { condition: _ => _.priority === WORK_ORDER_PRIORITY.HIGH, color: { backgroundColor: PRIORITY_BACKGROUND_COLORS[WORK_ORDER_PRIORITY.HIGH], color: PRIORITY_COLORS[WORK_ORDER_PRIORITY.HIGH] } },
        { condition: _ => _.priority === WORK_ORDER_PRIORITY.URGENT, color: { backgroundColor: PRIORITY_BACKGROUND_COLORS[WORK_ORDER_PRIORITY.URGENT], color: PRIORITY_COLORS[WORK_ORDER_PRIORITY.URGENT] } }
      ]
    })

    return {
      idField: 'id',
      searchFilters: ['summary', 'property', 'company', 'priority', 'status', 'dueDate', 'createdBy', 'assignedTo', 'costActual', 'costQuoted'],
      sortFields: ['summary', 'property', 'company', 'priority', 'status', 'dueDate', 'createdBy', 'assignedTo', 'costActual', 'costQuoted'],
      placeholder: t('WorkOrders.table.all.placeholder'),
      fields: [
        {
          id: 'summary', label: t('ui.name'), type: 'fragment', renderFragment: (item) => {
            return !showDetailPane ? (
              <Link to={`/${slugify(item.company)}/admin/work-order/${item.id}`}>
                {item.summary}
              </Link>
            ) : (
              <a href={`#${item.id}`} onClick={() => showDetailPane(item)}>
                {item.summary}
              </a>
            )
          }
        },
        { id: 'property', label: t('term.property'), type: 'details' },
        { ...priorityField() },
        statusField,
        { id: 'createdBy', label: 'Reporter', type: 'fragment', renderFragment: (item) => item.reporterName },
        { id: 'assignedTo', label: 'Assignee', type: 'fragment', renderFragment: (item) => item.assigneeName },
        {
          id: 'dueDate', label: 'Due Date', type: 'status', formatLabel: date => date ? format(new Date(date), 'MM/dd/yyyy') : "N/A", statusColors: [
            { condition: _ => isExpiring(_.dueDate, 0), color: { backgroundColor: 'rgba(109, 113, 117, 0.1)', color: '#6D7175' } },
            { condition: _ => isExpiring(_.dueDate, 1), color: { backgroundColor: 'rgba(206, 21, 20, 0.1)', color: '#CE1514' } },
            { condition: _ => isExpiring(_.dueDate, 7), color: { backgroundColor: 'rgba(255, 122, 0, 0.1)', color: '#FF7A00' } },
            { condition: _ => _, color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } }
          ]
        },
        { id: 'costActual', label: 'Actual Cost', type: 'currency' },
        { id: 'costQuoted', label: 'Quoted Cost', type: 'currency' },
        {
          id: 'coi', label: t('WorkOrders.table.coi'), type: 'fragment', renderFragment: (item) => {
            const coiFile = item.WorkOrderCOI || item.WorkOrderFiles.find(file => file.UploadedFile.category === UPLOADED_FILE_CATEGORY.COI);

            if (!coiFile) {
              return null;
            }

            return (
              <a href={coiFile.UploadedFile.url} title={t('WorkOrders.table.openCoi')} target='_blank' rel='noreferrer noopener'>
                <FileIcon extension='coi' />
              </a>
            );
          }
        },
        { ...tableActionsList[external ? 'external' : tab] },
      ],
      statusRowColors: priorityField().statusColors,
      showFields: (mobileView && ['summary', 'property', 'actionsList']) ||
        (!xLargeView && ['summary', 'property', 'priority', 'createdBy', 'assignedTo', 'dueDate', 'costActual', 'costQuoted', 'coi', 'actionsList']) ||
        ['summary', 'property', 'priority', 'status', 'createdBy', 'assignedTo', 'dueDate', 'costActual', 'costQuoted', 'coi', 'actionsList'],
      customWidths: (mobileView && ['50%', '50%']) ||
        (!xLargeView && ['40%', '40%', '20%']) ||
        ['10%', '20%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
      detailsDropdown: (mobileView && ['priority', 'status', 'createdBy', 'assignedTo', 'dueDate', 'costActual', 'costQuoted', 'coi']) ||
        (!xLargeView && ['status'])
    }
  }, [external, xLargeView, mobileView, statusField, t, tableActionsList, showDetailPane])

  const inspectionProps = useCallback((tab) => {
    const dueDateField = (showPastDueDate) => ({
      id: 'dueDate', label: t('Inspections.table.dueDate.label'), type: 'status', formatLabel: _ => formatDueDate(_, false, showPastDueDate), statusColors: [
        { condition: _ => isExpiring(_.dueDate, 0), color: { backgroundColor: 'rgba(109, 113, 117, 0.1)', color: '#6D7175' } },
        { condition: _ => isExpiring(_.dueDate, 1), color: { backgroundColor: 'rgba(206, 21, 20, 0.1)', color: '#CE1514' } },
        { condition: _ => isExpiring(_.dueDate, 7), color: { backgroundColor: 'rgba(255, 122, 0, 0.1)', color: '#FF7A00' } },
        { condition: _ => _, color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } }
      ]
    })

    const fields = {
      idField: 'id',
      searchFilters: ['templateName', 'property', 'username', 'dueDate', 'completeDate', 'status', 'score'],
      sortFields: ['templateName', 'property', 'username', 'dueDate', 'completeDate', 'status', 'score'],
      placeholder: t('Inspections.table.all.placeholder'),
      fields: [
        {
          id: 'templateName', label: t('ui.name'), type: 'fragment', renderFragment: (item) => {
            return (
              <Link to={`/${item.companySlug}/${isAdmin ? 'admin/' : ''}inspection/${item.id}`}>
                {item.templateName}
              </Link>
            )
          }
        },
        { id: 'property', label: t('term.property'), type: 'details' },
        { id: 'username', label: t('Inspections.table.all.username'), type: 'details' },
        { ...dueDateField(true) },
        { id: 'completeDate', label: t('Inspections.table.completeDate.label'), type: 'datetime', format: 'MM/DD/yyyy', incomingFormat: 'YYYY-MM-DD hh:mm:ss' },
        statusField,
        { id: 'score', label: t('Inspections.tabs.watching.score'), type: 'details' },

        { ...tableActionsList[external ? 'external' : tab] }
      ],
      statusRowColors: statusField.statusColors,
      showFields: (!largeView && ['templateName', 'property', 'actionsList']) ||
        (!xLargeView && ['templateName', 'property', 'username', 'dueDate', 'completeDate', 'actionsList']) ||
        ['templateName', 'property', 'username', 'dueDate', 'completeDate', 'status', 'score', 'actionsList'],
      customWidths: (!largeView && ['50%', '50%']) ||
        (!xLargeView && ['30%', '30%', '17%', '23%']) ||
        ['28%', '28%', '15%', '16%', '13%'],
      detailsDropdown: (!largeView && ['username', 'dueDate', 'status']) ||
        (!xLargeView && ['status'])
    }
    if (tab === 'watching') {
      fields.fields = fields.fields.filter(field => field.id !== 'status' && field.id !== 'completeDate')
    }
    return fields
  }, [external, largeView, statusField, t, tableActionsList, xLargeView, isAdmin])

  const tableProps = {
    [INSPECT_TYPE.INSPECTION]: inspectionProps,
    [INSPECT_TYPE.WORK_ORDER]: workOrderProps
  }

  return [tableProps[type]]
}

export default useTableProps