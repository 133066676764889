import React, { useMemo } from 'react';
import styles from './Option.module.css';
import { IoMdClose } from 'react-icons/io';
import OptionImage from './OptionImage/OptionImage';
import OptionLabel from './OptionLabel/OptionLabel';
import PropTypes from 'prop-types';

// This component is being used in the ClickSelect component as the
// options in the dropdown list and as the selected option/search.
// This component has two main modes: as an option in the dropdown
// (isSelectedOption === false) and as the selected option/search
// (isSelectedOption === true). The dropdown list mode is pretty 
// straightforward, displays option, when hovered over - background
// color changes, when clicked - option selected, search value reset,
// and active set to false which closes the list and makes selectedOption
// a normal label. For the selected option, when clicked - active is set
// to true. When active is set to true, the option turns into an input
// with focus set on that input, and the dropdown list appears.
const Option = ({
  option,
  showIcon,
  click = () => { },
  searchValue,
  setSearchValue,
  setIsSearching,
  active,
  setActive,
  isSelectedOption,
  blur,
  optionRef,
  append,
  readOnly,
  className = ''
}) => {

  const optionMousedownHandler = () => {
    click();
    if (readOnly) return;
    setActive(isSelectedOption ? true : false);
  }

  const optionStyles = useMemo(() => {
    let optionStyles = [`${styles.option} ${className} w-100 d-flex justify-content-start align-items-center position-relative`];

    if (!isSelectedOption) optionStyles.push(styles.listOption);
    if (isSelectedOption && !active) optionStyles.push(styles.selectedOptionNotActive);
    if (!showIcon) optionStyles.push(styles.noImageOption);
    if (readOnly) optionStyles.push(styles.readOnly);

    return optionStyles.join(' ');
  }, [active, className, isSelectedOption, readOnly, showIcon]);

  //Have to use mouseDown and e.preventDefault in order to prevent blur from firing for the input
  const deleteIcon = active ?
    <div className={`${styles.delete} position-absolute`} onMouseDown={(e) => { e.preventDefault(); setSearchValue(''); setIsSearching(true); }} data-testid='DeleteOption'>
      <IoMdClose />
    </div> : null;

  //Have to use mouseDown in order to ensure the handler is fired befor the input's onBlur.
  return <div
    className={optionStyles}
    onMouseDown={optionMousedownHandler}>
    <OptionImage
      icon={option.icon}
      label={option.label}
      showImage={showIcon}
      className={styles.image}
      circleTitleClassName={styles.circleTitle}
      iconClassName={styles.Icon}
    />
    <OptionLabel
      active={active}
      append={append}
      blur={blur}
      isSelectedOption={isSelectedOption}
      option={option}
      optionRef={optionRef}
      searchValue={searchValue}
      setIsSearching={setIsSearching}
      setSearchValue={setSearchValue}
      inputClassName={styles.input}
      labelClassName={styles.label}
    />
    {deleteIcon}
  </div>
}

Option.propTypes = {
  //Option of form {label: 'testlabel', value: 'testid', image: 'imageurl'}
  option: PropTypes.object,
  //Show image in option container. If true and image not provided, a circle title component will be 
  //show instead (i.e. Test User => TU)
  showIcon: PropTypes.bool,
  //Handler to be run when clicking on the option
  click: PropTypes.func,
  //Value to filter options with
  searchValue: PropTypes.string,
  //Setter for search value
  setSearchValue: PropTypes.func,
  //Used to determine if search should be used yet or not. For instance, if the user clicks the
  //input and it focuses, we do not want to search by the input immediately as it would be 
  //the current option and filter all other options. Thus this will be set to true when the 
  //user starts changing the input and the search value will begin to be used.
  setIsSearching: PropTypes.func,
  //Determines if the selected option should be shown as a search bar. If inactive the 
  //selected option will be shown as a regular label.
  active: PropTypes.bool,
  //Setter for the active prop
  setActive: PropTypes.func,
  //Option is the currently selected option. Used to control certain
  isSelectedOption: PropTypes.bool,
  //Used to handle blur when blur fired for the input.
  blur: PropTypes.func,
  //Used to control the ref and focus to the input/searchbar
  optionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  //Function used to append a string onto the label. This function will
  //be passed the option object and is expected to return a string.
  append: PropTypes.func,
  readOnly: PropTypes.bool
}

Option.defaultProps = {
  click: () => { }
}

export default Option;