import { MODAL_THUMBNAIL_TARGET_SIZES } from "./constants"

/**
 * Tries to get the first thumbnail URL available in `file` as per the settings in `MODAL_THUMBNAIL_TARGET_SIZES`.
 * If none could be retrieved, `file.url` will be returned instead.
 *
 * @param {{url: string, extra?: {thumbnails?: {xl?: string, lg?: string, md?: string}}}} file
 * @returns {string}
 */
export const getDefaultFileUrlForModal = (file) => {
  const targetThumbnail = MODAL_THUMBNAIL_TARGET_SIZES.map(
    thumbnailSize => file?.extra?.thumbnails[thumbnailSize]
  ).filter(Boolean).shift()

  return targetThumbnail ?? file?.url
}
