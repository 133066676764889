import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './StaticRatingLabel.module.css';
export const LABEL_STYLE_BINARY = 'BINARY';
export const LABEL_STYLE_SCALE = 'SCALE';
export const LABEL_STYLES = [LABEL_STYLE_BINARY, LABEL_STYLE_SCALE];

/**
 * Renders a human-readable label based on the `rank` value,
 * which can be `0`, `1`, `2`, `3`, `true`, `false`, among others.
 * It takes a `rank` and creates a proper label using the
 * defined "formal" name for it in `rankingLabels`, for
 * example 0 > Fail, 1 > Pass.
 * If the `labelStyle` is `LABEL_STYLE_BINARY`, it will
 * print the plain label for that rank. But if it's `LABEL_STYLE_SCALE`,
 * it will use the `rank` number followed by its specific label.
 * If no `rank` value, it renders "N/A".
 * @example
 * ```
 * For `taskName = "Fire Alarm Test"`
 * - Binary type:
 *   - 0 -> "Fire Alarm Test: Fail"
 *   - 1 -> "Fire Alarm Test: Pass"
 * - Scale type:
 *   - 0 -> "Fire Alarm Test: 0 - Bad"
 *   - 1 -> "Fire Alarm Test: 1 - Good"
 *   - 2 -> "Fire Alarm Test: 2 - Excellent"
 * - `null` / `undefined` value
 *   - "Fire Alarm Test: N/A"
 * ```
 */
const StaticRatingLabel = ({
  taskName,
  rank,
  rankingLabels,
  labelStyle,
}) => {
  const humanizedRankValue = useMemo(() => {
    // loose type-checking, should cover undefined and null values
    // excludes `0` which is a valid value for `rank`
    if (rank == null) {
      return 'N/A';
    }

    const labelForCurrentRank = rankingLabels[rank];

    if (labelStyle === LABEL_STYLE_BINARY) {
      return labelForCurrentRank;
    }

    return `${rank} - ${labelForCurrentRank}`
  }, [rank, rankingLabels, labelStyle]);

  return (
    <>
      <span className={styles.taskName}>{taskName}:</span><span className={styles.humanRankedValue}>{humanizedRankValue}</span>
    </>
  );
};

StaticRatingLabel.propTypes = {
  /** Name for the task this label represents. */
  taskName: PropTypes.string.isRequired,
  /** Ranking value for this task. */
  rank: PropTypes.number,
  /**
   * Map of human-readable, formal names for each `rank`,
   * like "Pass", "Fail", "Good", "Bad".
   */
  rankingLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Optional. Changes the way the label is rendered.
   * For binary, it outputs just the equivalent label in `rankingLabels`.
   * Otherwise it prints the `rank` value followed by its label.
   * Defaults to `LABEL_STYLE_BINARY`.
   */
  labelStyle: PropTypes.oneOf(LABEL_STYLES),
};

StaticRatingLabel.defaultProps = {
  labelStyle: LABEL_STYLE_BINARY,
};

export default StaticRatingLabel;