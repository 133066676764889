import React from 'react';

const ReportsBold = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6 0C11.8268 0 11.2 0.626802 11.2 1.4V12.6C11.2 13.3732 11.8268 14 12.6 14H13.5333C14.3065 14 14.9333 13.3732 14.9333 12.6V1.4C14.9333 0.626801 14.3065 0 13.5333 0H12.6Z" fill="currentColor" />
      <path d="M5.6 5.13333C5.6 4.36013 6.2268 3.73333 7 3.73333H7.93333C8.70653 3.73333 9.33333 4.36013 9.33333 5.13333V12.6C9.33333 13.3732 8.70653 14 7.93333 14H7C6.2268 14 5.6 13.3732 5.6 12.6V5.13333Z" fill="currentColor" />
      <path d="M0 8.86667C0 8.09347 0.626801 7.46667 1.4 7.46667H2.33333C3.10653 7.46667 3.73333 8.09347 3.73333 8.86667V12.6C3.73333 13.3732 3.10653 14 2.33333 14H1.4C0.626801 14 0 13.3732 0 12.6V8.86667Z" fill="currentColor" />
    </svg>
  );
}

export default ReportsBold;