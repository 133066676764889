export default {
  //AdminModal Component
  "AdminModal.deleteMessage": "Are you sure you want to do this?",
  "AdminModal.yesDelete": "Yes, Delete",

  "Inspections.delete.success": "Inspection successfully deleted",
  "Inspections.delete.error": "Inspection could not be deleted",
  "Inspections.table.dueDate.label": "Due Date",
  "Inspections.table.completeDate.label": "Completion Date",
  "Inspections.table.all.placeholder": "Search inspections by template name, property, etc.",
  "Inspections.table.all.username": "Assigned To",
  "Inspections.table.all.viewReport": "View Report",
  "Inspections.table.all.editInspection": "Edit Inspection",
  "Inspections.table.all.deleteInspection": "Delete Inspection",
  "Inspections.table.all.watch": "Watch",
  "Inspections.table.assigned.placeholder": "Search inspections by template name, property, etc.",
  "Inspections.table.completed.placeholder": "Search inspections by template name, property, etc.",
  "Inspections.table.properties.placeholder": "Search properties by name or address",
  "Inspections.table.properties.inspections": "Inspections",
  "Inspections.tabs.assigned": "Assigned",
  "Inspections.tabs.completed": "Completed",
  "Inspections.tabs.all": "All",
  "Inspections.tabs.watching": "Watching",
  "Inspections.tabs.watching.score": "Inspection Score",
  "Inspections.tabs.properties": "Properties",
  "Inspections.properties.inspections": "{count, plural, =0 {# Inspections} one {# Inspection} other {# Inspections}}",
  "Inspections.properties.goBack": "Back to properties",
  "Inspections.deleteInspection": "Delete inspection",
  "Inspections.delete.modal.title": "Yes, Delete",
  "Inspections.delete.modal.message": "Are you sure you want to delete this inspection?",

  "WorkOrders.delete.success": "Work order successfully deleted",
  "WorkOrders.delete.error": "Work order could not be deleted",
  "WorkOrders.table.dueDate.label": "Priority",
  "WorkOrders.table.all.placeholder": "Search work orders by name, property, etc.",
  "WorkOrders.table.all.username": "Assigned To",
  "WorkOrders.table.all.viewWorkOrder": "View Work Order",
  "WorkOrders.table.all.editWorkOrder": "Edit Work Order",
  "WorkOrders.table.all.deleteWorkOrder": "Delete Work Order",
  "WorkOrders.table.all.watch": "Watch",
  "WorkOrders.table.all.manageCOI": "Manage COI",
  "WorkOrders.table.assigned.placeholder": "Search work orders by name, property, etc.",
  "WorkOrders.table.completed.placeholder": "Search work orders by name, property, etc.",
  "WorkOrders.table.properties.placeholder": "Search properties by name or address",
  "WorkOrders.table.properties.workOrders": "Work Orders",
  "WorkOrders.tabs.assigned": "My Work Orders",
  "WorkOrders.tabs.completed": "Completed Work Orders",
  "WorkOrders.tabs.all": "All Work Orders",
  "WorkOrders.tabs.watching": "Watching",
  "WorkOrders.tabs.properties": "Properties",
  "WorkOrders.properties.workOrders": "{count, plural, =0 {# Work Orders} one {# Work Order} other {# Work Orders}}",
  "WorkOrders.properties.goBack": "Back to properties",
  "WorkOrders.title": "Dashboard",
  "WorkOrders.deleteWorkOrder": "Delete work order",
  "WorkOrders.delete.modal.title": "Yes, Delete",
  "WorkOrders.delete.modal.message": "Are you sure you want to delete this work order?",
  "WorkOrders.table.reporter": "Reporter",
  "WorkOrders.table.assignee": "Assignee",
  "WorkOrders.table.dueDate": "Due Date",
  "WorkOrders.table.actualCost": "Actual Cost",
  "WorkOrders.table.quotedCost": "Quoted Cost",
  "WorkOrders.table.coi": "COI",
  "WorkOrders.table.coiPending": "COI Pending",
  "WorkOrders.table.openCoi": "Open COI",

  "MoneyInput.customPlaceholder": "Enter dollar amount",
  "MoneyInput.placeholder": "Select dollar estimate",

  //MultipleMediaUpload Component
  "MultipleMediaUpload.deletePhoto": "Delete Photo",
  "MultipleMediaUpload.uploadPhotos": "Upload Photos",
  "MultipleMediaUpload.deleteVideo": "Delete Video",
  "MultipleMediaUpload.uploadVideos": "Upload Videos",
  "MultipleMediaUpload.upload": "Upload",
  "MultipleMediaUpload.delete": "Delete",

  "Notes.title": 'Notes',
  "Notes.empty": 'No notes present',

  //PasswordInput component
  "PasswordInput.password": "Password",
  "PasswordInput.createPassword": "Create password",
  "PasswordInput.strength.veryWeak": "Very Weak",
  "PasswordInput.strength.weak": "Weak",
  "PasswordInput.strength.good": "Good",
  "PasswordInput.strength.strong": "Strong",
  "PasswordInput.strength.veryStrong": "Very Strong",
  "PasswordInput.strength": "Strength",
  "PasswordInput.mustMatch.error": "Passwords must match",
  "PasswordInput.confirm": "Confirm password",
  "PasswordInput.tooltip.label": "Password must",
  "PasswordInput.showHide.alt": "show or hide password",

  //ColorPicker Component
  "ColorPicker.chooseHex": "Choose HEX#",
  "ColorPicker.hex": "HEX#",
  "ColorPicker.opacity": "Opacity",

  //DragDropImageUpload component
  "DragDropImageUpload.upload": "Upload a file",
  "DragDropImageUpload.dragDrop": "or drag and drop",
  "DragDropImageUpload.fileTypes": "{fileTypes} up to {fileSize}",
  "DragDropImageUpload.addPhoto": "Add Photo",
  "DragDropImageUpload.replaceImage": "Replace Image",
  "DragDropImageUpload.removeImage": "Remove Image",
  "DragDropImageUpload.drop": "Drop to upload",

  //inputValidationRules
  "inputValidationRules.requiredRule": "{item} required",
  "inputValidationRules.minLengthRule": "contain at least {minCharacters} characters",
  "inputValidationRules.maxLengthRule": "cannot contain more than {maxCharacters} characters",
  "inputValidationRules.hasUpperCaseRule": "contain at least one uppercase letter",
  "inputValidationRules.hasLowerCaseRule": "contain at least one lowercase letter",
  "inputValidationRules.hasNumberRule": "contain at least one number",
  "inputValidationRules.isValidEmailRule": "must be a valid e-mail address",
  "inputValidationRules.passwordStrengthRule": "be a strong password",

  "RatingScale.default.1": "Poor",
  "RatingScale.default.2": "Not Good",
  "RatingScale.default.3": "Average",
  "RatingScale.default.4": "Good",
  "RatingScale.default.5": "Great",
  "RatingScale.default.NA": "N/A",

  "SelectWithCustomInput.placeholder": "Enter custom value",
  "SelectWithCustomInput.customValue": "Custom Value",

  "YesNo.default.0": "No",
  "YesNo.default.1": "Yes",
  "YesNo.default.NA": "N/A",

  "TagSelector.select": "Please select a tag",
  "TagSelector.placeholder": "Start typing and select multiple values...",
  "TagSelector.noOptions": "No values left to select",
  "TagSelector.clear": "Clear Tags",

  "Task.viewAssetInSiteplan": "View on Siteplan",

  /************************************************************
 * General Use
 * -----------------------------------
 * General use translations that are used throughout the
 * application in multiple components
 ************************************************************/

  //Error Message
  "error.general": "An error occurred",
  "error.server": "The server is not responding",
  "error.spaceProfile.create": "There was an error creating the space profile",
  "error.deleteFile": "There was an error deleting the file",
  "error.iconNotFound": "Upload icon {icon} not found. Using default icon.",
  "error": "Error",
  "error.required": "{field} is required",
  "error.valid": "Enter a valid value for {field}",
  "error.addNewLevel": "There was an error adding a new level",
  "error.deleteNewLevel": "There was an error deleting the level",

  //Files
  "file.floorplan": "Floorplan",
  "file.featured": "Featured Image",
  "file.autocad": "AUTOCAD",
  "file.pdf": "LOD PDF",
  "file.profile_pdf": "Profile PDF",

  //Floor Labels
  "floorLabels.basement1": "Basement 1",
  "floorLabels.basement2": "Basement 2",
  "floorLabels.groundFloor": "Ground Floor",
  "floorLabels.mainFloor": "Main Floor",
  "floorLabels.mezzanine": "Mezzanine",
  "floorLabels.level": "Level {level}",

  //Hooks
  "hooks.useSections.error": "Sections were not able to be retrieved",
  "hooks.useAssets.error": "Assets were not able to be retrieved",
  "hooks.useMenu.error": "Could not fetch properties",
  "hooks.useWorkOrders.error": "Could not fetch work orders",
  "hooks.useInspectionInstances.error": "Could not fetch inspections",

  //Platform Related Language
  "platform.roles.admin": "Admin",

  // Business or general use terms that are often repeated as content for button
  "term.company": "Company",
  "term.property": "Property",
  "term.user": "User",
  "term.template": "Template",
  "term.task": "Task",
  "term.unavailable": "Unavailable",
  "term.available": "Available",
  "term.visible": "Visible",
  "term.required": "Required",
  "term.enabled": "Enabled",
  "term.unit": "Unit",
  "term.unit_no": "Unit #",
  "term.address": "Address",
  "term.unknown": "Unknown",
  "term.search": "Search",
  "term.filters": "Filters",
  "term.resetFilters": "Reset filters",
  "term.assetType": "Asset Type",
  "term.floorplan": "Floorplan",
  "term.asset": "Asset",
  "term.published": "published",
  "term.unpublished": "unpublished",
  "term.draft": "draft",
  "term.other": "Other",
  "term.location": "Location",
  "term.vendor": "Vendor",
  "term.space": "Space",
  "term.vacant": "Vacant",
  "term.occupied": "Occupied",
  "term.sqFootage": "Sq Footage",
  "term.state": "State",
  "term.id": "ID",
  "term.idpRecord": "IDP Record ID",
  "term.leasable": "Leasable",
  "term.notLeasable": "Not Leasable",
  "term.priority": "Priority",
  "term.priority.Low": "Low",
  "term.priority.Medium": "Medium",
  "term.priority.High": "High",
  "term.priority.Urgent": "Urgent",
  "term.status.Created": "Created",
  "term.status.Assigned": "Assigned",
  "term.status.Scheduled": "Scheduled",
  "term.status.Work in progress": "Work in Progress",
  "term.status.Approved": "Approved",
  "term.status.Pending approval": "Pending Approval",
  "term.status.Paused": "Paused",
  "term.status.Cancelled": "Cancelled",
  "term.status": "Status",
  "term.status.Vendor Assigned": "Vendor Assigned",
  "term.status.Pending Vendor Acceptance": "Pending Vendor Acceptance",
  "term.status.Vendor Accepted": "Vendor Accepted",
  "term.status.Vendor Declined": "Vendor Declined",
  "term.status.Work in Progress": "Work in Progress",
  "term.status.Submitted for Approval": "Submitted for Approval",
  "term.status.Review": "Review",
  "term.status.Accepted": "Accepted",
  "term.status.Declined": "Declined",
  "term.status.Invoice Requested": "Invoice Requested",
  "term.status.Invoice Submitted": "Invoice Submitted",
  "term.status.Invoice Accepted": "Invoice Accepted",
  "term.status.Completed": "Completed",
  "term.workOrders": "Work Orders",
  "term.workOrder": "Work Order",
  "term.between": "Between {start} and {end}",
  "term.interval": "Between",
  "term.exclusive": "Exclusive",
  "term.inclusive": "Inclusive",
  "term.exclusiveShort": "exc.",
  "term.inclusiveShort": "inc.",
  "term.startingFrom": "Starting from {start}",
  "term.upTo": "Up to {end}",

  //Calendar
  "Calendar.monday": "Monday",
  "Calendar.tuesday": "Tuesday",
  "Calendar.wednesday": "Wednesday",
  "Calendar.thursday": "Thursday",
  "Calendar.friday": "Friday",
  "Calendar.saturday": "Saturday",
  "Calendar.sunday": "Sunday",
  "Calendar.january": "January",
  "Calendar.february": "February",
  "Calendar.march": "March",
  "Calendar.april": "April",
  "Calendar.may": "May",
  "Calendar.june": "June",
  "Calendar.july": "July",
  "Calendar.august": "August",
  "Calendar.september": "September",
  "Calendar.october": "October",
  "Calendar.november": "November",
  "Calendar.december": "December",

  //Terms specific to CRM system
  "crm.new": "New",
  "crm.scheduled": "Scheduled",
  "crm.complete": "Complete in Field",
  "crm.delivered": "Delivered",
  "crm.hold": "Hold",
  "crm.canceled": "Canceled",

  //UI elements (common UI buttons, interactions)
  "ui.saving": "Saving",
  "ui.loading": "Loading",
  "ui.addressDisplay": "{address}, {city}, {stateOrRegion} {postal}",
  "ui.logout": "Log Out",
  "ui.uploadItem": "Upload {item}",
  "ui.uploading": "Uploading",
  "ui.default": "Default",
  "ui.theme": "Theme",
  "ui.themeTemplate": "{name} Theme",
  "ui.goBack": "Go Back",
  "ui.close": "Close",
  "ui.delete": "Delete",
  "ui.cancel": "Cancel",
  "ui.deleting": "Deleting",
  "ui.save": "Save",
  "ui.details": "Details",
  "ui.uploads": "Uploads",
  "ui.photos": "Photos",
  "ui.images": "Images",
  "ui.reference": "Reference",
  "ui.unitNo": "{unit} #",
  "ui.select": "Select",
  "ui.ok": "OK",
  "ui.yes": "Yes",
  "ui.no": "No",
  "ui.next": "Next",
  "ui.view": "View",
  "ui.noResults": "No results",
  "ui.all": "All",
  "ui.totalCount": "{count} Total",
  "ui.monthRange": "{number} Months",
  "ui.yearRange": "{number} Years",
  "ui.viewAll": "View All",
  "ui.dataNotFound": "Data Not Found",
  "ui.edit": "Edit",
  "ui.download": "Download",
  "ui.available": "Available",
  "ui.settings": "Settings",
  "ui.print": "Print",
  "ui.areYouSure": "Are you sure?",
  "ui.name": "Name",
  "ui.size": "Size",
  "ui.uploadDate": "Upload date",
  "ui.uploadedBy": "Uploaded by",
  "ui.uploadAFile": "Upload a file",
  "ui.uploadFiles": "Upload files",
  "ui.uploadedFiles": "Uploaded files",
  "ui.videos": "Videos",
  "ui.pause": "Pause",
  "ui.update": "Update",
  "ui.updating": "Updating",
  "ui.description": "Description",
  "ui.type": "Type",
  "ui.label": "Label",
  "ui.add": "Add",
  "ui.resume": "Resume",
  "ui.start": "Start",
  "ui.status": "Status",
  "ui.open": "Open",
  "ui.you": "You",
  "ui.approve": "Approve",
  "ui.deny": "Deny",
  "ui.submitting": "Submitting",
  "ui.submit": "Submit",
  "ui.unassigned": "Unassigned",
  "ui.after": "After",
  "ui.before": "Before",
  "ui.files": "Files",
  "ui.links": "Links",
  "ui.remove": "Remove",
  "ui.list": "List",
  "ui.pin": "PIN",
  "ui.phoneNumber": "Phone number",

  "DetailPane.paging": "{current} of {total}",

  "Comments.signUp": "Sign up to leave a comment",
  "Comments.post": "Post",
  "Comments.inputPlaceholder": "Write a comment... (use @ to mention other people)",
  "Comments.title": "Comments",
  "Comments.singularTitle": "Comment",
  "Comments.edit": "Edit",
  "Comments.delete": "Delete",
  "Comments.postedOn": "Posted on {date}",
  "Comments.editedOn": "Edited on {date}",
  "Comments.edited": "Edited",
  "Comments.authorSaid": "{author} said:",
  "Comments.youSaid": "You said:",
  "Comments.youRepliedTo": "You replied to {author}:",
  "Comments.reply": "Reply",
  "Comments.save": "Save",
  "Comments.cancel": "Cancel",

  "sections.videoMap": "Video Map",
  "sections.er": "Emergency Response",
  "sections.bg": "Background",
  "sections.bs": "Basic",
  "sections.blank": "Blank",
  "sections.dump": "Dump",
  "sections.dimensions": "Dimesions",
  "sections.gas": "Gas",
  "sections.fire": "Fire",
  "sections.irrig": "Irrigation",
  "sections.land": "Landscape",
  "sections.light": "Light",
  "sections.loc": "Location",
  "sections.park": "Parking",
  "sections.pave": "Pavement",
  "sections.ps": "Pylon Sign",
  "sections.info": "Info",
  "sections.roof": "Roof",
  "sections.sat": "Satellite",
  "sections.sign": "Signage",
  "sections.space": "Space",
  "sections.water": "Water",
  "sections.hvac": "HVAC",
  "sections.elec": "Electric",

  //SearchTable Componnet
  "SearchTable.pageCounter": "Page {current} of {total}",

  //SearchTable Storybook
  "SearchTable.all": "All",
  "SearchTable.active": "Active",
  "SearchTable.inactive": "Inactive",
  "SearchTable.pending": "Pending",
  "SearchTable.published": "Published",
  "SearchTable.unpublished": "Unpublished",
  "SearchTable.draft": "Draft",
  "SearchTable.fname": "User",
  "SearchTable.city": "Location",
  "SearchTable.company": "Company",
  "SearchTable.status": "Status",
  "SearchTable.actions": "Actions",
  "SearchTable.actions.edit": "Edit",
  "SearchTable.actions.delete": "Delete",
  "SearchTable.actions.contact": "Contact",
  "SearchTable.actions.share": "Share",
  "SearchTable.addUser": "Add",
  "SearchTable.removeUser": "Remove",
  "SearchTable.permissions": "Select Permissions",
  "SearchTable.permissions.editor": "Editor",
  "SearchTable.permissions.viewer": "Viewer",
  "SearchTable.label": "All Vendors",
  "SearchTable.tabs.all": "All Users",
  "SearchTable.tabs.vendors": "Vendors",
  "SearchTable.tabs.all.placeholder": "Filter all users by name or location",
  "SearchTable.tabs.vendors.placeholder": "Filter vendors by name or location",

  "TestTable.filterSpaces.placeholder": "Filter all spaces by name or location",
  "TestTable.sections.spaces": "Spaces",
  "TestTable.sections.hvac": "HVAC",
  "TestTable.sections.fire": "Fire",
  "TestTable.sections.irrigation": "Irrigation",
  "TestTable.removeSpace": "Remove",
  "TestTable.addSpace": "Add Space",
  "TestTable.created": "Created"
}
