import React from 'react';

const Home = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_906:11781)">
        <path d="M19.8385 5.45493C19.7331 5.29269 19.5828 5.16453 19.406 5.08598L10.406 1.08598C10.2782 1.02913 10.1399 0.999756 10 0.999756C9.86012 0.999756 9.7218 1.02913 9.594 1.08598L0.594 5.08598C0.417181 5.16453 0.266948 5.29269 0.161515 5.45493C0.056081 5.61716 -2.45246e-05 5.80649 8.04175e-09 5.99998V20H2V6.64998L10 3.09398L18 6.64998V20H20V5.99998C20 5.80649 19.9439 5.61716 19.8385 5.45493Z" fill="currentColor" />
        <path d="M12 7.99998H16V12H12V7.99998Z" fill="currentColor" />
        <path d="M10 9.99998H4V20H10V9.99998Z" fill="currentColor" />
        <path d="M16 14H12V20H16V14Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_906:11781">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Home;