import React from 'react';

const ChevronLeft = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.99976 12C5.74376 12 5.48776 11.902 5.29276 11.707L0.292762 6.70701C-0.0982383 6.31601 -0.0982383 5.68401 0.292762 5.29301L5.29276 0.293006C5.68376 -0.0979941 6.31576 -0.0979941 6.70676 0.293006C7.09776 0.684006 7.09776 1.31601 6.70676 1.70701L2.41376 6.00001L6.70676 10.293C7.09776 10.684 7.09776 11.316 6.70676 11.707C6.51176 11.902 6.25576 12 5.99976 12Z" fill="currentColor" />
    </svg>

  )
}

export default ChevronLeft;