import React, {
  useState
} from 'react';
import PropTypes from 'prop-types';
import FileListFile from '../FileList/FileListFile'
import FileUploadHub from '../FileUploadHub/FileUploadHub';
import styles from './MultiFileUploadButton.module.css';

/**
 * Component in charge of rendering a button that, upon click,
 * opens a `FileUploadHub` modal. The button is customizable,
 * and the logic for uploading and deleting files is handled inside
 * whatever component implements this one.
 *
 * @example
 * ```js
 * const [files, setFiles] = useState([]);
 * const [isUploading, setIsUploading] = useState(false);
 *
 * const handleFileDeletion = (fileToDelete) {
 *   // Compare and delete files in `files`
 *   //   using `fileToDelete.id`
 * };
 * const handleSelectedFile = (newFile, raw) => {
 *   setIsUploading(true);
 *   // Upload file logic...
 *   newFile.uploadUser = // whatever the API returned
 *   newFile.url = // whatever the API returned
 *   setFiles([].concat(
      files,
      [newFile],
    ));
 * };
 *
 * <MultiFileUploadButton
 *   className="btn btn-primary"
 *   files={files}
 *   isUploading={isUploading}
 *   onDelete={handleFileDeletion}
 *   onFileSelected={handleSelectedFile}
 * >
 *   Upload Files <span role="img" aria-label="Folder Emoji">📂</span>
 * </MultiFileUploadButton>
 * ```
 */
const MultiFileUploadButton = ({
  className,
  children,
  errorMessage,
  files,
  isUploading,
  modalTitle,
  onClose,
  onDelete,
  onFileSelected,
  readOnly
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const preferredClasses = className ? className : styles.Button;

  const openUploaderModal = () => setIsModalOpen(true);
  const handleFileUploadHubClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <div>
      <button
        className={preferredClasses}
        disabled={isModalOpen}
        onClick={openUploaderModal}
      >
        {children}
      </button>
      <FileUploadHub
        errors={errorMessage}
        files={files}
        isUploading={isUploading}
        onClose={handleFileUploadHubClose}
        onFileSelected={onFileSelected}
        onDelete={onDelete}
        show={isModalOpen}
        readOnly={readOnly}
        title={modalTitle}
      />
    </div>
  );
};

MultiFileUploadButton.propTypes = {
  /**`className` prop for the main button. */
  className: PropTypes.string,
  /** Inner contents */
  children: PropTypes.node.isRequired,
  /** An error message that will be displaned in the file upload modal. */
  errorMessage: PropTypes.string,
  /** Array of already uploaded files. Should be instances of FileListFile */
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileListFile)),
  /** Flag to toggle the "uploading" state */
  isUploading: PropTypes.bool,
  /** Custom title for the uploader modal. */
  modalTitle: PropTypes.string,
  /** Called when the modal is closed. */
  onClose: PropTypes.func,
  /**
   * Called when the delete button gets clicked.
   * It receives the current FileListFile instance as an argument.
   */
  onDelete: PropTypes.func,
  /**
   * Called when the upload button gets clicked and the user selects a file.
   * It receives two arguments:
   * - An instance of FileListFile: this object can be appended to the array of
   *    files passed via the `files` prop. It also stores important file info that
   *    might want to store in the backend.
   * - The raw file selected by the user: this is the raw data to be uploaded to
   *    an external service like S3.
   */
  onFileSelected: PropTypes.func,
  /**
   * Controls the read-only state of the component. If `true`, it will
   * show static data and disable uploads or deletions.
   */
  readOnly: PropTypes.bool
};

MultiFileUploadButton.defaultProps = {
  files: [],
  isUploading: false,
  onClose: () => {},
  onDelete: () => {},
  onFileSelected: () => {},
  readOnly: false
};

export default MultiFileUploadButton;