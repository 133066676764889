import React from 'react';

const NotificationsBold = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1667 3.33317H11.3333V1.99984C11.3333 1.2645 10.7353 0.666504 10 0.666504H6C5.26467 0.666504 4.66667 1.2645 4.66667 1.99984V3.33317H1.83333C0.822 3.33317 0 4.15517 0 5.1665V12.8332C0 13.8445 0.822 14.6665 1.83333 14.6665H14.1667C15.178 14.6665 16 13.8445 16 12.8332V5.1665C16 4.15517 15.178 3.33317 14.1667 3.33317ZM6 1.99984H10V3.33317H6V1.99984ZM10.5 9.33317C10.5 9.4405 10.4813 9.5425 10.4687 9.64584L11.0693 10.1005C11.2033 10.2018 11.2407 10.3872 11.1567 10.5332L10.6173 11.4665C10.5333 11.6118 10.354 11.6725 10.1993 11.6072L9.508 11.3158C9.33933 11.4445 9.16 11.5578 8.962 11.6405L8.87 12.3745C8.84867 12.5412 8.70733 12.6665 8.53933 12.6665H7.46133C7.29333 12.6665 7.15133 12.5412 7.13067 12.3745L7.03867 11.6405C6.84067 11.5578 6.66067 11.4438 6.49267 11.3158L5.80133 11.6078C5.646 11.6725 5.46667 11.6125 5.38267 11.4665L4.844 10.5332C4.76 10.3878 4.79733 10.2025 4.93133 10.1005L5.532 9.64584C5.51867 9.5425 5.5 9.4405 5.5 9.33317C5.5 9.22584 5.51867 9.12384 5.53133 9.0205L4.93067 8.56584C4.79667 8.4645 4.75933 8.27917 4.84333 8.13317L5.38267 7.19984C5.46667 7.0545 5.646 6.99384 5.80067 7.05917L6.492 7.3505C6.66067 7.22184 6.84 7.1085 7.038 7.02584L7.13 6.29184C7.15133 6.12517 7.29267 5.99984 7.46067 5.99984H8.53867C8.70667 5.99984 8.84867 6.12517 8.86933 6.29184L8.96133 7.02584C9.15933 7.1085 9.33933 7.2225 9.50733 7.3505L10.1987 7.05917C10.3533 6.99384 10.5327 7.05384 10.6167 7.19984L11.156 8.13384C11.24 8.27917 11.2027 8.4645 11.0687 8.5665L10.468 9.02117C10.4813 9.12384 10.5 9.22584 10.5 9.33317Z" fill="currentColor" />
    </svg>

  );
}

export default NotificationsBold;