import React from 'react'
import TwoColumnContent from './../TwoColumnContent/TwoColumnContent'
import LeftColumn from './../TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from './../TwoColumnContent/RightColumn/RightColumn'
import TitleDescription from './../TitleDescription/TitleDescription'
import { AdminCheckbox } from "@project/components"
import { get } from './../../utils/utils'
import styles from './ToggleWithLabel.module.css'

const classNames = require('classnames')

const Columns = ({ id, getKey, getObject, updateFunction, titleLabel, titleDesc, index }) => {
  return (
    <>
      <LeftColumn>
        <TitleDescription
          title={index === 0 ? titleLabel : null}
          description={titleDesc} />
      </LeftColumn>
      <RightColumn boxShadow={false} border={false} padded={false}>
        <div className={classNames('d-flex pl-3 ml-2', index > 0 ? 'mt-n3' : '')}>
          <AdminCheckbox
            id={id}
            checked={get(getObject, getKey, false)}
            onChange={(e) => updateFunction(e, getObject, getKey)}
            customstyle={`${styles.checkbox} mt-n4`} />
        </div>
      </RightColumn>
    </>
  )
}
const ToggleWithLabel = ({ titleLabel, titleDescriptions, ids, getObject, getKeys, updateFunction }) => {
  return (
    <TwoColumnContent>
      {ids.map((id, i) => (
        <Columns id={id} key={id} getKey={getKeys[i]} getObject={getObject} updateFunction={updateFunction} titleLabel={titleLabel} titleDesc={titleDescriptions[i]} index={i} />
      ))}
    </TwoColumnContent>
  )
}

export default ToggleWithLabel