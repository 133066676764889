import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import DocIcon from '../assets/images/doc-icon';
import FileListFile from '../FileList/FileListFile';
import MultiFileUploadButton from '../MultiFileUploadButton/MultiFileUploadButton';
import styles from './DocumentUploader.module.css';

const DocumentUploader = ({
  axiosInstance,
  axiosCancelTokenFactory,
  deleteRoute,
  documents,
  extraPayloadData,
  modalTitle,
  onClose,
  readOnly,
  taskId,
  uploadRoute,
  ownerType = 'InspectionTask',
  formatResponse
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [documentCache, setDocumentCache] = useState(documents);

  const docIconClasses = useMemo(() => classNames({
    [styles.docIconEmpty]: documentCache.length === 0,
    [styles.docIconFull]: documentCache.length > 0
  }), [documentCache.length]);

  const taskDocumentFiles = useMemo(() => {
    const transformFileJson = fileJson => new FileListFile({
      id: fileJson.id,
      name: fileJson.filename,
      size: fileJson.filesize,
      uploadDate: moment.utc(fileJson.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS').local().valueOf(),
      uploadUser: fileJson.createdBy,
      url: fileJson.url
    });

    return documentCache.filter(Boolean).map(transformFileJson);
  }, [documentCache]);

  useEffect(() => {
    const source = axiosCancelTokenFactory.source();

    const uploadFile = async () => {
      setIsUploading(true);
      setErrorMessage(null);

      const data = new FormData();
      data.append('files', fileToUpload);
      data.append('ownerId', taskId);
      data.append('ownerType', ownerType);

      for (const payloadKey in extraPayloadData) {
        data.append(payloadKey, extraPayloadData[payloadKey]);
      }

      try {
        const result = await axiosInstance.post(uploadRoute, data, {
          cancelToken: source.token
        });

        let record = (result.record || result.records).pop()
        if (record && formatResponse) record = formatResponse(record)
        setDocumentCache([...documentCache, record]);
      } catch (e) {
        setErrorMessage(e.message);
      } finally {
        setFileToUpload(null);
        setIsUploading(false);
      }
    };

    if (fileToUpload) {
      uploadFile();
    }

    return () => {
      source.cancel();
    };
  }, [axiosInstance, axiosCancelTokenFactory, documentCache, extraPayloadData, fileToUpload, taskId, uploadRoute, ownerType, formatResponse]);

  useEffect(() => {
    const source = axiosCancelTokenFactory.source();

    const deleteFile = async () => {
      setErrorMessage(null);

      try {
        await axiosInstance.delete(`${deleteRoute}/${fileToDelete.id}`);
        setDocumentCache(documentCache.filter(file => file.id !== fileToDelete.id));
      } catch (e) {
        setErrorMessage(e.message);
      } finally {
        setFileToDelete(null);
      }
    };

    if (fileToDelete) {
      deleteFile();
    }

    return () => {
      source.cancel();
    };
  }, [axiosInstance, axiosCancelTokenFactory, deleteRoute, documentCache, fileToDelete]);

  const handleFileDeletion = setFileToDelete;
  const handleSelectedFile = (_, raw) => setFileToUpload(raw);
  const handleUploaderClose = () => syncFileBundleChanges();
  const syncFileBundleChanges = () => onClose(documentCache);

  return (
    <MultiFileUploadButton
      className={styles.documentsUploaderButton}
      errorMessage={errorMessage}
      files={taskDocumentFiles}
      isUploading={isUploading}
      modalTitle={modalTitle}
      onClose={handleUploaderClose}
      onDelete={handleFileDeletion}
      onFileSelected={handleSelectedFile}
      readOnly={readOnly}
    >
      <DocIcon height={32} className={docIconClasses} data-testid="DocumentUploader" />
    </MultiFileUploadButton>
  );
};

DocumentUploader.propTypes = {
  /**
   * Axios instance customized via `axios.create`.
   * Required to upload and delete files.
   */
  axiosInstance: PropTypes.func.isRequired,
  /** An axios cancel token. */
  axiosCancelTokenFactory: PropTypes.func.isRequired,
  /** Array of documents that were previously uploaded, or empty array. */
  documents: PropTypes.arrayOf(PropTypes.object),
  /** API endpoint for doc deletion. */
  deleteRoute: PropTypes.string.isRequired,
  /** Any extra stuff to be sent to the API via axios. */
  extraPayloadData: PropTypes.object,
  /** Custom title for the uploader modal. */
  modalTitle: PropTypes.string,
  /**
   * Called when the uploader modal gets closed. It
   * receives an updated version of the `documents` prop
   * if there were new uploads or deletions.
   *
   * @example
   * ```js
   * onClose={(updatedDocuments) => console.log(updatedDocuments)}
   * ```
   */
  onClose: PropTypes.func,
  /**
   * Controls the read-only state of the component. If `true`, it will
   * show static data and disable uploads or deletions.
   */
  readOnly: PropTypes.bool,
  /** The ID for the task these documents belong to. */
  taskId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /** API endpoint for doc creation. */
  uploadRoute: PropTypes.string.isRequired,
  /* Owner Type (i.e. WorkOrder, InspectionTask, etc.) */
  ownerType: PropTypes.string.isRequired
};

DocumentUploader.defaultProps = {
  documents: [],
  extraPayloadData: {},
  onClose: () => {},
  readOnly: false,
  ownerType: 'InspectionTask'
};

export default DocumentUploader;