import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import FileListFile from '../FileList/FileListFile';
import styles from './ActionButtons.module.css';

/**
 * This component is in charge of rendering a button panel for each
 * action supported by the FileUploadHub.
 * These actions are:
 * - Download: opens a new tab with the URL of the current file
 * - Delete: calls an external function with the current file as an argument
 *
 * This component uses the `react-intl` hook to translate button labels,
 * so an Intl context is required in the app.
 */
const ActionButtons = ({
  file,
  onDelete,
  readOnly
}) => {
  const [deleteMode, setDeleteMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const onCancelDeleteButtonClick = () => setDeleteMode(false);
  const onConfirmDeleteButtonClick = () => {
    setIsDeleting(true);
    onDelete(file);
  };
  const onDeleteButtonClick = () => setDeleteMode(true);
  const onDownloadFileButtonClick = () => window.open(
    file.url,
    '_blank',
    'noopener,noreferrer'
  );

  const MainButtons = () => (
    <>
      <Button
        variant="link"
        onClick={onDownloadFileButtonClick}
      >{t('ui.download')}</Button>
      {!readOnly && (
        <Button
          variant="danger"
          onClick={onDeleteButtonClick}
        >{t('ui.delete')}</Button>
      )}
    </>
  );

  const DeleteButtons = () => (
    <>
      <span>{t('ui.areYouSure')}</span>
      <Button
        onClick={onConfirmDeleteButtonClick}
        variant="danger"
        className={styles.confirmationButton}
      >{t('ui.yes')}</Button>
      <Button
        onClick={onCancelDeleteButtonClick}
        variant="secondary"
        className={styles.confirmationButton}
      >{t('ui.no')}</Button>
    </>
  );

  return (
    <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
      {isDeleting && <span>Deleting...</span>}
      {!deleteMode && !isDeleting && <MainButtons />}
      {deleteMode && !isDeleting && <DeleteButtons />}
    </div>
  );
};

ActionButtons.propTypes = {
  /**
   * Actions in this component apply to this file.
   * Should be an instance of `FileListFile`.
   */
  file: PropTypes.instanceOf(FileListFile).isRequired,
  /**
   * Called when the delete confirmation button is clicked.
   * Receives the `file` prop as an argument.
   */
  onDelete: PropTypes.func,
  /**
   * Controls the read-only state of the component. If `true`, it will
   * show static data and disable uploads or deletions.
   */
   readOnly: PropTypes.bool
};

ActionButtons.defaultProps = {
  onDelete: () => {},
  readOnly: false
};

export default ActionButtons;