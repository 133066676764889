import React, { useState, useEffect } from 'react';
import { MultipleMediaUpload, AdminModal } from '@project/components';
import axios from '../../utils/axios';
import { CancelToken } from 'axios';
import { useIntl } from 'react-intl';

/**
 * This component is to open a popup that will handle file uploads attaching to the top level of a property
 */
export default function PropertyImageUpload({
  dialogClassName,
  propertySettings,
  activeUploadModal,
  setPropertySettings,
  setActiveUploadModal,
  setErrors,
  makeThumbnail,
  makeStandard,
  title
}) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [isFilesUploadSaving, setIsFilesUploadSaving] = useState(false);
  const [isFilesUploadDeleting, setIsFilesUploadDeleting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);

  // handles photo saving for floorplans and images
  useEffect(() => {
    const source = CancelToken.source();
    const handleSave = async (skipClose = false) => {
      setErrors(null);
      const data = new FormData();
      if (uploadedFiles) {
        uploadedFiles.forEach(file => data.append('file', file));
      } else {
        setIsFilesUploadSaving(false);
        return;
      }
      data.append('standard', makeStandard);
      data.append('thumbnail', makeThumbnail);
      try {
        const result = await axios.put(`/properties/upload/${propertySettings.company}/${propertySettings.name}/${activeUploadModal}`, data, {
          cancelToken: source.token
        });
        // setDefaults(spaceProfileDefaults, result.data.record);
        setPropertySettings(result.data);
        setUploadedFiles(null);
      } catch (e) {
        console.log(e);
        return setErrors({ generic: `${t('ui.general')}: ${e.errors || e}` });
      } finally {
        setIsFilesUploadSaving(false);
        if (!skipClose) setActiveUploadModal(null);
      }
    };

    if (isFilesUploadSaving) handleSave();

    return () => {
      source.cancel();
      setUploadedFiles(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilesUploadSaving, propertySettings, setErrors]);

  // handles deleting photos for floorplans and images
  const onDeletePhoto = async (photo, index) => {
    const data = { index: index };
    setIsFilesUploadDeleting(true);
    try {
      let result = await axios.delete(`/properties/upload/${propertySettings.company}/${propertySettings.name}/${activeUploadModal}`, { data: data });
      // setDefaults(spaceProfileDefaults, result.data.record);
      setPropertySettings(result.data);
      setUploadedFiles(null);
      propertySettings[activeUploadModal].splice(index, 1);
      setPropertySettings({ ...propertySettings });
    } catch (e) {
      console.log(e);
      return setErrors({ generic: `${t('ui.general')}: ${e.errors || e}` });
    } finally {
      setIsFilesUploadDeleting(false);
    }
  };

  const mediaUploadMediaUrls = React.useMemo(() => propertySettings[activeUploadModal] && propertySettings[activeUploadModal].map(_ => _.original || _), [propertySettings, activeUploadModal]);

  return (
    <AdminModal
      title={title}
      show={!!activeUploadModal}
      onHide={() => setActiveUploadModal(null)}
      onSave={() => setIsFilesUploadSaving(true)}
      isSaving={isFilesUploadSaving}
      readOnly={false}
      dialogClassName={dialogClassName}
    >
      <MultipleMediaUpload
        mediaUrls={mediaUploadMediaUrls}
        onDeleteMedia={onDeletePhoto}
        onSelectedMediaFiles={setUploadedFiles}
        readOnly={false}
        isSaving={isFilesUploadSaving}
        isDeleting={isFilesUploadDeleting}
        type="image"
      />
    </AdminModal>
  )
}
