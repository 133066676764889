import React, { useEffect, useState, useContext } from 'react';
import axios from '../../utils/axios';
import Axios, { CancelToken } from 'axios'
import { RootContext } from '../../hoc/RootContext/RootContext';
import styles from './PropertySelectAll.module.css';
import AdminSelect from '@project/components/src/AdminSelect/AdminSelect';
import { useIntl } from 'react-intl';
import { get } from '../../utils/utils';

const PropertySelectAll = (props) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { setGlobalProperties, currentProperty, setCurrentProperty, currentUser, currentCompany, globalProperties, setIsLoadingGlobalProperties } = useContext(RootContext);

  let preLoadedProperties = [];
  let currentCompanyValue = get(currentCompany, 'slug');

  if (globalProperties && globalProperties.length) {
    preLoadedProperties = currentCompany && currentCompany.slug ? globalProperties.filter(_ => _.companySlug === currentCompany.slug) : globalProperties;
  }

  const [properties, setProperties] = useState(preLoadedProperties);
  const [isLoading, setIsLoading] = useState(false);
  const [init, setInit] = useState(true);

  const defSelectionValue = 'All';
  const defSelectionLabel = t('ui.all');

  const setPropertiesAndProcessCallback = (_properties) => {
    setProperties(_properties);
    if (props.callback) props.callback(_properties);
  }
  useEffect(() => {
    const source = CancelToken.source();
    let isMounted = true;

    const fetchProperties = async () => {
      setIsLoading(true);
      try {
        let list;
        if (globalProperties && globalProperties.length) {
          setIsLoading(false);
          return;
        } else {
          setIsLoadingGlobalProperties(true);
          const response = await axios.get(`/menu`, { cancelToken: source.token });
          if (!response) setProperties([]);
          list = processResponse(response.data.data.companies);
        }
        // process the response before setting the properties because the response contains a lot of unnecessary information for our use case
        let _currentCompany = (currentCompany && currentCompany.value) || currentUser.company;
        let propertiesForCurrentCompany = list.filter((property) => property.company_name === _currentCompany);
        setPropertiesAndProcessCallback(propertiesForCurrentCompany);
        // set properties in the app context for accessing elsewhere
        setGlobalProperties(list);
        if (props.defaultProperty && currentCompany) {
          let defaultPropertyValue = list.find(_ => _.slug === props.defaultProperty);
          if (currentCompany.slug === get(props, 'defaultProperty.companySlug')) {
            setCurrentProperty(defaultPropertyValue);
          }
        }

      } catch (e) {
        if (Axios.isCancel(e)) {
          // request is cancelled
        } else {
          throw e;
        }
      }
      if (isMounted) {
        setIsLoading(false);
        setIsLoadingGlobalProperties(false);
      }
    }
    const processResponse = (data) => {
      if (!data) return [];
      return data.map(company => {
        let children = company.children;
        children.forEach(child => {
          // set the company name on the property record
          child.company_name = company.company_name;
          child.company_display_name = company.company_display_name;
        });
        return children;
      })
        .reduce((acc, curr) => acc.concat(curr), []).sort((a, b) => (a.displayName || a.name).trim().localeCompare((b.displayName || b.name).trim()));
    }

    // fetch list of properties from the api
    fetchProperties();

    return () => {
      source.cancel();
      isMounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setGlobalProperties])

  useEffect(() => {
    if (!init) setCurrentProperty(null);
    setInit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyValue])

  useEffect(() => {
    let propertiesForCurrentCompany = globalProperties.filter((property) => property.companySlug === (currentCompany && currentCompany.slug));
    setPropertiesAndProcessCallback(propertiesForCurrentCompany);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalProperties])

  /**
   * This effect runs when you select a company
   */
  useEffect(() => {
    let _properties = globalProperties;
    if (currentCompany && currentCompany.value !== 'All') {
      let propertiesForCurrentCompany = globalProperties.filter((property) => property.company_name === (currentCompany && currentCompany.value));
      _properties = propertiesForCurrentCompany;
    }
    selectProperty(getSelectedPropertyRecord(defSelectionValue));
    setPropertiesAndProcessCallback(_properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany])

  /**
   * Function called on choosing a property from the Properties dropdown
   * @param {Object || String} p The selected property database record
   */
  const selectProperty = async (p) => {
    if (!p) return;
    setCurrentProperty(p);

    if (props.callback) {
      if (!p) p = properties;
      props.callback(Array.isArray(p) ? p : [p]);
    }
  }

  /**
   * Populate the property select dropdown list
   * @param {Object} properties The list of properties from the database
   */
  const propertyList = (properties) => {
    properties = properties.map((_, i) => {
      return {
        label: _.displayName || _.name, value: `${_.name}_${i}`, name: _.name
      }
    });
    if (props.selectAll !== false) properties.unshift({ label: defSelectionValue, value: defSelectionValue });
    return properties;
  }

  /**
   * Get the selected property record
   * @param {String} name The property name
   */
  const getSelectedPropertyRecord = (name) => name ? properties.find(_ => _.name === name) : defSelectionValue;

  /**
   * Gets the value to be shown as the currently selected value in the property select dropdown
   * @param {Object} selected The selected property record
   */
  const getValue = (selected) => {
    if (props.selectAll === false && !selected) return { label: `${t('ui.select')}...`, value: t('ui.select'), name: t('ui.select') };
    if (!selected) return { label: defSelectionLabel, value: defSelectionValue, name: defSelectionLabel };
    else return propertyList(properties).find(_ => _.name === selected.name);
  }

  return (
    <>
      {props.show &&
        <AdminSelect
          id="property-select-all"
          identifierClass={'data-property-select-all'}
          isLoading={isLoading}
          label={t('term.properties')}
          propStyles={styles}
          options={propertyList(properties)}
          value={getValue(currentProperty)}
          onChange={selected => selectProperty(getSelectedPropertyRecord(selected.name))}
        />
      }
    </>
  )
}

export default PropertySelectAll;