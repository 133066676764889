import React, { useState, useContext, useEffect } from 'react';
import { Alerts, StatusLegend, PanZoom } from '@project/components';
import Title from '../../components/Title/Title';
import { useIntl } from 'react-intl';
import { useSpaces } from './hooks.js';
import { RootContext } from './../../hoc/RootContext/RootContext';
import styles from './LeaseRadarDetails.module.css';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import DashboardFilters from '../../components/DashboardFilters/DashboardFilters';
import { CardDeck } from 'react-bootstrap';
import MainCard from './../../components/MainCard/MainCard.js';
import { getRendererRequestPayload } from './../../services/rendererRequestService'
import axios from './../../utils/axios'
import Axios, { CancelToken } from 'axios';
import { ReactSVG } from 'react-svg';
import { Spinner } from 'react-bootstrap';
import Dot from '../../assets/images/dot';
import { get } from '../../utils/utils';
import moment from 'moment';
import { useCompanySettings } from '../../utils/hooks'
import TenantCard from '../../components/TenantCard/TenantCard.js';

const LeaseRadarDetails = () => {
  const [propertyMapUrl, setPropertyMapUrl] = useState("")
  const [popupPosition, setPopupPosition] = useState(null)
  const [hoveredText, setHoveredText] = useState(null)
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propertyMapLoading, setPropertyMapLoading] = useState(false);
  const [hoveredGuid, setHoveredGuid] = useState(false);
  const [spaceProfiles, setSpaceProfiles] = useState(null)
  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  const { mobileView, currentProperty, currentCompany } = useContext(RootContext);

  let spaces = useSpaces({ setLoading, setErrors, company: currentCompany, property: get(currentProperty, 'name'), propertySlug: get(currentProperty, 'slug') });

  const currentCompanyValue = get(currentCompany, 'value', '');
  const currentPropertyValue = get(currentProperty, 'name', '');
  const currentCompanySlugValue = get(currentCompany, 'slug', '');
  const [companySettings] = useCompanySettings(currentCompanyValue, true)

  useEffect(() => {
    const source = CancelToken.source()
    /**
     * Make a renderer service request to fetch the property map svg
     */
    async function requestPropertyMap() {
      setPropertyMapLoading(true);
      try {
        const showLandscape = get(companySettings, 'idDigitalSettings.showLandscapeFillsOnLeaseRadar', false)
        const payload = await getRendererRequestPayload({
          company: currentCompany?.value,
          name: currentProperty.name,
          width: currentProperty.width,
          height: currentProperty.height,
          measurement_unit: currentProperty.measurement_unit
        }, currentCompany.slug, currentProperty.slug, showLandscape)
        let res = await axios.post(process.env.REACT_APP_RENDERER_HOST, payload, { cancelToken: source.token })
        setPropertyMapUrl(res.data.url)
      } catch (e) {
        if (Axios.isCancel(e)) {
          // request is cancelled
        } else {
          throw e
        }
      }

      setPropertyMapLoading(false);
    }
    if (currentCompany && currentProperty && companySettings) requestPropertyMap()
    return () => source.cancel()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyValue, currentPropertyValue, companySettings])

  useEffect(() => {
    const source = CancelToken.source()
    async function fetchSpaceProfiles() {
      setLoading(true);
      try {
        const res = await axios.get(`/space-profiles/leasing/?company=${currentCompanyValue}&property=${currentPropertyValue}&companySlug=${currentCompanySlugValue}`, { cancelToken: source.token })
        let records = res.data.records
        records = records.filter(_ => _.status === 'published')
        if (records) {
          setSpaceProfiles(records)
        }
      } catch (e) {
        setErrors([e.message])
      }
      setLoading(false);
    }
    if (currentCompany && currentProperty) fetchSpaceProfiles()
    return () => source.cancel()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyValue, currentPropertyValue, currentCompanySlugValue])

  const MouseOverPopup = ({ text, space }) => {
    if (!text || !popupPosition || !space) {
      return null
    }
    return ( 
      <div className={`${styles.popup} d-flex align-items-center justify-content-start`}
        style={popupPosition ? {
          left: popupPosition.x,
          top: popupPosition.y
        } : {}}>
        <div className={styles.popupImage}>
          <img src={space.leasing_image} alt="leasing info" />
        </div>
        <div>
          <div>
            <div className={styles.popupSpaceNumber}><span>{space.tenantName} </span>{space.unit}</div>
            <div><span>{t('term.sqFootage')} - </span> {text} {t('unit.squareFootageVariant')}</div>
            {
              space.isLeaseExpired === 1 ? <div className={styles.popupSpaceNumber}><span>{t("LeaseRadarDetails.tenantCard.available")}</span></div> :
              <div className={styles.popupSpaceNumber}><span>{t("LeaseRadarDetails.popup.leaseExpiring")} - </span><>{moment(space.leaseEnd).format("MM/DD/YYYY")}</></div>
            }
          </div>
          <div className={styles.status}>
            <div data-testid="status" style={space.statusColor}>
              <div><Dot /></div>
              <div className="m-0" data-testid="status-label">{space.leaseExpirationFormatted}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const mouseHoverHandler = (elm, e) => {
    let mapContainer = document.getElementById('map-wrapper').getBoundingClientRect();
    let mapX = mapContainer.x;
    let mapY = mapContainer.y;
    let handler;
    if (!!elm.attributes['data-space-sqfootage']) {
      let x = e.clientX - mapX + 20;
      let y = e.clientY - mapY - 20;

      if (e.type === 'touchstart') {
        x = e.targetTouches[0].clientX - mapX + 10;
        y = e.targetTouches[0].clientY - mapY - 10;

        handler = setTimeout(() => {
          setPopupPosition(null)
        }, 1500);
      }
      let sqft = +elm.attributes['data-space-sqfootage'].value
      let guid = elm.attributes['data-guid'].value;
      setHoveredText(sqft.toLocaleString('en-US'));
      setHoveredGuid(guid)
      setPopupPosition({ x, y });
      if (e.type !== 'touchstart') {
        clearTimeout(handler)
      }
    } else {
      if (e.type === 'touchstart') {
        setPopupPosition(null);
      }
      setPopupPosition(null)
    }
  }

  const addSpaceMouseHoverHandler = (space) => {
    space.addEventListener('mousemove', mouseHoverHandler.bind(this, space), { passive: true });
    space.addEventListener('touchstart', mouseHoverHandler.bind(this, space), { passive: true });
    return _ => _ => { space.removeEventListener('mousemove', mouseHoverHandler); space.removeEventListener('touchstart', mouseHoverHandler); };
  }

  const addNonSpaceMouseHoverHandler = (elm) => {
    elm.addEventListener('mousemove', mouseHoverHandler.bind(this, elm), { passive: true });
    elm.addEventListener('touchstart', mouseHoverHandler.bind(this, elm), { passive: true });
    return _ => _ => { elm.removeEventListener('mousemove', mouseHoverHandler); elm.removeEventListener('touchstart', mouseHoverHandler); };
  }

  const isExpiring = (_, months = 6) => {
    const currentDate = moment();
    const leaseEndDate = moment(_.leaseEnd);

    // Check if the lease has already expired
    if (leaseEndDate.isBefore(currentDate)) return false;

    const monthsUntilLeaseExpiration = leaseEndDate.diff(currentDate, 'months');

    return monthsUntilLeaseExpiration < months;
  }

  const downloadMap = () => {
    const svg = document.getElementById('map-container').innerHTML;
    const blob = new Blob([svg.toString()]);
    const element = document.createElement("a");
    const dateObj = new Date()
    const month = dateObj.toLocaleString("default", { month: "short" });
    const year = dateObj.getFullYear();
    element.download = `${currentCompany.slug}_${currentProperty.slug}_${month}-${year}-leasing-map.svg`;
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
  }
  return (
    <PageContentContainer>
      <Title title={t('LeaseRadar.title')} backRoute={`/lease-radar${currentCompany && currentCompany.slug ? `?company=${currentCompany.slug}` : ''}${currentProperty && currentProperty.slug ? `&property=${currentProperty.slug}` : ''}`} />
      {errors.length > 0 ?
        <div className="w-100">
          <Alerts messages={errors} close={() => setErrors([])} />
        </div>
        : null}
      <div className={`${styles.details} px-4 pb-5 pt-3`}>
        <div className="d-flex justify-content-between w-100 pb-4"><div className={styles.title}>Lease Radar</div>{propertyMapUrl && currentProperty && !propertyMapLoading && <div onClick={downloadMap} className={styles.export}>Export</div>}</div>
        <DashboardFilters
          show={true}
          companySelectAll={false}
          propertySelectAll={false}
          defaultCompany={currentCompany?.value}
        />
        {currentProperty &&
          <>
            <div className={`${styles.separator} w-100 mt-1 mb-4`}></div>
            <div>
              <CardDeck>
                <MainCard isLoading={loading} title={t('LeaseRadarDetails.vacant').toUpperCase()} totalCount={spaces.filter(_ => _.isLeaseExpired === 1).length || '0'} type={intl.formatMessage({ id: 'ui.totalCount' }, { count: spaces.length })} animate={true} linkTitle={t('ui.viewAll')} externalLink={`${process.env.REACT_APP_RPM_HOST}/${currentCompany?.value}/${currentProperty.name}/index?company=${currentProperty.companySlug}&property=${currentProperty.slug}`} />
                <MainCard isLoading={loading} title={t('LeaseRadarDetails.expiration').toUpperCase()} totalCount={spaces.filter(_ => isExpiring(_)).length || '0'} type={intl.formatMessage({ id: 'ui.totalCount' }, { count: spaces.length })} linkTitle={t('ui.viewAll')} externalLink={`${process.env.REACT_APP_RPM_HOST}/${currentCompany?.value}/${currentProperty.name}/index?company=${currentProperty.companySlug}&property=${currentProperty.slug}`} />
                <MainCard isLoading={loading} title={t('LeaseRadarDetails.360').toUpperCase()} totalCount={spaces.filter(_ => _.status === 'published').length || '0'} type={intl.formatMessage({ id: 'ui.totalCount' }, { count: spaces.length })} linkTitle={t('ui.viewAll')} externalLink={`${process.env.REACT_APP_RPM_HOST}/${currentCompany?.value}/${currentProperty.name}/space?mode=sp&pg=1`} />
              </CardDeck>
            </div>
            {propertyMapUrl && !propertyMapLoading ?
              <>
                <StatusLegend
                  mobileView={mobileView}
                  statuses={[
                    { color: '#3535BB', label: intl.formatMessage({ id: 'ui.yearRange' }, { number: '> 10' }) },
                    { color: '#9A4FFC', label: intl.formatMessage({ id: 'ui.yearRange' }, { number: '7 - 10' }) },
                    { color: '#35A4BF', label: intl.formatMessage({ id: 'ui.yearRange' }, { number: '5 - 6' }) },
                    { color: '#33BE6D', label: intl.formatMessage({ id: 'ui.yearRange' }, { number: '3 - 4' }) },
                    { color: '#81C429', label: intl.formatMessage({ id: 'ui.yearRange' }, { number: '1 - 2' }) },
                    { color: '#C5C02E', label: intl.formatMessage({ id: 'ui.monthRange' }, { number: '6 - 12' }) },
                    { color: '#FF7A00', label: intl.formatMessage({ id: 'ui.monthRange' }, { number: '3 - 6' }) },
                    { color: '#CE1514', label: intl.formatMessage({ id: 'ui.monthRange' }, { number: '< 3' }) }
                  ]}
                  className="mt-4" />
                <div className={`${styles.tenantInfoContainer} my-4`}>
                  <div className={styles.tenantListContainer}>
                    {
                      spaces.map((value, idx) => (
                        <TenantCard key={idx} space={value} spaceProfiles={spaceProfiles}/>
                      ))
                    }
                  </div>
                  <div className={styles.map} id="map-wrapper">
                    <MouseOverPopup text={hoveredText} space={spaces && spaces.find(_ => _.assetGuid === hoveredGuid)} />
                    <PanZoom>
                      <ReactSVG src={propertyMapUrl}
                        id="map-container"
                        className={`${styles.svgContainer} ${styles.mapSizing}`}
                        loading={() => <Spinner className={styles.spinnerCenter} animation="grow" variant="primary" />}
                        beforeInjection={(svg) => {
                          svg.setAttribute('style', `height: 120%;`);
                          svg.setAttribute('viewBox', '0 0 930 728');
                        }}
                        afterInjection={(err, svgString) => {
                          if (!svgString) return;
                          const spaces = svgString.querySelectorAll('.transform-container>g.layer-space-shapes>g')
                          const otherSvgElements = svgString.querySelectorAll('.transform-container>g:not(.layer-space-shapes)')
                          if (!spaces || !otherSvgElements) return
                          spaces.forEach(_ => addSpaceMouseHoverHandler(_))
                          otherSvgElements.forEach(_ => addNonSpaceMouseHoverHandler(_))
                          if (err) return
                        }}
                      />
                    </PanZoom>
                  </div>
                </div>
              </>
              :
              !loading && <div className="w-100 d-flex justify-content-center"><Spinner className="m-4" animation="grow" variant="primary" /></div>}
          </>
        }
      </div>
    </PageContentContainer>
  )
}

export default LeaseRadarDetails;