import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { ACL } from '../constants'
import useTranslations from "./useTranslations"
import PropTypes from 'prop-types'

let source;

//Get all active companies for company
const useMenu = ({
  currentCompany, 
  company, 
  setIsLoading = () => {}, 
  requireCompany = false, 
  redirectOnFail, 
  toastError = () => {},
  axios,
  CancelToken, 
  isCancel
}) => {
  const [properties, setProperties] = useState([])
  const [t] = useTranslations()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchProperties = async () => {
      let cancelToken
      if (source) source.cancel()
      source = CancelToken.source()
      cancelToken = source.token

      setIsLoading(true)
      try {
        let list
        let result = await axios.get(`/menu?company=${company}`, {
          cancelToken,
          headers: {
            [ACL.HTTP_HEADERS.IDP_COMPANY]: currentCompany?.name || currentCompany?.value
          }
        })

        if (result.success || result?.data?.success) {
          list = processResponse(result?.data?.data?.companies || result?.data?.companies)
          setProperties(list.filter(_ => _.guid))
        } else {
          toastError(t('hooks.useMenu.error'))
          if (redirectOnFail) navigate(redirectOnFail)
        }
        // set properties in the app context for accessing elsewhere
        setProperties((list || []).filter(_ => _.guid))
      } catch (e) {
        if (!isCancel(cancelToken.reason)) {
          toastError(t('hooks.useMenu.error'))
          if (redirectOnFail) navigate(redirectOnFail)
        }
      } finally {
        if (!isCancel(cancelToken.reason)) setIsLoading(false)
      }
    }

    const processResponse = (data) => {
      if (!data) return []
      return data.map(company => {
        let children = company.children
        children.forEach(child => {
          // set the company name on the property record
          child.company_name = company.company_name
        })
        return children
      })
        .reduce((acc, curr) => acc.concat(curr), []).sort((a, b) => (a.displayName || a.name).trim().localeCompare((b.displayName || b.name).trim()))
    }

    if (!requireCompany || company) fetchProperties()

    return () => {
      if (source) source.cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return properties
}

useMenu.propTypes = {
  currentCompany: PropTypes.object, 
  company: PropTypes.string, 
  setIsLoading: PropTypes.bool, 
  requireCompany: PropTypes.bool, 
  redirectOnFail: PropTypes.string, 
  toastError: PropTypes.func,
  axios: PropTypes.func,
  CancelToken: PropTypes.func, 
  isCancel: PropTypes.func
}

useMenu.defaultProps = {
  setIsLoading: () => {}, 
  requireCompany: false, 
  toastError: () => {}
}

export default useMenu