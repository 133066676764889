import React from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const PrivateRouteOutlet = () => {
  return (
    <PrivateRoute>
      <Layout>
        <Outlet />
      </Layout>
    </PrivateRoute>);
}

export default PrivateRouteOutlet;