import React from 'react';
import { Navbar, Form, Image } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import logo from './../../assets/images/logo.svg';
import styles from './Layout.module.css';
import './../../assets/css/CommonStyle.css';
import MenuDrawer from './../../components/MenuDrawer/MenuDrawer';
import { RootContext } from "../../hoc/RootContext/RootContext";
import { AdminToast } from '@project/components';
import { useLocation } from 'react-router-dom';
import routePermissionsDenied from '../../utils/routePermissionsDenied';
import { get } from '../../utils/utils';
// import { getCookieOptions } from "../../utils/utils";
// import Cookies from "universal-cookie";
import Hamburger from '../../assets/images/hamburger';

const classNames = require('classnames');

export default ({ children }) => {

  const {
    currentUser,
    showToast,
    setShowToast,
    setIsAdminMenuOpen,
    largeView,
    isAdminMenuOpen
  } = React.useContext(RootContext);

  const location = useLocation();

  if (currentUser && get(routePermissionsDenied, `${location.pathname}`, []).includes(currentUser.type)) return <Navigate to="/dashboard" />

  // const onLogout = () => {
  //   const cookies = new Cookies();
  //   let copts = getCookieOptions();
  //   cookies.remove('auth', { path: copts.path, domain: copts.domain });
  //   cookies.remove('logged-in', { path: copts.path, domain: copts.domain });
  //   window.location.href = `/login`;
  // };

  if (!currentUser) return null;

  return (
    <>
      <div className={`${styles.nav} ${styles.navBar}`}>
        <Navbar expand="lg">
          <Navbar.Brand>
            <Link to="/dashboard" className={styles.userName}><Image src={logo} /></Link>
          </Navbar.Brand>
          <Form inline>
            {/* <Form.Control
            type="text"
            placeholder="Search"
            className={classNames(styles.menuSearch, 'mr-sm-2 rounded-0 bg-gray font16 border-0')}
          /> */}
          </Form>
          <Navbar.Toggle className={styles.collapseMenu} aria-controls="basic-navbar-nav" onClick={() => setIsAdminMenuOpen((prevValue) => !prevValue)}>
            <Hamburger />
          </Navbar.Toggle>
          <AdminToast variant={showToast.variant} onClose={() => setShowToast(false)} show={!!showToast} message={showToast.message} />
        </Navbar>
      </div>
      <div className={`d-flex pb-2 clearfix ${styles.nav} ${styles.menuDrawer}`}>
        <MenuDrawer />
        <section id="appBody" className={classNames(styles.rightCol, 'flex-grow-1 overflow-auto p-0 pb-4 clearfix')}>
          {children}
          <div style={{ clear: "both" }}>&nbsp;</div>
        </section>
      </div>
      {!largeView && isAdminMenuOpen && 
        <div className={styles.backdrop} onClick={!largeView && isAdminMenuOpen ? () => setIsAdminMenuOpen(false) : () => {}}></div>}
    </>
  );
};
