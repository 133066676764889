import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AdminInput } from '@project/components';
import FileUpload from '../FileUpload/FileUpload';
import PropertyImagesUpload from '../PropertyImagesUpload/PropertyImagesUpload';
import styles from './PropertyIdDigitalTab.module.css';
import { useIntl } from 'react-intl';

export default function PropertyIdDigitalTab({ onSavePropertySettings, propertySettings, setPropertySettings, onGenerateIdDigitalPdf, isSaving, setErrors, isReadOnly = false }) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [activeUploadModal, setActiveUploadModal] = useState(null);

  const updateField = ({ target: { type, value, checked } }, base, field) => {
    base[field] = value;
    setPropertySettings({ ...propertySettings });
  }

  const imageExt = ".png, .svg, .jpg, .gif, .jpeg";

  if (!propertySettings) return null;

  return (
    <div className={styles.propertyIdDigitalTab}>
      <Form key="field_visibility_form" className="is-common-form">
        <div className={`${styles.saveButton} row my-sm-4 d-flex align-items-center`}>
          <div className="font-lg col-sm-8" style={{ lineHeight: '60px' }}>{t('PropertyIdDigitalTab.description')}</div>
          <div className="col-sm-4" align="right"><Button disabled={isSaving} onClick={() => onSavePropertySettings({ ...propertySettings }, 'iddigital')} >{t('PropertyIdDigitalTab.save')}</Button></div>
        </div>

        <h4 className='is-main-heading pb-3'>{t('PropertyIdDigitalTab.title')}</h4>

        <h4 className='mb-2 mt-3 mt-sm-5 font-weight-light' style={{ borderBottom: '1px solid #dee2e6', paddingBottom: '0.5em' }}>{t('PropertyIdDigitalTab.pdf.title')}</h4>
        <p>{t('PropertyIdDigitalTab.pdf.details')}</p>

        <Button disabled={isSaving} onClick={() => onGenerateIdDigitalPdf(propertySettings['iddigital_overview_pdf'] || '', 'iddigital_overview_pdf')} >{t('PropertyIdDigitalTab.pdf.overview')}</Button>

        <h4 className='mb-2 mt-3 mt-sm-5 font-weight-light' style={{ borderBottom: '1px solid #dee2e6', paddingBottom: '0.5em' }}>{t('PropertyIdDigitalTab.details')}</h4>

        <div className='row my-sm-4'>
          <div className='col-sm-12'>
            <AdminInput
              label={t('PropertyIdDigitalTab.details.leasing')}
              value={propertySettings.description}
              type={'textarea'}
              onChange={(e) => updateField(e, propertySettings, 'description')} />
          </div>
        </div>
        <div className='row my-sm-4'>
          <div className='col-sm-12'>
            <AdminInput
              label={t('PropertyIdDigitalTab.details.keyFacts')}
              value={propertySettings.keyFacts}
              type={'textarea'}
              onChange={(e) => updateField(e, propertySettings, 'keyFacts')} />
          </div>
        </div>

        <h4 className='mb-2 mt-3 mt-sm-5 font-weight-light' style={{ borderBottom: '1px solid #dee2e6', paddingBottom: '0.5em' }}>{t('PropertyIdDigitalTab.leasingImages')}</h4>

        <div className='row my-sm-4'>
          <div className='col-sm-12'>
            <FileUpload
              property={propertySettings}
              updateField={updateField}
              uploadKey={'leasing_image'}
              description={t('PropertyIdDigitalTab.leasingImages.upload')}
              readOnly={isReadOnly}
              accept={imageExt}
              makeThumbnail={true}
              makeStandard={true} />
            <Button onClick={() => setActiveUploadModal('leasingImages')} variant="link" style={{ textAlign: 'left', paddingLeft: 0 }}>{t('PropertyIdDigitalTab.leasingImages.uploadMulti')}</Button>
          </div>
        </div>
      </Form>

      <PropertyImagesUpload
        propertySettings={propertySettings}
        setErrors={setErrors}
        activeUploadModal={activeUploadModal}
        setActiveUploadModal={setActiveUploadModal}
        setPropertySettings={setPropertySettings}
        title={t('PropertyIdDigitalTab.leasingImages.upload.title')}
        makeThumbnail={true}
        makeStandard={true}
        dialogClassName={styles.ModalDialog}
      />
    </div>
  )
}
