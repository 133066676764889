import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailContainer from './ThumbnailContainer';
import styles from './VideoThumbnail.module.css';

const VideoThumbnail = ({
  onClick,
  onRemoveButtonClick,
  selected,
  showRemoveButton,
  url,
}) => (
  <ThumbnailContainer
    onClick={onClick}
    onRemoveButtonClick={onRemoveButtonClick}
    selected={selected}
    showRemoveButton={showRemoveButton}
  >
    <video className={styles.Video} src={url} />
  </ThumbnailContainer>
);

VideoThumbnail.propTypes = {
  /** Called when the main container gets clicked. */
  onClick: PropTypes.func,
  /** Called when the X button gets clicked. */
  onRemoveButtonClick: PropTypes.func,
  /** Marks this element as "selected". It displays a dark square around the container. */
  selected: PropTypes.bool,
  /** Shows/hides the X button, intended to "remove" this element from the uploader. */
  showRemoveButton: PropTypes.bool,
  /** URL of a video. */
  url: PropTypes.string.isRequired
};

export default VideoThumbnail;