import React from 'react';
import { removeSpacesAndMakeLowerCase } from '../utils/utils';

const AdminCheckbox = ({
  id,
  label,
  checked,
  onChange,
  readOnly,
  alignLabel,
  customstyle = '',
  customLabelStyle = '',
  removeMargins = false,
  justifyContent = true
}) => {
  const orderOneClass = 'order-1';
  const orderTwoClass = 'order-2';

  const [labelOrderClass, inputOrderClass] = alignLabel === 'right' ?
    [orderTwoClass, orderOneClass] : [orderOneClass, orderTwoClass];

  return (
    <div className={`d-flex ${justifyContent ? 'justify-content-between' : ''}  ${!removeMargins ? "pt-1 pb-1 mb-3 mt-5" : ""}`}>
      <label htmlFor={id} className={`mb-0 ${labelOrderClass} ${customLabelStyle}`} datacy={`checkbox-${removeSpacesAndMakeLowerCase(label)}`}>{label}</label>
      <div className={`custom-control custom-switch ${inputOrderClass} ${customstyle}`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          name={id}
          checked={!!checked}
          onChange={onChange}
          disabled={readOnly}
        />
        <label className="custom-control-label" htmlFor={id}></label>
      </div>
    </div>
  );
};

export default AdminCheckbox;