import React from 'react';

const TwoColumnContent = ({ children, className, bottomBorder, topMargin = false }) => {

  return (
    <div className={`row mb-3 mb-lg-4 px-0 mx-0 ${className || ''} ${topMargin ? 'my-sm-4 pb-0 pb-lg-0' : 'pb-3 pb-lg-4'}`}
      style={bottomBorder ? {borderBottom: '1px solid #ddd', width: '100%'} : {}}>
      {children}
      {
        bottomBorder ?
          <div style={{}}></div>
          : null
      }
    </div>
  );
};

export default TwoColumnContent;
