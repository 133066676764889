import React, { useMemo } from 'react'
import PropTypes from 'prop-types';
import Table from '../Table/Table';
import defaultColumnOptions from './defaultColumnOptions';
import FileListFile from './FileListFile';

const FileList = ({
  columnOptions,
  customWidths,
  extraFields,
  files,
  itemsPerPage,
}) => {
  // Table component filters
  const tableSearchFilters = ['name', 'uploadUser'];
  // Table component sorting columns
  const tableSort = ['uploadDate', 'name'];
  // Initial sorting strategy
  const tableDefaultSort = {
    key: 'uploadDate',
    descending: false,
  };
  // Table component field mapping (for table heading and actions)
  const tableFields = useMemo(() => {
    const fieldArray = [];
    const mergedOptions = Object.assign({}, defaultColumnOptions, columnOptions);

    if (mergedOptions.id && !mergedOptions.id.hidden) {
      fieldArray.push({
        id: 'id',
        label: mergedOptions.id.label,
        type: 'details',
      });
    }

    if (mergedOptions.name && !mergedOptions.name.hidden) {
      fieldArray.push({
        id: 'name',
        label: mergedOptions.name.label,
        type: 'details',
      });
    }

    if (mergedOptions.size && !mergedOptions.size.hidden) {
      fieldArray.push({
        id: 'humanizedSize',
        label: mergedOptions.size.label,
        type: 'details',
      });
    }

    if (mergedOptions.uploadUser && !mergedOptions.uploadUser.hidden) {
      fieldArray.push({
        id: 'uploadUser',
        label: mergedOptions.uploadUser.label,
        type: 'details',
      });
    }

    if (mergedOptions.uploadDate && !mergedOptions.uploadDate.hidden) {
      fieldArray.push({
        id: 'uploadDate',
        label: mergedOptions.uploadDate.label,
        type: 'datetime',
        format: 'LLL',
      });
    }

    if (mergedOptions.download && !mergedOptions.download.hidden) {
      fieldArray.push({
        id: 'dowload',
        buttonLabel: mergedOptions.download.label,
        type: 'button',
        click: (file) => window.open(file.url, '_blank', 'noopener,noreferrer'),
      });
    }

    return fieldArray.concat(extraFields ? extraFields : []);
  }, [columnOptions, extraFields]);

  return (
    <Table
      idField={'id'}
      searchFilters={tableSearchFilters}
      checkSelectable={false}
      fields={tableFields}
      label={''}
      itemsPerPage={itemsPerPage}
      items={files}
      sortFields={tableSort}
      defaultSort={tableDefaultSort}
      customWidths={customWidths}
    />
  );
};

const columnOptionProp = PropTypes.shape({
  hidden: PropTypes.bool,
  label: PropTypes.string,
});

FileList.propTypes = {
  columnOptions: PropTypes.shape({
    id: columnOptionProp,
    name: columnOptionProp,
    size: columnOptionProp,
    uploadDate: columnOptionProp,
    uploadUser: columnOptionProp,
    download: columnOptionProp,
  }),
  customWidths: PropTypes.arrayOf(
    PropTypes.string
  ),
  extraFields: PropTypes.any,
  files: PropTypes.arrayOf(
    PropTypes.instanceOf(FileListFile),
  ),
  itemsPerPage: PropTypes.number,
};

FileList.defaultProps = {
  columnOptions: defaultColumnOptions,
  customWidths: [],
  extraFields: [],
  files: [],
  itemsPerPage: 5,
};

export default FileList;