import React, { useEffect, useRef, useState } from 'react';
import RulesTooltip from '../RulesTooltip/RulesTooltip';
import styles from './PasswordInput.module.css';
import { Form } from 'react-bootstrap';
import Eye from '../assets/images/eye-regular.svg';
import EyeSlash from '../assets/images/eye-slash-regular.svg'
import zxcvbn from 'zxcvbn';
import { useIntl } from 'react-intl';

const PasswordInput = ({ password, setPassword, rules = [], create, strength, setValidPassword, tooltipPlacement, validate, className, labelStyle }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const [showTooltip, setShowTooltip] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmTouched, setConfirmTouched] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    if (validate) {
      confirmPasswordHandler(confirmPassword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  const rulesNotMet = [];
  let strengthScore = zxcvbn(password || '').score;
  let strengthColor = ['darkred', 'orangered', 'orange', 'yellowgreen', 'green'];
  let strengthStatus = [
    t('PasswordInput.strength.veryWeak'),
    t('PasswordInput.strength.weak'),
    t('PasswordInput.strength.good'),
    t('PasswordInput.strength.strong'),
    t('PasswordInput.strength.veryStrong')
  ];

  rules.forEach(rule => {
    // special handling for strength score validation
    if (rule.name === 'passwordStrength' && !rule.validate(strengthScore)) {
      rulesNotMet.push(rule.name);
    } else if (rule.name !== 'passwordStrength') {
      if (!rule.validate(password || '')) rulesNotMet.push(rule.name);
    }
  })

  let invalidFormat = (rulesNotMet.length !== 0) || password === '';
  let valid = invalidFormat || (password !== confirmPassword) ? false : true;

  useEffect(() => {
    setValidPassword(valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid])

  const confirmPasswordHandler = (value) => {
    setConfirmPassword(value);
    setConfirmTouched(true);
  }

  return (
    <div className={className}>
      <Form.Label htmlFor='password' className={labelStyle || styles.label}>{create ? t('PasswordInput.createPassword') : t('PasswordInput.password')}</Form.Label>
      <div className={styles.inputContainer} ref={target} style={(confirmTouched && password !== confirmPassword) || (validate && invalidFormat) ? { border: '1px solid #dc3545' } : {}} data-testid="passwordInputContainer">
        <Form.Group className={styles.input}>
          <Form.Control className={styles.password} type={showPassword ? 'text' : 'password'} placeholder='••••••••••••' value={password === null || password === undefined ? '' : password} id='password' name='password' onChange={(e) => setPassword(e.target.value)} onFocus={() => setShowTooltip(create && true)} onBlur={() => setShowTooltip(create && false)} />
        </Form.Group>
        <div className={styles.inputIcon} onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}>
          <div data-testid="showHidePassword"><img src={showPassword ? EyeSlash : Eye} alt={t('PasswordInput.showHide.alt')} /></div>
        </div>
      </div>

      {create && strength &&
        <>
          <div className={styles.statusBar} data-testid="passwordStrengthBar">
            <div style={{ backgroundColor: strengthScore >= 0 && strengthColor[strengthScore] }}></div>
            <div style={{ backgroundColor: strengthScore >= 1 && strengthColor[strengthScore] }}></div>
            <div style={{ backgroundColor: strengthScore >= 2 && strengthColor[strengthScore] }}></div>
            <div style={{ backgroundColor: strengthScore >= 3 && strengthColor[strengthScore] }}></div>
            <div style={{ backgroundColor: strengthScore >= 4 && strengthColor[strengthScore] }}></div>
          </div>
          <div className={styles.strengthScore}>
            {t('PasswordInput.strength')}: {strengthStatus[strengthScore]}
          </div>
        </>
      }

      {create &&
        <>
          <Form.Label htmlFor='confirmPassword' className={labelStyle || styles.label}>{t('PasswordInput.confirm')}</Form.Label>
          <div className={styles.inputContainer} style={(confirmTouched && password !== confirmPassword) || (validate && invalidFormat) ? { border: '1px solid #dc3545' } : {}} data-testid="passwordInputContainer">
            <Form.Group className={styles.input}>
              <Form.Control type='password' placeholder='••••••••••••' value={confirmPassword === null || confirmPassword === undefined ? '' : confirmPassword} id='confirmPassword' name='confirmPassword' onChange={(e) => confirmPasswordHandler(e.target.value)} isInvalid={(confirmTouched && password !== confirmPassword) || (validate && !valid)} />
              <Form.Control.Feedback type="invalid">
                {validate && invalidFormat ? 'Enter a valid password' : t('PasswordInput.mustMatch.error')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </>
      }

      {create &&
        <RulesTooltip label={t('PasswordInput.tooltip.label')} show={showTooltip} target={target} rules={rules} rulesNotMet={rulesNotMet} placement={tooltipPlacement} />
      }
    </div>

  )
}

export default PasswordInput;