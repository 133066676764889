import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './ConfirmModal.module.css';

/** Simple confirmation modal component using bootstrap */
const ConfirmModal = ({ children, show, onHide, title, confirmMessage, cancelButtonText, confirmButtonText, onConfirm, onExited, darkMode }) =>
  <Modal show={show} onHide={onHide} className={`${styles.confirmModal} ${darkMode ? styles.darkMode : ''}`} onExited={onExited}>
    <Modal.Header className={styles.confirmModalHeader}>
      <Modal.Title className={styles.confirmModalHeaderTitle}><div>{title || "Confirm"}</div></Modal.Title>
    </Modal.Header>
    <Modal.Body className={styles.confirmModalBody}>
      {children ? children : <div>{confirmMessage || "Are you sure you want to do this?"}</div>}
    </Modal.Body>
    <Modal.Footer className={styles.confirmModalFooter}>
      <Button variant="secondary" onClick={onHide} className={`btn-gray ${styles.secondaryButton}`}>
        {cancelButtonText || 'Cancel'}
      </Button>
      <Button variant="primary" onClick={onConfirm} className={`btn-prime ${styles.primaryButton}`}>
        {confirmButtonText || 'OK'}
      </Button>
    </Modal.Footer>
  </Modal>

export default ConfirmModal

ConfirmModal.propTypes = {
  /** Boolean indicating whether the button should render as disabled */
  show: PropTypes.bool.isRequired,
  /** button label. */
  title: PropTypes.string,
  /** onClick handler */
  onHide: PropTypes.func.isRequired,
  /** onClick handler */
  onConfirm: PropTypes.func.isRequired,
  /** Dark Mode */
  darkMode: PropTypes.bool,
};
