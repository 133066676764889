/**
 * Enable a given feature if the user's company is ID Plans.
 * 
 * @returns {boolean}
 */
export const isCompanyIDPlans = () => ({ currentCompany }) => currentCompany.name === 'ID Plans';

/**
 * Enable a given companies to use the feature
 * 
 * @returns {boolean}
 */
export const isCompanyAllowedToUseVendorFeature = (companies = []) => ({ currentCompany }) => companies.includes(currentCompany.name);