import React, { useState, useContext } from "react"
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer'
import Title from '../../components/Title/Title'
import styles from './MigrateProperty.module.css'
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent'
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn'
import UserCompaniesSelect from '../../components/UserCompaniesSelect/UserCompaniesSelect'
import { useIntl } from 'react-intl'
import { RootContext } from '../../hoc/RootContext/RootContext'
import PropertySelect from '../../components/PropertySelect/PropertySelect'
import { AdminInput, AdminCheckbox } from '@project/components'
import { Button } from 'react-bootstrap'
import axios from '../../utils/axios'

const classNames = require('classnames')
let baseUrl = '/properties/migrateProperty'

const postRequest = async (type, body, setStatus) => {
  const url = `${baseUrl}/${type}`
  const response = await axios.post(url, body)
  if (!response.data.success) {
    let message = response.data.errors[0].message
    setStatus({ error: message })
  } else if (response.data.success) {
    setStatus({ success: true })
  }
}

const beginMigration = async ({selectedCompanySource, selectedCompanyDestination, selectedPropertySource, newPropertyDisplayName, migratePropertyRecord, migratePageRecords, migrateSpaceProfiles, migrateSpaceRecords, migrateAssetRecords, migrateTenantRecords, migrateVisionRecords, migrateRolesRecords, setMigratePropertyStatus, setMigratePageStatus, setMigrateSpaceProfileStatus, setMigrateSpacesStatus, setMigrateAssetsStatus, setMigrateTenantsStatus, setMigrateVisionsStatus, setMigrateRolesStatus}) => {
  if (!newPropertyDisplayName) {
    newPropertyDisplayName = selectedPropertySource?.displayName || selectedPropertySource?.name
  }
  if (!selectedCompanySource?.slug || !selectedCompanyDestination?.slug || !selectedPropertySource?.slug || !newPropertyDisplayName) return
  if (selectedCompanySource?.slug === selectedCompanyDestination?.slug) return

  const body = {
    sourceCompany: selectedCompanySource.value,
    sourceCompanySlug: selectedCompanySource.slug,
    destinationCompany: selectedCompanyDestination.value,
    destinationCompanySlug: selectedCompanyDestination.slug,
    sourceProperty: selectedPropertySource.name,
    sourcePropertySlug: selectedPropertySource.slug,
    propertyDisplayName: newPropertyDisplayName,
    destinationCompanyLogo: selectedCompanyDestination.logo
  }

  if (migratePropertyRecord) await postRequest('migratePropertyRecord', body, setMigratePropertyStatus)
  if (migratePageRecords) await postRequest('migratePageRecords', body, setMigratePageStatus)
  if (migrateSpaceProfiles) await postRequest('migrateSpaceProfiles', body, setMigrateSpaceProfileStatus)
  if (migrateSpaceRecords) await postRequest('migrateSpaceRecords', body, setMigrateSpacesStatus)
  if (migrateAssetRecords) await postRequest('migrateAssetRecords', body, setMigrateAssetsStatus)
  if (migrateTenantRecords) await postRequest('migrateTenantRecords', body, setMigrateTenantsStatus)
  if (migrateVisionRecords) await postRequest('migrateVisionRecords', body, setMigrateVisionsStatus)
  if (migrateRolesRecords) await postRequest('migrateRolesRecords', body, setMigrateRolesStatus)
}

const ControlCheckbox = ({ id, label, toggle, setter }) => {
  const [checked, setChecked] = useState(toggle)
  return (
    <AdminCheckbox
      id={id}
      label={label}
      checked={checked}
      removeMargins={true}
      justifyContent={false}
      onChange={() => {
        setChecked(!checked)
        setter(!checked)
      }}
      customstyle={styles.checkbox}
    />
  )
}

const Status = ({status, errorLabel, successLabel}) => {
  if (status === null) return null

  const classes = status.success ? 'alert alert-primary' : 'alert alert-danger'
  const message = status.success ? successLabel : `${errorLabel}: ${status.error}`
  return (
    <div className={`${classes}`} role="alert">{message}</div>
  )
}

const MigrateProperty = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { currentUser } = useContext(RootContext);

  const [selectedCompanySource, setSelectedCompanySource] = useState(null)
  const [selectedCompanyDestination, setSelectedCompanyDestination] = useState(null)
  const [selectedPropertySource, setSelectedPropertySource] = useState(null)
  const [newPropertyDisplayName, setNewPropertyDisplayName] = useState('')
  const [migratePropertyRecord, setMigratePropertyRecord] = useState(true)
  const [migratePageRecords, setMigratePageRecords] = useState(true)
  const [migrateSpaceProfiles, setMigrateSpaceProfiles] = useState(true)
  const [migrateSpaceRecords, setMigrateSpaceRecords] = useState(true)
  const [migrateAssetRecords, setMigrateAssetRecords] = useState(true)
  const [migrateTenantRecords, setMigrateTenantRecords] = useState(true)
  const [migrateVisionRecords, setMigrateVisionRecords] = useState(true)
  const [migrateRolesRecords, setMigrateRolesRecords] = useState(true)

  const [migratePropertyStatus, setMigratePropertyStatus] = useState(null)
  const [migratePageStatus, setMigratePageStatus] = useState(null)
  const [migrateSpaceProfileStatus, setMigrateSpaceProfileStatus] = useState(null)
  const [migrateSpacesStatus, setMigrateSpacesStatus] = useState(null)
  const [migrateAssetsStatus, setMigrateAssetsStatus] = useState(null)
  const [migrateTenantsStatus, setMigrateTenantsStatus] = useState(null)
  const [migrateVisionsStatus, setMigrateVisionsStatus] = useState(null)
  const [migrateRolesStatus, setMigrateRolesStatus] = useState(null)

  const params = {
    selectedCompanySource,
    selectedCompanyDestination,
    selectedPropertySource,
    newPropertyDisplayName,
    migratePropertyRecord,
    migratePageRecords,
    migrateSpaceProfiles,
    migrateSpaceRecords,
    migrateAssetRecords,
    migrateTenantRecords,
    migrateVisionRecords,
    migrateRolesRecords,
    setMigratePropertyStatus,
    setMigratePageStatus,
    setMigrateSpaceProfileStatus,
    setMigrateSpacesStatus,
    setMigrateAssetsStatus,
    setMigrateTenantsStatus,
    setMigrateVisionsStatus,
    setMigrateRolesStatus
  }

  return (
    <PageContentContainer>
      <Title title={t('MigrateProperty.title')} />
      <main className={classNames(styles.settings, 'row mt-4 shadow-sm bg-white border mx-auto')}>
        <div className="col-md-12 pl-3">
          <TwoColumnContent topMargin={true}>
            <LeftColumn>
              <UserCompaniesSelect label={t('term.sourceCompany')} setSelectedCompany={setSelectedCompanySource} selectAll={false} selectedValue={selectedCompanySource} show={currentUser && currentUser.companies.length > 1} defaultPropStyles={true} />
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} alignRight={false} padded={false}>
              <UserCompaniesSelect label={t('term.destinationCompany')} setSelectedCompany={setSelectedCompanyDestination} selectAll={false} selectedValue={selectedCompanyDestination} show={currentUser && currentUser.companies.length > 1} defaultPropStyles={true} />
            </RightColumn>
          </TwoColumnContent>
        </div>
        <div className="col-md-12 pl-3">
          <TwoColumnContent topMargin={true}>
            <LeftColumn>
              <PropertySelect label={t('term.sourceProperty')} selectedCompany={selectedCompanySource} selectedProperty={selectedPropertySource} setSelectedProperty={setSelectedPropertySource} />
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} alignRight={false} padded={false}>
              <AdminInput
                id="newPropertyDisplayName"
                type="text"
                value={ newPropertyDisplayName || selectedPropertySource?.displayName || selectedPropertySource?.name }
                onChange={(e) => setNewPropertyDisplayName(e.target.value)}
                label={t('MigrateProperty.newPropertyDisplayName')}
                customstyle={classNames("mb-0 mr-3")} />
            </RightColumn>
          </TwoColumnContent>
        </div>
        <div className="col-md-12 pl-3">
          <TwoColumnContent topMargin={true}>
            <LeftColumn>
              <ControlCheckbox 
                id={'migratePropertyRecord'} 
                label={t('MigrateProperty.migratePropertyRecord')}
                toggle={migratePropertyRecord}
                setter={setMigratePropertyRecord}
              />
              <Status status={migratePropertyStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} alignRight={false} padded={false}>
              <ControlCheckbox 
                id={'migratePageRecords'} 
                label={t('MigrateProperty.migratePageRecords')}
                toggle={migratePageRecords}
                setter={setMigratePageRecords}
              />
              <Status status={migratePageStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </RightColumn>
          </TwoColumnContent>
        </div>
        <div className="col-md-12 pl-3">
          <TwoColumnContent topMargin={true}>
            <LeftColumn>
              <ControlCheckbox 
                id={'migrateSpaceProfiles'}
                label={t('MigrateProperty.migrateSpaceProfiles')}
                toggle={migrateSpaceProfiles}
                setter={setMigrateSpaceProfiles}
              />
              <Status status={migrateSpaceProfileStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} alignRight={false} padded={false}>
              <ControlCheckbox 
                id={'migrateSpaceRecords'} 
                label={t('MigrateProperty.migrateSpaceRecords')}
                toggle={migrateSpaceRecords}
                setter={setMigrateSpaceRecords}
              />
              <Status status={migrateSpacesStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </RightColumn>
          </TwoColumnContent>
        </div>
        <div className="col-md-12 pl-3">
          <TwoColumnContent topMargin={true}>
            <LeftColumn>
              <ControlCheckbox 
                id={'migrateAssetRecords'} 
                label={t('MigrateProperty.migrateAssetRecords')}
                toggle={migrateAssetRecords}
                setter={setMigrateAssetRecords}
              />
              <Status status={migrateAssetsStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} alignRight={false} padded={false}>
              <ControlCheckbox 
                id={'migrateTenantRecords'} 
                label={t('MigrateProperty.migrateTenantRecords')} 
                toggle={migrateTenantRecords}
                setter={setMigrateTenantRecords}
              />
              <Status status={migrateTenantsStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </RightColumn>
          </TwoColumnContent>
        </div>
        <div className="col-md-12 pl-3">
          <TwoColumnContent topMargin={true}>
            <LeftColumn>
              <ControlCheckbox 
                id={'migrateVisionRecords'} 
                label={t('MigrateProperty.migrateVisionRecords')} 
                toggle={migrateVisionRecords}
                setter={setMigrateVisionRecords}
              />
              <Status status={migrateVisionsStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} alignRight={false} padded={false}>
              <ControlCheckbox 
                id={'migrateRolesRecords'} 
                label={t('MigrateProperty.migrateRolesRecords')} 
                toggle={migrateRolesRecords}
                setter={setMigrateRolesRecords}
              />
              <Status status={migrateRolesStatus} successLabel={t('term.success')} errorLabel={t('error')} />
            </RightColumn>
          </TwoColumnContent>
        </div>
        <div className="col-md-12 pl-3 mt-5">
          <Button onClick={() => beginMigration(params) } >{t('MigrateProperty.beginMigration')}</Button>
        </div>
      </main>
    </PageContentContainer>
  )
}

export default MigrateProperty