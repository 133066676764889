export default {
  guid: {
    hidden: false,
    label: 'GUID',
  },
  name: {
    hidden: false,
    label: 'Name',
  },
  size: {
    hidden: false,
    label: 'Size',
  },
  uploadDate: {
    hidden: false,
    label: 'Upload date',
  },
  uploadUser: {
    hidden: false,
    label: 'Uploaded by',
  },
  download: {
    hidden: false,
    label: 'Download',
  },
};