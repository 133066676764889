import { intl } from './intl';
const t = (id) => intl.formatMessage({ id });
/**
 * creates and returns a validation rule object that
 * is used to validate form inputs
 *
 * @param {string} ruleName - name of the validation rule
 * @param {string} errorMessage - message to display
 * @param {function} validateFunc - validation function
 */
function createValidationRule(ruleName, errorMessage, validateFunc) {
  return {
    name: ruleName,
    message: errorMessage,
    validate: validateFunc,
  };
}

function requiredRule(inputName) {
  return createValidationRule(
    'required',
    intl.formatMessage({ id: 'inputValidationRules.requiredRule' }, { item: inputName }),
    (inputValue) => inputValue.length !== 0
  );
}

function passwordStrengthRule(minStrength) {
  return createValidationRule(
    'passwordStrength',
    intl.formatMessage({ id: 'inputValidationRules.passwordStrengthRule' }, { minStrength }),
    strength => strength >= minStrength
  );
}

function minLengthRule(minCharacters) {
  return createValidationRule(
    'minLength',
    intl.formatMessage({ id: 'inputValidationRules.minLengthRule' }, { minCharacters }),
    (inputValue) => inputValue.length >= minCharacters
  );
}

function maxLengthRule(maxCharacters) {
  return createValidationRule(
    'maxLength',
    intl.formatMessage({ id: 'inputValidationRules.maxLengthRule' }, { maxCharacters }),
    (inputValue) => inputValue.length <= maxCharacters
  );
}

function hasUpperCaseRule() {
  return createValidationRule(
    'upperCase',
    t('inputValidationRules.hasUpperCaseRule'),
    (inputValue) => /[A-Z]/.test(inputValue)
  );
}

function hasLowerCaseRule() {
  return createValidationRule(
    'lowerCase',
    t('inputValidationRules.hasLowerCaseRule'),
    (inputValue) => /[a-z]/.test(inputValue)
  );
}

function hasNumberRule() {
  return createValidationRule(
    'hasNumber',
    t('inputValidationRules.hasNumberRule'),
    (inputValue) => /\d/.test(inputValue)
  );
}

function isValidEmailRule() {
  return createValidationRule(
    'isValidEmail',
    t('inputValidationRules.isValidEmailRule'),
    (inputValue) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(inputValue)
  )
}

const inputValidationRules = {
  requiredRule,
  minLengthRule,
  maxLengthRule,
  hasNumberRule,
  hasLowerCaseRule,
  hasUpperCaseRule,
  isValidEmailRule,
  passwordStrengthRule
};

export default inputValidationRules;