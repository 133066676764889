import iconBackground from './icons/icon-background'
import iconIDVision from './icons/icon-idvision'
import iconBasic from './icons/icon-basic'
import iconDimensions from './icons/icon-dimensions'
import iconBlank from './icons/icon-blank'
import iconDrainage from './icons/icon-drainage'
import iconDumpster from './icons/icon-dumpster'
import iconElectric from './icons/icon-electric'
import iconEmergency from './icons/icon-emergency'
import iconFire from './icons/icon-fire'
import iconGas from './icons/icon-gas'
import iconHvac from './icons/icon-hvac'
import iconIrrigation from './icons/icon-irrigation'
import iconLandscape from './icons/icon-landscape'
import iconParking from './icons/icon-parking'
import iconPavement from './icons/icon-pavement'
import iconPylon from './icons/icon-pylon'
import iconRoof from './icons/icon-roof'
import iconSignage from './icons/icon-signage'
import iconSpace from './icons/icon-space'
import iconWater from './icons/icon-water'

export const DEFAULT_UNCOLORED = '#d3d3d3'

export default [
  {
    id: 'idvision',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#00A79D',
        end: '#04DFD2'
      }
    },
    icon: iconIDVision,
    translateX: true
  },
  {
    id: 'background',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#939598',
        end: '#DADADA'
      }
    },
    icon: iconBackground
  },
  {
    id: 'basic',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#00A79D',
        end: '#2FE4A3'
      }
    },
    icon: iconBasic
  },
  {
    id: 'dimensions',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#1b75bc',
        end: '#4bb5dc'
      }
    },
    icon: iconDimensions
  },
  {
    id: 'siteplan',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#BCBEC0',
        end: '#A0A0A0'
      }
    },
    icon: iconBlank
  },
  {
    id: 'drainage',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#9398b2',
        end: '#c3c8e2'
      }  
    },
    icon: iconDrainage
  },
  {
    id: 'dumpster',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#009444',
        end: '#00b464'
      }
    },
    icon: iconDumpster,
    viewBox: [121, 110],
    scale: '1.5x'
  },
  {
    id: 'elec',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#FAFF0C',
        end: '#D7DF23'
      }
    },
    icon: iconElectric
  },
  {
    id: 'er',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#ED1C24',
        end: '#FF5C00'
      }  
    },
    icon: iconEmergency,
    translateX: true
  },
  {
    id: 'fire',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#F15A29',
        end: '#F129C5'
      }
    },
    icon: iconFire,
    viewBox: [140, 127],
    scale: '2x'
  },
  {
    id: 'gas',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#8CC6EC',
        end: '#2C9FEB'
      }
    },
    icon: iconGas,
    translateX: true
  },
  {
    id: 'hvac',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#F26522',
        end: '#EA0000'
      }
    },
    icon: iconHvac,
    translateX: true
  },
  {
    id: 'irrig',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#39B54A',
        end: "#016A10"
      }
    },
    icon: iconIrrigation,
    translateX: true
  },
  {
    id: 'land',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#006838',
        end: '#009B54'
      }
    },
    icon: iconLandscape,
    viewBox: [140, 127],
    scale: '2x'
  },
  {
    id: 'park',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#939598',
        end: '#626364'
      }
    },
    icon: iconParking,
    translateX: true
  },
  {
    id: 'pavement',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#414042',
        end: '#222222'
      }
    },
    icon: iconPavement,
    translateX: true
  },
  {
    id: 'pylon',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#a154a1',
        end: '#d534d5'
      }
    },
    icon: iconPylon,
    translateX: true
  },
  {
    id: 'roof',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#808285',
        end: '#606265'
      }
    },
    icon: iconRoof,
    viewBox: [121, 110],
    scale: '1.5x'
  },
  {
    id: 'sign',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#FBB040',
        end: '#FBD240'
      }
    },
    icon: iconSignage,
    translateX: true
  },
  {
    id: 'space',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#F15A29',
        end: '#FF8E01'
      }
    },
    icon: iconSpace
  },
  {
    id: 'water',
    colorThemes: {
      uncolored: DEFAULT_UNCOLORED,
      colored: {
        start: '#1B75BC',
        end: '#7D48D2'
      }
    },
    icon: iconWater
  }
]