// this is the default bucket where all V4 admin configured images are stored
const IMAGE_BUCKET_URL_V4 = 'https://idp-rpm-v4.s3.amazonaws.com'

/**
 * Safely accesses an object using a flattened key
 *
 * @param {Object} obj base object
 * @param {String} key dot delimited access key for the object
 * @param {Object} defaultReturn default return when a match isn't found
 */
 const get = (obj, key, defaultReturn = null) => {
  const res = key
    .split('.')
    .reduce((returnVal, currentKey) => (returnVal ? returnVal[currentKey] : defaultReturn), obj);
  return res !== null && res !== undefined ? res : defaultReturn;
}

/**
 * Returns true if an existing is already found in the object, else false
 * @param {Object} object The Object to fetch the image from
 * @param {String} objectKey The key which stores the image
 * @returns true or false (image already present)
 */
 const isImagePresent = (object, objectKey) => {
  if (typeof object[objectKey] === 'object') return false 
  return object[objectKey]?.indexOf('https:') === 0 || object[objectKey]?.indexOf('data:') === 0
}

/**
 * Get the image url from the Object
 * @param {Object} object The Object to fetch the image from
 * @param {Array} keys Array of dot separated keys
 * @param {String} defaultImage base64 encoded image
 * @param {String} objectKey The key which stores the image
 * @returns The image url
 */
const getImageUrl = (object, keys, defaultImage, objectKey) => {
  let _image
  if (isImagePresent(object, objectKey)) return object[objectKey]

  keys.forEach(key => {
    if (!get(object, key)) return
    _image = get(object, key)
    if (typeof _image === 'object') return
    object[objectKey] = _image?.indexOf('https') < 0 ? `${IMAGE_BUCKET_URL_V4}/${_image}` : _image
  })
  if (!_image) {
    object[objectKey] = defaultImage
  }
  return object[objectKey]
}

export { getImageUrl }