import { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import { FileListFile, UPLOADED_FILE_CATEGORY, WORK_ORDER_FILE_TYPE } from "@project/components";
import { useTranslations } from "../../../utils/hooks";

export const useCOIUpload = ({ acceptedFileFormats, coiFile, setCoiFile, vendorInvite, registrationToken }) => {
  const [t] = useTranslations();

  const [file, setFile] = useState(coiFile ? new FileListFile({
    id: coiFile.id,
    name: coiFile.filename,
    size: coiFile.filesize,
    uploadDate: coiFile.createdAt,
    uploadUser: coiFile.createdBy,
    url: coiFile.url
  }) : null);

  const [fileToUpload, setFileToUpload] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [submissionCompleted, setSubmissionCompleted] = useState(false);

  const onFileSelected = async (file, rawFile) => {
    if (!acceptedFileFormats.includes(file.extension)) {
      return setError(t('COIUpload.fileFormatError'));
    }
    setFile(file);
    setFileToUpload(rawFile);
  };

  useEffect(() => {
    if (fileToUpload && !isUploading) {
      setIsUploading(true);
      uploadFile();
    }
  }, [fileToUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadFile = async () => {
    const upload = new FormData();
    upload.append("files", fileToUpload);
    upload.append("category", UPLOADED_FILE_CATEGORY.COI);
    upload.append("type", WORK_ORDER_FILE_TYPE.DOCUMENT);

    try {
      const { data: { records } } = await axiosInstance.post(`/users/${vendorInvite.username}/files`, upload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Access-Token': registrationToken
        }
      });

      setError(null)
      setSubmissionCompleted(records[0]);
      setCoiFile(new FileListFile({
        id: records[0].id,
        name: records[0].UploadedFile.filename,
        size: records[0].UploadedFile.filesize,
        uploadDate: records[0].UploadedFile.createdAt,
        uploadUser: records[0].UploadedFile.createdBy,
        url: records[0].UploadedFile.url
      }));
    } catch (e) {
      setCoiFile(null)
      // Something went wrong, let's alert the user.
      setError(e.message);
      setSubmissionCompleted(false)
    } finally {
      setIsUploading(false);
    }
  }

  return {
    file,
    error,
    submissionCompleted,
    onFileSelected,
    isUploading
  }
}