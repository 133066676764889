import React from 'react';
import { Image } from 'react-bootstrap';
import styles from './Contact.module.css';
import { useIntl } from 'react-intl';

const Contact = ({ contact }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const USER_TITLES = {
    'Asset Manager': t('Contact.assetManager'),
    'Leasing Representative': t('Contact.leasingRepresentative'),
    'Property Manager': t('Contact.propertyManager'),
    'Billing Specialist': t('Contact.billingSpecialist'),
    'Renewal Agent': t('Contact.renewalAgent'),
    'Property Manager Assistant': t('Contact.propertyManagerAssistant'),
    'Leasing Agent': t('Contact.leasingAgent'),
    'Construction Manager': t('Contact.constructionManager')
  };

  const initials = contact.fname && contact.lname ? contact.fname.substring(0, 1) + contact.lname.substring(0, 1) : contact.username.substring(0, 1);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  return (
    <div className={`${styles.contact} d-flex border-bottom py-5 w-100`}>
      <div className="mx-auto mx-sm-0 mr-md-3 mb-2 mb-md-0">
        {contact.profilePicture ?
          <Image src={contact.profilePicture} className={`${styles.contactImage} img-fluid rounded-circle border-3`} alt={`${contact.fname} ${contact.lname}`} />
          : <div className={`${styles.circleTitle} d-flex justify-content-center align-items-center`}><span>{initials}</span></div>}
      </div>
      <div className="d-flex align-items-center ml-3">
        <div className="d-flex flex-column justify-content-between">
          <div className="h-33"><h5 className="card-title font19 mb-2">{`${contact.fname} ${contact.lname}`}</h5></div>
          <p className="h-33 card-text mb-2" style={{ color: '#8c8c8c' }}>{contact.title ? USER_TITLES[contact.title] || contact.title : t('Contact.propertyContact')}</p>
          <p className="h-33 card-text">
            <span className="w-100 d-block d-sm-inline">
              {contact.phone && <><span style={{ fontWeight: 500 }}>{t('Contact.phone')}:</span> {formatPhoneNumber(contact.phone)}</>}
              {contact.phone && contact.email && <>&nbsp;&nbsp;|&nbsp;&nbsp;</>}
            </span>
            {contact.email && <><span style={{ fontWeight: 500 }}>{t('Contact.email')}:</span> {contact.email}</>}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;