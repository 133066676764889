import React from 'react';
import AdminInput from './../AdminInput/AdminInput';
import Select from 'react-select';
import styles from './AdminSelect.module.css';


export default function AdminSelect(props) {
  const { id, label, isInvalid, propStyles, validationMessage, hideLabel, identifierClass, addNew, addNewLabel = 'Add New', reverseDropdownIcon, hideElement, disabled, onClick, testId = "admin-select" } = props;
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: `1px solid ${isInvalid ? 'red' : '#ebebeb'}`,
      display: reverseDropdownIcon ? 'flex' : provided.display,
      flexDirection: reverseDropdownIcon ? 'row-reverse' : provided.flexDirection
    }),
    indicatorSeparator: (provided) => ({}),
    menu: (provided, { selectProps: { menuIsOpen } }) => {
      const defaultZIndex = provided.zIndex ?? 1;
      const zIndex = menuIsOpen ? 1000 : defaultZIndex;

      return ({
        ...provided,
        zIndex
      });
    },
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: reverseDropdownIcon ? 0 : provided.paddingLeft
    })
  };

  return (
    <div id="adminSelect" className={`${identifierClass || ''} ${hideElement ? 'd-none' : 'd-block'}`} >
      <AdminInput disabled={disabled} id={id} hidelabel={hideLabel} label={label || ""} customstyle={propStyles ? propStyles.embeddedInput || propStyles : ''} isInvalid={isInvalid} validationmessage={validationMessage}>
        <div onClick={onClick} datacy={`${id}-${testId}`}>
          <Select
            {...props}
            isDisabled={props.isDisabled || disabled}
            classNamePrefix="list"
            loadingMessage={() => props.loadingMessage || 'Loading...'}
            noOptionsMessage={() => props.noOptionsMessage || 'No Options'}
            placeholder={props.placeholder || 'Select...'}
            styles={customStyles}
            className={propStyles ? propStyles.propertyMenu : null || styles.commonSelect}
            components={props.customComponents}
            width="200px"
          />
        </div>
      </AdminInput>
      {addNew && addNewLabel && <div className={`w-100 ${styles.addNew}`} onClick={addNew} datacy={`${id}-add-new`}>{`+ ${addNewLabel}`}</div>}
    </div>
  );
}