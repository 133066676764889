import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectedOption from './SelectedOption/SelectedOption';
import OptionsList from './OptionsList/OptionsList';

const ClickSelect = ({
  options = [],
  selectedOption = {},
  setSelectedOption,
  showIcon,
  label,
  readOnly,
  append,
  isLoadingOptions,
  sort = true,
  noBlur,
}) => {
  const [searchValue, setSearchValue] = useState(selectedOption.label);
  //Set to true when user starts to change the value in the search bar
  const [isSearching, setIsSearching] = useState(false);
  //Search bar active and options list open
  const [active, setActive] = useState(false);
  //Used to handle onBlur - active set to false
  const optionRef = useRef();

  useEffect(() => {
    //Used to focus the input when active is set to true.
    //Has to be done this way since the selectedOption is not made
    //an input until active is set to true.
    if (active && optionRef.current) optionRef.current.focus()
    //Reset isSearching when active set to false.
    if (!active) setIsSearching(false);
  }, [active, optionRef, label]);

  const selectedOptionFormatted = <SelectedOption
    active={active}
    append={append}
    readOnly={readOnly}
    searchValue={searchValue}
    selectedOption={selectedOption}
    showIcon={showIcon}
    setActive={setActive}
    setSearchValue={setSearchValue}
    setIsSearching={setIsSearching}
    optionRef={optionRef}
    noBlur={noBlur}
  />

  let labelFormatted = label ? <div className='w-100'>{label}</div> : null;

  return <div className='w-100 position-relative'>
    {labelFormatted}
    {selectedOptionFormatted}
    <OptionsList
      active={active}
      isLoadingOptions={isLoadingOptions}
      options={options}
      setSelectedOption={setSelectedOption}
      selectedOptionValue={selectedOption.value}
      isSearching={isSearching}
      searchValue={searchValue}
      showIcon={showIcon}
      append={append}
      setSearchValue={setSearchValue}
      setActive={setActive}
      sort={sort}
    />
  </div>
}

ClickSelect.propTypes = {
  //Array of options to be chose from. Of form {label: 'testlabel', value: 'testid', icon: 'imageurl'}
  options: PropTypes.arrayOf(PropTypes.object),
  //Chosen option of form {label: 'testlabel', value: 'testid', icon: 'imageurl'}
  selectedOption: PropTypes.object,
  //Setter for selectedOption
  setSelectedOption: PropTypes.func,
  //Show the image next to labels of the option and selectedOption. If no image given in option
  //object then a circle letter component is put in its place
  showIcon: PropTypes.bool,
  //Label to go above the select component.
  label: PropTypes.string,
  //Function used to append a string onto the label. This function will
  //be passed the option object and is expected to return a string.
  append: PropTypes.func,
  //Read only mode where user may not select any options
  readOnly: PropTypes.bool,
  //Used to allow a spinner to be placed in the options list container
  //when options not yet available
  isLoadingOptions: PropTypes.bool
}

ClickSelect.defaultProps = {
  options: [],
  selectedOption: {}
}

export default ClickSelect;