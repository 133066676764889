/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { AdminSelect, Table } from '@project/components'
import DashboardCard from '../DashboardCard/DashboardCard'

import styles from './SubscriptionsTracker.module.css'
import { useIntl } from 'react-intl'

import { useRpmSubscriptionsTrackerData } from './hooks'
import scopes, { RPMScopes } from './scopes'

const SubscriptionsTracker = ({
  currentCompany,
  currentProperty,
  view: { smallMobileView, mobileView, largeView }
}) => {
  const intl = useIntl()
  const t = (id, tokens = {}) => intl.formatMessage({ id }, tokens)

  const [isSubscriptionTrackerLoading, setIsSubscriptionTrackerLoading] = useState(true)
  const [totalItems, setTotalItems] = useState(1)
  const [scopeFields, setScopeFields] = useState([])
  const [scopeFieldIDs, setScopeFieldIDs] = useState([])
  const [
    subscriptionsTrackerData,
    subscriptionsTrackerPage,
    setSubscriptionsTrackerPage,
    setSubscriptionsTrackerFilter,
    subscriptionsTrackerDateRange,
    setSubscriptionsTrackerDateRange,
    subscriptionsTrackerScope,
    setSubscriptionsTrackerScope
  ] = useRpmSubscriptionsTrackerData({
    currentProperty,
    currentCompany,
    setLoading: setIsSubscriptionTrackerLoading,
    setTotalItems
  })

  useEffect(() => {
    if (subscriptionsTrackerScope && subscriptionsTrackerScope.value) {
      setScopeFields([{
        id: `scope${subscriptionsTrackerScope.value}Title`,
        label: t(`Dashboard.subscriptionsTracker.scopes.${subscriptionsTrackerScope.value.toLowerCase()}`),
        type: 'details'
      }])
    } else {
      setScopeFields(scopes.map(scope => ({
        id: `scope${scope}Title`,
        label: t(`Dashboard.subscriptionsTracker.scopes.${scope.toLowerCase()}`),
        type: 'details'
      })))
    }
  }, [subscriptionsTrackerScope])

  useEffect(() => {
    setScopeFieldIDs(scopeFields.map(scope => scope.id))
  }, [scopeFields])

  const timePeriodOptions = useMemo(() => {
    const timePeriods = [7, 30, 60, 90, 180]
    const options = [
      {
        value: '',
        label: t('term.anyDate')
      },
      ...timePeriods.map(period => ({
        value: `-${period}`,
        label: t('term.lastDate', { period, interval: t('term.days').toLowerCase(), gender: 'o' })
      })),
      ...timePeriods.map(period => ({
        value: `+${period}`,
        label: t('term.nextDate', { period, interval: t('term.days').toLowerCase(), gender: 'o' })
      }))
    ]
    return options
  })

  const scopeOptions = [{
    value: '',
    label: t('Dashboard.subscriptionsTracker.allScopes')
  }, ...scopes.map(s => ({
    value: s,
    label: t(`Dashboard.subscriptionsTracker.scopes.${s.toLowerCase()}`)
  }))]

  const fields = useMemo(() => [
    {
      id: 'name',
      label: t('term.property'),
      type: 'details'
    },
    {
      id: 'scopes',
      label: t('Dashboard.subscriptionsTracker.scopesTitle'),
      type: 'fragment',
      renderFragment: (subscription) => (
        <RPMScopes
          scopeFilter={subscriptionsTrackerScope}
          subscription={subscription}
        />
      )
    },
    {
      id: 'nameAndScopes',
      label: t('term.property'),
      type: 'fragment',
      renderFragment: (subscription) => <>
        <label className={styles.propertyName}>{subscription.name}</label>
        <RPMScopes
          scopeFilter={subscriptionsTrackerScope}
          subscription={subscription}
        />
      </>
    },
    ...scopeFields,
    {
      id: 'allScopesTitle',
      label: t('Dashboard.subscriptionsTracker.allScopes'),
      type: 'details'
    }
  ])

  const detailsDropdown = useMemo(
    () => (item) => item.limitedScope ? scopeFieldIDs.filter(scope => item[scope.replace(/Title/, '')]) : ['allScopesTitle']
  );

  return <DashboardCard
    header={<div className={`${styles.titleContainer}`}>
      <div className={`${styles.tableTitle}`}>{t('Dashboard.subscriptionsTracker.title')}</div>
      <AdminSelect
        id="timePeriod"
        options={timePeriodOptions}
        propStyles={{ propertyMenu: styles.filterDropdown }}
        defaultValue=""
        placeholder={t('Dashboard.subscriptionsTracker.filterByDate')}
        value={subscriptionsTrackerDateRange}
        onChange={setSubscriptionsTrackerDateRange}
        isLoading={false}
        loadingMessage={t('ui.loading')}
        noOptionsMessage={t('ui.noOptions')} />
      <AdminSelect
        id="scope"
        propStyles={{ propertyMenu: styles.filterDropdown }}
        options={scopeOptions}
        defaultValue=""
        placeholder={t('Dashboard.subscriptionsTracker.scopesTitle')}
        value={subscriptionsTrackerScope}
        onChange={setSubscriptionsTrackerScope}
        isLoading={false}
        loadingMessage={t('ui.loading')}
        noOptionsMessage={t('ui.noOptions')} />
    </div>
    }
  >
    <Table
      customStyle={styles.table}
      customWidths={(smallMobileView && ['50%', '50%']) || (mobileView && ['50%', '50%']) || (largeView && ['25%', '75%'])}
      itemsPerPage={Math.min(subscriptionsTrackerData.length || 10, 10)}
      items={subscriptionsTrackerData}
      idField={'rpmId'}
      fields={fields}
      searchFilters={['name', 'nameAndScopes']}
      showFields={!smallMobileView ? ['name', 'scopes'] : ['nameAndScopes']}
      isLoading={isSubscriptionTrackerLoading}
      detailsDropdown={detailsDropdown}
      detailsDropdownClass={'pl-3'}
      customPaginate={true}
      customPaginationTotalSize={totalItems}
      customPage={subscriptionsTrackerPage}
      setCustomPage={setSubscriptionsTrackerPage}
      setCustomSearchFilter={setSubscriptionsTrackerFilter}
      placeholder="Filter by property name"
      rowPlaceholderHeight="70px"
    />
  </DashboardCard>
}

export default SubscriptionsTracker