import React from 'react';

const Back = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.06653 1L1.00006 6M1.00006 6L5.06653 11M1.00006 6L13.2664 6" stroke="currentColor" strokeWidth="1.5" />
    </svg>

  );
}

export default Back;