import { useEffect, useState } from 'react'
import useTranslations from './useTranslations'
import PropTypes from 'prop-types'

//Fetch work worders
const useSpaces = ({
  companyName,
  propertyName,
  setIsLoading = () => {},
  toastError = () => {},
  axios,
  CancelToken,
  isCancel
}) => {
  const [t] = useTranslations()

  const [spaces, setSpaces] = useState([])

  useEffect(() => {

    const fetchSpaces = async () => {

      const source = CancelToken.source()

      try {
        setIsLoading(true);
        const params = [
          `property=${propertyName}`,
          `company=${companyName}`,
           `leases=true`,
        ]
        const paramsString = params.join('&')
        const result = await axios.get(`/spaces?${paramsString}`, {
          cancelToken: source.token
        });

        if (result.success) {
          setSpaces(result.records);
        } else {
          toastError(t('hooks.useAssets.error'))
        }
      } catch (e) {

        if (isCancel(e)) {
          // request is cancelled
        } else {
          toastError(t('hooks.useAssets.error'))
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (companyName && propertyName) {
      fetchSpaces()
    }

    return () => {
      CancelToken.source().cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, propertyName, setIsLoading])

  return [spaces]
}

useSpaces.propTypes = {
  companyGuid: PropTypes.string,
  propertyGuid: PropTypes.string,
  assetGuid: PropTypes.string,
  setIsLoading: PropTypes.func,
  toastError: PropTypes.func,
  axios: PropTypes.func,
  CancelToken: PropTypes.func,
  isCancel: PropTypes.func
}

useSpaces.defaultProps = {
  setIsLoading: () => {},
  toastError: () => {}
}

export default useSpaces