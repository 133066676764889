import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import axios from '../../utils/axios'
import { get } from '../../utils/utils'
import permissions from './permissions.js'
import Alert from '../../assets/images/alert'
import Home from '../../assets/images/home'
import HomeMajor from '../../assets/images/home-major'
import Savings from '../../assets/images/Savings.svg'
import Revenue from '../../assets/images/Revenue.js'

export const useLeaseRadarData = ({ setErrors, currentUser, setLoading, currentCompany, currentProperty }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  const [state, setState] = useState([])
  let leaseRadarLink = `/lease-radar${get(currentCompany, 'slug') ? `?company=${currentCompany.slug}` : ''}${get(currentCompany, 'slug') && get(currentProperty, 'slug') ? `&property=${currentProperty.slug}` : ''}`

  useEffect(() => {
    const fetch = async () => {

      try {
        setErrors([])
        setLoading(true)

        const results = await axios.post(`/reports/lease-radar`, {company: get(currentCompany, 'value'), property: get(currentProperty, 'name') })

        if (results.status === 200) {
          let reportData = get(results, 'data.result', {})

          let formattedReportData = [
            { count: reportData.occupied || 0, label: t('LeaseRadarDetails.occupiedSpaces'), icon: <HomeMajor />, key: 'occupied', link: `${leaseRadarLink}&leaseStatus=occupied` },
            { count: reportData.expiring || 0, label: t('LeaseRadarDetails.expiration'), icon: <Alert />, key: 'expiring', link: `${leaseRadarLink}&leaseStatus=expiring` },
            { count: reportData.vacant || 0, label: t('LeaseRadarDetails.vacant'), icon: <Home />, key: 'vacant', link: `${leaseRadarLink}&leaseStatus=vacant` }
          ]

          setState(formattedReportData)
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setErrors([`${t('error.serverData')} (${e.response.data.errors})`])
        } else {
          setErrors([(e && e.message) || t('error.server')])
        }
        setState([])
      }
      finally {
        setLoading(false)
      }
    }

    if (currentCompany && currentCompany.value && permissions.leaseRadar.includes(get(currentUser, 'type'))) fetch()
    if (!currentCompany || !currentCompany.value) setState([
      { count: 0, label: t('LeaseRadarDetails.occupiedSpaces'), icon: <HomeMajor />, link: `${leaseRadarLink}&leaseStatus=occupied` },
      { count: 0, label: t('LeaseRadarDetails.vacant'), icon: <Home />, link: `${leaseRadarLink}&leaseStatus=vacant` },
      { count: 0, label: t('LeaseRadarDetails.expiration'), icon: <Alert />, link: `${leaseRadarLink}&leaseStatus=expiring` }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, currentProperty])

  return state
}


//Updates need to be made here to properly integrate amp report api data
//Make sure if data is not present or successfully present, state is set to null
//Make sure to leave the format of the data below intact. Only count needs to be updated.
export const useAmpReportData = ({ currentCompany, setErrors, currentUser, setLoading, reportsBody }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  const [state, setState] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      try {
        setErrors([])
        setLoading(true)

        const results = await axios.post(`/reports/amp-summary`, reportsBody)
        if (results.status === 200) {
          let reportData = get(results, 'data.result', {})

          let formattedReportData = [
            {
              count: `$${parseFloat(reportData.cost).toFixed(2)}`,
              label: t('Dashboard.amp.totalCost'),
              icon: <img src={Savings} alt='savings' />,
              key: 'cost',
              link: `/reports/amp?company=${currentCompany.guid}`
            },
            {
              count: `$${parseFloat(reportData.revenue).toFixed(2)}`,
              label: t('Dashboard.amp.totalCharged'),
              icon: <Revenue backgroundColor='#ecdcf4' />,
              key: 'revenue',
              link: `/reports/amp?company=${currentCompany.guid}`
            }
          ]

          setState(formattedReportData)
        }
      } catch (e) {
        // ignore error, should only happen if current user
        // or requested company has NO RECORDS in int-muc-meter-data,
        // so just hide the window (auto hidden when state = null)
        setState(null)
      }
      finally {
        setLoading(false)
      }
    }

    if (reportsBody && reportsBody.length && permissions.ampReports.includes(get(currentUser, 'type'))) fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsBody])

  return state
}