import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import classNames from 'classnames';
import { get, removeSpacesAndMakeLowerCase } from '../utils/utils';
import styles from './Table.module.css';
import DefaultImage from '../assets/images/unknown-unit.png';
import SearchIcon from '../assets/images/search-icon.js';
import ChevronLeft from '../assets/images/chevron-left';
import ChevronRight from '../assets/images/chevron-right';
import Dot from '../assets/images/dot';
import DownArrow from '../assets/images/down-arrow';
import { Button } from 'react-bootstrap';
import Check from '../assets/images/check';
import Down from '../assets/images/down';
import { useIntl } from 'react-intl';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useDebounce } from '../utils/hooks';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link } from 'react-router-dom';
require('moment/locale/es-mx.js');
require('moment/locale/en-ca.js');
require('moment/locale/es.js');
require('moment/locale/fr-ca.js');
require('moment/locale/fr.js');

/*************************************************************************
Table Component
=======================================================
This table is meant to be flexible and conform to any necessary tabular structure.

idField* - The key of the field that acts as the unique identifier for the object

fields[]* - {
  //The definitions for each column of the table. Will determine how data is represented.

  -id*: Unique amongst fields. Must correspond to key in item if 'details' or 'status' type

  -label: Column header

  -type*:
    +details = traditional text representation
      ~options = {
        //Display photo next to value
        photo: {
          key: key of photo within item that you want to display
          objectFit: 'cover'|'contain' ('contain' by default)
          circleBorder: true|false
          noBorder: true|false
        }
        //Display circletitle next to value
        circleTitle: true|false (supercedes photo option above)
        //Concatenate other fields into same cell
        concatValues: {
          keys: [] (keys of fields you would like to concatenate),
          separator: ' ' - default (string you wish to place between concatenated values)
        }
      }

    +datetime = epoch timestamp formatted to give moment.js format
      ~format = YYYY-MM-DD hh:mm:ss - default (moment.js format)
      ~incomingFormat = expected formatting of value - default (unix epoch value)

    +status = highlights data certain color based on given condition
      ~options = {
        noDot: true|false (hide dot on status label)
      }
      ~statusColors = [
        {
          condition: (value) => {...} (function that determines if condition has been met)
          color: {backgroundColor: '...', color: '...'} (style to apply if condition above met)
        }
      ]

    +actions = dropdown menu of actions that may be applied to given item
      ~actions = [
        {
          label: dropdown label,
          click: (item) => {...} (Function to perform if action clicked. Item passed as arg.)
        }
      ]

    +button = button with given click handler
      ~click: click: (item) => {...} (Function to perform if button clicked. Item passed as arg.)
      ~options = {
        buttonType: 'underline'
        color: '...' (color of text)
        variant: 'danger'|'primary'|etc (bootstrap button variant)
      }

    +dropdown = checkbox dropdown that allows multiple value selection
      ~select: handles when checkbox selected (must handle adding/removing option from array manually - see storybook)
      ~unselect: handles when checbox unselected
      ~selectOptions: [
        {
          label: display value
          value: actual value to be used
        }
      ]
    +fragment = custom react element / fragment rendered outside of this component
      ~renderFragment: function that receives the current item and should return a fragment that
        will be embedded in the table.
  }
  label - Title for table
  placeholder - placeholder text for search filter
  itemsPerPage - max items to be shown on each page
  items[]* - array of items to be displayed
  searchFilters[] - keys that the data may be searched on. Also acts as a mechanishm for sorting the data.
    The data is sorted on each with the first item having highest priority, so order matters.
  tabs[] - {
    //Tabs allow for multiple data sets or an altered version of a dataset to be displayed on the same table
    //The click handler allows for doing these alterations to the props outside of the table component
    label: tab header,
    click: function to be called when tab is selected
  }
  itemLabels{} - {
    //Used to translate data values are more constant in nature (i.e. active, inactive, etc.)
    [key of field that may have translatable values] : {
      [value that needs translation]: translation (i.e. t('SearchTable.active'))
    }
  }
  customWidths[] - custom widths corresponding to each column
  onRowClick()=>{} - handler clicking on a table row (item passed back as arg)
  checkSelectable - activates check selection to left of items
  checkSelectedItems - items checked
  setCheckSelectedItems - set items checked
  checkSelectAll[] - {
    label: label for checkbox filter dropdown
    filter: filter function used for checking boxes (i.e. _ => _.status === 'active')
  }
  showFields[] - fields to show (used for responsiveness)
  hidePaginate - hide paginator
  hideTabs - hide tabs at top
  hideSearch - hide search filter
  hideTableHeader - hide table header
*************************************************************************/
const DropdownCheckBox = ({ checked, select, unselect }) => {
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked])

  return <div data-testid='dropdown-checkbox' onClick={check ? () => { unselect(); setCheck(false) } : () => { select(); setCheck(true); }} className={check ? styles.checked : styles.unchecked}>{check && <Check />}</div>
}

const Dropdown = ({ field, item, disabled = [], idField, test }) => {
  const [showList, setShowList] = useState(test || false);
  const listRef = useRef();

  return (
    <div tabIndex="0" ref={listRef} className={styles.actions} onClick={() => listRef.current.focus()} onBlur={() => setShowList(false)}>
      <div className={styles.actionDropdown} onClick={disabled.includes(item[idField]) ? () => { } : () => setShowList(prevShow => !prevShow)}>
        <div data-testid='checkbox-dropdown-label'>{field.dropdownLabel || field.label}</div>
        <span><DownArrow /></span>
      </div>
      {showList &&
        <div className={styles.actionsList} data-testid='checkbox-dropdown-list'>
          {(field.selectOptions && field.selectOptions.map(_ =>
            <div key={_.value}>
              <DropdownCheckBox checked={item[field.id].includes(_.value)} select={() => field.select(_.value, item)} unselect={() => field.unselect(_.value, item)} />
              <div data-testid='checkbox-dropdown-option' datacy={`table-dropdown-${_.value}`}>{_.label || _.value}</div>
            </div>
          ))}
        </div>
      }
    </div>
  );
}


const SideMenu = ({ field, item, test }) => {
  const [showList, setShowList] = useState(test || false);
  const listRef = useRef();

  const MenuOption = ({
    icon,
    label,
    onClick,
    value,
  }) => (
    <div onClick={onClick}>
      {icon}
      <div
        className={styles.sideMenuLabel}
        data-testid='checkbox-dropdown-option'
        datacy={`table-dropdown-${removeSpacesAndMakeLowerCase(label)}`}
      >{label || value}</div>
    </div>
  );

  return (
    <div tabIndex="0" ref={listRef} className={styles.sideMenu} onClick={() => listRef.current.focus()} onBlur={() => setShowList(false)} data-testid="side-menu">
      <div className={styles.sideMenuDropdown} onClick={() => setShowList(prevShow => !prevShow)} data-testid="side-menu-button" datacy="table-details-button">
        <BsThreeDotsVertical />
      </div>
      {showList &&
        <div className={styles.sideMenuList} data-testid='side-menu-dropdown-list' datacy="table-details-list">
          {(field.menuOptions && field.menuOptions.filter((option) => {
            // Do not remove items that don't have the constraints
            if (!option.condition) {
              return true;
            }

            return option.condition(item);
          }).map(option => (
            <MenuOption
              key={option.label}
              icon={option.icon}
              label={option.label}
              onClick={() => option.click(item)}
              value={option.value}
              datacy={`table-details-list-${option.label}`}
            />
          )))}
        </div>
      }
    </div>
  );
}

const SortArrows = ({ sort }) => {
  return <div className={styles.sortArrows}>
    <div className={`${styles.topArrow} ${sort === 1 ? styles.arrowSelected : ''}`} datacy='sort-arrow-up'><DownArrow /></div>
    <div className={`${styles.bottomArrow} ${sort === -1 ? styles.arrowSelected : ''}`} datacy='sort-arrow-down'><DownArrow /></div>
  </div>
}

const Table = ({
  idField,
  fields = [],
  label = "",
  placeholder = "",
  itemsPerPage = 10,
  items = [],
  searchFilters = [],
  sortFields = [],
  defaultSort = {},
  tabs = [],
  checkSelectable,
  checkSelectedItems = [],
  setCheckSelectedItems,
  checkSelectAll = [],
  showFields = [],
  itemLabels = {},
  onRowClick,
  customWidths = [],
  hidePaginate,
  hideTabs,
  hideSearch,
  hideTableHeader,
  hideItemCount,
  scrollHeight,
  isLoading,
  disabled = [],
  scrollX,
  children,
  test,
  customSelectedTab,
  rowPlaceholderHeight = '70px',
  borderBottomLastItem,
  customStyle,
  customPaginate,
  customPaginationTotalSize,
  customPage = null,
  isQueryChange = () => { },
  setCustomPage = () => { },
  setCustomSearchFilter = () => { },
  setCustomSort = () => { },
  headerButton,
  inspectionSchedulesCustomSorter,
  renderCustomButton,
  detailsDropdown = [],
  statusRowColors = [],
  detailsDropdownClass = '',
  detailsDropdownFilterClass = ''
}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [allChecked, setAllChecked] = useState(false);
  const [clickedHeader, setClickedHeader] = useState(defaultSort);
  const [showDetailsIds, setShowDetailsIds] = useState([]);

  const debouncedSearchFilter = useDebounce(searchFilter, 400);
  const defaultSortDesc = defaultSort.descending;
  const defaultSortKey = defaultSort.key;

  useEffect(() => {
    setCustomSearchFilter(debouncedSearchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchFilter])

  useEffect(() => {
    setShowDetailsIds([]);
  }, [page])

  useEffect(() => {
    setClickedHeader(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSortDesc, defaultSortKey])

  useEffect(() => {
    setPage(0);
    setShowDetailsIds([]);
  }, [isLoading, searchFilter, selectedTab, idField])

  useEffect(() => {
    setSelectedTab(customSelectedTab || 0)
  }, [customSelectedTab])

  useEffect(() => {
    isQueryChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, debouncedSearchFilter, clickedHeader, customPage]);

  useEffect(() => {
    setCustomPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, debouncedSearchFilter, clickedHeader])

  const renderField = (field, item, i) => {
    if (field.type === 'details') return renderDetails(field, item);
    if (field.type === 'status') return renderStatus(field, item);
    if (field.type === 'actions') return <Actions field={field} item={item} />;
    if (field.type === 'button') return renderButton(field, item);
    if (field.type === 'dropdown') return <Dropdown field={field} item={item} idField={idField} test={test} disabled={disabled} />;
    if (field.type === 'datetime') return renderDatetime(field, item);
    if (field.type === 'sideMenu') return <SideMenu item={item} field={field} />
    if (field.type === 'fragment') return renderFragment(field, item, i);
    if (field.type === 'currency') return renderMoney(field, item, field.currency)

    return <div>{item[field.id]}</div>
  }

  const renderDetails = (field, item = {}) => {
    let fieldSchema = field;

    if (field.conditionalSchemas) {
      for (let _ of field.conditionalSchemas) {
        if (_.condition(item)) {
          fieldSchema = _.schema;
          break;
        }
      }
    }

    const photoKey = get(fieldSchema, 'options.photo.key');
    const image = photoKey ? item[photoKey] || DefaultImage : null;

    const photo = image &&
      <div className={styles.detailsPhoto}
        style={{
          border: get(fieldSchema, 'options.photo.noBorder', false) ? 'none' : undefined,
          borderRadius: get(fieldSchema, 'options.photo.circleBorder', false) ? '100%' : undefined,
          width: get(fieldSchema, 'options.photo.width', false) ? fieldSchema.options.photo.width : undefined,
          height: get(fieldSchema, 'options.photo.height', false) ? fieldSchema.options.photo.height : undefined,
        }}>
        <img data-testid="details-photo" id="details-photo" src={(typeof image === 'string' && image) || (image && (image.thumbnail || image.standard || image.original)) || DefaultImage}
          style={{
            objectFit: get(fieldSchema, 'options.photo.objectFit', undefined),
            borderRadius: get(fieldSchema, 'options.photo.circleBorder', false) ? '100%' : undefined
          }} alt={item} /></div>;

    let circleLetters;
    let circleTitle;
    if (get(fieldSchema, 'options.circleTitle', false)) {
      circleLetters = (item[field.id] || '').split(' ')
      circleTitle =
        <div data-testid="circle-title" id="circle-title" className={styles.circleTitle}>
          {circleLetters.length > 1 ?
            (circleLetters[0].slice(0, 1) + circleLetters[1].slice(0, 1)).toUpperCase() :
            circleLetters[0].slice(0, 2).toUpperCase()
          }
        </div>
    }

    // Default
    let label = item[field.id];

    /**
     * We're not using the `get` helper fn here because we are accessing
     * object keys by user-generated values. Contenxt:
     * There was a strange side effect of `get` where it will get a "bad"
     * lookup string by using something like this:
     *
     *    get(itemLabels, `${field.id}.${item[field.id]}`, item[field.id]);
     *
     * That middle section ended up generating a key pair like
     * 'Name.Custom Task 6.14'. Now, because of its internal workings, `get` splits
     * the string on `.` causing it to look for a "Custom Task 6" in a "Name"
     * instead of the proper name. If that "Custom Task 6" does not exist, it will default
     * to the field default value, causing it to render incorrect data as a "Name"
     * instead of its actual name given by the user.
     * This issue was first discovered in IDInspect, in the Catalog Tasks table.
     *
     * Can this item data, field schema, and lookup logic be improved? Yes, so consider this
     * block below a workaround/patch more than a proper refactor/improvement.
     */
    if (
      itemLabels &&
      itemLabels[field.id] &&
      itemLabels[field.id][item[field.id]]
    ) {
      label = itemLabels[field.id][item[field.id]];
    }

    let concatKeys = get(fieldSchema, 'options.concatValues.keys');
    let concatSeparator = get(fieldSchema, 'options.concatValues.separator', ' ')

    if (concatKeys && concatKeys.length) {
      concatKeys.forEach(_ => {
        if ((get(itemLabels, `${_}.${item[_]}`) || item[_])) {
          label += concatSeparator + get(itemLabels, `${_}.${item[_]}`, item[_]);
        }
      });
    }

    return (
      <div className={`${styles.details} ${get(fieldSchema, 'options.style', '')}`}>
        {circleTitle || photo}
        <div className={styles.detailsText} data-testid="details-label" datacy={`table-row-${removeSpacesAndMakeLowerCase(field?.label)}`}>{label}</div>
      </div>
    );
  }

  const renderDatetime = (field, item = {}) => {
    const fieldValue = field.incomingFormat ? item[field.id] : parseInt(item[field.id])

    return <div className={styles.details} data-testid="datetime" datacy="table-row-date">
      {
        item[field.id]
        && moment(fieldValue, field.incomingFormat)
          .locale(intl.locale)
          .format(field.format || 'YYYY-MM-DD hh:mm:ss')
      }
    </div>
  }

  const getStatusColor = (statusColors, item) => {
    let statusColor = {
      backgroundColor: 'rgba(109, 113, 117, 0.1)',
      color: '#6d7175'
    };

    for (let _ of statusColors) {
      if (_.condition(item)) {
        statusColor = _.color;
        break;
      }
    }

    return statusColor;
  }

  const renderStatus = (field, item = {}) => {

    let statusColor = getStatusColor(field.statusColors, item);

    return (
      <div className={styles.status}>
        <div data-testid="status" style={statusColor}>
          {!get(field, 'options.noDot') &&
            <div><Dot /></div>
          }
          <div className="m-0" data-testid="status-label" datacy={`table-row-${removeSpacesAndMakeLowerCase(field?.label)}`}>{field.formatLabel ? field.formatLabel(item[field.id]) : get(itemLabels, `${field.id}.${item[field.id]}`, item[field.id])}</div>
        </div>
      </div>
    );
  }

  const renderMoney = (field, item = {}, currency = 'USD') => {
    const amount = item[field.id]
    return <div className={styles.details} data-testid="renderMoney" datacy={`table-row-${removeSpacesAndMakeLowerCase(field?.label)}`}>
      {
        amount ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
        }).format(amount) : '-'
      }
    </div>
  }

  const renderButton = (field, item) => {
    if (field.renderCustomButton) return field.renderCustomButton(item);

    let fieldSchema = field;

    if (field.conditionalSchemas) {
      for (let _ of field.conditionalSchemas) {
        if (_.condition(item)) {
          fieldSchema = _.schema;
          break;
        }
      }
    }

    if (fieldSchema.hide) return <div></div>;

    const FormattedLink = ({ children }) => {
      let link;

      if (get(fieldSchema, 'options.link')) {
        //Uses html link component. This opens in a seperate tab by default.
        link = <a href={get(fieldSchema, 'options.link')(item)} target='_blank' rel='noopener noreferrer' className={styles.link}>{children}</a>;
      } else if (get(fieldSchema, 'options.reactLink')) {
        //React Link uses Link component from react-router-dom. This allows us to be able to click a link
        //and have it open in the same tab, while also being able to left-click and have it open in the same
        //tab.
        link = <Link to={get(fieldSchema, 'options.reactLink')(item)} className={styles.link}>{children}</Link>;
      } else {
        link = <>{children}</>;
      }

      return link;
    }

    if (get(fieldSchema, 'options.buttonType') === 'underline') {
      return (
        <FormattedLink>
          <div data-testid="button-underline" className={`${styles.underlineButton} d-flex justify-content-center align-items-center`}
            style={{ color: get(fieldSchema, 'options.color', undefined), borderColor: get(fieldSchema, 'options.color', undefined) }}
            onClick={disabled.includes(item[idField]) ? () => { } : () => field.click(item)}>
            {field.buttonLabel}
          </div>
        </FormattedLink>)
    }
    return (
      <FormattedLink>
        <Button data-testid="button-normal" variant={get(field, 'options.variant') || "primary"} onClick={disabled.includes(item[idField]) ? () => { } : () => field.click(item)} className='btn-prime'>
          {fieldSchema.buttonLabel}
        </Button>
      </FormattedLink>
    )
  }

  const renderFragment = (field, item, i) => {

    return (
      <div data-testid="fragment-field" datacy={`table-row-${removeSpacesAndMakeLowerCase(field?.label)}`}>
        {!field.hide && field.renderFragment(item, i)}
      </div>
    );
  }

  const Actions = ({ field, item }) => {
    const [showList, setShowList] = useState(test || false);

    const listRef = useRef();

    return (
      <div data-testid="actions-dropdown" tabIndex="0" ref={listRef} className={styles.actions} onClick={() => listRef.current.focus()} onBlur={() => setShowList(false)}>
        <div className={styles.actionDropdown} onClick={() => setShowList(prevShow => !prevShow)}>
          <div>{field.label}</div>
          <span><DownArrow /></span>
        </div>
        {showList &&
          <div data-testid="actions-dropdown-list" className={styles.actionsList}>
            {(field.actions && field.actions.map(_ =>
              <div key={_.label} onClick={() => _.click(item)}>{_.label}</div>
            ))}
          </div>
        }
      </div>
    );
  }

  const CheckBox = ({ checked, select, unselect }) => {
    return <div data-testid="check-select" onClick={checked ? unselect : select} className={checked ? styles.checked : styles.unchecked}>{!!checked && <Check />}</div>
  }

  const CheckSelectAll = () => {
    const [showDropdown, setShowDropdown] = useState(test || false);

    const selectAll = (filter) => {
      setAllChecked(true);
      setCheckSelectedItems(items.filter(filter));
    }

    return (
      <div className="d-flex align-items-center position-relative">
        <CheckBox
          checked={allChecked}
          select={() => selectAll(_ => true)}
          unselect={() => { setCheckSelectedItems([]); setAllChecked(false); }} />
        <div className={`${styles.checkAllListDown} d-flex align-items-center justify-content-center`}
          onClick={() => setShowDropdown(!showDropdown)}><Down /></div>
        {showDropdown &&
          <div className={styles.checkAllList}>
            {checkSelectAll.map(_ =>
              <div data-testid="check-select-type" key={_.label} onClick={
                () => { selectAll(_.filter); setAllChecked(true); }
              }>{_.label}</div>
            )}
          </div>
        }
      </div>
    );
  }

  const checkSelectItem = (item) => {
    const newCheckSelectedItems = [...checkSelectedItems, item];

    setCheckSelectedItems(newCheckSelectedItems);
  }

  const removeCheckSelectedItem = (itemId) => {
    const newCheckSelectedItems = checkSelectedItems.filter(_ => _[idField] !== itemId);

    setCheckSelectedItems(newCheckSelectedItems);
  }

  let currentItems = items;
  if (!customPaginate) {
    currentItems = items.filter(item => (
      ((searchFilters.length && searchFilters) || [idField]).map(_ => (((item[_] && item[_].toString()) || '').toLowerCase()))
        .join('').match(new RegExp(searchFilter.toLowerCase()))
    ));
  }

  if (!customPaginate) [...searchFilters].reverse().forEach(_ => currentItems.sort((a, b) => (a[_] || '').toString().localeCompare((b[_] || '').toString(), undefined, { 'numeric': typeof a[_] === 'number' })));

  // this is for: https://idplans.atlassian.net/browse/IDI-653
  // the frequency field is the only field (for now) which needs to be sorted based on its weight property
  // and not their value (text in this case) and since this is only a requirement for InspectionSchedules
  // I made this new property to avoic having a better code and cause more issues or bugs since this Table
  // is used in other places
  // to check the usage of this inspectionSchedulesCustomSorter please go to InspectionSchedule.js#L103
  // if this property is not present then do the usual behavior
  if (inspectionSchedulesCustomSorter) {
    currentItems = inspectionSchedulesCustomSorter({ currentItems, clickedHeader, customPaginate })
  } else if (clickedHeader.key && !customPaginate) {
    currentItems.sort((a, b) => {
      if (clickedHeader.descending) {
        return (a[clickedHeader.key] || '').toString().toLowerCase().localeCompare((b[clickedHeader.key] || '').toString().toLowerCase(), undefined, { 'numeric': typeof a[clickedHeader.key] === 'number' });
      } else {
        return (b[clickedHeader.key] || '').toString().toLowerCase().localeCompare((a[clickedHeader.key] || '').toString().toLowerCase(), undefined, { 'numeric': typeof b[clickedHeader.key] === 'number' });
      }
    })
  }

  let currentItemsLength = currentItems.length;
  currentItems = !itemsPerPage ? currentItems : currentItems.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

  let scrollXStyle = scrollX ? { overflowX: 'scroll' } : {};

  const pageCounterLabel = useMemo(() => {
    const current = (customPaginate ? customPage : page) + 1
    const itemCount = customPaginate ? customPaginationTotalSize : items.length
    // 0 items divided 10 items per page should not return "Page 0" - Should be 1
    const total = (itemCount >= itemsPerPage) ? Math.ceil(itemCount / itemsPerPage) : 1

    return intl.formatMessage({
      id: 'SearchTable.pageCounter'
    }, {
      current,
      total
    })
  }, [customPaginate, customPage, customPaginationTotalSize, intl, items.length, itemsPerPage, page])

  const rowPlaceholderHeightNumeric = useMemo(() => Number(rowPlaceholderHeight.replace(/px$/, '')), [rowPlaceholderHeight])


  /** Custom Pagination stuff */

  const customPaginatorPlaceholderHeight = useMemo(() => (
    `${rowPlaceholderHeightNumeric * itemsPerPage}px`
  ), [itemsPerPage, rowPlaceholderHeightNumeric])

  const humanizedPageNumber = useMemo(() => customPage + 1, [customPage])

  const isCustomPaginationRightButtonDisabled = useMemo(() => (
    itemsPerPage * humanizedPageNumber >= customPaginationTotalSize || isLoading
  ), [customPaginationTotalSize, humanizedPageNumber, isLoading, itemsPerPage])

  const isCustomPaginationLeftButtonDisabled = useMemo(() => (
    humanizedPageNumber === 1 || isLoading
  ), [humanizedPageNumber, isLoading])

  const handleCustomPaginationLeftButtonClick = useCallback(
    () => {
      if (humanizedPageNumber > 1) {
        setCustomPage(customPage - 1)
      }
    },
    [customPage, humanizedPageNumber, setCustomPage]
  )

  const handleCustomPaginationRightButtonClick = useCallback(
    () => {
      if (humanizedPageNumber * itemsPerPage < customPaginationTotalSize) {
        setCustomPage(customPage + 1)
      }
    },
    [customPage, customPaginationTotalSize, humanizedPageNumber, itemsPerPage, setCustomPage]
  )

  /** End of Custom Pagination stuff */

  return (
    <div className={`${styles.searchComponent} ${customStyle}`} data-testid="table-container" datacy='table-header'>
      {!hideTabs && (tabs.length || label) &&
        <div data-testid='tabs' datacy='table-header-tabs' className={`d-flex justify-content-start ${styles.tabs}`}>
          {tabs.length ?
            tabs.map((_, i) => {
              const tabId = _.id ? _.id : i
              const isSelected = selectedTab === i
              return (
                <div
                  key={_.label}
                  data-testid={`tab-id-${tabId}`}
                  datacy={`tab-id-${tabId}`}
                  role="tab"
                  aria-selected={isSelected ? 'true' : 'false'}
                  className={isSelected ? styles.selectedTab : ''}
                  onClick={() => { setPage(0); setCustomPage(0); setSelectedTab(i); _.click() }}
                >{_.label}</div>
              )
            })
            :
            <div className={styles.selectedTab}>{label}</div>
          }
        </div>}
      {!hideSearch &&
        <div data-testid="search" className={styles.search}>
          <div className={`w-100 d-flex ${styles.searchFilter}`} >
            <div className="d-flex justify-content-center align-items-center">
              <SearchIcon />
            </div>
            <input data-testid="search-input" datacy='table-search-input' value={searchFilter} onChange={(e) => { setSearchFilter(e.target.value); }} placeholder={placeholder} />
          </div>
          {!hideItemCount &&
            <div className={`${styles.itemCount} d-flex justify-content-between`} datacy='table-item-count'>
              <div>{pageCounterLabel}</div>
              {headerButton && <div>{headerButton}</div>}
            </div>
          }
        </div>
      }
      <div className={`${styles.table} d-flex flex-column`} style={scrollHeight && items.length ? { height: scrollHeight, overflowY: 'scroll', borderBottom: '1px solid #e4e4e4', ...scrollXStyle } : { ...scrollXStyle }}>
        <table data-testid="table" datacy='main-table-content'>
          {!hideTableHeader &&
            <thead data-testid="thead" datacy='main-table-tabs'>
              <tr>
                {statusRowColors.length > 0 && <th className={styles.statusRowColorsHeader}></th>}
                {checkSelectable && <th className={styles.checkSelectAll}><CheckSelectAll /></th>}
                {typeof detailsDropdown === 'function' || (detailsDropdown.length && Array.isArray(detailsDropdown)) ? <th className={styles.detailsDropdownHeader}></th> : null}
                {fields.map((field, i) =>
                  showFields && showFields.length && !showFields.includes(field.id) ?
                    null :
                    <th
                      datacy={`table-header-${field.id}`}
                      key={field.id}
                      style={i < customWidths.length ? { [scrollX ? 'minWidth' : 'width']: customWidths[i] } : {}}
                      onClick={() => {
                        if (!['details', 'datetime', 'status', 'currency', 'fragment'].includes(field.type)) return;
                        if (!sortFields.includes(field.id)) return;
                        if (clickedHeader && clickedHeader.key === field.id) {
                          setClickedHeader({ key: field.id, descending: !clickedHeader.descending });
                          setCustomSort({ key: field.id, descending: !clickedHeader.descending, clickedHeader });
                        } else {
                          setClickedHeader({ key: field.id, descending: true });
                          setCustomSort({ key: field.id, descending: true, clickedHeader });
                        }
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div>{field.label}</div>
                        {sortFields.includes(field.id) &&
                          <SortArrows sort={clickedHeader.key === field.id ? clickedHeader.descending ? -1 : 1 : 0} />}
                      </div>
                    </th>)}
              </tr>
            </thead>
          }
          {currentItemsLength > 0 && !isLoading ?
            <tbody data-testid="tbody">
              {currentItems.map((item, i) => {
                const itemRow = fields.map((field, j) => {
                  if (showFields && showFields.length && !showFields.includes(field.id)) return null;

                  const data = renderField(field, item, i);

                  return <td
                    key={field.id}
                    style={{ [scrollX ? 'minWidth' : 'width']: customWidths && customWidths.length > j ? customWidths[j] : undefined }}
                  >{data}</td>;
                })

                let detailsDropdownData;
                let detailsDropdownFields = []

                if (typeof detailsDropdown === 'function') {
                  detailsDropdownFields = detailsDropdown(item)
                } else if (Array.isArray(detailsDropdown)) {
                  detailsDropdownFields = [...detailsDropdown]
                }

                if (detailsDropdownFields.length && showDetailsIds.includes(get(item, idField))) {
                  detailsDropdownData = fields.filter(_ => detailsDropdownFields.includes(_.id)).map((field, j) => {
                    const data = renderField(field, item);

                    return <div
                      key={field.id}
                      className={styles.detailsData}
                    ><div className={styles.label}>{field.label}</div>{data}</div>;
                  })
                }

                let isLastItem = !borderBottomLastItem && (rowPlaceholderHeight ? i === itemsPerPage - 1 : i === currentItems.length - 1);
                let detailsDropdownStyle = { height: showDetailsIds.includes(get(item, idField)) ? "auto" : '0px' };

                return <React.Fragment key={item[idField]}>
                  <tr key={get(item, idField)} onClick={onRowClick ? () => onRowClick(item, i) : () => { }}
                    className={`${disabled.includes(item[idField]) ? styles.disabled : ''} ${isLastItem ? styles.noBottomBorder : ''}`}
                    style={onRowClick ? { cursor: 'pointer' } : {}}
                    id="row"
                    data-testid={disabled.includes(item[idField]) ? 'disabled' : 'data-row'}
                    datacy='data-row'>
                    {statusRowColors.length > 0 && rowPlaceholderHeight &&
                      <td className={styles.statusRowColors} style={{ height: rowPlaceholderHeight }}>
                        <div style={{ backgroundColor: (getStatusColor(statusRowColors, item) || {}).backgroundColor }}></div>
                      </td>
                    }
                    {checkSelectable &&
                      <td>
                        <CheckBox
                          checked={checkSelectedItems.some(_ => _[idField] === item[idField])}
                          select={() => checkSelectItem(item)}
                          unselect={() => removeCheckSelectedItem(item[idField])} />
                      </td>
                    }
                    {detailsDropdownFields.length ?
                      <td className={showDetailsIds.includes(get(item, idField)) ? styles.detailsDropdownArrowOpen : styles.detailsDropdownArrowClosed}>
                        <div data-testid="details-dropdown" datacy="details-dropdown" className={styles.sideMenuDropdown} onClick={() => setShowDetailsIds(prevDetailsIds => showDetailsIds.includes(get(item, idField)) ? prevDetailsIds.filter(_ => _ !== get(item, idField)) : [...prevDetailsIds, get(item, idField)])}><Down /></div>
                      </td> : (typeof detailsDropdown === 'function' ? <td></td> : null)
                    }
                    {itemRow}
                  </tr>
                  <tr className={showDetailsIds.includes(get(item, idField)) ? styles.detailsDropdownOpen : styles.detailsDropdownClosed} style={detailsDropdownStyle}>
                    <td colSpan={fields?.length} className="p-0">
                      <div className={`${styles.detailsDropdownFilter} ${detailsDropdownFilterClass}`} style={detailsDropdownStyle}></div>
                      <div style={detailsDropdownStyle} className={detailsDropdownClass}>
                        {detailsDropdownData}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              })}
              {rowPlaceholderHeight && (currentItems.length < itemsPerPage) &&
                [...Array(itemsPerPage - currentItems.length)].map((_, i) => <tr key={i} style={{ height: rowPlaceholderHeight, border: 'none' }}></tr>)
              }
            </tbody> :
            <tbody data-testid="tbody"></tbody>
          }
        </table>
      </div>
      {currentItemsLength === 0 && !isLoading &&
        <div className={`${styles.noResults} w-100 d-flex justify-content-center`}>
          {t('ui.noResults')}
        </div>
      }
      {isLoading &&
        <div
          className="py-5 w-100 d-flex justify-content-center"
          style={{ height: customPaginatorPlaceholderHeight }}
        >
          <Spinner animation="grow"
            className={"SpinnerMedum"}
            variant="primary" />
        </div>
      }
      {!hidePaginate && !(customPaginate && customPage !== null) &&
        <div data-testid="paginator" datacy="table-paginator" className={`d-flex justify-content-center align-items-center w-100 ${styles.paginator}`}>
          <div className="d-flex">
            <div className={`${styles.leftChevron} ${page === 0 ? styles.paginateDisabled : ''} d-flex justify-content-center align-items-center`}
              onClick={() => setPage(prevPage => prevPage === 0 ? prevPage : prevPage - 1)}>
              <div data-testid='left-click' datacy="table-prev-button" >
                <ChevronLeft />
              </div>
            </div>
            <div className={`${styles.rightChevron} ${page === Math.ceil(currentItemsLength / itemsPerPage) - 1 || currentItems.length === 0 ? styles.paginateDisabled : ''} d-flex justify-content-center align-items-center`}
              onClick={(() => setPage(prevPage => prevPage < Math.ceil(currentItemsLength / itemsPerPage) - 1 ? prevPage + 1 : prevPage))}>
              <div data-testid='right-click' datacy="table-next-button">
                <ChevronRight />
              </div>
            </div>
          </div>
        </div>
      }
      {!hidePaginate && (customPaginate && customPage !== null) &&
        <div data-testid="paginator" datacy="table-paginator" className={`d-flex justify-content-center align-items-center w-100 ${styles.paginator}`}>
          <div className="d-flex">
            <div
              className={classNames(
                styles.leftChevron,
                {
                  [styles.paginateDisabled]: isCustomPaginationLeftButtonDisabled
                },
                'd-flex justify-content-center align-items-center'
              )}
              onClick={handleCustomPaginationLeftButtonClick}
            >
              <div data-testid='left-click' datacy="table-prev-button">
                <ChevronLeft />
              </div>
            </div>
            <div
              className={classNames(
                styles.rightChevron,
                {
                  [styles.paginateDisabled]: isCustomPaginationRightButtonDisabled
                },
                'd-flex justify-content-center align-items-center'
              )}
              onClick={handleCustomPaginationRightButtonClick}
            >
              <div data-testid='right-click' datacy="table-next-button">
                <ChevronRight />
              </div>
            </div>
          </div>
        </div>
      }
      {children && <div data-testid="children" className={styles.children}>{children}</div>}
    </div>
  );
}

export default Table;
