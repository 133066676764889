import React from 'react';

const Check = () => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4L4.33333 7L11 1" stroke="currentColor" strokeWidth="2" />
    </svg>
  )
}

export default Check;