import React from 'react';
import styles from './MenuDropdown.module.css';
import './MenuDropdown.css';
import { Navbar } from 'react-bootstrap';
import MenuLink from './../MenuLink/MenuLink';
import { useIntl } from 'react-intl';
import { TeamBold, SettingsBold } from '../../assets/images/menu-icons/index';
import { useLocation } from 'react-router-dom';

const classNames = require('classnames');

const MenuItems = ({ items, hide }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const location = useLocation();

  const links = {
    Users: ['invite-team-member'],
    Vendors: ['vendor-search', 'vendor-invite'],
    Property: [],
    Company: []
  }

  if (!items) return null;

  return items.map((item) => {
    const active = links[item].includes(location.pathname.split('/')[1])
    return (
      <MenuLink key={item} name={item} title={t(`MenuDropdown.${item.toLowerCase()}`)} internalLink={true} groupedLink={true} hide={hide} isActive={active} />
    );
  });
}

const MenuDropdown = ({ items, hide, name, links = [] }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const location = useLocation();

  if (hide) return null;

  const menuGroups = {
    'Settings': {
      icon: <SettingsBold />,
      label: t('MenuDrawer.settings')
    },
    'Team': {
      icon: <TeamBold />,
      label: t('MenuDrawer.team')
    }
  }

  let activeDropdown = links.includes(location.pathname.split('/')[1]);

  return (
    <li className={`${styles.dropdownContainer} ${activeDropdown ? styles.activeDropdown : ''}`}>
      <Navbar expand="true" className={styles.navBar}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" bsPrefix="toggler">
          <div className="d-flex">
            <span className={classNames(styles.fNavIcon)}>{menuGroups[name].icon}</span>
            <span className={classNames(styles.menuDescription)}> {menuGroups[name].label} </span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" >
          <ul className="pl-0 m-0">
            <MenuItems items={items} hide={hide} />
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </li>
  );
}

export default MenuDropdown;