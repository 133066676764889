import React, { useEffect, useState, useCallback } from 'react';
import { AdminSelect } from '@project/components';
import axios from '../../utils/axios';
import { useIntl } from 'react-intl';

/*
  Fields for react-select to search properties on
*/
const SEARCH_FIELDS = [
  'name',
  'displayName',
  'state',
  'market',
  'address1',
  'state',
  'integrationIds'
]

function PropertySelect({ selectedCompany, selectedProperty, setSelectedProperty, selectClass, children, filterActive = false, label }) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      setSelectedProperty(null);
      setProperties([]);
      if (!selectedCompany) return;
      setIsLoading(true);
      const result = await axios.get(`/properties?company=${encodeURIComponent(selectedCompany.value)}`);
      const propertyOptions = result.data.records
        .filter(_ => _.status === 'active' || !filterActive)
        .map(({ name, displayName, status, integrations = [], market, address1, state, slug }) => ({
          value: name,
          label: status === 'inactive' ? <>{`${displayName || name} - `} <i>{t('ui.inactive')}</i></> : name || displayName,
          name,
          displayName,
          integrationIds: integrations.map(integration => integration.integrationId).join(''),
          market,
          address1,
          state,
          slug
        }));
      setIsLoading(false);
      setProperties(propertyOptions);
    }
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, setSelectedProperty]);

  /*
  Used for filtering within the react-select component. This is an override for
  the default 'value' search
  */
  const filterHandler = useCallback((option, searchValue) => {
    return SEARCH_FIELDS.map(field => (option.data[field] || '').toLowerCase())
      .join('')
      .match(new RegExp(searchValue.toLowerCase()))
  }, [])

  return (
    <AdminSelect label={label || t('term.property')}
      id="propertySelect"
      defaultValue=""
      value={selectedProperty}
      onChange={setSelectedProperty}
      filterOption={filterHandler}
      options={properties}
      placeholder={isLoading ? `${t('ui.loading')}...` : t('PropertySelect.select')}
      isLoading={isLoading}
      loadingMessage={t('ui.loading')}
      noOptionsMessage={t('ui.noOptions')}
    />
  )
}

export default PropertySelect;