import React, { useMemo } from 'react'

import styles from './DetailPane.module.css'
import ChevronLeft from '../assets/images/chevron-left'
import ChevronRight from '../assets/images/chevron-right'
import { MdClose, MdHourglassFull } from 'react-icons/md'
import { useTranslations } from '../utils/hooks'

export const DetailPane = ({
  visible = false,
  currentIndex = 1,
  totalItems = 1,
  allowNavigation = true,
  onPreviousClicked,
  onNextClicked,
  onCloseClicked,
  title,
  children,
  isSaving
}) => {
  const canClickPrevious = useMemo(() => allowNavigation && !isSaving && currentIndex > 0, [allowNavigation, currentIndex, isSaving])
  const canClickNext = useMemo(() => allowNavigation && !isSaving && currentIndex + 1 < totalItems, [allowNavigation, currentIndex, totalItems, isSaving])
  const [t] = useTranslations()

  return !visible ? <></> : <details open className={styles.detailPane}>
    <summary>{title}</summary>
    <nav className={styles.nav}>
      <li className={`${styles.navLeft} ${!canClickPrevious ? styles.navDisabled : ''}`} onClick={canClickPrevious ? onPreviousClicked : () => { }}><ChevronLeft /></li>
      <li className={styles.navCurrent}>
        {t('DetailPane.paging', { current: currentIndex + 1, total: totalItems })}
        {isSaving && <>- <strong><MdHourglassFull /> {t('ui.saving')}...</strong></>}
      </li>
      <li className={`${styles.navRight} ${!canClickNext ? styles.navDisabled : ''}`} onClick={canClickNext ? onNextClicked : () => { }}><ChevronRight /></li>
      <li className={styles.navClose} onClick={onCloseClicked}><MdClose size="16" /></li>
    </nav>
    {children}
  </details>
}