import React, {useRef} from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import ChevronLeft from "../assets/images/chevron-left";
import ChevronRight from "../assets/images/chevron-right";
import styles from './PhotoSlideShow.module.css';

const SlideShow = React.memo(({photos = [], border}) => {
  const slideRef = useRef();

  const LeftArrow = () => {
    return <div className={`${styles.LeftArrow} position-absolute`} onClick={() => slideRef.current.goBack()}><ChevronLeft /></div>
  }
  
  const RightArrow = () => {
    return <div className={`${styles.RightArrow} position-absolute`} onClick={() => slideRef.current.goNext()}><ChevronRight /></div>
  }

  const renderPhoto = (photo, i) => {
    const url = typeof photo === 'object' ? URL.createObjectURL(photo) : photo;
    return <div className={`${styles.slide} each-slide`} key={`${url}-${i}`}>
    <div style={{'backgroundImage': `url(${url})`, 'backgroundSize': photos.length ? 'contain' : 'cover'}}>
    </div>
  </div>
  }
  
  return <div className={border ? styles.border : ''}>
      <Slide 
        prevArrow={<LeftArrow />} 
        nextArrow={<RightArrow />}
        ref={slideRef} 
        autoplay={false}
        transitionDuration={100}>
        {photos.map(renderPhoto)}
      </Slide>
    </div>
});

export default SlideShow;