/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import PropertyIdDigitalTab from '../PropertyIdDigitalTab/PropertyIdDigitalTab'
import PropertyGeneralSettings from '../PropertyGeneralSettings/PropertyGeneralSettings'
import { useIntl } from 'react-intl'

const PropertySettingsTabs = ({ propertySettings, setPropertySettings, onSavePropertySettings, onGenerateIdDigitalPdf, isSaving, setErrors }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  return (
    <Tabs id="property-settings-tabs" className="font-lg">
      <Tab eventKey="general" title={t('PropertySettingsTabs.general.title')}>
        <PropertyGeneralSettings 
          propertySettings={propertySettings}
          setPropertySettings={setPropertySettings}
          onSavePropertySettings={onSavePropertySettings}
          isSaving={isSaving}
          setErrors={setErrors} />
      </Tab>
      <Tab eventKey="iddigital" title={t('PropertySettingsTabs.iddigital.title')}>
        <PropertyIdDigitalTab
          setErrors={setErrors}
          isSaving={isSaving}
          propertySettings={propertySettings}
          setPropertySettings={setPropertySettings}
          onSavePropertySettings={onSavePropertySettings}
          onGenerateIdDigitalPdf={onGenerateIdDigitalPdf} />
      </Tab>
    </Tabs>
  )
}

export default PropertySettingsTabs