import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import classNames from 'classnames';
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
  getTextAlignBlockMetadata,
} from 'react-rte';
import styles from './AdminRichTextEditor.module.css';

/**
 * Component in charge or rendering a customized rich text editor.
 * Uses `react-rte` as a base component.
 *
 * Required props:
 * - `onChange`: called when the editor state changes. Receives
 *  the value as an HTML string.
 * - `value`: initial value to feed the `react-rte` component
 *  (plain string or stringified HTML).
 *
 * Extra props: Any extra props will be forwarded to `react-rte`,
 * like `readOnly`, `placeholder`, `textDirectionality` among others.
 * @see https://github.com/sstur/react-rte
 */
const AdminRichTextEditor = ({
  onChange,
  readOnly,
  value,
  ...otherProps
}) => {
  const formattedInitialValue = (!value) ? RichTextEditor.createEmptyValue() : (
    RichTextEditor.createValueFromString(
      value,
      'html',
      { customBlockFn: getTextAlignBlockMetadata }
    )
  );
  const [internalValue, setInternalValue] = useState(formattedInitialValue);

  const editorClassName = useMemo(() => classNames(
    styles.richTextEditorTextarea,
    {
      [styles.richTextEditorTextareaReadOnly]: readOnly
    }
  ), [readOnly]);

  const handleRichTextEditorChanges = useCallback(
    (rawValue) => {
      setInternalValue(rawValue);

      if (!onChange) {
        return;
      }

      const value = rawValue.toString(
        'html',
        { blockStyleFn: getTextAlignStyles }
      );
      const simulatedNativeOnChangeEvent = {
        target: { value },
        value,
      };

      onChange(simulatedNativeOnChangeEvent);
    },
    [onChange]
  );

  const toolbarConfig = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_ALIGNMENT_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS'
    ],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Strikethrough', style: 'STRIKETHROUGH'},
      {label: 'Monospace', style: 'CODE'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_ALIGNMENT_BUTTONS: [
      {label: 'Align Left', style: 'ALIGN_LEFT'},
      {label: 'Align Center', style: 'ALIGN_CENTER'},
      {label: 'Align Right', style: 'ALIGN_RIGHT'},
      {label: 'Align Justify', style: 'ALIGN_JUSTIFY'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'},
      {label: 'Blockquote', style: 'blockquote'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'},
      {label: 'Code Block', style: 'code-block'}
    ]
  };

  return (
    <RichTextEditor
      value={internalValue}
      blockStyleFn={getTextAlignClassName}
      onChange={handleRichTextEditorChanges}
      editorClassName={editorClassName}
      readOnly={readOnly}
      toolbarClassName={styles.richTextEditorToolbar}
      toolbarConfig={toolbarConfig}
      {...otherProps}
    />
  );
};

export default AdminRichTextEditor;