import React from 'react';
import styles from './DataCards.module.css';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

const DataCards = ({title, details, dataCollection, mobileView, dataStyles, isLoading, isDataLoading, status, noContainer = false}) => {

  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });
  const navigate = useNavigate();

  let dataCards = dataCollection.map((data, i) => {
    return (
      <div
        key={data.label}
        style={dataCollection.length < 3 ? {width: mobileView ? '100%' : `${100 / dataCollection.length}%`} : {}} className={`d-flex ${!mobileView && i !== dataCollection.length - 1 ? 'mr-3' : ''} ${mobileView && i !== dataCollection.length - 1 ? 'mb-3' : ''}`}
        onClick={() => {if (data.link) navigate(data.link)} }>
        <div className={`${styles.dataIcon} mr-3 ${dataStyles[data.key]} data-data-card-icon`}>
          {data.icon}
        </div>
        <div className={"d-flex flex-column justify-content-between align-items-start"}>
          <div className={`${styles.dataLabel} data-data-card-label`}>
            {data.label.toUpperCase()}
          </div>
          <div className={`${styles.dataCount} data-data-card-count`}>
            {data.count}
          </div>
        </div>
      </div>);
  })


  return <div className={`w-100 ${noContainer ? '' : `${styles.dataCardsContainer} ${styles.dataCardsPadding}`} mb-3`}>
    {!isDataLoading && !isLoading ?
      <>
        <div className="d-flex justify-content-between w-100 pt-3 ">
          <div className={styles.dataCardsTitle}>{title}</div>
        </div>
        {details &&
        <div className={`w-100 pt-3 pb-3 ${styles.dataCardsDetails}`}>
          <div>{details}</div>
        </div>}
        <div className={`w-100 py-3 ${styles.dataCards} ${mobileView ? "" : "d-flex justify-content-between data-lease-radar-cards"}`}>
          {dataCollection.length ?
            dataCards
            : null}
        </div>
        {!dataCards.length && <div className={'w-100 px-3 py-3'}>{t('ui.dataNotFound')}</div>}
      </>
      :
      <div className="w-100 d-flex justify-content-center">
        <Spinner className="my-3" animation="grow" variant="primary" />
      </div>
    }
  </div>
}

export default DataCards;