import React from 'react';
import styles from './InfoCard.module.css';
import Back from '../../assets/images/back';

const InfoCard = ({info}) => {
  return <div className={`d-flex ${styles.infoCard} h-100 justify-content-between`} style={{color: info.color || undefined, backgroundColor: info.backgroundColor || undefined}}>
    <div className={styles.details}>
      <div>
        <div className={styles.title}>{info.title}</div>
        <div className={styles.description}>{info.description}</div>
      </div>
      <a className={styles.link} href={info.linkUrl} target="_blank" rel="noopener noreferrer">
        <div className={`${styles.link} d-flex`}><div>{info.linkTitle}</div>&nbsp;<div className={styles.back}><Back /></div></div>
      </a>
    </div>
    <div className={`${styles.image}`}>
      <img src={info.imageUrl} alt={'info.title'} />
    </div>
  </div>
}

export default InfoCard;