import React from 'react';

//If active is true the selected option will be turned into an input where
//users can search. This only applies to Options with isSelectedOption set
//to true.
const OptionLabel = ({
  active, 
  append, 
  blur, 
  isSelectedOption, 
  option, 
  optionRef, 
  searchValue, 
  setIsSearching, 
  setSearchValue, 
  inputClassName = '', 
  labelClassName = ''
}) => {
  if (isSelectedOption && active) {
    return <input 
      ref={optionRef} 
      onBlur={blur} 
      className={`${inputClassName} position-absolute w-100 h-100`} 
      value={searchValue} 
      onChange={(e) => {setSearchValue(e.target.value); setIsSearching(true);}} 
    />;
  } else {
    return <div className={`${labelClassName} position-absolute`}>{`${option.label} ${append ? append(option) : ''}`}</div>;
  }
}

export default OptionLabel;