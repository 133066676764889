import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useTranslations from './useTranslations'
import PropTypes from 'prop-types'

//Fetch work worders
const useAssets = ({
  companyName, 
  propertyName,
  section, 
  stencilName,
  hasReference,
  setIsLoading = () => {}, 
  redirectOnFail, 
  toastError = () => {},
  axios,
  CancelToken,
  isCancel
}) => {
  const [t] = useTranslations()

  const [assets, setAssets] = useState([])

  const navigate = useNavigate()

  useEffect(() => {

    const fetchAssets = async () => {

      const source = CancelToken.source()

      try {
        setIsLoading(true);
        const params = [
          `property=${propertyName}`,
          `company=${companyName}`,
          `section=${section}`
        ]
        if (hasReference) params.push(`hasReference=true`)
        if (stencilName) params.push(`stencilName=true`)

        const paramsString = params.join('&')
        const result = await axios.get(`/assets?${paramsString}`, {
          cancelToken: source.token
        });

        if (result.success) {
          setAssets(result.records);
        } else {
          toastError(t('hooks.useAssets.error'))
          if (redirectOnFail) navigate(redirectOnFail)
        }
      } catch (e) {

        if (isCancel(e)) {
          // request is cancelled
        } else {
          toastError(t('hooks.useAssets.error'))
          if (redirectOnFail) navigate(redirectOnFail)
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (companyName && propertyName && section) {
      fetchAssets()
    }

    return () => {
      CancelToken.source().cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, propertyName, setIsLoading, section])

  return [assets]
}

useAssets.propTypes = {
  companyGuid: PropTypes.string, 
  propertyGuid: PropTypes.string, 
  assetGuid: PropTypes.string, 
  setIsLoading: PropTypes.func, 
  redirectOnFail: PropTypes.string, 
  toastError: PropTypes.func,
  axios: PropTypes.func,
  CancelToken: PropTypes.func,
  isCancel: PropTypes.func
}

useAssets.defaultProps = {
  setIsLoading: () => {}, 
  toastError: () => {}
}

export default useAssets