import React, { useContext, useState, useEffect } from 'react'
import { AdminSelect } from '@project/components'
import { RootContext } from './../../hoc/RootContext/RootContext'
import styles from './UserCompaniesSelect.module.css';
import { useIntl } from 'react-intl';
import { useCompany } from './../../utils/hooks'

const UserCompaniesSelect = ({ label, selectedValue, setSelectedCompany, setIsLoading, show = true, customStyles = '', selectAll = true, setCurrentCompany, setErrors, defaultPropStyles }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { currentUser } = useContext(RootContext);
  const [company, setCompany] = useState(selectedValue ? { value: selectedValue } : {});
  const [companyOptions, setCompanyOptions] = useState([]);
  const [userSelectedCompany, setUserSelectedCompany] = useState(null)
  const defSelectionValue = 'All';

  useEffect(() => {
    if (currentUser && currentUser.companies) {
      // get company displayName to show as label
      const getLabel = (company) => {
        return currentUser.displayCompanies?.find((c) => c.name === company)?.displayName || company
      }
      let options = currentUser.companies.map((company) => ({ label: getLabel(company), value: company }))
      options = (selectAll ? [{ label: defSelectionValue, value: defSelectionValue }] : []).concat([...options]);
      setCompanyOptions(options);
    }
  }, [currentUser, selectAll, defSelectionValue]);

  useCompany(company.value, currentUser, setUserSelectedCompany, setErrors)

  useEffect(() => {
    const toggleLoader = (flag) => {
      if (!setIsLoading) return
      setIsLoading(flag)
    }
    if (!userSelectedCompany) {
      toggleLoader(true)
      return
    }
    company['slug'] = userSelectedCompany.slug
    company['guid'] = userSelectedCompany.guid
    company['displaySlug'] = userSelectedCompany.displaySlug
    company['displayName'] = userSelectedCompany.displayName ? userSelectedCompany.displayName : userSelectedCompany.name
    company['logo'] = userSelectedCompany.logo?.thumbnail
    if (setSelectedCompany) setSelectedCompany({ ...company })
    if (setCurrentCompany) setCurrentCompany(userSelectedCompany)
    toggleLoader(false)
  }, [userSelectedCompany, company, setCurrentCompany, setIsLoading, setSelectedCompany])

  return (
    <>
      {show &&
        <AdminSelect
          id="user-companies-select"
          label={label}
          identifierClass={'data-user-companies-select'}
          options={companyOptions}
          defaultValue=""
          propStyles={defaultPropStyles ? '' : `${styles.embeddedInput} ${customStyles}`}
          placeholder={t('UserCompaniesSelect.placeholder')}
          value={company.value ? { label: company.displayName, value: company.value } : null}
          onChange={(c) => setCompany(c)}
          isLoading={false}
          loadingMessage={t('ui.loading')}
          noOptionsMessage={t('ui.noOptions')} />}
    </>
  )
};

export default UserCompaniesSelect