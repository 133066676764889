import React from 'react';
import Option from '../Option/Option';

//Selected Option and search bar

//Close select component and reset search values when user
//clicks away.
const SelectedOption = ({
  active,
  append,
  readOnly,
  searchValue,
  selectedOption,
  showIcon,
  setActive,
  setSearchValue,
  setIsSearching,
  optionRef,
  noBlur = false
}) => {
  const selectedOptionBlurHandler = () => {
    setSearchValue(selectedOption.label);
    setIsSearching(false);
  };

  return <div className='w-100'>
    <Option
      option={selectedOption}
      showIcon={showIcon}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setIsSearching={setIsSearching}
      active={active}
      setActive={(value) => {
        if (readOnly) setActive(false); setActive(value)
      }}
      isSelectedOption={true}
      optionRef={optionRef}
      blur={noBlur ? () => { } : selectedOptionBlurHandler}
      append={append}
      readOnly={readOnly}
    />
  </div>
}

export default SelectedOption;