import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.99056 10.1057C8.69751 10.1057 10.8919 8.06969 10.8919 5.55814C10.8919 3.04659 8.69751 1.01057 5.99056 1.01057C3.28361 1.01057 1.08919 3.04659 1.08919 5.55814C1.08919 8.06969 3.28361 10.1057 5.99056 10.1057ZM5.99056 11.1163C9.29906 11.1163 11.9811 8.62782 11.9811 5.55814C11.9811 2.48846 9.29906 0 5.99056 0C2.68207 0 0 2.48846 0 5.55814C0 8.62782 2.68207 11.1163 5.99056 11.1163Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2298 14L8.87305 9.95772L9.64323 9.24313L14 13.2854L13.2298 14Z" fill="currentColor" />
    </svg>
  )
}

export default SearchIcon;
