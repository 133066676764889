import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailContainer from './ThumbnailContainer';
import styles from './PhotoThumbnail.module.css';
import { getMediaUrl } from '../utils';

const PhotoThumbnail = ({
  onClick,
  onRemoveButtonClick,
  selected,
  showRemoveButton,
  url,
  media = null
}) => (
  <ThumbnailContainer
    onClick={onClick}
    onRemoveButtonClick={onRemoveButtonClick}
    selected={selected}
    showRemoveButton={showRemoveButton}
  >
    <img className={styles.Photo} src={getMediaUrl(media)} alt={url} />
  </ThumbnailContainer>
);

PhotoThumbnail.propTypes = {
  /** Called when the main container gets clicked. */
  onClick: PropTypes.func,
  /** Called when the X button gets clicked. */
  onRemoveButtonClick: PropTypes.func,
  /** Marks this element as "selected". It displays a dark square around the container. */
  selected: PropTypes.bool,
  /** Shows/hides the X button, intended to "remove" this element from the uploader. */
  showRemoveButton: PropTypes.bool,
  /** URL of an image. */
  url: PropTypes.string.isRequired
};

export default PhotoThumbnail;