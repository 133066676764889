// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect, useContext, useRef } from 'react';
import { RootContext } from '../../../hoc/RootContext/RootContext';
import axios from "../../../utils/axios";
import styles from './Amp.module.css';
import { Alerts, Spinner } from '@project/components';
import AdminSelect from '../../../components/AdminSelect/AdminSelect';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { useIntl } from 'react-intl';
import {formatThousands} from '../../../utils/utils'
import StringTimeSelector from '../../../components/StringTimeSelector/StringTimeSelector'
import {useSearchParams} from 'react-router-dom'

const Amp = ({ printFunction }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const tableRef = useRef()

  const [searchParams] = useSearchParams();
  // this is a backup in case currentcompany not set, typically
  // if the user refreshes this page
  const company = searchParams.get("company")

  const { currentUser, currentCompany } = useContext(RootContext);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(null);
  const [properties, setProperties] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({label: t('Amp.allProperties'), value: "all"});
  const [selectedTime, setSelectedTime] = useState({label: t('Amp.monthToDate'), value: "mtd"});
  const [printEnabled, setPrintEnabled] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [totalCharge, setTotalCharge] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [waterCharge, setTotalWaterCharge] = useState(0)
  const [sewerCharge, setTotalSewerCharge] = useState(0)
  const [wasteCharge, setTotalWasteCharge] = useState(0)

  useEffect(() => {

    const fetch = async () => {
      setLoading(true);

      try {

        const result = await axios.get(`/menu`);
        if (result.status === 200) {

          let tmp = [];

          result.data.data.companies.forEach((company) => {
            company.children.forEach((child) => {
              child.label = child.displayName;
              child.value = child.guid;
              tmp.push(child);
            });
          });

          tmp.sort((a, b) => (a.label > b.label) ? 1 : -1);
          tmp.unshift({label: t('Amp.allProperties'), value: "all"});
          setProperties(tmp);
        }

      }
      catch (e) {
        if (e.response && e.response.data) {
          setErrors(`There was an error retrieving data from the server (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || 'The server is not responding.');
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (currentUser) fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {

    const fetch = async () => {
      setLoading(true);

      try {
        let guids = selectedProperty?.value ? [selectedProperty.value] : null

        const result = await axios.post(`/reports/amp`, {guids, companyGuid: company || currentCompany?.guid, range: selectedTime.value});

        if (result.status === 200) {
          setTotalCharge(result?.data?.result?.totals?.grandTotalCharge)
          setTotalCost(result?.data?.result?.totals?.grandTotalCost)
          setTotalWaterCharge(result?.data?.result?.totals?.grandTotalWaterCharge)
          setTotalSewerCharge(result?.data?.result?.totals?.grandTotalSewerCharge)
          setTotalWasteCharge(result?.data?.result?.totals?.grandTotalWasteCharge)
          setStartDate(result?.data?.result?.startDate);
          setEndDate(result?.data?.result?.endDate);
          setRecords(result?.data?.result?.records);
          setPrintEnabled(true);
        }

      }
      catch (e) {
        if (e.response && e.response.data) {
          setErrors(`There was an error retrieving data from the server (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || 'The server is not responding.');
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (selectedProperty && selectedTime && properties.length) fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, company, selectedProperty, selectedTime, properties, setPrintEnabled]);

  if (loading) return <Spinner />;

  return (
    <div className="w-100">
      {errors && errors.length > 0 ?
        <div style={{ "marginTop": "20px" }}><Alerts messages={errors} close={() => setErrors(null)} /></div>
        :
        <>

          {/* filter options */}
          <div className={`d-flex flex-wrap justify-content-between w-100 ${styles.dropdowns} mb-3`}>
            <AdminSelect
              customStyle={{width: '250px'}}
              options={properties}
              value={selectedProperty}
              onChange={(e) => setSelectedProperty(e)}
              isLoading={loading} />
            <StringTimeSelector
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              loading={loading} />
          </div>
          {startDate && endDate ?
            <div className="w-100 d-flex justify-content-between">
              <h6 style={{marginLeft: 2}}>
                { moment(new Date(startDate).toISOString()).format('MM/DD/YYYY') } - { moment(new Date(endDate).toISOString()).format('MM/DD/YYYY') }
              </h6>
              <div>
                {
                  printEnabled &&
                  <div
                    className={styles.printButton}
                    onClick={() => printFunction('Amp', tableRef)}>{t('ui.print')}
                  </div>
                }
              </div>
            </div> : null
          }

          {/* report display */}
          <div className={`d-flex justify-content-between flex-wrap`}>
            <div className={styles.ReportTile}>
              <div className="w-100">{t('Reports.Amp.title')}</div>
              <Table ref={tableRef} striped hover>
                <thead>
                  <tr>
                    <td>{t('Reports.Amp.Facility')}</td>
                    <td>{t('Reports.Amp.Tenant')}</td>
                    <td>{t('Reports.Amp.BillDate')}</td>
                    <td>{t('Reports.Amp.TotalCharged')}</td>
                    <td>{t('Reports.Amp.TotalCost')}</td>
                    <td>{t('Reports.Amp.WaterCost')}</td>
                    <td>{t('Reports.Amp.SewerCost')}</td>
                    <td>{t('Reports.Amp.WasteCost')}</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    records?.length ?
                      <>
                        {
                          records.map(record => {
                            return (
                              <tr key={record.pid}>
                                <td>{record.facilityName.toUpperCase()}</td>
                                <td>{record.name}</td>
                                <td>{moment(record.billDate).format('MM/DD/YYYY')}</td>
                                <td>${parseFloat(record.totalCharge).toFixed(2)}</td>
                                <td>${(parseFloat(record.waterCharge) + parseFloat(record.sewerCharge) + parseFloat(record.wasteCharge)).toFixed(2)}</td>
                                <td>${parseFloat(record.waterCharge).toFixed(2)}</td>
                                <td>${parseFloat(record.sewerCharge).toFixed(2)}</td>
                                <td>${parseFloat(record.wasteCharge).toFixed(2)}</td>
                              </tr>
                            )
                          })
                        }
                        <tr>
                          <td colSpan={3} align={'right'}>Grand Totals</td>
                          <td>${formatThousands(totalCharge)}</td>
                          <td>${formatThousands(totalCost)}</td>
                          <td>${formatThousands(waterCharge)}</td>
                          <td>${formatThousands(sewerCharge)}</td>
                          <td>${formatThousands(wasteCharge)}</td>
                        </tr>
                      </>
                      : <tr><td colSpan={8}>No records found</td></tr>
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </>
      }
    </div>

  );
};

export default Amp;