/**
 * These env names match the ones set in the .env file and
 * React build targets.
 */
export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
}

/** General constants used in ID Inspect */

export const ACL = {
  HTTP_HEADERS: {
    IDP_COMPANY: 'IDP-Company'
  }
}

export const INSPECT_TYPE = {
  WORK_ORDER: 'work-order',
  INSPECTION: 'inspection'
}

/**
 * Enum for all the status strings in an inspection
 * object. Can be used to match against the backend
 * values.
 */
export const INSPECTION_STATUS = {
  OPEN: 'Open',
  IN_PROGRESS: 'In-Progress',
  PAUSED: 'Paused',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled'
};

export const INSPECTION_STATUS_COMPLETE = [
  INSPECTION_STATUS.APPROVED,
  INSPECTION_STATUS.COMPLETE
]

export const INSPECTION_INSTANCES_FILTER_TYPE = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  PROPERTIES: 'properties'
};

export const INSPECTION_STATUSES = Object.values(INSPECTION_STATUS)

/**
 * Work Order filter types for tables
 */
export const WORK_ORDER_FILTER_TYPE = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  PROPERTIES: 'properties'
};

/*
  Filter types in common for inspections and work orders
*/
export const INSPECT_FILTER_TYPE = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  PROPERTIES: 'properties',
  WATCHING: 'watching'
};

/**
 * Name of the URL search parameter corresponding to
 * the selected property in the dashboard table.
 */
export const PROPERTY_SEARCH_PARAM_KEY = 'property'

export const WORK_ORDER_STATUS = {
  CREATED: 'Created',
  ASSIGNED: 'Assigned',
  VENDOR_ASSIGNED: 'Vendor Assigned',
  PENDING_VENDOR_ACCEPTANCE: 'Pending Vendor Acceptance',
  VENDOR_ACCEPTED: 'Vendor Accepted',
  SCHEDULED: 'Scheduled',
  WORK_IN_PROGRESS: 'Work in progress',
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  SUBMITTED_FOR_APPROVAL: 'Submitted for Approval',
  REVIEW: 'Review',
  ACCEPTED: 'Accepted',
  INVOICE_REQUESTED: 'Invoice Requested',
  INVOICE_SUBMITTED: 'Invoice Submitted',
  INVOICE_ACCEPTED: 'Invoice Accepted',
  DECLINED: 'Declined',
  VENDOR_DECLINED: 'Vendor Declined',
  PAUSED: 'Paused',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed'
}


export const WORK_ORDER_PRIORITY = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  URGENT: 'Urgent'
}

export const WORK_ORDER_MODES = {
  NEW: "NEW",
  ADMIN_EDIT: "ADMIN_EDIT",
  ADMIN_VIEW: "ADMIN_VIEW",
  NONADMIN_VIEW: "NONADMIN_VIEW",
  NONADMIN_EDIT: "NONADMIN_EDIT",
  VENDOR_VIEW: "VENDOR_VIEW"
}

export const UPLOADED_FILE_CATEGORY = {
  GENERAL: 'general',
  PHOTOS: 'photos',
  DOCUMENTS: 'documents',
  VIDEOS: 'videos',
  COI: 'coi'
};

export const UPLOADED_FILE_OWNER_TYPE = {
  INSPECTION_INSTANCE: 'InspectionInstance',
  INSPECTION_TASK: 'InspectionTask',
  RATING: 'Rating',
  RATING_CHOICE: 'RatingChoice',
  CATALOG_TASK: 'CatalogTask',
  WORK_ORDER: 'WorkOrder'
};

export const WORK_ORDER_FILE_TYPE = {
  DOCUMENT: 'Document',
  IMAGE_BEFORE: 'Image: Before',
  IMAGE_AFTER: 'Image: After'
}

export const UPLOADER_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video'
};

export const WORK_ORDER_PRIORITIES = Object.values(WORK_ORDER_PRIORITY)

export const WORK_ORDER_FILE_TYPES = Object.values(WORK_ORDER_FILE_TYPE)

export const WORK_ORDER_STATUSES = Object.values(WORK_ORDER_STATUS)

export const WORK_ORDER_STATUSES_NON_ADMIN = Object.values(WORK_ORDER_STATUSES.filter(status => ![WORK_ORDER_STATUS.APPROVED, WORK_ORDER_STATUS.CANCELLED].includes(status)))

export const PRIORITY_COLORS = {
  [WORK_ORDER_PRIORITY.LOW]: '#007C9E',
  [WORK_ORDER_PRIORITY.MEDIUM]: '#FF9633',
  [WORK_ORDER_PRIORITY.HIGH]: '#FF891E',
  [WORK_ORDER_PRIORITY.URGENT]: '#F43E15'
}

export const PRIORITY_BACKGROUND_COLORS = {
  [WORK_ORDER_PRIORITY.LOW]: 'rgba(0, 124, 158, 0.2)',
  [WORK_ORDER_PRIORITY.MEDIUM]: 'rgba(253, 227, 33, 0.3)',
  [WORK_ORDER_PRIORITY.HIGH]: 'rgba(255, 137, 30, 0.2)',
  [WORK_ORDER_PRIORITY.URGENT]: 'rgba(244, 62, 21, 0.2)'
}

/**
 * All the image sizes we look for in a media file, in the specific
 * order we might want to retrieve them if some are not available.
 * 1st > "xl"
 * If not found > "lg"
 * If not found > "md"
 */
export const MODAL_THUMBNAIL_TARGET_SIZES = [
  'xl', // This XL variant should be enough for retina and 4K displays.
  'lg', // First fallback, use LG variant
  'md' // Second fallback, use MD variant
]