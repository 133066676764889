import React, { useRef } from 'react';
import { FileIcon, FileListFile } from '@project/components'
import styles from './coiUpload.module.css';
import { useTranslations } from '../../../utils/hooks';
import { useCOIUpload } from './hooks';
import { Alert, Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

const ACCEPTED_COI_FORMATS = ['pdf', 'doc', 'docx'];
const ACCEPTED_COI_MIME_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

export default function COIUpload({ coiFile, setCoiFile, vendorInvite, registrationToken }) {
  const [t] = useTranslations();
  const intl = useIntl();
  const {
    error, file, onFileSelected, isUploading
  } = useCOIUpload({
    acceptedFileFormats: ACCEPTED_COI_FORMATS,
    coiFile,
    setCoiFile,
    vendorInvite,
    registrationToken
  });
  // This is used to trigger the native file selector from an input[type=file]
  // from the "upload" button.
  const fileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const rawFile = event.target.files[0]
    const internalFileInstance = new FileListFile({
      name: rawFile.name,
      size: rawFile.size,
      uploadDate: Date.now()
    });
    onFileSelected(internalFileInstance, rawFile);
  };

  const UploadButton = () => (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept={ACCEPTED_COI_MIME_TYPES.join(',')}
        name="file"
        onChange={handleFileInputChange}
        className='d-none'
        data-testid="FileHubInput"
      />
      <Button
        variant="outline-primary"
        className="btn-block"
        disabled={isUploading}
        onClick={handleUploadButtonClick}
      >{intl.formatMessage({ id: isUploading ? 'ui.uploading' : file ? 'ui.coiChangeFile' : 'ui.uploadAFile' })}</Button>
    </>
  );

  return <div>
    <div>
      {t('COIUpload.instructions').split('\n').map((paragraph, index) => <p key={`p${index}`}>{paragraph}</p>)}
      <p>{t('COIUpload.acceptedFileFormats', { formats: ACCEPTED_COI_FORMATS.join(', ') })}</p>
    </div>
    <ul className={styles.uploadedFilesList}>
      {coiFile && <li className={styles.uploadedFile}>
        <FileIcon extension={coiFile.extension} iconClassName={styles.uploadedFileIcon} />
        <label className={styles.uploadedFileName}>
          {coiFile.url ? <a href={coiFile.url} rel="noreferrer noopener" target="_blank">{coiFile.name}</a> : coiFile.name}
        </label>
        <span className={styles.uploadedFileSize}>{coiFile.humanizedSize}</span>
      </li>}
    </ul>
    { /* TODO: change text to 'change/modifiy' if an old file is found? */}
    <section className="container-fluid">
      {error && (
        <div className="row">
          <div className="col-12 p-1">
            <Alert variant="danger">{intl.formatMessage({ id: 'error' })}: {error}</Alert>
          </div>
        </div>
      )}
      <div className="row justify-content-between">
        <div className="col-12 col-md-2 p-1 d-none d-md-block"></div>
        <div className="col-12 col-md-5">
          <UploadButton />
        </div>
      </div>
    </section>
  </div>
}