import React from 'react';

const DashboardBold = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.75 0H10.8333V5H15V1.25C15 0.559644 14.4404 0 13.75 0Z" fill="currentColor" />
      <path d="M15 6.66667H10.8333V15H13.75C14.4404 15 15 14.4404 15 13.75V6.66667Z" fill="currentColor" />
      <path d="M0 10.8333H9.16667V15H1.25C0.559644 15 0 14.4404 0 13.75V10.8333Z" fill="currentColor" />
      <path d="M1.25 0C0.559644 0 0 0.559644 0 1.25V9.16667H9.16667V0H1.25Z" fill="currentColor" />
    </svg>
  );
}

export default DashboardBold;