import {intl} from '../utils/intl';
const t = (id) => intl.formatMessage({id});

/**
 * General constants used in IDVision
 */

export const VALID_APPS = ['id360', 'idcloud', 'iddigital', 'idintel', 'idvision', 'rpm', 'idinspect'];
export const USER_TITLES = [
  { value: 'Asset Manager', label: t('userTitles.assetManager') },
  { value: 'Leasing Representative', label: t('userTitles.leasingRepresentative') },
  { value: 'Property Manager', label: t('userTitles.propertyManager') },
  { value: 'Billing Specialist', label: t('userTitles.billingSpecialist') },
  { value: 'Renewal Agent', label: t('userTitles.renewalAgent') },
  { value: 'Property Manager Assistant', label: t('userTitles.propertyManagerAssistant') },
  { value: 'Leasing Agent', label: t('userTitles.leasingAgent') },
  { value: 'Construction Manager', label: t('userTitles.constructionManager') }
]

export const USER_TITLE_LABELS = {
  'Asset Manager': t('userTitles.assetManager'),
  'Leasing Representative': t('userTitles.leasingRepresentative'),
  'Property Manager': t('userTitles.propertyManager'),
  'Billing Specialist': t('userTitles.billingSpecialist'),
  'Renewal Agent': t('userTitles.renewalAgent'),
  'Property Manager Assistant': t('userTitles.propertyManagerAssistant'),
  'Leasing Agent': t('userTitles.leasingAgent'),
  'Construction Manager': t('userTitles.constructionManager')
}

export const USER_STATUSES = [
  { value: 'active', label: t('ui.active') },
  { value: 'inactive', label: t('ui.disabled') } ];

export const AVAILABLE_REPORTS = [{
  id: 'amp',
  title: t('AvailableReports.amp.title'),
  description: t('AvailableReports.amp.description'),
  linkTitle: t('AvailableReports.amp.linkTitle'),
  link: '/reports/amp',
  requiredRoleAction: 'reports:amp'
}];

export const SPACE_INFO_OPTIONS = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' }
];

export const roleOptions = [
  {
    label: t('UsersPropertyRolesList.viewer'),
    value: "viewer",
    desc: t('UsersPropertyRolesList.viewer.desc')
  },
  {
    label: t('UsersPropertyRolesList.editor'),
    value: "editor",
    desc: t('UsersPropertyRolesList.editor.desc')
  },
  {
    label: t('UsersPropertyRolesList.designer'),
    value: "designer",
    desc: t('UsersPropertyRolesList.designer.desc')
  },
  {
    label: t('UsersPropertyRolesList.admin'),
    value: "company-admin"
  }
]
