import React, { useState } from 'react'
import { useEffect } from 'react'
import AdminSelect from '../AdminSelect/AdminSelect'
import { useTranslations } from '../utils/hooks'
import styles from './SelectWithCustomInput.module.css'
import PropTypes from 'prop-types'

/*
  React Select with overrides to allow for a custom value input in addition
  to selectable options. Also may be used as normal input.
*/
const SelectWithCustomInput = ({
  value,
  onChange,
  options = [],
  placeholder,
  customModePlaceholder,
  validationCheck,
  inputMode,
  customModeOn,
  isReadOnly,
  propStyles,
  ...props
}) => {
  const [t] = useTranslations()

  const [customMode, setCustomMode] = useState(customModeOn)

  useEffect(() => {
    if (inputMode) setCustomMode(true)
  }, [inputMode])

  //Accepts event objects from react-select and coordinates how
  //to update values based on the action.
  const updateField = (value, event) => {
    if (isReadOnly) return
    if (event.action === 'clear') onChange('')
    //Option selected from list
    if (['set-value', 'select-option'].includes(event.action)) {
      if (value?.value === 'custom') {
        setCustomMode(true)
        //Prepare for custom input
        onChange('')
      } else {
        setCustomMode(false)
        onChange(value?.value)
      }
      return
    }
    if (customMode) {
      //Prevent custom input from clearing on these actions
      if (['input-blur', 'menu-close'].includes(event.action)) return
      //Validation Check
      if(!validationCheck(value)) return
      onChange(value)
    }
  }

  const customOptions = [
    {value: 'custom', label: t('SelectWithCustomInput.customValue')},
    ...options
  ]

  //Convert the search input into a value input if custom value
  //was selected. Remove function when other option selected
  const customModeProps = customMode ? {
    inputValue: value || '',
    onInputChange: updateField
  } : {}

  //Convert into input without options dropdown
  const inputModeProps = inputMode ? {
    menuIsOpen: false,
    customComponents: {
      ...(props.customComponents || {}), 
      DropdownIndicator: null
    }
  } : {}

  const readOnlyProps = isReadOnly ? {
    menuIsOpen: false,
    customComponents: {
      ...(props.customComponents || {}), 
      DropdownIndicator: null
    },
    isClearable: false,
    propStyles: `${propStyles} ${styles.ReadOnly}`
  } : {}

  const formattedValue = value && {value: value, label: value}
  const formattedPlaceholder = customMode ? (customModePlaceholder || t('SelectWithCustomInput.customPlaceholder')) : placeholder

  return <AdminSelect
    {...customModeProps}
    onChange={updateField}
    options={customOptions}
    isClearable={customMode}
    controlShouldRenderValue={!customMode}
    placeholder={formattedPlaceholder}
    value={formattedValue}
    propStyles={propStyles}
    {...props}
    {...inputModeProps}
    {...readOnlyProps}
  />
}

SelectWithCustomInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  //Options for select of shape {value: '...', label: '...'}
  options: PropTypes.array,
  placeholder: PropTypes.string,
  //Placeholder for when user selects custom mode
  customModePlaceholder: PropTypes.string,
  //Checks input value with given function before allowing change
  validationCheck: PropTypes.func,
  //Allows us to use this as a normal input component
  inputMode: PropTypes.bool,
  //Overrides in-component state and switched to customMode
  customModeOn: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  propStyles: PropTypes.string
}

SelectWithCustomInput.defaultProps = {
  options: []
}

export default SelectWithCustomInput