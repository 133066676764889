import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { RootContext } from '../hoc/RootContext/RootContext';

export default function ScrollToTop({children}) {
  const location = useLocation();
  const { isAdminMenuOpen, largeView } = useContext(RootContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (document.getElementById('appBody')) document.getElementById('appBody').scrollTo(0, 0);
    if (document.getElementById('menuDrawer') && !largeView) document.getElementById('menuDrawer').scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isAdminMenuOpen]);

  return <>{children}</>;
}