import { INSPECTION_STATUSES, INSPECT_TYPE } from '../../../utils/constants'
import { WORK_ORDER_STATUSES } from '@project/components'
import { useMemo } from 'react'
import STATUS_COLORS_BY_INSPECTION_TYPE from '../../status-colors-by-inspection-type'

const STATUSES = {
  [INSPECT_TYPE.WORK_ORDER]: WORK_ORDER_STATUSES,
  [INSPECT_TYPE.INSPECTION]: INSPECTION_STATUSES
}

const DEFAULT_STATUS_COLOR = {
  condition: item => item,
  color: { backgroundColor: 'rgba(255, 202, 58, 0.4)' }
}

/**
 * Creates schema for status field. Handles color coordination and formatting
 * @param {string} type inspection or work-order
 * @param {(s: string) => string} t translator
 * @returns {any} Schema for status field
 */
const useTableStatusFields = ({
  t,
  type
}) => {
  const statusColors = useMemo(() => STATUSES[type].map(status => ({
    condition: item => item.status === status,
    color: { backgroundColor: STATUS_COLORS_BY_INSPECTION_TYPE[type][status] || DEFAULT_STATUS_COLOR.color.backgroundColor }
  })), [type])

  const statusFields = useMemo(() => ({
    id: 'status',
    label: t('ui.status'),
    type: 'status',
    options: { noDot: true },
    statusColors: [
      ...statusColors,
      DEFAULT_STATUS_COLOR
    ]
  }), [statusColors, t])

  return [statusFields, { ...STATUS_COLORS_BY_INSPECTION_TYPE[type], default: DEFAULT_STATUS_COLOR.color.backgroundColor }]
}

export default useTableStatusFields