import React, { useState, useEffect, useContext } from 'react';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import { RootContext } from './../../hoc/RootContext/RootContext';
import axios from "../../utils/axios";
import styles from './Reports.module.css';
import { Alerts, Spinner } from '@project/components';
import { useIntl } from 'react-intl';
import { useAmpReportData } from '../../containers/Dashboard/hooks';
import ReportCard from '../../components/ReportCard/ReportCard';
import DataCards from '../../components/DataCards/DataCards';
import { useNavigate } from 'react-router-dom';

const Reports = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const navigate = useNavigate();

  const { currentUser, mobileView } = useContext(RootContext);

  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [, setCompany] = useState(null);
  const [companyRole, setCompanyRole] = useState(null);
  const [isAmpReportLoading, setIsAmpReportLoading] = useState(false);
  const [reportsBody] = useState([]);

  let ampReportData = useAmpReportData({ setErrors, setLoading: setIsAmpReportLoading, reportsBody});

  const AVAILABLE_REPORTS = [
    {
      id: 'amp',
      title: t('Reports.amp.title'),
      details: t('Reports.amp.details'),
      dataCollection: ampReportData || [],
      dataStyles: {
        'savings': styles.ampSavingsIcon,
        'revenue': styles.ampRevenueIcon
      },
      status: ['savings', 'revenue'],
      dataLoading: isAmpReportLoading,
      requiredRoleAction: 'reports:amp',
      clickHandler: () => navigate('/reports/amp')
    }
  ]

  // fetch current company
  useEffect(() => {

    const fetch = async () => {
      try {
        setErrors(null);

        const encodedCompany = encodeURIComponent(companyName)
        let result = await axios.get(`/companies/${encodedCompany}`);
        if (result.status === 200) {
          setCompany(result.data.record);
        }

        // fetch acls
        let companyAcl;
        result = await axios.get(`/acls?username=${currentUser.username}&company=${encodedCompany}`);
        if (result.status === 200) {
          // setCompanyRole
          companyAcl = result.data.rows.find((_) => {
            return _.company === companyName && !_.property && !_.section;
          });
        }

        // fetch applicable role - company level
        let tmpRole;
        if (companyAcl) {
          result = await axios.get(`/roles/${encodedCompany}/${companyAcl.roleid}`);
          if (result.status === 200) {
            tmpRole = result.data.record;
            setCompanyRole(result.data.record);
          }
        }

        const errs = [];
        if (!companyAcl) errs.push(t('error.companyAclNotFound'));
        if (!tmpRole) errs.push(t('error.permissionsRoleNotFound'));

        const allowed = AVAILABLE_REPORTS.map((report) => {
          if (tmpRole.actions.includes(report.requiredRoleAction)) {
            return report;
          }
          return null
        }).filter(_ => _);

        if (!allowed || allowed.length < 1) errs.push(t('error.noAccessReports'));

        if (errs.length) setErrors(errs);

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }

    };

    if (currentUser && companyName) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, companyName]);

  useEffect(() => {
    if (currentUser) {
      setCompanyName(currentUser.company);
    }
  }, [currentUser]);

  if (!currentUser) {
    return <PageContentContainer><h3 style={{ "color": "#990000" }}>{t('error.notAuthorized')}</h3></PageContentContainer>
  }

  if (!errors && !companyRole) {
    return <Spinner />;
  }

  return (
    <PageContentContainer>
      {errors && errors.length > 0 ?
        <div style={{ "marginTop": "20px" }}><Alerts messages={errors} close={() => setErrors([])} /></div>
        :
        <div className="flex-wrap d-flex">
          {
            AVAILABLE_REPORTS.map((report) => {
              if (companyRole.actions.includes(report.requiredRoleAction)) {
                return (
                  <ReportCard
                    key={report.id}
                    title={report.title}
                    details={report.details}
                    mobileView={mobileView}
                    click={report.clickHandler}>
                    <DataCards
                      dataCollection={report.dataCollection}
                      dataStyles={report.dataStyles}
                      status={report.status}
                      mobileView={true}
                      isLoading={!errors && !companyRole}
                      isDataLoading={report.dataLoading}
                      noContainer={true}
                    />
                  </ReportCard>
                )
              }
              return null;
            })
          }
        </div>
      }
    </PageContentContainer>
  );
};

export default Reports;
