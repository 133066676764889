import React from 'react';

function CircleTitle({ title }) {
  return (
    <span className={('text-white d-inline-block rounded-circle mr-3 bglink font14 circleTitle text-center')}>
      {title}
    </span>
  );
};

export default CircleTitle;
