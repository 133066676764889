import React, {
  useCallback,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import { AdminCheckbox } from '@project/components'
import LeftColumn from '../TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from '../TwoColumnContent/RightColumn/RightColumn'
import TitleDescription from '../TitleDescription/TitleDescription'
import TwoColumnContent from '../TwoColumnContent/TwoColumnContent'
import { COMPANY_SETTINGS as IDINSPECT_COMPANY_SETTINGS } from '../../constants/inspections'
import { useTranslations } from '../../utils/hooks'
import { get } from '../../utils/utils'
import styles from './CompanyIdInspectTab.module.css'

/**
 * Component that renders all the settings and toggles related to ID Inspect.
 *
 * @example
 * ```jsx
 * const companySettings = {
 *   inspections: {
 *     enabled: true,
 *     inspectionArchival: false
 *   }
 * }
 *
 * const updateCompanySettings = (newValue, companySettings, key) => {
 *   // do something...
 * }
 *
 * const handleSaveButtonClick = () => {
 *   // do something...
 * }
 *
 * <Tab eventKey="idinspect" title="ID Inspect">
 *   <SettingsTab
 *     description="Change ID Inspect settings"
 *     onSaveButtonClick={handleSaveButtonClick}
 *     title="ID Inspect"
 *   >
 *     <CompanyIdInspectTab
 *       companySettings={companySettings}
 *       updateCompanySettings={updateCompanySettings}
 *     />
 *   </SettingsTab>
 * </Tab>
 * ```
 */
const CompanyIdInspectTab = ({
  companySettings,
  updateCompanySettings
}) => {
  const [t] = useTranslations()

  /**
   * Value from the current company settings object.
   * @type {boolean}
   */
  const isInspectionArchivalEnabled = useMemo(() => (
    get(companySettings, IDINSPECT_COMPANY_SETTINGS.ARCHIVAL)
  ), [companySettings])

  /**
   * Called when any setting checkbox/toggle component changes.
   * It bubbles the event via the `updateCompanySettings` prop.
   */
  const handleAdminCheckboxChange = useCallback(
    (newValue, settingsKey) => {
      updateCompanySettings(newValue, companySettings, settingsKey)
    },
    [companySettings, updateCompanySettings]
  )

  return (
    <TwoColumnContent>
      <LeftColumn>
        <TitleDescription
          title={t('CompanyIdInspectTab.InspectionArchival.title')}
          description={t('CompanyIdInspectTab.InspectionArchival.description')}
          style={{ maxWidth: 'none' }}
        />
      </LeftColumn>
      <RightColumn boxShadow={false} border={false}>
        <div className='row'>
          <div className="col-sm-12 d-flex pl-3 ml-2">
            <AdminCheckbox
              id="inspections_inspectionarchival"
              checked={isInspectionArchivalEnabled}
              onChange={(e) => handleAdminCheckboxChange(e, IDINSPECT_COMPANY_SETTINGS.ARCHIVAL)}
              customstyle={`${styles.checkbox} mt-n4`} />
          </div>
        </div>
      </RightColumn>
    </TwoColumnContent>
  )
}

CompanyIdInspectTab.propTypes = {
  /** Company settings object. Required. */
  companySettings: PropTypes.object.isRequired,
  /** To be called when any settin changes. Required. */
  updateCompanySettings: PropTypes.func.isRequired
}

export default CompanyIdInspectTab
