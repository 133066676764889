import axios, { CancelToken, isCancel } from "axios";
import Cookies from 'universal-cookie';
import { intl } from '../utils/intl';
const cookies = new Cookies();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
  headers: {
    ...(cookies.get('auth') ? { 'x-access-token': cookies.get('auth') } : {}),
    'Accept-Language': intl.locale
  },
  validateStatus: (status) => {
    return status !== 401 && status >= 200 && status < 500
  }
});

// intercepts the request and uses most recent token
axiosInstance.interceptors.request.use(
  function (config) {
    const token = cookies.get('auth');
    if (token) config.headers['x-access-token'] = token;
    config.headers['Accept-Language'] = intl.locale;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  try {
    const loginRedirectErrors = ['request not secured', 'login again please'];
    if (loginRedirectErrors.includes(error.response.data.errors[0])) window.location = '/';
  } catch (e) {
    // proceed with normal error handling
  }
  return Promise.reject(error);
});

axiosInstance.interceptors.request.use(function (config) {
  if (!window.activeRequests) window.activeRequests = 0;
  window.activeRequests++;
  // show loader
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
  if (!window.activeRequests) window.activeRequests = 0;
  window.activeRequests--;
  return response;
}, function (error) {
  if (!window.activeRequests) window.activeRequests = 0;
  window.activeRequests--;
  return Promise.reject(error);
});

export default axiosInstance;

export { CancelToken, isCancel };