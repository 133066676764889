import React from 'react';

const RadarBold = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.04639 4.98941C8.7168 4.87499 8.3688 4.7998 8.00077 4.7998C6.23677 4.7998 4.80078 6.23501 4.80078 7.9998C4.80078 9.7638 6.23677 11.1998 8.00077 11.1998C9.76478 11.1998 11.2008 9.76459 11.2008 7.9998C11.2008 7.86221 11.1776 7.731 11.16 7.59738L8.29755 8.74219C7.97276 8.87257 7.60076 8.77579 7.37914 8.50297C7.15834 8.23099 7.13992 7.84698 7.33432 7.55497L9.04639 4.98941Z" fill="currentColor" />
      <path d="M15.6768 5.79121L12.6904 6.98641C12.7616 7.3136 12.8 7.652 12.8 8C12.8 10.6472 10.6472 12.8 8.00004 12.8C5.35283 12.8 3.20003 10.6472 3.20003 8C3.20003 5.3528 5.35283 3.19999 8.00004 3.19999C8.69765 3.19999 9.35923 3.35281 9.95765 3.62161L11.7361 0.952802C10.5848 0.340013 9.31123 0 8 0C3.58879 0 0 3.58879 0 8C0 12.412 3.58879 16 8 16C12.4112 16 16 12.412 16 8C16 7.2464 15.8856 6.50802 15.6768 5.79121Z" fill="currentColor" />
    </svg>
  );
}

export default RadarBold;