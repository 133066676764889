import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AdminInput, AdminModal, AdminSelect, AdminToast } from '@project/components';
import UserCompaniesSelect from '../../components/UserCompaniesSelect/UserCompaniesSelect';
import PropertySelect from '../../components/PropertySelect/PropertySelect';
import SpaceSelect from '../../components/SpaceSelect/SpaceSelect';
import { SPACE_INFO_OPTIONS } from '../../constants';
import { useTranslations } from '../../utils/hooks';
import axios from '../../utils/axios';

const ORDER_SPACE_INFO = {
  YES: 'YES',
  NO: 'NO'
}

const OrderProfile = ({
  currentUser,
  activeOrderModal,
  setActiveOrderModal
}) => {
  const [t] = useTranslations();
  const [formError, setFormError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [isOrderProfileSaving, setIsOrderProfileSaving] = useState(false);
  const [orderProfile, setOrderProfile] = useState({
    unitNo: '',
    comments: ''
  });
  const [propertyManager, setPropertyManager] = useState({
    propertyManagerCellNo: '',
    managerName: `${currentUser.fname} ${currentUser.lname}`,
    email: currentUser.email
  });
  const [onSiteInformation, setOnSiteInformation] = useState({
    contactName: '',
    phoneNumber: '',
    lockboxCode: '',
    lockboxLocation: ''
  });
  const [spaceInformation, setSpaceInformation] = useState({
    isSpaceVacant: ORDER_SPACE_INFO.YES,
    areFixturesRemoved: ORDER_SPACE_INFO.YES,
    isWaterOn: ORDER_SPACE_INFO.YES,
    isRoofAccessAvailable: ORDER_SPACE_INFO.YES,
    location: ''
  });
  const sqFootageInputValue = useMemo(() => (
    selectedSpace?.sqFootage ? `${selectedSpace.sqFootage} sqft` : 'N/A'
  ), [selectedSpace])

  const submitOrderProfiles = async () => {
    const payload = {
      ...orderProfile,
      property: selectedProperty?.value,
      company: selectedCompany?.value,
      unitNo: selectedSpace?.value,
      grossLA: `${sqFootageInputValue}`,
      propertyManager,
      spaceInformation,
      onSiteInformation
    }
    try {
      setIsOrderProfileSaving(true)
      await axios.post(`/space-profiles/order`, { ...payload })
      setActiveOrderModal(false)
    } catch (e) {
      setShowError(true)
      setFormError(e.message)
    } finally {
      setIsOrderProfileSaving(false);
    }
  }

  const onOrderProfileChange = (key, value) => {
    setOrderProfile({ ...orderProfile, [key]: value });
  }

  const onPropertyManagerChange = (key, value) => {
    setPropertyManager({ ...propertyManager, [key]: value });
  }

  const onOnSiteInformationChange = (key, value) => {
    setOnSiteInformation({ ...onSiteInformation, [key]: value });
  }

  const onSpaceInformationChange = (key, value) => {
    setSpaceInformation({ ...spaceInformation, [key]: value });
  }

  return (
    <div>
      <AdminModal
        title={t('OrderProfile.OrderModal.title')}
        show={activeOrderModal}
        onHide={() => setActiveOrderModal(false)}
        onSave={() => submitOrderProfiles()}
        isSaving={isOrderProfileSaving}
        readOnly={!selectedSpace?.value}
      >
        <div>
          <p className="font20 my-2">{t('OrderProfile.OrderModal.UnitSelection.title')}</p>
          <UserCompaniesSelect label={t('term.company')} setSelectedCompany={setSelectedCompany} selectAll={false} selectedValue={currentUser && currentUser.company} show={currentUser && currentUser.companies.length > 1} defaultPropStyles={true} />
          <PropertySelect selectedCompany={selectedCompany} selectedProperty={selectedProperty} setSelectedProperty={setSelectedProperty} />
          <SpaceSelect selectedCompany={selectedCompany} selectedProperty={selectedProperty} selectedSpace={selectedSpace} setSelectedSpace={setSelectedSpace} />
          {selectedSpace &&
            <AdminInput
              id="sqFootageInput"
              label={t('OrderProfile.OrderModal.UnitSelection.propertyGLA')}
              type="text"
              value={sqFootageInputValue}
              disabled={true}
            />
          }

          <p className="font20 my-2">{t('OrderProfile.OrderModal.PropertyManagerDetails.title')}</p>
          <AdminInput
            id="propertyManagerName"
            label={t('OrderProfile.OrderModal.PropertyManagerDetails.propertyManager')}
            type="text"
            value={propertyManager.managerName}
            onChange={e => onPropertyManagerChange('managerName', e.target.value)}
          />

          <AdminInput
            id="propertyManagerCellNo"
            label={t('OrderProfile.OrderModal.PropertyManagerDetails.cellPhoneNo')}
            type="text"
            value={propertyManager.propertyManagerCellNo}
            onChange={e => onPropertyManagerChange('propertyManagerCellNo', e.target.value)}
          />

          <AdminInput
            id="propertyManagerEmail"
            label={t('OrderProfile.OrderModal.PropertyManagerDetails.email')}
            type="text"
            value={propertyManager.email}
            onChange={e => onPropertyManagerChange('email', e.target.value)}
          />

          <p className="font20 my-2">{t('OrderProfile.OrderModal.AccessInformation.title')}</p>
          <AdminInput
            id="contactName"
            label={t('OrderProfile.OrderModal.AccessInformation.contactName')}
            type="text"
            value={onSiteInformation.contactName}
            onChange={e => onOnSiteInformationChange('contactName', e.target.value)}
          />

          <AdminInput
            id="contactPhone"
            label={t('OrderProfile.OrderModal.AccessInformation.contactPhone')}
            type="text"
            value={onSiteInformation.phoneNumber}
            onChange={e => onOnSiteInformationChange('phoneNumber', e.target.value)}
          />

          <AdminInput
            id="lockboxCode"
            label={t('OrderProfile.OrderModal.AccessInformation.lockboxCode')}
            type="text"
            value={onSiteInformation.lockboxCode}
            onChange={e => onOnSiteInformationChange('lockboxCode', e.target.value)}
          />

          <AdminInput
            id="lockboxLocation"
            label={t('OrderProfile.OrderModal.AccessInformation.lockboxLocation')}
            type="text"
            value={onSiteInformation.lockboxLocation}
            onChange={e => onOnSiteInformationChange('lockboxLocation', e.target.value)}
          />

          <p className="font20 my-2">{t('OrderProfile.OrderModal.SpaceInformation.title')}</p>
          <AdminSelect
            id="isSpaceVacant"
            label={t('OrderProfile.OrderModal.SpaceInformation.isSpaceVacant')}
            defaultValue=""
            value={SPACE_INFO_OPTIONS.find(_ => _.value === spaceInformation.isSpaceVacant)}
            options={SPACE_INFO_OPTIONS}
            onChange={e => onSpaceInformationChange('isSpaceVacant', e.value)}
          />

          <AdminSelect
            id="areFixturesRemoved"
            label={t('OrderProfile.OrderModal.SpaceInformation.areFixturesRemoved')}
            value={SPACE_INFO_OPTIONS.find(_ => _.value === spaceInformation.areFixturesRemoved)}
            options={SPACE_INFO_OPTIONS}
            onChange={e => onSpaceInformationChange('areFixturesRemoved', e.value)}
          />

          <AdminSelect
            id="isWaterOn"
            label={t('OrderProfile.OrderModal.SpaceInformation.isWaterOn')}
            value={SPACE_INFO_OPTIONS.find(_ => _.value === spaceInformation.isWaterOn)}
            options={SPACE_INFO_OPTIONS}
            onChange={e => onSpaceInformationChange('isWaterOn', e.value)}
          />
          <AdminSelect
            id="isRoofAccessAvailable"
            label={t('OrderProfile.OrderModal.SpaceInformation.isRoofAccessAvailable')}
            value={SPACE_INFO_OPTIONS.find(_ => _.value === spaceInformation.isRoofAccessAvailable)}
            options={SPACE_INFO_OPTIONS}
            onChange={e => onSpaceInformationChange('isRoofAccessAvailable', e.value)}
          />
          <AdminInput
            id="location"
            label={t('OrderProfile.OrderModal.SpaceInformation.location')}
            type="text"
            value={spaceInformation.location}
            onChange={e => onSpaceInformationChange('location', e.target.value)}
          />

          <p className="font20 my-2">{t('OrderProfile.OrderModal.Others.title')}</p>

          <AdminInput
            id="comments"
            label={t('OrderProfile.OrderModal.Others.comments')}
            type="textarea"
            value={orderProfile.comments}
            onChange={e => onOrderProfileChange('comments', e.target.value)}
          />
          <AdminToast onClose={() => setShowError(false)} show={showError} message={formError} style={{ top: '0px', right: '200px' }} />
        </div>
      </AdminModal>
    </div>
  )
}

OrderProfile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  activeOrderModal: PropTypes.bool.isRequired,
  setActiveOrderModal: PropTypes.func.isRequired
};
export default OrderProfile;