/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import TitleDescription from './../../components/TitleDescription/TitleDescription';
import { AdminInput, AdminSelect, Alerts, Spinner } from '@project/components'
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import { RootContext } from "../../hoc/RootContext/RootContext";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "../../utils/axios";
import { isValidEmail } from "../../utils/utils";
import UsersPropertyRolesList from "../../components/UsersPropertyRolesList/UsersPropertyRolesList";
import { USER_TITLES as titleOptions, roleOptions } from '../../constants/index';
import { useIntl } from 'react-intl';
import Title from '../../components/Title/Title';
import styles from './InviteTeamMember.module.css';
import { toast } from 'react-toastify';
import { useFilteredProperties } from '../../utils/hooks';

const InviteTeamMember = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();

  const { currentUser } = useContext(RootContext);

  const [errors, setErrors] = useState([]);
  const [hasInvitePermission, setHasInvitePermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState(null);
  const [displayCompany, setDisplayCompany] = useState(null)
  const [useMfa, setUseMfa] = useState(true);
  const [forceMfa, setForceMfa] = useState(false);
  const [properties, setProperties] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyRole, setCompanyRole] = useState(roleOptions[0]);
  const [companyTitle, setCompanyTitle] = useState(null);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);

  const [globalAccess, setGlobalAccess] = useState(false);

  const [filter, setFilter] = useState('');
  const [currentUserActions, setCurrentUserActions] = useState([]);

  const [allSelected, setAllSelected] = useState({
    viewer: true,
    editor: false,
    designer: false
  });

  const query = new URLSearchParams(useLocation().search);
  const queryCompany = query.get("company")
  const queryDisplayCompany = query.get('displayCompany')

  const [filteredProperties] = useFilteredProperties({ filter, properties });

  useEffect(() => {

    if (currentUser) {
      setCompany(currentUser.company);
      setDisplayCompany(currentUser.companyDisplayName)
    }
    if (currentUser && currentUser.companies.length > 1) {
      let options = currentUser.displayCompanies
        .map(company => ({ label: company.displayName || company.name, value: company.name }))
        .sort((a, b) => (a.label > b.label) ? 1 : -1);
      setCompanyOptions(options);
      if (queryCompany) {
        setCompany(queryCompany);
      }
      setDisplayCompany(queryDisplayCompany)
    }

  }, [currentUser]);

  useEffect(() => {

    const fetch = async () => {
      try {

        setLoading(true);
        setErrors([]);

        const encodedCompany = encodeURIComponent(company)

        const promises = [
          axios.get(`/role?company=${encodedCompany}`),
          axios.get(`/properties/${encodedCompany}?status=active`),
          axios.get(`/companies/${encodedCompany}`)
        ]

        const results = await Promise.all(promises)

        if (results[0].status === 200) {
          const actions = results[0].data.record.actions
          setCurrentUserActions(actions)
          setHasInvitePermission(true);
        } else {
          setErrors([t('error.notAuthorized')]);
          setHasInvitePermission(false);
        }

        if (results[1].status === 200) {
          let props = results[1].data.data.map((p) => {
            if (!p.displayName) p.displayName = p.name;
            return p;
          }).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);
          props.forEach((p) => {
            p.roleId = companyRole.value;
          });
          setProperties(props);
        }

        if (results[2].status === 200) {
          setForceMfa(results[2].data.record.requireMfa);
          if (results[2].data.record.requireMfa) setUseMfa(true);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (company) fetch();

  }, [company]);

  useEffect(() => {

    if (companyRole) {
      if (companyRole.value === 'company-admin') {
        setGlobalAccess(true)
        setIsCompanyAdmin(true)
      } else {
        setIsCompanyAdmin(false)
      }

      const updatedProperties = properties.map(p => ({
        ...p,
        roleId: companyRole.value
      }));
      setProperties(updatedProperties);
      selectAll(companyRole.value, true);
    }

  }, [companyRole]);

  const onPropertyRoleChange = (event, index, roleId) => {
    filteredProperties[index].roleId = roleId;
    setProperties([...properties]);
  };

  const selectAll = (roleId, all) => {
    filteredProperties.forEach((p) => {
      p.roleId = ((all) ? (roleId) : (null));
    });
    setProperties([...properties]);

    let sel = { ...allSelected };
    Object.keys(sel).forEach((key) => {
      sel[key] = all ? key === roleId : false;
    });
    setAllSelected(sel);
  };

  const saveInvite = async () => {
    setLoading(false);
    setErrors([]);

    if (currentUserActions.includes('idcloud:invites:add')) {
      try {
        setLoading(true);

        // validation
        if (!isValidEmail(email)) {
          setErrors([t('error.email.valid')]);
          return;
        }

        // set property roles
        let propertyRoles = [];
        properties.forEach((p) => {
          propertyRoles.push({
            name: p.name,
            roleId: p.roleId
          });
        });

        const postobj = {
          company,
          email,
          companyAdmin: isCompanyAdmin,
          companyRole: companyRole.value,
          companyTitle: companyTitle ? companyTitle.value : companyTitle,
          propertyRoles,
          useMfa,
          globalAccess
        };

        let result = await axios.post(`/invites`, postobj);
        if (result.status === 200) {

          toast(t('Invitations.sent.user'));

          let path = `/users`;
          if (company) path += `?company=${encodeURIComponent(company)}`;
          navigate(path);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(e.response.data.errors.map(err => err.message))
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }
    }
    else {
      setErrors(intl.formatMessage({ id: 'error.addInvite' }, { displayCompany: company }))
    }

  };

  return (
    <>
      <PageContentContainer>
        <Title
          title={t('InviteTeamMember.mainTitle')}
          backRoute={'/users'}
          button={{ click: saveInvite, label: t('InviteTeamMember.inviteMember') }} />

        {errors.length > 0 ?
          <div style={{ "marginTop": "20px" }}><Alerts messages={errors} close={() => setErrors([])} /></div>
          : null}

        {/* multi home company list */}
        {
          companyOptions.length > 0 ?
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.newMember.title')}
                  description={intl.formatMessage({ id: 'InviteTeamMember.newMember.description' }, { displayCompany })}
                />
              </LeftColumn>
              <RightColumn >
                <AdminSelect
                  id="company"
                  propStyles={styles.adminSelect}
                  options={companyOptions}
                  value={{ label: displayCompany, value: company }}
                  onChange={(c) => {
                    setCompany(c.value);
                    setDisplayCompany(c.label)
                  }}
                  isLoading={false} />
              </RightColumn>
            </TwoColumnContent>
            : null
        }

        {hasInvitePermission ?
          <>
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.email.title')}
                  description={t('InviteTeamMember.email.description')} />
              </LeftColumn>
              <RightColumn >
                <AdminInput
                  id="email"
                  customstyle={styles.input}
                  label={t('InviteTeamMember.email.label')}
                  type="email"
                  placeholder={t('InviteTeamMember.email.placeholder')}
                  value={email}
                  onChange={e => setEmail(e.target.value)} />
              </RightColumn>
            </TwoColumnContent>

            {/* use mfa ? */}
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.mfa.title')}
                  description={t('InviteTeamMember.mfa.details')} />
              </LeftColumn>
              <RightColumn>
                <div className={`${styles.checkInput} ${styles.mfa} d-flex align-items-center`}>
                  <label htmlFor="useMfa" className="m-0">{t('InviteTeamMember.mfa')}</label>
                  <input
                    style={{ "marginLeft": "20px" }}
                    id='useMfa'
                    type='checkbox'
                    checked={useMfa}
                    disabled={forceMfa}
                    onChange={(e) => setUseMfa(e.currentTarget.checked)} />
                </div>
                {
                  forceMfa ? <label style={{ marginLeft: 24 }}>{t('InviteTeamMember.mfa.force')}</label> : null
                }
              </RightColumn>
            </TwoColumnContent>

            {/* COMPANY TITLE */}
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.companyTitle.title')}
                  description={t('InviteTeamMember.companyTitle.details')} />
              </LeftColumn>
              <RightColumn >
                <AdminSelect
                  id="companyTitle"
                  propStyles={styles.adminSelect}
                  label={t('InviteTeamMember.companyTitle.label')}
                  defaultValue=""
                  placeholder={t('InviteTeamMember.companyTitle.placeholder')}
                  options={titleOptions}
                  value={companyTitle}
                  onChange={(c) => setCompanyTitle(c)}
                  isLoading={false} />
              </RightColumn>
            </TwoColumnContent>

            {/* COMPANY ROLE */}
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.role.title')}
                  description={t('InviteTeamMember.role.details')} />
              </LeftColumn>
              <RightColumn >
                <div>
                  <AdminSelect
                    id="role-select"
                    propStyles={styles.adminSelect}
                    label={t('InviteTeamMember.role.label')}
                    options={roleOptions}
                    value={companyRole}
                    onChange={(c) => setCompanyRole(c)}
                    isLoading={false} />
                  <div className="pt-2">
                    {companyRole.desc}
                  </div>
                </div>
              </RightColumn>
            </TwoColumnContent>

            {/* COMPANY ROLE */}
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.globalAccess.title')}
                  description={t('InviteTeamMember.globalAccess.desc')} />
              </LeftColumn>
              <RightColumn >
                <div className={`${styles.checkInput} ${styles.mfa} d-flex align-items-center`}>
                  <label htmlFor="has-global-access" className="m-0">{t('InviteTeamMember.globalAccess.label')}</label>
                  <input
                    style={{ "marginLeft": "20px" }}
                    id={'has-global-access'}
                    type='checkbox'
                    checked={globalAccess}
                    disabled={isCompanyAdmin}
                    onChange={(e) => setGlobalAccess(e.target.checked)} />
                </div>
              </RightColumn>
            </TwoColumnContent>

            {/* PROPERTY ROLES */}
            <TwoColumnContent className="pb-4">
              <LeftColumn>
                <TitleDescription
                  title={t('InviteTeamMember.propertyRole.title')}
                  description={t('InviteTeamMember.propertyRole.details')} />
              </LeftColumn>
              <RightColumn padded={true} >
                {
                  !globalAccess ?
                    <div className="pl-2">
                      <AdminInput
                        id="role"
                        label={t('InviteTeamMember.propertyRole.label')}
                        type="text"
                        placeholder={t('InviteTeamMember.propertyRole.placeholder')}
                        value={filter}
                        onChange={e => setFilter(e.target.value)} />
                      <UsersPropertyRolesList
                        properties={filteredProperties}
                        allSelectedViewer={allSelected.viewer}
                        allSelectedEditor={allSelected.editor}
                        allSelectedDesigner={allSelected.designer}
                        onPropertyRoleChange={onPropertyRoleChange}
                        selectAll={selectAll}
                        globalAccess={globalAccess}
                      />
                    </div>
                    :
                    <div className="pl-2">{t('InviteTeamMember.globalAccess.disable')}</div>
                }
              </RightColumn>
            </TwoColumnContent>
          </>
          : null}

      </PageContentContainer>
      {loading ?
        <Spinner />
        : null
      }
    </>
  );
};

export default InviteTeamMember;
