import { componentsEn } from '@project/components';
export default {
  ...componentsEn,
  "currencyCode": "USD",

  /************************************************************
   * IDCloud Components
   * -----------------------------------
   * First section of id corresponds to component using the
   * translation
   ************************************************************/
  "Alerts.title": "Alertas",
  "Alerts.desc": "Alertas activas",
  "Alerts.filterPlaceholder": "Filtrar alertas activas por propiedad, tipo, campo o fecha.",
  "Alerts.property": "Propiedad",
  "Alerts.stencilName": "Tipo de Activo",
  "Alerts.fieldName": "Campo",
  "Alerts.alertDate": "Fecha de Alerta",
  "Alerts.view": "Ver",

  "Amp.allProperties": "Todas las propiedades",
  "Amp.monthToDate": "Mes hasta hoy",
  "Amp.yearToDate": "Año hasta hoy",
  "Amp.prevMonth": "Mes anterior",
  "Amp.prev3Month": "3 meses atrás",
  "Amp.prev6Month": "6 meses atrás",
  "Amp.prev2Years": "2 años atrás",

  "Amp.1Week": "1 Semana",
  "Amp.2Week": "2 Semanas",
  "Amp.3Week": "3 Semanas",
  "Amp.1Month": "1 Mes",
  "Amp.3Month": "3 Meses",
  "Amp.6Month": "6 Meses",
  "Amp.1Year": "1 Año",

  "AssignedInspections.placeholder": "Filtrar inspecciones por nombre de plantilla, propiedad, etc.",
  "AssignedInspections.title": "Inspecciones Asignadas",
  "AssignedInspections.unknownError": "Hubo un error al buscar las inspecciones asignadas.",
  "AssignedInspections.view": "Ver",
  "AssignedInspections.tabs.assigned": "Inspecciones Asignadas",
  "AssignedInspections.tabs.properties": "Propiedades",
  "AssignedInspections.properties.inspections": "{count, plural, =0 {# inspecciones} one {# inspección} other {# inspecciones}}",
  "AssignedInspections.properties.placeholder": "Buscar propiedades por nombre o dirección",
  "AssignedInspections.inspections": "Inspecciones",
  "AssignedInspections.properties.goBack": "Volver a Propiedades",

  "AvailableReports.amp.title": "Informe AMP",
  "AvailableReports.amp.description": "Informes de Agua y Alcantarillas para {company}",
  "AvailableReports.amp.linkTitle": "Informe AMP",
  "AvailableReports.link": "Ver {report}",

  "Reports.Amp.title": "Informe AMP detallado",
  "Reports.Amp.Facility": "Instalación",
  "Reports.Amp.Tenant": "Arrendatario",
  "Reports.Amp.BillDate": "Fecha de la factura",
  "Reports.Amp.TotalCharged": "Total cobrado",
  "Reports.Amp.TotalCost": "Costo total",
  "Reports.Amp.WaterCost": "Costo de agua",
  "Reports.Amp.SewerCost": "Costo de alcantarillas",
  "Reports.Amp.WasteCost": "Costo de desperdicios",

  "Company.mainTitle": "Configuración de la compañía",
  "Company.header.title": "Seleccione una compañía.",
  "Company.header.description": "Su cuenta tiene acceso administrativo a más de una compañía. La compañía con la que está trabajando ahora es {companyDisplayName}.",
  "Company.companySettings.saved": "La configuración de la compañía se guardó exitosamente",
  "Company.tab.generalSettings": "Opciones generales",
  "Company.tab.idDigital": "ID Digital",
  "Company.tab.rpm": "RPM",
  "Company.tab.theming": "Tema y estilos",
  "Company.theming.details": "Personalice el tema de las páginas de su compañía.",
  "Company.theming.saveSettings": "Guardar configuración",
  "Company.theming.colors.title": "Colores de tema de la compañía",
  "Company.theming.colors.description": "Defina los colores de tema para la compañía. Estos serán utilizados para personalizar las páginas de su compañía.",
  "Company.theming.primaryColor.label": "Color Primario",
  "Company.theming.secondaryColor.label": "Color Secundario",
  "Company.idDigitalSettings.spaceColor.label": "Colores de espacio para el plano",
  "Company.idDigitalSettings.spaceColor.desc": "Colores de relleno y borde para espacios mostrados en el plano",
  "Company.idDigitalSettings.spaceColor.fillLabel": "Relleno",
  "Company.idDigitalSettings.spaceColor.borderLabel": "Borde",
  "Company.idDigitalSettings.spaceBackground.label": "Color de relleno para espacio",
  "Company.idDigitalSettings.spaceBackground.desc": "Color de relleno para espacio mostrado en el plano",
  "Company.idDigitalSettings.spaceBorder.label": "Color de borde para espacio",
  "Company.idDigitalSettings.spaceBorder.desc": "Color de borde para espacio mostrado en el plano",
  "Company.idDigitalSettings.vacantSpaceBackground.label": "Color de relleno para espacio vacante en el plano",
  "Company.idDigitalSettings.vacantSpaceBackground.desc": "Color de relleno para espacio vacante mostrado en el plano",
  "Company.idDigitalSettings.spaceText.label": "Color de etiqueta de espacio para espacio",
  "Company.idDigitalSettings.spaceText.desc": "Color de texto utilizado en las etiquetas de espacio mostradas en el plano",
  "Company.idDigitalSettings.siteMapSpaceLabel.label": "Etiqueta de espacio en el plano",
  "Company.idDigitalSettings.siteMapSpaceLabel.desc": "Seleccione qué información se presentará en los espacios",
  "Company.idDigitalSettings.siteMapSpaceLabel.spaceNumber": "Número de espacio",
  "Company.idDigitalSettings.siteMapSpaceLabel.tenantName": "Nombre de arrendatario",
  "Company.idDigitalSettings.siteMapSpaceLabel.squareFootage": "Pies Cuadrados",
  "Company.idDigitalSettings.overrideID360SpaceColor.label": "Anular color de espacio de ID360",
  "Company.idDigitalSettings.overrideID360SpaceColor.desc": "Ignora el color de estado de ID360 en los espacios",
  "Company.idDigitalSettings.showLandFillsInIddigital.label": "Rellenos para Paisaje",
  "Company.idDigitalSettings.showLandFillsInIddigital.desc": "Mostrar rellenos de Paisaje en IDDigital",
  "Company.idDigitalSettings.showLandFillsOnLeaseRadar.desc": "Mostrar rellenos de Paisaje en Radar de Alquileras",
  "Company.error.bootstrapClass": "Se encontró una clase de Bootstramp en el código CSS personalizado del encabezado o pie de página.",
  "Company.error.invalidCSS": "El código CSS para personalizar el encabezado o el pie de página es inválido.",

  "CompanyGeneralTab.saveSettings": "Guardar configuración",
  "CompanyGeneralTab.generalSettings": "Opciones generales",
  "CompanyGeneralTab.details": "Configure las opciones generales para esta compañía.",
  "CompanyGeneralTab.mfa.title": "Requerir MFA (Autenticación Multi-Factor)",
  "CompanyGeneralTab.mfa.label": "¿Requerir que la cuenta de la compañía utilice MFA?",
  "CompanyGeneralTab.mfa.details": "Marcar esta casilla obligará a todos los usuarios representantes de {companyDisplayName} a realizar autenticación multi-factor cuando inicien sesión en ID Plans. La autenticación multi-factor sólo es requerida cada 30 días y/o cuando se accede a las aplicaciones de ID Plans desde nuevos dispositivos.",
  "CompanyGeneralTab.sso.title": "Requerir SSO (inicio de sesión único)",
  "CompanyGeneralTab.sso.label": "¿Requerir que la cuenta de la empresa use SSO?",
  "CompanyGeneralTab.sso.details": "Marcar esta casilla requerirá que todos los usuarios de {companyDisplayName} realicen la autenticación SSO al iniciar sesión en ID Plans.",
  "CompanyGeneralTab.ssoUserFilter.title": "Filtro de usuario SSO",
  "CompanyGeneralTab.ssoUserFilter.label": "Filtrar usuarios para SSO (por ejemplo, @ejemplo\\.com$)",
  "CompanyGeneralTab.ssoUserFilter.details": "El correo electrónico del usuario debe coincidir con la expresión regular anterior para permitir el uso de SSO",
  "CompanyGeneralTab.logo.title": "Logo de la Compañía",
  "CompanyGeneralTab.logo.description": "Este logo aparecerá en las páginas de su compañía.",
  "CompanyGeneralTab.logoSize.placeholder": "Seleccionar Tamaño del Logo",
  "CompanyGeneralTab.logoSize.title": "Tamaño del Logo",
  "CompanyGeneralTab.logoSize.description": "Seleccione el tamaño del logo que se mostrará en las páginas de su empresa.",
  "CompanyGeneralTab.logoSize.small": "Pequeño (50px)",
  "CompanyGeneralTab.logoSize.regular": "Normal (100px)",
  "CompanyGeneralTab.logoSize.large": "Grande (150px)",
  "CompanyGeneralTab.logoSize.larger": "Más grande (175px)",
  "CompanyGeneralTab.logoSize.jumbo": "Jumbo (200px)",
  "CompanyGeneralTab.logoSize.largest": "La más grande (250px)",
  "CompanyGeneralTab.primaryEmail": "E-mail principal",
  "CompanyGeneralTab.propertyManagementEmail": "E-mail de la Gerencia de Propiedades",
  "CompanyGeneralTab.leasingContactEmail": "E-mail del Contacto de Alquileres",
  "CompanyGeneralTab.primaryEmailDesc": "Este es el correo electrónico oficial de la compañía.",
  "CompanyGeneralTab.propertyManagementEmailDesc": "E-mail alternativo para gerencia de propiedades.",
  "CompanyGeneralTab.leasingContactEmailDesc": "E-mail alternativo para contacto por alquileres.",
  "CompanyGeneralTab.IdpRecordId": "ID del registro IDP",
  "CompanyGeneralTab.bypassValidation": "Evitar validación",
  "CompanyGeneralTab.IntegrationId": "Identificación de la integración",
  "CompanyGeneralTab.enforceValidation": "Aplicar validación",

  "CompanyIdDigitalTab.title": "Opciones de IDDigital",
  "CompanyIdDigitalTab.saveSettings": "Guardar configuración",
  "CompanyIdDigitalTab.details": "Configure las opciones para las páginas de IDDigital pertenecientes a su compañía.",
  "CompanyIdDigitalTab.toggleTenant.title": "Mostrar nómina de arrendatarios a la izquierda",
  "CompanyIdDigitalTab.toggleTenant.description": "Activa o desactiva la nómina de arrendatarios a la izquierda del plano en la página de detalles de la propiedad.",
  "CompanyIdDigitalTab.showContactForm.title": "Mostrar formulario de contacto",
  "CompanyIdDigitalTab.showContactForm.description": "Muestra u oculta el formulario de contacto en la página de detalles de la propiedad.",
  "CompanyIdDigitalTab.removeBackgroundShapeBorders.title": "Eliminar bordes en formas de fondo",
  "CompanyIdDigitalTab.removeBackgroundShapeBorders.description": "Quita el borde alrededor del límite de las formas dibujadas en la capa de fondo.",
  "CompanyIdDigitalTab.customMarker.title": "Marcador personalizado para mapa general",
  "CompanyIdDigitalTab.customMarker.description": "Esta imagen se mostrará como marcador de las propiedades de su compañía en el mapa general.",
  "CompanyIdDigitalTab.customHeader.title": "Personalizar encabezado",
  "CompanyIdDigitalTab.customHeader.description": "Agregue código HTML aquí para personalizar su barra de encabezado en IDDigital.",
  "CompanyIdDigitalTab.customFooter.title": "Personalizar pie de página",
  "CompanyIdDigitalTab.customFooter.description": "Agregue código HTML aquí para personalizar su barra de pie de página en IDDigital.",
  "CompanyIdDigitalTab.customFooter.css.description": "Agregue código CSS aquí para personalizar la apariencia de su código HTML. El uso de selectores de etiqueta o de selectores de clase de Bootstrap no está permitido.",
  "CompanyIdDigitalTab.customFont.title": "Selección de fuente personalizada",
  "CompanyIdDigitalTab.customFont.description": "Fuente personalizada para páginas 'General' y 'Propiedad'",
  "CompanyIdDigitalTab.customFont.preview": "Previsualizar",
  "CompanyIdDigitalTab.customFont.selectFont": "Seleccionar fuente",
  "CompanyIdDigitalTab.customFontOnSpaces.title": "Fuente para espacios en el plano",
  "CompanyIdDigitalTab.customFontOnSpaces.description": "Fuente personalizada para espacios en IDDigital",

  "CompanyIdInspectTab.details": "Configure opciones para ID Inspect.",
  "CompanyIdInspectTab.InspectionArchival.title": "Activar archivado automático",
  "CompanyIdInspectTab.InspectionArchival.description": "Cuando esta opción esté activada, se archivarán automáticamente las inspecciones que no hayan sido actualizadas en 90 días.",

  "CompanyRPMTab.disableRPM.title": "Disable RPM Access",
  "CompanyRPMTab.disableRPM.description": "Disable RPM access for this company.",

  "Company.rpm.configure": "Configure RPM access for this company.",

  "Company.portal.enable": "Se habilitó Tenant Portal para esta compañía.",
  "Company.portal.title": "Tenant Portal",
  "Company.portal.true": "Esta compañía ya ha sido incorporada a IDT y no puede desactivarse desde estas opciones.",
  "Company.portal.false": "Habilitar Tenant Portal para esta compañía iniciará el proceso de 'incorporación' y no es reversible. El proceso importará la compañía y sus propiedades, contactos, espacios y arrendatarios existentes.",
  "Company.portal.confirmation": "¿Está seguro de habilitar Tenant Portal para esta compañía? Esta acción es irreversible e iniciará una sincronización de objetos entre RPM y IDT para ",
  "Company.portal.alreadyError": "Esta compañía ya ha sido incorporada a IDT.",
  "Company.portal.canTakeAWhile": "El proceso de incorporación puede demorar unos minutos en finalizar.",

  "COIUpload.instructions": "Por favor suba un archivo con el Certificado de Seguro.",
  "COIUpload.acceptedFileFormats": "Formatos de archivo soportados: {formats}",
  "COIUpload.fileFormatError": "Por favor suba un archivo que tenga alguno de los formatos soportados.",

  "ContactsList.select": "Seleccionar",
  "ContactsList.selected": "Seleccionado",
  "ContactsList.remove": "Eliminar",
  "ContactsList.name": "Nombre",
  "ContactsList.title": "Cargo",
  "ContactsList.phone": "Teléfono",
  "ContactsList.email": "Email",

  "Dashboard.propertyCount": "{count, plural, =0 {No hay propiedades} one {1 propiedad} other {# propiedades}}",
  "Dashboard.totalSqft": "Total de ft²",
  "Dashboard.publishedProfiles": "Perfiles publicados de ID360",
  "Dashboard.actualSqft": "ft² actuales {footage}",
  "Dashboard.lastDays": "En los últimos 30 días",
  "Dashboard.recentProfiles": "Estos son sus perfiles de espacio de ID360 más recientes",
  "Dashboard.noProfiles": "No se encontraron perfiles de espacio recientes de ID360",
  "Dashboard.spaceProfile": "Perfil de Espacio",
  "Dashboard.publishDate": "Fecha de Publicación",
  "Dashboard.myProperties.label": "Mis Propiedades",
  "Dashboard.myProperties.placeholder": "Buscar por propiedad o compañía",
  "Dashboard.spaceProfiles.label": "Todos los Espacios",
  "Dashboard.spaceProfiles.placeholder": "Filtrar espacios por nombre o ubicación",
  "Dashboard.profilesHeader": "Seguimiento de estado de ID 360",
  "Dashboard.amp.title": "Informes AMP",
  "Dashboard.amp.details": "Vea su reporte detallado. Siga y administre activos de propiedad tales como servicios utilitarios y de administración de desperdicios.",
  "Dashboard.amp.revenue": "Ganancia Mensual",
  "Dashboard.amp.savings": "Ahorros Totales",
  "Dashboard.amp.totalCharged": "Total Facturado",
  "Dashboard.amp.totalRecouped": "Total Recuperado",
  "Dashboard.amp.totalCost": "Costo Total",
  "Dashboard.export": "Exportar Todo",
  "Dashboard.subscriptionsTracker.title": "Seguimiento de estado de RPM",
  "Dashboard.subscriptionsTracker.scopes.basic": "Básico",
  "Dashboard.subscriptionsTracker.scopes.dimensions": "Dimensiones",
  "Dashboard.subscriptionsTracker.scopes.drainage": "Drenaje",
  "Dashboard.subscriptionsTracker.scopes.dumpster": "Basurero",
  "Dashboard.subscriptionsTracker.scopes.elec": "Electricidad",
  "Dashboard.subscriptionsTracker.scopes.er": "Emergencias",
  "Dashboard.subscriptionsTracker.scopes.fire": "Fuego",
  "Dashboard.subscriptionsTracker.scopes.gas": "Gas",
  "Dashboard.subscriptionsTracker.scopes.hvac": "HVAC",
  "Dashboard.subscriptionsTracker.scopes.idvision": "ID Vision",
  "Dashboard.subscriptionsTracker.scopes.irrig": "Irrigación",
  "Dashboard.subscriptionsTracker.scopes.land": "Paisaje",
  "Dashboard.subscriptionsTracker.scopes.park": "Aparcamiento",
  "Dashboard.subscriptionsTracker.scopes.pavement": "Pavimento",
  "Dashboard.subscriptionsTracker.scopes.pylon": "Letrero de Pilón",
  "Dashboard.subscriptionsTracker.scopes.roof": "Techado",
  "Dashboard.subscriptionsTracker.scopes.sign": "Señalización",
  "Dashboard.subscriptionsTracker.scopes.space": "Espacio",
  "Dashboard.subscriptionsTracker.scopes.water": "Agua",
  "Dashboard.subscriptionsTracker.scopesTitle": "Secciones",
  "Dashboard.subscriptionsTracker.allScopes": "Todas las Secciones",
  "Dashboard.subscriptionsTracker.filterPlaceholder": "Filtrar por nombre de propiedad",
  "Dashboard.subscriptionsTracker.filterByDate": "Rango de fechas",

  "EditTeamMember.breadcrumb": "Administrarar Miembros de Equipo",
  "EditTeamMember.mainTitle": "Editar miembro de equipo",
  "EditTeamMember.updateMember": "Actualizar Miembro",
  "EditTeamMember.email.title": "E-mail del miembro",
  "EditTeamMember.email.details": "Actualiza el correo electrónico del miembro.",
  "EditTeamMember.email.placeholder": "Ingrese correo electrónico",
  "EditTeamMember.email.label": "Email",
  "EditTeamMember.firstName.title": "Nombre del miembro",
  "EditTeamMember.firstName.details": "Actualiza el nombre del miembro.",
  "EditTeamMember.firstName.placeholder": "Ingrese nombre",
  "EditTeamMember.firstName.label": "Nombre",
  "EditTeamMember.lastName.title": "Apellido del miembro",
  "EditTeamMember.lastName.details": "Actualiza el apellido del miembro.",
  "EditTeamMember.lastName.placeholder": "Ingrese apellido",
  "EditTeamMember.lastName.label": "Apellido",
  "EditTeamMember.mfa.title": "Autenticación Multi-Factor (MFA)",
  "EditTeamMember.mfa.details": "El usuario tendrá activada la autenticación multi-factor para su cuenta.",
  "EditTeamMember.mfa.label": "¿La cuenta está protegida con MFA?",
  "EditTeamMember.admin.title": "Administradores de la compañía",
  "EditTeamMember.admin.details": "Los administradores pueden agregar, editar y eliminar miembros del equipo.",
  "EditTeamMember.admin.checkbox": "¿Es el miembro un administrador de compañía?",
  "EditTeamMember.admin.description": "Actualmente, la gestión de equipos en IDCloud es la única funcionalidad restringida. A medida que se desarrollen nuevas características, los administradores de compañía podrían tener acceso a más configuraciones.",
  "EditTeamMember.companyTitle.title": "Cargo en la compañía",
  "EditTeamMember.companyTitle.details": "El cargo del usuario será visible si se lo selecciona como contacto para una propiedad.",
  "EditTeamMember.companyTitle.placeholder": "Seleccione un cargo",
  "EditTeamMember.companyTitle.label": "Cargo en la compañía",
  "EditTeamMember.status.title": "Estado del usuario",
  "EditTeamMember.status.details": "Indica el estado actual del usuario.",
  "EditTeamMember.status.placeholder": "Seleccione un estado",
  "EditTeamMember.status.label": "Estado del usuario",
  "EditTeamMember.permissions.title": "Asignación de Roles de Propiedades",
  "EditTeamMember.permissions.details": "A los nuevos miembros de equipo se les puede asignar diferentes roles por propiedad, así como también se les puede restringir el acceso a ciertas propiedades simplemente dejándoles sin rol asignado en ellas.",
  "EditTeamMember.permissions.placeholder": "Ingrese texto para filtar por nombre de propiedad",
  "EditTeamMember.permissions.label": "Permisos de propiedad personalizados",
  "EditTeamMember.role.title": "Rol primario",
  "EditTeamMember.role.details": "El rol primario es utilizado como el rol predeterminado para el acceso del usuario a las propiedades. Este rol se aplica cuando los permisos individuales no están especificados y para propiedades creadas en el futuro.",
  "EditTeamMember.role.label": "Rol primario del usuario",

  "EditVendor.breadcrumb": "Administrar Proveedores",
  "EditVendor.mainTitle": "Administrar Proveedor",
  "EditVendor.saveVendor": "Guardar",
  "EditVendor.deleteVendor": "Eliminar",
  "EditVendor.information.title": "Información del Proveedor",
  "EditVendor.information.description": "Sólo el proveedor dueño o administrador de esta cuenta de compañía puede editar detalles de perfil.",
  "EditVendor.company.title": "Compañía",
  "EditVendor.company.description": "Seleccione las compañías con las que desea asociar al proveedor.",
  "EditVendor.propertyRole.title": "Acceso a propiedades y espacios",
  "EditVendor.propertyRole.details": "Edite el acceso del proveedor a propiedades y espacios.",
  "EditVendor.propertyRole.placeholder": "Ingrese texto para filtar por nombre de propiedad",
  "EditVendor.removeVendorWarning": "¿Está seguro de que desea eliminar a este proveedor?",

  "Export.profiles": "Exportando datos de perfiles, esto puede tardar unos momentos",
  "Export.complete": "Exportación finalizada",


  "Inspections.title": "ID Inspections",


  "Invitations.mainTitle": "Registrar nuevo miembro",
  "Invitations.complete": "Completar registración",
  "Invitations.title": "Información del Usuario y de la Cuenta",
  "Invitations.email": "Email",
  "Invitations.email.placeholder": "Ingrese su correo electrónico",
  "Invitations.username": "Nombre de usuario",
  "Invitations.username.placeholder": "Ingrese su nombre de usuario",
  "Invitations.password": "Contraseña",
  "Invitations.password.placeholder": "Ingrese su contraseña",
  "Invitations.confirmPassword": "Confirmar contraseña",
  "Invitations.firstName": "Nombre",
  "Invitations.firstName.placeholder": "Ingrese su nombre",
  "Invitations.lastName": "Apellido",
  "Invitations.lastName.placeholder": "Ingrese su apellido",
  "Invitations.phone": "Teléfono",
  "Invitations.phone.placeholder": "Ingrese su teléfono",
  "Invitations.mfa": "¿Proteger su cuenta con autenticación multi-factor (MFA)?",
  "Invitations.privacyPolicy": "Al hacer clic en 'Completar registración', usted acepta los Términos de Servicio y la Política de Privacidad.",
  "Invitations.sent.user": "Se envió una invitación al nuevo miembro de equipo",
  "Invitations.sent.vendor": "Se envió una invitación al nuevo proveedor",
  "Invitations.sent.existingVendor": "El proveedor ya existe. Se le otorgó acceso a las propiedades especificadas y se le envió un e-mail de bienvenida.",

  "InviteTeamMember.globalAccess.title": "Acceso Global a Propiedades",
  "InviteTeamMember.globalAccess.desc": "Permite que el usuario tenga acceso a todas las propiedades actuales y futuras.",
  "InviteTeamMember.globalAccess.label": "¿Tiene el usuario acceso global a todas las propiedades?",
  "InviteTeamMember.globalAccess.disable": "Desactive el acceso global para asignar permisos específicos por propiedad.",

  "InviteTeamMember.breadcrumb": "Administrar Miembros de Equipo",
  "InviteTeamMember.mainTitle": "Invitar a miembro de equipo",
  "InviteTeamMember.inviteMember": "Enviar invitación",
  "InviteTeamMember.newMember.title": "Compañía del nuevo miembro",
  "InviteTeamMember.newMember.description": "Su cuenta tiene acceso administrativo sobre más de una compañía. Actualmente está utilizando la compañía {displayCompany}.",
  "InviteTeamMember.email.title": "Email del nuevo miembro",
  "InviteTeamMember.email.description": "Utilizaremos este correo electrónico para enviar un enlace donde el nuevo miembro podrá completar toda la información necesaria para su registro.",
  "InviteTeamMember.email.label": "Email",
  "InviteTeamMember.email.placeholder": "Ingrese correo electrónico",
  "InviteTeamMember.mfa.title": "Autenticación Multi-Factor (MFA)",
  "InviteTeamMember.mfa.details": "El usuario tendrá activada la autenticación multi-factor para su cuenta.",
  "InviteTeamMember.mfa": "¿La cuenta está protegida con MFA?",
  "InviteTeamMember.mfa.force": "La configuración de la companía requiere el uso de MFA para todos los usuarios",
  "InviteTeamMember.admin.title": "Administrador de compañía",
  "InviteTeamMember.admin.details": "Los administradores pueden agregar, editar y eliminar miembros del equipo.",
  "InviteTeamMember.admin": "¿Es el miembro un administrador de compañía?",
  "InviteTeamMember.admin.description": "Actualmente, la gestión de equipos en IDCloud es la única funcionalidad restringida. A medida que se desarrollen nuevas características, los administradores de compañía podrían tener acceso a más configuraciones.",
  "InviteTeamMember.companyTitle.title": "Cargo en la compañía",
  "InviteTeamMember.companyTitle.details": "El cargo del usuario será visible si se lo selecciona como contacto para una propiedad.",
  "InviteTeamMember.companyTitle.label": "Cargo en la compañía",
  "InviteTeamMember.companyTitle.placeholder": "Seleccione un cargo",
  "InviteTeamMember.role.title": "Rol primario",
  "InviteTeamMember.role.details": "El rol primario es utilizado como el rol predeterminado para el acceso del usuario a las propiedades. Este rol se aplica cuando los permisos individuales no están especificados y para propiedades creadas en el futuro.",
  "InviteTeamMember.role.label": "Rol primario del usuario",
  "InviteTeamMember.propertyRole.title": "Asignación de Roles de Propiedades",
  "InviteTeamMember.propertyRole.details": "A los nuevos miembros de equipo se les puede asignar diferentes roles por propiedad, así como también se les puede restringir el acceso a ciertas propiedades simplemente dejándoles sin rol asignado en ellas.",
  "InviteTeamMember.propertyRole.label": "Permisos de propiedad personalizados",
  "InviteTeamMember.propertyRole.placeholder": "Ingrese texto para buscar por nombre de propiedad",

  "InviteVendor.breadcrumb": "Administrar Proveedores",
  "InviteVendor.mainTitle": "Invitar Proveedor",
  "InviteVendor.inviteMember": "Enviar invitación",
  "InviteVendor.newVendor.title": "Acceso a compañía.",
  "InviteVendor.newVendor.description": "Seleccione la compañía con la que desea asociar al proveedor.",
  "InviteVendor.email.title": "Email del proveedor",
  "InviteVendor.email.description": "Ingrese el e-mail del proveedor al cual está invitandoo. Si el e-mail ya se encuentra registrado, administre a este usuario desde el tablero de Proveedores.",
  "InviteVendor.email.label": "Email",
  "InviteVendor.email.placeholder": "Ingrese email",
  "InviteVendor.name.title": "Nombre completo del proveedor",
  "InviteVendor.name.description": "Ingrese el nombre y el apellido del proveedor al que está invitando.",
  "InviteVendor.name.placeholder": "Nombre Apellido",
  "InviteVendor.propertyRole.title": "Acceso a propiedades y espacios",
  "InviteVendor.propertyRole.details": "Edite el acceso del proveedor a las propiedades y a los espacios.",
  "InviteVendor.propertyRole.label": "Permisos de propiedad personalizados",
  "InviteVendor.propertyRole.placeholder": "Ingrese texto para filtrar por propiedad o ubicación",
  "InviteVendor.propertyRole.noPermsWarning": "No ha asignado ninguna propiedad a este proveedor. ¿Está seguro de que desea invitar al proveedor sin ningún acceso a propiedades?",

  "LeaseRadar.title": "Radar de Alquileres",
  "LeaseRadar.occupied": "Ocupados",
  "LeaseRadar.expiring": "Por vencer",
  "LeaseRadar.vacant": "Vacantes",
  "LeaseRadar.filterSpaces.placeholder": "Filtrar espacios por nombre o ubicación",

  "LeaseRadarDetails.expiration": "Alquileres por Vencer",
  "LeaseRadarDetails.vacant": "Espacios Vacantes",
  "LeaseRadarDetails.occupiedSpaces": "Espacios Ocupados",
  "LeaseRadarDetails.360": "ID360s",

  "Login.redirect.loading": "Accediendo a ID Plans..",
  "LoginVendor.welcome": "¡Bienvenido, proveedor! Verifique su identidad completando este formulario:",

  "LostPassword.label": "Ingrese la dirección de correo electrónico asociada a su cuenta.",
  "LostPassword.placeholder": "Dirección de correo electrónico",
  "LostPassword.send": "Enviar solicitud de acceso",

  "MfaForm.select": "Seleccione un método de envío para su código de autenticación multi-factor",
  "MfaForm.email": "Email",
  "MfaForm.phone": "Teléfono",
  "MfaForm.title": "Autenticación Multi-Factor (MFA)",
  "MfaForm.details": "Su cuenta requiere de autenticación multi-factor. Por favor seleccione el método por el cual desea recibir su código de autenticación.",
  "MfaForm.request": "Solicitar Código",
  "MfaForm.enterCode": "Ingrese el código de 6 dígitos que fue enviado a su {device}.",
  "MfaForm.placeholder": "Código MFA",
  "MfaForm.dontAskAgain": "No solicitar nuevamente en este dispositivo por 30 días.",
  "MfaForm.verify": "Verificar",

  "MenuDrawer.dashboard": "Tablero",
  "MenuDrawer.company": "Compañía",
  "MenuDrawer.manageTeamMembers": "Administrar equipo",
  "MenuDrawer.settings": "Configuración",
  "MenuDrawer.userSettings": "Perfil de usuario",
  "MenuDrawer.vendorSettings": "Perfil de proveedor",
  "MenuDrawer.propertySettings": "Configuración de propiedad",
  "MenuDrawer.reports": "Reportes",
  "MenuDrawer.leaseRadar": "Radar de alquileres",
  "MenuDrawer.collapse": "Ocultar menú",
  "MenuDrawer.team": "Equipo",
  "MenuDrawer.menu": "Menú",
  "MenuDrawer.management": "Administración",

  "MenuDropdown.users": "Usuarios",
  "MenuDropdown.vendors": "Proveedores",
  "MenuDropdown.company": "Compañía",
  "MenuDropdown.property": "Propiedad",

  "OrderProfile.newOrderProfile": "Solicitar ID360",
  "OrderProfile.OrderModal.title": "Solicitar ID360",
  "OrderProfile.selectProperty.warning": "Por favor seleccione una propiedad",
  "OrderProfile.OrderModal.UnitSelection.title": "Selección de Unidad",
  "OrderProfile.OrderModal.UnitSelection.propertyGLA": "Superficie bruta arrendable (GLA)",
  "OrderProfile.OrderModal.ShoppingCenter.propertyName": "Nombre de Propiedad",
  "OrderProfile.OrderModal.ShoppingCenter.unitNo": "Unidad #",

  "OrderProfile.OrderModal.PropertyManagerDetails.title": "Detalles del Gerente de Propiedades",
  "OrderProfile.OrderModal.PropertyManagerDetails.propertyManager": "Gerente de Propiedades",
  "OrderProfile.OrderModal.PropertyManagerDetails.cellPhoneNo": "Celular",
  "OrderProfile.OrderModal.PropertyManagerDetails.email": "Email",

  "OrderProfile.OrderModal.AccessInformation.title": "Información de Acceso",
  "OrderProfile.OrderModal.AccessInformation.contactName": "Nombre de contacto en el sitio",
  "OrderProfile.OrderModal.AccessInformation.contactPhone": "Teléfono del contacto en el sitio",
  "OrderProfile.OrderModal.AccessInformation.lockboxCode": "Código de la caja de seguridad",
  "OrderProfile.OrderModal.AccessInformation.lockboxLocation": "Ubicación de la caja de seguridad",

  "OrderProfile.OrderModal.SpaceInformation.title": "Información del Espacio",
  "OrderProfile.OrderModal.SpaceInformation.isSpaceVacant": "¿El espacio está actualmente vacante?",
  "OrderProfile.OrderModal.SpaceInformation.areFixturesRemoved": "¿Se han removido todos los accesorios?",
  "OrderProfile.OrderModal.SpaceInformation.isWaterOn": "¿Hay servicio de agua funcionando en el espacio?",
  "OrderProfile.OrderModal.SpaceInformation.isRoofAccessAvailable": "¿Se puede acceder al espacio a través del techo?",
  "OrderProfile.OrderModal.SpaceInformation.location": "Si el acceso por techo está disponible, indique su ubicación",

  "OrderProfile.OrderModal.Others.title": "Otros",
  "OrderProfile.OrderModal.Others.comments": "Comentarios",

  "MigrateProperty.title": "Migrate Property",
  "MigrateProperty.newPropertyDisplayName": "New Property Display Name",
  "MigrateProperty.migratePropertyRecord": "Migrate Property Record",
  "MigrateProperty.migratePageRecords": "Migrate Page Records",
  "MigrateProperty.migrateSpaceProfiles": "Migrate Space Profiles",
  "MigrateProperty.migrateSpaceRecords": "Migrate Space Records",
  "MigrateProperty.migrateAssetRecords": "Migrate Asset Records",
  "MigrateProperty.migrateTenantRecords": "Migrate Tenant Records",
  "MigrateProperty.migrateVisionRecords": "Migrate Vision Records",
  "MigrateProperty.migrateRolesRecords": "Migrate Roles Records",
  "MigrateProperty.beginMigration": "Begin Migration",

  "PropertySelect.select": "Seleccione Propiedad",

  "PropertySettings.mainTitle": "Configuración de Propiedad",
  "PropertySettings.toast.save": "Se guardó exitosamente la configuración de la propiedad",
  "PropertySettings.toast.pdf": "Generando PDF, esto puede tomar unos instantes",
  "PropertySettings.general.save": "Guardar configuración",
  "PropertyGeneralSettings.displayName": "Nombre para mostrar",
  "PropertyGeneralSettings.logo": "Logotipo de la Propiedad",

  "PropertySettingsTabs.general.title": "Configuración General",
  "PropertyGeneralSettings.details": "Configure las opciones generales de esta propiedad.",
  "PropertyGeneralSettings.multipleBuildings.label": "Edificios Múltiples",
  "PropertyGeneralSettings.multipleBuildings.desc": "¿Esta propiedad tiene múltiples edificios?",
  "PropertySettingsTabs.iddigital.title": "ID Digital",

  "PropertyIdDigitalTab.title": "Configuración de ID Digital",
  "PropertyIdDigitalTab.description": "Configure las opciones de ID Digital para esta propiedad.",
  "PropertyIdDigitalTab.save": "Guardar configuración de ID Digital",
  "PropertyIdDigitalTab.pdf.title": "PDFs",
  "PropertyIdDigitalTab.pdf.details": "Los PDFs aparecerán en la página de ID Digital de esta propiedad.",
  "PropertyIdDigitalTab.pdf.overview": "Generar PDF general",
  "PropertyIdDigitalTab.details": "Detalles",
  "PropertyIdDigitalTab.details.leasing": "Descripción del Arrendamiento",
  "PropertyIdDigitalTab.details.keyFacts": "Datos Clave de la Propiedad",
  "PropertyIdDigitalTab.leasingImages": "Imágenes de Arrendamiento",
  "PropertyIdDigitalTab.leasingImages.upload.title": "Subir Imágenes de Arrendamiento",
  "PropertyIdDigitalTab.leasingImages.upload": "Imagen de Arrendamiento Destacada",
  "PropertyIdDigitalTab.leasingImages.uploadMulti": "Subir imágenes adicionales",
  "PropertyGeneralSettings.contacts": "Contactos",
  "PropertyGeneralSettings.contacts.add": "Agregar Contacto",
  "PropertyGeneralSettings.contacts.save": "Guardar Contacto",
  "PropertyGeneralSettings.contacts.modal.title": "Agregar contacto",
  "PropertyGeneralSettings.contacts.modal.details": "Usuarios de la compañía que puede agregar como contacto",
  "PropertyGeneralSettings.contacts.modal.placeholder": "Buscar Usuarios",
  "PropertyGeneralSettings.removeContact.message": "¿Está seguro de que desea eliminar a este contacto de la publicación de la propiedad?",
  "PropertyGeneralSettings.removeContact.title": "Eliminar contacto",

  "SpaceSelect.select": "Seleccione Unidad",

  "Reports.title": "Sumario de Reportes",

  "Report.title": "Reportes",

  "ReportCard.viewReport": "Ver Reporte",

  "Reports.amp.title": "Administración de Activos",
  "Reports.amp.details": "Vea el reporte de administración de los activos de su propiedad. Este reporte incluye detalles de administración del agua, utilidades y desperdicios.",

  "ResetPassword.label": "Por favor ingrese y confirme su nueva contraseña",
  "ResetPassword.resetPassword": "Reestablecer contraseña",
  "ResetPassword.passwordUpdated": "Su contraseña ha sido actualizada exitosamente.",
  "ResetPassword.login": "Haga click aquí para iniciar sesión",
  "ResetPassword.redirect": "O espere y será redirigido.",

  "Settings.header": "Configuración",
  "Settings.mainTitle": "Perfil Personal",
  "Settings.description": "Edite su perfil personal para cambiar su contraseña, dirección de correo electrónico de contacto, foto de perfil e idioma preferido.",
  "Settings.uploadPhoto": "Subir foto",
  "Settings.removePhoto": "Eliminar foto",
  "Settings.firstName": "Nombre",
  "Settings.lastName": "Apellido",
  "Settings.title": "Cargo",
  "Settings.email": "Email",
  "Settings.phone": "Teléfono (Opcional)",
  "Settings.error.updates": "Hubo un error al guardar los cambios",
  "Settings.saveSuccessful": "Cambios guardados exitosamente",
  "Settings.profileImageUploaded": "Foto de perfil actualizada",
  "Settings.profileImageDeleted": "Foto de perfil eliminada exitosamente",
  "Settings.profileImageDeleted.error": "Hubo un error al eliminar la imagen",
  "Settings.Save": "Guardar configuración",
  "Settings.Tab.Title": "Configuración de {title}",
  "Settings.productTitle": "Configuración del producto",
  "Settings.disableQc.title": "Desactivar control de calidad",
  "Settings.disableQc.description": "Ya no tendría acceso a la funcionalidad de control de calidad en RPM",

  "Team.title": "Invitar a miembros del equipo",
  "Team.selectCompany": "Seleccione una compañía.",
  "Team.selectCompany.details": "Su cuenta tiene acceso administrativo a más de una compañía. La compañía con la que está trabajando ahora es {displayCompany}.",
  "Team.newMembers": "Nuevos miembros",
  "Team.newMembers.details": "Los nuevos miembros pueden administrar propiedades o actuar como administradores. Puede especificar los permisos luego de hacer click en \"Invitar nuevo miembro\".",
  "Team.invites.title": "Invitaciones pendientes",
  "Team.invites.resend": "Reenviar invitación (la última se envió con fecha: {sentOn})",
  "Team.invites.cancel": "Cancelar invitación",
  "Team.invites.invite": "Invitar nuevo miembro",
  "Team.currentMembers": "Miembros actuales",
  "Team.filterMembers": "Filtrar miembros",
  "Team.filterMembers.placeholder": "Ingrese texto para filtrar por nombre o email",

  "UserCompaniesSelect.placeholder": "Seleccione la compañía",

  "UsersList.name": "Nombre",
  "UsersList.email": "Email",
  "UsersList.status": "Estado",
  "UsersList.created": "Creado el",
  "UsersList.edit": "Editar",
  "UsersList.username": "Nombre de usuario",

  "UsersPropertyRolesList.propertyName": "Nombre de propiedad",
  "UsersPropertyRolesList.viewer": "Lector",
  "UsersPropertyRolesList.editor": "Editor",
  "UsersPropertyRolesList.designer": "Diseñador",
  "UsersPropertyRolesList.admin": "Administrador",
  "UsersPropertyRolesList.viewer.desc": "Los lectores pueden ver las propiedades a las que fueron asignados. No pueden editar  ninguna información de la propiedad.",
  "UsersPropertyRolesList.editor.desc": "Los editores pueden ver las propiedades a las que fueron asignados. También pueden agregar y eliminar activos, así como también hacer cambios a datos de los activos (como por ejemplo, números de medidores).",
  "UsersPropertyRolesList.designer.desc": "Los diseñadores pueden ver y editar las propiedades a las que fueron asignados. Tienen acceso total a agregar, editar y eliminar activos. Los diseñadores también pueden cambiar cambios de diseño en la disposición visual.",

  "userTitles.assetManager": "Gerente de Activos",
  "userTitles.leasingRepresentative": "Representante de Arrendamientos",
  "userTitles.propertyManager": "Gerente de Propiedades",
  "userTitles.billingSpecialist": "Especialista de Facturación",
  "userTitles.renewalAgent": "Agente de Renovación",
  "userTitles.propertyManagerAssistant": "Asistente de Gerencia de Propiedades",
  "userTitles.leasingAgent": "Agente de Arrendamiento",
  "userTitles.constructionManager": "Gerente de Construcción",

  "VendorDashboard.mainTitle": "Tablero de Proveedores",
  "VendorDashboard.company.title": "Seleccione una compañía.",
  "VendorDashboard.company.description": "Su cuenta tiene acceso administrativo a más de una compañía. La compañía con la que está trabajando ahora es {displayCompany}.",
  "VendorDashboard.vendors.title": "Todos los Proveedores",
  "VendorDashboard.filterVendors.placeholder": "Filtrar proveedores por nombre y ubicación",
  "VendorDashboard.invitations.title": "Invitaciones",
  "VendorDashboard.invitations.description": "Aquí se mostrarán las invitaciones en curso. Las invitaciones pueden reenviarse o ser canceladas.",
  "VendorDashboard.activeInvitations": "Invitaciones en curso",
  "VendorDashboard.myVendors": "Mis Proveedores",
  "VendorDashboard.vendorInvitations.resend": "Reenviar",
  "VendorDashboard.vendorInvitations.cancel": "Cancelar",
  "VendorDashboard.deleteInvite.message": "¿Está seguro de que desea cancelar esta invitación?",
  "VendorDashboard.deleteInvite.title": "Cancelar Invitación a Proveedor",
  "VendorDashboard.resendInvite.success": "La invitación ha sido reenviada exitosamente.",
  "VendorDashboard.invitations.expired": "Expiradas",
  "VendorDashboard.invitations.sent": "Enviadas",
  "VendorDashboard.invitations.sentToday": "Enviadas hoy",

  "VendorsPropertyRolesList.propertyName": "Nombre de Propiedad",
  "VendorsPropertyRolesList.viewer": "Lector",
  "VendorsPropertyRolesList.editor": "Editor",
  "VendorsPropertyRolesList.designer": "Diseñador",
  "VendorsPropertyRolesList.sections": "Secciones",
  "VendorsPropertyRolesList.location": "Ubicación",
  "VendorsPropertyRolesList.viewer.desc": "Los lectores pueden ver las propiedades a las que fueron asignados. No pueden editar  ninguna información de la propiedad.",
  "VendorsPropertyRolesList.editor.desc": "Los editores pueden ver las propiedades a las que fueron asignados. También pueden agregar y eliminar activos, así como también hacer cambios a datos de los activos (como por ejemplo, números de medidores).",
  "VendorsPropertyRolesList.designer.desc": "Los diseñadores pueden ver y editar las propiedades a las que fueron asignados. Tienen acceso total a agregar, editar y eliminar activos. Los diseñadores también pueden cambiar cambios de diseño en la disposición visual.",
  "VendorsPropertyRolesList.SectionsSelector.noAccess": 'Sin acceso a secciones',
  "VendorsPropertyRolesList.SectionsSelector.noSections": 'No hay secciones',

  "VendorRegistration.alert.previousSteps": "Se encontraron errores en pasos anteriores:",
  "VendorRegistration.step": "Paso {step}",
  "VendorRegistration.mainTitle": "Registrese para obtener su cuenta de proveedor gratuita.",
  "VendorRegistration.mainDetails": "Gestione su equipo y proyectos de forma más eficiente al registrarse.",
  "VendorRegistration.getStarted.title": "¡Comencemos!",
  "VendorRegistration.getStarted.details": "Cree su nuevo perfil y tablero como proveedor",
  "VendorRegistration.tellUsMore.title": "Cuéntenos más sobre su compañía.",
  "VendorRegistration.tellUsMore.details": "Esta información se utilizará en su perfil de proveedor.",
  "VendorRegistration.businessType.title": "¿A qué tipo de negocio representa usted?",
  "VendorRegistration.businessType.details": "Díganos en qué tipo de negocio trabaja, utilizando las etiquetas de categoría para describir sus servicios.",
  "VendorRegistration.logo.title": "Suba el logo de su compañía.",
  "VendorRegistration.logo.details": "Esto será utilizado en su perfil para exponer su negocio a potenciales clientes.",
  "VendorRegistration.password.title": "¡Ya casi termina!",
  "VendorRegistration.password.details": "Cree una contraseña y publique su perfil.",
  "VendorRegistration.finish.title": "¡Felicitaciones!",
  "VendorRegistration.finish.details": "Su perfil de proveedor está publicado. Revise su correo electrónico para acceder al enlace de verificación de su cuenta.",
  "VendorRegistration.alert.registration": "Se ha producido un error al guardar o publicar su perfil",
  "VendorRegistration.noLongerActive": "Esta invitación ha expirado.",
  "VendorRegistration.toolTip.label.step1": "Sugerencia para su perfil de proveedor",
  "VendorRegistration.toolTip.details.step1": "Indique el sitio web de su compañía para destacarlo en su perfil de proveedor. Los clientes podrán encontrarlo más facilmente mientras más información cargue en su perfil.",
  "VendorRegistration.toolTip.label.step2": "Radio de Viaje",
  "VendorRegistration.toolTip.details.step2": "Indique qué tan lejos está dispuesto a viajar. Esto determina cómo los clientes pueden buscarlo a usted y a sus servicios en el area donde ellos se encuentran.",
  "VendorRegistration.toolTip.label.step3": "Etiquetas de categoría de negocio",
  "VendorRegistration.toolTip.details.step3": "Indique qué tan lejos está dispuesto a viajar. Esto determina cómo los clientes pueden buscarlo a usted y a sus servicios en el area donde ellos se encuentran.",
  "VendorRegistration.toolTip.label.step4": "Etiquetas de categoría de negocio",
  "VendorRegistration.toolTip.details.step4": "Indique qué tan lejos está dispuesto a viajar. Esto determina cómo los clientes pueden buscarlo a usted y a sus servicios en el area donde ellos se encuentran.",
  "VendorRegistration.toolTip.label.step5": "Etiquetas de categoría de negocio",
  "VendorRegistration.toolTip.details.step5": "Indique qué tan lejos está dispuesto a viajar. Esto determina cómo los clientes pueden buscarlo a usted y a sus servicios en el area donde ellos se encuentran.",

  "vendorSchema.fname.label": "Nombre",
  "vendorSchema.lname.label": "Apellido",
  "vendorSchema.phone.label": "Teléfono",
  "vendorSchema.phone.placeholder": "Su número de teléfono",
  "vendorSchema.company.label": "Companía",
  "vendorSchema.company.placeholder": "Ingrese el nombre de la compañía",
  "vendorSchema.companySite.label": "Sitio web de la compañía",
  "vendorSchema.companySite.placeholder": "Ingrese la URL de la compañía",
  "vendorSchema.address1.label": "Dirección de la compañía",
  "vendorSchema.address1.placeholder": "Ingrese dirección",
  "vendorSchema.city.label": "Ciudad",
  "vendorSchema.city.placeholder": "Ingrese ciudad",
  "vendorSchema.postal.label": "Código Postal",
  "vendorSchema.postal.placeholder": "Ingrese código postal",
  "vendorSchema.privacyPolicy.label": "Acepto la Política de Privacidad",
  "vendorSchema.privacyPolicy.error": "Debe aceptar la política de privacidad para continuar.",
  "vendorSchema.state.label": "Estado",
  "vendorSchema.state.placeholder": "Seleccione estado",
  "vendorSchema.travelRadius.label": "Radio de viaje",
  "vendorSchema.travelRadius.placeholder": "Seleccione distancia",
  "vendorSchema.travelRadius.miles": "{count} Millas",
  "vendorSchema.travelRadius.remote": "Servicio Remoto",
  "vendorSchema.businessCategories.label": "Etiquetas de Categoría de Negocio",
  "vendorSchema.businessCategories.placeholder": "Escriba y seleccione múltiples valores...",
  "vendorSchema.businessCategories.plumbing": "Plomería",
  "vendorSchema.businessCategories.hvac": "HVAC",
  "vendorSchema.businessCategories.contractor": "Contratista",
  "vendorSchema.businessCategories.trash": "Residuos",
  "vendorSchema.businessCategories.recycle": "Reciclaje",
  "vendorSchema.businessCategories.landscaping": "Paisajismo",
  "vendorSchema.coi.insuranceStartDate": "Fecha de inicio del certificado",
  "vendorSchema.coi.insuranceEndDate": "Fecha de vencimiento del certificado",

  "VendorSearch.title": "Búsqueda de Proveedores",
  "VendorSearch.addVendor": "+ Agregar Proveedor",
  "VendorSearch.fname.label": "Usuario",
  "VendorSearch.city.label": "Ubicación",
  "VendorSearch.company.label": "Compañía",
  "VendorSearch.actions.label": "Acciones",
  "VendorSearch.actions.invite.label": "Invitar Proveedor",
  "VendorSearch.table.title": "Todos los Proveedores",
  "VendorSearch.table.placeholder": "Filtrar proveedores por nombre o ubicación",

  /************************************************************
   * General Use
   * -----------------------------------
   * General use translations that are used throughout the
   * application in multiple components
   ************************************************************/

  //Error Message
  "error.general": "Se ha producido un error",
  "error.server": "El servidor no está respondiendo",
  "error.spaceProfile.create": "Hubo un error al crear el perfil de espacio",
  "error.deleteFile": "Hubo un error al eliminar el archivo",
  "error.iconNotFound": "El ícono subido {icon} no se encontró. Utilizando ícono predeterminado.",
  "error": "Error",
  "error.required": "{field} es requerido",
  "error.valid": "Ingrese un valor válido para {field}",
  "error.addNewLevel": "Hubo un error al agregar una nueva planta",
  "error.deleteNewLevel": "Hubo un error al eliminar la planta",
  "error.saving": "Hubo un error al guardar",
  "error.serverData": "Hubo un error al leer datos del servidor",
  "error.serverDataTeam": "Usted no tiene acceso a administrar los usuarios de esta compañía",
  "error.serverDataVendor": "Usted no tiene acceso a administrar los proveedores de esta compañía",
  "error.companyAclNotFound": "No se encontró la nómina de permisos de acceso para esta compañía",
  "error.permissionsRoleNotFound": "No se enccontró un rol de permisos válido",
  "error.noAccessReports": "Usted no tiene acceso a ningún reporte.",
  "error.notAuthorized": "No Autorizado",
  "error.requestInvite": "Hubo un error al solicitar la invitación",
  "error.cancelInvite": "Hubo un error al cancelar la invitación",
  "error.sendInvite": "Hubo un error al guardar/enviar la invitación",
  "error.saveVendor": "Hubo un error al guardar/enviar la invitación",
  "error.deleteVendor": "Hubo un error al eliminar el proveedor",
  "error.email.valid": "La dirección de correo electrónico no parece ser válida.",
  "error.saveUser": "Hubo un error al guardar el usuario",
  "error.password.match": "Las contraseñas deben coincidir exactamente.",
  "error.password.length": "La contraseña es demasiado corta (debe ingresar al menos 12 caracteres).",
  "error.firstName.empty": "El campo 'Nombre' no puede estar vacío.",
  "error.lastName.empty": "El campo 'Apellido' no puede estar vacío.",
  "error.phone.invalid": "El número de teléfono no parece válido.",
  "error.privacyPolicy": "Debe aceptar la política de privacidad para continuar.",
  "error.registerMember": "Hubo un error al registrar su información de nuevo miembro",
  "error.sendingRequest": "Hubo un error al enviar la solicitud",
  "error.verifyNotRobot": "Por favor verifique que no es un robot marcando la casilla de reCaptcha a continuación.",
  "error.tooManyLogins": "Hubo demasiados intentos de inicio de sesión inválido. Se requiere un CAPTCHA para continuar.",
  "error.login": "Hubo un error al iniciar sesión",
  "error.resetPassword": "Debe reestablecer su contraseña haciendo click sobre el enlace a continuación para desbloquear su cuenta.",
  "error.validateMfa": "Hubo un error al validar el código MFA",
  "error.chooseMfaOption": "Se debe indicar un método de envío.",
  "error.requestMfa": "Hubo un error al solicitar el código MFA",
  "error.password.matchAndLength": "Las contraseñas deben coincidir y tener al menos 10 caracteres",
  "error.password.update": "Hubo un error al actualizar su contraseña",
  "error.resendInvite": "El usuario no tiene permisos para reenviar invitaciones de parte de {displayCompany}",
  "error.addInvite": "El usuario no tiene permisos para crear nuevas invitaciones de parte de {displayCompany}",
  "error.cancelExistingInvite": "El usuario no tiene permisos para cancelar invitaciones de parte de {displayCompany}",
  "error.listUsers": "El usuario no tiene permisos para listar los usuarios de {displayCompany}",
  "error.listInvites": "El usuario no tiene permisos para listar las invitaciones de {displayCompany}",
  "error.listVendors": "El usuario no tiene permisos para listar proveedores de {displayCompany}",

  //Files
  "file.floorplan": "Planta",
  "file.featured": "Imagen Destacada",
  "file.autocad": "AUTOCAD",
  "file.pdf": "LOD PDF",
  "file.profile_pdf": "PDF de Perfil",

  //Platform Related Language
  "platform.roles.admin": "Administrador",

  // Business or general use terms that are often repeated as content for button
  "term.company": "Compañía",
  "term.sourceCompany": "Source Company",
  "term.destinationCompany": "Destination Company",
  "term.dueDate": "Fecha Límite",
  "term.orderDate": "Fecha de Solicitud",
  "term.property": "Propiedad",
  "term.sourceProperty": "Source Property",
  "term.properties": "Propiedades",
  "term.unavailable": "No Disponible",
  "term.available": "Disponible",
  "term.visible": "Visible",
  "term.required": "Requerido",
  "term.enabled": "Activado",
  "term.unit": "Unidad",
  "term.unit_no": "Unidad #",
  "term.address": "Dirección",
  "term.unknown": "Desconocido",
  "term.search": "Buscar",
  "term.assetType": "Tipo de Activo",
  "term.floorplan": "Planta",
  "term.asset": "Activo",
  "term.published": "publicado",
  "term.unpublished": "sin publicar",
  "term.draft": "borrador",
  "term.other": "Otro",
  "term.scheduledDate": "Programado",
  "term.completedDate": "Completado",
  "term.lastDate": "Hace {period} {interval}",
  "term.nextDate": "En {period} {interval}",
  "term.days": "Días",
  "term.anyDate": "Cualquier Fecha",
  "term.leaseExpirationDate": "Vencimiento del Arrendamiento",
  "term.success": "Success",

  //UI elements (common UI buttons, interactions)
  "ui.coiChangeFile": "Cambiar archivo",
  "ui.notAuthorized": "No Autorizado",
  "ui.noOptions": "Sin Opciones",
  "ui.inactive": "Inactivo",
  "ui.active": "Activo",
  "ui.disabled": "Desactivado",
  "ui.username": "Usuario",
  "ui.password": "Contraseña",
  "ui.login": "Iniciar sesión",
  "ui.login.samlSso": "Iniciar sesión usando SAML SSO",
  "ui.login.password": "Iniciar sesión usando contraseña",
  "ui.login.samlSsoButton": "Iniciar sesión con SSO",
  "ui.lostPassword": "¿Olvidó su contraseña?",
  "ui.confirmPassword": "Confirmar Contraseña",
  "ui.skip": "Omitir",
  "ui.publish": "Publicar",
  "ui.server": "Error de Servidor",

  // Measurement Units
  "unit.squareFootage": "ft²",
  "unit.squareMeters": "m²",
  "unit.feet": "pies",
  "unit.inches": "pulgadas",
  "unit.squareFootageVariant": "FT2",

  /************************************************************
   * Monorepo Components
   * -----------------------------------
   * Translations need to be passed in props as these components
   * don't have access to the intl provider
   ************************************************************/

  //AdminInput Component
  "AdminInput.validationMessage": "Ingrese un valor válido para ''{label}''",

  //Contact Component
  "Contact.phone": "T",
  "Contact.email": "E",
  "Contact.propertyContact": "Contacto de la Propiedad",
  "Contact.assetManager": "Gerente de Activos",
  "Contact.leasingRepresentative": "Representante de Arrendamientos",
  "Contact.propertyManager": "Gerente de la Propiedad",
  "Contact.billingSpecialist": "Especialista en Facturación",
  "Contact.renewalAgent": "Agente de Renovación",
  "Contact.propertyManagerAssistant": "Asistente del Gerente de la Propiedad",
  "Contact.leasingAgent": "Agente de Arrendamiento",
  "Contact.constructionManager": "Gerente de Construcción",

  //CompanySelectComponent
  "CompanySelect.placeholder": "Seleccione...",
  "CompanySelect.loadingMessage": "Cargando...",
  "CompanySelect.noOptions": "Sin opciones",

  //Common Components
  "MapView.noResults": "Sin resultados",

  // Lease Radar Details
  "LeaseRadarDetails.popup.leaseExpiring": "Alquileres por Vencer",
  "LeaseRadarDetails.tenantCard.lease": "Arrendamiento",
  "LeaseRadarDetails.tenantCard.vacate": "Desocupar",
  "LeaseRadarDetails.tenantCard.categoryUnavailable": "Categoría no disponible",
  "LeaseRadarDetails.tenantCard.available": "DISPONIBLE",
}
