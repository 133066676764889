import React from 'react';
import PropTypes from 'prop-types';
import SlideContainer from './SlideContainer';
import styles from './PhotoSlide.module.css';

const PhotoSlide = ({ url, contain, onClick }) => (
  <SlideContainer
    className={styles.PhotoSlide}
    onClick={onClick}
  >
    <img
      style={{objectFit: contain ? 'contain' : 'cover'}}
      src={url}
      alt={url}
    />
  </SlideContainer>
);

PhotoSlide.propTypes = {
  /** URL of an image. */
  url: PropTypes.string.isRequired,
  /** Whether the image has a `contain` or `cover` CSS fit. */
  contain: PropTypes.bool
};

PhotoSlide.defaultProps = {
  contain: true
};

export default PhotoSlide;