import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'
import makeAnimated from 'react-select/animated'
import Option from './CheckboxOption'
import MultiValue from './CustomMultiValue'

/**
 * Main Component.
 *
 * This component renders a custom dropdown/select component
 * that relies on `react-select` to handle multiple selectable
 * checkboxes.
 * Check `react-select` for all the available props.
 *
 * @example
 * ```jsx
 * const [selectedOptions, setSelectedOptions] = useState([])
 * // An option should be an object with `value` (unique) and `label` keys.
 * const options = [
 *   { value: 1, label: '#1' },
 *   { value: 2, label: '#2' },
 *   { value: 3, label: '#3' },
 *   { value: 4, label: '#4' }
 * ]
 *
 * <MultiSelectDropdown
 *   onChange={setSelectedOptions}
 *   options={options}
 *   value={selectedOptions}
 * />
 * ```
 *
 * @see https://github.com/JedWatson/react-select/tree/react-select%405.4.0
 */
const MultiSelectDropdown = ({
  allowSelectAll,
  allOption,
  closeMenuOnSelect,
  hideSelectedOptions,
  onChange,
  options,
  value,
  ...otherProps
}) => {
  const animatedComponents = makeAnimated()

  /**
   * Standard callback for the `react-select` onChange
   * event, but takes care of the "select all" behavior.
   * If `allowSelectAll` is enabled and is selected by the user,
   * we call `onChange` with the entire `options` array.
   * Otherwise, we pass the selected options as expected.
   * @param {{value: any, label: any}[]} selected
   * @returns {void}
   */
  const handleReactSelectOnChange = (selected) => {
    if (
      allowSelectAll &&
      Array.isArray(selected) &&
      selected.some(selectedOption => selectedOption.value === allOption.value)
    ) {
      return onChange(options)
    }

    onChange(selected)
  }

  /**
   * Prepends the "Select all" option to the array
   * of selectable options if `allowSelectAll` is enabled.
   */
  const normalizedOptions = useMemo(() => (
    [
      allowSelectAll ? allOption : null,
      ...options
    ].filter(Boolean)
  ), [allowSelectAll, allOption, options])

  /**
   * Style overrides to make the `react-select` component look like
   * our `AdminInput` component.
   */
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      borderRadius: '.25rem',
      color: '#495057',
      borderColor: state.isFocused ? '#80bdff' : '#e5e5e5',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'none',
      fontSize: '14px',
      outline: state.isFocused ? 0 : 'initial',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#80bdff' : '#e5e5e5'
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '14px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: '14px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '8px'
    })
  }

  return (
    <ReactSelect
      isMulti={true}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      components={{ Option, MultiValue, animatedComponents }}
      onChange={handleReactSelectOnChange}
      options={normalizedOptions}
      styles={customStyles}
      value={value}
      {...otherProps}
    />
  )
}

MultiSelectDropdown.propTypes = {
  /**
   * Will display a "Select all" option on top - Clicking it will
   * select everything.
   */
  allowSelectAll: PropTypes.bool,
  /** Customizable "Select all" option. */
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }),
  /** Inherited from `react-select` */
  closeMenuOnSelect: PropTypes.bool,
  /** Inherited from `react-select` */
  hideSelectedOptions: PropTypes.bool,
  /** Inherited from `react-select` */
  onChange: PropTypes.func,
  /** Inherited from `react-select` */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })),
  /** Inherited from `react-select` */
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }))
}

MultiSelectDropdown.defaultProps = {
  allowSelectAll: false,
  allOption: {
    label: 'Select all',
    value: '*'
  },
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  onChange: () => { },
  options: [],
  value: []
}

export default MultiSelectDropdown
