import React from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getCookieOptions } from "./../../utils/utils";

const Logout = () => {

  const cookies = new Cookies();
  const query = new URLSearchParams(useLocation().search);
  const app = query.get("app");
  let path = query.get("path");

  let copts = getCookieOptions();
  cookies.remove('auth', { path: copts.path, domain: copts.domain });
  cookies.remove('logged-in', { path: copts.path, domain: copts.domain });

  const querys = [];

  if (app) querys.push(`app=${app}`);
  if (path) querys.push(`path=${path}`);

  window.location.href = `/login${querys.length > 0 ? '?' : ''}${querys.join('&')}`;
  return (
    <div></div>
  );

};

export default Logout;