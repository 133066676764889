import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './BtnLight.module.css';

function BtnLight({ title, onClick, className }) {
  return (
    <Button variant="light" className={`${styles.btnLight} ${className}`} onClick={onClick}>
      {title}
    </Button>
  )
}

export default BtnLight;