import React from 'react';

const NotificationsBold = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.7315 0C7.21499 0 7.607 0.391975 7.607 0.8755V2.6265L7.60657 2.65425L7.60572 2.67326L7.60401 2.69864C10.0899 3.11424 11.9845 5.27562 11.9845 7.8795C11.9845 10.5877 12.5317 11.6546 13.3841 13.3166L13.4145 13.376C13.563 13.6655 13.3527 14.008 13.0272 14.008H0.435851C0.110317 14.008 -0.100008 13.6655 0.048545 13.376L0.0788969 13.3166C0.931313 11.6546 1.4785 10.5877 1.4785 7.8795C1.4785 5.27562 3.37314 3.11424 5.85899 2.69864L5.85707 2.664C5.85643 2.65157 5.856 2.63906 5.856 2.6265V0.8755C5.856 0.391975 6.24801 0 6.7315 0Z" fill="currentColor" />
      <path d="M8.4825 15.759C8.4825 16.726 7.69848 17.51 6.7315 17.51C5.76452 17.51 4.9805 16.726 4.9805 15.759H8.4825Z" fill="currentColor" />
    </svg>
  );
}

export default NotificationsBold;