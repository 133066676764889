import React from 'react';

const Cancel = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.8485 5.00034L9.6243 1.22455C9.8589 0.989945 9.8589 0.610745 9.6243 0.376145C9.3897 0.141545 9.0105 0.141545 8.7759 0.376145L5.0001 4.15194L1.2243 0.376145C0.989701 0.141545 0.610501 0.141545 0.375901 0.376145C0.141301 0.610745 0.141301 0.989945 0.375901 1.22455L4.1517 5.00034L0.375901 8.77614C0.141301 9.01075 0.141301 9.38994 0.375901 9.62454C0.492901 9.74154 0.646501 9.80034 0.800101 9.80034C0.953701 9.80034 1.1073 9.74154 1.2243 9.62454L5.0001 5.84875L8.7759 9.62454C8.8929 9.74154 9.0465 9.80034 9.2001 9.80034C9.3537 9.80034 9.5073 9.74154 9.6243 9.62454C9.8589 9.38994 9.8589 9.01075 9.6243 8.77614L5.8485 5.00034Z" fill="currentColor" />
    </svg>
  );
}

export default Cancel;