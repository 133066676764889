import React, { useMemo } from 'react'
import { IoDocumentTextSharp } from 'react-icons/io5'
import { RiDeleteBin6Fill, RiEditFill, RiEyeFill, RiFileFill } from 'react-icons/ri'
import { INSPECT_FILTER_TYPE, INSPECT_TYPE, INSPECTION_STATUS } from '../../../utils/constants'
import ViewInspectionButton from '../ViewInspectionButton/ViewInspectionButton'

/**
   * This is container for props that will be passed to the Table component
   * to handle fields, filters, sizing, etc when property selected
   * @param {(item: string, inspectFilter: string) => string} getIdInspectUrl returns view inspect url for external location
   * @param {(s: string) => string} t translator
   * @param {(id: string) => string} viewItem view inspection or work-order
   * @param {(show: bool) => void} setShowDeleteItemModal shows delete item modal
   * @param {(guid: string) => void} setDeleteItem sets guid of item to delete
   * @param {boolean} isAdmin is user admin
   * @param {{guid: string, slug: string}} currentCompany currently selected company
   * @param {string} type inspection or work-order
   * @param {(id: string) => string} editInspection view item in edit mode
   * @param {(item: any) => string} setEditSharedUsersInspection sets item to edit shared users on
   * @param {(show: bool) => void} setShowEditSharedUsersModal shows shared users edit modal
   * @param {string} inspectFilter filter type (assigned, all, etc.)
   * @returns {any} Returns actions list for table
   */
const useTableActionsList = ({
  getIdInspectUrl,
  t,
  viewItem,
  setShowDeleteItemModal,
  setDeleteItemId,
  isAdmin,
  currentCompany,
  type,
  editInspection,
  setEditSharedUsersInspection,
  setShowEditSharedUsersModal,
  inspectFilter,
  setShowManageCOIModal
}) => {
  //This is the action list for when the table is placed outside idinspect. It's
  //simply a 'view' link
  const externalProps = useMemo(() => ({
    id: 'actionsList',
    buttonLabel: t('ui.view'),
    type: 'button',
    click: () => null,
    options: {
      buttonType: 'underline',
      link: (item) => getIdInspectUrl(item, inspectFilter)
    }
  }), [getIdInspectUrl, inspectFilter, t])

  const labels = {
    'view': {
      [INSPECT_TYPE.INSPECTION]: t('Inspections.table.all.viewReport'),
      [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.table.all.viewWorkOrder')
    },
    'delete': {
      [INSPECT_TYPE.INSPECTION]: t('Inspections.table.all.deleteInspection'),
      [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.table.all.deleteWorkOrder')
    },
    'watch': {
      [INSPECT_TYPE.INSPECTION]: t('Inspections.table.all.watch'),
      [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.table.all.watch')
    }
  }

  //Edit Inspection and edit shared users for inspection. These are only shown
  //on the list for inspections
  const inspectionActions = useMemo(() => [
    {
      label: t('Inspections.table.all.editInspection'),
      click: (item) => editInspection(item.id),
      icon: <RiEditFill />,
      // This `Edit` button should not be displayed if approved or complete
      condition: (item) => (
        item.status !== INSPECTION_STATUS.APPROVED &&
        item.status !== INSPECTION_STATUS.COMPLETE &&
        item.status !== INSPECTION_STATUS.CANCELED
      )
    }
  ], [editInspection, t])

  const inspectionByTaskActions = useMemo(() => ({
    id: 'actionsList',
    type: 'sideMenu',
    menuOptions: [
      {
        label: labels['view'][type],
        click: (item) => viewItem(item.id),
        icon: <IoDocumentTextSharp />
      },
      {
        label: t('Inspections.table.all.editInspection'),
        click: (item) => editInspection(item.id),
        icon: <RiEditFill />,
        // This `Edit` button should not be displayed if approved or complete
        condition: (item) => (
          item.status !== INSPECTION_STATUS.APPROVED &&
          item.status !== INSPECTION_STATUS.COMPLETE &&
          item.status !== INSPECTION_STATUS.CANCELED
        )
      }
    ]
  }), [editInspection, labels, t, type, viewItem])

  //The list of items for the actions list on the table.

  const allTableProps = useMemo(() => ({
    id: 'actionsList',
    type: 'sideMenu',
    menuOptions: [
      {
        label: labels['view'][type],
        click: (item) => viewItem(item.id),
        icon: <IoDocumentTextSharp />
      },
      {
        label: labels['delete'][type],
        click: (item) => { setShowDeleteItemModal(true); setDeleteItemId(item) },
        icon: <RiDeleteBin6Fill />
      },
      ...(type === INSPECT_TYPE.INSPECTION ? inspectionActions : []),
      {
        label: labels['watch'][type],
        click: (item) => { setShowEditSharedUsersModal(true); setEditSharedUsersInspection(item); },
        icon: <RiEyeFill />,
        condition: (item) => (item.status !== INSPECTION_STATUS.CANCELED)
      },
      ...(type === INSPECT_TYPE.WORK_ORDER ? [{
        label: t('WorkOrders.table.all.manageCOI'),
        click: (item) => setShowManageCOIModal(item),
        icon: <RiFileFill />,
      }] : [])
    ]
    /* eslint-disable react-hooks/exhaustive-deps */
  }), [inspectionActions, setEditSharedUsersInspection, setShowEditSharedUsersModal, labels, setDeleteItemId, setShowDeleteItemModal, type, viewItem])


  const assignedTableProps = { id: 'actionsList', type: 'button', renderCustomButton: (item) => <ViewInspectionButton itemId={item.id} currentCompany={currentCompany} status={item.status} type={type} /> }
  const completedTableProps = { id: 'actionsList', type: 'button', renderCustomButton: (item) => <ViewInspectionButton itemId={item.id} isAdmin={isAdmin} currentCompany={currentCompany} status={item.status} type={type} /> }

  return [{
    external: externalProps,
    byTask: inspectionByTaskActions,
    [INSPECT_FILTER_TYPE.ALL]: allTableProps,
    [INSPECT_FILTER_TYPE.ASSIGNED]: assignedTableProps,
    [INSPECT_FILTER_TYPE.COMPLETED]: completedTableProps
  }]
}

export default useTableActionsList