import { useEffect, useState } from 'react';
import axios from '../../utils/axios';

const useUsers = (company) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      const result = await axios.get(`/users/?company=${encodeURIComponent(company)}`);
      if (result.status === 200) {
        const users = result.data.data.sort((a, b) => (a.lname > b.lname) ? 1 : -1).filter(_ => _.status === 'active');
        setUsers(users);
      }
    };
    if (company) fetchUsers();
  }, [company]);

  return users;
};

const useContacts = (propertySettings, users, selectedUser, deleteContact, isContactSaving, confirmDelete, setSelectedUser, setDeleteContact, setIsContactSaving, setActiveUserModal) => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    async function fetchContacts() {
      const contactUsernames = propertySettings.property_contacts.map(_ => _.username);
      setContacts(users.filter(_ => contactUsernames.includes(_.username)));
    };
    if (users && propertySettings.property_contacts) fetchContacts();
  }, [propertySettings, users]);

  useEffect(() => {
    async function deletePropertyContact() {
      let propertyContacts = contacts.filter(_ => _.username !== deleteContact.username);
      setContacts(propertyContacts);
      propertySettings.property_contacts = propertyContacts.map(_ => { return { username: _.username } });

      try {
        await axios.put(`/properties/${propertySettings.name}/${propertySettings.company}`, {
          ...propertySettings,
          property_contacts: propertyContacts.map(_ => { return { username: _.username } })
        });
      } catch (e) {
        //Rollback property contacts if the request is not successful
        let prevContacts = propertySettings.property_contacts.push({ username: deleteContact.username });
        setContacts(prevContacts);
        propertySettings.property_contacts = prevContacts;
      }

      setDeleteContact(null);
    };
    if (deleteContact && confirmDelete) deletePropertyContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteContact, propertySettings, confirmDelete]);

  useEffect(() => {
    async function saveContact() {
      let propertyContacts = [...propertySettings.property_contacts, { username: selectedUser.username }];

      try {
        const result = await axios.put(`/properties/${propertySettings.name}/${propertySettings.company}`, {
          ...propertySettings,
          property_contacts: propertyContacts
        });
        if (result.status === 200) {
          propertySettings.property_contacts = propertyContacts;
          const contactUsernames = propertySettings.property_contacts.map(_ => _.username);
          setContacts(users.filter(_ => contactUsernames.includes(_.username)));
          setSelectedUser(null);
          setActiveUserModal(false)
        }
      } catch (e) { }
      setIsContactSaving(false);
    }
    if (isContactSaving && selectedUser) saveContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, isContactSaving, setIsContactSaving]);

  return contacts;
}

export { useUsers, useContacts };