import React, {
  useCallback,
  useMemo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

/**
 * This component is in charge of rendering the footer in the file uploader
 * modal. This footer includes:
 * - Alerts for errors during uploads
 * - A file selector button
 * - A close button
 *
 * This component uses the `react-intl` hook to translate button labels,
 * so an Intl context is required in the app.
 */
const Footer = ({
  errors,
  isUploading,
  onClose,
  onFileSelected,
  readOnly,
  hideClose,
  showSubmitButton,
  canSubmit,
  onSubmit,
  fileFormats = []
}) => {
  const intl = useIntl();
  const t = useCallback((id) => intl.formatMessage({ id }), [intl]);
  // This is used to trigger the native file selector from an input[type=file]
  // from the "upload" button.
  const fileInputRef = useRef(null);
  // The label can mutate based on the `isUploading` prop
  const uploadButtonLabel = useMemo(() => (
    isUploading ? t('ui.uploading') : t('ui.uploadAFile')
  ), [isUploading, t]);

  const handleUploadButtonClick = () => {
    if (isUploading) {
      return;
    }

    // The custom, styled button triggers the file selector
    // from an input[type=file]
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    // Grab the selected file and pass it to the external prop
    onFileSelected(event.target.files[0]);
  };

  const UploadButton = () => (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept={fileFormats.join(',')}
        name="file"
        onChange={handleFileInputChange}
        className='d-none'
        data-testid="FileHubInput"
      />
      <Button
        variant="outline-primary"
        className="btn-block"
        disabled={isUploading}
        onClick={handleUploadButtonClick}
      >{uploadButtonLabel}</Button>
    </>
  );

  const CloseButton = () => (
    <Button
      variant="light"
      className="btn-block"
      onClick={onClose}
    >{t('ui.close')}</Button>
  );

  const SubmitButton = () => (
    <Button
      variant="primary"
      className="btn-block"
      disabled={!canSubmit}
      onClick={onSubmit}
    >
      {t('ui.submit')}
    </Button>
  )

  return (
    <section className="container-fluid">
      {errors && (
        <div className="row">
          <div className="col-12 p-1">
            <Alert variant="danger">{t('error')}: {errors}</Alert>
          </div>
        </div>
      )}
      <div className="row justify-content-between">
        <div className="col-12 col-md-2 p-1 d-none d-md-block"></div>
        <div className="col-12 col-md-5">
          {!readOnly && <UploadButton />}
        </div>
        {!hideClose && <div className="col-12 col-md-2">
          <CloseButton />
        </div>}
        <div className="col-12 p-1 d-block d-md-none"></div>
        {showSubmitButton && <div className="col-12 col-md-5">
          <SubmitButton />
        </div>}
      </div>
    </section>
  );
};

Footer.propTypes = {
  /** String with errors regarding the "upload" process */
  errors: PropTypes.string,
  /** Flag to toggle the "uploading" state */
  isUploading: PropTypes.bool,
  /** Called when the close button gets clicked */
  onClose: PropTypes.func,
  /**
   * Called when the upload button gets clicked and the user selects a file.
   * It receives two arguments:
   * - An instance of FileListFile: this object can be appended to the array of
   *    files passed via the `files` prop. It also stores important file info that
   *    might want to store in the backend.
   * - The raw file selected by the user: this is the raw data to be uploaded to
   *    an external service like S3.
   */
  onFileSelected: PropTypes.func,
  /**
   * Controls the read-only state of the component. If `true`, it will
   * show static data and disable uploads or deletions.
   */
  readOnly: PropTypes.bool
};

Footer.defaultProps = {
  errors: null,
  isUploading: false,
  onClose: () => { },
  onFileSelected: () => { },
  readOnly: false
};

export default Footer;