import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropertySelectAll from './../PropertySelectAll/PropertySelectAll.js';
import UserCompaniesSelect from './../UserCompaniesSelect/UserCompaniesSelect.js';
import { RootContext } from './../../hoc/RootContext/RootContext';
import styles from './DashboardFilters.module.css';
import { get } from '../../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { CancelToken } from '../../utils/axios';
import { Spinner } from 'react-bootstrap';

const classNames = require('classnames');

const DashboardFilters = ({ setIsLoading, setDashboardData, show, companySelectAll, propertySelectAll, defaultCompany, defaultProperty, hidePropertySelect }) => {
  const { currentUser, setCurrentCompany, mobileView, currentCompany, currentProperty, setCurrentProperty } = useContext(RootContext);

  const [queryLoaded, setQueryLoaded] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [, setErrors] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const currentCompanyValue = get(currentCompany, 'value', '');
  const currentCompanySlug = get(currentCompany, 'slug', '');
  const currentPropertyValue = get(currentProperty, 'name', '');

  const query = new URLSearchParams(useLocation().search);
  const queryCompany = query.get("company");
  const queryProperty = query.get("property");

  const handleCompanySelection = useCallback(
    (selectedCompany) => {
      if (selectedCompany.slug !== currentCompany.slug) {
        setCurrentCompany(selectedCompany);
      }
    },
    [currentCompany, setCurrentCompany]
  );

  useEffect(() => {
    const source = CancelToken.source()
    let isMounted = true;

    async function fetchProperty() {
      setErrors([]);
      setQueryLoading(true);
      try {
        const promises = [
          axios.get(
            `/properties/${queryCompany}/${queryProperty}?slugged=true`,
            { cancelToken: source.token }
          )
        ];

        if (!currentCompany) {
          promises.push(
            axios.get(
              `/companies/${queryCompany || get(currentUser, 'company')}${queryCompany ? `?slugged=true` : ''}`,
              { cancelToken: source.token }
            )
          );
        }

        const [propertyResult, companyResult] = await Promise.all(promises);

        if (propertyResult.status === 200) {
          const data = propertyResult.data.record;
          if (currentCompany && (currentCompany.slug !== data.companySlug)) {
            setCurrentProperty(null);
            return;
          }

          setCurrentProperty(data);
        } else {
          setErrors([`There was an error: ${propertyResult.errors}`]);
        }

        if (companyResult && companyResult.status === 200) {
          const data = companyResult.data.record;
          setCurrentCompany({ value: data.name, slug: data.slug, guid: data.guid, displaySlug: data.displaySlug, displayName: data.displayName })
        } else {
          setErrors([`There was an error: ${propertyResult.errors}`]);
        }
      } catch (e) {
      }
      if (isMounted) {
        setQueryLoading(false);
        setQueryLoaded(true);
      }
    };

    async function fetchCompany() {
      setErrors([]);
      setQueryLoading(true);
      try {
        const result = await axios.get(
          `/companies/${queryCompany || get(currentUser, 'company')}${queryCompany ? `?slugged=true` : ''}`,
          { cancelToken: source.token }
        );

        if (result.status === 200) {
          const data = result.data.record;
          setCurrentCompany({ value: data.name, slug: data.slug, guid: data.guid, displaySlug: data.displaySlug, displayName: data.displayName })
          setCurrentProperty(null);
        } else {
          setErrors([`There was an error: ${result.errors}`]);
        }
      } catch (e) {
      }
      if (isMounted) {
        setQueryLoading(false);
        setQueryLoaded(true);
      }
    };

    if (queryCompany && queryProperty) {
      fetchProperty();
    } else if (!currentCompany) {
      fetchCompany();
    } else {
      setQueryLoaded(true);
    }

    return () => {
      source.cancel()
      isMounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!queryLoading && queryLoaded && !isMenuLoading) {
      const queryParams = paramsToObject(query.entries());
      delete queryParams.company;
      delete queryParams.property;

      let prevQuery = '';
      Object.keys(queryParams).forEach(_ => prevQuery += `&${_}=${queryParams[_]}`)

      navigate(`${location.pathname}${currentCompany && currentCompany.slug ? `?company=${currentCompany.slug}` : ''}${currentProperty && currentProperty.slug ? `&property=${currentProperty.slug}` : ''}${prevQuery}`, {replace: true});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyValue, currentCompanyValue, currentCompanySlug, isMenuLoading, navigate])

  function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }

  return queryLoaded && !queryLoading ?
    <div className={classNames("d-flex", !mobileView ? 'flex-row' : 'flex-column', styles.dashboardFilters)}>
      <UserCompaniesSelect
        label="Company"
        selectedValue={get(currentCompany, 'value') || defaultCompany || currentUser.company}
        setSelectedCompany={handleCompanySelection}
        setIsLoading={setIsLoading}
        show={show}
        selectAll={false}
      />
      <PropertySelectAll
        isLoading={isMenuLoading}
        setIsLoading={setIsMenuLoading}
        callback={setDashboardData}
        show={!hidePropertySelect && show}
        selectAll={propertySelectAll}
        defaultProperty={queryProperty || (currentProperty && currentProperty.slug) || defaultProperty}
        lazyLoad={true}
      />
    </div> : <div className={`d-flex w-100 my-3 justify-content-center`}><Spinner animation="grow" variant="primary" /></div>;

}

export default DashboardFilters;
