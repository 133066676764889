import React from 'react';
import { Table } from "react-bootstrap";
import styles from './ContactsList.module.css';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

function ContactsList({ contacts, selectedValue, deletable, onClick }) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  let buttonVariant = 'primary';
  let buttonContent = t('ContactsList.select');

  if (deletable) {
    buttonVariant = 'danger';
    buttonContent = t('ContactsList.remove');
  }

  return (
    <Table
      className={`table table-hover table-striped bg-white ${styles.contactsListTable}`}
      responsive="sm">
      <thead>
        <tr className="border-bottom">
          <th>{t('ContactsList.name')}</th>
          <th className={styles.mediumTable}>{t('ContactsList.title')}</th>
          {deletable &&
            <>
              <th className={styles.mobileTable}>{t('ContactsList.phone')}</th>
              <th className={styles.mobileTable}>{t('ContactsList.email')}</th>
            </>}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((_, index) => {
          return (
            <tr key={index}>
              <td>{deletable ? <>{_.fname}&nbsp;{_.lname}</> : <>{_.lname},&nbsp;{_.fname}</>}</td>
              <td className={styles.mediumTable}>{_.title}</td>
              {deletable &&
                <>
                  <td className={styles.mobileTable}>{_.phone}</td>
                  <td className={styles.mobileTable}>{_.email}</td>
                </>}
              <td className="text-right">
                <Button onClick={() => onClick(_)} variant={selectedValue && _.username === selectedValue.username ? "success" : buttonVariant}>
                  {selectedValue && _.username === selectedValue.username ? t('ContactsList.selected') : buttonContent}
                </Button>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
}

export default ContactsList;
