import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { CancelToken } from 'axios'
import classNames from 'classnames'
import { Alert, Tab, Tabs } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { AdminToast, ColorPicker } from '@project/components'
import CompanyIdInspectTab from '../../components/CompanyIdInspectTab/CompanyIdInspectTab'
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer'
import SettingsTab from '../../components/SettingsTab/SettingsTab'
import Title from '../../components/Title/Title'
import TitleDescription from '../../components/TitleDescription/TitleDescription'
import LeftColumn from '../../components/TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from '../../components/TwoColumnContent/RightColumn/RightColumn'
import TwoColumnContent from '../../components/TwoColumnContent/TwoColumnContent'
import UserCompaniesSelect from '../../components/UserCompaniesSelect/UserCompaniesSelect'
import { COMPANY_SETTINGS as IDINSPECT_COMPANY_SETTINGS } from '../../constants/inspections'
import { RootContext } from '../../hoc/RootContext/RootContext'
import axios from '../../utils/axios'
import { useCompany } from '../../utils/hooks'
import { get, hasMatchingEnclosingTags, hasBootstrapClass } from '../../utils/utils'
import CompanyGeneralTab from '../CompanyGeneralTab/CompanyGeneralTab'
import CompanyTenantPortalTab from '../CompanyTenantPortalTab/CompanyTenantPortalTab'
import CompanyIdDigitalTab from '../CompanyIdDigitalTab/CompanyIdDigitalTab'
import CompanyRPMTab from '../../components/CompanyRPMTab/CompanyRPMTab'
import styles from './Company.module.css'
const imageExt = '.png, .svg, .jpg, .gif, .jpeg'

const Company = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { currentUser, setCurrentCompany } = useContext(RootContext);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyDisplayName, setCompanyDisplayName] = useState(null)
  const [company, setCompany] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('')
  const [secondaryColor, setSecondaryColor] = useState('')
  const [isPrimaryColorInvalid, setIsPrimaryColorInvalid] = useState(false);
  const [isSecondaryColorInvalid, setIsSecondaryColorInvalid] = useState(false);
  const [primaryOpacity, setPrimaryOpacity] = useState(1);
  const [secondaryOpacity, setSecondaryOpacity] = useState(1);
  const [companySettings, setCompanySettings] = useState(null);
  const [showPickerPrimary, setShowPrimaryPicker] = useState(false);
  const [showPickerSecondary, setShowSecondaryPicker] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [headerFooterCSSErrors, setHeaderFooterCSSErrors] = useState(null);

  // Controls whether to show or hide the ID Inspect settings
  const isIdInspectEnabled = useMemo(() => (
    !!get(companySettings, IDINSPECT_COMPANY_SETTINGS.ENABLED)
  ), [companySettings])

  // fetch current company
  useCompany(companyName, currentUser, setCompany, setErrors)

  useEffect(() => {
    const source = CancelToken.source();
    // returns all other company settings, and inits the settings if necessary
    async function fetchCompanySettings() {
      try {
        setErrors(null);
        const result = await axios.get(`/company-settings?name=${encodeURIComponent(company.name)}`, { cancelToken: source.token });
        if (result.status === 200) {
          const settings = result.data.record;
          setCompanySettings({ ...settings });
          setPrimaryColor(get(settings, 'generalSettings.themeColors.primary.color', ''));
          setSecondaryColor(get(settings, 'generalSettings.themeColors.secondary.color', ''));
          setPrimaryOpacity(get(settings, 'generalSettings.themeColors.primary.opacity', 1));
          setSecondaryOpacity(get(settings, 'generalSettings.themeColors.secondary.opacity', 1));
        } else {
          setErrors(`${t('error.general')}: ${result.errors}`);
        }
      } catch (err) {
      }
    };

    if (company && companySettings?.name !== company.name) {
      fetchCompanySettings();
    }

    return () => {
      source.cancel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    setCompanyName(company?.name || currentUser?.company)
    setCompanyDisplayName(company?.displayName || currentUser?.companyDisplayName)
    // set in root context
    setCurrentCompany(company?.name || currentUser?.company)
  }, [currentUser, company, setCurrentCompany])

  const saveSettings = async () => {
    setErrors(null);
    if (headerFooterCSSErrors) {
      setErrors(t('Company.error.invalidCSS'));
      return;
    }

    if (hasBootstrapClass(get(companySettings, 'idDigitalSettings.headerFooterCss'))) {
      setErrors(t('Company.error.bootstrapClass'));
      setHeaderFooterCSSErrors(false);
      return;
    }

    try {
      await axios.put(`/companies/${company.name}`, {
        requireMfa: company.requireMfa,
        requireSSO: company.requireSSO,
        ssoUserFilter: company.ssoUserFilter,
        contactemail: company.contactemail,
        contacts: company.contacts
      });
    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }

    try {
      companySettings.generalSettings.themeColors = !companySettings.generalSettings.themeColors ? {} : companySettings.generalSettings.themeColors;
      if (!isPrimaryColorInvalid) companySettings.generalSettings.themeColors.primary = { color: primaryColor, opacity: primaryOpacity };
      if (!isSecondaryColorInvalid) companySettings.generalSettings.themeColors.secondary = { color: secondaryColor, opacity: secondaryOpacity };

      delete companySettings.name;

      let result = await axios.put(`/company-settings/${encodeURIComponent(company.name)}`, companySettings);
      if (result.status === 200) {
        setShowSuccess(true);
      } else {
        setErrors(t('error.saving'));
      }
    } catch (e) {
      setErrors(`${t('error.general')}: ${e.errors}`);
    }
  };

  const updateField = ({ target: { type, value, checked } }, base, field) => {
    let fields = field.split('.');
    fields.slice(0, -1).forEach(_ => base = base[_]);
    base[fields.slice(-1)[0]] = value;
    setCompany({ ...company });
  }

  const updateCompanySettings = ({ target: { type, value, checked } }, base, field) => {
    if (!companySettings) return;
    let fields = field.split('.');
    fields.slice(0, -1).forEach(_ => base = base[_]);
    base[fields.slice(-1)[0]] = type === 'checkbox' ? checked : value;
    setCompanySettings({ ...companySettings });
  }

  const setCompanyRequireMfa = (checked) => {
    let tmp = { ...company };
    tmp.requireMfa = checked;
    setCompany(tmp);
  };

  const setCompanyRequireSSO = checked => {
    setCompany((previousCompany) => ({
      ...previousCompany,
      requireSSO: checked
    }));
  };

  const setCompanySSOUserFilter = (value) => {
    setCompany((previousCompany) => ({
      ...previousCompany,
      ssoUserFilter: value
    }));
  };

  const saveHTMLHandler = (e, type) => {
    updateCompanySettings(e, companySettings, `idDigitalSettings.custom${type}HTML`);
  }

  const saveCSSHandler = (e) => {
    const isValidCSS = (css) => {
      let matchingBraces = hasMatchingEnclosingTags(css, '{', '}');

      //Checks for element tag selectors which are disallowed here as they will disrupt the styling of the applications existing stylesheet

      // eslint-disable-next-line no-useless-escape
      let elementTagMatches = css.match(/([\s,]+[A-Za-z0-9\-]+[,\n\s{]+|^[A-Za-z0-9\-]+[,\n\s{]+)(?![^{]*})/gm);

      return matchingBraces && !(elementTagMatches && elementTagMatches.length);
    }

    let css = e.target.value;
    if (!isValidCSS(css)) {
      setHeaderFooterCSSErrors(true);
    } else {
      setHeaderFooterCSSErrors(null);
    }
    updateCompanySettings(e, companySettings, `idDigitalSettings.headerFooterCSS`);
  }

  if (!currentUser || !currentUser.companyAdmin) {
    return <PageContentContainer><h3 style={{ "color": "#990000" }}>{t('ui.notAuthorized')}</h3></PageContentContainer>
  }

  if (!company && errors) {
    return (<Alert key={errors} variant='danger'>
      {Array.isArray(errors) ? errors.map((error, i) => <div key={`error${i}`}>{error.message}</div>) : <>{errors.generic || `${t('error')}: ${errors}`}</>}
    </Alert>);
  }

  if (!company) {
    return null;
  }

  return (
    <PageContentContainer>
      <Title title={t('Company.mainTitle')} />

      <main className={classNames(styles.settings, 'shadow-sm border row mt-4 mx-auto')}>

        <div className="col-xl-12 px-4 pt-4">
          {/* multi home company list */}
          {
            currentUser.companies.length > 0 ?
              <TwoColumnContent>
                <LeftColumn>
                  <TitleDescription
                    title={t('Company.header.title')}
                    description={intl.formatMessage({ id: 'Company.header.description' }, { companyDisplayName })}
                  />
                </LeftColumn>
                <RightColumn padded={false} boxShadow={false} border={false}>
                  <UserCompaniesSelect setErrors={setErrors} selectedValue={currentUser.company} setCurrentCompany={setCompany} show={currentUser.companies && currentUser.companies.length > 1} selectAll={false} />
                </RightColumn>
              </TwoColumnContent>
              : null
          }
          <AdminToast onClose={() => setShowSuccess(false)} show={showSuccess} message={t('Company.companySettings.saved')} style={{ top: '0px', right: '50px' }} />
          {errors ? <Alert key={errors} variant='danger'>
            {Array.isArray(errors) ? errors.map((error, i) => <div key={`error${i}`}>{error.message}</div>) : <>{errors.generic || `${t('error')}: ${errors}`}</>}
          </Alert> : null}
          <Tabs id="company-settings-tabs" className="font-lg">
            <Tab eventKey="general" title={t('Company.tab.generalSettings')}>
              <SettingsTab
                description={t('CompanyGeneralTab.details')}
                onSaveButtonClick={saveSettings}
                title={t('CompanyGeneralTab.generalSettings')}
              >
                <CompanyGeneralTab
                  company={company}
                  companyName={companyName}
                  companyDisplayName={companyDisplayName}
                  updateField={updateField}
                  imageExt={imageExt}
                  companySettings={companySettings}
                  updateCompanySettings={updateCompanySettings}
                  setCompanyRequireMfa={setCompanyRequireMfa}
                  setCompanyRequireSSO={setCompanyRequireSSO}
                  setCompanySSOUserFilter={setCompanySSOUserFilter}
                  setErrors={setErrors}
                />
              </SettingsTab>
            </Tab>
            <Tab eventKey="iddigital" title={t('Company.tab.idDigital')}>
              <SettingsTab
                description={t('CompanyIdDigitalTab.details')}
                onSaveButtonClick={saveSettings}
                title="ID Digital"
              >
                <CompanyIdDigitalTab
                  company={company}
                  imageExt={imageExt}
                  companySettings={companySettings}
                  headerFooterCSSErrors={headerFooterCSSErrors}
                  saveHTMLHandler={saveHTMLHandler}
                  saveCSSHandler={saveCSSHandler}
                  updateCompanySettings={updateCompanySettings}
                />
              </SettingsTab>
            </Tab>
            {isIdInspectEnabled && (
              <Tab eventKey="idinspect" title="ID Inspect">
                <SettingsTab
                  description={t('CompanyIdInspectTab.details')}
                  onSaveButtonClick={saveSettings}
                  title="ID Inspect"
                >
                  <CompanyIdInspectTab
                    companySettings={companySettings}
                    updateCompanySettings={updateCompanySettings}
                  />
                </SettingsTab>
              </Tab>
            )}
            <Tab eventKey="theming" title={t('Company.tab.theming')}>
              <SettingsTab
                description={t('Company.theming.details')}
                onSaveButtonClick={saveSettings}
                title={t('Company.tab.theming')}
              >
                <TwoColumnContent>
                  <LeftColumn>
                    <TitleDescription
                      title={t('Company.theming.colors.title')}
                      description={t('Company.theming.colors.description')}
                      style={{ maxWidth: 'none' }} />
                  </LeftColumn>
                  <RightColumn boxShadow={false} border={false}>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ColorPicker
                          color={primaryColor}
                          setColor={setPrimaryColor}
                          opacity={primaryOpacity}
                          setOpacity={setPrimaryOpacity}
                          label={t('Company.theming.primaryColor.label')}
                          isInvalid={isPrimaryColorInvalid}
                          setIsInvalid={setIsPrimaryColorInvalid}
                          showPicker={showPickerPrimary}
                          setShowPicker={setShowPrimaryPicker} />
                      </div>
                      <div className="col-12 col-md-6">
                        <ColorPicker
                          color={secondaryColor}
                          setColor={setSecondaryColor}
                          opacity={secondaryOpacity}
                          setOpacity={setSecondaryOpacity}
                          label={t('Company.theming.secondaryColor.label')}
                          isInvalid={isSecondaryColorInvalid}
                          setIsInvalid={setIsSecondaryColorInvalid}
                          showPicker={showPickerSecondary}
                          setShowPicker={setShowSecondaryPicker} />
                      </div>
                    </div>
                  </RightColumn>
                </TwoColumnContent>
              </SettingsTab>

            </Tab>

            <Tab eventKey="TenantPortal" title={t('Company.portal.title')}>
              <SettingsTab
                description={t('Company.portal.enable')}
                onSaveButtonClick={saveSettings}
                title={t('Company.portal.title')}>
                <CompanyTenantPortalTab
                  company={company}
                  setErrors={setErrors}
                  setCompany={setCompany}
                />
              </SettingsTab>
            </Tab>
            {currentUser.company === 'ID Plans' && (
              <Tab eventKey="rpm" title={t('Company.tab.rpm')}>
                <SettingsTab
                  description={t('Company.rpm.configure')}
                  onSaveButtonClick={saveSettings}
                  title={t('Company.tab.rpm')}>
                  <CompanyRPMTab
                    companySettings={companySettings}
                    updateCompanySettings={updateCompanySettings}
                  />
                </SettingsTab>
              </Tab>
            )}
          </Tabs>
          <AdminToast onClose={() => setShowSuccess(false)} show={showSuccess} message={t('Company.companySettings.saved')} style={{ top: '0px', right: '50px' }} />
        </div>
      </main>
    </PageContentContainer>
  );
};

export default Company;
