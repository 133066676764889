/**
 * Convert dimensions on the property record into pixels
 * @param {Object} property the property object
 * @param {String} dimension either 'width' or 'height'
 * @returns width or height in pixels
 */
const getDimensionInPixels = (property, dimension) => {
  if (property.measurement_unit === 'inch') {
    // convert inches into px (72 px per inch)
    return +property[dimension] * 72;
  } else if (property.measurement_unit === 'mm') {
    // convert mm into pixels (0.0393701 inches per mm & 72 px per inch)
    return +property[dimension] * 72 * 0.0393701;
  }
}

export { getDimensionInPixels }