import React from 'react'
import classNames from 'classnames'
import { components } from 'react-select'
import Check from '../assets/images/check'
import styles from './CheckboxOption.module.css'

/**
 * This component renders an option for the `MultiSelectDropdown`
 * component. It extends from `react-select.options.Option` and
 * renders a checkbox next to a label.
 */
const CheckboxOption = ({
  label,
  isSelected,
  ...otherProps
}) => (
  <div>
    <components.Option {...otherProps}>
      <div className={styles.Option}>
        <div
          className={classNames(
            styles.Checkbox,
            {
              [styles.Checked]: isSelected,
              [styles.Unchecked]: !isSelected
            }
          )}
        >{isSelected && <Check />}</div>
        <label className={styles.Label}>{label}</label>
      </div>
    </components.Option>
  </div>
)

export default CheckboxOption
