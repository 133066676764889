import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardDisplay.module.css';
import DefaultImage from '../assets/images/unknown-unit.png';
import TagPills from './TagPills/TagPills';
import ActionBox from './ActionBox/ActionBox';

const CardDisplay = ({
  children, 
  title, 
  subtitle, 
  image,
  hideImage, 
  tags = [], 
  editHandler, 
  hideTags, 
  selected, 
  selectHandler, 
  unselectHandler, 
  deleteHandler,
  link
}) => {
  return (
    <div className={`${hideTags ? styles.cardExtended : ''} ${styles.card} d-flex w-100 justify-content-between align-items-start`}>
      <div className='d-flex w-100 justify-content-between'>
        <div className='d-flex'>
          {!hideImage ? 
            <div className={`${styles.image} mr-3`} data-testid="CardImage">
              <img src={image || DefaultImage} alt={title} />
            </div> : null}
          <div className={`d-flex flex-column justify-content-between`}>
            <div>
              <div className={styles.cardName}>{title}</div>
              <div className={styles.cardDescription}>{subtitle}</div>
              <TagPills 
                editHandler={editHandler}
                hideTags={hideTags}
                link={link}
                tags={tags}
              />
            </div>
          </div>
        </div>
        {children}
        <ActionBox
          deleteHandler={deleteHandler}
          selectHandler={selectHandler}
          selected={selected}
          unselectHandler={unselectHandler}
        />
      </div>
    </div>
  )
}

CardDisplay.propTypes = {
  //Displayed title of card
  title: PropTypes.string,
  //Displayed subtitle of card
  subtitle: PropTypes.string,
  //Displayed image (defaults to default image)
  image: PropTypes.string,
  //Hide image and image container
  hideImage: PropTypes.bool,
  //Array of strings that will convert to pills below subtitle
  tags: PropTypes.arrayOf(PropTypes.string),
  //Function run when edit pill is clicked. If not provided no edit pill will be rendered.
  editHandler: PropTypes.func,
  //Hide tags and tags container (used mostly for styling purposes)
  hideTags: PropTypes.bool,
  //Used to determine if checkbox (if being used) is selected
  selected: PropTypes.bool, 
  //This handler is run when the checkbox is clicked while not checked
  selectHandler: PropTypes.func, 
  //This handler is run when the checkbox is clicked while checked
  unselectHandler: PropTypes.func, 
  //This handler is run when the delete icon is clicked
  deleteHandler: PropTypes.func
};

CardDisplay.defaultProps = {
  tags: []
};

export default CardDisplay;