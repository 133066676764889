import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './DashboardCard.module.css';

/**
 * This component renders a Card element. It has defined borders,
 * a title, a other CSS styles by default.
 * Children wrapped by this component will be rendered on the main
 * body of the card, right below the title. The className can be
 * cutomized as well as the entire header, replacing the default
 * title.
 * @example
 * ```jsx
 * // Basic example
 * <DashboardCard
 *   className={something}
 *   title="Nice Title"
 * >
 *   <p>Contents inside</p>
 * </DashboardCard>
 *
 * // Custom header
 * <DashboardCard
 *   className={something}
 *   header={
 *     <h1>Awesome Custom Header</h1>
 *   }
 * >
 *   <p>Contents inside</p>
 * </DashboardCard>
 * ```
 */
const DashboardCard = ({
  children,
  className,
  header,
  title
}) => {
  const wrapperClasses = classNames(
    'w-100 mb-3',
    styles.DashboardCard,
    className
  );
  const titleClasses = classNames(
    'align-self-center',
    styles.Title
  );

  const hasHeader = !!header;
  const hasTitle = !!title;

  return (
    <div className={wrapperClasses}>
      {/* Print custom header is there's any */}
      {hasHeader && header}
      {/* If no custom header, but title string, print that */}
      {!hasHeader && hasTitle && (
        <div className="d-flex justify-content-between w-100 pt-3 mb-2 align-items-center">
          <div className={titleClasses}>{title}</div>
        </div>
      )}
      {children}
    </div>
  );
};

DashboardCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.node,
  title: PropTypes.string
};

export default DashboardCard;
