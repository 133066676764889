import React from 'react';

const Alert = () => {
  return (
    <svg width="24" height="22.8" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM8.75 8.7495C8.75 9.43986 9.30964 9.9995 10 9.9995C10.6904 9.9995 11.25 9.43986 11.25 8.7495V6.2495C11.25 5.55914 10.6904 4.9995 10 4.9995C9.30964 4.9995 8.75 5.55914 8.75 6.2495V8.7495ZM8.75 13.7495C8.75 14.4399 9.30964 14.9995 10 14.9995C10.6904 14.9995 11.25 14.4399 11.25 13.7495C11.25 13.0591 10.6904 12.4995 10 12.4995C9.30964 12.4995 8.75 13.0591 8.75 13.7495Z" fill="currentColor" />
    </svg>
  );
}

export default Alert;