import React, { useMemo } from 'react';
import { IoMdDocument } from 'react-icons/io';

import styles from './FileIcon.module.css';

const FileIcon = ({ extension, iconClassName = '', extensionClassName = '', color = '#5371B7', size = 48 }) => {
  const wrapperClassName = useMemo(() => [styles.uploadedFileIcon, iconClassName].join(' '), [iconClassName]);
  const labelClassName = useMemo(() => [styles.uploadedFileExtension, extensionClassName].join(' '), [extensionClassName]);

  return <div className={wrapperClassName}>
    <IoMdDocument fill={color} size={size} />
    <label className={labelClassName}>{extension}</label>
  </div>
}

export default FileIcon;