import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import styles from './Alerts.module.css';

/** Simple multiple alerts/messages using bootstrap */

const Alerts = ({ messages, close, variant, showIcon, dismissible = true }) => {
  if (!Array.isArray(messages)) {
    messages = [messages];
  }

  if (messages && messages.length) {
    return (
      messages.map((msg, index) => {
        return <Alert
          key={index}
          variant={variant ? variant : 'danger'}
          onClose={() => close(msg, index)}
          dismissible={dismissible}>{showIcon ? variant === 'success' ? <AiFillCheckCircle size={23} className={styles.icon} /> : <AiFillWarning size={23} className={styles.icon} /> : null} {msg}</Alert>
      })
    );
  }
  else {
    return null;
  }
};

export default Alerts;

Alerts.propTypes = {
  /** string or Array of messages/errors */
  messages: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,

  /** Function to run when alert is dismissed */
  close: PropTypes.func.isRequired
};