/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleDescription from './../../components/TitleDescription/TitleDescription';
import BtnLight from './../../components/BtnLight/BtnLight';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import { RootContext } from './../../hoc/RootContext/RootContext';
import axios from "../../utils/axios";
import moment from 'moment';
import UserCompaniesSelect from '../../components/UserCompaniesSelect/UserCompaniesSelect';
import { useIntl } from 'react-intl';
import { Alerts, Table, AdminModal } from "@project/components";
import styles from './Team.module.css';
import Title from '../../components/Title/Title';
import Cancel from '../../assets/images/cancel'

const Team = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();
  const { smallMobileView, mobileView, currentUser } = useContext(RootContext);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [displayCompany, setDisplayCompany] = useState(null)
  const [invites, setInvites] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUserActions, setCurrentUserActions] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [inviteToBeCancelled, setInviteToBeCancelled] = useState(null)
  const [isCancellingingInvite, setIsCancellingInvite] = useState(false)

  const resendInvite = async (invite) => {

    if (currentUserActions.includes('idcloud:invites:resend')) {
      try {

        let tmp = [...invites];

        let result = await axios.post(`/resend-invite/${invite.company}/${invite.email}`);
        if (result.status === 200) {
          tmp.find(_ => _.email === invite.email).sent = moment().format("X");
          setInvites(tmp);
        }

      }
      catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.requestInvite')} (${e.response.data.errors})`);
        }
        else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
    }
    else {
      setErrors(`${t('error.resendInvite')} ${displayCompany}`)
    }
  };

  const cancelInvite = async (invite) => {

    if (currentUserActions.includes('idcloud:invites:cancel') ||
      currentUserActions.includes('idcloud:invites:delete')) {
      setIsCancellingInvite(true)
      try {
        let tmp = [...invites];

        let result = await axios.post(`/cancel-invite/${invite.company}/${invite.email}`);
        if (result.status === 200) {
          tmp = tmp.filter(_ => {
            return _.email !== invite.email;
          });
          setInvites(tmp);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.cancelInvite')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      setIsCancellingInvite(false)
      setInviteToBeCancelled(null)
      setShowCancelModal(false)
    }
    else {
      setErrors(`${t('error.cancelExistingInvite')} ${displayCompany}`)
    }

  };

  const cancelInviteHandler = () => {
    setShowCancelModal(false);
    setInviteToBeCancelled(null);
  }

  const inviteNewUser = () => {
    if (currentUserActions.includes('idcloud:invites:add')) {
      let path = `/invite-team-member`;
      if (company) path += `?company=${encodeURIComponent(company)}&displayCompany=${encodeURIComponent(displayCompany)}`
      navigate(path);
    }
    else {
      setErrors(`${t('error.addInvite')} ${displayCompany}`)
    }
  };

  // fetch current invitations
  // and current users
  useMemo(() => {

    const fetch = async () => {

      try {

        setLoading(true);
        let actions = [];
        setInvites([])
        setUsers([])
        setErrors(null)

        const result = await axios.get(`/role?company=${encodeURIComponent(company)}`);

        if (result.status === 200) {
          actions = result.data.record.actions
          setCurrentUserActions(actions)
        }

        const encoded = encodeURIComponent(company)
        const result1 = axios.get(`/invites/?company=${encoded}&status=active`);
        const result2 = axios.get(`/users/?company=${encoded}`);

        let results = await Promise.all([result1, result2]);

        if (results[0].status === 200 && actions.includes('idcloud:invites:list')) {
          const invites = results[0].data.records.sort((a, b) => (a.sent > b.sent) ? 1 : -1).reverse();
          setInvites(invites);
        }

        if (results[1].status === 200 && actions.includes('users:list')) {
          const users = results[1].data.data.sort((a, b) => (a.fname > b.fname) ? 1 : -1);
          setUsers(users);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverDataTeam')}`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (currentUser && company) fetch();

  }, [currentUser, company]);

  useEffect(() => {
    if (currentUser) {
      setCompany(currentUser.company);
      setDisplayCompany(currentUser.companyDisplayName)
    }

  }, [currentUser]);

  const setSelectedCompany = async (e) => {
    setCompany(e.value)
    setDisplayCompany(e.displayName)
  };

  const editUser = (user) => {
    navigate(`${user.username}`);
  };

  if (!currentUser) {
    return <PageContentContainer><h3 style={{ "color": "#990000" }}> {t('error.notAuthorized')}</h3></PageContentContainer>
  }

  return (
    <div className={`${styles.team} pb-5`}>
      {/* invite portion - top */}
      <PageContentContainer>
        <Title title={t('Team.title')} />

        {
          errors ?
            <Alerts messages={errors} close={() => setErrors(null)} />
            : null
        }

        {/* multi home company list */}
        {
          currentUser.companies.length > 0 ?
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('Team.selectCompany')}
                  description={intl.formatMessage({ id: 'Team.selectCompany.details' }, { displayCompany })}
                />
              </LeftColumn>
              <RightColumn >
                <UserCompaniesSelect
                  customStyles={styles.adminSelect}
                  setSelectedCompany={setSelectedCompany}
                  selectedValue={currentUser.company}
                  selectAll={false} />
              </RightColumn>
            </TwoColumnContent>
            : null
        }

        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('Team.newMembers')}
              description={t('Team.newMembers.details')}
            />
          </LeftColumn>
          <RightColumn padded={false} border={false}>
            {
              !loading && !currentUserActions.includes('idcloud:invites:list') ?
                <Alerts messages={intl.formatMessage({ id: 'error.listInvites' }, { displayCompany })} close={() => null} />
                : null
            }
            <Table
              idField={'email'}
              label={'Current outstanding invitations'}
              items={invites}
              fields={[
                { id: 'email', type: 'details', options: { circleTitle: !smallMobileView && true, style: styles.invitationCircleTitle } },
                { id: 'resend', type: 'button', buttonLabel: 'Resend', click: resendInvite, options: { buttonType: 'underline', color: '#757575' } },
                { id: 'cancel', type: 'button', buttonLabel: 'Cancel', click: (_) => { setInviteToBeCancelled(_); setShowCancelModal(true); }, options: { buttonType: 'underline', color: '#D72C0D' } }
              ]}
              hideSearch={true}
              hideTableHeader={true}
              hidePaginate={true}
              borderBottomLastItem={true}
              customWidths={['80%', '10%', '10%']}
              scrollHeight={'200px'}
              isLoading={loading}>
              {
                currentUserActions.includes('idcloud:invites:add') ?
                  <BtnLight title={t('Team.invites.invite')} onClick={inviteNewUser} />
                  : null
              }
            </Table>

          </RightColumn>
        </TwoColumnContent>
        {/* <Container className={classes.Container} fluid>
          <Row>
            <Col xs={ 12 } className="p-0 mb-5 mt-2 mb-4">

            </Col>
          </Row>
        </Container> */}
        {
          !loading && !currentUserActions.includes('users:list') ?
            <Alerts messages={intl.formatMessage({ id: 'error.listUsers' }, { displayCompany })} close={() => null} />
            : null
        }
        <Table
          idField={'username'}
          searchFilters={['username', 'fname', 'lname', 'email']}
          sortFields={['username', 'fname', 'email', 'status', 'created']}
          itemsPerPage={8}
          fields={[
            {
              id: 'username', label: t('UsersList.username'), type: 'details', options: { circleTitle: true },
              conditionalSchemas: [
                { condition: _ => _.profilePicture, schema: { type: 'details', options: { photo: { key: 'profilePicture', objectFit: 'cover', circleBorder: true } } } }
              ]
            },
            { id: 'fname', label: t('UsersList.name'), type: 'details', options: { concatValues: { keys: ['lname'], separator: ' ' } } },
            { id: 'email', label: t('UsersList.email'), type: 'details', options: {} },
            {
              id: 'status', label: t('SearchTable.status'), type: 'status', statusColors: [
                { condition: _ => _.status === 'active', color: { backgroundColor: 'rgba(55, 182, 91, 0.1)', color: '#37b65b' } }
              ]
            },
            { id: 'created', label: t('UsersList.created'), type: 'datetime', options: {} },
            {
              id: 'actions', label: t('VendorSearch.actions.label'), type: 'actions', actions: [
                { label: t('UsersList.edit'), click: editUser }
              ]
            }
          ]}
          showFields={
            (smallMobileView && ['fname', 'actions']) ||
            (mobileView && ['fname', 'actions']) ||
            ['fname', 'email', 'status', 'actions']
          }
          detailsDropdown={
            (mobileView && ['email', 'status', 'created']) ||
            ['created']
          }
          label={t('Team.currentMembers')}
          placeholder={t('Team.filterMembers.placeholder')}
          items={users}
          itemLabels={{
            status: {
              'active': t('SearchTable.active'),
              'inactive': t('SearchTable.inactive'),
              'pending': t('SearchTable.pending')
            }
          }}
          customWidths={
            (mobileView && ['100%', '100%']) ||
            ['33%', '33%', '33%', '33%']
          }
          isLoading={loading}
          rowPlaceholderHeight={'71px'}
        />
      </PageContentContainer>
      <AdminModal
        show={showCancelModal}
        onHide={cancelInviteHandler}
        noSave={true}
        customFooterStyle={"justify-content-between"}
        onDelete={_ => cancelInvite(inviteToBeCancelled)}
        deleteButtonText={t('ui.yesCancel')}
        deleteModeOverride={true}
        isSaving={isCancellingingInvite}
        customCancellationLabel={t('ui.close')}>
        <div className={`${styles.cancelModal} w-100`}>
          <div className={styles.cancelButtonModal}>
            <div onClick={cancelInviteHandler}><Cancel /></div>
          </div>
          <div className={styles.cancelInviteModalTitle}>
            {t('Team.cancelInvite.title')}
          </div>
          <div className={styles.cancelInviteModalDetails}>
            {t('Team.cancelInvite.message')}
          </div>
        </div>
      </AdminModal>
    </div>
  );
};

export default Team;
