import React from "react"
import styles from "./TenantCard.module.css"
import CollapsibleCard from "@project/components/src/CollapsibleCard/CollapsibleCard";
import DefaultImage from '../../assets/images/unknown-unit.png';
import moment from 'moment';
import { useIntl } from "react-intl";

function TenantCard({ space, spaceProfiles }) {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  const formatDate = (date) => {
    if (date) return moment(date).format("MM/DD/YYYY");

    return "N/A";
  }
  
  const id360 = spaceProfiles.find(profile => profile.spaceId === space.spaceId)

  return (
    <CollapsibleCard 
      className={styles.tenantCard} 
      containedContent={true} 
      iconAlign='right'
      summary={<TenantCardSummary space={space} t={t} />}
    >
      <div className={styles.tenantCardContentContainer}>
        <div className={styles.tenantCardContent}>
          <img src={space.logo?.thumbnail?.S || space?.logo || space?.companyLogo || DefaultImage} alt={space.displayName || space.name} className={styles.tenantCardContentImage} />
          <div className={styles.tenantCardTextContainer}>
            <p className={styles.tenantCardText}>{space.tenantCategoryDescription ?? t("LeaseRadarDetails.tenantCard.categoryUnavailable")} <span className={styles.tenantCardSecondaryText}>{space.squareFootage} SQ FT</span></p>
            {
              space.isLeaseExpired === 0 && (
                <p className={styles.tenantCardText}>
                  {t("LeaseRadarDetails.tenantCard.lease")} <span className={styles.tenantCardSecondaryText}>{formatDate(space.leaseStart)} - {formatDate(space.leaseEnd)}</span>
                </p>
              )
            }
            <p className={styles.tenantCardText}>{t("LeaseRadarDetails.tenantCard.vacate")} <span className={styles.tenantCardSecondaryText}>{formatDate(space.vacateDate)}</span></p>
            { id360 ? <p className={styles.tenantCardText}>ID360 <span className={styles.tenantCardSecondaryText}> 
              <a href={`${process.env.REACT_APP_ID360_HOST}/profile/${id360.guid}`}>{space.unit}</a>
            </span></p> : null }
          </div>
        </div>
      </div>
    </CollapsibleCard>
  )
}

function TenantCardSummary({ space, t }) {
  return (
    <div className={styles.tenantCardSummaryContainer}>
      <div className={styles.summaryContentLeft}>
        <img src={space?.logo?.thumbnail?.S || space?.logo || space?.companyLogo || DefaultImage} alt={space.displayName || space.name} className={styles.tenantCardImage} />
        <div>
          <p className={styles.tenantCardDisplayName}>{space.isLeaseExpired ? t("LeaseRadarDetails.tenantCard.available") : space.tenantName} <span className={styles.tenantCardUnitNumber}>{space.unit}</span></p>
        </div>
      </div>
    </div>
  )
}

export default TenantCard;