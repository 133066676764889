import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './SlideContainer.module.css';

const SlideContainer = ({
  children,
  className,
  onClick
}) => (
  <div
    className={classNames(styles.Slide, 'each-slide', className)}
    onClick={onClick}
  >
    <div className={styles.MediaWrapper}>
      {children}
    </div>
  </div>
);

SlideContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func
};

SlideContainer.defaultProps = {
  onClick: () => {}
};

export default SlideContainer;