let list = [
  '\\.btn-primary\\s',
  '\\.btn-secondary\\s',
  '\\.btn-success\\s',
  '\\.btn-warning\\s',
  '\\.btn-danger\\s',
  '\\.btn-light\\s',
  '\\.btn-link\\s',
  '\\.btn-outline-primary\\s',
  '\\.btn-outline-secondary\\s',
  '\\.btn-outline-success\\s',
  '\\.btn-outline-info\\s',
  '\\.btn-outline-warning\\s',
  '\\.btn-outline-danger\\s',
  '\\.btn-outline-light\\s',
  '\\.card\\s',
  '\\.card-body\\s',
  '\\.card-title\\s',
  '\\.card-text\\s',
  '\\.card-subtitle\\s',
  '\\.card-link\\s',
  '\\.card-img-top\\s',
  '\\.card-header\\s',
  '\\.card-footer\\s',
  '\\.card-group\\s',
  '\\.collapse\\s',
  '\\.accordian\\s',
  '\\.dropdown\\s',
  '\\.form-group\\s',
  '\\.form-inline\\s',
  '\\.form-control\\s',
  '\\.readonly\\s',
  '\\.input-group\\s',
  '\\.input-group-sm\\s',
  '\\.input-group-lg\\s',
  '\\.checkbox\\s',
  '\\.radio\\s',
  '\\.dropdown\\s',
  '\\.container\\s',
  '\\.container-fluid\\s',
  '\\.row\\s',
  '\\.img-fluid\\s',
  '\\.img-thumbnail\\s',
  '\\.list-group\\s',
  '\\.media\\s',
  '\\.modal\\s',
  '\\.nav-fill\\s',
  '\\.nav-tabs\\s',
  '\\.nav-pills\\s',
  '\\.nav-justified\\s',
  '\\.navbar\\s',
  '\\.navbar-brand\\s',
  '\\.navbar-text\\s',
  '\\.navbar-light\\s',
  '\\.progress\\s',
  '\\.progress-bar\\s',
  '\\.spinner-border\\s',
  '\\.spinner-grow\\s',
  '\\.table\\s',
  '\\.thead-light\\s',
  '\\.thead-dark\\s',
  '\\.table-striped\\s',
  '\\.table-bordered\\s',
  '\\.table-borderless\\s',
  '\\.table-hover\\s',
  '\\.table-sm\\s',
  '\\.table-reflow\\s',
  '\\.table-active\\s',
  '\\.table-primary\\s',
  '\\.table-secondary\\s',
  '\\.table-success\\s',
  '\\.table-info\\s',
  '\\.table-warning\\s',
  '\\.table-danger\\s',
  '\\.table-light\\s',
  '\\.table-dark\\s',
  '\\.toast\\s',
  '\\.border\\s',
  '\\.border-primary\\s',
  '\\.border-secondary\\s',
  '\\.border-success\\s',
  '\\.border-danger\\s',
  '\\.border-warning\\s',
  '\\.border-info\\s',
  '\\.border-light\\s',
  '\\.border-dark\\s',
  '\\.border-white\\s',
  '\\.rounded\\s',
  '\\.rounded-sm\\s',
  '\\.rounded-lg\\s',
  '\\.rounded-circle\\s',
  '\\.rounded-1\\s',
  '\\.rounded-2\\s',
  '\\.rounded-3\\s',
  '\\.rounded-4\\s',
  '\\.rounded-5\\s',
  '\\.bg-primary\\s',
  '\\.bg-secondary\\s',
  '\\.bg-success\\s',
  '\\.bg-info\\s',
  '\\.bg-warning\\s',
  '\\.bg-danger\\s',
  '\\.bg-light\\s',
  '\\.bg-dark\\s',
  '\\.bg-white\\s',
  '\\.text-primary\\s',
  '\\.text-secondary\\s',
  '\\.text-success\\s',
  '\\.text-info\\s',
  '\\.text-warning\\s',
  '\\.text-danger\\s',
  '\\.text-light\\s',
  '\\.text-dark\\s',
  '\\.text-white\\s',
  '\\.shadow\\s',
  '\\.shadow-sm\\s',
  '\\.shadow-lg\\s',
  '\\.invisible\\s',
  '\\.visible\\s',
  '\\.clearfix\\s',
  '\\.fixed-top\\s',
  '\\.fixed-bottom\\s',
  '\\.sticky-top\\s',
  '\\.font-weight-bold\\s',
  '\\.font-weight-bolder\\s',
  '\\.font-weight-light\\s',
  '\\.font-weight-lighter\\s',
  '\\.font-weight-normal\\s',
  '\\.text-lowercase\\s',
  '\\.text-uppercase\\s',
  '\\.text-capitalize\\s',
  '\\.text-truncate\\s',
  '\\.text-body\\s',
  '\\.text-black-50\\s',
  '\\.text-muted\\s',
  '\\.w-[0-100]\\s',
  '\\.h-[0-100]\\s',
  '\\.(m|p)(x|y|l|r|t|p|)(-sm|-md|-lg|-xl|-xxl|)-[0-5]\\s',
  '\\.justify-content(-sm|-md|-lg|-xl|-xxl|)-(start|end|center|between|around)\\s',
  '\\.align-items(-sm|-md|-lg|-xl|-xxl|)-(start|end|center|stretch|around)\\s',
  '\\.flex(-sm|-md|-lg|-xl|-xxl|)-(column|column-reverse|row|row-reverse|nowrap|wrap|wrap-reverse|fill|grow-1|grow-0|shrink-1|shrink-0)\\s',
  '\\.d(-sm|-md|-lg|-xl|-xxl|)-(block|flex|inline|inline-block|inline-flex|none|table|table-cell)\\s',
  '\\.col\\s',
  '\\.col(-sm|-md|-lg|-xl|-xxl|)-'
];

export default list;