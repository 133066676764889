/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Table } from "@project/components";
import axios from '../../utils/axios';
import DashboardCard from '../DashboardCard/DashboardCard';
import StringTimeSelector from '../StringTimeSelector/StringTimeSelector';
import styles from './Alertables.module.css';
import adminSelectStyles from '../AdminSelect/AdminSelect.module.css';

const Alertables = ({user, company, property, setErrors, view: {smallMobileView, mobileView, xLargeView}}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [selectedTime, setSelectedTime] = useState({ label: t('Amp.3Week'), value: "plus3w" });

  useEffect(() => {

    const fetch = async () => {

      try {
        setLoading(true);
        const currentCompany = (company && company.value) || user.company;
        let getlink = `/alert-events/?range=${selectedTime.value}&alerting=true&company=${encodeURIComponent(currentCompany)}`;
        if (property && property !== 'All') getlink += `&property=${encodeURIComponent(property.name)}`;
        const results = await axios.get(getlink);

        if (results.status === 200) {
          const tmp = results.data.records.map((alert) => {
            return {
              id: alert.id,
              guid: alert.AssetAlert.assetGuid,
              company: alert.AssetAlert.company,
              property: alert.AssetAlert.property,
              section: alert.AssetAlert.section,
              stencilName: alert.AssetAlert.stencilName,
              fieldName: alert.AssetAlert.fieldName,
              alertDate: moment(alert.alertDate).format('MM/DD/YYYY hh:mm A'),
              view: 'view'
            };
          }).sort((a, b) => (a.alertDate > b.alertDate) ? 1 : -1);
          setAlerts(tmp);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          if (setErrors) setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          if (setErrors) setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (user) fetch();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [user, selectedTime, property, company]);

  const getRpmLink = (e) => {
    const company = encodeURIComponent(e.company);
    const property = encodeURIComponent(e.property);
    return `${process.env.REACT_APP_RPM_HOST}/${company}/${property}/${e.section}?asset=${e.guid}`;
  };

  if (!user) return null;

  return (
    <DashboardCard
      header={
        <div className={`${styles.titleContainer} pt-3 mb-2`}>
          <div className={`${styles.tableTitle}`}>{t('Alerts.desc')}</div>
          <div className="d-inline-block">
            <div className="d-inline-block">Alerting within </div>
            <div className={`${styles.timeSelector} d-inline-block`}>
              <StringTimeSelector
                propStyles={{
                  propertyMenu: `${adminSelectStyles.propertyMenu} ${styles.timeSelectorMenu}`
                }}
                timeOption={'future'}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                loading={loading} />
            </div>
          </div>
        </div>
      }
    >
      <Table
        idField={'id'}
        searchFilters={['property', 'stencilName', 'fieldName', 'alertDate']}
        sortFields={['property', 'stencilName', 'fieldName', 'alertDate']}
        itemsPerPage={8}
        fields={[
          { id: 'property', label: t('Alerts.property'), type: 'details', options: { circleTitle: true } },
          { id: 'stencilName', label: t('Alerts.stencilName'), type: 'details', options: {} },
          { id: 'fieldName', label: t('Alerts.fieldName'), type: 'details', options: {} },
          { id: 'alertDate', label: t('Alerts.alertDate'), type: 'details', options: {} },
          {
            id: 'view',
            buttonLabel: t('Alerts.view'),
            type: 'button',
            click: () => null,
            options: {
              buttonType: 'underline',
              link: getRpmLink
            }
          }
        ]}
        customWidths={
          (smallMobileView && ['50%', '50%']) ||
          (mobileView && ['41%', '25%', '33%']) ||
          ['30%', '20%', '25%', '25%']
        }
        showFields={
          (smallMobileView && ['property', 'stencilName', 'view']) ||
          (mobileView && ['property', 'stencilName', 'alertDate', 'view']) ||
          (!xLargeView && ['property', 'stencilName', 'fieldName', 'alertDate', 'view'])
        }
        detailsDropdown={
          (smallMobileView && ['fieldName', 'alertDate']) ||
          (mobileView && ['fieldName'])
        }
        placeholder={t('Alerts.filterPlaceholder')}
        items={alerts}
        isLoading={loading}
        rowPlaceholderHeight={'71px'}
        customStyle={styles.table}
      />
    </DashboardCard>
  )
}

export default Alertables;
