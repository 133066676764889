// Constants required to match data coming from IDInspect.

/**
 * Statuses for inspection instances.
 */
export const INSPECTION_STATUS = {
  OPEN: 'Open',
  IN_PROGRESS: 'In-Progress',
  PAUSED: 'Paused',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  COMPLETE: 'Complete'
};

/**
 * Values for the `type` filter under GET `/inspect/instances` endpoint.
 */
export const INSPECTION_INSTANCES_FILTER_TYPE = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  PROPERTIES: 'properties'
};

export const ACL = {
  HTTP_HEADERS: {
    IDP_COMPANY: 'IDP-Company'
  }
}

export const COMPANY_SETTINGS = {
  ENABLED: 'inspections.enabled',
  ARCHIVAL: 'inspections.inspectionArchival'
}
