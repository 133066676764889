import React from "react";
import styles from "./CollapsibleCard.module.css";
import { useState, useEffect } from "react";
import { IoChevronDownCircle } from "react-icons/io5";


const classNames = require('classnames');

function Collapsible({ className, summary, children, subtitle, selection, onSelect, onDeselect, containedContent, open, iconAlign = "left" }) {
  const [selectionState, setSelectionState] = useState(false);
  const [isOpen, setIsOpen] = useState(open ?? false);

  useEffect(() => {
    setIsOpen(selectionState);
  }, [selectionState]);

  useEffect(() => {
    if (open !== undefined) {
      setSelectionState(open);
    }
  }, [open]);

  const containerClass = containedContent ? styles.detailsContained : styles.details;
  const summaryClass = containedContent ? styles.summaryContained : styles.summary;

  const handleSelectionChange = (event) => {
    const newState = event.target.checked;
    setSelectionState(newState);

    if (onSelect && newState) {
      onSelect();
    }

    if (onDeselect && !newState) {
      onDeselect();
    }
  };

  const toggleCollapsible = () => {
    setSelectionState(prevState => !prevState);
  };

  return (
    <div className={className} onClick={toggleCollapsible}>
      <details className={containerClass} open={isOpen} onClick={(e) => e.stopPropagation()}>
        <summary className={classNames(summaryClass, `${iconAlign === "right" ? "flex-row-reverse" : ""}`)} onClick={toggleCollapsible}>
          {
            selection ?
              <input
                className={styles.CheckBox}
                checked={selectionState}
                onChange={handleSelectionChange}
                type="checkbox"
              /> :
              <IoChevronDownCircle className={styles.ArrowIcon} />
          }
          <span className={styles.title}>
            {summary}
          </span>
          {subtitle && <span className={styles.subtitle}>
            {subtitle}
          </span>}
        </summary>
        {children}
      </details>
    </div>
  );
};

export default Collapsible;
