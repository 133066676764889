import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import AdminRichTextEditor from '../AdminRichTextEditor/AdminRichTextEditor';
import styles from './AdminInput.module.css';

const AdminInput = (props) => {
  const {
    id,
    label,
    isInvalid,
    placeholder,
    text,
    value,
    readOnly,
    type,
    options,
    children,
    validationmessage,
    hidelabel = false,
    customstyle = '',
    onChange,
    autoFocus = false,
    suffix = <></>,
    disabled = false,
  } = props;
  const isRichTextEditor = useMemo(() => type === 'richtext', [type]);

  const textAreaProps = type === 'textarea' ? { as: 'textarea', rows: 5 } : {};

  // https://stackoverflow.com/a/60046254/3759218
  // Adding attribute `spellCheck = "false"` disables smart punctuations (on iPads)
  const userAgent = navigator.userAgent
  const disableSpellCheck = userAgent.indexOf('iPad') > -1 && ['text', 'textarea'].includes(type)
  const allTextProps = { spellCheck: !disableSpellCheck }

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Form.Group className={classNames(styles.adminInputGroup, customstyle)}>
      <Form.Label htmlFor={id} bsPrefix={hidelabel ? 'd-none' : ''} datacy={`${id}-label`}>{label}</Form.Label>
      {children}
      {!children && !isRichTextEditor && (
        <div className={suffix ? styles.controlWithSuffix : ''}><Form.Control
          {...props}
          {...textAreaProps}
          {...allTextProps}
          {...options}
          hidelabel={hidelabel || undefined}
          placeholder={placeholder}
          value={value === null || value === undefined ? '' : value}
          readOnly={readOnly}
          id={id}
          name={id}
          ref={inputRef}
        />{suffix}</div>
      )}
      {!children && isRichTextEditor && (
        <AdminRichTextEditor
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
      {text ? (
        <Form.Text className="text-muted" name={id} id={id} disabled={disabled}>
          {text}
        </Form.Text>
      ) : null}
      {isInvalid ?
        <Form.Control.Feedback id="invalidFeedback" type="invalid" style={{ display: "block" }}>
          {validationmessage || `Enter a valid value for '${label}'`}
        </Form.Control.Feedback>
        : null}
    </Form.Group>
  );
};

AdminInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  validationmessage: PropTypes.string,
  hidelabel: PropTypes.bool,
  customstyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
}

AdminInput.defaultProps = {
  placeholder: '',
  text: '',
  value: '',
  readOnly: false,
  options: [],
  validationmessage: '',
  hidelabel: false,
  customstyle: '',
  onChange: () => { }
}

export default AdminInput;