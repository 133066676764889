import React from 'react'
import { useIntl } from 'react-intl'
import icons, { DEFAULT_UNCOLORED } from "./icons"

import styles from './RpmIcon.module.css'

const useIcon = (id, theme) => {
  const intl = useIntl()
  const t = (key) => intl.formatMessage({id: key})

  const iconConfiguration = icons.find(icon => icon.id === id)

  if (!iconConfiguration) {
    return {
      width: 72,
      height: 72,
      viewboxWidth: 72,
      viewboxHeight: 72,
      containerStyle: { background: DEFAULT_UNCOLORED },
      defs: null,
      icon: <text x="2" y="20" fill="black">{id.substr(0, 2)}</text>
    }
  }
  
  const { icon, colorThemes, translateX, scale, viewBox = [] } = iconConfiguration
  const [viewboxWidth = 72, viewboxHeight = 72] = viewBox

  let fill = colorThemes[theme]
  let defs = null
  
  if (fill.start && fill.end) {
    // Gradient.
    fill = `linear-gradient(${fill.start}, ${fill.end})`
  }

  const containerStyle = { background: fill }
  let svgClassName = []
  const svgStyle = { transform: [] }
  let textY = '95%'

  if (translateX) {
    svgClassName.push(styles.translateFix)
  }

  if (scale === '2x') {
    svgClassName.push(styles.scaleFix)
    textY = '100%'
  } else if (scale === '1.5x') {
    svgClassName.push(styles.scaleAltFix)
    textY = '99%'
  }

  svgClassName = svgClassName.join(' ')

  const textElement = <text 
    x="50%" y={textY}
    fontFamily='sans-serif' fontSize='10' 
    letterSpacing="0.5px" 
    fill="white" 
    textAnchor="middle">
    {t(`Dashboard.subscriptionsTracker.scopes.${id}`).toUpperCase()}
  </text>

  return {
    width: 36,
    height: 36,
    viewboxWidth,
    viewboxHeight,
    containerStyle,
    svgClassName,
    svgStyle,
    defs,
    textElement,
    icon
  }
}

const RpmIcon = ({ id, theme = 'uncolored', title = '' }) => {
  const {
    width,
    height,
    viewboxWidth,
    viewboxHeight,
    containerStyle,
    svgClassName,
    defs,
    icon,
    textElement
  } = useIcon(id, theme)
  
  return <div title={title} className={styles.rpmIcon} style={containerStyle}><svg className={svgClassName} width={width} height={height} viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    {icon}
    {textElement}
    {defs}
  </svg></div>
}

export default RpmIcon