import React, {
  memo
} from 'react'
import classNames from 'classnames'
import { Spinner } from 'react-bootstrap'
import styles from './LoadingIndicator.module.css'

/**
 * Component that renders a small spinner alonside a label
 * indicating a loading status.
 */
const LoadingIndicator = () => (
  <>
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    <span className={classNames('visually-hidden', styles.LoadingIndicatorLabel)}>Loading...</span>
  </>
)

export default memo(LoadingIndicator)
