import React, { useState, useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import upload from './../../assets/images/button-icons/upload.svg';
import { IoMdClose } from 'react-icons/io';
import styles from './UploadButton.module.css';
import { useIntl } from 'react-intl';

export default function UploadButton({ variant = "light", description, disabled, icon, onClick, persistedFile, uploadedFile, isSaving, onDelete }) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [uploadIcon, setUploadIcon] = useState(null);
  const isIconComponent = typeof icon === 'function';

  // bring in the upload icon based on the schema
  useEffect(() => {
    if (!icon || isIconComponent) return;

    // if icon is already an image, use it as is, otherwise import it
    if (icon.indexOf('data:image') === 0) return setUploadIcon(icon);
    import(`./../../assets/images/${icon}`).then(module => {
      setUploadIcon(module.default);
    }).catch(() => console.warn(`Upload icon ${icon} not found. Using default icon.`));
  }, [icon, isIconComponent]);

  const renderLink = () => uploadedFile ?
    <div className={`d-inline ml-sm-2 ${styles.uploadText}`}><span>{uploadedFile ? `${t('ui.upload')}: ` : null}{uploadedFile.name}</span></div> :
    <a href={persistedFile} rel="noopener noreferrer" target="_blank">{persistedFile.split('/').pop()}</a>

  const IconComponent = isIconComponent ? icon : () => { };

  const renderIcon = () => isIconComponent ? <IconComponent className="mr-2" /> : <Image src={uploadIcon || upload} className="mr-2" rounded alt={intl.formatMessage({id: 'ui.uploadItem'}, {item: description})} />;

  const renderButtonContents = () => <>{renderIcon()} <span>{isSaving ? `${t('ui.uploading')}...` : intl.formatMessage({id: 'ui.uploadItem'}, {item: description})}</span></>;

  return (
    <>
      <Button className={`border-0 d-flex align-items-center ${styles.uploadButton}`} variant={variant} size="lg" onClick={onClick} disabled={disabled}>
        {renderButtonContents()}
      </Button>
      &nbsp;
      <div>
        {persistedFile || uploadedFile ?
          <>
            {renderLink()}
            {!disabled && !uploadedFile ? <IoMdClose className='text-dark ml-2' onClick={onDelete} /> : null}
          </>
          : null}
      </div>
    </>
  );
}
