import { useEffect, useCallback } from "react"
import { API_FILE_CATEGORY_MAP } from '../constants';

export const useRotate = ({
  setIsRotating, setErrorMessage, type, mediaCache, fileToRotate,
  ownerId, ownerType, extraPayloadData, axiosInstance, rotateRoute,
  setMediaCache, onUpdate, setFileToRotate
}) => {
  const rotateMedia = useCallback(async () => {
    const { targetFile, rotationDegrees, rotationCallback } = fileToRotate

    if (!targetFile) return

    setIsRotating(true)
    setErrorMessage(null)

    const category = API_FILE_CATEGORY_MAP[type]
    const data = new FormData()
    const fileToRotateIndex = mediaCache.findIndex(file => file.id === targetFile.id)
    data.append('file', targetFile.url)
    data.append('ownerId', ownerId)
    data.append('ownerType', ownerType)
    data.append('category', category)
    data.append('rotate', rotationDegrees)
    for (const payloadKey in extraPayloadData) {
      data.append(payloadKey, extraPayloadData[payloadKey]);
    }
    try {
      const rotateResponse = await axiosInstance.put(`${rotateRoute}/${targetFile.id}`, data)
      const rotatedFile = rotateResponse.record
      const updatedMedia = [...mediaCache]
      // Something to force re-rendering
      rotatedFile.timestamp = Date.now()
      updatedMedia[fileToRotateIndex] = rotatedFile
      setMediaCache(updatedMedia);
      onUpdate(updatedMedia);
      rotationCallback(updatedMedia)
    } catch (e) {
      setErrorMessage(e.message);
    }
    setIsRotating(false)
    setFileToRotate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToRotate])

  useEffect(() => {
    if (fileToRotate) {
      rotateMedia()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToRotate])
}