import React from 'react';

function PageContentContainer({ children, style }) {
  if (!style) {
    style = {
      "display": "normal"
    };
  }
  return (
    <div className="d-flex mt-2 mt-lg-4 mb-5" style={style}>
      <div className="col-xl-10 offset-xl-1">
        {children}
      </div>
    </div>
  );

};

export default PageContentContainer;
