import React, { useState, useContext } from 'react';
import { Table, Alerts } from '@project/components';
import Title from '../../components/Title/Title';
import { useIntl } from 'react-intl';
import { useSpaces } from './hooks.js';
import { useNavigate } from 'react-router-dom';
import { RootContext } from './../../hoc/RootContext/RootContext';
import styles from './LeaseRadar.module.css';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import moment from 'moment';
import DashboardFilters from '../../components/DashboardFilters/DashboardFilters';

const LeaseRadar = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availabilityFilter, setAvailabilityFilter] = useState('all');
  const [setDashboardData] = useState(false);
  const [spaceQueryParams, setSpaceQueryParams] = useState({
    company: '',
    property: '',
    page: 0,
    orderBy: 'property',
    orderAscending: true,
    search: '',
    tagFields: [],
    limit: 10
  });
  const [activeTable, setActiveTable] = useState('')

  const { spaces, pagination } = useSpaces({
    setLoading,
    loading,
    setErrors,
    leaseStatus: availabilityFilter,
    setLeaseStatus: setAvailabilityFilter,
    spaceQueryParams,
    activeTable
  });

  const { smallMobileView, mobileView } = useContext(RootContext);

  const isOccupied = ({ isLeaseExpired }) => {
    return !isLeaseExpired;
  }

  const isExpiring = (_, months = 6) => {
    const currentDate = moment();
    const leaseEndDate = moment(_.leaseEnd);

    // Check if the lease has already expired
    if (leaseEndDate.isBefore(currentDate)) return false;

    const monthsUntilLeaseExpiration = leaseEndDate.diff(currentDate, 'months');

    return monthsUntilLeaseExpiration < months;
  };

  const isVacant = ({ isLeaseExpired }) => {
    return isLeaseExpired;
  }

  const tabFilters = {
    'all': _ => true,
    'occupied': isOccupied,
    'expiring': (_) => isExpiring(_, 6),
    'vacant': isVacant,
    'available': _ => _.isLeaseExpired === 1
  }

  return (
    <PageContentContainer>
      <Title title={t('LeaseRadar.title')} backRoute={'/dashboard'} />
      {errors.length > 0 ?
        <div className="w-100">
          <Alerts messages={errors} close={() => setErrors([])} />
        </div>
        : null}
      <DashboardFilters
        setDashboardData={setDashboardData}
        show={true}
        companySelectAll={false}
        propertySelectAll={true} />
      <Table
        idField={'id'}
        searchFilters={['property', 'unit', 'leaseExpirationDate', 'address']}
        sortFields={['property', 'unit', 'leaseExpirationFormatted', 'leaseExpirationDate', 'address']}
        itemsPerPage={mobileView ? 5 : 10}
        showFields={
          (smallMobileView && ['property', 'unit', 'view']) ||
          (mobileView && ['property', 'unit', 'leaseExpirationFormatted', 'view']) ||
          ['property', 'unit', 'leaseExpirationDate', 'leaseExpirationFormatted', 'view']
        }
        detailsDropdown={
          (smallMobileView && ['leaseExpirationFormatted', 'leaseExpirationDate', 'address']) ||
          (mobileView && ['leaseExpirationDate', 'address']) ||
          ['address']
        }
        fields={[
          { id: 'property', label: t('term.property'), type: 'details', options: { photo: { key: 'leasing_image', objectFit: 'cover' }, style: styles.leaseRadarCell } },
          { id: 'unit', label: t('term.space'), type: 'details', options: { style: styles.details } },
          {
            id: 'leaseExpirationFormatted', label: t('SearchTable.status'), type: 'status', statusColors: [
              { condition: (_) => isOccupied(_) && !_.leaseEnd, color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } },
              { condition: (_) => isExpiring(_, 3), color: { backgroundColor: 'rgba(206, 21, 20, 0.1)', color: '#CE1514' } },
              { condition: (_) => isExpiring(_, 6), color: { backgroundColor: 'rgba(255, 122, 0, 0.1)', color: '#FF7A00' } },
              { condition: (_) => isExpiring(_, 12), color: { backgroundColor: 'rgba(197, 192, 46, 0.1)', color: '#C5C02E' } },
              { condition: (_) => isExpiring(_, 24), color: { backgroundColor: 'rgba(129, 196, 41, 0.1)', color: '#81C429' } },
              { condition: (_) => isExpiring(_, 48), color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } },
              { condition: (_) => isExpiring(_, 72), color: { backgroundColor: 'rgba(53, 164, 191, 0.1)', color: '#35A4BF' } },
              { condition: (_) => isExpiring(_, 120), color: { backgroundColor: 'rgba(154, 79, 252, 0.1)', color: '#9A4FFC' } },
              { condition: (_) => isExpiring(_, 10000), color: { backgroundColor: 'rgba(53, 53, 187, 0.1)', color: '#3535BB' } },
              { condition: isVacant, color: { backgroundColor: 'rgba(109, 113, 117, 0.1)', color: '#6D7175' } }
            ]
          },
          { id: 'leaseExpirationDate', label: t('term.leaseExpirationDate'), type: 'details', options: { style: styles.details } },
          { id: 'address', label: t('term.location'), type: 'details', options: { concatValues: { keys: ['city', 'state'], separator: ', ' }, style: styles.details } },
          {
            id: 'view',
            buttonLabel: t('Alerts.view'),
            type: 'button',
            click: _ => navigate(`/lease-radar/details?company=${_.companySlug}&property=${_.propertySlug}`),
            options: {
              buttonType: 'underline'
            }
          }
        ]}
        label={t('VendorDashboard.myVendors')}
        placeholder={t('LeaseRadar.filterSpaces.placeholder')}
        items={spaces}
        customWidths={
          (smallMobileView && ['70%', '30%']) ||
          (mobileView && ['40%', '35%', '25%']) ||
          ['40%', '15%', '15%', '30%']
        }
        tabs={[
          { label: t('ui.all'), click: () => setAvailabilityFilter('all') },
          { label: t('LeaseRadar.occupied'), click: () => setAvailabilityFilter('occupied') },
          { label: t('LeaseRadar.expiring'), click: () => setAvailabilityFilter('expiring') },
          { label: t('LeaseRadar.vacant'), click: () => setAvailabilityFilter('vacant') },
          { label: t('ui.available'), click: () => setAvailabilityFilter('available') }
        ]}
        scrollX={mobileView}
        smallMobileView={smallMobileView}
        isLoading={loading}
        customSelectedTab={Object.keys(tabFilters).findIndex(_ => _ === availabilityFilter)}
        rowPlaceholderHeight={'71px'}
        customPaginate={true}
        customPaginationTotalSize={pagination.count}
        setCustomSearchFilter={(newSearch) => setSpaceQueryParams(prevParams => {return {...prevParams, search: newSearch}})}
        setCustomSort={(newSort) => setSpaceQueryParams(prevParams => {return {...prevParams, orderBy: newSort.key, orderAscending: !newSort.descending}})}
        setCustomPage={(newPage) => setSpaceQueryParams(prevParams => {return {...prevParams, page: newPage}})}
        customPage={spaceQueryParams.page}
        isQueryChange={() => setActiveTable({name: 'spaces'})}
      />
    </PageContentContainer>
  )
}

export default LeaseRadar;
