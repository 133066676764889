import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import styles from './MfaForm.module.css';
import axios from '../../utils/axios';
import { isValidEmail } from './../../utils/utils';
import Cookies from 'universal-cookie';
import { getCookieOptions } from "./../../utils/utils";
import { useIntl } from 'react-intl';

const classNames = require('classnames');

const MfaForm = (props) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const cookies = new Cookies();

  let savedMfa = cookies.get('save-mfa');

  const [errors, setErrors] = useState(null);
  const [code, setCode] = useState(null);
  const [method, setMethod] = useState(null);
  const [saveMfa, setSaveMfa] = useState(savedMfa || false);
  const [step, setStep] = useState(1);

  const { user } = props;

  // if cookie exists
  // just return success and quit
  if (savedMfa) {
    props.onSuccess();
    return null;
  }

  const processMfa = async (e) => {
    const callback = (success) => {
      props.loadingIndicator(false);
      if (success) {
        props.onSuccess();
      } else {
        props.onFailure();
      }
    }
    let copts = getCookieOptions();

    setErrors(null);
    setCode(null);
    props.loadingIndicator(true);

    try {

      const username = user.username;
      let result = await axios.post('/users/validate-mfa', { username, code });

      if (result.status === 200) {
        // if saved set cookie
        if (saveMfa) cookies.set('save-mfa', 'true', copts);
        callback(true);
      }
      else {
        cookies.remove('save-mfa', { path: copts.path, domain: copts.domain });
        callback(false);
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.validateMfa')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
      callback(false);
    }
  };

  const requestMfa = async (e) => {

    setErrors(null);
    setCode(null);
    props.loadingIndicator(true);

    try {

      if (method && method !== 'none') {
        const username = user.username;
        let result = await axios.post('/users/request-mfa', { username, method });

        if (result.status === 200) {
          setStep(2);
        }
      }
      else {
        setErrors(t('error.chooseMfaOption'))
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.requestMfa')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }
    finally {
      props.loadingIndicator(false);
    }

  };

  const createSelectItems = () => {
    let items = [];

    items.push(<option key={'none'} value={'none'}>{t('MfaForm.select')}</option>);

    if (isValidEmail(user.email)) {
      const half = user.email.split('@').shift().length / 2;
      let tmp = "*".repeat(half) + user.email.split('@').shift().substring(half);
      tmp += '@' + user.email.split('@').pop();
      items.push(<option key={'email'} value={'email'}>{t('MfaForm.email')} ({tmp})</option>);
    }

    if (user.phone) {
      let tmp = "*".repeat(6) + '-' + user.phone.substring(6);
      items.push(<option key={'phone'} value={'phone'}>{t('MfaForm.phone')} ({tmp})</option>);
    }

    return items;
  };

  return (
    <>
      <h3>{t('MfaForm.title')}</h3>
      {
        step === 1 ?
          <>
            <p>
              {t('MfaForm.details')}
            </p>
            <Form>
              <Form.Group>
                <Form.Control
                  as="select"
                  onChange={e => setMethod(e.target.value)}>
                  {createSelectItems()}
                </Form.Control>
              </Form.Group>
              {errors ? (
                <Alert key={errors} variant="danger">
                  {t('error')}: {errors}
                </Alert>
              ) : null}
              <Button variant="primary" block
                className={classNames(styles.btnLogin, 'border-0 w-100 d-flex align-items-center justify-content-center')}
                onClick={requestMfa}>
                {t('MfaForm.request')}
              </Button>
            </Form>
            <div className="border-bottom my-3"></div>
          </>
          :
          <>
            <p>
              {intl.formatMessage({id: 'MfaForm.enterCode'}, {device: method})}
            </p>
            <Form>
              <Form.Group>
                <Form.Control
                  className={styles.loginInput}
                  type="text"
                  placeholder={t('MfaForm.placeholder')}
                  onChange={e => setCode(e.target.value)}
                />
              </Form.Group>
              {errors ? (
                <Alert key={errors} variant="danger">
                  {t('error')}: {errors}
                </Alert>
              ) : null}
              <Button variant="primary" block
                className={classNames(styles.btnLogin, 'border-0 w-100 d-flex align-items-center justify-content-center')}
                onClick={processMfa}>
                {t('MfaForm.verify')}
              </Button>
              <div className={styles.MfaCheck} >
                <input
                  id='isSaveMfa'
                  type='checkbox'
                  checked={saveMfa}
                  onChange={(e) => { setSaveMfa(e.currentTarget.checked) }} />
                <label htmlFor="isSaveMfa">{t('MfaForm.dontAskAgain')} </label>
              </div>
            </Form>
            <div className="border-bottom my-3"></div>
          </>
      }
    </>
  );
};

export default MfaForm;
