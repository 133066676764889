import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import RemoveIcon from '../../assets/images/times-circle-solid.svg';
import styles from './ThumbnailContainer.module.css';

const ThumbnailContainer = ({
  children,
  onClick,
  onRemoveButtonClick,
  selected,
  showRemoveButton
}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const containerClasses = classNames(
    styles.ThumbnailContainer,
    {
      [styles.Selected]: selected
    }
  );

  return (
    <div className={containerClasses} onClick={onClick}>
      {showRemoveButton && (
        <div className={styles.RemoveButton} onClick={onRemoveButtonClick} >
          <img
            className={styles.RemoveButtonIcon}
            src={RemoveIcon}
            alt={t('DragDropImageUpload.removeImage')}
          />
        </div>
      )}
      {children}
    </div>
  );
};

ThumbnailContainer.propTypes = {
  /** Elements inside the thumbnail container. It can be an image, video. */
  children: PropTypes.node,
  /** Called when the main container gets clicked. */
  onClick: PropTypes.func,
  /** Called when the X button gets clicked. */
  onRemoveButtonClick: PropTypes.func,
  /** Marks this element as "selected". It displays a dark square around the container. */
  selected: PropTypes.bool,
  /** Shows/hides the X button, intended to "remove" this element from the uploader. */
  showRemoveButton: PropTypes.bool
};

ThumbnailContainer.defaultProps = {
  onClick: () => {},
  onRemoveButtonClick: () => {},
  selected: false,
  showRemoveButton: false
};

export default ThumbnailContainer;