import { useState, useCallback } from 'react'
import { useWidthBreakpoints, useInspectPropertyCounts } from '@project/components/src/utils/hooks'
import { INSPECT_FILTER_TYPE } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

/**
   * This is container for props that will be passed to the Table component
   * to handle fields, filters, sizing, etc when property selected
   * @param {boolean} isAdmin is user admin
   * @param {{guid: string, slug: string}} currentCompany currently selected company
   * @param {HTMLDivElement} tableRef reference to data table. used for sizing
   * @param {(guid: string) => void} setPropertySelected sets guid of selected company so we can filter on this value
   * @param {(filter: string) => void} setInspectFilter sets the filter type (assigned, all, etc.)
   * @param {string} type inspection or work-order
   * @param {string} companySlug slug of currently selected company
   * @param {string} companyGuid guid of currently selected company
   * @returns {any} Returns various utilities for the inspecttable
   */
const useTableUtils = ({
  isAdmin,
  currentCompany,
  axios,
  CancelToken,
  isCancel,
  tableRef,
  setPropertySelected,
  setInspectFilter,
  type,
  companySlug,
  companyGuid,
  openActionsInNewWindow = false
}) => {
  const [propertiesLoading, setPropertiesLoading] = useState(false)

  const navigate = useNavigate()

  //Using this hook so that the table will self adjust for responsiveness. The breakpoints are based
  //based on the table width itself rather than the window. This allows for more flexible in placement.
  const {
    mobileView,
    largeView,
    xLargeView
  } = useWidthBreakpoints({
    widthBreakpoints: {
      mobileView: (width) => width <= 680,
      largeView: (width) => width > 820,
      xLargeView: (width) => width > 1024
    },
    elementWidth: document.body.clientWidth
  })

  const properties = useInspectPropertyCounts({
    companyGuid,
    currentCompany,
    setIsLoading: setPropertiesLoading,
    toastError: () => { },
    axios,
    CancelToken,
    isCancel,
    isAdmin,
    type
  })

  /**
   * Constructs an IDInspect URL that leads to the details page for a given
   * `inspection`.
   * @param {Object} inspection An inspection object.
   * @return An IDInspect URL string.
   */
  const getIdInspectUrl = useCallback(
    (item, inspectFilter) => `${process.env.REACT_APP_IDINSPECT_HOST}/${companySlug}${[INSPECT_FILTER_TYPE.ALL, INSPECT_FILTER_TYPE.COMPLETED].includes(inspectFilter) && isAdmin ? '/admin' : ''}/${type}/${item.id}`,
    [companySlug, type, isAdmin]
  )

  //Allows us to open up an inspection or work order in admin view
  const viewItem = useCallback((itemId) => {
    const prefix = currentCompany ? `/${companySlug}` : ''
    const url = `${prefix}/admin/${type}/${itemId}`
    if (!openActionsInNewWindow) {
      navigate(url)
    } else {
      window.open(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySlug, navigate, type])

  //Starts the filter and url change process when property selected from properties list
  const handlePropertyRowClick = useCallback((tableRowItem) => {
    setPropertySelected(tableRowItem.guid)
    const targetFilter = isAdmin ? INSPECT_FILTER_TYPE.ALL : INSPECT_FILTER_TYPE.ASSIGNED
    setInspectFilter(targetFilter)
  }, [isAdmin, setPropertySelected, setInspectFilter])

  //Directs us to the edit inspection page
  const editInspection = useCallback((inspectionId) => {
    const prefix = currentCompany ? `/${companySlug}` : '';
    const url = `${prefix}/inspection/${inspectionId}`;
    if (!openActionsInNewWindow) {
      navigate(url)
    } else {
      window.open(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySlug, navigate])

  return {
    properties,
    propertiesLoading,
    mobileView,
    largeView,
    xLargeView,
    getIdInspectUrl,
    viewItem,
    handlePropertyRowClick,
    editInspection
  }
}

export default useTableUtils