import React from 'react';

const LeftColumn = ({ children }) => {

  return (
    <div className='pl-0 col-12 col-lg-5'>
      {children}
    </div>
  );
};

export default LeftColumn;
