import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactImageLightbox from 'react-image-lightbox';
import { DEFAULT_Z_INDEX } from './constants';
import styles from './Lightbox.module.css';
import 'react-image-lightbox/style.css';

const Lightbox = ({
  reactModalStyle,
  wrapperClassName,
  zIndex,
  ...props
}) => {
  const overriddenWrapperClasses = classNames(
    styles.LightboxWrapper,
    wrapperClassName
  );
  const overriddenModalStyles = Object.assign({}, {
    overlay: {
      zIndex,
    }
  }, reactModalStyle);

  return (
    <ReactImageLightbox
      reactModalStyle={overriddenModalStyles}
      wrapperClassName={overriddenWrapperClasses}
      {...props}
    />
  );
};

// Leaving all these props here so that Intellisense or any other code auto-completion tool
// can decypher the props that we're hiding under the wrapper component
Lightbox.propTypes = {
  /**
   * Replaces the default Z-Index value of the internal React Modal component
   * implemented by ReactImageLightbox.
   * Default: 1099.
   */
  zIndex: PropTypes.number,
  /** `react-image-lightbox` prop. */
  mainSrc: PropTypes.string.isRequired,
  /** `react-image-lightbox` prop. */
  nextSrc: PropTypes.string,
  /** `react-image-lightbox` prop. */
  prevSrc: PropTypes.string,
  /** `react-image-lightbox` prop. */
  mainSrcThumbnail: PropTypes.string,
  /** `react-image-lightbox` prop. */
  prevSrcThumbnail: PropTypes.string,
  /** `react-image-lightbox` prop. */
  nextSrcThumbnail: PropTypes.string,
  /** `react-image-lightbox` prop. */
  onCloseRequest: PropTypes.func.isRequired,
  /** `react-image-lightbox` prop. */
  onMovePrevRequest: PropTypes.func,
  /** `react-image-lightbox` prop. */
  onMoveNextRequest: PropTypes.func,
  /** `react-image-lightbox` prop. */
  onImageLoad: PropTypes.func,
  /** `react-image-lightbox` prop. */
  onImageLoadError: PropTypes.func,
  /** `react-image-lightbox` prop. */
  imageLoadErrorMessage: PropTypes.node,
  /** `react-image-lightbox` prop. */
  onAfterOpen: PropTypes.func,
  /** `react-image-lightbox` prop. */
  discourageDownloads: PropTypes.bool,
  /** `react-image-lightbox` prop. */
  animationDisabled: PropTypes.bool,
  /** `react-image-lightbox` prop. */
  animationOnKeyInput: PropTypes.bool,
  /** `react-image-lightbox` prop. */
  animationDuration: PropTypes.number,
  /** `react-image-lightbox` prop. */
  keyRepeatLimit: PropTypes.number,
  /** `react-image-lightbox` prop. */
  keyRepeatKeyupBonus: PropTypes.number,
  /** `react-image-lightbox` prop. */
  imageTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** `react-image-lightbox` prop. */
  imageCaption: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** `react-image-lightbox` prop. */
  imageCrossOrigin: PropTypes.string,
  /** `react-image-lightbox` prop. */
  toolbarButtons: PropTypes.node,
  /** `react-image-lightbox` prop. */
  reactModalStyle: PropTypes.any,
  /** `react-image-lightbox` prop. */
  reactModalProps: PropTypes.any,
  /** `react-image-lightbox` prop. */
  imagePadding: PropTypes.number,
  /** `react-image-lightbox` prop. */
  clickOutsideToClose: PropTypes.bool,
  /** `react-image-lightbox` prop. */
  enableZoom: PropTypes.bool,
  /** `react-image-lightbox` prop. */
  wrapperClassName: PropTypes.string,
  /** `react-image-lightbox` prop. */
  nextLabel: PropTypes.string,
  /** `react-image-lightbox` prop. */
  prevLabel: PropTypes.string,
  /** `react-image-lightbox` prop. */
  zoomInLabel: PropTypes.string,
  /** `react-image-lightbox` prop. */
  zoomOutLabel: PropTypes.string,
  /** `react-image-lightbox` prop. */
  closeLabel: PropTypes.string
};

Lightbox.defaultProps = {
  reactModalStyle: {},
  wrapperClassName: '',
  zIndex: DEFAULT_Z_INDEX
};

export default Lightbox;
