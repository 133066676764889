import React, {useState} from 'react'
import { AdminCheckbox, ConfirmModal } from '@project/components'
import LeftColumn from '../../components/TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from '../../components/TwoColumnContent/RightColumn/RightColumn'
import TitleDescription from '../../components/TitleDescription/TitleDescription'
import TwoColumnContent from '../../components/TwoColumnContent/TwoColumnContent'
import { useTranslations } from '../../utils/hooks'
import styles from './CompanyTenantPortalTab.module.css'
import PropTypes from 'prop-types'
import axios from '../../utils/axios'

const CompanyTenantPortalTab = ({company, setErrors, setCompany}) => {
  const [t] = useTranslations()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const alreadyTenant = t('Company.portal.true')
  const notATenant = t('Company.portal.false')

  const confirmDeleteHandler = async () => {
    try {
      const result = await axios.post('/tenant/onboarding', { company: company.name });
      if (result.status === 200) {
        const newCompany = Object.assign(company, {isTenant: true})
        setCompany(newCompany);
      }
    }
    catch (e) {
      setErrors(e.message)
    }
    finally {
      setShowConfirmModal(false)
    }
  }

  const updateTenantPortal = (isTenant) => {
    if (!isTenant) return setErrors(t('Company.portal.alreadyError'))
    setShowConfirmModal(true)
  }

  return (
    <TwoColumnContent>
      <LeftColumn>
        <TitleDescription
          title={t('Company.portal.title')}
          description={company?.isTenant ? alreadyTenant : notATenant}
          style={{ maxWidth: 'none' }}
        />
      </LeftColumn>
      <RightColumn boxShadow={false} border={false}>
        <div className='row'>
          <div className="col-sm-12 d-flex pl-3 ml-2">
            <AdminCheckbox
              id="company_is_tenant_portal"
              readOnly={company?.isTenant}
              checked={company?.isTenant}
              onChange={(e) => {updateTenantPortal(e.target.checked)}}
              customstyle={`${styles.checkbox} mt-n4`} />
          </div>
        </div>
      </RightColumn>

      <ConfirmModal
        show={showConfirmModal}
        title={t('Company.portal.title')}
        onConfirm={confirmDeleteHandler}
        onHide={() => setShowConfirmModal(false)}
        cancelButtonText={t('ui.cancel')}
        confirmButtonText={t('ui.ok')}>
        {t('Company.portal.confirmation')} {company.name}???
        <br /><br />
        {t('Company.portal.canTakeAWhile')}
      </ConfirmModal>
    </TwoColumnContent>
  )
}

CompanyTenantPortalTab.propTypes = {
  /** company object  */
  company: PropTypes.object.isRequired,
  /** a function to call with error messages. */
  setErrors: PropTypes.func.isRequired
}

export default CompanyTenantPortalTab
