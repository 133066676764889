import React from 'react';

const VendorsBold = () => {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9903 11.8641C12.6117 11.4466 12.1554 11.2427 11.5729 11.2427H10.3454L9.53962 10.4661C8.94739 10.9515 8.22895 11.2331 7.45222 11.2331C6.72406 11.2331 6.03474 10.9806 5.47164 10.5437L4.75316 11.2331H3.47573C2.82525 11.2331 2.3204 11.4564 1.92234 11.9127C0.767 13.2525 0.961174 16.1166 1.04855 17.0001H13.9806C14.0486 16.1068 14.1651 13.1748 12.9903 11.8641Z" fill="currentColor" />
      <path d="M4.08708 5.78638C3.85407 5.98055 3.69873 6.29123 3.69873 6.65045C3.69873 7.18443 4.04824 7.62132 4.49485 7.69899C4.59193 8.50482 4.92203 9.21355 5.41717 9.73783C5.52394 9.84462 5.63073 9.95142 5.74724 10.0388C5.8929 10.1553 6.04821 10.2524 6.21326 10.3398C6.58219 10.5339 6.98992 10.6407 7.42685 10.6407C7.86374 10.6407 8.27154 10.5339 8.64044 10.3398C8.80548 10.2524 8.96082 10.1553 9.10646 10.0388C9.22299 9.95142 9.32976 9.84462 9.43655 9.73783C9.92199 9.21359 10.2618 8.51453 10.3492 7.7087C10.3589 7.7087 10.3686 7.7087 10.3783 7.7087C10.8929 7.7087 11.3006 7.23298 11.3006 6.65045C11.3006 6.29123 11.1453 5.98055 10.9123 5.78638H4.08708Z" fill="currentColor" />
      <path d="M10.9742 3.74773C10.6732 2.74773 9.87711 1.72832 8.8674 1.20404V3.48559V3.79627C8.8674 3.81569 8.8674 3.8254 8.8674 3.83511C8.85769 3.9419 8.83828 4.03899 8.78976 4.13608C8.66352 4.3788 8.41109 4.55355 8.11986 4.55355H7.96452H6.89656C6.62472 4.55355 6.38197 4.40792 6.25579 4.19433C6.19754 4.10695 6.16841 4.0099 6.14899 3.90307C6.13928 3.86423 6.13928 3.83511 6.13928 3.79627V3.52443V1.19434C5.12958 1.72832 4.33346 2.74773 4.03249 3.74773L3.96453 3.96132H3.41113V5.17491H4.02278H4.87715H5.23637H9.91601H10.2655H11.5886V5.11666V3.96132H11.0422L10.9742 3.74773Z" fill="currentColor" />
      <path d="M6.89284 4.25242H8.10643C8.28119 4.25242 8.42682 4.15533 8.50449 4.0097C8.54333 3.94174 8.56275 3.87378 8.56275 3.79611V3.70873V3.34951V1.05825V0.728154V0.601941C8.56275 0.524271 8.54333 0.45631 8.52391 0.388349C8.43657 0.165048 8.21326 0 7.96083 0H6.89284C6.65983 0 6.46565 0.174757 6.44623 0.398057C6.44623 0.417475 6.43652 0.436892 6.43652 0.45631V0.728154V1.05825V3.38834V3.75727V3.79611C6.43652 3.8932 6.46565 3.98057 6.51419 4.05824C6.6016 4.17475 6.7375 4.25242 6.89284 4.25242Z" fill="currentColor" />
    </svg>
  );
}

export default VendorsBold;