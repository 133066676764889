/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import TitleDescription from './../../components/TitleDescription/TitleDescription';
import { AdminSelect, Alerts, ConfirmModal, Table } from '@project/components';
import { Spinner } from 'react-bootstrap';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import { RootContext } from "../../hoc/RootContext/RootContext";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "../../utils/axios";
import { useIntl } from 'react-intl';
import classes from './EditVendor.module.css';
import { FaPhoneAlt, FaEnvelope, FaUser } from 'react-icons/fa';
import { formatPhone, get } from './../../utils/utils';
import { getImageUrl } from '@project/components/src/utils/image-location-utils'
import DefaultLeasingImage from "../../assets/images/unknown-unit.png";
import DefaultCompanyLogo from "../../assets/images/id-plans-logo-dark.png";
import Title from '../../components/Title/Title';

const EditVendor = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { username } = useParams();

  const navigate = useNavigate();

  const { smallMobileView, mobileView, xLargeView, currentUser } = useContext(RootContext);

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [displayCompany, setDisplayCompany] = useState(null)
  const [vendor, setVendor] = useState({});
  const [vendorCompany, setVendorCompany] = useState({});
  const [properties, setProperties] = useState([]);
  const [acls, setAcls] = useState([]);
  const [sections, setSections] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [confirmingNoPerms, setConfirmingNoPerms] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);

  useEffect(() => {

    const fetch = async () => {
      try {

        const result = await axios.get(`/sections`);
        if (result.status === 200 && result.status === 200) {
          setSections(result.data.data);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }

    };

    fetch();

  }, []);

  useEffect(() => {

    if (currentUser) {
      setCompany(currentUser.company);
      setDisplayCompany(currentUser.companyDisplayName)
    }

    if (currentUser && currentUser.displayCompanies.length > 1) {
      let options = currentUser.displayCompanies.map(_ => ({ label: _.displayName, value: _.name }));
      setCompanyOptions(options);
    }

  }, [currentUser]);

  useEffect(() => {

    const fetch = async () => {
      try {
        setProperties([]);
        setSelectedProperties([]);
        setLoading(true);
        setErrors({});

        let properties = await axios.get(`/properties/${company}?status=active`);

        if (properties.status === 200) {

          let formattedProperties = properties.data.data.map((p) => {
            if (!p.displayName) p.displayName = p.name;
            p.integrationIds = (p.integrations || []).map(_ => _.integrationId).join('');
            p.sections = ['all']
            return p;
          }).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);

          setProperties(formattedProperties);

          setProperties(formattedProperties.filter(_ => _.guid));

          const result = await axios.get(`/acls/?username=${encodeURIComponent(username)}&company=${encodeURIComponent(company)}&filter=false`);
          setAcls(result.data.rows);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        if (!window.activeRequests) setLoading(false);
      }

    };

    if (company) {
      fetch();
    }

  }, [company]);

  useEffect(() => {

    // get vendor info details and vendor company details
    const fetch = async () => {
      setLoading(true);
      setErrors({});

      try {

        const encodedCompany = encodeURIComponent(company)

        // these have to run in order so we have
        // vendor company for next api call
        let result = await axios.get(`/vendors/${username}?company=${encodedCompany}`);
        if (result.status === 200) {
          setVendor(result.data.record);
          if (result.data.record.vendorCompanyInformation) {
            setVendorCompany(result.data.record.vendorCompanyInformation)
          }
          else {
            // for backwards compatibility with previously created vendor users
            const tmpCompany = {
              company: result.data.record.company,
              address1: '',
              city: '',
              state: '',
              postal: ''
            }
            setVendorCompany(tmpCompany)
          }
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        if (!window.activeRequests) setLoading(false);
      }

    };

    // get acls, we separate these so they run simultaneously
    const fetchAcls = async () => {
      setLoading(true);
      setErrors([]);

      try {
        let result = await axios.get(`/acls/?username=${encodeURIComponent(username)}&company=${encodeURIComponent(company)}&filter=false`);
        if (result.status === 200) setAcls(result.data.rows);

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        if (!window.activeRequests) setLoading(false);
      }

    };

    if (username && company && !vendor.username) fetch();
    if (username && company && acls.length < 1) fetchAcls();

  }, [username, company]);

  useEffect(() => {

    const applyAclsToProperties = async () => {
      setLoading(true);
      setErrors([]);

      try {

        const companyAcl = acls.find(_ => _.company === company && !_.property);

        const tmpProperties = [];

        properties.forEach((property) => {

          const propertyAcl = acls.find((_) => {
            return _.company === company &&
              (_.property === property.name || _.property === property.displayName) &&
              !_.section;
          });

          const sectionAcls = acls.filter((_) => {
            return _.company === company &&
              (_.property === property.name || _.property === property.displayName) &&
              _.section
          });

          // now we have all the property and section acls
          // we have to iterate both and activate/inactive all
          // applicable properties and sections within the property
          // to turn the right ones on/off
          property.sections = [];

          // first no section acls
          if (sectionAcls.length < 1 &&
            ((propertyAcl && propertyAcl.status === 'active') || (!propertyAcl && companyAcl && companyAcl.status === 'active'))) {
            property.sections.push('all');
          }

          const hasall = property.sections.includes('all');

          // we iterate all sections because not
          // all sectionsAcls section need to be there
          sections.forEach((section) => {

            const sectionAcl = sectionAcls.find(_ => _.section === section.id);

            // no section, no property (parent) so check grandparent company,
            //             // and 'all' not already there
            if (!sectionAcl && !propertyAcl && companyAcl && companyAcl.status === 'active' && !hasall) {
              property.sections.push(section.id);
            }

            // no section acl so check parent property,
            // and 'all' not already there
            if (!sectionAcl && propertyAcl && propertyAcl.status === 'active' && !hasall) {
              property.sections.push(section.id);
            }
            // active section acl, who cares about any parents,
            //             // and 'all' not already there
            if (sectionAcl && sectionAcl.status === 'active' && !hasall) {
              property.sections.push(section.id);
            }

          });

          // active company acl so just make sure property not inactive
          if (companyAcl && companyAcl.status === 'active' && !propertyAcl) tmpProperties.push(property);

          // active property acl so definitely add it
          if (propertyAcl && propertyAcl.status === 'active' && sectionAcls.length < 1) tmpProperties.push(property);

          // no property or company acl or both inactive,
          // check for any active children sections
          const activeSections = sectionAcls.filter(_ => _.status === 'active');
          if (activeSections.length > 0) tmpProperties.push(property);

        });

        setSelectedProperties(tmpProperties);

      } catch (e) {
        setErrors(e.message);
      }
      finally {
        if (!window.activeRequests) setLoading(false);
      }

    };

    if (acls.length && properties.length) applyAclsToProperties();

  }, [acls, setProperties, setSelectedProperties]);

  const selectSection = (section, property) => {
    if (section === 'all') {
      property.sections = ['all'];
    } else {
      property.sections = (property.sections || []).filter(_ => _ !== 'all');
      property.sections.push(section);
    }
    setProperties([...properties]);
  }

  const unselectSection = (section, property) => {
    property.sections = (property.sections || []).filter(_ => _ !== section);

    setProperties([...properties]);
  }

  const preSaveVendor = async () => {

    setLoading(false);
    setErrors([]);

    if (!vendor || !properties.length) return setErrors('Please wait for the vendor information to fully load.');

    try {
      setLoading(true);

      // set property roles
      const access = selectedProperties
        .map((p) => {
          return {
            name: p.name,
            sections: p.sections
          };
        });

      if (access.length > 0) return await saveVendor();

      setConfirmingNoPerms(true);

    } catch (e) {
      setErrors(e.message);
    }

  };

  const saveVendor = async () => {

    setConfirmingNoPerms(false);

    try {

      // set property roles
      const access = selectedProperties
        .map((p) => {
          return {
            name: p.name,
            sections: p.sections
          };
        });

      let postobj = {
        attributes: {
          company: company,
          access: access
        }
      };

      const result = await axios.put(`/vendors/${username}`, postobj);
      if (result.status === 200) {
        navigate(`/vendors`);
      }
      else {
        setErrors(`${t('error.saveVendor')}`);
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.saveVendor')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }
    finally {
      setLoading(false);
    }

  };

  const deleteVendor = async () => {

    try {

      const result = await axios.delete(`/vendors/${username}`, { data: { company } });
      if (result.status === 200) {
        navigate(`/vendors`);
      }
      else {
        setErrors(`${t('error.deleteVendor')}`);
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.deleteVendor')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }
    finally {
      setConfirmingDelete(false);
      setLoading(false);
    }

  };

  const selectedPropertiesHandler = (properties) => {
    properties.forEach(_ => {
      if (!_.sections.length) _.sections = ['all'];
    })
    setSelectedProperties(properties);
  }

  const getCompanyLogo = () => {
    return get(vendorCompany, 'logo.standard') || get(vendorCompany, 'logo.thumbnail') || get(vendorCompany, 'logo.original') || DefaultCompanyLogo;
  }

  // security
  if (!currentUser || !currentUser.companyAdmin || !username) {
    return <PageContentContainer><h3 style={{ "color": "#990000" }}> {t('error.notAuthorized')}</h3></PageContentContainer>
  }

  properties.forEach(property => {
    property.leasing_image = getImageUrl(property, ['leasing_image.thumbnail.S', 'leasing_image'], DefaultLeasingImage, 'leasing_image')
  })

  let sectionOptions = sections.sort((a, b) => a - b).map(_ => ({ value: _.id, label: _.section_name }));

  sectionOptions = [{ value: 'all', label: t('ui.all') }, ...sectionOptions]

  let selectedPropertiesKeys = selectedProperties.map(_ => _.guid);

  let unselectedProperties = [];
  properties.forEach(_ => { if (!selectedPropertiesKeys.includes(_.guid)) unselectedProperties.push(_.guid) });

  return (
    <>
      <PageContentContainer>
        <Title title={t('EditVendor.mainTitle')} backRoute={'/vendors'}
          button={{ click: preSaveVendor, label: t('EditVendor.saveVendor') }}
          secondaryButton={{ click: () => setConfirmingDelete(true), label: t('EditVendor.deleteVendor') }}
        />

        {
          errors.length > 0 ?
            <div style={{ "marginTop": "20px" }}><Alerts messages={errors} close={() => setErrors([])} /></div>
            : null
        }

        {/* vendor/company details */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditVendor.information.title')}
              description={t('EditVendor.information.description')} />
          </LeftColumn>
          <RightColumn padded={true}>
            {!loading ?
              <>
                <div className={classes.Company}>
                  <div className={classes.CompanyLogo}>
                    <img alt={vendorCompany.company} src={getCompanyLogo()} />
                  </div>
                  <div className={classes.CompanyDetails}>
                    <h3>{vendorCompany.company}</h3>
                    {vendorCompany.address1 && `${vendorCompany.address1} ${vendorCompany.city}, ${vendorCompany.state} ${vendorCompany.postal}`}
                  </div>
                </div>
                <div className={classes.ColumnSection}>
                  <div className="d-flex align-items-end"><div><FaPhoneAlt className={classes.DetailsIcon} /></div><div><h6 className="mb-0">Phone</h6></div></div>
                  <div className={classes.DetailsText}>{formatPhone(vendor.phone)}</div>
                </div>
                <div className={classes.ColumnSection}>
                  <div className="d-flex align-items-end"><div><FaEnvelope className={classes.DetailsIcon} /></div><div><h6 className="mb-0">Email</h6></div></div>
                  <div className={classes.DetailsText}>{vendor.email}</div>
                </div>
                <div className={`${classes.ColumnSection} border-0 pb-0`}>
                  <div className="d-flex align-items-end"><div><FaUser className={classes.DetailsIcon} /></div><div><h6 className="mb-0">Name</h6></div></div>
                  <div className={classes.DetailsText}>{vendor.fname} {vendor.lname}</div>
                </div>
              </> :
              <div className="w-100 d-flex justify-content-center"><Spinner animation="grow"
                className={"SpinnerMedum"}
                variant="primary" /></div>}
          </RightColumn>
        </TwoColumnContent>

        {/* multi home company list */}
        {
          companyOptions.length > 0 ?
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('EditVendor.company.title')}
                  description={intl.formatMessage({ id: 'EditVendor.company.description' }, { company })}
                />
              </LeftColumn>
              <RightColumn>
                <AdminSelect
                  options={companyOptions}
                  value={{ label: displayCompany, value: company }}
                  onChange={(c) => {
                    setCompany(c.value)
                    setDisplayCompany(c.label)
                  }}
                  isLoading={false}
                  propStyles={classes.adminSelect} />
              </RightColumn>
            </TwoColumnContent>
            : null
        }

        {/* PROPERTY ROLES */}

        <TitleDescription
          title={t('EditVendor.propertyRole.title')}
          description={t('EditVendor.propertyRole.details')}
          style={{ maxWidth: '100%' }} />

        <Table
          idField={'guid'}
          searchFilters={['name', 'displayName', 'address1', 'market', 'state', 'integrationIds']}
          itemsPerPage={8}
          fields={[
            { id: 'displayName', label: t('term.property'), type: 'details', options: !smallMobileView ? { photo: { key: 'leasing_image', objectFit: 'cover' } } : {} },
            { id: 'address1', label: t('term.location'), type: 'details', options: { concatValues: { keys: ['city', 'state'], separator: ', ' } } },
            {
              id: 'sections', label: 'Sections', dropdownLabel: 'Section permissions', type: 'dropdown', select: selectSection, unselect: unselectSection, selectOptions: sectionOptions
            }
          ]}
          showFields={
            (smallMobileView && ['displayName', 'sections']) ||
            (mobileView && ['displayName', 'sections']) ||
            (!xLargeView && ['displayName', 'address1', 'sections'])
          }
          customWidths={(mobileView && ['65%', '35%']) || ['40%', '40%', '30%']}
          label={t('InviteVendor.propertyRole.label')}
          placeholder={t('InviteVendor.propertyRole.placeholder')}
          items={properties}
          checkSelectable={true}
          checkSelectedItems={selectedProperties}
          checkSelectAll={[
            { label: t('SearchTable.all'), filter: _ => true }
          ]}
          setCheckSelectedItems={selectedPropertiesHandler}
          disabled={unselectedProperties}
          isLoading={loading}
        />
      </PageContentContainer>

      {/* delete confirmation */}
      <ConfirmModal
        show={confirmingDelete}
        title={'Delete Vendor?'}
        onConfirm={deleteVendor}
        onHide={() => setConfirmingDelete(false)} >
        {t('EditVendor.removeVendorWarning')}
      </ConfirmModal>

      {/* no perms confirmation */}
      <ConfirmModal
        show={confirmingNoPerms}
        title={'Delete Vendor?'}
        onConfirm={() => { saveVendor() }}
        onHide={() => { setConfirmingNoPerms(false); setLoading(false) }} >
        {t('InviteVendor.propertyRole.noPermsWarning')}
      </ConfirmModal>
    </>
  );
};

export default EditVendor;
