import React from 'react';
import { Button } from 'react-bootstrap';

function BtnPrime({ title, onClick, className, variant, disabled }) {
  return (
    <Button variant={variant} className={'rounded-10 border btn-prime ' + className} onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  )
}

export default BtnPrime;