/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import TitleDescription from './../../components/TitleDescription/TitleDescription';
import { AdminInput, AdminSelect } from '@project/components';
import { Alerts, Spinner } from '@project/components';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import { RootContext } from "../../hoc/RootContext/RootContext";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "../../utils/axios";
import { isValidEmail } from "../../utils/utils";
import UsersPropertyRolesList from "../../components/UsersPropertyRolesList/UsersPropertyRolesList";
import { USER_TITLES as titleOptions, roleOptions } from '../../constants/index';
import { USER_STATUSES as statusOptions } from '../../constants/index';
import { USER_TITLE_LABELS } from '../../constants/index';
import { useIntl } from 'react-intl';
import Title from '../../components/Title/Title';
import styles from './EditTeamMember.module.css';
import { useFilteredProperties } from '../../utils/hooks';

const EditTeamMember = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const navigate = useNavigate();

  const { currentUser } = useContext(RootContext);
  const { username } = useParams();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [oldEmail, setOldEmail] = useState(null);

  const [isDirty, setIsDirty] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [globalAccess, setGlobalAccess] = useState(false);

  const [forceMfa, setForceMfa] = useState(false);
  const [properties, setProperties] = useState([]);
  const [, setAcls] = useState([]);
  const [companyRole, setCompanyRole] = useState(roleOptions[0])

  const [filter, setFilter] = useState('');

  const [allSelected, setAllSelected] = useState({
    viewer: false,
    editor: false,
    designer: false,
    "company-admin": false
  });

  const [companyLevelAcl, setCompanyLevelAcl] = useState(null)

  const [filteredProperties] = useFilteredProperties({filter, properties});

  /*
  * get the user info for user in path
  * */
  useEffect(() => {

    const fetch = async () => {

      try {
        setLoading(true);

        let result = await axios.get(`/users/${encodeURIComponent(username)}`);
        if (result.status === 200) {

          // default some values in case they dont exist on object
          if (!result.data.record.useMfa) result.data.record.useMfa = false;
          if (!result.data.record.companyAdmin) result.data.record.companyAdmin = false;
          if (!result.data.record.companyTitle) result.data.record.companyTitle = 'Property Manager';

          // temp setup for user.companytitle to
          // interact with react-select nicely - gets put
          // back to string value on save
          result.data.record.companyTitle = {
            label: USER_TITLE_LABELS[result.data.record.companyTitle] || result.data.record.companyTitle,
            value: result.data.record.companyTitle
          };

          // temp setup for user.status to
          // interact with react-select nicely - gets put
          // back to string value on save
          result.data.record.status = {
            label: (result.data.record.status === 'active' ? t('ui.active') : t('ui.disabled')),
            value: result.data.record.status
          };

          setUser(result.data.record);
          setOldEmail(result.data.record.email);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('ui.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('ui.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (username) {
      fetch();
    }

  }, [username]);

  useEffect(() => {
    if (companyRole && properties.length) {
      if (companyRole.value === 'company-admin') {
        setGlobalAccess(true)
        updateUserObject('companyAdmin', true)
      } else {
        updateUserObject('companyAdmin', false)
      }

      const updatedProperties = properties.map(p => ({
        ...p,
        roleId: companyRole.value
      }));
      setProperties(updatedProperties);
      selectAll(companyRole.value, true);
      setCompanyLevelAcl(prevState => ({...prevState, roleid: companyRole.value}))
    }
  }, [companyRole])

  useEffect(() => {
    if (companyLevelAcl) {
      setCompanyLevelAcl(prevState => ({
        ...prevState, 
        status: !globalAccess ? statusOptions[1].value : statusOptions[0].value
      }))
    }
  }, [globalAccess])

  /*
  * get the user's company info, property list,
  * and all of the user's acls
  * */
  useEffect(() => {

    const fetch = async () => {

      try {
        setLoading(true);

        let props;
        let result;
        let tmpAcls;

        result = await axios.get(`/acls?username=${encodeURIComponent(username)}&company=${encodeURIComponent(user.company)}&filter=false`);
        if (result.status === 200) {
          // used below to set roleid on properties
          tmpAcls = result.data.rows;
          setAcls(result.data.rows);

          const hasGlobalAccess = tmpAcls?.length === 1 && tmpAcls[0]?.company && !tmpAcls[0]?.property && !tmpAcls[0]?.section
          setGlobalAccess(hasGlobalAccess)
        }

        result = await axios.get(`/properties/${user.company}`);
        if (result.status === 200) {

          props = result.data.data.map((p) => {
            if (!p.displayName) p.displayName = p.name;
            return p;
          }).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);

          const companyAcl = tmpAcls.find((acl) => {
            return !acl.property && !acl.section;
          });
          setCompanyLevelAcl(companyAcl)

          // set current company role as existing in the ACL table as the default selected value in the Primary role dropdown
          const _companyRole = roleOptions.find(role => role.value === companyAcl.roleid)
          setCompanyRole(_companyRole ?? roleOptions[0])

          // set the role id for each property based on current acls
          props.forEach((p) => {
            // default to no role
            p.roleId = '';
            // find relevant acl, property exists use it
            // use company level if not exists
            const acl = tmpAcls.find((acl) => {
              return (acl.property === p.name || !acl.property === p.displayName) && acl.status === 'active';
            });

            if (acl) {
              p.roleId = acl.roleid;
            }
            else if (companyAcl && companyAcl.status === 'active') {
              p.roleId = companyAcl.roleid;
            }

          });

          setProperties(props);
        }

        result = await axios.get(`/companies/${user.company}`);
        if (result.status === 200) {
          setForceMfa(result.data.record.requireMfa);
        }

        setIsLoaded(true);

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (user && properties.length < 1) {
      fetch();
    }

  }, [user]);

  const onPropertyRoleChange = (event, index, roleId) => {
    if (isLoaded) setIsDirty(true);
    if (filteredProperties[index].roleId !== roleId) {
      filteredProperties[index].roleId = roleId;
    }
    else {
      filteredProperties[index].roleId = '';
    }
    setProperties([...properties]);
  };

  const selectAll = (roleId, all) => {
    if (isLoaded) setIsDirty(true);

    filteredProperties.forEach((p) => {
      p.roleId = ((all) ? (roleId) : (null));
    });
    setProperties([...properties]);

    let sel = { ...allSelected };
    Object.keys(sel).forEach((key) => {
      sel[key] = all ? key === roleId : false;
    });
    setAllSelected(sel);
  };

  const saveUser = async () => {
    setLoading(false);
    setErrors([]);

    try {
      setLoading(true);

      // validation
      if (!isValidEmail(user.email)) {
        setErrors([t('error.email.valid')]);
        return;
      }

      // 'email', 'fname', 'lname', 'useMfa', 'companyTitle', 'companyAdmin', "status"
      let postobj = {
        fname: user.fname,
        lname: user.lname,
        useMfa: forceMfa ? true : user.useMfa,
        companyTitle: user.companyTitle.value,
        companyAdmin: user.companyAdmin,
        status: user.status.value
      };

      // updating email pulls an exist check api side
      // before updating db so only send it if it changed
      if (oldEmail !== user.email) postobj.email = user.email;

      let result1 = await axios.put(`/users/${user.username}`, postobj);

      let result2;
      if (isDirty) {
        // filter the properties to only send back acl data
        // and then post as batch to new endpoint

        const newAcls = properties.map((prop) => {
          return {
            username: username,
            company: user.company,
            property: prop.name,
            roleid: prop?.roleId?.length > 0 ? prop.roleId : 'viewer',
            status: prop?.roleId?.length > 0 ? 'active' : 'inactive',
            objid: `${user.company}_${prop.name}`
          }
        });

        // Include company level ACL along with property ones for ease of filtering on backend
        postobj = {
          acls: [companyLevelAcl, ...newAcls],
          globalAccess
        };
        result2 = await axios.put(`/acls/batch-replace/${user.username}`, postobj);
      }

      if (result1.status === 200 && (!isDirty || result2.status === 200)) {
        navigate(`/users`);
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.saveUser')} (${e.response.data.errors[0].message})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }
    finally {
      setLoading(false);
    }
  };

  const updateUserObject = (key, val) => {
    const tmpUser = {...user};
    tmpUser[key] = val;

    setUser(tmpUser);
  };


  if (!currentUser || !currentUser.companyAdmin) {
    return <PageContentContainer><h3 style={{ "color": "#990000" }}>{t('error.notAuthorized')}</h3></PageContentContainer>
  }

  if (!user) return null;

  return (
    <>
      <PageContentContainer>
        <Title title={t('EditTeamMember.mainTitle')} backRoute={'/users'} button={{click: saveUser, label: t('EditTeamMember.updateMember')}} />

        {errors.length > 0 ?
          <div style={{ "marginTop": "20px" }}><Alerts messages={errors} close={() => setErrors([])} /></div>
          : null}

        {/* EMAIL */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.email.title')}
              description={t('EditTeamMember.email.details')} />
          </LeftColumn>
          <RightColumn padded={true}>
            <AdminInput
              customstyle={styles.input}
              label={t('EditTeamMember.email.label')}
              type="email"
              placeholder={t('EditTeamMember.email.placeholder')}
              value={user.email}
              onChange={e => updateUserObject('email', e.currentTarget.value)} />
          </RightColumn>
        </TwoColumnContent>

        {/* fname */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.firstName.title')}
              description={t('EditTeamMember.firstName.details')} />
          </LeftColumn>
          <RightColumn padded={true}>
            <AdminInput
              customstyle={styles.input}
              label={t('EditTeamMember.firstName.label')}
              type="text"
              placeholder={t('EditTeamMember.firstName.placeholder')}
              value={user.fname}
              onChange={e => updateUserObject('fname', e.currentTarget.value)} />
          </RightColumn>
        </TwoColumnContent>

        {/* lname */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.lastName.title')}
              description={t('EditTeamMember.lastName.details')} />
          </LeftColumn>
          <RightColumn padded={true}>
            <AdminInput
              customstyle={styles.input}
              label={t('EditTeamMember.lastName.label')}
              type="text"
              placeholder={t('EditTeamMember.lastName.placeholder')}
              value={user.lname}
              onChange={e => updateUserObject('lname', e.currentTarget.value)} />
          </RightColumn>
        </TwoColumnContent>

        {/* use mfa ? */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.mfa.title')}
              description={t('EditTeamMember.mfa.details')} />
          </LeftColumn>
          <RightColumn>
            <div className={`${styles.checkInput} ${styles.mfa} d-flex align-items-center`}>
              <label htmlFor="useMfa" className="m-0">{t('EditTeamMember.mfa.label')}</label>
              <input
                style={{ "marginLeft": "20px" }}
                id='useMfa'
                type='checkbox'
                checked={user.useMfa}
                disabled={forceMfa}
                onChange={(e) => updateUserObject('useMfa', e.currentTarget.checked)} />
            </div>
          </RightColumn>
        </TwoColumnContent>

        {/* COMPANY TITLE */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.companyTitle.title')}
              description={t('EditTeamMember.companyTitle.details')} />
          </LeftColumn>
          <RightColumn>
            <AdminSelect
              propStyles={styles.adminSelect}
              label={t('EditTeamMember.companyTitle.label')}
              defaultValue=""
              placeholder={t('EditTeamMember.companyTitle.placeholder')}
              options={titleOptions}
              value={user.companyTitle}
              onChange={(e) => updateUserObject('companyTitle', e)}
              isLoading={false} />
          </RightColumn>
        </TwoColumnContent>

        {/* USER STATUS */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.status.title')}
              description={t('EditTeamMember.status.details')} />
          </LeftColumn>
          <RightColumn>
            <AdminSelect
              propStyles={styles.adminSelect}
              label={t('EditTeamMember.status.label')}
              placeholder={t('EditTeamMember.status.placeholder')}
              options={statusOptions}
              value={user.status}
              onChange={(e) => updateUserObject('status', e)}
              isLoading={false} />
          </RightColumn>
        </TwoColumnContent>

        {/* COMPANY ROLE */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.role.title')}
              description={t('EditTeamMember.role.details')} />
          </LeftColumn>
          <RightColumn >
            <div>
              <AdminSelect
                propStyles={styles.adminSelect}
                label={t('EditTeamMember.role.label')}
                options={roleOptions}
                value={companyRole}
                onChange={(c) => setCompanyRole(c)}
                isLoading={false} />
              <div className="pt-2">
                {companyRole.desc}
              </div>
            </div>
          </RightColumn>
        </TwoColumnContent>

        {/* global? */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('InviteTeamMember.globalAccess.title')}
              description={t('InviteTeamMember.globalAccess.desc')} />
          </LeftColumn>
          <RightColumn >

            <div className={`${styles.checkInput} ${styles.mfa} d-flex align-items-center`}>
              <label htmlFor="has-global-access" className="m-0">{t('InviteTeamMember.globalAccess.label')}</label>
              <input
                style={{ "marginLeft": "20px" }}
                id={'has-global-access'}
                type='checkbox'
                checked={globalAccess}
                disabled={user.companyAdmin}
                onChange={(e) => {
                  setIsDirty(true)
                  setGlobalAccess(e.target.checked)
                } } />
            </div>
          </RightColumn>
        </TwoColumnContent>

        {/* PROPERTY ROLES */}
        <TwoColumnContent className="pb-4">
          <LeftColumn>
            <TitleDescription
              title={t('EditTeamMember.permissions.title')}
              description={t('EditTeamMember.permissions.details')} />
          </LeftColumn>
          <RightColumn padded={true}>
            {
              !globalAccess ?
                <div className="pl-2">
                  <AdminInput
                    label={t('EditTeamMember.permissions.label')}
                    type="text"
                    placeholder={t('EditTeamMember.permissions.placeholder')}
                    value={filter}
                    onChange={e => setFilter(e.target.value)} />

                  <UsersPropertyRolesList
                    properties={filteredProperties}
                    allSelectedViewer={allSelected.viewer}
                    allSelectedEditor={allSelected.editor}
                    allSelectedDesigner={allSelected.designer}
                    allSelectedAdmin={allSelected["company-admin"]}
                    allowAdmin={true}
                    onPropertyRoleChange={onPropertyRoleChange}
                    selectAll={selectAll}
                  />
                </div>
                :
                <div className="pl-2">{t('InviteTeamMember.globalAccess.disable')}</div>
            }
          </RightColumn>
        </TwoColumnContent>

      </PageContentContainer>
      {loading ?
        <Spinner />
        : null
      }
    </>
  );
};

export default EditTeamMember;
