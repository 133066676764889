import { componentsEn } from '@project/components';
export default {
  ...componentsEn,
  "currencyCode": "USD",

  /************************************************************
   * IDCloud Components
   * -----------------------------------
   * First section of id corresponds to component using the
   * translation
   ************************************************************/
  "Alerts.title": "Alerts",
  "Alerts.desc": "Active Alerts",
  "Alerts.filterPlaceholder": "Filter active alerts by property, type, field, or date.",
  "Alerts.property": "Property",
  "Alerts.stencilName": "Asset Type",
  "Alerts.fieldName": "Field",
  "Alerts.alertDate": "Alert Date",
  "Alerts.view": "View",

  "Amp.allProperties": "All properties",
  "Amp.monthToDate": "Month to date",
  "Amp.yearToDate": "Year to date",
  "Amp.prevMonth": "Previous month",
  "Amp.prev3Month": "Previous 3 months",
  "Amp.prev6Month": "Previous 6 months",
  "Amp.prev2Years": "Previous 2 years",

  "Amp.1Week": "1 Week",
  "Amp.2Week": "2 Weeks",
  "Amp.3Week": "3 Weeks",
  "Amp.1Month": "1 Month",
  "Amp.3Month": "3 Months",
  "Amp.6Month": "6 Months",
  "Amp.1Year": "1 Year",

  "AssignedInspections.placeholder": "Filter inspections by template name, property, etc.",
  "AssignedInspections.title": "Assigned Inspections",
  "AssignedInspections.unknownError": "There was an error retrieving assigned inspections.",
  "AssignedInspections.view": "View",
  "AssignedInspections.tabs.assigned": "Assigned Inspections",
  "AssignedInspections.tabs.properties": "Properties",
  "AssignedInspections.properties.inspections": "{count, plural, =0 {# inspections} one {# inspection} other {# inspections}}",
  "AssignedInspections.properties.placeholder": "Search properties by name or address",
  "AssignedInspections.inspections": "Inspections",
  "AssignedInspections.properties.goBack": "Back to properties",

  "AvailableReports.amp.title": "AMP Report",
  "AvailableReports.amp.description": "Water & Sewer reports for {company}",
  "AvailableReports.amp.linkTitle": "AMP Report",
  "AvailableReports.link": "View {report}",

  "Reports.Amp.title": "AMP Detail Report",
  "Reports.Amp.Facility": "Facility",
  "Reports.Amp.Tenant": "Tenant",
  "Reports.Amp.BillDate": "Bill Date",
  "Reports.Amp.TotalCharged": "Total Charged",
  "Reports.Amp.TotalCost": "Total Cost",
  "Reports.Amp.WaterCost": "Water Cost",
  "Reports.Amp.SewerCost": "Sewer Cost",
  "Reports.Amp.WasteCost": "Waste Cost",

  "Company.mainTitle": "Company Settings",
  "Company.header.title": "Select a company.",
  "Company.header.description": "Your account has access to administer more than 1 company. {companyDisplayName} is the currently active company.",
  "Company.companySettings.saved": "Company settings saved successfully",
  "Company.tab.generalSettings": "General Settings",
  "Company.tab.idDigital": "ID Digital",
  "Company.tab.rpm": "RPM",
  "Company.tab.theming": "Theming",
  "Company.theming.details": "Customize the theme of your company's pages.",
  "Company.theming.saveSettings": "Save Settings",
  "Company.theming.colors.title": "Company Theme Colors",
  "Company.theming.colors.description": "Set company theme colors. These will be used to customize your company's pages.",
  "Company.theming.primaryColor.label": "Primary Color",
  "Company.theming.secondaryColor.label": "Secondary Color",
  "Company.idDigitalSettings.spaceColor.label": "Sitemap Space Colors",
  "Company.idDigitalSettings.spaceColor.desc": "Fill & border color for space displayed in the sitemap",
  "Company.idDigitalSettings.spaceColor.fillLabel": "Fill",
  "Company.idDigitalSettings.spaceColor.borderLabel": "Border",
  "Company.idDigitalSettings.spaceBackground.label": "Sitemap Space Fill Color",
  "Company.idDigitalSettings.spaceBackground.desc": "Fill color for space displayed in the sitemap",
  "Company.idDigitalSettings.spaceBorder.label": "Sitemap Space Border Color",
  "Company.idDigitalSettings.spaceBorder.desc": "Border color for space displayed in the sitemap",
  "Company.idDigitalSettings.vacantSpaceBackground.label": "Vacant Sitemap Space Color",
  "Company.idDigitalSettings.vacantSpaceBackground.desc": "Fill color for vacant space displayed in the sitemap",
  "Company.idDigitalSettings.spaceText.label": "Sitemap Space Label Color",
  "Company.idDigitalSettings.spaceText.desc": "Text color displayed on space labels in the sitemap",
  "Company.idDigitalSettings.siteMapSpaceLabel.label": "Sitemap Space Label",
  "Company.idDigitalSettings.siteMapSpaceLabel.desc": "Choose which information will be displayed on spaces",
  "Company.idDigitalSettings.siteMapSpaceLabel.spaceNumber": "Space Number",
  "Company.idDigitalSettings.siteMapSpaceLabel.tenantName": "Tenant Name",
  "Company.idDigitalSettings.siteMapSpaceLabel.squareFootage": "Square Footage",
  "Company.idDigitalSettings.overrideID360SpaceColor.label": "Override ID360 Space Color",
  "Company.idDigitalSettings.overrideID360SpaceColor.desc": "Ignore ID360 status color on spaces",
  "Company.idDigitalSettings.showLandFillsInIddigital.label": "Landscape Fills",
  "Company.idDigitalSettings.showLandFillsInIddigital.desc": "Show landscape fills in IDDigital",
  "Company.idDigitalSettings.showLandFillsOnLeaseRadar.desc": "Show landscape fills on Lease Radar",
  "Company.error.bootstrapClass": "Found bootstrap class in custom header/footer css",
  "Company.error.invalidCSS": "Invalid css for custom header/footer",

  "CompanyGeneralTab.saveSettings": "Save Settings",
  "CompanyGeneralTab.generalSettings": "General",
  "CompanyGeneralTab.details": "Configure general settings for this company.",
  "CompanyGeneralTab.mfa.title": "Require MFA",
  "CompanyGeneralTab.mfa.label": "Require the company account to use MFA?",
  "CompanyGeneralTab.mfa.details": "Checking this box will require all users for {companyDisplayName} to perform multi-factor authentication when logging into ID Plans. MFA is only required every 30 days and when accessing ID Apps from new devices.",
  "CompanyGeneralTab.sso.title": "Require SSO",
  "CompanyGeneralTab.sso.label": "Require the company account to use SSO?",
  "CompanyGeneralTab.ssoUserFilter.title": "SSO User Filter",
  "CompanyGeneralTab.ssoUserFilter.label": "Filter users for SSO (ex. @example\\.com$)",
  "CompanyGeneralTab.ssoUserFilter.details": "The user's email must match the above regex to allow the use of SSO",
  "CompanyGeneralTab.sso.details": "Checking this box will require all users for {companyDisplayName} to perform sso authentication when logging into ID Plans.",
  "CompanyGeneralTab.logo.title": "Company Logo",
  "CompanyGeneralTab.logo.description": "This logo will appear on your company's pages.",
  "CompanyGeneralTab.logoSize.placeholder": "Select Logo Size",
  "CompanyGeneralTab.logoSize.title": "Logo Size",
  "CompanyGeneralTab.logoSize.description": "Select the size of the logo to be displayed on your company's pages.",
  "CompanyGeneralTab.logoSize.small": "Small (50px)",
  "CompanyGeneralTab.logoSize.regular": "Regular (100px)",
  "CompanyGeneralTab.logoSize.large": "Large (150px)",
  "CompanyGeneralTab.logoSize.larger": "Larger (175px)",
  "CompanyGeneralTab.logoSize.jumbo": "Jumbo (200px)",
  "CompanyGeneralTab.logoSize.largest": "Largest (250px)",
  "CompanyGeneralTab.primaryEmail": "Primary Email",
  "CompanyGeneralTab.propertyManagementEmail": "Property Management Email",
  "CompanyGeneralTab.leasingContactEmail": "Leasing Contact Email",
  "CompanyGeneralTab.primaryEmailDesc": "This is the official company email",
  "CompanyGeneralTab.propertyManagementEmailDesc": "Fallback email for property management",
  "CompanyGeneralTab.leasingContactEmailDesc": "Fallback email for leasing contact",
  "CompanyGeneralTab.IdpRecordId": "IDP Record ID",
  "CompanyGeneralTab.bypassValidation": "Bypass validation",
  "CompanyGeneralTab.IntegrationId": "Integration ID",
  "CompanyGeneralTab.enforceValidation": "Enforce validation",

  "CompanyIdDigitalTab.title": "IDDigital Settings",
  "CompanyIdDigitalTab.saveSettings": "Save Settings",
  "CompanyIdDigitalTab.details": "Configure settings for your company's IDDigital pages.",
  "CompanyIdDigitalTab.toggleTenant.title": "Toggle Left Tenant Roster",
  "CompanyIdDigitalTab.toggleTenant.description": "Toggle the tenant roster on the left side of the sitemap on the property detail page.",
  "CompanyIdDigitalTab.showContactForm.title": "Show Contact Form",
  "CompanyIdDigitalTab.showContactForm.description": "Enable the contact form on property detail page.",
  "CompanyIdDigitalTab.removeBackgroundShapeBorders.title": "Remove Background Shape borders",
  "CompanyIdDigitalTab.removeBackgroundShapeBorders.description": "Remove the border around the edge of the shapes drawn on the background layer",
  "CompanyIdDigitalTab.customMarker.title": "Custom Overview Map Marker",
  "CompanyIdDigitalTab.customMarker.description": "This image will appear as the marker for your company's properties on the overview map.",
  "CompanyIdDigitalTab.customHeader.title": "Customize Header",
  "CompanyIdDigitalTab.customHeader.description": "Add HTML here to customize your header bar in IDDigital.",
  "CompanyIdDigitalTab.customFooter.title": "Customize Footer",
  "CompanyIdDigitalTab.customFooter.description": "Add HTML here to customize your footer bar in IDDigital.",
  "CompanyIdDigitalTab.customFooter.css.description": "Add CSS here to customize your html above. Using tag selectors or bootstrap class selectors is not allowed here.",
  "CompanyIdDigitalTab.customFont.title": "Custom Font Select",
  "CompanyIdDigitalTab.customFont.description": "Custom font setting used for Overview and Property pages",
  "CompanyIdDigitalTab.customFont.preview": "Preview",
  "CompanyIdDigitalTab.customFont.selectFont": "Select Font",
  "CompanyIdDigitalTab.customFontOnSpaces.title": "Sitemap Space Font",
  "CompanyIdDigitalTab.customFontOnSpaces.description": "Custom font select used for IDDigital spaces",

  "CompanyIdInspectTab.details": "Configure settings for ID Inspect.",
  "CompanyIdInspectTab.InspectionArchival.title": "Enable automatic archival",
  "CompanyIdInspectTab.InspectionArchival.description": "Automatically archive inspections that haven't been updated in 90 days.",

  "CompanyRPMTab.disableRPM.title": "Disable RPM",
  "CompanyRPMTab.disableRPM.description": "Disable RPM access for this company.",

  "Company.rpm.configure": "Configure RPM access for this company.",

  "Company.portal.enable": "Enable Tenant Portal for this company.",
  "Company.portal.title": "Tenant Portal",
  "Company.portal.true": "This company has already been onboarded to IDT and cannot be disabled from these settings.",
  "Company.portal.false": "Enabling Tenant Portal for this company will initate the 'onboarding' process and is not reversible. The process will import the company and it's existing properties, contacts, spaces, and tenants.",
  "Company.portal.confirmation": "Are you sure you want to enable Tenant Portal for this company. This action is irreversible and will cause an object synchronization between RPM and IDT for ",
  "Company.portal.alreadyError": "This company is already onboarded.",
  "Company.portal.canTakeAWhile": "The onboarding process can take up to a few minutes to complete.",

  "COIUpload.instructions": "Please upload a file containing a Certificate of Insurance.",
  "COIUpload.acceptedFileFormats": "Accepted file formats are: {formats}",
  "COIUpload.fileFormatError": "Please select a file with a supported format.",

  "ContactsList.select": "Select",
  "ContactsList.selected": "Selected",
  "ContactsList.remove": "Remove",
  "ContactsList.name": "Name",
  "ContactsList.title": "Title",
  "ContactsList.phone": "Phone",
  "ContactsList.email": "Email",

  "Dashboard.propertyCount": "{count, plural, =0 {No Properties} one {1 Property} other {# Properties}}",
  "Dashboard.totalSqft": "Total Sqft",
  "Dashboard.publishedProfiles": "Published ID360 Profiles",
  "Dashboard.actualSqft": "Actual SQFT {footage}",
  "Dashboard.lastDays": "In the last 30 days",
  "Dashboard.recentProfiles": "These are your recent ID360 space profiles",
  "Dashboard.noProfiles": "There are no recent ID360 space profiles",
  "Dashboard.spaceProfile": "Space Profile",
  "Dashboard.publishDate": "Publish Date",
  "Dashboard.myProperties.label": "My Properties",
  "Dashboard.myProperties.placeholder": "Search by property or company",
  "Dashboard.spaceProfiles.label": "All Spaces",
  "Dashboard.spaceProfiles.placeholder": "Filter spaces by name or location",
  "Dashboard.profilesHeader": "ID 360 Status Tracker",
  "Dashboard.amp.title": "AMP Reports",
  "Dashboard.amp.details": "View your detailed program report. Track and manage property assets such as utility and waste management services.",
  "Dashboard.amp.revenue": "Monthly Revenue",
  "Dashboard.amp.savings": "Total Savings",
  "Dashboard.amp.totalCharged": "Total Charged",
  "Dashboard.amp.totalRecouped": "Total Recoup",
  "Dashboard.amp.totalCost": "Total Cost",
  "Dashboard.export": "Export All",
  "Dashboard.subscriptionsTracker.title": "RPM Status Tracker",
  "Dashboard.subscriptionsTracker.scopes.basic": "Basic",
  "Dashboard.subscriptionsTracker.scopes.dimensions": "Dimensions",
  "Dashboard.subscriptionsTracker.scopes.drainage": "Drainage",
  "Dashboard.subscriptionsTracker.scopes.dumpster": "Dumpster",
  "Dashboard.subscriptionsTracker.scopes.elec": "Electric",
  "Dashboard.subscriptionsTracker.scopes.er": "Emergency",
  "Dashboard.subscriptionsTracker.scopes.fire": "Fire",
  "Dashboard.subscriptionsTracker.scopes.gas": "Gas",
  "Dashboard.subscriptionsTracker.scopes.hvac": "HVAC",
  "Dashboard.subscriptionsTracker.scopes.idvision": "ID Vision",
  "Dashboard.subscriptionsTracker.scopes.irrig": "Irrigation",
  "Dashboard.subscriptionsTracker.scopes.land": "Landscape",
  "Dashboard.subscriptionsTracker.scopes.park": "Parking",
  "Dashboard.subscriptionsTracker.scopes.pavement": "Pavement",
  "Dashboard.subscriptionsTracker.scopes.pylon": "Pylon Sign",
  "Dashboard.subscriptionsTracker.scopes.roof": "Roofing",
  "Dashboard.subscriptionsTracker.scopes.sign": "Signage",
  "Dashboard.subscriptionsTracker.scopes.space": "Space",
  "Dashboard.subscriptionsTracker.scopes.water": "Water",
  "Dashboard.subscriptionsTracker.scopesTitle": "Scopes",
  "Dashboard.subscriptionsTracker.allScopes": "All Scopes",
  "Dashboard.subscriptionsTracker.filterPlaceholder": "Filter by property name",
  "Dashboard.subscriptionsTracker.filterByDate": "Date range",

  "EditTeamMember.breadcrumb": "Manage Team Members",
  "EditTeamMember.mainTitle": "Edit team member",
  "EditTeamMember.updateMember": "Update Member",
  "EditTeamMember.email.title": "Member email",
  "EditTeamMember.email.details": "Update the member's email address.",
  "EditTeamMember.email.placeholder": "Enter email",
  "EditTeamMember.email.label": "Email",
  "EditTeamMember.firstName.title": "Member first name",
  "EditTeamMember.firstName.details": "Update the member's first name.",
  "EditTeamMember.firstName.placeholder": "Enter first name",
  "EditTeamMember.firstName.label": "First Name",
  "EditTeamMember.lastName.title": "Member last name",
  "EditTeamMember.lastName.details": "Update the member's last name.",
  "EditTeamMember.lastName.placeholder": "Enter last name",
  "EditTeamMember.lastName.label": "Last Name",
  "EditTeamMember.mfa.title": "Multi-Factor Authentication",
  "EditTeamMember.mfa.details": "User will have multi-factor authentication enabled for their account.",
  "EditTeamMember.mfa.label": "Is the account protected with MFA?",
  "EditTeamMember.admin.title": "Company administrator",
  "EditTeamMember.admin.details": "Company administrators can add, edit, and delete team members.",
  "EditTeamMember.admin.checkbox": "Is the member a company administrator?",
  "EditTeamMember.admin.description": "Currently, the Team area of IDCloud is the only restricted area. As additional features become available, Company Admins could have expanded functionality.",
  "EditTeamMember.companyTitle.title": "Company title",
  "EditTeamMember.companyTitle.details": "The title of the user will be visible if selected as a contact for a property.",
  "EditTeamMember.companyTitle.placeholder": "Select Title",
  "EditTeamMember.companyTitle.label": "Company title",
  "EditTeamMember.status.title": "User status",
  "EditTeamMember.status.details": "The user's status.",
  "EditTeamMember.status.placeholder": "Select status",
  "EditTeamMember.status.label": "User status",
  "EditTeamMember.permissions.title": "Assign Properties Roles",
  "EditTeamMember.permissions.details": "New members can be assigned different roles per property. New members can be restricted from specific properties by assigning no role to the property.",
  "EditTeamMember.permissions.placeholder": "Enter text to filter by property name",
  "EditTeamMember.permissions.label": "Custom Property Permissions",
  "EditTeamMember.role.title": "Primary role",
  "EditTeamMember.role.details": "The primary role is used as the default role for user access to properties. This role is applied when individual permissions are not specified below and to properties added later on.",
  "EditTeamMember.role.label": "Primary user role",

  "EditVendor.breadcrumb": "Manage Vendors",
  "EditVendor.mainTitle": "Manage Vendor",
  "EditVendor.saveVendor": "Save",
  "EditVendor.deleteVendor": "Delete",
  "EditVendor.information.title": "Vendor Information",
  "EditVendor.information.description": "Only the vendor who owns or manages this company account can edit profile details.",
  "EditVendor.company.title": "Company",
  "EditVendor.company.description": "Choose the companies you want the vendor to be associated with.",
  "EditVendor.propertyRole.title": "Property and space access",
  "EditVendor.propertyRole.details": "Edit the vendor's access to properties and spaces.",
  "EditVendor.propertyRole.placeholder": "Enter text to filter by property name",
  "EditVendor.removeVendorWarning": "Are you sure you want to remove this vendor?",

  "Export.profiles": "Exporting profile data, this may take a few moments",
  "Export.complete": "Export complete",

  "Inspections.title": "Inspections",

  "Invitations.mainTitle": "Register ID Cloud Account",
  "Invitations.complete": "Complete Registration",
  "Invitations.title": "User / Account information",
  "Invitations.email": "Email",
  "Invitations.email.placeholder": "Enter email",
  "Invitations.username": "Username",
  "Invitations.username.placeholder": "Enter username",
  "Invitations.password": "Password",
  "Invitations.password.placeholder": "Enter password",
  "Invitations.confirmPassword": "Confirm password",
  "Invitations.firstName": "First Name",
  "Invitations.firstName.placeholder": "Enter first name",
  "Invitations.lastName": "Last Name",
  "Invitations.lastName.placeholder": "Enter last name",
  "Invitations.phone": "Phone",
  "Invitations.phone.placeholder": "Enter phone",
  "Invitations.mfa": "Protect your account with multi-factor authentication?",
  "Invitations.privacyPolicy": "Do you agree to the privacy policy?",
  "Invitations.sent.user": "New team member successfully invited",
  "Invitations.sent.vendor": "New vendor successfully invited",
  "Invitations.sent.existingVendor": "The vendor already exists. Access to the specified properties has been given to the existing vendor and a welcome email has been sent.",

  "InviteTeamMember.globalAccess.title": "Global Property Access",
  "InviteTeamMember.globalAccess.desc": "Will allow the user to access all current and future properties",
  "InviteTeamMember.globalAccess.label": "Does the user have Global Access to all properties",
  "InviteTeamMember.globalAccess.disable": "Disable Global Access to assign property specific access.",

  "InviteTeamMember.breadcrumb": "Manage Team Members",
  "InviteTeamMember.mainTitle": "Invite team member",
  "InviteTeamMember.inviteMember": "Send invite",
  "InviteTeamMember.newMember.title": "New member company.",
  "InviteTeamMember.newMember.description": "Your account has access to administer more than 1 company. {displayCompany} is the current active company.",
  "InviteTeamMember.email.title": "New member email",
  "InviteTeamMember.email.description": "We will email the new member with a link to complete the required registration information.",
  "InviteTeamMember.email.label": "Email",
  "InviteTeamMember.email.placeholder": "Enter email",
  "InviteTeamMember.mfa.title": "Multi-Factor Authentication",
  "InviteTeamMember.mfa.details": "User will have multi-factor authentication enabled for their account.",
  "InviteTeamMember.mfa": "Is the account protected with MFA?",
  "InviteTeamMember.mfa.force": "Company Settings require MFA for all users",
  "InviteTeamMember.admin.title": "Company administrator",
  "InviteTeamMember.admin.details": "Company administrators can add, edit, and delete team members.",
  "InviteTeamMember.admin": "Is the member a company administrator? ",
  "InviteTeamMember.admin.description": "Currently, the Team area of IDCloud is the only restricted area. As additional features become available, Company Admins could have expanded functionality.",
  "InviteTeamMember.companyTitle.title": "Company title",
  "InviteTeamMember.companyTitle.details": "The title of the user will be visible if selected as a contact for a property.",
  "InviteTeamMember.companyTitle.label": "Company title",
  "InviteTeamMember.companyTitle.placeholder": "Select Title",
  "InviteTeamMember.role.title": "Primary role",
  "InviteTeamMember.role.details": "The primary role is used as the default role for user access to properties. This role is applied when individual permissions are not specified below and to properties added later on.",
  "InviteTeamMember.role.label": "Primary user role",
  "InviteTeamMember.propertyRole.title": "Assign Properties Roles",
  "InviteTeamMember.propertyRole.details": "New members can be assigned different roles per property. New members can be restricted from specific properties by assigning no role to the property.",
  "InviteTeamMember.propertyRole.label": "Custom Property Permissions",
  "InviteTeamMember.propertyRole.placeholder": "Enter text to filter by property name",

  "InviteVendor.breadcrumb": "Manage Vendors",
  "InviteVendor.mainTitle": "Invite Vendor",
  "InviteVendor.inviteMember": "Send invite",
  "InviteVendor.newVendor.title": "Company access.",
  "InviteVendor.newVendor.description": "Choose the company you want the vendor to have access to.",
  "InviteVendor.email.title": "Vendor email",
  "InviteVendor.email.description": "Enter the email of the vendor you are inviting. If email already exists, manage on your vendor dashboard.",
  "InviteVendor.email.label": "Email",
  "InviteVendor.email.placeholder": "Enter email",
  "InviteVendor.name.title": "Vendor name",
  "InviteVendor.name.description": "Enter the first and last name of the vendor you are inviting.",
  "InviteVendor.name.placeholder": "First-Name Last-Name",
  "InviteVendor.propertyRole.title": "Property and space access",
  "InviteVendor.propertyRole.details": "Edit the vendor's access to properties and spaces.",
  "InviteVendor.propertyRole.label": "Custom Property Permissions",
  "InviteVendor.propertyRole.placeholder": "Enter text to filter by property name or location",
  "InviteVendor.propertyRole.noPermsWarning": "You have not assigned any properties to this new vendor, are you sure you want to invite the vendor without access to any properties?",

  "LeaseRadar.title": "Lease Radar",
  "LeaseRadar.occupied": "Occupied",
  "LeaseRadar.expiring": "Expiring",
  "LeaseRadar.vacant": "Vacant",
  "LeaseRadar.filterSpaces.placeholder": "Filter spaces by name or location",

  "LeaseRadarDetails.expiration": "Leases Expiring",
  "LeaseRadarDetails.vacant": "Vacant Spaces",
  "LeaseRadarDetails.occupiedSpaces": "Occupied Spaces",
  "LeaseRadarDetails.360": "ID360's",

  "Login.redirect.loading": "Loading ID Plans..",
  "LoginVendor.welcome": "Welcome, Vendor! Please verify your identity completing this form:",

  "LostPassword.label": "Enter the email address associated to your account.",
  "LostPassword.placeholder": "Email address",
  "LostPassword.send": "Send Reset Request",

  "MfaForm.select": "Select an MFA Code delivery method",
  "MfaForm.email": "Email",
  "MfaForm.phone": "Phone",
  "MfaForm.title": "Multi-Factor Authentication",
  "MfaForm.details": "Your account requires multi-factor authentication. Please select the method you would like your authentication code sent to you.",
  "MfaForm.request": "Request Code",
  "MfaForm.enterCode": "Enter the 6 digit code that was sent to your {device}.",
  "MfaForm.placeholder": "MFA Code",
  "MfaForm.dontAskAgain": "Don't ask again for 30 days on this device.",
  "MfaForm.verify": "Verify",

  "MenuDrawer.dashboard": "Dashboard",
  "MenuDrawer.company": "Company",
  "MenuDrawer.manageTeamMembers": "Manage Team Members",
  "MenuDrawer.settings": "Settings",
  "MenuDrawer.userSettings": "User Profile",
  "MenuDrawer.vendorSettings": "Vendor Profile",
  "MenuDrawer.propertySettings": "Property Settings",
  "MenuDrawer.reports": "Reports",
  "MenuDrawer.leaseRadar": "Lease Radar",
  "MenuDrawer.collapse": "Collapse menu",
  "MenuDrawer.team": "Team",
  "MenuDrawer.menu": "Menu",
  "MenuDrawer.management": "Management",

  "MenuDropdown.users": "Users",
  "MenuDropdown.vendors": "Vendors",
  "MenuDropdown.company": "Company",
  "MenuDropdown.property": "Property",

  "OrderProfile.newOrderProfile": "Order ID360",
  "OrderProfile.OrderModal.title": "Order ID360",
  "OrderProfile.selectProperty.warning": "Please Select Property",
  "OrderProfile.OrderModal.UnitSelection.title": "Unit Selection",
  "OrderProfile.OrderModal.UnitSelection.propertyGLA": "Property GLA",
  "OrderProfile.OrderModal.ShoppingCenter.propertyName": "Property Name",
  "OrderProfile.OrderModal.ShoppingCenter.unitNo": "Unit #",

  "OrderProfile.OrderModal.PropertyManagerDetails.title": "Property Manager Details",
  "OrderProfile.OrderModal.PropertyManagerDetails.propertyManager": "Property Manager",
  "OrderProfile.OrderModal.PropertyManagerDetails.cellPhoneNo": "Cell Phone#",
  "OrderProfile.OrderModal.PropertyManagerDetails.email": "Email",

  "OrderProfile.OrderModal.AccessInformation.title": "Access Information",
  "OrderProfile.OrderModal.AccessInformation.contactName": "Onsite Contact Name",
  "OrderProfile.OrderModal.AccessInformation.contactPhone": "Onsite Contact Phone #",
  "OrderProfile.OrderModal.AccessInformation.lockboxCode": "Lockbox Code",
  "OrderProfile.OrderModal.AccessInformation.lockboxLocation": "Lockbox Location",

  "OrderProfile.OrderModal.SpaceInformation.title": "Space Information",
  "OrderProfile.OrderModal.SpaceInformation.isSpaceVacant": "Space Currently Vacant?",
  "OrderProfile.OrderModal.SpaceInformation.areFixturesRemoved": "Are the fixtures removed?",
  "OrderProfile.OrderModal.SpaceInformation.isWaterOn": "Is the water on for the space?",
  "OrderProfile.OrderModal.SpaceInformation.isRoofAccessAvailable": "Is roof access available for the space?",
  "OrderProfile.OrderModal.SpaceInformation.location": "If roof access is available, location",

  "OrderProfile.OrderModal.Others.title": "Others",
  "OrderProfile.OrderModal.Others.comments": "Comments",

  "PropertySelect.select": "Select Property",

  "MigrateProperty.title": "Migrate Property",
  "MigrateProperty.newPropertyDisplayName": "New Property Display Name",
  "MigrateProperty.migratePropertyRecord": "Migrate Property Record",
  "MigrateProperty.migratePageRecords": "Migrate Page Records",
  "MigrateProperty.migrateSpaceProfiles": "Migrate Space Profiles",
  "MigrateProperty.migrateSpaceRecords": "Migrate Space Records",
  "MigrateProperty.migrateAssetRecords": "Migrate Asset Records",
  "MigrateProperty.migrateTenantRecords": "Migrate Tenant Records",
  "MigrateProperty.migrateVisionRecords": "Migrate Vision Records",
  "MigrateProperty.migrateRolesRecords": "Migrate Roles Records",
  "MigrateProperty.beginMigration": "Begin Migration",

  "PropertySettings.mainTitle": "Property Settings",
  "PropertySettings.toast.save": "Property settings saved successfully",
  "PropertySettings.toast.pdf": "Generating PDF. May take up to a minute.",
  "PropertySettings.general.save": "Save Settings",
  "PropertyGeneralSettings.displayName": "Display Name",
  "PropertyGeneralSettings.logo": "Property Logo",

  "PropertySettingsTabs.general.title": "General Settings",
  "PropertyGeneralSettings.details": "Configure general settings for this property.",
  "PropertyGeneralSettings.multipleBuildings.label": "Multiple Buildings",
  "PropertyGeneralSettings.multipleBuildings.desc": "Does this property have multiple buildings?",
  "PropertySettingsTabs.iddigital.title": "ID Digital",

  "PropertyIdDigitalTab.title": "ID Digital Settings",
  "PropertyIdDigitalTab.description": "Configure ID Digital settings for this property.",
  "PropertyIdDigitalTab.save": "Save ID Digital Settings",
  "PropertyIdDigitalTab.pdf.title": "PDFs",
  "PropertyIdDigitalTab.pdf.details": "PDFs will appear on this property's ID Digital page.",
  "PropertyIdDigitalTab.pdf.overview": "Generate Overview PDF",
  "PropertyIdDigitalTab.details": "Details",
  "PropertyIdDigitalTab.details.leasing": "Leasing Description",
  "PropertyIdDigitalTab.details.keyFacts": "Property Key Facts",
  "PropertyIdDigitalTab.leasingImages": "Leasing images",
  "PropertyIdDigitalTab.leasingImages.upload.title": "Upload Leasing Images",
  "PropertyIdDigitalTab.leasingImages.upload": "Featured Leasing Image",
  "PropertyIdDigitalTab.leasingImages.uploadMulti": "Upload additional images",
  "PropertyGeneralSettings.contacts": "Contacts",
  "PropertyGeneralSettings.contacts.add": "Add Contact",
  "PropertyGeneralSettings.contacts.save": "Save Contact",
  "PropertyGeneralSettings.contacts.modal.title": "Add contact",
  "PropertyGeneralSettings.contacts.modal.details": "Company users available to add",
  "PropertyGeneralSettings.contacts.modal.placeholder": "Search Users",
  "PropertyGeneralSettings.removeContact.message": "Are you sure you want to remove this contact from the property listing?",
  "PropertyGeneralSettings.removeContact.title": "Remove contact",

  "SpaceSelect.select": "Select Unit",

  "Reports.title": "Reports Summary",

  "Report.title": "Reports",

  "ReportCard.viewReport": "View Report",

  "Reports.amp.title": "Asset Management",
  "Reports.amp.details": "View your property asset management report. This report includes: water, utility, and waste management details.",

  "ResetPassword.label": "Enter and confirm your new password please.",
  "ResetPassword.resetPassword": "Reset Password",
  "ResetPassword.passwordUpdated": "Your password has been successfully updated.",
  "ResetPassword.login": "Click here to login",
  "ResetPassword.redirect": "Or, just wait and you will be redirected.",

  "Settings.header": "Settings",
  "Settings.mainTitle": "Personal Profile",
  "Settings.description": "Edit your personal profile to change your password, email contact, avatar photo, and preferred language.",
  "Settings.uploadPhoto": "Upload photo",
  "Settings.removePhoto": "Remove photo",
  "Settings.firstName": "First name",
  "Settings.lastName": "Last name",
  "Settings.title": "Title",
  "Settings.email": "Email",
  "Settings.phone": "Phone (Optional)",
  "Settings.error.updates": "There was an error saving the updates",
  "Settings.saveSuccessful": "Settings saved successfully",
  "Settings.profileImageUploaded": "Profile Image uploaded",
  "Settings.profileImageDeleted": "Profile picture deleted successfully",
  "Settings.profileImageDeleted.error": "There was an error deleting the image",
  "Settings.Save": "Save Settings",
  "Settings.Tab.Title": "{title} Settings",
  "Settings.productTitle": "Product Settings",
  "Settings.disableQc.title": "Disable QC",
  "Settings.disableQc.description": "You would no longer have access to QC functionality in RPM",

  "Team.title": "Invite team members",
  "Team.selectCompany": "Select a company.",
  "Team.selectCompany.details": "Your account has access to administer more than 1 company. {displayCompany} is the current active company.",
  "Team.newMembers": "New members",
  "Team.newMembers.details": "New members can manage properties or act as administrators. You can specify permissions after clicking \"Invite new member\".",
  "Team.invites.title": "Current outstanding invitations",
  "Team.invites.resend": "Resend invite (last sent {sentOn})",
  "Team.invites.cancel": "Cancel invite",
  "Team.invites.invite": "Invite new team member",
  "Team.currentMembers": "Current team members",
  "Team.filterMembers": "Filter members",
  "Team.filterMembers.placeholder": "Enter text to filter by name or email",

  "UserCompaniesSelect.placeholder": "Select Company",

  "UsersList.name": "Name",
  "UsersList.email": "Email",
  "UsersList.status": "Status",
  "UsersList.created": "Created",
  "UsersList.edit": "Edit",
  "UsersList.username": "Username",

  "UsersPropertyRolesList.propertyName": "Property Name",
  "UsersPropertyRolesList.viewer": "Viewer",
  "UsersPropertyRolesList.editor": "Editor",
  "UsersPropertyRolesList.designer": "Designer",
  "UsersPropertyRolesList.admin": "Admin",
  "UsersPropertyRolesList.viewer.desc": "Viewers can view assigned properties. Viewers cannot edit any property data.",
  "UsersPropertyRolesList.editor.desc": "Editors can view assigned properties. Editors can also add & delete asset as well as make changes to existing asset data such as meter # on an electric meter.",
  "UsersPropertyRolesList.designer.desc": "Designers can view & edit assigned properties. Designers have full access to add, edit, & delete assets. Designers can also make layout design changes.",

  "userTitles.assetManager": "Asset Manager",
  "userTitles.leasingRepresentative": "Leasing Representative",
  "userTitles.propertyManager": "Property Manager",
  "userTitles.billingSpecialist": "Billing Specialist",
  "userTitles.renewalAgent": "Renewal Agent",
  "userTitles.propertyManagerAssistant": "Property Manager Assistant",
  "userTitles.leasingAgent": "Leasing Agent",
  "userTitles.constructionManager": "Construction Manager",

  "VendorDashboard.mainTitle": "Vendor Dashboard",
  "VendorDashboard.company.title": "Select a company.",
  "VendorDashboard.company.description": "Your account has access to administer more than 1 company. {displayCompany} is the current active company.",
  "VendorDashboard.vendors.title": "All Vendors",
  "VendorDashboard.filterVendors.placeholder": "Filter vendors by name and location",
  "VendorDashboard.invitations.title": "Invitations",
  "VendorDashboard.invitations.description": "Current active invitations will be shown here. Invitations can be resent or cancelled.",
  "VendorDashboard.activeInvitations": "Active Invitations",
  "VendorDashboard.myVendors": "My Vendors",
  "VendorDashboard.vendorInvitations.resend": "Resend",
  "VendorDashboard.vendorInvitations.cancel": "Cancel",
  "VendorDashboard.deleteInvite.message": "Are you sure you want to cancel this invitation?",
  "VendorDashboard.deleteInvite.title": "Cancel Vendor Invite",
  "VendorDashboard.resendInvite.success": "The invite has been successfully resent",
  "VendorDashboard.invitations.expired": "Expired",
  "VendorDashboard.invitations.sent": "Sent",
  "VendorDashboard.invitations.sentToday": "Sent today",

  "Team.cancelInvite.message": "Are you sure you want to cancel this invitation?",
  "Team.cancelInvite.title": "Cancel User Invite",

  "VendorsPropertyRolesList.propertyName": "Property Name",
  "VendorsPropertyRolesList.viewer": "Viewer",
  "VendorsPropertyRolesList.editor": "Editor",
  "VendorsPropertyRolesList.designer": "Designer",
  "VendorsPropertyRolesList.sections": "Sections",
  "VendorsPropertyRolesList.location": "Location",
  "VendorsPropertyRolesList.viewer.desc": "Viewers can view assigned properties. Viewers cannot edit any property data.",
  "VendorsPropertyRolesList.editor.desc": "Editors can view assigned properties. Editors can also add & delete asset as well as make changes to existing asset data such as meter # on an electric meter.",
  "VendorsPropertyRolesList.designer.desc": "Designers can view & edit assigned properties. Designers have full access to add, edit, & delete assets. Designers can also make layout design changes.",
  "VendorsPropertyRolesList.SectionsSelector.noAccess": 'No section access...',
  "VendorsPropertyRolesList.SectionsSelector.noSections": 'No sections...',

  "VendorRegistration.alert.previousSteps": "There are errors on previous steps:",
  "VendorRegistration.step": "Step {step}",
  "VendorRegistration.mainTitle": "Sign up for your free vendor account.",
  "VendorRegistration.mainDetails": "Manage your team and projects more efficiently when you register.",
  "VendorRegistration.getStarted.title": "Let’s get started!",
  "VendorRegistration.getStarted.details": "Create your new vendor profile and dashboard",
  "VendorRegistration.tellUsMore.title": "Tell us more about your company.",
  "VendorRegistration.tellUsMore.details": "This information will be used on your vendor profile.",
  "VendorRegistration.businessType.title": "What type of business are you?",
  "VendorRegistration.businessType.details": "Tell us what type of business you are by adding category tags to describe your services.",
  "VendorRegistration.logo.title": "Upload your company logo.",
  "VendorRegistration.logo.details": "This will be used on your profile to showcase your business to potential leads.",
  "VendorRegistration.password.title": "You’re almost done!",
  "VendorRegistration.password.details": "Create a password and publish your profile.",
  "VendorRegistration.finish.title": "Congratulations!",
  "VendorRegistration.finish.details": "Your vendor profile is now published. Check your email for your log in verification link.",
  "VendorRegistration.alert.registration": "An error occurred in saving/publishing",
  "VendorRegistration.noLongerActive": "This invitation is no longer active/valid",
  "VendorRegistration.toolTip.label.step1": "Vendor Profile Tip",
  "VendorRegistration.toolTip.details.step1": "Enter your company website for it to be featured on your vendor profile. Customers will be able to find you better with the more information you have on your profile.",
  "VendorRegistration.toolTip.label.step2": "Travel Radius",
  "VendorRegistration.toolTip.details.step2": "Select how far your willing to travel. This determines how customers can search for you and your services in their area.",
  "VendorRegistration.toolTip.label.step3": "Business Category Tags",
  "VendorRegistration.toolTip.details.step3": "Select how far your willing to travel. This determines how customers can search for you and your services in their area.",
  "VendorRegistration.toolTip.label.step4": "Business Category Tags",
  "VendorRegistration.toolTip.details.step4": "Select how far your willing to travel. This determines how customers can search for you and your services in their area.",
  "VendorRegistration.toolTip.label.step5": "Business Category Tags",
  "VendorRegistration.toolTip.details.step5": "Select how far your willing to travel. This determines how customers can search for you and your services in their area.",

  "vendorSchema.fname.label": "First Name",
  "vendorSchema.lname.label": "Last Name",
  "vendorSchema.phone.label": "Your phone number",
  "vendorSchema.phone.placeholder": "Enter your phone number",
  "vendorSchema.privacyPolicy.label": "I agree to the Privacy Policy",
  "vendorSchema.privacyPolicy.error": "You must agree to the Privacy Policy",
  "vendorSchema.email.label": "Your email",
  "vendorSchema.email.placeholder": "Enter your email",
  "vendorSchema.company.label": "Company name",
  "vendorSchema.company.placeholder": "Enter your company name",
  "vendorSchema.companySite.label": "Company Website",
  "vendorSchema.companySite.placeholder": "Enter company URL",
  "vendorSchema.address1.label": "Company Address",
  "vendorSchema.address1.placeholder": "Enter address",
  "vendorSchema.city.label": "City",
  "vendorSchema.city.placeholder": "Enter city",
  "vendorSchema.postal.label": "Zip Code",
  "vendorSchema.postal.placeholder": "Enter zip code",
  "vendorSchema.state.label": "State",
  "vendorSchema.state.placeholder": "Select state",
  "vendorSchema.travelRadius.label": "Travel Radius",
  "vendorSchema.travelRadius.placeholder": "Select distance",
  "vendorSchema.travelRadius.miles": "{count} Miles",
  "vendorSchema.travelRadius.remote": "Remote Service",
  "vendorSchema.businessCategories.label": "Business Category Tags",
  "vendorSchema.businessCategories.placeholder": "Start typing and select multiple values...",
  "vendorSchema.businessCategories.plumbing": "Plumbing",
  "vendorSchema.businessCategories.hvac": "HVAC",
  "vendorSchema.businessCategories.contractor": "Contractor",
  "vendorSchema.businessCategories.trash": "Trash",
  "vendorSchema.businessCategories.recycle": "Recycle",
  "vendorSchema.businessCategories.landscaping": "Landscaping",
  "vendorSchema.coi.insuranceStartDate": "Insurance start date",
  "vendorSchema.coi.insuranceEndDate": "Insurance end date",

  "VendorSearch.title": "Vendor Search",
  "VendorSearch.addVendor": "+ Add Vendor",
  "VendorSearch.fname.label": "User",
  "VendorSearch.city.label": "Location",
  "VendorSearch.company.label": "Company",
  "VendorSearch.actions.label": "Actions",
  "VendorSearch.actions.invite.label": "Invite Vendor",
  "VendorSearch.table.title": "All Vendors",
  "VendorSearch.table.placeholder": "Filter vendors by name or location",

  /************************************************************
   * General Use
   * -----------------------------------
   * General use translations that are used throughout the
   * application in multiple components
   ************************************************************/

  //Error Message
  "error.general": "An error occurred",
  "error.server": "The server is not responding",
  "error.spaceProfile.create": "There was an error creating the space profile",
  "error.deleteFile": "There was an error deleting the file",
  "error.iconNotFound": "Upload icon {icon} not found. Using default icon.",
  "error": "Error",
  "error.required": "{field} is required",
  "error.valid": "Enter a valid value for {field}",
  "error.addNewLevel": "There was an error adding a new level",
  "error.deleteNewLevel": "There was an error deleting the level",
  "error.saving": "There was an error in saving",
  "error.serverData": "There was an error retrieving data from the server",
  "error.serverDataTeam": "You do not have access to manage users for this company",
  "error.serverDataVendor": "You do not have access to manage vendor for this company",
  "error.companyAclNotFound": "A valid company ACL could not be found",
  "error.permissionsRoleNotFound": "A valid permissions role could not be found",
  "error.noAccessReports": "You do not have access to any reports.",
  "error.notAuthorized": "Not Authorized",
  "error.requestInvite": "There was an error requesting the invitation",
  "error.cancelInvite": "There was an error cancelling the invitation ",
  "error.sendInvite": "There was an error saving/sending the invitation",
  "error.saveVendor": "There was an error saving/sending the invitation",
  "error.deleteVendor": "There was an error deleting the vendor",
  "error.email.valid": "The email address appears invalid.",
  "error.saveUser": "There was an error saving the user",
  "error.password.match": "The password and confirm password must match exactly.",
  "error.password.length": "The password is not long enough (at least 12 characters).",
  "error.firstName.empty": "The first name cannot be empty.",
  "error.lastName.empty": "The last name cannot be empty.",
  "error.phone.invalid": "The phone # appears invalid.",
  "error.registerMember": "There was an error registering your new member information",
  "error.sendingRequest": "There was an error sending the request",
  "error.privacyPolicy": "You must agree to the Privacy Policy",
  "error.verifyNotRobot": "Please verify you are not a robot by checking the reCaptcha box below.",
  "error.tooManyLogins": "There were too many bad login attempts. Captcha is now required.",
  "error.login": "There was an error logging in",
  "error.resetPassword": "You must reset your password by clicking the link below to unlock your account.",
  "error.validateMfa": "There was an error validating the MFA code",
  "error.chooseMfaOption": "A delivery method must be selected.",
  "error.requestMfa": "There was an error requesting the MFA code",
  "error.password.matchAndLength": "The passwords must match and be at least 10 characters",
  "error.password.update": "There was an error updating your password",
  "error.resendInvite": "User lacks permissions to resend invitations for {displayCompany}",
  "error.addInvite": "User lacks permissions to create new invitations for {displayCompany}",
  "error.cancelExistingInvite": "User lacks permissions to cancel invitations for {displayCompany}",
  "error.listUsers": "User lacks permissions to list users for {displayCompany}",
  "error.listInvites": "User lacks permissions to list invitations for {displayCompany}",
  "error.listVendors": "User lacks permissions to list vendors for {displayCompany}",

  //Files
  "file.floorplan": "Floorplan",
  "file.featured": "Featured Image",
  "file.autocad": "AUTOCAD",
  "file.pdf": "LOD PDF",
  "file.profile_pdf": "Profile PDF",

  //Platform Related Language
  "platform.roles.admin": "Admin",

  // Business or general use terms that are often repeated as content for button
  "term.company": "Company",
  "term.sourceCompany": "Source Company",
  "term.destinationCompany": "Destination Company",
  "term.dueDate": "Due Date",
  "term.orderDate": "Order Date",
  "term.property": "Property",
  "term.sourceProperty": "Source Property",
  "term.properties": "Properties",
  "term.unavailable": "Unavailable",
  "term.available": "Available",
  "term.visible": "Visible",
  "term.required": "Required",
  "term.enabled": "Enabled",
  "term.unit": "Unit",
  "term.unit_no": "Unit #",
  "term.address": "Address",
  "term.unknown": "Unknown",
  "term.search": "Search",
  "term.assetType": "Asset Type",
  "term.floorplan": "Floorplan",
  "term.asset": "Asset",
  "term.published": "published",
  "term.unpublished": "unpublished",
  "term.draft": "draft",
  "term.other": "Other",
  "term.scheduledDate": "Scheduled",
  "term.completedDate": "Completed",
  "term.lastDate": "Last {period} {interval}",
  "term.nextDate": "Next {period} {interval}",
  "term.days": "Days",
  "term.anyDate": "Any Date",
  "term.leaseExpirationDate": "Lease Expiration",
  "term.success": "Success",

  //UI elements (common UI buttons, interactions)
  "ui.coiChangeFile": "Replace file",
  "ui.notAuthorized": "Not Authorized",
  "ui.noOptions": "No Options",
  "ui.inactive": "Inactive",
  "ui.active": "Active",
  "ui.disabled": "Disabled",
  "ui.username": "Username",
  "ui.password": "Password",
  "ui.login": "Login",
  "ui.login.samlSso": "Sign in with SAML SSO",
  "ui.login.password": "Sign in with password",
  "ui.login.samlSsoButton": "Sign in with SSO",
  "ui.lostPassword": "Forgot your password?",
  "ui.confirmPassword": "Confirm Password",
  "ui.skip": "Skip",
  "ui.publish": "Publish",
  "ui.yesCancel": "Yes, cancel",
  "ui.close": "Close",
  "ui.server": "Server Error",

  // Measurement Units
  "unit.squareFootage": "sqft",
  "unit.squareMeters": "sq m",
  "unit.feet": "feet",
  "unit.inches": "inches",
  "unit.squareFootageVariant": "SF",

  /************************************************************
   * Monorepo Components
   * -----------------------------------
   * Translations need to be passed in props as these components
   * don't have access to the intl provider
   ************************************************************/

  //AdminInput Component
  "AdminInput.validationMessage": "Enter a valid value for ''{label}''",

  //Contact Component
  "Contact.phone": "P",
  "Contact.email": "E",
  "Contact.propertyContact": "Property Contact",
  "Contact.assetManager": "Asset Manager",
  "Contact.leasingRepresentative": "Leasing Representative",
  "Contact.propertyManager": "Property Manager",
  "Contact.billingSpecialist": "Billing Specialist",
  "Contact.renewalAgent": "Renewal Agent",
  "Contact.propertyManagerAssistant": "Property Manager Assistant",
  "Contact.leasingAgent": "Leasing Agent",
  "Contact.constructionManager": "Construction Manager",

  //CompanySelectComponent
  "CompanySelect.placeholder": "Select...",
  "CompanySelect.loadingMessage": "Loading...",
  "CompanySelect.noOptions": "No Options",

  //Common Components
  "MapView.noResults": "No results found!",

  
  // Lease Radar Details
  "LeaseRadarDetails.popup.leaseExpiring": "Lease Expiring",
  "LeaseRadarDetails.tenantCard.lease": "Lease",
  "LeaseRadarDetails.tenantCard.vacate": "Vacate",
  "LeaseRadarDetails.tenantCard.categoryUnavailable": "Category Unavailable",
  "LeaseRadarDetails.tenantCard.available": "AVAILABLE",
}
