import React, { createContext, useContext } from 'react';

const FeatureFlagContext = createContext({
  /**
   * Checks if a flag is enabled.
   * 
   * @param {string} flag 
   * @param {object|undefined} evaluationContext 
   */
  isFlagEnabled: (flag, evaluationContext) => { }
});

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export default ({ children, flagConfiguration }) => {
  const currentEnvironment = process.env.REACT_APP_BUILD_TARGET;
  const flags = Object.entries(flagConfiguration).map(([key, config]) => ({ [key]: config[currentEnvironment] })).reduce((acc, val) => ({ ...acc, ...val }), {})

  const isFlagEnabled = (flag, evaluationContext) => {
    const evaluator = flags[flag]
    let result;

    if (typeof evaluator === 'boolean') {
      // Flag is already set, no need to evaluate, return it.
      result = evaluator;
    }

    if (typeof evaluator === 'function') {
      // Flag is a function, return the result, using the evaluation context.
      result = evaluator.call(null, evaluationContext);
    }

    console.debug('🏳️ Checking for Feature Flag: %o - Result: %o', flag, result);
    return result;
  }

  return (
    <FeatureFlagContext.Provider value={{ isFlagEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
}