import { TABLE_TYPES } from "./InspectTable"

/**
   * This is container for props that will be passed to the Table component
   * to handle fields, filters, sizing, etc when property selected
   * @param {(s: string) => string} t translator
   * @param {boolean} mobileView whether table has passed the mobileView size breakpoint
   * @param {(item: any) => void} workOrder Work Order entity
   * @returns {any} Props for tables
   */
const usePropertiesTableProps = ({
  t,
  mobileView,
  handlePropertyRowClick,
  tableType
}) => {
  const keysByTableType = {
    [TABLE_TYPES.INSPECTION]: {
      placeholder: 'Inspections.table.properties.placeholder',
      count: 'Inspections.table.properties.inspections'
    },
    [TABLE_TYPES.WORK_ORDER]: {
      placeholder: 'WorkOrders.table.properties.placeholder',
      count: 'WorkOrders.table.properties.workOrders'
    }
  };

  return [{
    idField: 'guid',
    searchFilters: ['name', 'address', 'count'],
    sortFields: ['name', 'address', 'count'],
    placeholder: t(keysByTableType[tableType].placeholder),
    fields: [
      { id: 'name', label: t('term.property'), type: 'details', options: { photo: { key: 'leasingImage', objectFit: 'cover' } } },
      { id: 'address', label: t('term.address'), type: 'details' },
      {
        id: 'count', label: t(keysByTableType[tableType].count), type: 'status', options: { noDot: true }, statusColors: [
          { condition: _ => _, color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } }]
      }
    ],
    defaultSort: {
      key: 'rawCount',
      descending: false
    },
    showFields: (mobileView && ['name', 'count']) || ['name', 'address', 'count'],
    customWidths: (mobileView && ['65%', '35%']) || ['40%', '40%', '20%'],
    onRowClick: handlePropertyRowClick
  }]
}

export default usePropertiesTableProps