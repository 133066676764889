import React from 'react';
import styles from './MultiStepNavigator.module.css';
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';

const MultiStepNavigator = ({ backHandler, nextHandler, totalSteps, currentStep, customNextLabel, customBackLabel, hideBack, hideNext, nextDisabled, testId = "footer-button" }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  return (
    <div className={styles.multiStepNavigator}>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{ width: `${currentStep / totalSteps * 100}%` }}></div>
      </div>
      <div className={styles.controls} datacy={`${testId}-container`}>
        {!hideBack ? <Button onClick={backHandler} className={styles.backButton} datacy={`${testId}-back`}>{customBackLabel || t('ui.goBack')}</Button> : <div></div>}
        {!hideNext ? <Button disabled={nextDisabled} onClick={nextHandler} datacy={`${testId}-next`}>{customNextLabel || t('ui.next')}</Button> : <div></div>}
      </div>
    </div>
  )

}

export default MultiStepNavigator;