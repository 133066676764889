import React, {
  useMemo,
  useState,
  useRef
} from 'react'
import { Table } from '@project/components'
import { useWorkOrders, useTranslations, useInspectQueryParams, useInspectionInstances } from '@project/components/src/utils/hooks'
import { INSPECT_FILTER_TYPE, INSPECT_TYPE } from '../../utils/constants'
import styles from './InspectTable.module.css'
import './InspectTable.css'
import PropTypes from 'prop-types'
import useTableUtils from './hooks/useTableUtils'
import useTableTabs from './hooks/useTableTabs'
import useTableStatusFields from './hooks/useTableStatusFields'
import usePropertiesTableProps from './propertiesTableProps'
import useTableActionsList from './hooks/useTableActionsList'
import PropertiesBackButton from '../PropertiesBackButton/PropertiesBack'
import useTableProps from './hooks/useTableProps'
import DeleteItemConfirmationModal from './DeleteItemConfirmationModal/DeleteItemConfirmationModal'
import { DetailPane } from '../../DetailPane/DetailPane'

export const TABLE_TYPES = {
  INSPECTION: 'inspection',
  WORK_ORDER: 'work-order'
}

/**
 * This hook gathers the counts for inspections or work orders for all properties. Counts
 * are gathered from the count routes and then the missing properties (gathered with menu)
 * are autofilled with 0 inspections
 * @param {{guid: string, slug: string}} currentCompany currently selected company
 * @param {string} itemId item to bew viewed
 * @param {boolean} isAdmin is user admin
 * @param {(show: bool) => void} toast success toast
 * @param {(show: bool) => void} setError Either sets error or send out toast error depending on what is passed in
 * @param {string} type inspection or work-order
 * @param {(item: any) => string} setEditSharedUsersInspection sets item to edit shared users on
 * @param {(show: bool) => void} setShowEditSharedUsersModal shows shared users edit modal
 * @returns {any} Button for viewing/resuming/etc item
 */
export const InspectTable = ({
  currentUser,
  isVendor = false,
  currentCompany,
  isAdmin,
  toast = () => { },
  setError = () => { },
  useQueryParams,
  external,
  axios,
  CancelToken,
  isCancel,
  type,
  setEditSharedUsersInspection,
  setShowEditSharedUsersModal,
  renderDetailPane = null,
  isSaving = false,
  showManageCOIModal,
  setShowManageCOIModal,
  coiModal: COIModal,
  allowDetailPaneNavigation = true,
  rowPlaceholderHeight = '71px'
}) => {
  const [t] = useTranslations()
  const tableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [deletedItemIds, setDeletedItemIds] = useState([])
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)
  const [inspectFilter, setInspectFilter] = useState(null)
  const [queryParams, setQueryParams] = useState({ page: 0 })
  const [pagination, setPagination] = useState({})
  const [propertySelected, setPropertySelected] = useState(null)

  const utilProps = {
    currentUser,
    currentCompany,
    isAdmin,
    toast,
    setError,
    axios,
    CancelToken,
    isCancel,
    t,
    type,
    setIsLoading,
    user: currentUser
  }

  const companyGuid = useMemo(() => (
    currentCompany?.guid
  ), [currentCompany])

  const companySlug = useMemo(() => (
    currentCompany?.displaySlug || currentCompany?.slug
  ), [currentCompany])

  const {
    properties,
    propertiesLoading,
    mobileView,
    largeView,
    xLargeView,
    getIdInspectUrl,
    viewItem,
    handlePropertyRowClick,
    editInspection
  } = useTableUtils({
    ...utilProps,
    tableRef,
    setPropertySelected,
    setInspectFilter,
    companyGuid,
    companySlug
  })
  const [tabs, customSelectedTab] = useTableTabs({ t, setInspectFilter, isAdmin, isVendor, inspectFilter, type, external })
  const [statusField] = useTableStatusFields({ t, type })
  const [propertiesTableProps] = usePropertiesTableProps({ t, mobileView, handlePropertyRowClick, tableType: type })
  const [tableActionsList] = useTableActionsList({
    ...utilProps,
    external,
    getIdInspectUrl,
    viewItem,
    setShowDeleteItemModal,
    setDeleteItemId,
    editInspection,
    setEditSharedUsersInspection,
    setShowEditSharedUsersModal,
    inspectFilter,
    setShowManageCOIModal
  })

  const [workOrders = [], setWorkOrders] = useWorkOrders({
    ...utilProps,
    companyGuid,
    propertyGuid: propertySelected,
    filter: inspectFilter,
    queryParams,
    setPagination,
    disabled: type !== INSPECT_TYPE.WORK_ORDER,
    allowExternalWorkOrderAltering: true
  });

  const inspections = useInspectionInstances({
    ...utilProps,
    companyGuid,
    propertyGuid: propertySelected,
    filter: inspectFilter,
    type: INSPECT_FILTER_TYPE.ALL,
    queryParams,
    setPagination,
    disabled: type !== INSPECT_TYPE.INSPECTION || inspectFilter === INSPECT_FILTER_TYPE.PROPERTIES
  }) || [];

  useInspectQueryParams({
    propertySelected,
    filter: inspectFilter,
    setFilter: setInspectFilter,
    setPropertySelected,
    isAdmin,
    hide: !useQueryParams,
    route: type === INSPECT_TYPE.WORK_ORDER ? 'work-orders' : 'inspections',
    companySlug
  })

  const [selectedRow, setSelectedRow] = useState(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)

  const showDetailPane = (item) => {
    const index = data.findIndex(dataRow => dataRow.id === item.id)
    setSelectedRow(item)
    setSelectedRowIndex(index)
  }

  const closeDetailPane = () => {
    setSelectedRow(null)
    setSelectedRowIndex(null)
  }

  const setNextItem = () => {
    setSelectedRow(data[selectedRowIndex + 1])
    setSelectedRowIndex(selectedRowIndex + 1)
  }

  const setPreviousItem = () => {
    setSelectedRow(data[selectedRowIndex - 1])
    setSelectedRowIndex(selectedRowIndex - 1)
  }

  const [getTableProps] = useTableProps({
    type,
    statusField,
    t,
    isAdmin,
    tableActionsList,
    external,
    mobileView,
    largeView,
    xLargeView,
    showDetailPane: renderDetailPane ? showDetailPane : undefined
  })

  let tableProps = {
    all: getTableProps(INSPECT_FILTER_TYPE.ALL),
    assigned: getTableProps(INSPECT_FILTER_TYPE.ASSIGNED),
    completed: getTableProps(INSPECT_FILTER_TYPE.COMPLETED),
    properties: propertiesTableProps,
    watching: getTableProps(INSPECT_FILTER_TYPE.WATCHING)
  }

  let itemsFormatted = useMemo(() => {
    const items = {
      [INSPECT_TYPE.INSPECTION]: inspections,
      [INSPECT_TYPE.WORK_ORDER]: workOrders
    }
    return items[type].filter(item => !deletedItemIds.includes(item?.id))
  }, [inspections, workOrders, type, deletedItemIds])

  const propertiesTabSelected = inspectFilter === INSPECT_FILTER_TYPE.PROPERTIES
  let data = propertiesTabSelected ? properties : itemsFormatted

  const propertiesBackButton = <PropertiesBackButton
    setPropertySelected={setPropertySelected}
    setInspectFilter={setInspectFilter}
    t={t}
  />

  const isFilteringByProperty = useMemo(() => propertySelected !== null, [propertySelected])

  return <div className={`w-100 mb-3`} ref={tableRef}>
    <Table
      {...tableProps[inspectFilter]}
      customSelectedTab={customSelectedTab}
      tabs={tabs}
      customStyle={styles.table}
      itemsPerPage={10}
      items={data}
      headerButton={isFilteringByProperty && propertiesBackButton}
      rowPlaceholderHeight={rowPlaceholderHeight}
      isLoading={propertiesTabSelected ? propertiesLoading : isLoading}
      customPaginate={inspectFilter !== INSPECT_FILTER_TYPE.PROPERTIES}
      customPage={queryParams.page}
      customPaginationTotalSize={pagination.count}
      setCustomSearchFilter={(newSearch) => setQueryParams(prevParams => { return { ...prevParams, search: newSearch } })}
      setCustomSort={(newSort) => setQueryParams(prevParams => { return { ...prevParams, orderBy: newSort.key, orderAscending: !newSort.descending } })}
      setCustomPage={(newPage) => setQueryParams(prevParams => { return { ...prevParams, page: !newPage && newPage !== 0 ? prevParams.page : newPage } })}
    />
    {renderDetailPane && <DetailPane
      allowNavigation={allowDetailPaneNavigation}
      currentIndex={selectedRowIndex}
      totalItems={data.length}
      visible={selectedRow !== null}
      onCloseClicked={closeDetailPane}
      onNextClicked={setNextItem}
      onPreviousClicked={setPreviousItem}
      isSaving={isSaving}
    >
      {selectedRow && renderDetailPane(selectedRow, setSelectedRow)}
    </DetailPane>}
    <DeleteItemConfirmationModal
      showDeleteItemModal={showDeleteItemModal}
      setShowDeleteItemModal={setShowDeleteItemModal}
      itemId={deleteItemId}
      setDeletedItemIds={setDeletedItemIds}
      {...utilProps}
    />
    {showManageCOIModal ? <COIModal workOrder={showManageCOIModal} setWorkOrders={setWorkOrders} /> : null}
  </div>
}

InspectTable.propTypes = {
  currentUser: PropTypes.object,
  currentCompany: PropTypes.object,
  isAdmin: PropTypes.bool,
  toast: PropTypes.func,
  setError: PropTypes.func,
  useQueryParams: PropTypes.bool,
  external: PropTypes.bool,
  axios: PropTypes.func,
  CancelToken: PropTypes.func,
  isCancel: PropTypes.func,
  type: PropTypes.string,
  setEditSharedUsersInspection: PropTypes.func,
  setShowEditSharedUsersModal: PropTypes.func,
  renderDetailPane: PropTypes.func,
  showManageCOIModal: PropTypes.bool,
  setShowManageCOIModal: PropTypes.func,
  coiModal: PropTypes.func
}

InspectTable.defaultProps = {
  isAdmin: false,
  toast: () => { },
  setError: () => { },
  useQueryParams: false,
  external: false
}