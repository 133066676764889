import { useEffect, useState } from "react"
import PropTypes from 'prop-types'

//This hook allows us to create custom breakpoints
//For example, bootstrap has breakpoints at 998px, 768px, 576px,
//for Large, Mobile, MobileSmall respectively. In most of our applications
//we have breakpoints set up in root context that follow these. These
//are useful for sizing and responsiveness. However, they follow the window.
//This hook bases itself on the given element which allows for it be self-responsive
//in multiple layouts.
const useWidthBreakpoints = ({
  widthBreakpoints = {}, 
  elementWidth
}) => {
  const [breakpoints, setBreakpoints] = useState({})

  //Check all breakpoint conditions on resize
  useEffect(() => {
    const handleResize = () => {
      const tempBreakpoints = {}
      Object.keys(widthBreakpoints).forEach(breakpointName => {
        tempBreakpoints[breakpointName] = widthBreakpoints[breakpointName](elementWidth)
      })
      setBreakpoints(tempBreakpoints)
    }
    if (elementWidth) handleResize()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementWidth])

  return breakpoints
}

useWidthBreakpoints.propTypes = {
  //Object with key as name of breakpoint, and value as condition to meet
  //Ex. {mobileView: (width) => width < 768, largeView: (width) => width > 998}
  widthBreakpoints: PropTypes.object,
  //Element that breakpoints will be based on
  element: PropTypes.element
}

useWidthBreakpoints.defaultProps = {
  widthBreakpoints: {}
}

export default useWidthBreakpoints