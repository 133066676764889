/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Form, Button, Alert } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';
import { Navigate, Link, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Spinner } from '@project/components';
import logo from '../../assets/images/login-logo2.png';
import { VALID_APPS } from '../../constants';
import { RootContext } from '../../hoc/RootContext/RootContext';
import { usePersistentStateViaCookie } from '../../utils/hooks';
import axios from '../../utils/axios';
import MfaForm from '../MfaForm/MfaForm';
import styles from './Login.module.css';
import moment from 'moment';

const LOGIN_CAPTCHA_COOKIE_NAME = 'idp-login-captcha';

/**
 * Checks if a given array of API errors contains at least one error with `message`.
 * @param {{message}[]} errorArray Array of API errors
 * @param {string} message An error message to look for in `errorArray`
 * @returns {Boolean}
 */
const hasSpecificError = (errorArray, message) => errorArray.some(errorObj => errorObj.message === message);

const hasCaptchaError = errorArray => hasSpecificError(errorArray, 'captcha required');

const hasAccountLockedError = errorArray => hasSpecificError(errorArray, 'account is locked out');

const Login = () => {
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });

  const cookies = new Cookies();

  const [errors, setErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captchaChanged, setCaptchaChanged] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    setAuthToken,
    setCurrentUser,
    authToken,
    currentUser,
    getUser,
    isLoggedIn,
    setIsLoggedIn,
    loginErrors
  } = useContext(RootContext);

  const query = new URLSearchParams(useLocation().search);
  const app = query.get("app");
  let path = query.get("path");
  const token = query.get("token");
  const cookieToken = cookies.get("auth");
  const isOktaUser = query.get("oktaSso") === 'true'
  const isNativeMobile = app === 'mobile';
  const nativeMobileDeepLink = isNativeMobile && query.get("redirect_uri");

  const [showCaptcha, setShowCaptcha] = usePersistentStateViaCookie(LOGIN_CAPTCHA_COOKIE_NAME);

  /**
   * Flag that allows/disallows login based on the captcha state:
   * - Now shown: allowed
   * - Shown and unchanged: not allowed
   * - Shown and changed: allow
   * @type {Boolean}
   */
  const isAllowedToLogin = useMemo(() => {
    if (!showCaptcha) return true; // Always allowed if no captcha is triggered

    return captchaChanged; // If captcha AND it changed, allow again
  }, [captchaChanged, showCaptcha]);

  // trigger a login request if a token is detected
  useEffect(() => token && sendLoginRequest(), []);

  const sendLoginRequest = async () => {
    cookies.remove('auth');
    cookies.remove('logged-in');
    setAuthToken(null);
    setCurrentUser(null);
    setIsLoggedIn(false);
    setErrors(null);
    setLoading(false);

    try {
      if (isAllowedToLogin || token || cookieToken) {
        setLoading(true);
        setShowCaptcha(false);

        const payload = (() => {
          if (username && password) return { username, password };
          if (token) return { token };
          if (cookieToken) return { token: cookieToken };
        })();

        if (currentUser) return;
        await getUser(payload);
      } else {
        setCaptchaChanged(false);
        setErrors(t('error.verifyNotRobot'));
      }
    } catch (e) {
      if (e.response && e.response.data) {
        setApiErrors([]);
        let msg;

        if (!e.response.data.errors) {
          return;
        }

        const apiErrorArray = e.response.data.errors;

        if (hasCaptchaError(apiErrorArray)) {
          msg = t('error.tooManyLogins');
          setShowCaptcha(true);
          setCaptchaChanged(false);
        } else if (hasAccountLockedError(apiErrorArray)) {
          msg += t('error.resetPassword');
        } else {
          msg = t('error.login');
          setApiErrors(apiErrorArray);
        }

        setErrors(msg);
      } else {
        setErrors((e && e.message) || t('ui.server'));
      }
    } finally {
      setLoading(false);
    }
  };

  const checkMFA = async () => {
    let result = await axios.get(`/companies/${currentUser.company}`, isNativeMobile ? { headers: { 'x-access-token': authToken } } : {});
    const company = result.data.record;

    // check if mfa is required company wide or if
    // the user has opted in to MFA
    // MFA is ignored if the user is Okta verified or if the user is masquerading
    const now = moment().format('X');
    if (
      (company.requireMfa || currentUser.useMfa) &&
      !isOktaUser &&
      (!currentUser.masquerade || currentUser.masquerade < now)
    ) {
      setShowMfa(true);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    if ((token || cookieToken || (authToken && isNativeMobile)) && currentUser) {
      checkMFA();
    }
  }, [token, cookieToken, currentUser, authToken, isNativeMobile]);

  const onCaptchaChange = e => {
    setCaptchaChanged(true);
  };

  const onCaptchaError = e => {
    setCaptchaChanged(false);
  };

  const onCaptchaExpired = e => {
    setCaptchaChanged(false);
  };

  const onMfaSuccess = e => {
    setIsLoggedIn(true);
  };

  const onMfaFailure = e => {
    setShowMfa(false);
    setAuthToken(null);
    setCurrentUser(null);
  };

  if (isLoggedIn && authToken && currentUser) {
    if (isNativeMobile) {
      window.location.replace(`${nativeMobileDeepLink}?token=${authToken}`);
    }
    if (app && VALID_APPS.includes(app.toLowerCase())) {
      const key = currentUser.type === 'VENDOR' ? `REACT_APP_IDINSPECT_HOST` : `REACT_APP_${app.toUpperCase()}_HOST`;
      if (!path) {
        path = '';
      }
      window.location.href = `${process.env[key]}${path}`;
    } else {
      return <Navigate to="/dashboard" />;
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    sendLoginRequest();
  };

  return (
    <>
      {isOktaUser ? (
        <>
          <Spinner />
          <div className="row justify-content-center my-5 vh-100">
            <span className="align-self-center">{t('Login.redirect.loading')}</span>
          </div>
        </>
      ) : (
        <div className={classNames(styles.loginPage, 'd-flex')}>
          <div className={classNames(styles.loginContainer, 'm-auto mw-100 bg-white shadow-sm')}>
            <div className={classNames(styles.logo, 'text-center')}>
              <img src={logo} width="313px" alt="IDCloud" />
            </div>
            {!showMfa ? (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      id="username"
                      className={styles.loginInput}
                      type="text"
                      placeholder={t('ui.username')}
                      onChange={e => setUsername(e.target.value)}
                      autoComplete="off"
                      autoCapitalize="none"
                      autoCorrect="off"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      id="password"
                      className={styles.loginInput}
                      type="password"
                      placeholder={t('ui.password')}
                      onChange={e => setPassword(e.target.value)}
                      autoCorrect="off"
                      autoCapitalize="none"
                    />
                  </Form.Group>
                  <div className="d-flex">
                    <Link to="/lost-password" className="ml-auto">
                      {t('ui.lostPassword')}
                    </Link>
                  </div>
                  {errors ? (
                    <Alert key={errors} variant="danger">
                      {t('error')}: {errors}
                      {apiErrors.length > 0 && (
                        <ul>
                          {apiErrors.map((apiError, i) => (
                            <li key={i}>{apiError.message}</li>
                          ))}
                        </ul>
                      )}
                    </Alert>
                  ) : null}
                  {loginErrors.length ? (
                    <Alert key={errors} variant="danger">
                      {loginErrors.length > 0 && (
                        <ul>
                          {loginErrors.map((error, i) => (
                            <li key={i}>{error}</li>
                          ))}
                        </ul>
                      )}
                    </Alert>
                  ) : null}

                  {showCaptcha ? (
                    <ReCAPTCHA
                      type={'image'}
                      theme={'light'}
                      size={'normal'}
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onChange={onCaptchaChange}
                      onErrored={onCaptchaError}
                      onExpired={onCaptchaExpired}
                    />
                  ) : null}

                  <div className="border-bottom my-3"></div>

                  <Button
                    variant="primary"
                    block
                    className={classNames(
                      styles.btnLogin,
                      'border-0 w-100 d-flex align-items-center justify-content-center'
                    )}
                    type="submit"
                  >
                    {t('ui.login')}
                  </Button>
                </Form>

                <div className="border-bottom my-3">
                  <Link to="/login/saml-sso">{t('ui.login.samlSso')}</Link>
                </div>
              </>
            ) : (
              <MfaForm
                onSuccess={onMfaSuccess}
                onFailure={onMfaFailure}
                user={currentUser}
                loadingIndicator={setLoading}
              />
            )}
          </div>
          {loading ? <Spinner /> : null}
        </div>
      )}
    </>
  );
};

export default Login;
