import React from 'react'
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent'
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn'
import TitleDescription from './../../components/TitleDescription/TitleDescription'
import ToggleWithLabel from './../../components/ToggleWithLabel/ToggleWithLabel'
import { useUserActions } from './../../utils/hooks'
import { AdminCheckbox, AdminInput, AdminSelect } from "@project/components"
import { isCompanyIDPlans } from "@project/components/src/FeatureFlags/FeatureFlagEnablers"
import { useIntl } from 'react-intl'
import { Image } from 'react-bootstrap'
import FileUpload from '../FileUpload/FileUpload'
import styles from './CompanyGeneralTab.module.css'

const CompanyGeneralTab = ({
  company,
  companyName,
  companyDisplayName,
  updateField,
  imageExt,
  setCompanyRequireMfa,
  setCompanyRequireSSO,
  setCompanySSOUserFilter,
  companySettings,
  updateCompanySettings,
  setErrors
}) => {
  const intl = useIntl();
  const t = id => intl.formatMessage({ id });
  const isCompanyAdmin = useUserActions(companyName, null, 'company-settings:edit', setErrors, true);
  const logoSizeOptions = ['small', 'regular', 'large', 'larger', 'jumbo', 'largest'];
  const optionsMap = (options) => options.map(option => ({ label: t(`CompanyGeneralTab.logoSize.${option}`), value: option }));

  return (
    <>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription title={t('CompanyGeneralTab.mfa.title')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <div>
            <AdminCheckbox
              id={'requireMfa'}
              label={t('CompanyGeneralTab.mfa.label')}
              onChange={e => setCompanyRequireMfa(e.currentTarget.checked)}
              checked={company.requireMfa}
              customstyle={styles.checkbox}
            />
            <label htmlFor="requireMfa">
              {intl.formatMessage({ id: 'CompanyGeneralTab.mfa.details' }, { companyDisplayName })}
            </label>
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription title={t('CompanyGeneralTab.sso.title')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <div>
            <AdminCheckbox
              id={'requireSSO'}
              label={t('CompanyGeneralTab.sso.label')}
              onChange={e => setCompanyRequireSSO(e.currentTarget.checked)}
              checked={company.requireSSO}
              customstyle={styles.checkbox}
            />
            <label htmlFor="requireSSO">
              {intl.formatMessage({ id: 'CompanyGeneralTab.sso.details' }, { companyDisplayName })}
            </label>
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription title={t('CompanyGeneralTab.ssoUserFilter.title')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <div>
            <AdminInput
              placeholder={t('CompanyGeneralTab.ssoUserFilter.label')}
              hidelabel={+true}
              value={company.ssoUserFilter}
              type='text'
              onChange={e => updateField(e, company, 'ssoUserFilter')}
              disabled={!company.requireSSO}
            />
            <label htmlFor="ssoUserFilter">
              {intl.formatMessage({ id: 'CompanyGeneralTab.ssoUserFilter.details' })}
            </label>            
          </div>
        </RightColumn>
      </TwoColumnContent>      
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyGeneralTab.logo.title')}
            description={t('CompanyGeneralTab.logo.description')}
          />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <div className="d-flex justify-content-between align-items-center" style={{ flexWrap: 'wrap' }}>
            <FileUpload
              company={company}
              updateField={updateField}
              uploadKey={'logo'}
              description={t('CompanyGeneralTab.logo.title')}
              accept={imageExt}
              makeThumbnail={true}
              makeStandard={true}
            />
            {company.logo && company.logo.thumbnail ? (
              <div className="my-4">
                <Image src={`https://s3.amazonaws.com/idp-rpm-v4/${company.logo.thumbnail}`} />
              </div>
            ) : null}
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyGeneralTab.logoSize.title')}
            description={t('CompanyGeneralTab.logoSize.description')}
          />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <div className="d-flex justify-content-between align-items-center" style={{ flexWrap: 'wrap' }}>
          <AdminSelect
            id="company-logo-size-select"
            identifierClass={'company-logo-size-select'}
            options={optionsMap(logoSizeOptions)}
            defaultValue="Regular"
            placeholder={t('CompanyGeneralTab.logoSize.placeholder')}
            value={companySettings?.generalSettings?.logoSize ? companySettings.generalSettings.logoSize : null}
            onChange={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'generalSettings.logoSize')}
            isLoading={false}
            loadingMessage={t('ui.loading')}
            noOptionsMessage={t('ui.noOptions')}
          />
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyGeneralTab.primaryEmail')}
            description={t('CompanyGeneralTab.primaryEmailDesc')}
          />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <AdminInput
            placeholder={t('CompanyGeneralTab.primaryEmail')}
            hidelabel={+true}
            value={company.contactemail}
            type={'text'}
            onChange={e => updateField(e, company, 'contactemail')}
          />
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyGeneralTab.propertyManagementEmail')}
            description={t('CompanyGeneralTab.propertyManagementEmailDesc')}
          />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <AdminInput
            placeholder={t('CompanyGeneralTab.propertyManagementEmail')}
            hidelabel={+true}
            value={company.contacts?.property_management_email}
            type={'text'}
            onChange={e => updateField(e, company, 'contacts.property_management_email')}
          />
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyGeneralTab.leasingContactEmail')}
            description={t('CompanyGeneralTab.leasingContactEmailDesc')}
          />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <AdminInput
            placeholder={t('CompanyGeneralTab.leasingContactEmail')}
            hidelabel={+true}
            value={company.contacts?.leasing_management_email}
            type={'text'}
            onChange={e => updateField(e, company, 'contacts.leasing_management_email')}
          />
        </RightColumn>
      </TwoColumnContent>
      {isCompanyAdmin && isCompanyIDPlans() ? (
        <>
          <ToggleWithLabel
            titleLabel={t('CompanyGeneralTab.IdpRecordId')}
            titleDescriptions={[t('CompanyGeneralTab.bypassValidation')]}
            ids={['idpRecordId']}
            getObject={companySettings}
            getKeys={['generalSettings.propertyValidationRules.idpRecordId.bypass']}
            updateFunction={updateCompanySettings}
          />
          <ToggleWithLabel
            titleLabel={t('CompanyGeneralTab.IntegrationId')}
            titleDescriptions={[t('CompanyGeneralTab.enforceValidation')]}
            ids={['integrationId']}
            getObject={companySettings}
            getKeys={['generalSettings.propertyValidationRules.integrationId.enforce']}
            updateFunction={updateCompanySettings}
          />
        </>
      ) : null}
    </>
  );
};

export default CompanyGeneralTab
