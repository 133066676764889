import bootstrapClassList from "./bootstrapClassList";
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx'
import moment from 'moment'

export const toastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
}

/**
 * Safely accesses an object using a flattened key
 *
 * @param {Object} obj base object
 * @param {String} key dot delimited access key for the object
 * @param {Object} defaultReturn default return when a match isn't found
 */
const get = (obj, key, defaultReturn = null) => {
  const res = key
    .split('.')
    .reduce((returnVal, currentKey) => (returnVal ? returnVal[currentKey] : defaultReturn), obj);
  return res !== null && res !== undefined ? res : defaultReturn;
}

/**
 * Validate url string
 *
 * @param {String} str string to test
 */
const isValidUrl = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

const isValidPhone = (phone) => {
  // not null/undefined?
  if (phone) {
    // filter
    phone = phone.toString().replace(/[^0-9]/gi, '');
    // check length after filtered
    if (phone.length === 10 || (phone.length === 11 && phone.substr(0, 1) === "1")) {
      // add country code?
      return `${((phone.length === 10) ? ('1') : (''))}${phone}`;
    }
  }
  return false;
};

const formatPhone = (phone) => {
  let str = '';

  try {
    phone = phone.toString().replace(/[^0-9.]/gi, '');
    if (phone.substr(0, 1) === '1') phone = phone.substr(1);
    str = `+1 (${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6)}`;
  }
  catch (e) {
    // no-op
  }

  return str;
};

const isValidEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * Converts a string value to a number by removing commas in the value
 * @param {String} s The string value to be converted to a number
 * @param {String} p The property name (for logging purposes)
 */
const parseNumber = (s, p) => {
  s = s.trim().replace(/,/g, '');
  if (isNaN(s) || s.trim() === '') {
    console.warn(`Invalid number "${s}". Cannot convert to float. It is possibly an invalid sq. footage value for the property ${p}`);
    return 0;
  }
  return parseFloat(s, 10);
}

/**
 * Provide cookie options based on environment
 */
const getCookieOptions = (validDayCount = 30) => {

  /*
  path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
  expires (Date): absolute expiration date for the cookie
  maxAge (number): relative max age of the cookie from when the client receives it in seconds
  domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
  secure (boolean): Is only accessible through HTTPS?
  httpOnly (boolean): Is only the server can access the cookie?
  sameSite (boolean|none|lax|strict): Strict or Lax enforcement
  */

  if (parseInt(validDayCount) < 1) validDayCount = 30;

  let opts = {
    path: '/',
    domain: 'localhost',
    secure: false
  };

  let date = new Date();
  date.setDate(date.getDate() + validDayCount);
  opts.expires = date;

  if (process.env.NODE_ENV !== 'development') {
    opts.domain = ".idplans.com";
    opts.secure = true;
  }

  return opts;
};

/**
 * Provide cookie options based on environment
 */
const formatThousands = (number, fixed = 2) => {
  if (!number) return (0.000000000).toFixed(fixed);
  let num = `${number.toFixed(fixed).toString().split('.')[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  if (fixed > 0) {
    num = `${num}.${number.toFixed(fixed).toString().split('.')[1]}`;
  }
  return num;
};

/**
 * Checks that tags (i.e. (),{},[]) are closed and nested properly in a given string
 *
 * @param {String} str string to test
 * @param {String} str opening tag
 * @param {String} str closing tag
 */
const hasMatchingEnclosingTags = (str, openingTag, closingTag) => {
  let openingTagCount = 0;
  let closingTagCount = 0;
  let matchingTags = true;
  let re = new RegExp(`${openingTag}|${closingTag}`, 'g');
  let matches = str.match(re);

  matches && matches.forEach(match => {
    if (match.match(openingTag)) openingTagCount++;
    if (match.match(closingTag)) closingTagCount++;
    if (openingTagCount < closingTagCount) {
      matchingTags = false;
    }
  });

  return matchingTags && openingTagCount === closingTagCount;
}

/**
 * Checks to see if a given string has bootstrap classes
 *
 * @param {String} str string to test
 */
const hasBootstrapClass = (str) => {
  if (!str) return false;
  let hasBootstrapClass = false;

  for (let bootstrapClass of bootstrapClassList) {
    let re = new RegExp(bootstrapClass, 'g');
    if (str.match(re)) {
      hasBootstrapClass = true;
      break;
    }
  }

  return hasBootstrapClass;
};

/**
 * Slugify function equivalent to the one we use in the
 * API. It takes a `str` string and turns it into a
 * URL-friendly value.
 *
 * @param {String} str string to slugify
 * @param {Integer} len max response length
 */
const slugify = (str, len = 64) => {
  if (str === undefined || str === null) str = '';
  return str.toString().trim().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .substr(0, len);
};

/**
 * Converts a "truthy" or "falsy" boolean string into a proper boolean value.
 * Matches:
 * - "true", "1", "on", "yes"
 * - Ignores whitespaces
 * - Case insensitive
 *
 * @example
 * ```js
 * const isItTrue = parseBooleanString("TRUE")
 * // => `true`
 * ```
 *
 * @param {string} stringToParse
 * @returns {Boolean}
 */
const parseBooleanString = (stringToParse) => {
  return (/^\s*(true|1|on|yes)\s*$/i).test(stringToParse)
}

/**
 * backfills status with status reason if not published and status reason exists
 * otherwise just shows the existing status, this is for backwards compat for non
 * crm sync'd profiles (crm status)
 *
 * @param {Array<objects>} records, an array of profile records
 */
const normalizeProfileStatus = (records) => {
  records.forEach(profile => {
    if (profile?.status !== 'published') {
      profile.status = profile?.crmStatusReason || profile?.status;
    }
  });
  return records;
}

/**
 * converts json array of records to xls
 * export and opens download in new window
 *
 * @param {Array<objects>} records, an array of any type of record
 * @param {String} type, string to prepend default name with
 */
const exportData = (records, type = '') => {
  // max filename length per XLSX is 31 characters
  type = type.substring(0, 15)
  const filename = `${type}${type ? '_' : ''}${moment().format('YYYY-MM-DD')}.xlsx`
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(records);
  XLSX.utils.book_append_sheet(wb, ws, filename);
  XLSX.writeFile(wb, filename)
}

/**
 * capitalize the first letter of each word.
 *
 * @param {Number} string
 */
const titleCase = str => {
  return str
    .split(' ')
    .map(_ => `${_[0].toUpperCase()}${_.slice(1).toLowerCase()}`)
    .join(' ');
};

export {
  get,
  isValidEmail,
  isValidPhone,
  isValidUrl,
  parseNumber,
  getCookieOptions,
  formatThousands,
  hasBootstrapClass,
  hasMatchingEnclosingTags,
  formatPhone,
  slugify,
  parseBooleanString,
  normalizeProfileStatus,
  exportData,
  titleCase
};
