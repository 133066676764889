import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import styles from './DefaultFooter.module.css';

/**
 * Component that renders a "default" footer for the
 * AdminModal. It supports closing, saving, deleting and
 * delete confirmation behaviors.
 */
export const DefaultFooter = ({
  closeButtonLabel,
  isCloseButtonDisabled,
  deleteButtonLabel,
  deleteCancellationButtonLabel,
  deleteConfirmationButtonLabel,
  isDeleteButtonDisabled,
  isDeleteConfirmationButtonDisabled,
  isOnDeleteMode,
  isSaveButtonDisabled,
  onCloseButtonClick = () => { },
  onDeleteButtonClick = () => { },
  onDeleteCancellationButtonClick = () => { },
  onDeleteConfirmationButtonClick = () => { },
  onSaveButtonClick = () => { },
  showDeleteButton,
  showSaveButton,
  hideCloseButton,
  saveButtonLabel,
  darkMode
}) => {
  const CloseButton = () => (
    <Button
      className={`${styles.buttonSecondary} ${styles.btnClose}`}
      disabled={isCloseButtonDisabled}
      onClick={onCloseButtonClick}
      data-testid="adminmodal-defaultfooter-closebutton"
      datacy="admin-modal-close-button"
    >{closeButtonLabel}</Button>
  );

  const DeleteButton = () => (
    <Button
      disabled={isDeleteButtonDisabled}
      variant="outline-danger"
      onClick={onDeleteButtonClick}
      className={`${styles.adminModalDestroyButton} ${styles.btnDelete}`}
      data-testid="adminmodal-defaultfooter-deletebutton"
      datacy="admin-modal-delete-button"
    >{deleteButtonLabel}</Button>
  );

  const DeleteCancellationButton = () => (
    <Button
      className={`${styles.buttonSecondary} btn`}
      onClick={onDeleteCancellationButtonClick}
      data-testid="adminmodal-defaultfooter-deletecancellationbutton"
      datacy="admin-modal-delete-cancellation-button"
    >{deleteCancellationButtonLabel}</Button>
  );

  const DeleteConfirmationButton = () => (
    <Button
      variant="danger"
      className={styles.deleteButton}
      onClick={onDeleteConfirmationButtonClick}
      disabled={isDeleteConfirmationButtonDisabled}
      data-testid="adminmodal-defaultfooter-deleteconfirmationbutton"
      datacy="admin-modal-delete-confirmation-button"
    >{deleteConfirmationButtonLabel}</Button>
  );

  const SaveButton = () => (
    <Button
      className={`${styles.buttonPrimary} ${styles.btnSave}`}
      onClick={onSaveButtonClick}
      disabled={isSaveButtonDisabled}
      data-testid="adminmodal-defaultfooter-savebutton"
      datacy="admin-modal-save-button"
    >{saveButtonLabel}</Button>
  );

  const MainButtonPanel = () => (
    <>
      {showDeleteButton && <DeleteButton />}
      {!hideCloseButton && <CloseButton />}
      {showSaveButton && <SaveButton />}
    </>
  );

  const DeleteModeButtonPanel = () => (
    <>
      <DeleteCancellationButton />
      <DeleteConfirmationButton />
    </>
  );

  return (
    <div className={`${darkMode ? styles.darkMode : ''} ${styles.btnContainer} ${isOnDeleteMode ? styles.twoButtonsOnExtremes : ''}`}>
      {!isOnDeleteMode && <MainButtonPanel />}
      {isOnDeleteMode && <DeleteModeButtonPanel />}
    </div>
  );
};

DefaultFooter.propTypes = {
  /** Label for the "close" button. */
  closeButtonLabel: PropTypes.string.isRequired,
  /**
   * Enables/disables the "close" button.
   * @default false
   */
  isCloseButtonDisabled: PropTypes.bool,
  /** Label for the "delete" button. */
  deleteButtonLabel: PropTypes.string.isRequired,
  /** Label for the "cancel deletion" button. */
  deleteCancellationButtonLabel: PropTypes.string.isRequired,
  /** Label for the "confirm deletion" button. */
  deleteConfirmationButtonLabel: PropTypes.string.isRequired,
  /**
   * Enables/disables the "delete" button.
   * @default false
   */
  isDeleteButtonDisabled: PropTypes.bool,
  /**
   * Enables/disables the "confirm deletion" button.
   * @default false
   */
  isDeleteConfirmationButtonDisabled: PropTypes.bool,
  /**
   * Shows/hides all delete-related buttons.
   * @default false
   */
  isOnDeleteMode: PropTypes.bool,
  /**
   * Enables/disables the "save" button.
   * @default false
   */
  isSaveButtonDisabled: PropTypes.bool,
  /**
   * Called when the "close" button gets clicked.
   * @default () => void
   */
  onCloseButtonClick: PropTypes.func,
  /**
   * Called when the "delete" button gets clicked.
   * @default () => void
   */
  onDeleteButtonClick: PropTypes.func,
  /**
   * Called when the "cancel deletion" button gets clicked.
   * @default () => void
   */
  onDeleteCancellationButtonClick: PropTypes.func,
  /**
   * Called when the "confirm deletion" button gets clicked.
   * @default () => void
   */
  onDeleteConfirmationButtonClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool
  ]),
  /**
   * Called when the "save" button gets clicked.
   * @default () => void
   */
  onSaveButtonClick: PropTypes.func,
  /**
   * Shows/hides the "delete" button.
   * @default false
   */
  showDeleteButton: PropTypes.bool,
  /**
   * Shows/hides the "save" button.
   * @default false
   */
  showSaveButton: PropTypes.bool,
  /** Label for the "save" button. */
  saveButtonLabel: PropTypes.string.isRequired,
  /** Dark mode */
  darkMode: PropTypes.bool,
};

export default DefaultFooter;
