import React, { useState } from 'react';
import styles from './TagSelector.module.css';
import Remove from '../assets/images/remove.js';
import { useIntl } from 'react-intl';

const TagSelector = ({values = [], options = [], setValues, label = '', height, isInvalid, onBlur, placeholder, invalidationMessage, customCategoryFilter = _ => true, setCustomSearch = _ => true, clearHandler, className, hideSelectedTags, identifierClass}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [search, setSearch] = useState('');
  const [showTagList, setShowTagList] = useState(false);

  const optionsFiltered = options.filter(option => option.label.toLowerCase().match(new RegExp(search.toLowerCase()))).filter(option => !values.includes(option.value)).filter(customCategoryFilter);

  const optionsList = optionsFiltered.map((option, i) => 
    <div key={'' + option.id + i} onClick={() => {setValues([...values, option.value]); setSearch(''); setCustomSearch('');}}>{option.label}</div>
  )

  const selectedTags = values.map(value => {
    const selectedOption = options.find(_ => _.value === value);
    if (!selectedOption) return null;
    return (
      <div className={styles.tag} key={value}>
        <div className={styles.tagLabel}>{selectedOption.label}</div>
        <div className={styles.tagRemove} onClick={() => setValues(values.filter(_ => _ !== selectedOption.value))}><Remove /></div>
      </div>
    )
  })

  return (
    <div className={`${styles.tagSelector} ${className}`} onBlur={onBlur}>
      <div>
        <div className={styles.inputTag}>
          <label htmlFor={label}>{label}</label>
          <input className={identifierClass} style={isInvalid ? {border: '1px solid #dc3545'} : {}} name={label} id={label} placeholder={placeholder || t('TagSelector.placeholder')} type="text" onChange={(e) => {setSearch(e.target.value); setCustomSearch(e.target.value)}} value={search} onFocus={() => setShowTagList(true)} onBlur={() => setTimeout(() => setShowTagList(false), 200)} />
        </div> 
      </div>
      {showTagList &&
        <div className={styles.tagList}>
          <div>
            {optionsList.length ? optionsList : <div>{t('TagSelector.noOptions')}</div>}
          </div>
        </div>
      }
      {!hideSelectedTags &&
        <div id="tags" className={styles.selectedTags} style={{height: height ? height : undefined, border: isInvalid ? '1px solid #dc3545' : undefined}}>
        <div>
          {selectedTags}
          {clearHandler &&
            <div className={`${styles.tag} ${styles.removeTags}`}>
              <div className={styles.tagLabel}>{t('TagSelector.clear')}</div>
              <div className={styles.tagRemove} onClick={clearHandler}><Remove /></div>
            </div>
          }
        </div>
      </div>
      }
      {isInvalid &&
        <small style={{color: '#dc3545'}}>{invalidationMessage || t('TagSelector.select')}</small>
      }
    </div>
  )
}

export default TagSelector;