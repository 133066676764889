import React from 'react'
import { useTranslations, useUserActions } from '../../utils/hooks'
import DashboardCard from '../DashboardCard/DashboardCard'
import { InspectTable as WorkOrdersTable, TABLE_TYPES } from '@project/components/src/DataTables'
import PropTypes from 'prop-types'
import axios, { isCancel, CancelToken } from '../../utils/axios'
import { get } from '../../utils/utils'

const WorkOrders = ({
  currentCompany,
  currentProperty,
  currentUser,
  companySettings,
  setErrors
}) => {
  const [t] = useTranslations();
  //Possible this needs to be updated if we decide to have seperate acls for inspection and work order admin
  //inspection:ui:admin => workorders:ui:admin
  const hasInspectAdminRole = useUserActions(get(currentCompany, 'value'), get(currentProperty, 'value'), 'inspection:ui:admin', setErrors);

  const isAdmin = hasInspectAdminRole && get(companySettings, 'inspections.enabled');

  return <DashboardCard title={t('term.workOrders')}>
    <WorkOrdersTable
      currentUser={currentUser}
      currentCompany={currentCompany}
      isAdmin={isAdmin}
      toast={() => { }}
      setError={setErrors}
      external={true}
      axios={axios}
      CancelToken={CancelToken}
      isCancel={isCancel}
      type={TABLE_TYPES.WORK_ORDER}
      rowPlaceholderHeight={'0px'}
    />
  </DashboardCard>
}

WorkOrders.propTypes = {
  currentCompany: PropTypes.object,
  currentProperty: PropTypes.object,
  currentUser: PropTypes.object,
  companySettings: PropTypes.object,
  setErrors: PropTypes.func
}

export default WorkOrders;