/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import axios from '../../utils/axios'
import { get } from '../../utils/utils'
import moment from 'moment'
import { useIntl } from 'react-intl'
import scopes from './scopes'

/**
 * Using the provided filters, creates a request URL for /rpms and /rpms/page-count.
 * 
 * @returns string
 */
const buildRequestURLs = ({
  currentCompany,
  currentProperty,
  subscriptionsTrackerFilter,
  subscriptionsTrackerDateRange,
  subscriptionsTrackerScope,
  subscriptionsTrackerPage
}) => {
  const companySlug = get(currentCompany, 'slug')
  const propertySlug = get(currentProperty, 'slug')

  const rpmUrlParams = new URLSearchParams()
  rpmUrlParams.append('company', companySlug)

  if (propertySlug) {
    rpmUrlParams.append('property', propertySlug)
  }

  if (subscriptionsTrackerFilter) {
    rpmUrlParams.append('search', subscriptionsTrackerFilter)
  }

  if (subscriptionsTrackerDateRange) {
    rpmUrlParams.append('period', subscriptionsTrackerDateRange.value)
  }

  if (subscriptionsTrackerScope) {
    rpmUrlParams.append('scope', subscriptionsTrackerScope.value)
  }

  const rpmPageCountUrl = `/rpms/page-count?${rpmUrlParams.toString()}`

  if (subscriptionsTrackerPage) {
    rpmUrlParams.append('page', subscriptionsTrackerPage)
  }

  const rpmUrl = `/rpms?${rpmUrlParams.toString()}`

  return { rpmPageCountUrl, rpmUrl }
}

/**
 * Creates the necessary state getter/setters for request parameters.
 */
const useRpmSubscriptionsRequestParameters = () => {
  const [subscriptionsTrackerPage, setSubscriptionsTrackerPage] = useState(1)
  const [subscriptionsTrackerFilter, setSubscriptionsTrackerFilter] = useState('')
  const [subscriptionsTrackerDateRange, setSubscriptionsTrackerDateRange] = useState('')
  const [subscriptionsTrackerScope, setSubscriptionsTrackerScope] = useState('')

  return [
    subscriptionsTrackerPage,
    setSubscriptionsTrackerPage,
    subscriptionsTrackerFilter,
    setSubscriptionsTrackerFilter,
    subscriptionsTrackerDateRange,
    setSubscriptionsTrackerDateRange,
    subscriptionsTrackerScope,
    setSubscriptionsTrackerScope
  ]
}

/**
 * Calls /rpms/page-count and stores the response using setTotalItems.
 */
const fetchPageCount = async ({ rpmPageCountUrl, setTotalItems }) => {
  return axios.get(rpmPageCountUrl).then(({ data: pageCount }) => {
    setTotalItems(Number(pageCount.data))
  })
}

/**
 * Calls /rpms and passes along the response.
 */
const fetchData = async ({ rpmUrl }) => axios.get(rpmUrl)

/**
 * Calculates availability and completion dates for unlimited scope subscriptions.
 */
const estipulateDatesForUnlimitedScopeSubscriptions = (subscription, t, intl) => {
  if (subscription.limitedScope) {
    return
  }

  // Since this subscription includes the work for *all* scopes/sections,
  // the availability and completion dates are global. In that case,
  // we copy all of the data to each scope's individual availability date
  // for easier rendering.

  for (const scope of scopes) {
    // If no global availability start date is defined, use the target start date.
    // If no global availability end date is defined, use the delivered date.
    subscription[`scope${scope}AvailabilityStart`] = subscription.availabilityStart || subscription.targetStartDate
    subscription[`scope${scope}AvailabilityEnd`] = subscription.availabilityEnd || subscription.deliveredDate

    // Assume delivered date as scope completion date.
    subscription[`scope${scope}Completed`] = subscription.deliveredDate

    // The scope is marked as true if it has been surveyed.
    subscription[`scope${scope}`] = moment().isSameOrAfter(subscription[`scope${scope}AvailabilityEnd`])
  }

  // If the subscription work is completed, the legend will say `Completed {date}`.
  // If not, it means it hasn't started yet, so theh legend will say `Scheduled {date}`.
  let completedOrScheduledTranslationKey = ''
  let completedOrScheduledDate = ''

  if (subscription.deliveredDate) {
    completedOrScheduledTranslationKey = t('term.completedDate')
    completedOrScheduledDate = subscription.deliveredDate
  } else {
    completedOrScheduledTranslationKey = t('term.scheduledDate')
    completedOrScheduledDate = subscription.availabilityEnd
  }

  // It could happen that no work has started yet, so we use the subscription's
  // target start date.
  if (!completedOrScheduledDate) {
    completedOrScheduledDate = subscription.targetStartDate
  }

  subscription.allScopesTitle = [
    completedOrScheduledTranslationKey,
    `${moment(completedOrScheduledDate)
      .locale(intl.locale)
      .format('dddd, MMMM D, YYYY')}`
  ].join(' ')
}

/**
 * Based on the subscription and scope statuses, sets which icon theme to use.
 * Icon will be colored or grayed (uncolored) according to whether it is included or not in the subscription.
 */
const assignIconAndLabelForScope = (subscription, scope, t, intl) => {
  subscription[`scope${scope}Icon`] = scope.toLowerCase()
  subscription[`scope${scope}IconColorTheme`] = subscription[`scope${scope}Completed`] ? 'colored' : 'uncolored'
  subscription[`scope${scope}Label`] = t(`Dashboard.subscriptionsTracker.scopes.${scope.toLowerCase()}`)
}


const assignTitleForScope = (subscription, scope, t, intl) => {
  // If the subscription work is completed, the legend will say `Completed {date}`.
  // If not, it means it hasn't started yet, so theh legend will say `Scheduled {date}`.
  const isScopeCompleted = subscription[`scope${scope}Completed`] !== null
  let completedOrScheduledTranslationKey = ''
  let completedOrScheduledDate = ''

  if (isScopeCompleted) {
    completedOrScheduledTranslationKey = t('term.completedDate')
    completedOrScheduledDate = subscription[`scope${scope}Completed`]
  } else {
    completedOrScheduledTranslationKey = t('term.scheduledDate')
    completedOrScheduledDate = subscription[`scope${scope}AvailabilityEnd`] || subscription.targetStartDate
  }

  subscription[`scope${scope}Title`] = [
    completedOrScheduledTranslationKey,
    `${moment(completedOrScheduledDate)
      .locale(intl.locale)
      .format('dddd, MMMM D, YYYY')}`
  ].join(' ')
}

export const useRpmSubscriptionsTrackerData = ({
  currentCompany,
  currentProperty,
  setLoading,
  setTotalItems
}) => {
  const [subscriptionsTrackerData, setSubscriptionsTrackerData] = useState([])
  const [
    subscriptionsTrackerPage,
    setSubscriptionsTrackerPage,
    subscriptionsTrackerFilter,
    setSubscriptionsTrackerFilter,
    subscriptionsTrackerDateRange,
    setSubscriptionsTrackerDateRange,
    subscriptionsTrackerScope,
    setSubscriptionsTrackerScope
  ] = useRpmSubscriptionsRequestParameters()

  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  // Reset pagination when company or property change.
  useEffect(() => {
    setSubscriptionsTrackerPage(0)
  }, [currentCompany, currentProperty])

  // Update records if filters change.
  useEffect(() => {
    if (!currentCompany) {
      return
    }

    setLoading(true)

    const { rpmPageCountUrl, rpmUrl } = buildRequestURLs({
      currentCompany,
      currentProperty,
      subscriptionsTrackerFilter,
      subscriptionsTrackerDateRange,
      subscriptionsTrackerScope,
      subscriptionsTrackerPage
    })

    fetchPageCount({ rpmPageCountUrl, setTotalItems })
      .then(() => fetchData({ rpmUrl }))
      .then(response => {
        const { data: subscriptions } = response.data
        const transformedData = subscriptions.map(subscription => {
          estipulateDatesForUnlimitedScopeSubscriptions(subscription, t, intl)

          for (const scope of scopes) {
            assignIconAndLabelForScope(subscription, scope, t, intl)
            assignTitleForScope(subscription, scope, t, intl)
          }

          return subscription
        })
        setLoading(false)
        setSubscriptionsTrackerData(transformedData)
      })
  }, [
    currentCompany,
    currentProperty,
    subscriptionsTrackerPage,
    subscriptionsTrackerFilter,
    subscriptionsTrackerDateRange,
    subscriptionsTrackerScope
  ])

  return [
    subscriptionsTrackerData,
    subscriptionsTrackerPage,
    setSubscriptionsTrackerPage,
    setSubscriptionsTrackerFilter,
    subscriptionsTrackerDateRange,
    setSubscriptionsTrackerDateRange,
    subscriptionsTrackerScope,
    setSubscriptionsTrackerScope
  ]
}