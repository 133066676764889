import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AdminModal from '../AdminModal/AdminModal';
import FileList from '../FileList/FileList';
import FileListFile from '../FileList/FileListFile';
import ActionButtons from './ActionButtons';
import Footer from './Footer'

/**
 * This is a modal that centralizes general file uploads. It displays a
 * list of already uploaded files with different columns for relevant file
 * data, sorting and searching of uploaded files, and support for new
 * file uploads.
 *
 * This component uses the `react-intl` hook to translate button labels,
 * so an Intl context is required in the app.
 */
const FileUploadHub = ({
  errors,
  files,
  isUploading,
  onClose,
  onDelete,
  onFileSelected,
  readOnly,
  show,
  title,
  dialogClassName = 'modal-xl',
  hideClose,
  listLayout = 'table', // or 'list'
  listRenderer: ListRenderer = () => { },
  showSubmitButton,
  onSubmit,
  fileFormats = []
}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  // When the "upload" button on the footer returns a file,
  // a new FileListFile gets created with critical data to render on
  // the file list table, then gets passed to the onFileSelected prop
  // so that the upload can be managed from the implementation.
  const handleSelectedFile = (rawFile) => {
    const internalFileInstance = new FileListFile({
      name: rawFile.name,
      size: rawFile.size,
      uploadDate: Date.now(),
    });

    onFileSelected(internalFileInstance, rawFile);
  };

  const UploaderModalFooter = (
    <Footer
      fileFormats={fileFormats}
      errors={errors}
      isUploading={isUploading}
      onClose={onClose}
      onFileSelected={handleSelectedFile}
      hideClose={hideClose}
      readOnly={readOnly}
      showSubmitButton={showSubmitButton}
      canSubmit={files.length === 1 && !isUploading && !errors}
      onSubmit={onSubmit}
    />
  );

  /* File list settings below */
  // Hidden some columns from the reusable component + translated labels
  const fileListColumnOptions = {
    id: {
      hidden: true,
    },
    download: {
      hidden: true,
    },
    name: {
      label: t('ui.name'),
    },
    size: {
      label: t('ui.size'),
    },
    uploadDate: {
      label: t('ui.uploadDate'),
    },
    uploadUser: {
      label: t('ui.uploadedBy'),
    },
  };
  // Buttons for download and delete actions, at the end of the row
  const fileListExtraFields = [{
    id: 'actionButtons',
    type: 'fragment',
    renderFragment: (file) => (
      <ActionButtons file={file} onDelete={onDelete} readOnly={readOnly} />
    ),
  }];
  // Custom column widths to give more room for important data
  const fileListColumnWidths = [
    '30%', // name
    '10%', // size
    '20%', // uploaded by
    '20%', // upload date
    '20%', // action buttons
  ];

  return (
    <AdminModal
      centered={true}
      dialogClassName={dialogClassName}
      footer={UploaderModalFooter}
      show={show}
      title={title}
      onHide={onClose}
      preventDismiss={hideClose}
      onSave={() => { }}
    >
      {listLayout === 'table' && <FileList
        columnOptions={fileListColumnOptions}
        customWidths={fileListColumnWidths}
        extraFields={fileListExtraFields}
        files={files}
      />}
      {listLayout === 'list' && <ListRenderer files={files} />}
    </AdminModal>
  );
};

FileUploadHub.propTypes = {
  /** String with errors for the "upload" section */
  errors: PropTypes.string,
  /** Array of already uploaded files. Should be instances of FileListFile */
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileListFile)),
  /** Flag to toggle the "uploading" state */
  isUploading: PropTypes.bool,
  /** Called when the close button gets clicked */
  onClose: PropTypes.func,
  /**
   * Called when the delete button gets clicked.
   * It receives the current FileListFile instance as an argument.
   */
  onDelete: PropTypes.func,
  /**
   * Controls the read-only state of the component. If `true`, it will
   * show static data and disable uploads or deletions.
   */
  readOnly: PropTypes.bool,
  /**
   * Called when the upload button gets clicked and the user selects a file.
   * It receives two arguments:
   * - An instance of FileListFile: this object can be appended to the array of
   *    files passed via the `files` prop. It also stores important file info that
   *    might want to store in the backend.
   * - The raw file selected by the user: this is the raw data to be uploaded to
   *    an external service like S3.
   */
  onFileSelected: PropTypes.func,
  /** Show/hide the modal */
  show: PropTypes.bool,
  /** Custom title for the modal */
  title: PropTypes.string,
};

FileUploadHub.defaultProps = {
  errors: null,
  files: [],
  isUploading: false,
  onClose: () => { },
  onDelete: () => { },
  onFileSelected: () => { },
  readOnly: false,
  show: false,
  title: 'Upload Files',
}

export default FileUploadHub;