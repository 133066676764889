import React, {useMemo} from 'react';

// This component, given a string, renders a circle with two letters 
// (either the first letters of the first two words or the first two 
// letters if only one word).
const CircleImage = ({
  label = '',
  className
}) => {
  let optionLabelLetters = useMemo(() => {
      let splitLabelWords = label.split(' ');
      return splitLabelWords.length > 1 ?
          (splitLabelWords[0].slice(0, 1) + splitLabelWords[1].slice(0, 1)) :
          splitLabelWords[0].slice(0, 2)
  }, [label]);

  return <div data-testid="circle-title" id="circle-title" className={`${className} d-flex justify-content-center align-items-center`}>
      {optionLabelLetters}
    </div>
}

const OptionImage = ({
  icon, 
  label, 
  showImage, 
  className = '', 
  circleTitleClassName = '',
  iconClassName = ''
}) => {
  if (!showImage) return null;
  
  return <div className={`${className} position-absolute`}>
      {icon ? <div className={`${iconClassName} d-flex align-items-center justify-content-between`}>{icon}</div> : <CircleImage label={label} className={circleTitleClassName} />}
    </div>
}

export default OptionImage;