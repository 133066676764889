import React from 'react';

const UsersBold = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.69 8.54126H12.4526C12.5788 8.88657 12.6477 9.25923 12.6477 9.64764V14.3242C12.6477 14.4861 12.6195 14.6416 12.5682 14.7861H14.6138C15.378 14.7861 15.9996 14.1645 15.9996 13.4003V10.8509C15.9997 9.57739 14.9636 8.54126 13.69 8.54126Z" fill="currentColor" />
      <path d="M3.35204 9.64764C3.35204 9.2592 3.42094 8.88657 3.54707 8.54126H2.30969C1.03613 8.54126 0 9.57739 0 10.851V13.4004C0 14.1645 0.621657 14.7862 1.38582 14.7862H3.43151C3.38023 14.6416 3.35204 14.4861 3.35204 14.3242V9.64764Z" fill="currentColor" />
      <path d="M9.41438 7.33813H6.58557C5.312 7.33813 4.27588 8.37426 4.27588 9.64783V14.3244C4.27588 14.5795 4.48269 14.7863 4.73782 14.7863H11.2621C11.5173 14.7863 11.7241 14.5795 11.7241 14.3244V9.64783C11.7241 8.37426 10.6879 7.33813 9.41438 7.33813Z" fill="currentColor" />
      <path d="M8.00034 1.21362C6.46872 1.21362 5.22266 2.45969 5.22266 3.99135C5.22266 5.03026 5.79606 5.9376 6.64287 6.41398C7.04453 6.63992 7.50759 6.76904 8.00034 6.76904C8.49309 6.76904 8.95615 6.63992 9.35781 6.41398C10.2047 5.9376 10.778 5.03022 10.778 3.99135C10.778 2.45972 9.53197 1.21362 8.00034 1.21362Z" fill="currentColor" />
      <path d="M3.12276 3.80273C1.97729 3.80273 1.04541 4.73461 1.04541 5.88008C1.04541 7.02555 1.97729 7.95743 3.12276 7.95743C3.41332 7.95743 3.69001 7.89724 3.94135 7.78906C4.37592 7.60196 4.73423 7.27077 4.95595 6.85593C5.11157 6.56477 5.20011 6.23261 5.20011 5.88008C5.20011 4.73464 4.26823 3.80273 3.12276 3.80273Z" fill="currentColor" />
      <path d="M12.8776 3.80273C11.7322 3.80273 10.8003 4.73461 10.8003 5.88008C10.8003 6.23265 10.8888 6.5648 11.0445 6.85593C11.2662 7.2708 11.6245 7.60199 12.059 7.78906C12.3104 7.89724 12.5871 7.95743 12.8776 7.95743C14.0231 7.95743 14.955 7.02555 14.955 5.88008C14.955 4.73461 14.0231 3.80273 12.8776 3.80273Z" fill="currentColor" />
    </svg>
  );
}

export default UsersBold;