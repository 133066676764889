import React from 'react';

const DownArrow = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 1.5L5 5L1.5 1.5" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  )
}

export default DownArrow;