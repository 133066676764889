import React from 'react';
import { Spinner as Spnr } from 'react-bootstrap';
import './Spinner.css';

export default function Spinner({ size, withBackground }) {

  let css;
  switch (size) {
    case "large":
      css = "SpinnerLarge";
      break;
    case "medium":
      css = "SpinnerMedium";
      break;
    case 'small':
    default:
      css = "SpinnerSmall";
      break;
  }

  const bggStyle = {
    "position": "absolute",
    "height": "100vh",
    "width": "100vw"
  };

  const bgStyle = {
    "position": "absolute",
    "height": "100vh",
    "width": "100vw",
    "opacity": "0.3",
    "backgroundColor": "#000000"
  };

  let content = (
    <Spnr animation="grow"
      className={css}
      variant="primary" />
  );

  if (withBackground) {
    content = (
      <div style={bgStyle}>
        <Spnr animation="grow"
          className={css}
          variant="primary" />
      </div>
    )
  }

  return (
    <div style={bggStyle}>
      {content}
    </div>
  );

}
