/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { AdminToast } from '@project/components';
import PropertySelect from '../../components/PropertySelect/PropertySelect';
import PropertySettingsTabs from '../PropertySettingsTabs/PropertySettingsTabs';
import styles from './PropertySettings.module.css';
import axios from '../../utils/axios';
import Axios, { CancelToken } from 'axios';
import { Alert } from 'react-bootstrap';
import { useUserActions } from '../../utils/hooks';
import UserCompaniesSelect from '../../components/UserCompaniesSelect/UserCompaniesSelect'
import { useIntl } from 'react-intl';
import Title from '../../components/Title/Title';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { RootContext } from '../../hoc/RootContext/RootContext';

const classNames = require('classnames');

export default function PropertySettings() {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { currentUser } = useContext(RootContext);

  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSuccessPdf, setShowSuccessPdf] = useState(false);
  const [errors, setErrors] = useState(null);
  const [propertySettings, setPropertySettings] = useState(null);

  const canEditCompanyProperties = useUserActions(selectedCompany && selectedCompany.value, null, "properties:edit", setErrors);
  const canEditProperty = useUserActions(selectedCompany && selectedCompany.value, selectedProperty && selectedProperty.value, 'properties:edit', setErrors);

  const onSavePropertySettings = async (propertySettings) => {
    if (isSaving) return
    setIsSaving(true)
    axios.put(`/properties/${propertySettings.slug}/${propertySettings.companySlug}?pdfKey=iddigital_overview_pdf&slugged=true&company=${selectedCompany.value}&property=${selectedProperty.value}`, propertySettings).then(res => {
      if (res.status === 200) {
        setShowSuccess(true)
      } else if (res.status === 400) {
        setErrors(res.data.errors)
      }
    }).catch(e => {
      // no-op
    }).finally(() => {
      setIsSaving(false)
    })
  }

  const onGenerateIdDigitalPdf = async (currentPdf, pdfKey) => {
    try {
      await axios.put(`/properties/${propertySettings.slug}/${propertySettings.companySlug}?pdfKey=${pdfKey}&slugged=true&company=${selectedCompany.value}&property=${selectedProperty.value}`, { [pdfKey]: currentPdf });
      setShowSuccessPdf(true);
    } catch (e) {
      setErrors(`${t('error.general')}: ${e.errors}`);
    }
  }

  // retrieves property record when company and property are selected
  useEffect(() => {
    const source = CancelToken.source();
    // retrieve property to get up-to-date settings
    async function fetchProperty() {
      try {
        const result = await axios.get(`/properties/${encodeURIComponent(selectedCompany.value)}/${encodeURIComponent(selectedProperty.value)}`, { cancelToken: source.token });
        if (result.status === 200) {
          const data = result.data.record;
          setPropertySettings(data);
        } else {
          setErrors(`There was an error: ${result.errors}`);
        }
      } catch (e) {
        if (Axios.isCancel(e)) {
          // request is cancelled
        } else {
          throw e;
        }
      }
    };

    if (selectedProperty) {
      fetchProperty();
    }

    return () => {
      source.cancel();
    }
  }, [selectedProperty]);

  return (
    <PageContentContainer>
      <Title title={t('PropertySettings.mainTitle')} />
      <main className={classNames(styles.settings, 'row mt-4 shadow-sm bg-white border mx-auto')}>
        <AdminToast onClose={() => { setShowSuccess(false); setShowSuccessPdf(false); }} show={showSuccess || showSuccessPdf} message={t('PropertySettings.toast.pdf')} style={{ top: '75px', right: '45px' }} />
        <AdminToast onClose={() => setShowSuccess(false)} show={showSuccess} message={t('PropertySettings.toast.save')} style={{ top: '15px', right: '45px' }} />
        <div className="col-xl-12 px-4">
          <div className="mb-5 mt-3">
            {errors ? <Alert key={errors} variant='danger'>
              {Array.isArray(errors) ? errors.map((error, i) => <div key={`error${i}`}>{error.message}</div>) : <>{errors.generic || `${t('error')}: ${errors}`}</>}
            </Alert> : null}
            <div className="col-md-6 pl-0">
              <UserCompaniesSelect label={t('term.company')} setSelectedCompany={setSelectedCompany} selectAll={false} selectedValue={currentUser && currentUser.company} show={currentUser && currentUser.companies.length > 1} defaultPropStyles={true} />
            </div>
            {canEditCompanyProperties &&
              <div className="col-md-6 pl-0">
                <PropertySelect selectedCompany={selectedCompany} selectedProperty={selectedProperty} setSelectedProperty={setSelectedProperty} />
              </div>}
          </div>
          {selectedProperty && propertySettings && canEditProperty &&
            <PropertySettingsTabs
              propertySettings={propertySettings}
              onSavePropertySettings={onSavePropertySettings}
              setPropertySettings={setPropertySettings}
              setSelectedProperty={setSelectedProperty}
              setErrors={setErrors}
              isSaving={isSaving}
              onGenerateIdDigitalPdf={onGenerateIdDigitalPdf} />}
        </div>
      </main>
    </PageContentContainer>
  );
};