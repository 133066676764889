import React, { useEffect, useState } from 'react';
import { Overlay, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import styles from './Tooltip.module.css';
import './Tooltip.css';

const Tooltip = ({ label, details, target, show, placement = "left", update, className = '' }) => {
  const [showTooltip, setShowTooltip] = useState(null);

  useEffect(() => {
    setShowTooltip(show);
  }, [show, update])

  return (
    <Overlay target={target.current} show={showTooltip} placement={placement}>
      {(props) => (
        <BootstrapTooltip {...props} className={`${styles.tooltip} ${className}`} data-testid="rulesTooltip">
          <div className={styles.tooltipBody}>
            <div className={styles.heading}>{label}</div>
            <div className={styles.details}>{details}</div>
          </div>
        </BootstrapTooltip>
      )}
    </Overlay>
  )
}
export default Tooltip;