import React, { useState, useEffect, useContext } from 'react';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import { RootContext } from './../../hoc/RootContext/RootContext';
import { useIntl } from 'react-intl';
import axios from "../../utils/axios";
import {AVAILABLE_REPORTS} from './../../constants';
// import { CardDeck } from 'react-bootstrap';
import { Alerts, Spinner } from '@project/components';
import { useParams } from 'react-router-dom';
import Amp from './Amp/Amp';
import Title from '../../components/Title/Title';
import * as XLSX from 'xlsx'

const Report = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { currentUser } = useContext(RootContext);
  const { reportName } = useParams();
  const REPORT = AVAILABLE_REPORTS.find(_ => _.id === reportName);

  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState(null);
  const [companyRole, setCompanyRole] = useState(null);

  // fetch current company
  useEffect(() => {

    const fetch = async () => {
      try {
        setErrors(null);

        const encoded = encodeURIComponent(companyName)
        // we need to secure this report still even though it
        // happened on previous page in case they favorite it
        // or give someone else the link
        let result = await axios.get(`/companies/${encoded}`);
        if (result.status === 200) {
          setCompany(result.data.record);
        }

        // fetch acls
        let companyAcl;
        result = await axios.get(`/acls?username=${currentUser.username}&company=${encoded}`);
        if (result.status === 200) {
          // setCompanyRole
          companyAcl = result.data.rows.find((_) => {
            return _.company === companyName && !_.property && !_.section;
          });
        }

        let tmpRole;
        if (companyAcl) {
          result = await axios.get(`/roles/${encoded}/${companyAcl.roleid}`);
          if (result.status === 200) {
            tmpRole = result.data.record;
            setCompanyRole(result.data.record);
          }
        }

        const errs = [];
        if (!companyAcl) errs.push('A valid company ACL could not be found');
        if (!tmpRole) errs.push('A valid permissions role could not be found');

        // check user acl -> role.actions for report name
        if (tmpRole && !tmpRole.actions.includes(REPORT.requiredRoleAction)) errs.push('The specified report could not be authorized.');

        if (errs.length) setErrors(errs);

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`There was an error retrieving data from the server (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || 'The server is not responding.');
        }
      }

    };

    if (currentUser && companyName) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, companyName]);

  useEffect(() => {
    if (currentUser) {
      setCompanyName(currentUser.company);
    }
  }, [currentUser]);

  const printReport = (name, tableRef) => {
    const workbook = XLSX.utils.table_to_book(tableRef.current);
    XLSX.writeFile(workbook, `${name}.xlsx`);
  };

  let reportBody = <div>
    {errors && errors.length > 0 ?
      <div style={{ "marginTop": "20px" }}>
        <Alerts messages={errors} close={() => setErrors([])} />
      </div>
      : null
    }
    {
      REPORT.id === 'amp' ?
        <Amp printFunction={printReport} />
        : null
    }
  </div>;

  if (!currentUser) {
    reportBody = <PageContentContainer><h3 style={{ "color": "#990000" }}> Not Authorized</h3></PageContentContainer>
  }

  if (!errors && !companyRole) {
    reportBody = <Spinner />;
  }

  if (!REPORT) {
    reportBody = <PageContentContainer><h3 style={{ "color": "#990000" }}> Report not found.</h3></PageContentContainer>
  }

  return (
    <PageContentContainer>
      <Title title={t('Reports.title')} backRoute={'/reports'} />
      {reportBody}
    </PageContentContainer>
  );
};

export default Report;
