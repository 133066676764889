import {useState, useEffect, useContext} from 'react';
import { useIntl } from 'react-intl';
import axios, { CancelToken } from '../../utils/axios';
import { get } from '../../utils/utils';
import { getImageUrl } from '@project/components/src/utils/image-location-utils'
import moment from 'moment';
import DefaultProfilePicture from '../../assets/images/unknown-unit.png';
import { RootContext } from './../../hoc/RootContext/RootContext';

export const useSpaces = ({setErrors, setLoading, company, property }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const { currentProperty } = useContext(RootContext);

  const [state, setState] = useState([]);

  const currentPropertyValue = get(currentProperty, 'name', '');

  const getStatusColor = (space) => {
    const isOccupied = ({ isLeaseExpired }) => {
      return !isLeaseExpired;
    }
  
    const isExpiring = (_, months = 6) => {
      const currentDate = moment();
      const leaseEndDate = moment(_.leaseEnd);

      // Check if the lease has already expired
      if (leaseEndDate.isBefore(currentDate)) return false;

      const monthsUntilLeaseExpiration = leaseEndDate.diff(currentDate, 'months');

      return monthsUntilLeaseExpiration < months;
    }
  
    const isVacant = ({ isLeaseExpired }) => {
      return isLeaseExpired;
    }
  
    let statusColors = [
      { condition: isVacant, color: { backgroundColor: 'rgba(109, 113, 117, 0.1)', color: '#6D7175' } },
      { condition: (_) => isOccupied(_) && !_.leaseEnd, color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } },
      { condition: (_) => isExpiring(_, 3), color: { backgroundColor: 'rgba(206, 21, 20, 0.1)', color: '#CE1514' } },
      { condition: (_) => isExpiring(_, 6), color: { backgroundColor: 'rgba(255, 122, 0, 0.1)', color: '#FF7A00' } },
      { condition: (_) => isExpiring(_, 12), color: { backgroundColor: 'rgba(197, 192, 46, 0.1)', color: '#C5C02E' } },
      { condition: (_) => isExpiring(_, 24), color: { backgroundColor: 'rgba(129, 196, 41, 0.1)', color: '#81C429' } },
      { condition: (_) => isExpiring(_, 48), color: { backgroundColor: 'rgba(51, 190, 109, 0.1)', color: '#33BE6D' } },
      { condition: (_) => isExpiring(_, 72), color: { backgroundColor: 'rgba(53, 164, 191, 0.1)', color: '#35A4BF' } },
      { condition: (_) => isExpiring(_, 120), color: { backgroundColor: 'rgba(154, 79, 252, 0.1)', color: '#9A4FFC' } },
      { condition: (_) => isExpiring(_, 10000), color: { backgroundColor: 'rgba(53, 53, 187, 0.1)', color: '#3535BB' } }
    ]

    let statusColor = {
      backgroundColor: 'rgba(109, 113, 117, 0.1)',
      color: '#6d7175'
    };

    for (let _ of statusColors) {
      if (_.condition(space)) {
        statusColor = _.color;
        break;
      }
    }

    return statusColor;
  }

  useEffect(() => {
    const source = CancelToken.source()

    const fetch = async () => {
      try {
        setErrors({});
        setLoading(true);

        if (!currentProperty) return;

        const results = await axios.get(`/space-profiles/leasing?companySlug=${company.slug}&company=${company.value}&property=${property}`, { cancelToken: source.token });
        if (results.status === 200) {
          let spaces = get(results, 'data.records', [])
          spaces.forEach(space => {
            space.leasing_image = getImageUrl(space, ['leasing_image.thumbnail.S', 'leasing_image'], DefaultProfilePicture, 'leasing_image')
            space.leaseExpirationFormatted = space.leaseEnd && !space.isLeaseExpired ? t('term.occupied') : t('term.vacant');
            space.unit = space.unit ? `#${space.unit}` : '';
            space.statusColor = getStatusColor(space);
          })
          setState(spaces);
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }
    }

    if (company) fetch();

    return () => source.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyValue, company])

  return state;
}