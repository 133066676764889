import React from 'react';
import styles from './Steps.module.css';
import { DragDropImageUpload, PasswordInput, inputValidationRules } from '@project/components';

export const Step0 = ({ vendorRegistration, renderInput, smallMobileView }) => {

  return (
    <div>
      {smallMobileView ?
        <>
          <div>{renderInput('fname', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('lname', vendorRegistration, styles.inputStyle)}</div>
        </>
        :
        <div className={`d-flex justify-content-between ${styles.twoInput}`}>
          {renderInput('fname', vendorRegistration, styles.inputStyle)}
          {renderInput('lname', vendorRegistration, styles.inputStyle)}
        </div>
      }
      <div>
        {renderInput('phone', vendorRegistration, styles.inputStyle)}
      </div>

      <div>
        {renderInput('privacyPolicy', vendorRegistration)}
        {/* Render link to privacy policy */}
        <div className={styles.privacyPolicyLink}>
          <a href="https://images2.idplans.com/documents/IDP_Privacy_Policy.pdf" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
        </div>

      </div>
    </div>
  );
}

export const Step1 = ({ vendorRegistration, renderInput, smallMobileView }) => {
  return (
    <div>
      <div>
        {renderInput('company', vendorRegistration, styles.inputStyle)}
      </div>
      <div>
        {renderInput('companySite', vendorRegistration, styles.inputStyle)}
      </div>
      {smallMobileView ?
        <>
          <div>{renderInput('address1', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('city', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('postal', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('state', vendorRegistration, styles.inputStyle)}</div>
        </>
        :
        <>
          <div className={`d-flex justify-content-between ${styles.twoInput}`}>
            {renderInput('address1', vendorRegistration, styles.inputStyle)}
            {renderInput('city', vendorRegistration, styles.inputStyle)}
          </div>
          <div className={`d-flex justify-content-between ${styles.twoInput}`}>
            {renderInput('postal', vendorRegistration, styles.inputStyle)}
            {renderInput('state', vendorRegistration, styles.inputStyle)}
          </div>
        </>
      }

    </div>
  );
}

export const Step2 = ({ vendorRegistration, renderInput }) => {
  return (
    <div>
      <div>
        {renderInput('travelRadius', vendorRegistration, styles.inputStyle)}
      </div>
    </div>
  );
}

export const Step3 = ({ vendorRegistration, updateField, renderInput }) => {
  return (
    <div>
      <div>
        {renderInput('businessCategories', vendorRegistration)}
      </div>
    </div>
  );
}

export const Step4 = ({ logo, setLogo }) => {
  return (
    <div>
      <div>
        <DragDropImageUpload
          setFiles={setLogo}
          files={logo}
          style={{ height: '200px' }}
        />
      </div>
    </div>
  );
}

export const Step5 = ({ vendorRegistration, updateField, validPassword, setValidPassword, mobileView, validate }) => {
  return (
    <div>
      <div>
        <PasswordInput
          create={true}
          strength={true}
          validate={validate}
          password={vendorRegistration.password || ''}
          setPassword={(value) => updateField(value, vendorRegistration, 'password')}
          setValidPassword={setValidPassword}
          validPassword={validPassword}
          rules={[
            inputValidationRules.minLengthRule(12),
            inputValidationRules.hasNumberRule(),
            inputValidationRules.hasUpperCaseRule(),
            inputValidationRules.hasLowerCaseRule(),
            inputValidationRules.passwordStrengthRule(3)
          ]}
          tooltipPlacement={mobileView ? 'bottom' : 'left'}
        />
      </div>
    </div>
  );
}