import React from 'react';
import { AdminSelect as Select } from '@project/components';
import Arrow from '../../assets/images/DropdownArrowBold.svg';
import styles from './AdminSelect.module.css';

const DropdownArrow = (props) => {
  return props.customDropdownComponent || <div className={styles.dropdownArrow}><img src={Arrow} alt='select options' /></div>;
};

const AdminSelect = (props) => {
  return <div className={styles.adminSelect}><Select 
    reverseDropdownIcon={props.reverseDropdownIcon}
    propStyles={{...styles, ...props.propStyles }}
    customComponents={{
      IndicatorsContainer: () => DropdownArrow(props)
    }} {...props} /></div>;
}

export default AdminSelect;