export default [
  {
    title: 'Stay Updated',
    description: 'Welcome to ID Cloud',
    linkTitle: 'Watch now',
    linkUrl: 'https://youtu.be/1UUZVDajAgY',
    imageUrl: 'https://idcloud-staging.s3.amazonaws.com/static/media/Business.svg',
    status: 'active'
  },
  {
    title: 'INVITE YOUR TEAM',
    description: 'Invite your vendors to start managing work orders and billing',
    linkTitle: 'Send invite',
    linkUrl: `/vendor-invite`,
    imageUrl: 'https://idcloud-staging.s3.amazonaws.com/static/media/Business2.svg',
    status: 'active',
    backgroundColor: '#7092E9'
  }
]