export const CUSTOM_WEB_FONTS = [
  'Arial',
  'Avenir',
  'Bebas Neue',
  'Cursive',
  'Helvetica',
  'IM Fell Great Primer',
  'Lato',
  'Monospace',
  'Montserrat',
  'Myriad Pro',
  'Open Sans',
  'Palanquin',
  'Raleway',
  'Sans',
  'Sans Serif',
  'Serif',
  'Signia Pro',
  'Source Sans Pro',
  'Source Serif Pro',
  'Verdana'
]

export const CUSTOM_RENDERER_FONTS = [
  'Arial',
  'Cursive',
  'Monospace',
  'Sans',
  'Sans Serif',
  'TT Hoves Bold',
  'TT Hoves DemiBold',
  'TT Hoves Light',
  'TT Hoves Medium',
  'TT Hoves Regular',
  'TT Hoves Thin',
  'Verdana'
]