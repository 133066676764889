export {default as DashboardBold} from "./dashboard-bold";
export {default as MessagesBold} from "./messages-bold";
export {default as NotificationsBold} from "./notifications-bold";
export {default as RadarBold} from "./radar-bold";
export {default as ReportsBold} from "./reports-bold";
export {default as SettingsBold} from "./settings-bold";
export {default as TeamBold} from "./team-bold";
export {default as UsersBold} from "./users-bold";
export {default as VendorsBold} from "./vendors-bold";
export {default as UserBold} from './user-bold';
export {default as LogoutBold} from './logout-bold';
export {default as PropertyBold} from './property-bold';
export {default as CompanyBold} from './company-bold';