export const getMediaUrl = (media) => {
  if (media && media.url) {
    let url = media.url

    // Add timestamp if media isn't blobbed and if it doesn't have a previous timestamp.
    if (!url.startsWith('blob') && !url.includes('?_=')) {
      url += '?_=' + (media.timestamp || Date.now())
    }
    return url
  }

  if (media && typeof media === 'object') {
    let url = URL.createObjectURL(media)
    if (!url.startsWith('blob')) {
      url += '?_=' + Date.now()
    }
    return url
  }
  return media
}

export const getRandomNumber = () => {
  let randomHashArray;

  if (typeof window === 'object' && window.crypto) {
    randomHashArray = new Uint32Array(1);
    window.crypto.getRandomValues(randomHashArray);
  } else {
    randomHashArray.push(Math.random() * 2097152);
  }

  return randomHashArray[0];
}
