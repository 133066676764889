import React, { useContext, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './MenuDrawer.module.css';
import { RootContext } from './../../hoc/RootContext/RootContext';
import MenuLink from './../../components/MenuLink/MenuLink';
import MenuDropdown from './../MenuDropdown/MenuDropdown';
import { useIntl } from 'react-intl';
import { get, getCookieOptions, slugify } from "../../utils/utils";
import Cookies from "universal-cookie";
import { useCompanySettings } from '../../utils/hooks';
import { useMemo } from 'react';
const classNames = require('classnames');

export default (props) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { isAdminMenuOpen, currentUser, currentCompany, largeView, setIsAdminMenuOpen } = useContext(RootContext);
  const [userCompanySettings] = useCompanySettings(currentUser.company)

  // flag to show the ID360 link
  const isID360User = currentUser ? currentUser.is_id360_admin || currentUser.is_id360_user : false;

  const isVisionAdmin = currentUser ? currentUser.visionAdmin : false;
  const isAdmin = currentUser ? !!currentUser.companyAdmin : false;
  const isVendor = currentUser ? currentUser.type === 'VENDOR' : false;
  const isIDInspectUser = useMemo(() => userCompanySettings ? userCompanySettings.inspections.enabled : false, [userCompanySettings]);
  const isRPMDisabled = !!userCompanySettings?.rpmSettings?.disableRPM

  const onLogout = () => {
    const cookies = new Cookies();
    let copts = getCookieOptions();
    cookies.remove('auth', { path: copts.path, domain: copts.domain });
    cookies.remove('logged-in', { path: copts.path, domain: copts.domain });
    window.location.href = `/login`;
  };

  const profileMenuHandler = () => {
    if (isProfileMenuOpen) {
      setIsProfileMenuOpen(false);
    } else {
      setIsProfileMenuOpen(true);
      profileRef.current.focus();
    }
  }

  useEffect(() => {
    if (!isAdminMenuOpen) setIsProfileMenuOpen(false);
  }, [isAdminMenuOpen])

  useEffect(() => {
    if (largeView) setIsAdminMenuOpen(true);
  }, [largeView, setIsAdminMenuOpen])

  const profileRef = useRef();

  const initials = currentUser.fname && currentUser.lname ? currentUser.fname.substring(0, 1) + currentUser.lname.substring(0, 1) : currentUser.username.substring(0, 1);

  return (
    <>
      <section id="menuDrawer" className={classNames(styles.menuDrawerOpen, styles.leftCol, 'bg-white', styles.menuDrawer)} style={{ transform: `translateX(${!largeView ? isAdminMenuOpen ? '0' : '-120%' : '0'})` }}>
        <div className={styles.menuWrapper}>
          <div className={`w-100 d-flex justify-content-between align-items-center ${styles.profile}`}>
            <div className="d-flex align-items-center">
              <div className={styles.image}>
                {get(currentUser, 'profilePicture') ?
                  <img src={`${currentUser.profilePicture}?${Math.random()}`} alt='user profile' /> :
                  <div>{initials}</div>
                }
              </div>
              <div className={styles.label}>{currentUser ? `${get(currentUser, 'fname', '')} ${get(currentUser, 'lname', '')}` : 'User'}</div>
            </div>

            <div tabIndex="0" ref={profileRef} onBlur={() => setIsProfileMenuOpen(false)}>
              <div className={styles.vertical} onClick={profileMenuHandler}>
                <img src={'https://idcloud-staging.s3.amazonaws.com/static/media/vertical.svg'} alt='settings' />
              </div>
              <div className={`${styles.menu} ${isProfileMenuOpen ? styles.profileMenuOpen : styles.profileMenuClosed} d-flex align-items-center flex-column justify-content-center`}>
                <div onClick={() => { navigate('settings'); setIsProfileMenuOpen(false); if (!largeView) setIsAdminMenuOpen(false); }}>{t('ui.settings')}</div>
                <div onClick={() => { onLogout(); setIsProfileMenuOpen(false); if (!largeView) setIsAdminMenuOpen(false); }}>{t('ui.logout')}</div>
              </div>
            </div>
          </div>
          <nav className={styles.nav} id="menuDropdown">
            <ul className='p-0 mt-0'>

              <li className={styles.menuSection}><div>{t('MenuDrawer.menu')}</div></li>
              {(
                isRPMDisabled ?
                  null :
                  <MenuLink name={'rpm'} title="RPM" link={`${process.env.REACT_APP_RPM_HOST}/user/properties/list`} />
              )}
              <MenuLink name={'id360'} title="ID 360" link={`${process.env.REACT_APP_ID360_HOST}/admin/profiles/recent`} hide={!isID360User} />
              <MenuLink name={'idvision'} title="ID Vision" link={`${process.env.REACT_APP_IDVISION_HOST}/admin/properties`} hide={!isVisionAdmin} />
              <MenuLink name={'idintel'} title="ID Intel" link={`${process.env.REACT_APP_IDINTEL_HOST}/search-assets`} hide={isVendor} />
              <MenuLink name={'iddigital'} title="ID Digital" link={`${process.env.REACT_APP_IDDIGITAL_HOST}/overview/${typeof currentCompany === 'string' ? slugify(currentCompany) : currentCompany?.slug}`} hide={!isID360User} />
              <MenuLink name={'idinspect'} title="ID Inspect" link={`${process.env.REACT_APP_IDINSPECT_HOST}/inspections`} hide={(!isIDInspectUser && !isVendor)} />
              <MenuLink name={'tenant'} title="Tenant" link={`${process.env.REACT_APP_TENANT_HOST}/`} hide={isVendor} />
              <li className={styles.menuSectionSeparator} />

              <li className={styles.menuSection}><div>{t('MenuDrawer.management')}</div></li>
              <MenuLink name={'Dashboard'} title={t('MenuDrawer.dashboard')} internalLink={true} />
              <MenuDropdown items={['Users', 'Vendors']} links={['users', 'vendors', 'vendor-invite', 'vendor-search', 'invite-team-member']} hide={isVendor} name={'Team'} />
              <MenuLink name={'Reports'} title={t('MenuDrawer.reports')} internalLink={true} hide={isVendor} />
              <MenuLink name={'Lease Radar'} title={t('MenuDrawer.leaseRadar')} internalLink={true} />
              {
                isAdmin ? <MenuDropdown items={['Property', 'Company']} links={['company', 'property-settings']} hide={false} name={'Settings'} /> : null
              }
              <MenuLink name={'Settings'} title={t(`MenuDrawer.${isVendor ? 'vendorSettings' : 'userSettings'}`)} internalLink={true} />
              <MenuLink name={'Lease Radar'} title={t('MenuDrawer.leaseRadar')} internalLink={true} hide={true} />
            </ul>
          </nav>
          <div className={`${styles.logout} w-100`}>
            <div className={styles.logoutButton} onClick={onLogout}>
              {t('ui.logout')}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}