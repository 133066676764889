import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useTranslations from './useTranslations'
import { WORK_ORDER_FILTER_TYPE, ACL } from '../constants'

let source;

//Fetch work worders
const useInspectionInstances = ({
  companyGuid,
  propertyGuid,
  setIsLoading = () => { },
  redirectOnFail,
  toastError = () => { },
  axios,
  CancelToken,
  isCancel,
  filter,
  setPagination,
  queryParams,
  currentCompany,
  currentUser,
  disabled,
  isAdmin
}) => {
  const [t] = useTranslations();

  const [inspectionInstances, setInspectionInstances] = useState([])

  const navigate = useNavigate();

  const {
    page,
    orderBy,
    orderAscending,
    search
  } = queryParams

  useEffect(() => {

    const fetchInspectionInstances = async () => {

      let cancelToken
      if (source) source.cancel()
      source = CancelToken.source()
      cancelToken = source.token

      try {
        setIsLoading(true);
        const params = new URLSearchParams();

        let formattedFilter = filter
        if (filter === WORK_ORDER_FILTER_TYPE.COMPLETED) formattedFilter = WORK_ORDER_FILTER_TYPE.COMPLETED

        params.set('companyGuid', companyGuid);
        //normalize page number
        params.set('page', page + 1)
        params.set('type', formattedFilter);
        params.set('isAdmin', isAdmin)

        // set a param to query by user when the filter is 'assigned'
        if (formattedFilter === 'assigned' && currentUser?.username) {
          params.set('userFilter', true)
        }


        if (search) params.set('search', search);
        if (propertyGuid) params.set('propertyGuid', propertyGuid);
        if (orderBy) params.set('sort', `${orderAscending ? '' : '-'}${orderBy}`);

        const result = await axios.get(`/inspect/instances?${params.toString()}`, {
          cancelToken,
          headers: {
            [ACL.HTTP_HEADERS.IDP_COMPANY]: currentCompany?.name
          }
        });

        if (result.success || result?.data?.success) {
          setInspectionInstances(result.records || result?.data?.records)
          setPagination({
            count: parseInt(result?.headers?.['pagination-count'])
          })
        } else {
          toastError(t('hooks.useInspectionInstances.error'))
          if (redirectOnFail) navigate(redirectOnFail)
        }
      } catch (e) {
        // request not cancelled
        if (!isCancel(cancelToken.reason)) {
          toastError(t('hooks.useInspectionInstances.error'));
          if (redirectOnFail) navigate(redirectOnFail);
        }
      } finally {
        // request not cancelled
        if (!isCancel(cancelToken.reason)) setIsLoading(false);
      }
    }

    if (!disabled && companyGuid && filter) {
      fetchInspectionInstances();
    }

    return () => {
      if (source) source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyGuid, propertyGuid, setIsLoading, filter, page, orderAscending, orderBy, search])

  return inspectionInstances
}

export default useInspectionInstances