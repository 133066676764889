import React, { useContext } from 'react';
import styles from './MenuLink.module.css';
import { RootContext } from './../../hoc/RootContext/RootContext';
import { NavLink } from 'react-router-dom';
import { DashboardBold, MessagesBold, NotificationsBold, RadarBold, ReportsBold, TeamBold, UsersBold, VendorsBold, UserBold, PropertyBold, CompanyBold } from '../../assets/images/menu-icons/index';
import DigitalIcon from '../../assets/images/menu-icons/digital.png';
import VisionIcon from '../../assets/images/menu-icons/vision.png';
import Icon360 from '../../assets/images/menu-icons/360.png';
import RPMIcon from '../../assets/images/menu-icons/rpm.png';
import IDInspectIcon from '../../assets/images/menu-icons/idinspect.png';
import TenantIcon from '../../assets/images/menu-icons/tenant.png';
import routePermissionsDenied from '../../utils/routePermissionsDenied';
import { get } from '../../utils/utils';

const icons = {
  'Dashboard': <DashboardBold />,
  'Company': <CompanyBold />,
  'Property': <PropertyBold />,
  'Messages': <MessagesBold />,
  'Notifications': <NotificationsBold />,
  'Reports': <ReportsBold />,
  'Lease Radar': <RadarBold />,
  'Vendors': <VendorsBold />,
  'Users': <UsersBold />,
  'Settings': <UserBold />,
  'Property Settings': <PropertyBold />,
  'Team': <TeamBold />,
  'rpm': RPMIcon,
  'id360': Icon360,
  'idvision': VisionIcon,
  'iddigital': DigitalIcon,
  'idintel': RPMIcon,
  'idinspect': IDInspectIcon,
  'tenant': TenantIcon
};

const classNames = require('classnames');

export default (props) => {
  if (props.hide) return null;
  const { largeView, setIsAdminMenuOpen, currentUser } = useContext(RootContext);
  let lowercaseName = props.name.toLowerCase()

  //To append `-settings` to `Company` & `Property` props.name
  if (['company', 'property'].includes(lowercaseName)) {
    lowercaseName = `${lowercaseName}-settings`;
  }

  if (props.internalLink) {
    let routeName = `/${lowercaseName.replace(/ /gi, '-')}`;
    if (currentUser && get(routePermissionsDenied, routeName, []).includes(currentUser.type)) return null;
    let linkStyles = classNames(props.groupedLink ? styles.groupedLink : '');
    return (
      <li onClick={!largeView ? () => setIsAdminMenuOpen(false) : () => { }}>
        <NavLink to={routeName}
          className={(navData) => classNames(linkStyles, navData.isActive || props.isActive ? styles.activeLink : '')}>
          <span className={classNames(styles.fNavIcon)}>{icons[props.name]}</span>
          <span className={styles.menuDescription}> {`${props.title}`} </span>
        </NavLink>
      </li>
    );
  }
  return (
    <li onClick={!largeView ? () => setIsAdminMenuOpen(false) : () => { }}>
      <a href={props.link} target="_blank" rel="noopener noreferrer" title={props.title} className={styles.internalLink}>
        <span className={classNames(styles.fNavIcon)}><img src={icons[props.name]} alt={props.name} /></span>
        <span className={styles.menuDescription}> {`${props.title}`} </span>
      </a>
    </li>
  );
}