import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslations } from '@project/components/src/utils/hooks'
import { INSPECTION_STATUS, INSPECTION_STATUS_COMPLETE, INSPECT_TYPE } from '../../../utils/constants'
import styles from './ViewInspectionButton.module.css'
import PropTypes from 'prop-types'

/**
 * Creates button for inspection or work-order for viewing/resuming/etc
 * @param {{guid: string, slug: string}} currentCompany currently selected company
 * @param {string} itemId item to bew viewed
 * @param {boolean} isAdmin is user admin
 * @param {string} type inspection or work-order
 * @param {string} status current status of inspection
 * @returns {any} Button for viewing/resuming/etc item
 */
const ViewInspectionButton = ({
  currentCompany,
  itemId,
  isAdmin,
  type,
  status
}) => {
  const [t] = useTranslations()

  const navigate = useNavigate()

  const itemUrl = useMemo(() => {
    const prefix = currentCompany ? `/${currentCompany.displaySlug}` : ''
    const view = !isAdmin && type === INSPECT_TYPE.INSPECTION && INSPECTION_STATUS_COMPLETE.includes(status) ? 'view/' : '';
    const admin = isAdmin ? '/admin' : ''
    return `${prefix}${admin}/${type}/${view}${itemId}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, type, itemId, isAdmin])

  let statusStyle = styles.inspectionView
  let statusLabel = t('ui.view')
  if (type === INSPECT_TYPE.INSPECTION) {
    statusStyle = status === INSPECTION_STATUS.OPEN ? styles.inspectionDefault : INSPECTION_STATUS_COMPLETE.includes(status) ? styles.inspectionView : styles.inspectionPaused
    statusLabel = status === INSPECTION_STATUS.OPEN ? t('ui.start') : INSPECTION_STATUS_COMPLETE.includes(status) ? t('ui.view') : t('ui.resume')
  }

  return (
    <div
      className={`${statusStyle} ${styles.inspectionButton}`}
      onClick={() => navigate(itemUrl)}>
      {statusLabel}
    </div>
  )
}

ViewInspectionButton.propTypes = {
  currentCompany: PropTypes.object,
  itemId: PropTypes.number,
  isAdmin: PropTypes.bool,
  type: PropTypes.string,
  status: PropTypes.string
}

export default ViewInspectionButton