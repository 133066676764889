import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import styles from './RulesTooltip.module.css';
import './RulesTooltip.css';

const RulesTooltip = ({label, target, show, rules, rulesNotMet, placement = "left"}) => {
  const circle = <div className={styles.circle}></div>;
  const circleMet = <div className={styles.circleMet}></div>;

  return (
    <Overlay target={target.current} show={show} placement={placement}>
      {(props) => (
        <Tooltip {...props} className={styles.tooltip} data-testid="rulesTooltip">  
          <div className={styles.heading}>{label}</div>
          {rules.map(rule => {
            const ruleMet = (rulesNotMet).includes(rule.name)
            return (
              <div className={styles.rule} key={rule.name}>
                {ruleMet ? circle : circleMet}
                {rule.message}
              </div>
            )
          })}
        </Tooltip>
      )}
      </Overlay>
  )
}
export default RulesTooltip;