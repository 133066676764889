import React from 'react'
import { components } from 'react-select'

/**
 * This component renders a "humanized" version of a
 * `MultiSelectDropdown` option. It extends from
 * `react-select.components.MultiValue` component.
 *
 * This custom implementation will just render the `label`
 * value from the `options` passed to the `MultiSelectDropdown`
 * component.
 */
const CustomMultiValue = ({
  data: { label },
  ...otherProps
}) => (
  <components.MultiValue {...otherProps}>
    <span>{label}</span>
  </components.MultiValue>
)

export default CustomMultiValue
