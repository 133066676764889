import { States } from '@project/components';
import { intl } from '../../utils/intl';
const t = (id) => intl.formatMessage({ id });

export default {
  fname: {
    required: true,
    label: t('vendorSchema.fname.label'),
    placeholder: t('vendorSchema.fname.label')
  },
  lname: {
    required: true,
    label: t('vendorSchema.lname.label'),
    placeholder: t('vendorSchema.lname.label')
  },
  phone: {
    required: true,
    label: t('vendorSchema.phone.label'),
    placeholder: t('vendorSchema.phone.placeholder'),
    validation: /^\(\d{3}\) \d{3}-\d{4}$/
  },
  email: {
    required: true,
    label: t('vendorSchema.email.label'),
    placeholder: t('vendorSchema.email.placeholder'),
    // eslint-disable-next-line no-useless-escape
    validation: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  },
  company: {
    required: true,
    label: t('vendorSchema.company.label'),
    placeholder: t('vendorSchema.company.placeholder')
  },
  companySite: {
    label: t('vendorSchema.companySite.label'),
    placeholder: t('vendorSchema.companySite.placeholder')
  },
  address1: {
    required: true,
    label: t('vendorSchema.address1.label'),
    placeholder: t('vendorSchema.address1.placeholder')
  },
  city: {
    required: true,
    label: t('vendorSchema.city.label'),
    placeholder: t('vendorSchema.city.placeholder')
  },
  postal: {
    required: true,
    label: t('vendorSchema.postal.label'),
    placeholder: t('vendorSchema.postal.placeholder')
  },
  state: {
    required: true,
    label: t('vendorSchema.state.label'),
    placeholder: t('vendorSchema.state.placeholder'),
    type: 'select',
    options: States
  },
  travelRadius: {
    label: t('vendorSchema.travelRadius.label'),
    placeholder: t('vendorSchema.travelRadius.placeholder'),
    type: 'select',
    options: [
      { value: intl.formatMessage({ id: 'vendorSchema.travelRadius.miles' }, { count: '10' }) },
      { value: intl.formatMessage({ id: 'vendorSchema.travelRadius.miles' }, { count: '20' }) },
      { value: intl.formatMessage({ id: 'vendorSchema.travelRadius.miles' }, { count: '30' }) },
      { value: intl.formatMessage({ id: 'vendorSchema.travelRadius.miles' }, { count: '40+' }) },
      { value: t('vendorSchema.travelRadius.remote') }
    ]
  },
  businessCategories: {
    required: true,
    label: t('vendorSchema.businessCategories.label'),
    placeholder: t('vendorSchema.businessCategories.placeholder'),
    type: 'tagSelect',
    options: [
      { id: 'plumbing', label: t('vendorSchema.businessCategories.plumbing'), value: 'Plumbing' },
      { id: 'hvac', label: t('vendorSchema.businessCategories.hvac'), value: 'HVAC' },
      { id: 'contractor', label: t('vendorSchema.businessCategories.contractor'), value: 'Contractor' },
      { id: 'trash', label: t('vendorSchema.businessCategories.trash'), value: 'Trash' },
      { id: 'recycle', label: t('vendorSchema.businessCategories.recycle'), value: 'Recycle' },
      { id: 'landscaping', label: t('vendorSchema.businessCategories.landscaping'), value: 'Landscaping' }
    ]
  },
  logo: {
  },
  coiFile: {
    required: true,
    type: 'file'
  },
  insuranceStartDate: {
    required: true,
    type: 'date',
    label: t('vendorSchema.coi.insuranceStartDate')
  },
  insuranceEndDate: {
    required: true,
    type: 'date',
    label: t('vendorSchema.coi.insuranceEndDate')
  },
  password: {
    required: true,
    type: 'password'
  }
}