import { useEffect, useMemo, useState } from 'react'
import useTranslations from './useTranslations'
import { INSPECT_FILTER_TYPE } from '../constants'
import useMenu from './useMenu'

/**
 * This hook gathers the counts for inspections or work orders for all properties. Counts
 * are gathered from the count routes and then the missing properties (gathered with menu)
 * are autofilled with 0 inspections
 * @param {{guid: string, slug: string}} currentCompany currently selected company
 * @param {string} itemId item to bew viewed
 * @param {boolean} isAdmin is user admin
 * @param {string} type inspection or work-order
 * @param {string} status current status of inspection
 * @returns {any} Button for viewing/resuming/etc item
 */
const useInspectPropertyCounts = ({
  companyGuid,
  type = 'inspection',
  setIsLoading = () => {},
  toastError = () => {},
  axios,
  CancelToken,
  isCancel,
  isAdmin,
  currentCompany
}) => {
  let source;
  const [t] = useTranslations();

  const [properties, setProperties] = useState([])

  const menuProperties = useMenu({
    currentCompany,
    setIsLoading,
    company: currentCompany?.name || currentCompany?.value,
    requireCompany: true,
    axios,
    CancelToken,
    isCancel
  })

  const props = useMemo(() => ({
    'inspection': {
      route: '/inspect/properties/instance-counts',
      countTranslator: (count) => t('Inspections.properties.inspections', { count })
    },
    'work-order': {
      route: '/work-orders/properties/work-order-counts',
      countTranslator: (count) => t('WorkOrders.properties.workOrders', { count })
    }
  }), [t])

  useEffect(() => {

    const fetchCounts = async () => {
      let cancelToken
      if (source) source.cancel()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      source = CancelToken.source()
      cancelToken = source.token

      try {
        setIsLoading(true);
        const params = new URLSearchParams();
        params.set('companyGuid', companyGuid);
        params.set('type', isAdmin ? INSPECT_FILTER_TYPE.ALL : INSPECT_FILTER_TYPE.ASSIGNED);

        const result = await axios.get(`${props[type].route}?${params.toString()}`, {
          cancelToken
        });

        if (result.success || result?.data?.success) {
          const tempProperties = (result.records || result?.data?.records).map(property => ({
            ...property,
            rawCount: property.count,
            count: props[type].countTranslator(property.count)
          }))

          setProperties(tempProperties)
        } else {
          toastError(t('hooks.useWorkOrders.error'))
        }
      } catch (e) {
        if (!isCancel(cancelToken.reason)) toastError(t('hooks.useWorkOrders.error'))
      } finally {
        if (!isCancel(cancelToken.reason)) setIsLoading(false)
      }
    }
    if (companyGuid) fetchCounts()

    return () => {
      if (source) source.cancel();
    }
  }, [companyGuid, isAdmin])

  const formattedProperties = useMemo(() => {
    const tempProperties = [...properties]
    if (menuProperties?.length) {
      const propertyGuids = properties.map(_ => _.guid)
      menuProperties.forEach(menuProperty => {
        if (!propertyGuids.includes(menuProperty.guid)) {
          tempProperties.push({
            guid: menuProperty.guid,
            rawCount: 0,
            count: props[type].countTranslator(0),
            name: menuProperty?.displayName || menuProperty?.name,
            address: `${menuProperty.address1}, ${menuProperty.city}, ${menuProperty.state}`,
            leasingImage: menuProperty.leasingImageThumbnailUrl
          })
        }
      })
    }
    return tempProperties
  }, [menuProperties, properties, props, type])

  return formattedProperties
}

export default useInspectPropertyCounts