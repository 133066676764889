import React, {useState} from 'react';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import TitleDescription from './../../components/TitleDescription/TitleDescription'
import { AdminCheckbox, AdminInput, ColorPicker } from "@project/components";
import { Image } from 'react-bootstrap';
import FileUpload from '../FileUpload/FileUpload';
import styles from './CompanyIdDigitalTab.module.css';
import { get } from '../../utils/utils';
import { useIntl } from 'react-intl';
import FontSelectorWithPreview from './../../components/FontSelectorWithPreview/FontSelectorWithPreview'
import ToggleWithLabel from './../../components/ToggleWithLabel/ToggleWithLabel'

const CompanyIdDigitalTab = ({ company, imageExt, companySettings, headerCSSErrors, saveHTMLHandler, saveCSSHandler, updateCompanySettings }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [isSpaceBackgroundColorInvalid, setIsSpaceBackgroundColorInvalid] = useState(false);
  const [isSpaceBorderColorInvalid, setIsSpaceBorderColorInvalid] = useState(false);
  const [isVacantSpaceBackgroundColorInvalid, setIsVacantSpaceBackgroundColorInvalid] = useState(false);
  const [isSpaceTextColorInvalid, setIsSpaceTextColorInvalid] = useState(false);

  const [showSpaceBackgroundPicker, setShowSpaceBackgroundPicker] = useState(false);
  const [showSpaceBorderPicker, setShowSpaceBorderPicker] = useState(false);
  const [showVacantSpaceBackgroundPicker, setShowVacantSpaceBackgroundPicker] = useState(false);
  const [showSpaceTextPicker, setShowSpaceTextPicker] = useState(false);

  return (
    <>
      <ToggleWithLabel 
        titleLabel={t('CompanyIdDigitalTab.toggleTenant.title')} 
        titleDescriptions={[t('CompanyIdDigitalTab.toggleTenant.description')]} 
        ids={['showTenantRoster']}
        getObject={companySettings}
        getKeys={['idDigitalSettings.showTenantRoster']}
        updateFunction={updateCompanySettings} 
      />
      <ToggleWithLabel 
        titleLabel={t('CompanyIdDigitalTab.showContactForm.title')} 
        titleDescriptions={[t('CompanyIdDigitalTab.showContactForm.description')]} 
        ids={['showContactForm']}
        getObject={companySettings}
        getKeys={['idDigitalSettings.showContactForm']}
        updateFunction={updateCompanySettings} 
      />
      <ToggleWithLabel 
        titleLabel={t('CompanyIdDigitalTab.removeBackgroundShapeBorders.title')} 
        titleDescriptions={[t('CompanyIdDigitalTab.removeBackgroundShapeBorders.description')]} 
        ids={['removeBackgroundShapeBorders']}
        getObject={companySettings}
        getKeys={['idDigitalSettings.removeBackgroundShapeBorders']}
        updateFunction={updateCompanySettings} 
      />
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyIdDigitalTab.customMarker.title')}
            description={t('CompanyIdDigitalTab.customMarker.description')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <div className="d-flex justify-content-between align-items-center" style={{ flexWrap: 'wrap' }}>
            <FileUpload
              companySettings={companySettings}
              company={company}
              updateField={updateCompanySettings}
              uploadKey={'idDigitalSettings.mapMarker'}
              description={t('CompanyIdDigitalTab.customMarker.title')}
              accept={imageExt}
              maxHeight={20}
              maxWidth={20} />
            {get(companySettings, 'idDigitalSettings.mapMarker.thumbnail') ?
              <div className="my-4"><Image src={`https://s3.amazonaws.com/idp-rpm-v4/${companySettings.idDigitalSettings.mapMarker.thumbnail}`} /></div> : null}
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyIdDigitalTab.customHeader.title')}
            description={t('CompanyIdDigitalTab.customHeader.description')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <AdminInput
            type="textarea"
            value={get(companySettings, 'idDigitalSettings.customHeaderHTML')}
            onChange={(e) => saveHTMLHandler(e, 'Header')}
            label='HTML' />
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyIdDigitalTab.customFooter.title')}
            description={t('CompanyIdDigitalTab.customFooter.description')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <AdminInput
            type="textarea"
            value={get(companySettings, 'idDigitalSettings.customFooterHTML')}
            onChange={(e) => saveHTMLHandler(e, 'Footer')}
            label='HTML' />
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            description={t('CompanyIdDigitalTab.customFooter.css.description')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <AdminInput
            type="textarea"
            value={get(companySettings, 'idDigitalSettings.headerFooterCSS')}
            onChange={(e) => saveCSSHandler(e)}
            isInvalid={headerCSSErrors}
            label='CSS' />
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyIdDigitalTab.customFont.title')}
            description={t('CompanyIdDigitalTab.customFont.description')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <FontSelectorWithPreview
            companySettings={companySettings}
            updateCompanySettings={updateCompanySettings}
            settingsAttribute={'idDigitalSettings.customFont'}
            selectorLabel={t('CompanyIdDigitalTab.customFont.selectFont')}
            defaultValueLabel={t('ui.default')} 
            previewLabel={t('CompanyIdDigitalTab.customFont.preview')}
            fontType={'WEB'} />
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('Company.idDigitalSettings.spaceColor.label')}
            description={t('Company.idDigitalSettings.spaceColor.desc')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false} padded={false}>
          <TwoColumnContent>
            <LeftColumn>
              <div className="pl-3">
                <ColorPicker
                  label={t('Company.idDigitalSettings.spaceColor.fillLabel')}
                  color={get(companySettings, 'idDigitalSettings.spaceBackgroundColor.color', 'fff')}
                  setColor={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.spaceBackgroundColor.color')}
                  opacity={get(companySettings, 'idDigitalSettings.spaceBackgroundColor.opacity', '1')}
                  setOpacity={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.spaceBackgroundColor.opacity')}
                  isInvalid={isSpaceBackgroundColorInvalid}
                  setIsInvalid={setIsSpaceBackgroundColorInvalid}
                  showPicker={showSpaceBackgroundPicker}
                  setShowPicker={setShowSpaceBackgroundPicker} />
              </div>
            </LeftColumn>
            <RightColumn boxShadow={false} border={false} padded={false}>
              <div className="pl-3">
                <ColorPicker
                  label={t('Company.idDigitalSettings.spaceColor.borderLabel')}
                  color={get(companySettings, 'idDigitalSettings.spaceBorderColor.color', 'fff')}
                  setColor={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.spaceBorderColor.color')}
                  opacity={get(companySettings, 'idDigitalSettings.spaceBorderColor.opacity', '1')}
                  setOpacity={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.spaceBorderColor.opacity')}
                  isInvalid={isSpaceBorderColorInvalid}
                  setIsInvalid={setIsSpaceBorderColorInvalid}
                  showPicker={showSpaceBorderPicker}
                  setShowPicker={setShowSpaceBorderPicker} />
              </div>
            </RightColumn>
          </TwoColumnContent>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('Company.idDigitalSettings.vacantSpaceBackground.label')}
            description={t('Company.idDigitalSettings.vacantSpaceBackground.desc')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false} padded={false}>
          <div className="pl-3">
            <ColorPicker
              color={get(companySettings, 'idDigitalSettings.vacantSpaceBackground.color', 'fff')}
              setColor={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.vacantSpaceBackground.color')}
              opacity={get(companySettings, 'idDigitalSettings.vacantSpaceBackground.opacity', '1')}
              setOpacity={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.vacantSpaceBackground.opacity')}
              isInvalid={isVacantSpaceBackgroundColorInvalid}
              setIsInvalid={setIsVacantSpaceBackgroundColorInvalid}
              showPicker={showVacantSpaceBackgroundPicker}
              setShowPicker={setShowVacantSpaceBackgroundPicker} />
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('Company.idDigitalSettings.spaceText.label')}
            description={t('Company.idDigitalSettings.spaceText.desc')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false} padded={false}>
          <div className="pl-3">
            <ColorPicker
              color={get(companySettings, 'idDigitalSettings.spaceTextColor.color', '222831')}
              setColor={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.spaceTextColor.color')}
              opacity={get(companySettings, 'idDigitalSettings.spaceTextColor.opacity', '1')}
              setOpacity={(e) => updateCompanySettings({target: {value: e}}, companySettings, 'idDigitalSettings.spaceTextColor.opacity')}
              isInvalid={isSpaceTextColorInvalid}
              setIsInvalid={setIsSpaceTextColorInvalid}
              showPicker={showSpaceTextPicker}
              setShowPicker={setShowSpaceTextPicker} />
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('Company.idDigitalSettings.siteMapSpaceLabel.label')}
            description={t('Company.idDigitalSettings.siteMapSpaceLabel.desc')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false} padded={false}>
          <div className={`${styles.siteMapRow} row`}>
            <div className={`${styles.sitemapLabel} pl-0 col-12 col-lg-5`}>
              {t('Company.idDigitalSettings.siteMapSpaceLabel.spaceNumber')}
            </div>
            <div className="col-12 col-lg-2 px-0">
              <AdminCheckbox
                id={'showSpaceNumber'}
                checked={get(companySettings, 'idDigitalSettings.showSpaceNumber', true)}
                onChange={(e) => updateCompanySettings(e, companySettings, 'idDigitalSettings.showSpaceNumber')}
                customstyle={styles.checkbox} />
            </div>
          </div>

          <div className={`${styles.siteMapRow} row`}>
            <div className={`${styles.sitemapLabel} pl-0 col-12 col-lg-5`}>
              {t('Company.idDigitalSettings.siteMapSpaceLabel.tenantName')}
            </div>
            <div className="col-12 col-lg-2 px-0">
              <AdminCheckbox
                id={'showTenantName'}
                checked={get(companySettings, 'idDigitalSettings.showTenantName')}
                onChange={(e) => updateCompanySettings(e, companySettings, 'idDigitalSettings.showTenantName')}
                customstyle={styles.checkbox} />
            </div>
          </div>

          <div className={`${styles.siteMapRow} row`}>
            <div className={`${styles.sitemapLabel} pl-0 col-12 col-lg-5`}>
              {t('Company.idDigitalSettings.siteMapSpaceLabel.squareFootage')}
            </div>
            <div className="col-12 col-lg-2 px-0">
              <AdminCheckbox
                id={'showSquareFootage'}
                checked={get(companySettings, 'idDigitalSettings.showSquareFootage')}
                onChange={(e) => updateCompanySettings(e, companySettings, 'idDigitalSettings.showSquareFootage')}
                customstyle={styles.checkbox} />
            </div>
          </div>
        </RightColumn>
      </TwoColumnContent>
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription
            title={t('CompanyIdDigitalTab.customFontOnSpaces.title')}
            description={t('CompanyIdDigitalTab.customFontOnSpaces.description')} />
        </LeftColumn>
        <RightColumn boxShadow={false} border={false}>
          <FontSelectorWithPreview
            companySettings={companySettings}
            updateCompanySettings={updateCompanySettings}
            settingsAttribute={'idDigitalSettings.customFontForSpaces'}
            selectorLabel={t('CompanyIdDigitalTab.customFont.selectFont')}
            defaultValueLabel={t('ui.default')}
            previewLabel={t('CompanyIdDigitalTab.customFont.preview')} />
        </RightColumn>
      </TwoColumnContent>
      <ToggleWithLabel 
        titleLabel={t('Company.idDigitalSettings.overrideID360SpaceColor.label')} 
        titleDescriptions={[t('Company.idDigitalSettings.overrideID360SpaceColor.desc')]} 
        ids={['id360SpaceColorOverride']}
        getObject={companySettings}
        getKeys={['idDigitalSettings.id360SpaceColorOverride']}
        updateFunction={updateCompanySettings} 
      />
      <ToggleWithLabel 
        titleLabel={t('Company.idDigitalSettings.showLandFillsInIddigital.label')} 
        titleDescriptions={[t('Company.idDigitalSettings.showLandFillsInIddigital.desc'), t('Company.idDigitalSettings.showLandFillsOnLeaseRadar.desc')]} 
        ids={['showLandscapeFills', 'showLandscapeFillsOnLeaseRadar']}
        getObject={companySettings}
        getKeys={['idDigitalSettings.showLandscapeFills', 'idDigitalSettings.showLandscapeFillsOnLeaseRadar']}
        updateFunction={updateCompanySettings} 
      />
    </>
  );
}

export default CompanyIdDigitalTab;
