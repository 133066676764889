import {
  useCallback,
  useEffect,
  useRef
} from 'react';

/**
 * This custom hook allows us to keep track of mounting/unmounting
 * of a component that calls it. We can take advantage of this hook
 * to perform side-effects only if the component is mounted.
 * @example
 * ```js
 * const checkIfMounted = useIsMounted();
 *
 * if (checkIfMounted()) {
 *   // mounted
 * }
 * ```
 */
const useIsMounted = () => {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMounted;
};

export default useIsMounted;
