import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './MainCard.module.css';

function MainCard({ title, totalCount, type, link, externalLink, linkTitle, isLoading, description, animate = false }) {
  // logic for counter animation
  // only numbers (without) comma can be used for counter animation
  // right now there are two limitaitons
  // 1. numbers boyond 1000000 (1 million) don't work
  // 2. numbers which work on the counter animation won't show commas
  const numericCount = Number.isInteger(totalCount) ? totalCount : parseInt(totalCount.replace(/,/g, ''));
  if (numericCount > 1000000) animate = false;
  if (animate && !isLoading) {
    document.documentElement.style.setProperty('--finalNumber', numericCount);
  }
  const classNames = require('classnames');
  return (
    <Card className={classNames("shadow-sm", isLoading ? styles.spinnerCard : "", styles.card)}>
      { isLoading ? <Spinner className="m-4" animation="grow" variant="primary" /> :
        <Card.Body>
          <h5 className='mb-3 font12'>{title}</h5>
          {
            totalCount ?
              <h3 className={classNames("mb-3 font26", animate ? styles.animatedNumber : '')}>{animate ? '' : totalCount}</h3>
              : null
          }
          {
            description ?
              <h3 className="mb-3 font26">{description}</h3>
              : null
          }
          {(type || (link && linkTitle)) &&
            <div className="border-top d-flex pt-3 justify-content-between">
              {type && <span className='font14'>{type}</span>}
              {link && linkTitle && <Link className={styles.link} to={link}>{linkTitle}</Link>}
              {externalLink && linkTitle &&
              <a
                rel="noopener noreferrer"
                href={externalLink}
                target="_blank"
                className={styles.link}>{linkTitle}></a>}
            </div>
          }
        </Card.Body>
      }
    </Card>
  );
};

export default MainCard;
