import React, { useEffect, useMemo, useState } from 'react';
import Axios, { CancelToken } from 'axios';
import classNames from 'classnames';
import { Alert, Button, Form, Image, Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useIsMounted } from '@project/components/src/utils/hooks';
import logo from '../../assets/images/login-logo2.png';
import axios from '../../utils/axios';
import { isValidEmail, slugify, titleCase } from '../../utils/utils';
import styles from './SamlSsoLogin.module.css';
import { useImages } from '../../utils/hooks';

/**
 * This page renders a form to sign in using SAML SSO instead of the
 * traditional user/password combination.
 * The form takes an email and, upon clicking the Login button, we:
 *   1. Get the public user data associated with that email
 *   2. We extract "company" from the user data and turn it into a slug
 *   3. Use the slug to retrieve the public settings for that company
 *   4. We extract the SSO URL from the public company settings
 *   5. Finally, we redirect to the SSO URL (OKTA, Entra, etc)
 */
const SamlSsoLogin = () => {
  const checkIfMounted = useIsMounted();

  const intl = useIntl();
  const t = id => intl.formatMessage({ id });

  const [errors, setErrors] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [triggerLogin, setTriggerLogin] = useState(false);
  const [companySlug, setCompanySlug] = useState(null);
  const [identityProviders, setIdentityProviders] = useState([]);

  const imageUrls = useMemo(() => identityProviders.map(idp => `sso/${idp.provider}.svg`), [identityProviders]);

  const getImage = useImages(imageUrls);

  useEffect(() => {
    const source = CancelToken.source();

    const loginWithSamlSso = async () => {
      setErrors(null);
      setIsLoading(true);

      try {
        if (!isValidEmail(email)) {
          throw new Error(t('error.email.valid'));
        }

        // We get the public user data because we're not logged in yet
        const userApiResult = await axios.get(`/public/users/${email}`, {
          cancelToken: source.token
        });

        // Just in case something went wrong with the API
        if (userApiResult.status !== 200) {
          throw new Error(t('ui.server'));
        }

        // We need the company slug to be able to retrieve the SSO settings
        const { company } = userApiResult.data.record;
        const slugifiedCompany = slugify(company);

        setCompanySlug(slugifiedCompany);

        const samlSettingsApiResult = await axios.get(`/public/saml/settings?company=${slugifiedCompany}`);

        // Just in case something went wrong with the API
        if (samlSettingsApiResult.status === 200) {
          const { entryPointUrl, identityProviders, provider } = samlSettingsApiResult.data.record;
          if (entryPointUrl) {
            if (checkIfMounted()) {
              // Do not redirect if the user suddenly gets out of this page
              // This might happen because of the async operations above
              if (provider) {
                // Redirect to the SSO provider
                window.location.href = `${process.env.REACT_APP_API_HOST}saml/login/${slugifiedCompany}/${provider}`;
              } else {
                window.location.href = entryPointUrl;
              }
            }
          } else if (identityProviders) {
            setIsLoading(false);
            setIdentityProviders(identityProviders);
          } else {
            throw new Error(t('ui.server'));
          }
        } else {
          throw new Error(t('ui.server'));
        }
      } catch (e) {
        if (!checkIfMounted()) {
          return;
        }

        setIsLoading(false);
        setTriggerLogin(false);

        if (Axios.isCancel(e)) {
          // The error was a canceled request - Do nothing
          return;
        }

        // "Unknown" errors
        if (e.response && e.response.data) {
          setErrors(`${t('error.sendingRequest')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('ui.server'));
        }
      }
    };

    if (triggerLogin) {
      loginWithSamlSso();
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerLogin]);

  const handleIdentityProviderClick = provider => {
    window.location.href = `${process.env.REACT_APP_API_HOST}saml/login/${companySlug}/${provider}`;
  };

  const pageClasses = classNames(styles.loginPage, 'd-flex');
  const containerClasses = classNames(styles.loginContainer, 'm-auto mw-100 bg-white shadow-sm');
  const logoClasses = classNames(styles.logo, 'text-center');
  const loginButtonClasses = classNames(
    styles.btnLogin,
    'border-0 w-100 d-flex align-items-center justify-content-center'
  );

  const handleSubmit = e => {
    e.preventDefault();
    setTriggerLogin(true);
  };

  return (
    <div className={pageClasses}>
      <div className={containerClasses}>
        <div className={logoClasses}>
          <img src={logo} width="313px" alt="IDCloud" />
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              id="email"
              className={styles.loginInput}
              type="text"
              placeholder={t('LostPassword.placeholder')}
              readOnly={isLoading}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
          {!!errors && (
            <Alert key={errors} variant="danger">
              {errors}
            </Alert>
          )}

          {isLoading && (
            <Form.Group>
              <div className="d-flex justify-content-center">
                <Spinner animation="grow" role="status" variant="primary" />
              </div>
            </Form.Group>
          )}

          {!isLoading && !identityProviders.length && (
            <Button variant="primary" block className={loginButtonClasses} type="submit">
              {t('ui.login.samlSsoButton')}
            </Button>
          )}

          {!!identityProviders.length && (
            <>
              <div>Select a provider</div>
              <div className={styles.providerButtons}>
                {identityProviders.map((idp, index) => {
                  const image = getImage(`sso/${idp.provider}.svg`);

                  return (
                    <Button
                      key={`idp-${index}`}
                      className={styles.button}
                      variant="light"
                      onClick={e => {
                        handleIdentityProviderClick(idp.provider);
                      }}
                    >
                      {image && <Image height={24} src={image.default} alt={idp.provider} />}
                      {titleCase(idp.provider)}
                    </Button>
                  );
                })}
              </div>
            </>
          )}
        </Form>

        <div className="border-bottom my-3">
          <Link to="/login">{t('ui.login.password')}</Link>
        </div>
      </div>
    </div>
  );
};

export default SamlSsoLogin;
