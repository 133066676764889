import React from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MdFilterCenterFocus } from "react-icons/md";
import { HiOutlinePlus, HiMinus } from 'react-icons/hi';
import styles from './PanZoom.module.css';

const PanZoom = ({children}) => {
  return <TransformWrapper
  initialScale={1}
  initialPositionX={0}
  initialPositionY={0}
  wrapperClass={styles.mapSizing}
  contentClass={styles.mapSizing}
>
  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
    <>
      <div className={`${styles.tools} ${styles.toolsLeft} tools d-flex mt-2 ml-2`}>
        <div className={`${styles.toolButton} d-flex justify-content-center align-items-center mr-2`} onClick={() => zoomOut()}><HiMinus /></div>
        <div className={`${styles.toolButton} d-flex justify-content-center align-items-center`} onClick={() => zoomIn()}><HiOutlinePlus /></div>  
      </div>
      <div className={`${styles.tools} ${styles.toolsRight} tools d-flex mt-2 mr-2`}>
        <div className={`${styles.toolButton} d-flex justify-content-center align-items-center`} onClick={() => resetTransform()}><MdFilterCenterFocus /></div>
      </div>
      <TransformComponent wrapperClass={styles.mapSizing} contentClass={`${styles.mapSizing} d-flex justify-content-center`}>
        {children}
      </TransformComponent>
    </>
  )}
</TransformWrapper>
}

export default PanZoom;