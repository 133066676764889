import React from 'react'
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent'
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn'
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn'
import TitleDescription from './../../components/TitleDescription/TitleDescription'
import { Button } from 'react-bootstrap'
// import { useIntl } from 'react-intl'

const ButtonWithDescTwoColumn = ({ onClick, buttonDescription, buttonText }) => {
  // const intl = useIntl()
  // const t = (id) => intl.formatMessage({id})

  return (
    <TwoColumnContent topMargin={true}>
      <LeftColumn>
        <TitleDescription
          description={buttonDescription} style={ { 'maxWidth': '100%', 'marginTop': '1.5em' } } />
      </LeftColumn>
      <RightColumn boxShadow={false} border={false} alignRight={true}>
        <Button onClick={onClick}>{buttonText}</Button>
      </RightColumn>
    </TwoColumnContent>
  )
}

export default ButtonWithDescTwoColumn