import React from 'react';

const MessagesBold = () => {
  return (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.82634V9.82143C0 10.4723 0.498978 11 1.1145 11H13.7455C14.361 11 14.86 10.4723 14.86 9.82143V1.82626L7.80432 6.17869C7.57297 6.3214 7.28691 6.3214 7.05556 6.17869L0 1.82634Z" fill="currentColor" />
      <path d="M14.4464 0.262166C14.2549 0.098214 14.0111 0 13.7455 0H1.1145C0.848924 0 0.605043 0.0982315 0.413577 0.26221L7.42994 4.59038L14.4464 0.262166Z" fill="currentColor" />
    </svg>
  );
}

export default MessagesBold;