import React, { useState, useContext } from 'react';
import { Table, Alerts } from "@project/components";
import styles from './VendorSearch.module.css';
import { RootContext } from './../../hoc/RootContext/RootContext';
import { useVendors } from './hooks';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import Title from '../../components/Title/Title';

const VendorSearch = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  let vendors = useVendors({setLoading, setErrors});

  const { smallMobileView, mobileView, xLargeView } = useContext(RootContext);

  const inviteVendorHandler = (vendor) => {
    navigate(`/vendors/${vendor.username}`);
  }

  return (
    <PageContentContainer>
      <div className={styles.vendorSearch}>
        <Title title={t('VendorSearch.title')} backRoute={'/vendors'} button={{click: () => navigate('/vendor-invite'), label: t('VendorSearch.addVendor')}} />
        <div className={`${styles.vendorSearchTable} pt-4`}>
          {errors.length > 0 ?
            <div className="w-100">
              <Alerts messages={errors} close={() => setErrors([])} />
            </div>
            : null}
          <Table
            idField={'username'}
            items={vendors}
            searchFilters={['fname', 'lname', 'city']}
            sortFields={['fname', 'city', 'company']}
            label={t('VendorSearch.table.title')}
            placeholder={t('VendorSearch.table.placeholder')}
            fields={[
              { id: 'fname', label: t('term.vendor'), type: 'details', options: { photo: {key: 'profilePicture', objectFit: 'cover'}, concatValues: { keys: ['lname']}, style: styles.vendorCell } },
              { id: 'city', label: t('VendorSearch.city.label'), type: 'details', options: { concatValues: { keys: ['state'], separator: ', ' } } },
              { id: 'company', label: t('VendorSearch.company.label'), type: 'details', options: { photo: {key: 'logo', noBorder: true }, style: styles.companyCell } },
              { id: 'actions', label: t('VendorSearch.actions.label'), type: 'actions', actions: [ 
                { label: t('VendorSearch.actions.invite.label'), click: inviteVendorHandler }
              ] }
            ]}
            showFields={
              (smallMobileView && ['fname', 'actions']) ||
              (mobileView && ['fname', 'actions']) ||
              (!xLargeView && ['fname', 'company', 'actions'])
            }
            customWidths={
              (mobileView && ['70%', '0', '0', '30%']) ||
              (!xLargeView && ['40%', '0', '40%', '20%']) ||
              ['30%', '25%', '30%', '15%']
            }
            isLoading={loading}
            rowPlaceholderHeight={'71px'}
          />
        </div>
      </div>
    </PageContentContainer>

  );
}

export default VendorSearch;