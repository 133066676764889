import React from 'react'
import RpmIcon from '../RpmIcon/RpmIcon'
import styles from './SubscriptionsTracker.module.css'

const scopes = [
  'Basic', 'Dimensions', 'Drainage',
  'Dumpster', 'Elec', 'ER', 'Fire',
  'Gas', 'Hvac', 'IdVision', 'Irrig',
  'Land', 'Park', 'Pavement', 'Pylon',
  'Roof', 'Sign', 'Space', 'Water'
]

export const RPMScopes = ({ subscription, scopeFilter }) => {
  let scopesList = []
  if (!(scopeFilter && scopeFilter.value)) {
    scopesList.push(...scopes)
  } else {
    scopesList.push(...scopes.filter(scope => scope === scopeFilter.value))
  }

  if (subscription.limitedScope) {
    scopesList = scopesList.filter(scope => subscription[`scope${scope}`] === true);
  }

  return (
    <ul className={styles.scopesList}>
      {scopesList.map(scope => <RpmIcon
        key={`scope${scope}Icon`}
        id={subscription[`scope${scope}Icon`]}
        theme={subscription[`scope${scope}IconColorTheme`]}
        title={subscription[`scope${scope}Title`]}
      />)}
    </ul>
  )
}

export default scopes