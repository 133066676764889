import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './containers/Login/Login';
import LoginVendor from './containers/Login/LoginVendor';
import SamlSsoLogin from './containers/SamlSsoLogin/SamlSsoLogin';
import Logout from './containers/Logout/Logout';
import Dashboard from './containers/Dashboard/Dashboard';
import LostPassword from './containers/LostPassword/LostPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import Company from './containers/Company/Company';
import Team from './containers/Team/Team';
import EditTeamMember from './containers/EditTeamMember/EditTeamMember';
import Invitations from './containers/Invitations/Invitations';
import InviteTeamMember from './containers/InviteTeamMember/InviteTeamMember';
import InviteVendor from './containers/InviteVendor/InviteVendor';
import VendorDashboard from './containers/VendorDashboard/VendorDashboard';
import Settings from './components/Settings/Settings';
import PropertySettings from './containers/PropertySettings/PropertySettings';
import Reports from './containers/Reports/Reports';
import Report from './containers/Report/Report';
import VendorRegistration from './containers/VendorRegistration/VendorRegistration';
import NewVendorRegistration from './containers/NewVendorRegistration/NewVendorRegistration';
import LeaseRadar from './containers/LeaseRadar/LeaseRadar'
import RootContext from './hoc/RootContext/RootContext';
import './app.css';
import { IntlProvider } from 'react-intl';
import VendorSearch from './containers/VendorSearch/VendorSearch';
import EditVendor from './containers/EditVendor/EditVendor';
import ScrollToTop from './utils/ScrollToTop';
import LeaseRadarDetails from './containers/LeaseRadarDetails/LeaseRadarDetails';
import PrivateRouteOutlet from './hoc/PrivateRouteOutlet/PrivateRouteOutlet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FeatureFlagContext, RELEASE_VERSION } from '@project/components'
import MigrateProperty from './containers/MigrateProperty/MigrateProperty';

import English from './lang/en.js';
import Spanish from './lang/es.js';
import FeatureFlags from './FeatureFlags';

let locale = navigator.languages.length && navigator.languages[navigator.languages.length - 1];
let lang;
locale = "en"
if (locale === 'en') {
  lang = English;
} else {
  lang = Spanish;
}

console.log(`Rel ${RELEASE_VERSION}`)

function App() {
  return (
    <IntlProvider messages={lang} locale={locale} defaultLocale="en">
      <FeatureFlagContext flagConfiguration={FeatureFlags}>
        <RootContext>
          <BrowserRouter>
            <ToastContainer />
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login/vendor" element={<LoginVendor />} />
                <Route path="/login/saml-sso" element={<SamlSsoLogin />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/lost-password" element={<LostPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/invitations" element={<Invitations />} />
                <Route path="/vendor/signup" element={<VendorRegistration />} />
                <Route path="/vendor/new-signup" element={<NewVendorRegistration />} />
                <Route path="/*" element={<PrivateRouteOutlet />}>
                  <Route path="" element={<Navigate to="/dashboard" />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="property-settings" element={<PropertySettings />} />
                  <Route path="company-settings" element={<Company />} />
                  <Route path="users" element={<Team />} />
                  <Route path="users/:username" element={<EditTeamMember />} />
                  <Route path="invite-team-member" element={<InviteTeamMember />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="reports/:reportName" element={<Report />} />
                  <Route path="vendors" element={<VendorDashboard />} />
                  <Route path="vendors/:username" element={<EditVendor />} />
                  <Route path="vendor-invite" element={<InviteVendor />} />
                  <Route path="vendor-search" element={<VendorSearch />} />
                  <Route path="lease-radar" element={<LeaseRadar />} />
                  <Route path="lease-radar/details" element={<LeaseRadarDetails />} />
                  <Route path="migrate-property" element={<MigrateProperty/>} />
                </Route>
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </RootContext>
      </FeatureFlagContext>
    </IntlProvider>
  );
}

export default App;
