import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import logo from './../../assets/images/login-logo2.png';
import styles from './LostPassword.module.css';
import axios from '../../utils/axios';
import { isValidEmail } from '../../utils/utils';
import { useIntl } from 'react-intl';

const classNames = require('classnames');

const LostPassword = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [errors, setErrors] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  const sendLostPasswordRequest = async () => {
    setErrors(null);
    setMessage(null);

    try {

      if (isValidEmail(email)) {

        const result = await axios.post('/users/lost-password', { email });

        if (result.status === 200) {
          setMessage(result.data.message);
        }

      }
      else {
        setErrors(t('error.email.valid'));
      }


    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.sendingRequest')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('ui.server'));
      }
    }
  };

  return (
    <div className={classNames(styles.loginPage, 'd-flex')}>
      <div className={classNames(styles.loginContainer, 'm-auto mw-100 bg-white shadow-sm')}>
        <div className={classNames(styles.logo, 'text-center')}>
          <img src={logo} width="133px" alt="ID360" />
        </div>
        <p>
          {t('LostPassword.label')}
        </p>
        <Form>
          <Form.Group>
            <Form.Control
              id="email" className={styles.loginInput}
              type="text" placeholder={t('LostPassword.placeholder')}
              onChange={e => setEmail(e.target.value)} />
          </Form.Group>
          {errors ? (
            <Alert key={errors} variant="danger">
              {errors}
            </Alert>
          ) : null}
          {message ? (
            <Alert key={message} variant="success">
              {message}
            </Alert>
          ) : null}
          <Button
            variant="primary"
            block
            className={classNames(styles.btnLogin, 'border-0 w-100 d-flex align-items-center justify-content-center')}
            onClick={sendLostPasswordRequest}>
            {t('LostPassword.send')}
          </Button>
        </Form>
        <div className="border-bottom my-3"></div>
      </div>
    </div>
  );
};

export default LostPassword;
