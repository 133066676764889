import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import Lightbox from '../Lightbox/Lightbox'
import { ENVIRONMENTS } from '../utils/constants'
import LoadingIndicator from './LoadingIndicator'
import styles from './SiteplanLightboxButton.module.css'

/**
 * Component that renders a button that, when clicked:
 * - Calls the renderer service and waits for the S3 URL of a rendered siteplan
 * - Opens a fullscreen image lightbox and shows the siteplan
 */
const SiteplanLightboxButton = ({
  assetGuid,
  axiosInstance,
  axiosCancelTokenFactory,
  children,
  companyName,
  propertyName,
  onError
}) => {
  /**
   * Controls the open/closed state of the image viewer
   * (for both fetching the siteplan and showing the lightbox).
   */
  const [isOpen, setIsOpen] = useState(false)
  /**
   * Indicates an ongoing request to the renderer service.
   */
  const [isLoadingSiteplan, setIsLoadingSiteplan] = useState(true)
  /**
   * Should be set to a URL string once we get a response from the
   * renderer service.
   */
  const [siteplanUrl, setSiteplanUrl] = useState(null)

  /**
   * Overrides the default z-index value of the imagelightbox to
   * surpass the `!important` z-index value that was thrown to some other
   * components sharing the same DOM tree. Will not tackle specificity,
   * so if any new component gets rendered on top of the lightbox again,
   * this value should be increased.
   */
  const lightboxZIndex = 10001

  /**
   * The loading indicator should be shown if
   * - The image viewer is "open"
   * - We have called the renderer
   * - And it hasn't replied yet
   */
  const shouldRenderLoading = useMemo(() => (
    isOpen && isLoadingSiteplan
  ), [isOpen, isLoadingSiteplan])

  /**
   * The fullscreen lightbox should be shown if
   * - The image viewer is "open"
   * - We don't have a pending request to the renderer
   * - We were able to get an S3 URL from the renderer
   */
  const shouldRenderLightbox = useMemo(() => (
    isOpen && !isLoadingSiteplan && siteplanUrl
  ), [isOpen, isLoadingSiteplan, siteplanUrl])

  /**
   * Renderer should be called only if
   * - The image viewer is "open"
   * - We don't have an S3 URL stored
   */
  const shouldFetchSiteplan = useMemo(() => (
    isOpen && !siteplanUrl
  ), [isOpen, siteplanUrl])

  /**
   * Sets this image viewer as "open"
   */
  const handleMainButtonClick = () => setIsOpen(true)

  /**
   * Sets this image viewer as "closed"
   */
  const handleLightboxClose = () => setIsOpen(false)

  /**
   * Given an error instance:
   * - Stops any loading indicators
   * - Sets this image viewer as "closed"
   * - Notifies the parent component via the `onError` prop
   */
  const handleFetchError = useCallback((error) => {
    if (!error) {
      return
    }

    setIsLoadingSiteplan(false)
    setIsOpen(false)
    onError(error)
  }, [onError])

  /**
   * If the lightbox component couldn't fetch the siteplan SVG,
   * will call this function. It happens in some edge cases where
   * the Renderer API will return an S3 URL and it might take a couple of
   * milliseconds before it could be properly fetched.
   * This function will try to refresh it by cycling through a `t`
   * query param to prevent caching.
   * The lightbox component will stop calling this function once it
   * can load the SVG.
   */
  const retryLightbox = useCallback(() => {
    if (!siteplanUrl) {
      return
    }

    const sitePlanUrlParsed = new URL(siteplanUrl)
    sitePlanUrlParsed.searchParams.set('t', Date.now()) // Keep refreshing the timestamp while re-fetching
    setSiteplanUrl(sitePlanUrlParsed.toString())
  }, [siteplanUrl])

  useEffect(() => {
    const source = axiosCancelTokenFactory.source()

    const fetchSiteplanFromRenderer = async () => {
      const payload = {
        data: {
          asset_guid: assetGuid,
          company_name: companyName,
          property_name: propertyName,
          sections: ['space', 'bg']
        },
        meta: {
          page: 1,
          displayPage: 1,
          propertyWidth: 1200,
          propertyHeight: 1200,
          disableCache: process.env.REACT_APP_BUILD_TARGET !== ENVIRONMENTS.PRODUCTION
        }
      }

      try {
        setIsLoadingSiteplan(true)

        const response = await axiosInstance.post(`${process.env.REACT_APP_RENDERER_HOST}?excludeRaw=true`, JSON.stringify(payload), {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (response.url) {
          // Appends a timestamp to prevent strong caching
          setSiteplanUrl(`${response.url}?t=${Date.now()}`)
        }

        setIsLoadingSiteplan(false)
      } catch (e) {
        handleFetchError(e)
      }
    }

    if (shouldFetchSiteplan) {
      fetchSiteplanFromRenderer()
    }

    return () => {
      source.cancel()
    }
  }, [assetGuid, axiosCancelTokenFactory, axiosInstance, companyName, handleFetchError, propertyName, shouldFetchSiteplan, siteplanUrl])

  return (
    <>
      <Button
        disabled={isOpen}
        className={styles.ShowLightboxButton}
        onClick={handleMainButtonClick}
      >
        {shouldRenderLoading && <LoadingIndicator />}
        {!shouldRenderLoading && children}
      </Button>
      {shouldRenderLightbox && (
        <Lightbox
          mainSrc={siteplanUrl}
          onImageLoadError={retryLightbox}
          imageLoadErrorMessage="Please wait..."
          onCloseRequest={handleLightboxClose}
          zIndex={lightboxZIndex}
        />
      )}
    </>
  )
}

SiteplanLightboxButton.propTypes = {
  /**
   * GUID for the asset to be highlighted on the siteplan.
   * Required.
   */
  assetGuid: PropTypes.string.isRequired,
  /**
   * Children elements. Will be rendered inside button..
   * Required.
   */
  children: PropTypes.node.isRequired,
  /**
   * Name of the company this siteplan refers to.
   * Required.
   */
  companyName: PropTypes.string.isRequired,
  /**
   * Name of the property this siteplan refers to.
   * Required.
   */
  propertyName: PropTypes.string.isRequired,
  /**
   * Function to be called if there's an error fetching the siteplan.
   */
  onError: PropTypes.func
}

SiteplanLightboxButton.defaultProps = {
  onError: () => { }
}

export default memo(SiteplanLightboxButton)
