import React from 'react';
import { useIntl } from 'react-intl';

function UsersPropertyRolesList({ properties, allSelectedViewer, allSelectedEditor, allSelectedDesigner, allSelectedAdmin, onPropertyRoleChange, selectAll, allowAdmin }) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  if (!properties) return null;

  return (
    <div style={{ "maxHeight": "500px", "overflow": "auto" }}>
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th>{t('UsersPropertyRolesList.propertyName')}</th>
            <th>{t('UsersPropertyRolesList.viewer')}</th>
            <th>{t('UsersPropertyRolesList.editor')}</th>
            <th>{t('UsersPropertyRolesList.designer')}</th>
            {
              allowAdmin ?
                <th>{t('UsersPropertyRolesList.admin')}</th>
                : null
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td className='text-center'>
              <input
                checked={allSelectedViewer}
                type='checkbox'
                onChange={(e) => { return selectAll('viewer', e.currentTarget.checked) }} />
            </td>
            <td className='text-center'>
              <input
                checked={allSelectedEditor}
                type='checkbox'
                onChange={(e) => { return selectAll('editor', e.currentTarget.checked) }} />
            </td>
            <td className='text-center'>
              <input
                checked={allSelectedDesigner}
                type='checkbox'
                onChange={(e) => { return selectAll('designer', e.currentTarget.checked) }} />
            </td>
            {
              allowAdmin ?
                <td className='text-center'>
                  <input
                    checked={allSelectedAdmin}
                    type='checkbox'
                    onChange={(e) => { return selectAll('company-admin', e.currentTarget.checked) }} />
                </td>
                : null
            }

          </tr>
          {
            properties.map((_, index) => {

              return (
                <tr key={index}>
                  <td style={{ "fontWeight": 'bold' }}>{_.displayName}</td>
                  <td className='text-center'>
                    <input
                      type='checkbox'
                      checked={_.roleId === 'viewer'}
                      onChange={(e) => onPropertyRoleChange(e, index, 'viewer')} />
                  </td>
                  <td className='text-center'>
                    <input
                      type='checkbox'
                      checked={_.roleId === 'editor'}
                      onChange={(e) => onPropertyRoleChange(e, index, 'editor')} />
                  </td>
                  <td className='text-center'>
                    <input
                      type='checkbox'
                      checked={_.roleId === 'designer'}
                      onChange={(e) => onPropertyRoleChange(e, index, 'designer')} />
                  </td>

                  {
                    allowAdmin ?
                      <td className='text-center'>
                        <input
                          type='checkbox'
                          checked={_.roleId === 'company-admin'}
                          onChange={(e) => onPropertyRoleChange(e, index, 'company-admin')} />
                      </td>
                      : null
                  }

                </tr>
              )

            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default UsersPropertyRolesList;
