import React from 'react';
import styles from './TitleDescription.module.css';

function TitleDescription({ title, description, style }) {
  return (
    <>
      <div className="font16">{title}</div>
      <p className={styles.desc} style={style}>
        {description}
      </p>
    </>
  );
};

export default TitleDescription;
