import { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import axios, { CancelToken, isCancel } from '../../utils/axios';
import { get } from '../../utils/utils';
import { getImageUrl } from '@project/components/src/utils/image-location-utils'
import DefaultProfilePicture from '../../assets/images/unknown-unit.png';
import { RootContext } from './../../hoc/RootContext/RootContext';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

export const useSpaces = ({ setErrors, setLoading, leaseStatus, spaceQueryParams, activeTable, setLeaseStatus }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { currentCompany, currentProperty } = useContext(RootContext);

  const location = useLocation();
  const navigate = useNavigate();

  const currentCompanyValue = get(currentCompany, 'value', '');
  const currentPropertyValue = get(currentProperty, 'name', '');

  const emptySpacesData = {
    spaces: [],
    pagination: {
      count: null,
      page: null,
      limit: null
    }
  };

  const [spacesData, setSpacesData] = useState(emptySpacesData);
  const [leaseStatusInit, setLeaseStatusInit] = useState(true);

  const query = new URLSearchParams(useLocation().search);
  const queryLeaseStatus = query.get("leaseStatus");

  useEffect(() => {
    setLeaseStatus(queryLeaseStatus || 'all');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!leaseStatusInit && currentProperty) navigate(`${location.pathname}?${currentCompany && currentCompany.slug ? `company=${currentCompany.slug}` : ''}${currentProperty && currentProperty.slug ? `&property=${currentProperty.slug}` : ''}&leaseStatus=${leaseStatus}`, {replace: true});
    setLeaseStatusInit(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseStatus])

  useEffect(() => {
    const source = CancelToken.source();

    const fetch = async () => {
      try {
        setErrors({});
        setLoading(true);

        let orderByMap = {
          'leaseExpirationFormatted': 'leaseEnd',
          'property': 'p.name',
          'unit': 'unit',
          'address': 'address',
          'leaseExpirationDate': 'leaseEnd'
        }

        // The Table component is 0-based, we must adjust the value for the API so that
        // page numbers start from 1
        const normalizedPageNumber = Number(spaceQueryParams.page) + 1;
        let orderBy = orderByMap[spaceQueryParams.orderBy] || 'p.name';

        const results = await axios.get(`/space-profiles/leasing-datalake?leaseStatus=${leaseStatus}&search=${spaceQueryParams.search}&limit=${spaceQueryParams.limit}&page=${normalizedPageNumber}&orderBy=${orderBy}&orderAscending=${spaceQueryParams.orderAscending}&company=${currentCompany.value}${currentProperty && currentProperty.name ? `&property=${currentProperty.name}` : ''}`, { cancelToken: source.token });
        if (results.status === 200) {
          let spaces = get(results, 'data.records', [])
          spaces.forEach((space, i) => {
            space.id = `${space.id}_${space.unit}_${i}`;
            space.leasing_image = getImageUrl(space, ['leasing_image.thumbnail.S', 'leasing_image'], DefaultProfilePicture, 'leasing_image')
            space.hasLease = space.leaseEnd;
            space.leaseExpirationFormatted = space.leaseEnd && !space.isLeaseExpired ? t('term.occupied') : t('term.vacant');
            space.unit = space.unit ? `#${space.unit}` : '';
            space.leaseExpirationDate = space.leaseEnd ? moment(space.leaseEnd).format('MM/DD/YYYY') : '';
          })
          const count = get(results, 'headers.pagination-count', null)
          const page = get(results, 'headers.pagination-page', null)
          const limit = get(results, 'headers.pagination-limit', null)

          setSpacesData({
            spaces,
            pagination: { count, page, limit }
          })
          setErrors([])
          setLoading(false)
        }
      } catch (e) {
        if (!isCancel(e)) {
          setLoading(false);
        }
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        }

        setSpacesData(emptySpacesData);
      }
    }

    if (currentCompany) fetch();

    return () => {
      setLoading(false);
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyValue, currentPropertyValue, activeTable])

  return spacesData;
}
