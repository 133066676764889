import { useMemo } from 'react'
import { INSPECT_FILTER_TYPE, INSPECT_TYPE } from '../../../utils/constants'

/**
 * Creates tab schema for inspect tables
 * @param {string} type inspection or work-order
 * @param {(s: string) => string} t translator
 * @param {(filter: string) => void} setInspectFilter sets the filter type (assigned, all, etc.)
 * @param {boolean} isAdmin is user admin
 * @param {string} inspectFilter filter type (assigned, all, etc.)
 * @param {string} type inspection or work-order
 * @param {boolean} external is table found outside idinspect
 * @returns {any} Schema for status field
 */
const useTableTabs = ({
  t,
  setInspectFilter,
  isAdmin,
  isVendor = false,
  inspectFilter,
  type,
  external
}) => {
  const tabs = useMemo(() => {
    const labels = {
      [INSPECT_FILTER_TYPE.ASSIGNED]: {
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.tabs.assigned'),
        [INSPECT_TYPE.INSPECTION]: t('Inspections.tabs.assigned')
      },
      [INSPECT_FILTER_TYPE.COMPLETED]: {
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.tabs.completed'),
        [INSPECT_TYPE.INSPECTION]: t('Inspections.tabs.completed')
      },
      [INSPECT_FILTER_TYPE.PROPERTIES]: {
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.tabs.properties'),
        [INSPECT_TYPE.INSPECTION]: t('Inspections.tabs.properties')
      },
      [INSPECT_FILTER_TYPE.ALL]: {
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.tabs.all'),
        [INSPECT_TYPE.INSPECTION]: t('Inspections.tabs.all')
      },
      [INSPECT_FILTER_TYPE.WATCHING]: {
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.tabs.watching'),
        [INSPECT_TYPE.INSPECTION]: t('Inspections.tabs.watching')
      }
    }

    const completedTab = type === INSPECT_TYPE.WORK_ORDER || !external ? [{
      id: INSPECT_FILTER_TYPE.COMPLETED,
      label: labels[INSPECT_FILTER_TYPE.COMPLETED][type],
      click: () => setInspectFilter(INSPECT_FILTER_TYPE.COMPLETED)
    }] : []

    const defaultTabs = [{
      id: INSPECT_FILTER_TYPE.ASSIGNED,
      label: labels[INSPECT_FILTER_TYPE.ASSIGNED][type],
      click: () => setInspectFilter(INSPECT_FILTER_TYPE.ASSIGNED)
    },
    ...completedTab,
    {
      id: INSPECT_FILTER_TYPE.PROPERTIES,
      label: labels[INSPECT_FILTER_TYPE.PROPERTIES][type],
      click: () => setInspectFilter(INSPECT_FILTER_TYPE.PROPERTIES)
    },
    {
      id: INSPECT_FILTER_TYPE.WATCHING,
      label: labels[INSPECT_FILTER_TYPE.WATCHING][type],
      click: () => setInspectFilter(INSPECT_FILTER_TYPE.WATCHING)
    }]

    const vendorTabs = [{
      id: INSPECT_FILTER_TYPE.ASSIGNED,
      label: labels[INSPECT_FILTER_TYPE.ASSIGNED][type],
      click: () => setInspectFilter(INSPECT_FILTER_TYPE.ASSIGNED)
    },
    ...completedTab]

    return (
      isAdmin ? [{
        id: INSPECT_FILTER_TYPE.ALL,
        label: labels[INSPECT_FILTER_TYPE.ALL][type],
        click: () => setInspectFilter(INSPECT_FILTER_TYPE.ALL)
        // this is for: https://idplans.atlassian.net/browse/IDI-304
      }, ...defaultTabs] : isVendor && type === INSPECT_TYPE.WORK_ORDER ? vendorTabs : defaultTabs
    )
  }, [t, type, external, isAdmin, setInspectFilter, isVendor])

  const customSelectedTab = useMemo(() => {
    const targetIndex = tabs.findIndex(tab => tab.id === inspectFilter)
    return targetIndex >= 0 ? targetIndex : 0 // findIndex can return -1 when no matches, so default to first tab
  }, [inspectFilter, tabs])

  return [tabs, customSelectedTab]
}

export default useTableTabs