import React from 'react';

export default <>
  <path
    d="M42.7053 50.0171C53.751 50.0171 62.7053 41.0628 62.7053 30.0171C62.7053 18.9714 53.751 10.0171 42.7053 10.0171C31.6596 10.0171 22.7053 18.9714 22.7053 30.0171C22.7053 41.0628 31.6596 50.0171 42.7053 50.0171Z"
    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path
    d="M42.7055 50.0171C47.6147 50.0171 51.5944 41.0628 51.5944 30.0171C51.5944 18.9714 47.6147 10.0171 42.7055 10.0171C37.7963 10.0171 33.8167 18.9714 33.8167 30.0171C33.8167 41.0628 37.7963 50.0171 42.7055 50.0171Z"
    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path
    d="M22.7053 30.0171C22.7053 34.9282 31.6609 38.906 42.7053 38.906C53.7498 38.906 62.7053 34.9282 62.7053 30.0171C62.7053 25.106 53.7498 21.1282 42.7053 21.1282C31.6609 21.1282 22.7053 25.106 22.7053 30.0171Z"
    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></>