import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RootContext } from '../../hoc/RootContext/RootContext';
import { get } from '../../utils/utils';
import DashboardCard from '../DashboardCard/DashboardCard';
import { useCompanySettings, useUserActions } from '../../utils/hooks';
import axios, { isCancel, CancelToken } from '../../utils/axios'
import { InspectTable as InspectionsTable, TABLE_TYPES } from '@project/components/src/DataTables';
import { useIntl } from 'react-intl';

/**
 * Dashboard Card component in charge of rendering a table with all the
 * assigned IDInspect inspections, filtered by the current Company and
 * Property values.
 * The look and feel of this component mimics the one on the IDInspect
 * Dashboard, except for an added "View" link that sends the user to
 * the inspection details page on the IDInspect app.
 * @example
 * ```jsx
 * const [errors, setErrors] = useState([]);
 *
 * // This will load based on the global RootContext changes
 * <AssignedInspections setErrors={setErrors} />
 *
 * // Display errrors, etc
 * <ul>
 *   {errors.map((errorMsg, index) => <li key={index}>{errorMsg}</strong>)}
 * </ul>
 * ```
 */

const AssignedInspections = ({
  setErrors
}) => {
  // Global states
  const {
    currentCompany,
    currentProperty,
    currentUser
  } = useContext(RootContext);

  const [currentCompanySettings] = useCompanySettings(currentCompany, true);
  const hasInspectAdminRole = useUserActions(get(currentCompany, 'value'), get(currentProperty, 'value'), 'inspection:ui:admin', setErrors);

  const isAdmin = hasInspectAdminRole && get(currentCompanySettings, 'inspections.enabled');
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  return (
    <DashboardCard title={t('Inspections.title')}>
      <InspectionsTable
        currentUser={currentUser}
        currentCompany={currentCompany}
        isAdmin={isAdmin}
        toast={() => { }}
        setError={setErrors}
        external={true}
        axios={axios}
        CancelToken={CancelToken}
        isCancel={isCancel}
        type={TABLE_TYPES.INSPECTION}
        rowPlaceholderHeight={"0px"}
      />
    </DashboardCard>
  );
};

AssignedInspections.propTypes = {
  /** To be called after catching an API error. */
  setErrors: PropTypes.func
};

AssignedInspections.defaultProps = {
  setErrors: () => null
};

export default AssignedInspections;
