import React from 'react';

const TeamBold = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.17302 8.39949C4.87585 8.4653 4.56697 8.5 4.25 8.5C1.90279 8.5 0 6.59721 0 4.25C0 1.90279 1.90279 0 4.25 0C6.54571 0 8.41629 1.82021 8.49727 4.09615L12.2178 4.71624C12.6525 3.93136 13.4892 3.4 14.45 3.4C15.8583 3.4 17 4.54167 17 5.95C17 7.35833 15.8583 8.5 14.45 8.5C14.1776 8.5 13.9152 8.45729 13.669 8.37819L11.2328 11.5768C11.652 12.1422 11.9 12.8421 11.9 13.6C11.9 15.4778 10.3778 17 8.5 17C6.62223 17 5.1 15.4778 5.1 13.6C5.1 12.5481 5.57765 11.6079 6.32787 10.9842L5.17302 8.39949ZM6.72489 7.70545L7.86584 10.259C8.0713 10.2203 8.28328 10.2 8.5 10.2C9.00358 10.2 9.48158 10.3095 9.91152 10.5059L12.3169 7.3478C12.1305 7.06394 11.9993 6.74064 11.9384 6.39312L8.21889 5.77321C7.91879 6.55465 7.39455 7.22497 6.72489 7.70545Z" fill="currentColor" />
    </svg>
  );
}

export default TeamBold;