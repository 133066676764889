/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { getCookieOptions } from "./../../utils/utils";
import axios from "./../../utils/axios";

export const RootContext = React.createContext();

export default ({ children }) => {

  /*
  * TODO - Specify SameSite=Strict or SameSite=Lax if the cookie should not be sent in cross-site requests
  * */
  const cookies = new Cookies();

  const prevAuth = cookies.get('auth');
  const userState = cookies.get('logged-in');
  const [authToken, setAuthToken] = useState(prevAuth);
  const [currentUser, setCurrentUser] = useState(null);
  const [globalProperties, setGlobalProperties] = useState([]);
  const [companyProperties, setCompanyProperties] = useState([]);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(true);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(userState === 'true' || false);
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);
  const [smallMobileView, setSmallMobileView] = useState(window.innerWidth <= 576);
  const [xLargeView, setXLargeView] = useState(window.innerWidth >= 1200);
  const [largeView, setLargeView] = useState(window.innerWidth >= 992);
  const [isLoadingGlobalProperties, setIsLoadingGlobalProperties] = useState(false);
  const [loginErrors, setLoginErrors] = useState([]);

  const identifyUser = user => {
    if (!window.FS) return;
    try {
      window.FS.identify(user.username, {
        email: user.email,
        displayName: `${user.fname} ${user.lname}`
      })
    } catch (e) {
      // no-op
    }
  }


  const getUser = async (payload) => {
    try {
      const result = await axios.post('/login', payload || { token: authToken });

      if (result.status === 200) {
        const { user } = result.data;
        setCurrentUser(user);
        identifyUser(user);
      } else {
        const errors = result.data.errors || [];
        if (errors.length) {
          if (Array.isArray(errors)) {
            setLoginErrors(errors.map((error) => error.message));
          } else {
            setLoginErrors([errors.message]);
          }
        }
      }
    } catch (e) {
      setAuthToken(null);
      setCurrentUser(null);
      throw e;
    }
  };

  useEffect(() => {
    if (globalProperties && currentCompany) {
      setCompanyProperties(globalProperties.filter(property => property.companySlug === currentCompany.slug))
    }
  }, [currentCompany, globalProperties])

  useEffect(() => {
    if (!isLoggedIn) return;
    let copts = getCookieOptions();
    cookies.set('logged-in', 'true', copts);
  }, [isLoggedIn]);

  useEffect(() => {
    if (!largeView) {
      setIsAdminMenuOpen(false);
    }
  }, [largeView])

  useEffect(() => {
    const handleResize = () => {
      setSmallMobileView(window.innerWidth <= 576);
      setLargeView(window.innerWidth >= 992);
      setXLargeView(window.innerWidth >= 1200)
      setMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return _ => window.removeEventListener('resize', handleResize);
  });

  useEffect(async () => {
    let copts = getCookieOptions();
    if (!authToken && currentUser) {
      setAuthToken(currentUser.token);
    }

    if (!authToken && !currentUser) {
      cookies.remove('auth', { path: copts.path, domain: copts.domain });
    }
    else {
      cookies.set('auth', authToken || currentUser.token, copts);
    }

    if (authToken && !currentUser) {
      // lookup user, in case login was saved in cookie
      // user is not saved any longer, so if this was not
      // a user who just logged in, get user
      try {
        await getUser();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }

    }

  }, [authToken, currentUser]);

  const defaultContext = {
    getUser,
    currentUser,
    setCurrentUser,
    authToken,
    setAuthToken,
    globalProperties,
    setGlobalProperties,
    isAdminMenuOpen,
    setIsAdminMenuOpen,
    currentProperty,
    setCurrentProperty,
    showToast,
    setShowToast,
    currentCompany,
    setCurrentCompany,
    isLoggedIn,
    setIsLoggedIn,
    mobileView,
    smallMobileView,
    xLargeView,
    largeView,
    isLoadingGlobalProperties,
    setIsLoadingGlobalProperties,
    companyProperties,
    loginErrors
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
}