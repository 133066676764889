
export const UPLOADED_FILE_CATEGORY = {
  GENERAL: 'general',
  PHOTOS: 'photos',
  DOCUMENTS: 'documents',
  VIDEOS: 'videos'
};

/**
 * General-purpose section definitions used by the Ember app.
 * @see https://github.com/idplans/rpm-viewer/blob/staging/app/lib/sections/definitions.js
 */
export const RPM_VIEWER_SECTION = {
  INDEX: 'index',
  SITEPLAN: 'siteplan',
  GENERAL: 'general',
  DIMENSIONS: 'dimensions',
  DUMPSTER: 'dumpster',
  ELECTRIC: 'electric',
  EMERGENCY: 'emergency',
  FIRE: 'fire',
  GAS: 'gas',
  HVAC: 'hvac',
  IRRIGATION: 'irrigation',
  LANDSCAPE: 'landscape',
  LIGHT: 'light',
  PARKING: 'parking',
  PAVEMENT: 'pavement',
  PYLON: 'pylon',
  ROOF: 'roof',
  SIGNAGE: 'signage',
  SPACE: 'space',
  STORM: 'storm',
  WATER: 'water',
  VIDEO: 'video',
  BACKGROUND: 'background',
  SAT: 'sat',
  IDDIGITAL: 'iddigital'
}

/**
 * Mapping that links the general-purpose sections used by the Ember app
 * with the asset section used in data models.
 */
export const RPM_VIEWER_SECTION_ASSET_SECTION_MAPPING = {
  [RPM_VIEWER_SECTION.INDEX]: 'info',
  [RPM_VIEWER_SECTION.SITEPLAN]: 'siteplan',
  [RPM_VIEWER_SECTION.GENERAL]: 'bs',
  [RPM_VIEWER_SECTION.DIMENSIONS]: 'dimensions',
  [RPM_VIEWER_SECTION.DUMPSTER]: 'dump',
  [RPM_VIEWER_SECTION.ELECTRIC]: 'elec',
  [RPM_VIEWER_SECTION.EMERGENCY]: 'er',
  [RPM_VIEWER_SECTION.FIRE]: 'fire',
  [RPM_VIEWER_SECTION.GAS]: 'gas',
  [RPM_VIEWER_SECTION.HVAC]: 'hvac',
  [RPM_VIEWER_SECTION.IRRIGATION]: 'irrig',
  [RPM_VIEWER_SECTION.LANDSCAPE]: 'land',
  [RPM_VIEWER_SECTION.LIGHT]: 'light',
  [RPM_VIEWER_SECTION.PARKING]: 'park',
  [RPM_VIEWER_SECTION.PAVEMENT]: 'pave',
  [RPM_VIEWER_SECTION.PYLON]: 'ps',
  [RPM_VIEWER_SECTION.ROOF]: 'roof',
  [RPM_VIEWER_SECTION.SIGNAGE]: 'sign',
  [RPM_VIEWER_SECTION.SPACE]: 'space',
  [RPM_VIEWER_SECTION.STORM]: 'storm',
  [RPM_VIEWER_SECTION.WATER]: 'water',
  [RPM_VIEWER_SECTION.VIDEO]: 'videoMap',
  [RPM_VIEWER_SECTION.BACKGROUND]: 'bg',
  [RPM_VIEWER_SECTION.SAT]: 'sat',
  [RPM_VIEWER_SECTION.IDDIGITAL]: 'iddigital'
}
