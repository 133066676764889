/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import classNames from 'classnames'
import { Form, Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Navigate, useLocation } from 'react-router-dom'
import { Alerts, Spinner, useFeatureFlags } from '@project/components'
import logo from '../../assets/images/login-logo2.png'
import styles from './Login.module.css'
import axiosInstance from '../../utils/axios'
import { FEATURES } from '@project/components/src/FeatureFlags/Features'
import Cookies from 'universal-cookie'

const LoginVendor = () => {
  const cookies = new Cookies();

  // Ensure any regular user login is removed.
  cookies.remove('auth');
  cookies.remove('is-logged-in');

  const { isFlagEnabled } = useFeatureFlags();

  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const query = new URLSearchParams(useLocation().search);

  const workOrderId = query.get('wid');
  const companyGuid = query.get('cid');

  const sendLoginRequest = async () => {
    setLoading(true);
    setErrors([]);

    const options = { phone: phoneNumber, pin }

    if (companyGuid) {
      options.company = companyGuid
    }

    if (workOrderId) {
      options.workOrderId = Number(workOrderId)
    }

    try {
      const { data } = await axiosInstance.post('/login/vendor', options)
      if (!data.url) {
        setErrors([t('ui.notAuthorized')]);
      } else {
        window.location.href = data.url
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const acceptEnter = (e) => {
    if (e.keyCode === 13) {
      sendLoginRequest();
    }
  };

  return !isFlagEnabled(FEATURES.IDINSPECT_VENDOR_FLOW) ? <Navigate to={'/login'} /> : (
    <div className={classNames(styles.loginPage, 'd-flex')}>
      <div className={classNames(styles.loginContainer, 'm-auto mw-100 bg-white shadow-sm')}>
        <div className={classNames(styles.logo, 'text-center')}>
          <img src={logo} width="313px" alt="IDCloud" />
        </div>
        <p>{t('LoginVendor.welcome')}</p>
        <Form>
          <Form.Group>
            <Form.Control id="phoneNumber" className={styles.loginInput} type="text" placeholder={t('ui.phoneNumber')}
              onChange={e => setPhoneNumber(e.target.value)}
              onKeyUp={acceptEnter} autoCorrect="off" autoCapitalize="none"
            />
          </Form.Group>
          <Form.Group controlId="">
            <Form.Control id="pin" className={styles.loginInput} type="password" placeholder={t('ui.pin')}
              onChange={e => setPin(e.target.value)}
              maxLength={4}
              onKeyUp={acceptEnter} autoCorrect="off" autoCapitalize="none"
            />
          </Form.Group>

          <div className="border-bottom my-3"></div>

          {errors.length ? <Alerts variant='danger' messages={errors} dismissible close={() => setErrors([])} /> : null}

          <Button variant="primary" block
            className={classNames(styles.btnLogin, 'border-0 w-100 d-flex align-items-center justify-content-center')}
            onClick={sendLoginRequest}>
            {t('ui.login')}
          </Button>
        </Form>
      </div>
      {loading ?
        <Spinner />
        : null
      }
    </div>
  );
};

export default LoginVendor;
