import React, { useState, useEffect } from 'react';
import styles from './CheckBox.module.css';
import Check from '../assets/images/check';

const CheckBox = ({ checked, select, unselect, data }) => {
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked])

  return <div data-testid='dropdown-checkbox' onClick={check ? () => { unselect(data); setCheck(false) } : () => { select(data); setCheck(true); }} className={check ? styles.checked : styles.unchecked}>{check && <Check />}</div>
}

export default CheckBox;