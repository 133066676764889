import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import styles from './TagPills.module.css';

const TagPills = ({
  editHandler,
  hideTags,
  link,
  tags,
  readOnly = false
}) => {
  const intl = useIntl();
  const t = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  if (hideTags) return null;
  const tagPills = tags.map((tag, index) => <div key={`tag${index}`} className={`${styles.tags} mr-2 mb-2`}>{tag}</div>)
  const edit = editHandler || link ? <div className={`${styles.tags} ${styles.edit} mr-2 mb-2`} onClick={editHandler}>{t('ui.edit')}</div> : null;
  const editPill = link ? <a data-testid="CardLink" href={link}>{edit}</a> : edit;

  return <div className='d-flex mt-2 flex-wrap'>
    {tagPills}
    {!readOnly && editPill}
  </div>
}

export default TagPills;