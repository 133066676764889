import React, { useEffect, useState, useContext, useRef } from 'react';
import styles from './VendorRegistration.module.css';
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import Celebrate from '../../assets/images/celebrate.png';
import CelebrateFace from '../../assets/images/celebrate-face.png';
import { Step0, Step1, Step3, Step4, Step5 } from './Steps/Steps';
import { AdminInput, MultiStepNavigator, AdminSelect, TagSelector, Spinner, Tooltip, AdminCheckbox } from '@project/components';
import { get } from '../../utils/utils';
import formSchema from './schema.js';
import IDPlansLogo from '../../assets/images/id-plans-logo.png';
import { Alert } from 'react-bootstrap';
import { RootContext } from './../../hoc/RootContext/RootContext';

const VendorRegistration = (props) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const { mobileView, smallMobileView } = useContext(RootContext);

  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const [loading, setLoading] = useState(true);
  const [, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [previousStepErrors, setPreviousStepErrors] = useState([])
  const [registrationToken, setRegistrationToken] = useState(null);
  const [step, setStep] = useState(0);
  const [vendorRegistration, setVendorRegistration] = useState({ password: '' });
  const [logo, setLogo] = useState([]);
  const [validPassword, setValidPassword] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [vendorInvite, setVendorInvite] = useState({});
  const [validInvite, setValidInvite] = useState(true);

  const tooltipRef = useRef(null);

  const stepFormKeys = [
    ['fname', 'lname', 'phone', 'privacyPolicy'],
    ['company', 'companySite', 'address1', 'city', 'postal', 'state'],
    // ['travelRadius'],
    ['businessCategories'],
    ['logo'],
    ['password']
  ]

  const skippableSteps = [
    // {step: 2, field: 'travelRadius'},
    { step: 3, field: 'logo', condition: _ => !logo.length }
  ];

  // validate invitation exists and is active
  useEffect(() => {
    setLoading(false);
    setServerErrors([]);

    const val = async () => {
      try {
        setLoading(true);

        let result = await axios.post(`/validate-vendor-invite`, { token: registrationToken });

        if (result.status === 200) {
          setVendorInvite(result.data.invite);
          setVendorRegistration(get(result, 'data.invite.state', {}));
          let logo = get(result, 'data.invite.state.logo.standard') || get(result, 'data.invite.state.logo.original');
          setLogo(logo ? [logo] : [])
          if (get(result, 'data.invite.status', false) !== 'active') setValidInvite(false);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setServerErrors([`${t('error.sendInvite')} (${e.response.data.errors})`]);
        } else {
          setServerErrors([(e && e.message) || t('error.server')]);
        }
        setValidInvite(false);
      }
      finally {
        setLoading(false);
      }
    };

    if (registrationToken) {
      setRegistrationToken(registrationToken);
      val();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationToken]);

  useEffect(() => {
    const registrationTokenParam = query.get('t');
    let step = query.get('step');

    if (registrationTokenParam) {
      setRegistrationToken(registrationTokenParam);
    }
    if (step) {
      step = parseInt(step);
      if (step >= 0 && step < stepForms.length) {
        setStep(step);
      } else {
        setStep(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registrationToken) {
      navigate({
        pathname: '/vendor/signup',
        search: `?t=${registrationToken}&step=${step}`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, registrationToken])

  useEffect(() => {
    setValidPassword(false);
    setValidatePassword(false);
  }, [step])

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');

    let phone = cleaned.slice(0, 3);

    if (cleaned.length > 3) {
      phone = '';
      phone += '(' + cleaned.slice(0, 3) + ') ';
      phone += cleaned.slice(3, 6);
    }

    if (cleaned.length > 6) {
      phone += '-' + cleaned.slice(6, 10);
    }

    return phone;
  }

  const renderSelect = (key, base, isInvalid) => {
    const schema = get(formSchema, key);
    let optionsList = schema.options;
    const required = schema.required;

    const optionsMap = optionsList.map(option => typeof option === 'object' ? { label: option.label || option.value, value: option.value || option.label } : { label: option, value: option });
    const selectedValue = optionsMap.find(option => option.value === get(base, key));

    return <AdminSelect id={key} label={required ? `${schema.label} *` : schema.label} options={optionsMap}
      value={selectedValue} onChange={selected => updateField(selected.value, base, key)}
      propStyles={isInvalid ? styles.invalidSelect : styles.select} placeholder={schema.placeholder}
      isInvalid={isInvalid} validationMessage={intl.formatMessage({ id: 'AdminInput.validationMessage' }, { label: schema.label })} />;
  }

  const renderTagSelector = (key, base, isInvalid) => {
    const schema = get(formSchema, key);
    let optionsList = schema.options;

    const optionsMap = optionsList.map(option => typeof option === 'object' ? { label: option.label || option.value, value: option.value || option.label } : { label: option, value: option });

    return <TagSelector
      isInvalid={isInvalid}
      options={optionsMap}
      values={vendorRegistration.businessCategories || []}
      setValues={(values) => { updateField(values, vendorRegistration, key); setTimeout(() => saveVendorRegistration({ key: key }), 200); }}
      label={schema.label} height={'200px'}
    />
  }

  const renderCheckbox = (key, base, isInvalid) => {
    const schema = get(formSchema, key);
    const value = get(base, key);
    const required = schema.required;

    return (
      <AdminInput
        id={key}
        label=""
        readOnly={false}
        customstyle={styles.checkbox}
        validationmessage={schema.validationLabel}
        isInvalid={isInvalid}
        removeMargins={true}
        justifyContent={false}
      >
        <AdminCheckbox
          id={key}
          label={required ? `${schema.label} *` : schema.label}
          checked={value}
          onChange={e => updateField(e.target.checked, base, key)}
          readOnly={false}
          alignLabel={'left'}
          customstyle={styles.checkbox}
          removeMargins={true}
          justifyContent={false}
        />
      </AdminInput>
    );
  }

  const renderInput = (key, base = vendorRegistration, customstyle) => {
    const schema = get(formSchema, key); // retrieve the schema values that defines the input
    const value = get(vendorRegistration, key); // retreive the value from the space profile record
    const hasError = Array.isArray(errors) && !!errors.find(_ => _.key === key);
    const required = schema.required;

    if (schema.type === 'select') return renderSelect(key, base, hasError);
    if (schema.type === 'tagSelect') return renderTagSelector(key, base, hasError);
    if (schema.type === 'checkbox') return renderCheckbox(key, base, hasError);

    return (
      <AdminInput
        id={key}
        label={required ? `${schema.label} *` : schema.label}
        placeholder={schema.placeholder}
        value={value}
        validationmessage={intl.formatMessage({ id: 'AdminInput.validationMessage' }, { label: schema.label })}
        onChange={e => updateField(e.target.value, base, key, e)}
        isInvalid={hasError}
        type={schema.type}
        required={required}
        onBlur={() => saveVendorRegistration({ key: key, cacheSave: true })}
        customstyle={customstyle || "my-sm-4"}
      />
    );
  }

  const updateField = (value, base, field) => {
    if (field === 'phone') {
      value = formatPhoneNumber(value)
    }
    base[field] = value;
    setVendorRegistration({ ...vendorRegistration });
  }

  const saveVendorRegistration = async ({ key = '', cacheSave, publish }) => {
    setValidatePassword(true);
    if (!cacheSave) {
      const errors = validateErrors(vendorRegistration);
      setErrors(errors);
      if (errors.length !== 0) return false;
    } else {
      setErrors(errors.filter(_ => _.key !== key));
      setPreviousStepErrors(previousStepErrors.filter(_ => _.key !== key));
    }
    try {
      setSaving(true);
      setServerErrors([]);

      let result;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      let formData = new FormData();
      if (!publish) {
        delete vendorRegistration?.password
      }
      formData.append('token', registrationToken);
      formData.append('state', JSON.stringify({ ...vendorRegistration, logo: !logo.length ? '' : vendorRegistration.logo }))

      if (publish) {
        result = await axios.post(`/redeem-vendor-invitation`, formData, config);
        if (result.status === 200) return true;
      } else if (cacheSave) {
        //Saving to cache registration progress (onBlur)
        result = await axios.put(`/vendor-invites-progress/${vendorInvite.company}/${vendorInvite.email}`, formData, config);
      } else {
        //Saving to cache registration progress with logo save (nextHandler)
        if (logo.length && (typeof logo[0] !== 'string')) formData.append('logo', logo[0]);
        result = await axios.put(`/vendor-invites-progress/${vendorInvite.company}/${vendorInvite.email}`, formData, config);
      }

      if (result.status === 200) {
        result = get(result, 'data.record', [{}])[0];
        setVendorInvite(result);
        setVendorRegistration(result.state);
        let logo = get(result, 'state.logo.standard') || get(result, 'state.logo.original');
        setLogo(logo ? [logo] : []);
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setServerErrors([`${t('error.sendInvite')} (${e.response.data.errors})`]);
      } else {
        setServerErrors([(e && e.message) || t('error.server')]);
      }
      return false;
    }
    finally {
      setSaving(false);
    }

    return true;
  }

  const Description = ({ variant, icon, subtitle = '', title = '', details = '' }) => {
    if (variant === "secondary") {
      return (
        <div>
          <div className={styles.secondarySubtitle}>{subtitle}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.secondaryDetails}>{details}</div>
        </div>
      )
    } else {
      return (
        <div>
          <div className={styles.icon}>{icon && <img src={icon} alt="congratulations" />}</div>
          <div className={styles.mainTitle}>{title}</div>
          <div className={styles.details}>{details}</div>
        </div>
      )
    }
  }

  const validateErrors = (data) => {
    const validate = (key, errors) => {
      const schema = formSchema[key];
      const value = data[key];
      let step = 0;

      for (let i = 1; i < stepFormKeys.length; i++) {
        if (stepFormKeys[i].includes(key)) {
          step = i;
        }
      }

      if (schema.type === 'boolean') return;

      if (key === 'password' && !validPassword) {
        errors.push({ key: key, step, message: intl.formatMessage({ id: 'error.valid' }, { field: schema.label }) })
      }
      // determine if the field is required based on field settings vs the schema
      const isRequired = schema.required;
      const isValid = value && schema.validation ? String(value).match(schema.validation) : true;

      if ((((!value && value !== 0) || (Array.isArray(value) && !value.length)) && isRequired) || (value && !isValid)) {
        errors.push({ key: key, step, message: intl.formatMessage({ id: 'error.required' }, { field: schema.label }) })
      }
    }

    const errors = [];
    let previousErrors = [];

    let previousStepsFormKeys = [];
    for (let i = 0; i < step; i++) {
      previousStepsFormKeys = previousStepsFormKeys.concat(stepFormKeys[i]);
    }

    stepFormKeys[step].forEach(key => {
      validate(key, errors);
    });

    previousStepsFormKeys.forEach(key => {
      validate(key, previousErrors);
    })

    setPreviousStepErrors(previousErrors);

    return [...errors, ...previousErrors];
  }

  const nextStepHandler = async ({ publish }) => {
    let successfulSave = await saveVendorRegistration({ cacheSave: false, publish: publish });
    if (!successfulSave) return;
    setValidPassword(false);
    setStep((prevStep) => prevStep === stepForms.length + 1 ? prevStep : prevStep + 1);
  }

  const stepDetails = [
    <Description icon={Celebrate} title={t('VendorRegistration.getStarted.title')} details={t('VendorRegistration.getStarted.details')} />,
    <Description title={t('VendorRegistration.tellUsMore.title')} details={t('VendorRegistration.tellUsMore.details')} subtitle={intl.formatMessage({ id: 'VendorRegistration.step' }, { step: 1 }).toUpperCase()} variant="secondary" />,
    // <Description title={'How far are you will to travel?'} details={'This determines your service radius for new and existing customers needing your expertise.'} subtitle={'STEP 2'} variant="secondary" />,
    <Description title={t('VendorRegistration.businessType.title')} details={t('VendorRegistration.businessType.details')} subtitle={intl.formatMessage({ id: 'VendorRegistration.step' }, { step: 2 }).toUpperCase()} variant="secondary" />,
    <Description title={t('VendorRegistration.logo.title')} details={t('VendorRegistration.logo.details')} subtitle={intl.formatMessage({ id: 'VendorRegistration.step' }, { step: 3 }).toUpperCase()} variant="secondary" />,
    <Description title={t('VendorRegistration.password.title')} details={t('VendorRegistration.password.details')} subtitle={intl.formatMessage({ id: 'VendorRegistration.step' }, { step: 4 }).toUpperCase()} variant="secondary" />,
    <Description icon={CelebrateFace} title={t('VendorRegistration.finish.title')} details={t('VendorRegistration.finish.details')} />
  ]

  const stepForms = [
    <Step0 vendorRegistration={vendorRegistration} renderInput={renderInput} smallMobileView={smallMobileView} />,
    <Step1 vendorRegistration={vendorRegistration} renderInput={renderInput} smallMobileView={smallMobileView} />,
    // <Step2 vendorRegistration={vendorRegistration} renderInput={renderInput}/>,
    <Step3 vendorRegistration={vendorRegistration} renderInput={renderInput} />,
    <Step4 renderInput={renderInput} setLogo={setLogo} logo={logo} />,
    <Step5 vendorRegistration={vendorRegistration} updateField={updateField} valid={validPassword} setValidPassword={setValidPassword} mobileView={mobileView} validate={validatePassword} />
  ]

  const stepTooltips = [
    { showTooltip: false },
    { showTooltip: true, label: t('VendorRegistration.toolTip.label.step1'), details: t('VendorRegistration.toolTip.details.step1') },
    { showTooltip: false },
    { showTooltip: false },
    { showTooltip: false },
    { showTooltip: false }
  ]

  let alerts = previousStepErrors.length ?
    <Alert variant="danger" style={{ paddingBottom: '0px' }} className={styles.alerts}>
      <div>{t('VendorRegistration.alert.previousSteps')}</div>
      <ul>
        {previousStepErrors.map((error, i) => <li key={'' + error.id + i}>{`${intl.formatMessage({ id: 'VendorRegistration.step' }, { step: error.step })} - ${error.message}`}</li>)}
      </ul>
    </Alert> : null;

  let serverAlerts = serverErrors.length ?
    <Alert variant="danger" style={{ paddingBottom: '0px' }} className={styles.alerts}>
      <div>{t('VendorRegistration.alert.registration')}</div>
      <ul>
        {serverErrors.map((error, i) => <li key={'' + error + i}>{error}</li>)}
      </ul>
    </Alert> : null;

  let skippable = skippableSteps.find(_ => _.step === step);
  let skip = false;
  if (skippable) {
    skip = skippable.condition();
  }

  if (loading) return <Spinner />;

  return (
    <>
      <div className={styles.vendorRegistration}>
        <div className={styles.companyLogo}><img src={IDPlansLogo} alt="company logo" /></div>
        {step === 0 &&
          <div className={styles.signUpMessage}>
            <div className={styles.title}>{t('VendorRegistration.mainTitle')}</div>
            <div className={styles.details}>{t('VendorRegistration.mainDetails')}</div>
          </div>
        }
        {!mobileView && validInvite && <Tooltip
          update={step}
          target={tooltipRef}
          details={stepTooltips[step].details}
          label={stepTooltips[step].label}
          show={stepTooltips[step].showTooltip} />}
        <div ref={tooltipRef} className={styles.form} style={mobileView ? { width: '100vw' } : {}}>
          <div className={styles.fields} style={smallMobileView ? { width: '90vw' } : mobileView ? { width: '80vw' } : {}}>
            {validInvite ?
              <>
                {stepDetails[step]}
                {alerts}
                {serverAlerts}
                {stepForms[step]}
                {step === stepForms.length && <Button onClick={() => navigate('/')} style={{ width: '50%', margin: '15px 0' }}>{t('ui.login')}</Button>}
              </>
              :
              <>
                <div className={styles.title}>{t('VendorRegistration.noLongerActive')}</div>
                {serverAlerts}
              </>
            }
          </div>
          {step !== stepForms.length && validInvite &&
            <MultiStepNavigator
              totalSteps={stepForms.length}
              currentStep={step}
              backHandler={() => setStep((prevStep) => prevStep === 0 ? prevStep : prevStep - 1)}
              nextHandler={() => nextStepHandler({ publish: step === stepForms.length - 1 })}
              customNextLabel={skip ? t('ui.skip') : step === stepForms.length - 1 ? t('ui.publish') : null}
              hideBack={step === 0}
            />}
        </div>
      </div>

    </>
  )
}

export default VendorRegistration;