import React from 'react'
import Back from '../../assets/images/back'
import styles from './PropertiesBackButton.module.css'
import { INSPECT_FILTER_TYPE } from '../../utils/constants'
import PropTypes from 'prop-types'

/**
 * This button is resposible for resetting the property filter and going back to properties tab
 * @param {(guid: string) => void} setPropertySelected sets guid of selected company so we can filter on this value
 * @param {(filter: string) => void} setInspectFilter sets the filter type (assigned, all, etc.)
 * @param {(s: string) => string} t translator
 * @returns {any} Back Button
 */
const PropertiesBackButton = ({
  setPropertySelected, 
  setInspectFilter,
  t
}) => (
  <div
    className={styles.propertiesBackButton}
    onClick={() => {
      setPropertySelected(null)
      setInspectFilter(INSPECT_FILTER_TYPE.PROPERTIES)
    }}
  >
    <Back />{t('WorkOrders.properties.goBack')}
  </div>
)

PropertiesBackButton.propTypes = {
  setPropertySelected: PropTypes.func, 
  setInspectFilter: PropTypes.func,
  t: PropTypes.func
}

export default PropertiesBackButton