import React, { useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import TitleDescription from './../TitleDescription/TitleDescription';
import CircleTitle from './../CircleTitle/CircleTitle';
import { AdminInput, AdminSelect, AdminCheckbox } from '@project/components';
import { RootContext } from '../../hoc/RootContext/RootContext';
import axiosInstance from '../../utils/axios';
import NumberFormat from 'react-number-format';
import { USER_TITLES } from '../../constants/index';
import { useIntl } from 'react-intl';
import Title from '../Title/Title';
import PageContentContainer from '../PageContentContainer/PageContentContainer';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import styles from './Settings.module.css';
import permissions from './../../containers/Dashboard/permissions'
import { get } from '../../utils/utils'

const Settings = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});
  
  const { currentUser, getUser, setShowToast } = React.useContext(RootContext);

  const uploadRef = useRef(null);
  const [errors, setErrors] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [formState, setFormState] = useState({
    fname: currentUser.fname,
    lname: currentUser.lname,
    phone: currentUser.phone,
    title: currentUser.title
  });
  const [disableQc, setDisableQc] = useState(currentUser.qcDisabled ?? false)


  // some simple validation for now
  const isValid = formState.fname && formState.lname;

  const saveUserInfo = async (message, photoUpload) => {
    if (!isValid) return;
    if (formState.phone) formState.phone = formState.phone.replace(/[^0-9]*/g, '');

    const data = new FormData();
    if (photoUpload) data.append('profilepicture', photoUpload);
    Object.keys(formState).forEach(key => data.append(key, formState[key]));
    data.append("qcDisabled", disableQc);

    setIsSaving(true);
    try {
      let result = await axiosInstance.put(`/users/${currentUser.username}`, data);
      if (result.status === 200) {
        getUser();
        setShowToast({ show: true, message: message ? message : t('Settings.saveSuccessful') });
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('Settings.error.updates')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onFormChange = (key, value) => {
    setFormState({ ...formState, [key]: value });
  }

  const onProfileImageUpload = e => {
    saveUserInfo(t('Settings.profileImageUploaded'), e.target.files[0]);
  };

  const onProfileImageDelete = async () => {
    setIsSaving(true);
    try {
      let result = await axiosInstance.delete(`/users/${currentUser.username}/profile_picture`);
      if (result.status === 200) {
        getUser();
        setShowToast({ show: true, message: t('Settings.profileImageDeleted') });
      }
    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('Settings.profileImageDeleted.error')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const isUserAVendor = () => {
    return permissions.myProperties.includes(get(currentUser, 'type'))
  }

  const initials = currentUser.fname && currentUser.lname ? currentUser.fname.substring(0, 1) + currentUser.lname.substring(0, 1) : currentUser.username.substring(0, 1);

  return (
    <PageContentContainer>
      <Title title={t('Settings.header')} button={{click: () => saveUserInfo(), label: t('ui.save'), disabled: isSaving || !isValid}} />

      {errors ? <Alert key={errors} variant='danger' className="mt-3">
        {Array.isArray(errors) ? errors.map((error, i) => <div key={`error${i}`}>{error.message}</div>) : <>{t('error')}: {errors}</>}
      </Alert> : null}
      <TwoColumnContent>
        <LeftColumn>
          <TitleDescription title={t('Settings.mainTitle')} description={t('Settings.description')} />
        </LeftColumn>
        <RightColumn padded={false} border={false}>
          <div className={`shadow-sm bg-white border ${styles.profile}`}>
            <div className="d-flex align-items-center py-3 px-1 ">
              <div className={`${styles.profilePicture} textLink`}>
                {currentUser.profilePicture ? <img alt="profile" src={`${currentUser.profilePicture}?${Math.random()}`} className="d-inline-block rounded-circle mr-3 bglink font14 circleTitle p-0" /> : <CircleTitle title={initials.toUpperCase()} />}
              </div>
              <Button variant="light" className={'rounded-0 border mr-3 '} onClick={() => uploadRef.current.click()} disabled={isSaving}>
                {t('Settings.uploadPhoto')}
              </Button>
              <input ref={uploadRef} type="file" name="file" onChange={onProfileImageUpload} className='d-none' accept=".jpg, .jpeg, .gif, .png" />
              <Button variant="light" className={'rounded-0 border'} onClick={onProfileImageDelete} disabled={isSaving || !currentUser.profilePicture}>
                {t('Settings.removePhoto')}
              </Button>
            </div>
            <div className="border-bottom w-100"></div>
            <Form>
              <div className="d-flex pt-3">
                <div className="col-sm-6">
                  <AdminInput label={t('Settings.firstName')} type="text" value={formState.fname} isInvalid={!formState.fname} onChange={e => onFormChange('fname', e.target.value)} />
                </div>
                <div className="col-sm-6">
                  <AdminInput label={t('Settings.lastName')} required={true} type="text" value={formState.lname} isInvalid={!formState.lname} onChange={e => onFormChange('lname', e.target.value)} />
                </div>
              </div>
              <div className={`${isUserAVendor() ? 'd-none' : 'd-flex pt-3'}`}>
                <div className="col-sm-12">
                  <AdminSelect label={t('Settings.title')} type="text" value={USER_TITLES.find(_ => _.value === formState.title)} options={USER_TITLES} onChange={e => onFormChange('title', e.value)} />
                </div>
              </div>
              <div className="d-flex pt-3 pb-4">
                <div className="col-sm-6">
                  <AdminInput label={t('Settings.email')} controlCLass='d-none'>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="textLink">{currentUser.email}</span>
                      {/* <span className="textLink">Change</span> */}
                    </div>
                  </AdminInput>
                </div>
                <div className="col-sm-6">
                  <AdminInput label={t('Settings.phone')}>
                    <NumberFormat value={formState.phone} format="(###) ###-####" mask="_" onChange={e => onFormChange('phone', e.target.value)} className="form-control" />
                  </AdminInput>
                </div>
              </div>
            </Form>
          </div>
        </RightColumn>
      </TwoColumnContent>

      {currentUser.company === 'ID Plans' ?
        <TwoColumnContent>
          <LeftColumn>
            <TitleDescription title={t('Settings.productTitle')} />
          </LeftColumn>
          <RightColumn>
            <h5>{t('Settings.disableQc.title')}</h5>
            <AdminCheckbox
              id={'disableQc'}
              label={t('Settings.disableQc.description')}
              onChange={(e) => setDisableQc(e.currentTarget.checked)}
              checked={disableQc}
              customstyle={styles.checkbox}
              removeMargins={true}
            />
          </RightColumn>
        </TwoColumnContent> :
        null
      }
    </PageContentContainer>
  );
};

export default Settings;
