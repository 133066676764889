import { createIntl, createIntlCache } from 'react-intl';
import English from '../lang/en.js';
import Spanish from '../lang/es.js';

const cache = createIntlCache()

let locale = navigator.languages.length && navigator.languages[navigator.languages.length - 1];
let lang;
locale = "en";

if (locale === 'en') {
  lang = English;
} else {
  lang = Spanish;
}

const intl = createIntl({locale: locale, messages: lang}, cache);
const formatMessage = intl.formatMessage;

export {
  formatMessage,
  intl
};