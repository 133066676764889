import React, { useEffect, useState } from 'react';
import { AdminSelect } from '@project/components';
import { useTranslations } from '../../utils/hooks';
import axios from '../../utils/axios';

function SpaceSelect({ selectedCompany, selectedProperty, selectedSpace, setSelectedSpace, selectClass, children, filterActive = false }) {
  const [t] = useTranslations();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      setSelectedSpace(null);
      setProperties([]);
      if (!selectedProperty) return;
      setIsLoading(true);
      const result = await axios.get(`/public/properties/spaces/${encodeURIComponent(selectedCompany.value)}/${encodeURIComponent(selectedProperty.value)}?hasSpaceNumber=true`);
      const spaceOptions = result.data.records
        .map(({ spaceNumber, sqFootage }) => ({
          value: spaceNumber,
          label: spaceNumber,
          sqFootage
        }));
      setIsLoading(false);
      setProperties(spaceOptions);
    }
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty, setSelectedSpace]);

  return (
    <AdminSelect label={t('term.unit')}
      defaultValue=""
      value={selectedSpace}
      onChange={setSelectedSpace}
      options={properties}
      placeholder={isLoading ? `${t('ui.loading')}...` : t('SpaceSelect.select')}
      isLoading={isLoading}
      loadingMessage={t('ui.loading')}
      noOptionsMessage={t('ui.noOptions')}
    />
  )
}

export default SpaceSelect;