import React, {
  useMemo
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import AdminModal from '../AdminModal/AdminModal';
import ImageIcon from '../assets/images/image';
import VideoIcon from '../assets/images/video-icon';
import MultipleMediaUpload from '../MultipleMediaUpload/MultipleMediaUpload';
import {
  UPLOADER_TYPE,
} from './constants';
import styles from './MediaUploadButton.module.css';
import { useCallback } from 'react'
import { useMediaUploadButtonActions } from './useMediaUploadButtonHooks';
import { getDefaultFileUrlForModal } from '../utils/image';


const MediaUploadButton = ({
  axiosInstance,
  axiosCancelTokenFactory,
  className,
  deleteRoute,
  media,
  onDelete,
  onUpload,
  onUpdate,
  ownerId,
  ownerType,
  readOnly,
  title,
  type,
  uploadRoute,
  rotateRoute,
  extraPayloadData,
  formatResponse,
  canRotate
}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const {
    errorMessage,
    setFileToDelete,
    setFileToRotate,
    setFilesToUpload,
    isUploading,
    isDeleting,
    isRotating,
    isModalOpen,
    setIsModalOpen,
    mediaCache,
  } = useMediaUploadButtonActions({
    axiosInstance, axiosCancelTokenFactory, rotateRoute, deleteRoute, uploadRoute,
    media, ownerId, ownerType, type, extraPayloadData,
    onUpdate, onUpload, onDelete, formatResponse,
  })

  const isImageType = useMemo(() => type === UPLOADER_TYPE.IMAGE, [type])
  const isVideoType = useMemo(() => type === UPLOADER_TYPE.VIDEO, [type])


  /**
   * Callback for the modal component called when the user dismisses the modal.
   * In this context, it sets the `isModalOpen` state to `false` to close all instances of the modal
   * and trigger cleanups.
   */
  const handleAdminModalHide = () => setIsModalOpen(false)

  /**
   * Callback for the modal component called when the user clicks the "Save" button.
   * In this context, it sets the `isModalOpen` state to `false` to close all instances of the modal
   * and trigger cleanups, and also notifies the parent component implementing `MediaUploadButton` via the
   * `onUpdate` prop.
   */
  const handleAdminModalSave = useCallback(
    () => {
      setIsModalOpen(false)
      onUpdate(mediaCache)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaCache, onUpdate]
  )

  /**
   * Callback that sets the `isModalOpen` state to `true` to open the main modal and transition to the
   * "Upload" status.
   */
  const handleMainButtonClick = () => setIsModalOpen(true)

  /**
   * Callback for the modal component called when the user clicks the "Delete Photo/Video" button.
   * In this context, it will find the original file object that provided the URL for the modal
   * and mark is as "to delete".
   * Actual file deletion (API) is handled elsewhere by observing the `fileToDelete` state mutation.
   */
  const handleMediaDeletion = useCallback(
    (fileToDeleteUrl) => {
      const targetFile = mediaCache.find(cachedFile => {
        // For this file we are iterating, we should determine its type and get the default image URL
        // That way we can compare it with `fileToDeleteUrl` that comes from the modal.
        const cachedFileUrl = isImageType ? getDefaultFileUrlForModal(cachedFile) : cachedFile.url
        return cachedFileUrl === fileToDeleteUrl // If they are the same, this `cachedFile` is the one we should delete
      })

      setFileToDelete(targetFile)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaCache, isImageType]
  )

  /**
   * Callback for the modal component called when the user clicks the "Upload Photos/Videos" button.
   * It receives an array of raw files and mutates the `filesToUpload` state accordingly.
   * Actual file upload/saving (API) is handled elsewhere by observing the `filesToUpload` state mutation.
   */
  const handleMediaSelection = (files) => setFilesToUpload(files)

  /**
   * Callback for the modal component called when the user clicks the "Rotate Left" or "Rotate Right" buttons.
   */
  const handleMediaRotation = useCallback((fileToRotateUrl, rotationDegrees, rotationCallback) => {
    const targetFile = mediaCache.find(cachedFile => getDefaultFileUrlForModal(cachedFile) === fileToRotateUrl || cachedFile.url === fileToRotateUrl)
    setFileToRotate({ targetFile, rotationDegrees, rotationCallback })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaCache])

  const mediaUrlsFormattedForModal = useMemo(() => (
    mediaCache.map(media => {
      // Support for image thumbnails
      if (!isImageType) {
        return media?.url
      }

      return getDefaultFileUrlForModal(media)
    })
  ), [mediaCache, isImageType])

  const iconProps = useMemo(() => ({
    className: classNames(
      styles.ButtonIcon,
      {
        [styles.IconEmpty]: mediaCache.length === 0,
        [styles.IconFull]: mediaCache.length > 0
      }
    ),
    count: mediaCache.length
  }), [mediaCache.length]);

  const wrapperClasses = classNames(styles.ButtonContainer, className);

  return (
    <div className={wrapperClasses}>
      <button
        className={styles.Button}
        disabled={isModalOpen}
        onClick={handleMainButtonClick}
        data-testid="MultipleMediaUploadIcon"
      >
        {isImageType && <ImageIcon {...iconProps} height={32} />}
        {isVideoType && <VideoIcon {...iconProps} height={28} />}
      </button>
      <AdminModal
        title={title}
        show={isModalOpen}
        onHide={handleAdminModalHide}
        onSave={handleAdminModalSave}
        noSave={readOnly}
        isSaving={isUploading || isRotating || isDeleting}
      >
        <MultipleMediaUpload
          className={styles.MultipleMediaUpload}
          hideButtons={readOnly}
          isDeleting={isDeleting}
          isSaving={isUploading}
          isRotating={isRotating}
          mediaUrls={mediaUrlsFormattedForModal}
          onDeleteMedia={handleMediaDeletion}
          onSelectedMediaFiles={handleMediaSelection}
          readOnly={readOnly}
          type={type}
          canRotate={!readOnly && canRotate && mediaUrlsFormattedForModal.length > 0}
          onRotateMedia={handleMediaRotation}
          cachedMedia={mediaCache}
        />
        {!!errorMessage && (
          <div className={styles.ErrorWrapper}>
            <Alert variant="danger">{t('error')}: {errorMessage}</Alert>
          </div>
        )}
      </AdminModal>
    </div>
  );
};

MediaUploadButton.propTypes = {
  /**
   * Axios instance customized via `axios.create`.
   * Required to upload and delete files.
   */
  axiosInstance: PropTypes.func.isRequired,
  /** An axios cancel token. */
  axiosCancelTokenFactory: PropTypes.func.isRequired,
  /** API endpoint for media deletion. */
  deleteRoute: PropTypes.string.isRequired,
  /** Array of media objects that were previously uploaded, or empty array. */
  media: PropTypes.arrayOf(PropTypes.object),
  /** Called when a file is deleted. Receives the original file that was deleted. */
  onDelete: PropTypes.func,
  /** Called when the `media` array is updated, either via uploads or deletions. */
  onUpdate: PropTypes.func,
  /** Called when files have been uploaded. Received the data returned by the API. */
  onUpload: PropTypes.func,
  /** API `ownerId` parameter. */
  ownerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** API `ownerType` parameter. */
  ownerType: PropTypes.string,
  /**
   * Controls the read-only state of the component. If `true`, it will
   * show static data and disable uploads or deletions.
   */
  readOnly: PropTypes.bool,
  /** Modal title. */
  title: PropTypes.string.isRequired,
  /** Uploader type (Video or Image) */
  type: PropTypes.oneOf([UPLOADER_TYPE.IMAGE, UPLOADER_TYPE.VIDEO]).isRequired,
  /** Enable rotate image feature */
  canRotate: PropTypes.bool,
  /** API endpoint for media creation. */
  uploadRoute: PropTypes.string.isRequired,
  /** API endpoint for media rotation. */
  rotateRoute: PropTypes.string,
  /** Any extra stuff to be sent to the API via axios. */
  extraPayloadData: PropTypes.object,
  /** Formats response record when upload made. This aids in flexibility with different
   *  json responses
   */
  formatResponse: PropTypes.func
};

MediaUploadButton.defaultProps = {
  media: [],
  onDelete: () => { },
  onUpdate: () => { },
  onUpload: () => { },
  readOnly: false,
  canRotate: false,
};

export default MediaUploadButton;
