import React from 'react';
import styles from './Title.module.css';
import Back from '../../assets/images/back';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap'

const Title = ({title, backRoute, button, secondaryButton, customButton}) => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.header} d-flex justify-content-between align-items-center mt-2 mb-4`}>
      <div className="d-flex justify-content-between align-items-center">
        {backRoute &&
          <div className={`${styles.backButton} d-flex justify-content-center align-items-center`} onClick={() => navigate(backRoute)}><Back /></div>
        }
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className="d-flex flex-nowrap">
        {secondaryButton &&
          <Button className={styles.secondaryButton} onClick={secondaryButton.click} disabled={secondaryButton.disabled}>{secondaryButton.label}</Button>
        }
        {button &&
          <Button className={styles.button} onClick={button.click} disabled={button.disabled}>{button.label}</Button>
        }
        {customButton}
      </div>
    </div>
  );
};

export default Title;