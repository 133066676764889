/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@project/components';
import { Button } from 'react-bootstrap';
import DashboardCard from '../DashboardCard/DashboardCard';
import OrderProfile from '../OrderProfile/OrderProfile';
import styles from './SpaceProfileStatusTracker.module.css';
import { exportData, normalizeProfileStatus } from './../../utils/utils'
import {toast} from 'react-toastify'
import axios, { CancelToken } from '../../utils/axios';

//Keeping a global variable here as it's the only way to get cancel working reliably on requests where
//we want it to override a previous (pending) request. Note: CancelToken has been deprecated
let source

const SpaceProfileStatusTracker = ({
  currentUser,
  currentCompany,
  currentProperty,
  view: {smallMobileView, mobileView, largeView}
}) => {

  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});
  const [activeOrderModal, setActiveOrderModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [spaceProfiles, setSpaceProfiles] = useState([])
  const [pagination, setPagination] = useState({ page: 0 })
  const [queryParams, setQueryParams] = useState({});

  const {
    page,
    orderBy,
    orderAscending,
    search
  } = queryParams

  useEffect(() => {
    const fetchData = async () => {
      let cancelToken
      if (source) source.cancel()
      source = CancelToken.source()
      cancelToken = source.token

      try {
        setIsLoading(true)

        const params = new URLSearchParams();

        params.set('company', currentCompany)
        params.set('page', page + 1) //normalize page
        if (currentProperty) params.set('property', currentProperty)

        params.set('excludeStatuses[]', 'tear sheet')

        let formattedSort = orderBy ? `&sort=${orderAscending ? '' : '-'}${orderBy}` : ''
        let formattedSearch = search ? `&search=${search}` : ''

        const result = await axios.get(`/space-profiles?${params.toString()}${formattedSearch}${formattedSort}&parentOnly=true&limit=5`, { cancelToken });

        if (result?.data?.success) {
          setPagination({count: result?.headers?.['pagination-count']})
          setSpaceProfiles(result?.data?.data);
        } else {
          return Promise.reject();
        }
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }

    if (currentCompany) fetchData();

    return () => {
      setIsLoading(false);
      if (source) source.cancel();
    }
  }, [currentCompany, currentProperty, page, orderBy, orderAscending, search])


  let formattedSpaceProfiles = normalizeProfileStatus(spaceProfiles)

  const exportResults = async () => {
    const opts = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    }
    toast.warn(t('Export.profiles'), opts)

    try {
      const params = new URLSearchParams();

      params.set('company', currentCompany)
      if (currentProperty) params.set('property', currentProperty)
      if (search) params.set('search', search)

      let records = []

      const results = await axios.get(`/space-profiles?${params.toString()}&parentOnly=true`)
      records = normalizeProfileStatus(results.data.data)
      records = records.map(record => {
        return {
          [t('term.property')]: record.property,
          [t('term.space')]: record.address2,
          [t('SearchTable.status')]: record.status,
          [t('term.orderDate')]: record.crmOrderDate,
          [t('term.scheduledDate')]: record.crmScheduledDate,
          [t('term.dueDate')]: record.crmDueDate,
          [t('term.location')]: record.address1
        }
      })

      exportData(records, 'profiles')
      opts.autoClose = 1000
      toast.success(t('Export.complete'), opts)
    }
    catch (e) {
      toast.error(e.message, opts)
    }
  }

  return (
    <div>
      <DashboardCard
        header={ <div className={`${styles.titleContainer} pt-3 mb-2`}>
          <div className={`${styles.tableTitle}`}>{t('Dashboard.profilesHeader')}</div>
          <div className="d-inline-block">
            <Button className='d-inline-block mb-2 mr-2' onClick={() => exportResults()}>
              {t('Dashboard.export')}
            </Button>
            <Button className='d-inline-block mb-2' onClick={() => setActiveOrderModal(true)}>
              {t('OrderProfile.newOrderProfile')}
            </Button>
          </div>
        </div>
        }
      >
        <Table
          idField={'guid'}
          isLoading={isLoading}
          searchFilters={['property', 'address2', 'company']}
          sortFields={['property', 'address2', 'status', 'crmOrderDate', 'crmDueDate', 'crmScheduledDate', 'address1']}
          customStyle={styles.table}
          placeholder={t('Dashboard.spaceProfiles.placeholder')}
          fields={[
            {id: 'property', label: t('term.property'), type: 'details', options: {photo: { key: 'thumbnail', objectFit: 'cover' }, style: styles.propertyCell}},
            {id: 'address2', label: t('term.space'), type: 'details'},
            {id: 'status', label: t('SearchTable.status'), type: 'status', options: {noDot: true}, statusColors: [
              {condition: _ => _.status === 'published', color: {backgroundColor: 'rgba(55, 182, 91, 0.2)', color: '#202223'}},
              {condition: _ => _.status === 'unpublished', color: {backgroundColor: 'rgba(237, 185, 0, 0.2)', color: '#202223'}},
              {condition: _ => _.status === 'draft', color: {backgroundColor: 'rgba(255, 122, 0, 0.2)', color: '#202223'}},
              {condition: _ => _.status === 'New', color: {backgroundColor: 'rgba(255, 122, 0, 0.1)', color: '#202223'}},
              {condition: _ => _.status === 'Scheduled', color: {backgroundColor: 'rgba(255, 122, 0, 0.2)', color: '#202223'}},
              {condition: _ => _.status === 'Complete in Field', color: {backgroundColor: 'rgba(55, 182, 91, 0.2)', color: '#202223'}},
              {condition: _ => _.status === 'Delivered', color: {backgroundColor: 'rgba(255, 122, 0, 0.1)', color: '#202223'}},
              {condition: _ => _.status === 'Hold', color: {backgroundColor: 'rgba(109, 113, 117, 0.2)', color: '#202223'}},
              {condition: _ => _.status === 'Canceled', color: {backgroundColor: 'rgba(212, 30, 21, 0.2)', color: '#202223'}}
            ]},
            {
              id: 'crmOrderDate',
              label: t('term.orderDate'),
              type: 'datetime',
              incomingFormat: 'YYYY-MM-DD',
              format: 'l'
            },
            {
              id: 'crmScheduledDate',
              label: t('term.scheduledDate'),
              type: 'datetime',
              incomingFormat: 'YYYY-MM-DD',
              format: 'l'
            },
            {
              id: 'crmDueDate',
              label: t('term.dueDate'),
              type: 'datetime',
              incomingFormat: 'YYYY-MM-DD',
              format: 'l'
            },
            {id: 'address1', label: t('term.location'), type: 'details', options: {concatValues: {keys: ['city', 'state'], separator: ', '}}},
            {
              id: '360link',
              buttonLabel: currentUser && currentUser.is_id360_admin ? t('ui.edit') : t('ui.view'),
              type: 'button',
              click: () => {},
              options: {
                buttonType: 'underline',
                color: '#4068cf',
                link: item => currentUser && currentUser.is_id360_admin ? `${process.env.REACT_APP_ID360_HOST}/admin/profile/edit/${item.guid}` : `${process.env.REACT_APP_ID360_HOST}/profile/${item.guid}`
              },
              conditionalSchemas: [{
                condition: _ => currentUser && !currentUser.is_id360_admin && _.status !== 'published', schema: {hide: true}
              }]
            }
          ]}
          itemsPerPage={5}
          items={formattedSpaceProfiles}
          showFields={(smallMobileView && ['property', 'address2', '360link']) || (!largeView && ['property', 'address2', 'status', '360link'])}
          customWidths={(smallMobileView && ['50%', '30%', '20%']) || (!largeView && ['50%', '20%', '25%', '15%']) || ['25%', '10%', '13%', '15%', '17%', '15%']}
          detailsDropdown={(mobileView && ['status', 'crmOrderDate', 'crmDueDate', 'address1']) || (!largeView && ['crmOrderDate', 'crmDueDate', 'address1'])}
          itemLabels={{
            status: {
              'draft': t('term.draft'),
              'published': t('term.published'),
              'unpublished': t('term.unpublished'),
              'New': t('crm.new'),
              'Scheduled': t('crm.scheduled'),
              'Complete in Field': t('crm.complete'),
              'Hold': t('crm.hold'),
              'Canceled': t('crm.canceled')
            }
          }}
          rowPlaceholderHeight={'71px'}
          customPaginate={true}
          customPage={queryParams.page}
          customPaginationTotalSize={pagination.count}
          setCustomSearchFilter={(newSearch) => setQueryParams(prevParams => {return {...prevParams, search: newSearch}})}
          setCustomSort={(newSort) => setQueryParams(prevParams => {return {...prevParams, orderBy: newSort.key, orderAscending: !newSort.descending}})}
          setCustomPage={(newPage) => setQueryParams(prevParams => {return {...prevParams, page: newPage}})}
        />
      </DashboardCard>
      <OrderProfile
        currentUser={currentUser}
        activeOrderModal={activeOrderModal}
        setActiveOrderModal={setActiveOrderModal}
      />
    </div>
  )
}

SpaceProfileStatusTracker.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentCompany: PropTypes.string,
  currentProperty: PropTypes.string,
  view: PropTypes.object.isRequired
};

export default SpaceProfileStatusTracker;