import {
  createSearchParams,
  generatePath,
  useNavigate
} from 'react-router-dom'

/**
 * Custom hook that returns a decorated `react-router-dom`'s `navigate()`
 * function that allows navigation with query params.
 *
 * @example
 * ```js
 * const navigateWithQueryParams = useNavigateWithParams()
 * // ...
 * navigateWithQueryParams('/search', {
 *   sort: ['name', 'price']
 * })
 * // -> Takes you to /search?sort=name,price
 * ```
 */
const useNavigateWithParams = () => {
  const navigate = useNavigate()

  /**
   * Decorator that wraps the standard `react-router-dom`'s `navigate()`
   * and adds support for extra query paramters compliant with React Router DOM.
   *
   * @param {string} url Main URL path or route you'd use for `navigate`
   * @param {string | [string, string][] | Record<string, string | string[]> | URLSearchParams} params The query params
   *
   * @example
   * ```js
   * // Supports the default `URLSearchParams` signature.
   * navigateWithQueryParams('/search', [
   *   ['sort', 'name'],
   *   ['sort', 'price']
   * ]);
   * // -> Takes you to /search?sort=name,price
   *
   * // Also supports a more "readable" format
   * navigateWithQueryParams('/search', {
   *   sort: ['name', 'price']
   * });
   * // -> Takes you to /search?sort=name,price
   * ```
   */
  const navigateWithQueryParams = (url, params) => {
    const path = generatePath(':url?:queryString', {
      url,
      queryString: createSearchParams(params).toString()
    })
    navigate(path)
  }

  return navigateWithQueryParams
}

export default useNavigateWithParams
