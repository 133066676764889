import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Custom hook that returns a memoized version of the
 * browser's URLSearchParams.
 * @example
 * ```jsx
 * // Navigate to /something?name=FooBar
 * const query = useSearchParams()
 * // ...
 * <span>{query.get('name')}</span>
 * ```
 * @returns {URLSearchParams} Memoized URLSearchParams instance
 */
const useSearchParams = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default useSearchParams
