import React, { useCallback, useState } from 'react'
import { AdminModal } from '@project/components'
import { INSPECT_TYPE, ACL } from '../../../utils/constants'
import PropTypes from 'prop-types'

/**
 * This component handles the deletion of an item. This includes a modal for
 * confirming deletion.
 * @param {boolean} showDeleteItemModal show modal for confirming deletion
 * @param {(show: boolean) => void} setShowDeleteItemModal set show modal for confirming deletion
 * @param {string} deleteItem id of item to delete
 * @param {string[]} deletedItems ids of items that have been deleted
 * @param {string} type inspection or work-order
 * @param {{guid: string, slug: string}} currentCompany currently selected company
 * @param {(s: string) => string} t translator
 * @returns {any} Modal
 */
const DeleteItemConfirmationModal = ({
  showDeleteItemModal,
  setShowDeleteItemModal,
  itemId,
  setDeletedItemIds,
  type,
  currentCompany,
  t,
  axios,
  CancelToken,
  isCancel,
  toast,
  setError
}) => {
  const [isDeletingItem, setIsDeletingItem] = useState(false)

  const deleteItemHandler = useCallback((item) => {
    const deleteItem = async () => {
      const source = CancelToken.source()

      const route = {
        [INSPECT_TYPE.INSPECTION]: '/inspect/instances',
        [INSPECT_TYPE.WORK_ORDER]: '/work-orders'
      }
      const toastSuccessMessage = {
        [INSPECT_TYPE.INSPECTION]: t('Inspections.delete.success'),
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.delete.success')
      }
      const errorMessage = {
        [INSPECT_TYPE.INSPECTION]: t('Inspections.delete.error'),
        [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.delete.error')
      }

      try {
        setIsDeletingItem(true)
        const result = await axios.delete(`${route[type]}/${item.id}`, {
          cancelToken: source.token,
          headers: {
            [ACL.HTTP_HEADERS.IDP_COMPANY]: currentCompany?.name || currentCompany?.value
          }
        })

        if (result.success) {
          //toast delete successful
          toast(toastSuccessMessage[type])
          setDeletedItemIds(prevDeletedItemIds => [...prevDeletedItemIds, item.id])
        } else {
          setError(errorMessage[type])
        }
      } catch (e) {

        if (!isCancel(e)) {
          setError(errorMessage[type])
        }
      } finally {
        setIsDeletingItem(false)
        setShowDeleteItemModal(false)
      }
    }

    if (item?.id) deleteItem()
  }, [CancelToken, axios, currentCompany, isCancel, setDeletedItemIds, setError, setShowDeleteItemModal, t, toast, type])

  const modalTitle = {
    [INSPECT_TYPE.INSPECTION]: t('Inspections.deleteInspection'),
    [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.deleteWorkOrder')
  }
  const deleteButtonText = {
    [INSPECT_TYPE.INSPECTION]: t('Inspections.delete.modal.title'),
    [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.delete.modal.title')
  }

  const modalMessage = {
    [INSPECT_TYPE.INSPECTION]: t('Inspections.delete.modal.message'),
    [INSPECT_TYPE.WORK_ORDER]: t('WorkOrders.delete.modal.message')
  }

  return <AdminModal
    onSave={() => {}}
    deleteModeOverride={true}
    noSave={true}
    show={showDeleteItemModal}
    title={modalTitle[type]}
    isSaving={isDeletingItem}
    onHide={() => setShowDeleteItemModal(false)}
    onDelete={() => deleteItemHandler(itemId)}
    deleteButtonText={deleteButtonText[type]}>
    <div className='my-4'>
      <div className="mb-4">{modalMessage[type]}</div>
    </div>
  </AdminModal>
}

DeleteItemConfirmationModal.propTypes = {
  showDeleteItemModal: PropTypes.bool,
  setShowDeleteItemModal: PropTypes.func,
  deleteItem: PropTypes.object,
  deletedItems: PropTypes.arrayOf(PropTypes.number),
  setDeletedItems: PropTypes.func,
  type: PropTypes.string,
  currentCompany: PropTypes.object,
  t: PropTypes.func,
  axios: PropTypes.func,
  CancelToken: PropTypes.func,
  isCancel: PropTypes.func,
  toast: PropTypes.func,
  setError: PropTypes.func
}

export default DeleteItemConfirmationModal