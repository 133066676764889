export const UPLOADER_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video'
};

export const ACCEPTED_FILES = {
  [UPLOADER_TYPE.IMAGE]: '.jpg, .jpeg, .gif, .png, .svg, .heic', 
  [UPLOADER_TYPE.VIDEO]: 'video/*'
};

export const ROTATE_LEFT = -90;
export const ROTATE_RIGHT = 90;

export const FILE_TYPE_LABELS = {
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'image/heic': 'HEIC',
  'image/svg+xml': 'SVG',
};
