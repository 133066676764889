import React from 'react';
import styles from './Steps.module.css';
import COIUpload from '../coiUpload'
import { PasswordInput, inputValidationRules } from '@project/components';

export const Step0 = ({ vendorRegistration, renderInput, description }) => {
  return (
    <div>
      <div className={`d-flex justify-content-between ${styles.twoInput}`}>
        {renderInput('fname', vendorRegistration, styles.inputStyle)}
        {renderInput('lname', vendorRegistration, styles.inputStyle)}
      </div>
      <div className={`d-flex justify-content-between ${styles.twoInput}`}>
        {renderInput('phone', vendorRegistration, styles.inputStyle)}
        {renderInput('email', vendorRegistration, styles.inputStyle)}
      </div>
      <div>
        {description}
        {renderInput('businessCategories', vendorRegistration)}
      </div>
    </div>
  );
}

export const Step1 = ({ vendorRegistration, renderInput, smallMobileView }) => {
  return (
    <div>
      <div>
        {renderInput('company', vendorRegistration, styles.inputStyle)}
      </div>
      <div>
        {renderInput('companySite', vendorRegistration, styles.inputStyle)}
      </div>
      {smallMobileView ?
        <>
          <div>{renderInput('address1', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('city', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('postal', vendorRegistration, styles.inputStyle)}</div>
          <div>{renderInput('state', vendorRegistration, styles.inputStyle)}</div>
        </>
        :
        <>
          <div className={`d-flex justify-content-between ${styles.twoInput}`}>
            {renderInput('address1', vendorRegistration, styles.inputStyle)}
            {renderInput('city', vendorRegistration, styles.inputStyle)}
          </div>
          <div className={`d-flex justify-content-between ${styles.twoInput}`}>
            {renderInput('postal', vendorRegistration, styles.inputStyle)}
            {renderInput('state', vendorRegistration, styles.inputStyle)}
          </div>
        </>
      }

    </div>
  );
}

// this should be the COI file
export const Step2 = ({ coiFile, setCoiFile, renderInput, vendorRegistration, vendorInvite, registrationToken }) => {
  return (
    <div>
      <COIUpload setCoiFile={setCoiFile} coiFile={coiFile} vendorInvite={vendorInvite} registrationToken={registrationToken} />
      <div>
        {renderInput('insuranceStartDate', vendorRegistration, styles.inputStyle)}
      </div>
      <div>
        {renderInput('insuranceEndDate', vendorRegistration, styles.inputStyle)}
      </div>
    </div>
  );
}

export const Step3 = ({ vendorRegistration, updateField, validPassword, setValidPassword, mobileView, validate }) => {
  return (
    <div>
      <div>
        <PasswordInput
          create={true}
          strength={true}
          validate={validate}
          password={vendorRegistration.password || ''}
          setPassword={(value) => updateField(value, vendorRegistration, 'password')}
          setValidPassword={setValidPassword}
          validPassword={validPassword}
          rules={[
            inputValidationRules.minLengthRule(12),
            inputValidationRules.hasNumberRule(),
            inputValidationRules.hasUpperCaseRule(),
            inputValidationRules.hasLowerCaseRule()
          ]}
          tooltipPlacement={mobileView ? 'bottom' : 'left'}
        />
      </div>
    </div>
  );
}