import { useEffect, useRef } from 'react'

/**
 * Custom hook that takes a function and runs it as an effect
 * but only once. Useful to setup states as soon as the app
 * loads and then forget.
 * We can pass a simple function or a `useCallback` memoized function
 * with params and deps.
 *
 * @param {() => void} effect Function to be run only once
 *
 * @example
 * ```js
 * // Simple callback
 * useEffectOnce(() => console.log('hello'))
 *
 * // Memoized callback with some deps
 * import { useCallback } from 'react'
 *
 * const memoizedSideEffect = useCallback(() => {
 *   doSomething(dep1, dep2)
 * }, [dep1, dep2])
 * // Above fn will run once even if `dep1` or `dep2` keep changing
 * useEffectOnce(memoizedSideEffect)
 * ```
 */
const useEffectOnce = (effect) => {
  const initialRef = useRef(true)

  useEffect(() => {
    if (!initialRef.current) {
      return
    }

    initialRef.current = false
    effect()
  }, [effect])
}

export default useEffectOnce
