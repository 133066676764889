import React from 'react';

const NotificationsBold = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7655 7.4345L8.56563 0.234588C8.25284 -0.0781959 7.74724 -0.0781959 7.43445 0.234588L0.234542 7.4345C0.00575734 7.66328 -0.0630543 8.0073 0.0609567 8.30648C0.184143 8.60568 0.476939 8.80008 0.800148 8.80008H1.60013V16H14.4V8.80008H15.1999C15.5231 8.80008 15.8159 8.60568 15.9391 8.30648C16.0631 8.0073 15.9935 7.66328 15.7655 7.4345ZM12 10.3273H10.8064C10.7416 10.5761 10.644 10.8096 10.52 11.0288L11.2728 11.7816L10.1825 12.872L9.42887 12.1192C9.21047 12.244 8.97767 12.3408 8.72808 12.4056V13.6H7.27369V12.4064C7.0241 12.3408 6.79051 12.244 6.5729 12.12L5.81931 12.8728L4.72894 11.7825L5.48174 11.0297C5.35773 10.8113 5.26016 10.5777 5.19536 10.3281H4.00017V8.87369H5.19374C5.25854 8.6241 5.35615 8.39051 5.48013 8.1729L4.72732 7.41931L5.8177 6.32894L6.57129 7.08174C6.78969 6.95694 7.02248 6.86016 7.27208 6.79536V5.60017H8.72646V6.79374C8.97606 6.85854 9.20964 6.95533 9.42725 7.08013L10.1808 6.32733L11.2712 7.4177L10.5184 8.17129C10.6424 8.38969 10.74 8.62327 10.8048 8.87208H12V10.3273Z" fill="currentColor" />
    </svg>
  );
}

export default NotificationsBold;