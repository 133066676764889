import { useEffect } from "react";
import { API_FILE_CATEGORY_MAP } from '../constants';

/** Upload handler */
export const useUpload = ({
  axiosInstance, axiosCancelTokenFactory, filesToUpload, mediaCache, onUpdate, onUpload, ownerId, ownerType, type, uploadRoute, extraPayloadData, formatResponse,
  setIsUploading, setErrorMessage, setFilesToUpload, setMediaCache
}) => {
  useEffect(() => {
    const source = axiosCancelTokenFactory.source();

    const uploadFiles = async () => {
      setIsUploading(true);
      setErrorMessage(null);

      const category = API_FILE_CATEGORY_MAP[type];
      const uploadPromises = filesToUpload.filter(
        // Previously uploaded files are included as strings.
        // We don't need those to upload new files, so they're excluded.
        file => typeof file !== 'string'
      ).map(file => {
        const data = new FormData();
        data.append('files', file);
        data.append('ownerId', ownerId);
        data.append('ownerType', ownerType);
        data.append('category', category);

        if (file.orientation) {
          data.append('orientation', file.orientation);
        }

        if (file.nativePlatform) {
          data.append('platform', file.nativePlatform);
        }

        for (const payloadKey in extraPayloadData) {
          data.append(payloadKey, extraPayloadData[payloadKey]);
        }

        return axiosInstance.post(uploadRoute, data, {
          cancelToken: source.token
        });
      });

      try {
        const results = await Promise.all(uploadPromises);
        setFilesToUpload(null);

        const uploadedFiles = results.map(fileUploadResult => {
          let record = (fileUploadResult.record || fileUploadResult.records || fileUploadResult.data.record || fileUploadResult.data.records).pop()
          if (formatResponse) record = formatResponse(record)
          return record
        });
        const updatedMedia = [...mediaCache, ...uploadedFiles];

        setMediaCache(updatedMedia);
        onUpdate(updatedMedia);
        onUpload(uploadedFiles);
      } catch (e) {
        setErrorMessage(e.message);
        setFilesToUpload(null);
      } finally {
        setIsUploading(false);
      }
    };

    if (filesToUpload) {
      uploadFiles();
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);
}
