import React from 'react';
import styles from './RightColumn.module.css';

const RightColumn = ({ children, padded = true, className, boxShadow = true, border = true, alignRight = false, noBackground = false }) => {

  let alignRightClass = `${alignRight ? 'text-right' : ''}`
  let classes = `col-12 col-lg-7 ${boxShadow ? styles.shadow : ''} ${border ? styles.border : ''} ${noBackground ? '' : 'bg-white'} p-3 ${alignRightClass}`;

  if (!padded) {
    classes = `col-12 col-lg-7 px-0 ${boxShadow ? styles.shadow : ''} ${border ? styles.border : ''} ${noBackground ? '' : 'bg-white'} ${alignRightClass}`;
  }

  return (
    <div className={`${styles.rightColumn} ${classes} ${className}`}>
      {children}
    </div>
  );
};

export default RightColumn;
