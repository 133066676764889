/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { AdminInput, AdminModal, Contact, ConfirmModal } from '@project/components'
import { useUsers, useContacts } from './hooks.js'
import ContactsList from './../../components/ContactsList/ContactsList'
import ToggleWithLabel from './../../components/ToggleWithLabel/ToggleWithLabel'
import PropertyImagesUpload from '../PropertyImagesUpload/PropertyImagesUpload'
import { useIntl } from 'react-intl'
import FileUpload from '../FileUpload/FileUpload'
import styles from './PropertyGeneralSettings.module.css'

const PropertyGeneralSettings = ({ propertySettings, setPropertySettings, onSavePropertySettings, isSaving, setErrors, isReadOnly = false }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [activeUserModal, setActiveUserModal] = useState(false)
  const [activeUploadModal, setActiveUploadModal] = useState(null)
  const [usersSearchFilter, setUsersSearchFilter] = useState('')
  const [isContactSaving, setIsContactSaving] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteContact, setDeleteContact] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const users = useUsers(propertySettings ? propertySettings.company : null)
  const contacts = useContacts(propertySettings, users, selectedUser, deleteContact, isContactSaving, confirmDelete, setSelectedUser, setDeleteContact, setIsContactSaving, setActiveUserModal)

  const imageExt = '.png, .svg, .jpg, .gif, .jpeg'

  const updateField = ({target: { type, checked, value }}, base, field) => {
    base[field] = type === 'checkbox' ? checked : value
    setPropertySettings({ ...propertySettings })
  }

  const activeUserModalHandler = (active) => {
    setActiveUserModal(active)
    setUsersSearchFilter('')
  }

  const deleteContactHandler = (_) => {
    setConfirmDelete(false)
    setDeleteContact(_)
    setShowConfirmModal(true)
  }

  const confirmDeleteHandler = () => {
    setConfirmDelete(true)
    setShowConfirmModal(false)
  }

  const searchUsers = (user) => {
    let searchString = `${user.fname} ${user.lname} ${user.username} ${user.title}`
    return !(searchString.toLowerCase().search(usersSearchFilter.toLowerCase()) === -1)
  }

  return (
    <>
      <Form key="field_visibility_form" className="is-common-form">
        <div className={`${styles.saveButton} row my-sm-4 d-flex align-items-center`}>
          <div className="font-lg col-sm-8" style={{ lineHeight: '60px' }}>{t('PropertyGeneralSettings.details')}</div>
          <div className="col-sm-4" align="right"><Button disabled={isSaving} onClick={() => onSavePropertySettings({ ...propertySettings })} >{t('PropertySettings.general.save')}</Button></div>
        </div>
        <h4 className='is-main-heading pb-3'>{t('PropertySettingsTabs.general.title')}</h4>
        <div className='row my-sm-4'>
          <div className='col-sm-6'>
            <AdminInput
              label={t('PropertyGeneralSettings.displayName')}
              value={propertySettings.displayName}
              type={'text'}
              onChange={(e) => updateField(e, propertySettings, 'displayName')} />
          </div>
        </div>
        <ToggleWithLabel 
          titleLabel={t('PropertyGeneralSettings.multipleBuildings.label')} 
          titleDescriptions={[t('PropertyGeneralSettings.multipleBuildings.desc')]} 
          ids={['multipleBuildings']}
          getObject={propertySettings}
          getKeys={['multipleBuildings']}
          updateFunction={updateField} 
        />
        <div className="col-12 px-0 mt-5">
          <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid #dee2e6', paddingBottom: '0.5em' }}>
            <h4 className='mb-0 font-weight-light'>{t('PropertyGeneralSettings.contacts')}</h4><Button className='mb-2' onClick={() => setActiveUserModal(true)}>{t('PropertyGeneralSettings.contacts.add')}</Button>
          </div>
          <div className={`${styles.tableContainer} my-sm-4`}>
            <ContactsList
              contacts={contacts}
              onClick={deleteContactHandler}
              deletable={true} />
          </div>
        </div>
        <h4 className='mb-2 mt-3 mt-sm-5 font-weight-light' style={{ borderBottom: '1px solid #dee2e6', paddingBottom: '0.5em' }}>{t('PropertyGeneralSettings.logo')}</h4>

        <div className='row my-sm-4'>
          <div className='col-sm-12'>
            <FileUpload
              property={propertySettings}
              updateField={updateField}
              uploadKey={'logo'}
              description={t('PropertyGeneralSettings.logo')}
              readOnly={isReadOnly}
              accept={imageExt}
              makeThumbnail={true}
              makeStandard={true} />
          </div>
        </div>
      </Form>
      <AdminModal
        title={t('PropertyGeneralSettings.contacts.modal.title')}
        show={!!activeUserModal}
        onHide={() => activeUserModalHandler(null)}
        onSave={() => setIsContactSaving(true)}
        isSaving={isContactSaving}
        readOnly={!selectedUser}
        customSaveLabel={t('PropertyGeneralSettings.contacts.save')}
        dialogClassName={styles.ModalDialog}
      >
        <div className={styles.addContact}>
          <p className="font20 my-2">{t('PropertyGeneralSettings.contacts.modal.details')}</p>
          <div className='row'>
            <div className='col-sm-12'>
              <AdminInput
                placeholder={t('PropertyGeneralSettings.contacts.modal.placeholder')}
                value={usersSearchFilter}
                type={'text'}
                onChange={(e) => setUsersSearchFilter(e.target.value)} />
            </div>
          </div>

          <div className={styles.tableContainer}>
            <ContactsList
              contacts={users.filter(user => !contacts.map(_ => _.username).includes(user.username)).filter(_ => searchUsers(_))}
              selectedValue={selectedUser}
              onClick={setSelectedUser} />
          </div>
          <div>
            {selectedUser &&
              <Contact
                contact={selectedUser} />}
          </div>
        </div>
      </AdminModal>
      <ConfirmModal
        show={showConfirmModal}
        title={t('PropertyGeneralSettings.removeContact.title')}
        onConfirm={confirmDeleteHandler}
        onHide={() => setShowConfirmModal(false)}
        cancelButtonText={t('ui.cancel')}
        confirmButtonText={t('ui.ok')}>
        {t('PropertyGeneralSettings.removeContact.message')}
      </ConfirmModal>
      <PropertyImagesUpload
        propertySettings={propertySettings}
        setErrors={setErrors}
        activeUploadModal={activeUploadModal}
        setActiveUploadModal={setActiveUploadModal}
        setPropertySettings={setPropertySettings}
        title={t('PropertyGeneralSettings.logo')}
        makeThumbnail={true}
        makeStandard={true}
        dialogClassName={styles.ModalDialog}
      />
    </>
  )
}

export default PropertyGeneralSettings