import React, { useState, useRef, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import uploadImg from './../../assets/images/button-icons/upload.svg';
import autocadImg from './../../assets/images/button-icons/autocad-icon.png';
import axios from '../../utils/axios';
import UploadButton from '../../components/UploadButton/UploadButton';
import Axios, { CancelToken } from 'axios';
import { get } from '../../utils/utils';
import { useIntl } from 'react-intl';

/**
 * Handles generic uploads for space profile
 */
export default function FileUpload({ company, property, updateField, uploadKey, description, readOnly, icon, accept, makeThumbnail, makeStandard, companySettings, maxHeight, maxWidth }) {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [fileUpload, setFileUpload] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const uploadRef = useRef(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {

    setErrors(null);
    if (!fileUpload) return;
    setIsSaving(true);
    const source = CancelToken.source();
    const saveFileUpload = async () => {
      try {
        let result;
        const data = new FormData();

        if (property) {
          data.append('file', fileUpload);
          if (makeThumbnail) data.append('thumbnail', true);
          if (makeStandard) data.append('standard', true);
          result = await axios.put(`/properties/upload/${property.companySlug}/${property.slug}/${uploadKey}?slugged=true&company=${property.company}&property=${property.name}`, data, {
            cancelToken: source.token
          });
          updateField({ target: { type: 'text', value: result.data[uploadKey] } }, property || company, uploadKey);
        }

        if (company && !companySettings) {
          data.append(uploadKey, fileUpload);
          data.append('city', company.city);
          result = await axios.put(`/companies/${company.name}`, data, {
            cancelToken: source.token
          });
          let upload = get(result, `data.company.${uploadKey}`);
          updateField({ target: { type: 'text', value: upload } }, company, uploadKey);
        }

        if (companySettings) {
          data.append(uploadKey, fileUpload);
          Object.keys(companySettings).forEach(_ => data.append(_, JSON.stringify(companySettings[_])));
          result = await axios.put(`/company-settings/${encodeURIComponent(company.name)}?uploadKey=${uploadKey}&maxWidth=${maxWidth}&maxHeight=${maxHeight}`, data, {
            cancelToken: source.token
          });
          let upload = get(result, `data.${uploadKey}`);
          updateField({ target: { type: 'text', value: upload } }, companySettings, uploadKey);
        }

        setFileUpload(null);
      } catch (e) {
        if (Axios.isCancel(e)) {
          // request is cancelled
        } else {
          setErrors(e.response.data.errors.map(err => err.message))
        }
      } finally {
        setIsSaving(false);
      }
    }
    saveFileUpload();

    return () => {
      source.cancel();
      setFileUpload(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUpload, property, makeStandard, makeThumbnail, setErrors, updateField, uploadKey]);

  const onFileInputChangeHandler = async (e) => {
    setFileUpload(e.target.files[0]);
    e.target.value = null;
  };

  const onDelete = async () => {
    try {
      if (property) {
        const result = await axios.delete(`/properties/upload/${property.company}/${property.name}/${uploadKey}`);
        updateField({ target: { type: 'text', value: result.data[uploadKey] } }, property, uploadKey);
      }

      if (company && !companySettings) {
        const data = new FormData();
        data.append(uploadKey, '');
        data.append('city', company.city);
        let result = await axios.put(`/companies/${company.name}`, data);
        let upload = get(result, `data.company.${uploadKey}`);
        updateField({ target: { type: 'text', value: upload } }, company, uploadKey);
      }

      if (company && companySettings) {
        const data = new FormData();
        updateField({ target: { type: 'text', value: undefined } }, companySettings, uploadKey);
        Object.keys(companySettings).forEach(_ => data.append(_, JSON.stringify(companySettings[_])));
        await axios.put(`/company-settings/${encodeURIComponent(company.name)}`, data);
      }

      setFileUpload(null);
    } catch (e) {
      setErrors(t('error.deleteFile'));
    }

  }

  const buttonImg = uploadKey === 'autocad' ? autocadImg : (icon || uploadImg);

  let persistedFile;
  if (property) {
    persistedFile = property[uploadKey] && (property[uploadKey].original || property[uploadKey]);
  }
  if (company && !companySettings) {
    persistedFile = get(company, `${uploadKey}.thumbnail`) || get(company, `${uploadKey}.original`);
    persistedFile = persistedFile ? `https://s3.amazonaws.com/idp-rpm-v4/${persistedFile}` : null;
  }

  if (companySettings) {
    persistedFile = get(companySettings, `${uploadKey}.thumbnail`);
    persistedFile = persistedFile ? `https://s3.amazonaws.com/idp-rpm-v4/${persistedFile}` : null;
  }

  return (
    <div className='d-sm-flex align-items-center'>
      <UploadButton
        variant="secondary"
        disabled={isSaving || readOnly}
        isSaving={isSaving}
        icon={buttonImg}
        description={description}
        persistedFile={persistedFile}
        onClick={() => uploadRef.current.click()}
        onDelete={onDelete}
      />
      <input ref={uploadRef} type="file" name="file" onChange={onFileInputChangeHandler} className='d-none' accept={accept} />
      {errors ? <Alert className='mt-2' key={errors} variant='danger'>{t('error')}: {errors}</Alert> : null}
    </div>
  )
}
