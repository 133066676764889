import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import logo from './../../assets/images/login-logo2.png';
import styles from './ResetPassword.module.css';
import axios from '../../utils/axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alerts, Spinner } from '@project/components';
import { useIntl } from 'react-intl';

const ResetPassword = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const classNames = require('classnames');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [success, setSuccess] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const token = query.get("t");

  const sendResetPasswordRequest = async () => {
    setErrors([]);
    setSuccess(false);
    setLoading(true);

    try {

      if (password1 === password2 && password1.length >= 10) {
        const result = await axios.post('/users/reset-password', { token: token, password: password1 });

        if (result.status === 200) {
          setSuccess(true);
        }
      }
      else {
        setErrors(t('error.password.matchAndLength'));
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.password.update')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    if (success) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 5000);


      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <>
      <div className={classNames(styles.loginPage, 'd-flex')}>
        <div className={classNames(styles.loginContainer, 'm-auto mw-100 bg-white shadow-sm')}>
          <div className={classNames(styles.logo, 'text-center')}>
            <img src={logo} width="133px" alt="ID360" />
          </div>
          {!success ?
            <p>
              {t('ResetPassword.label')}
            </p>
            : null}
          <Form>
            {!success ?
              <>
                <Form.Group>
                  <Form.Control
                    className={styles.loginInput}
                    type="password" placeholder={t('ui.password')}
                    onChange={e => setPassword1(e.target.value)} />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    className={styles.loginInput}
                    type="password" placeholder={t('ui.confirmPassword')}
                    onChange={e => setPassword2(e.target.value)} />
                </Form.Group>
              </>
              : null}
            {errors ? <Alerts messages={errors} close={() => { setErrors([]) }}></Alerts> : null}
            {success ? (
              <Alert key={'messagekey'} variant="success">
                {t('ResetPassword.passwordUpdated')} <Link to='/login'>{t('ResetPassword.login')}</Link>. {t('ResetPassword.redirect')}
              </Alert>
            ) : null}
            <Button
              variant="primary"
              block
              className={classNames(styles.btnLogin, 'border-0 w-100 d-flex align-items-center justify-content-center')}
              onClick={sendResetPasswordRequest}>
              {t('ResetPassword.resetPassword')}
            </Button>
          </Form>
          <div className="border-bottom my-3"></div>
        </div>
      </div>

      {loading ?
        <Spinner />
        : null
      }
    </>
  );
};

export default ResetPassword;
