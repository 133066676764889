import React, {
  useEffect
} from 'react';
// TODO: part of the IDCloud MFA login workaround
import Cookies from 'universal-cookie';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';

/**
 * Higher-Order Component that enhances components by giving them
 * access to auth-related props and logic. This component will also
 * control the auth status (logged in or not) and redirect the app
 * to an specified URL if no auth token is present.
 *
 * Props passed to WrappedComponent:
 * - `authToken` initial, cold value coming from a stored cookie
 * - Any other prop assigned to the enhanced HOC.
 *
 * @example
 * ```js
 * const MyComponent = ({ authToken, name }) => (
 *   // This component receives the authToken and any extra props
 *   <p>Hello, {name}. Your token is {authToken}</p>
 * );
 *
 * const MyComponentWithAuth = withAuth(MyComponent); // Will inject the authToken
 *
 * <MyComponentWithAuth
 *  name="React" // Extra props, like name, are passed to the original component
 *  kickToExternalUrl="https://idcloud.idplans..." // Redirection
 * />
 *
 * // Or
 *
 * <MyComponentWithAuth
 *  name="React" // Extra props, like name, are passed to the original component
 *  kickToInternalUrl="/login"
 * />
 *
 * // Or we can pass the redirection URLs as options instead of props
 *
 * const MyComponentWithAuth = withAuth(MyComponent, {
 *   kickToInternalUrl: '/login'
 * }); // This way we can pass around the component variable without having to worry about the props
 * ```
 *
 * @param {ReactNode} WrappedComponent
 * @param {string?} options.kickToExternalUrl
 * @param {string?} options.kickToInternalUrl
 * @returns Enhanced `<WithAuth />` component.
 */
const withAuth = (WrappedComponent, {
  kickToExternalUrl: kickToExternalUrlOption,
  kickToInternalUrl: kickToInternalUrlOption,
  isLoggedInCondition: isLoggedInConditionOption,
}) => {
  const WithAuth = ({
    kickToExternalUrl: kickToExternalUrlProp,
    kickToInternalUrl: kickToInternalUrlProp,
    isLoggedInCondition: isLoggedInConditionProp,
    ...passThroughProps
  }) => {
    const [authToken] = useAuth();

    const externalUrl = kickToExternalUrlProp || kickToExternalUrlOption;
    const internalUrl = kickToInternalUrlProp || kickToInternalUrlOption;
    const isLoggedInCondition = isLoggedInConditionProp || isLoggedInConditionOption;

    // TODO: part of the IDCloud MFA login workaround
    const cookies = new Cookies();
    const loggedInCookie = cookies.get('logged-in');
    //const queryParams = new URLSearchParams(window.location.search)
    const isLoggedIn = isLoggedInCondition || (loggedInCookie && loggedInCookie === 'true');

    return (
      <>
        {/*
          This controls the redirection to a login URL (or any URL we want).
          As soon as the auth token disappears, this component will kick the
          app to the internal or external URL.
        */}
        <ForbiddenRedirect
          externalUrl={externalUrl}
          internalUrl={internalUrl}
          trigger={!isLoggedIn}
        />
        {/*
          The wrapped/enhanced component receives the token and a callback
          that allows us to mutate that token in another context (after a
          login or logout, we must set or null it).
        */}
        <WrappedComponent
          authToken={authToken}
          {...passThroughProps}
        />
      </>
    );
  };

  // Copy static members
  hoistNonReactStatic(WithAuth, WrappedComponent);

  // Improve/beautify the enhanced component name - Debugging and React DevTools
  WithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;

  // Done.
  return WithAuth;
};

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
);

/**
 * Component that will redirect the app to either an internal or an
 * external URL.
 * @example
 * ```js
 * // To an internal URL
 * <ForbiddenRedirector internal="/login" trigger={true} />
 *
 * // External
 * <ForbiddenRedirector external="https://idcloud.idplans.com/login" trigger={true} />
 * ```
 */
const ForbiddenRedirect = ({
  internalUrl,
  externalUrl,
  trigger
}) => {
  useEffect(() => {
    if (trigger && externalUrl) {
      window.location.href = externalUrl;
    }
  }, [externalUrl, trigger]);

  return (
    <>
      {trigger && !!internalUrl && (
        <Navigate to={internalUrl} />
      )}
    </>
  );
};

export default withAuth;
