// eslint-disable-next-line
import React, {useEffect} from 'react'
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
const cookies = new Cookies();

export default function Analytics({ua, appId, pageId, title, category, name, axios, recordPageHits, company, property, space}) {

  ReactGA.initialize(ua, {debug: true, alwaysSendReferrer: true});

  const getCookieOpts = () => {
    let opts = {
      path: '/',
      domain: 'localhost',
      secure: false
    };

    let date = new Date();
    date.setTime(date.getTime() + (60 * 60 * 1000));
    opts.expires = date;

    if (process.env.NODE_ENV !== 'development') {
      opts.domain = ".idplans.com";
      opts.secure = true;
    }

    return opts;
  };

  const addPageHit = async () => {

    try {

      if (company) ReactGA.set({company: company});
      if (property) ReactGA.set({property: property});
      if (space) ReactGA.set({space: space});

      ReactGA.pageview(window.location.pathname);

    }
    catch (e) {
      // fail silently but
      // expose error in console
      console.log(e);
    }

  };

  // record page hits automatically
  // other state/effects can be setup
  // to trigger events
  useEffect(() => {

    const _addPageHit = () => {
      const expires = cookies.get(`${appId}-${pageId}-analytics`);
      if (!expires) {
        cookies.set(`${appId}-${pageId}-analytics`, `${appId}-${pageId}-analytics`, getCookieOpts());
        addPageHit();
      }
    };

    if (recordPageHits && appId && pageId && axios) {
      _addPageHit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

Analytics.propTypes = {
  /* appId, pageId, title, category, name, axios, recordPageHits */
  /** unique id for app we're in */
  appId: PropTypes.string.isRequired,
  /** unique id for page we're on - the combo key of appId/pageId should be unique across entire platform */
  pageId: PropTypes.string.isRequired,
  /* a 'category' for recording page hits, maybe filterable from analytics? */
  category: PropTypes.string,
  /* a 'title' for recording page hits, maybe filterable from analytics? */
  title: PropTypes.string,
  /* a created and usable axios object passed in from app using this component */
  axios: PropTypes.func,
  /* boolean whether to forceable space recording page hits with 1 hour pause */
  recordPageHits: PropTypes.bool
};