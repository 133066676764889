/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import TitleDescription from './../../components/TitleDescription/TitleDescription';
import { Alerts, AdminSelect, Table, AdminModal } from '@project/components';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import TwoColumnContent from './../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from './../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from './../../components/TwoColumnContent/RightColumn/RightColumn';
import { RootContext } from "../../hoc/RootContext/RootContext";
import axios from "../../utils/axios";
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import BtnLight from './../../components/BtnLight/BtnLight';
import DefaultProfilePicture from '../../assets/images/unknown-unit.png';
import Title from '../../components/Title/Title';
import styles from './VendorDashboard.module.css';
import Cancel from '../../assets/images/cancel';
import { get } from '../../utils/utils';

const INVITE_EXPIRATION_DAYS = 7;

const VendorDashboard = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();

  const { smallMobileView, mobileView, xLargeView, currentUser, largeView } = useContext(RootContext);

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [displayCompany, setDisplayCompany] = useState(null)
  const [companyOptions, setCompanyOptions] = useState([]);
  const [invites, setInvites] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [inviteToBeDeleted, setInviteToBeDeleted] = useState(null);
  const [isDeletingInvite, setIsDeletingInvite] = useState(false);
  const [resendInviteSuccess, setResendInviteSuccess] = useState(false);
  const [currentUserActions, setCurrentUserActions] = useState([]);

  useEffect(() => {

    if (currentUser) {
      setCompany(currentUser.company);
      setDisplayCompany(currentUser.companyDisplayName)
    }

    if (currentUser && currentUser.displayCompanies.length > 1) {
      let options = currentUser.displayCompanies.map(_ => ({ label: _.displayName, value: _.name }));
      setCompanyOptions(options);
    }

  }, [currentUser]);

  useMemo(() => {

    const fetch = async () => {
      try {

        setLoading(true);
        let actions = [];
        setInvites([])
        setVendors([])
        setErrors([])

        const encodedCompany = encodeURIComponent(company)
        const result = await axios.get(`/role?company=${encodedCompany}`);

        if (result.status === 200) {
          actions = result.data.record.actions
          setCurrentUserActions(actions)
        }

        const result1 = axios.get(`/vendor-invites?company=${encodedCompany}&status=active`);
        const result2 = axios.get(`/vendors?company=${encodedCompany}`);
        let results = await Promise.all([result1, result2]);

        if (results[0].status === 200 && actions.includes('idcloud:invites:list')) {
          const invites = results[0].data.records.filter(invite => invite.sent).sort((a, b) => (a.sent > b.sent) ? 1 : -1).reverse();
          invites.forEach(vendor => {

            // determine the elpased ms since the invite was sent (we store the sent date in seconds)
            const dateDiff = Date.now() - new Date(parseInt(vendor.sent) * 1000);

            // if the invite was sent more than 7 days ago, mark it as expired, otherwise show the relative time
            if (vendor.sent && (dateDiff > (INVITE_EXPIRATION_DAYS * 1000 * 60 * 60 * 24))) {
              vendor.sentStatus = t('VendorDashboard.invitations.expired');
            } else {
              const elapsedDays = Math.floor(dateDiff / (1000 * 60 * 60 * 24));
              vendor.sentStatus = elapsedDays === 0 ? t('VendorDashboard.invitations.sentToday') : `${t('VendorDashboard.invitations.sent')} ${intl.formatRelativeTime(0 - elapsedDays, 'day')}`;
            }

          });

          setInvites(invites);
        }

        if (results[1].status === 200 && actions.includes('vendors:list')) {
          let vendors = results[1].data.records.sort((a, b) => (a.fname > b.fname) ? 1 : -1);
          vendors = vendors.map(vendor => {
            return { ...vendor, profilePicture: vendor.profilePicture || DefaultProfilePicture, company: '', ...get(vendor, 'vendorCompanyInformation', {}) }
          })
          setVendors(vendors);
        }


      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverDataVendor')}`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (currentUser && company) fetch();

  }, [currentUser, company]);

  const resendInvite = async (selectedInvite) => {
    if (currentUserActions.includes('idcloud:invites:resend')) {
      setResendInviteSuccess(false);
      try {
        setLoading(true);
        setErrors([]);

        let result = await axios.post(`/resend-vendor-invite/${selectedInvite.company}/${selectedInvite.email}`);

        if (get(result, 'data.success')) setResendInviteSuccess(true);
      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response?.data?.errors?.[0]?.message})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }
    }
    else {
      setErrors(`${t('error.resendInvite')} ${company}`)
    }
  };

  const cancelDeleteInviteHandler = () => {
    setShowCancelModal(false);
    setInviteToBeDeleted(null);
  }

  const cancelInvite = async (selectedInvite) => {

    if (currentUserActions.includes('idcloud:invites:cancel') ||
      currentUserActions.includes('idcloud:invites:delete')) {
      setIsDeletingInvite(true);
      try {
        setLoading(true);
        setErrors([]);

        const c = selectedInvite.company;
        const e = selectedInvite.email;
        const result = await axios.post(`/cancel-vendor-invite/${c}/${e}`);
        if (result.status === 200) {
          const newInvites = invites.filter((_) => !(_.company === c && _.email === e));
          setInvites(newInvites);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response?.data?.errors?.[0]?.message})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }
      setIsDeletingInvite(false);
      setInviteToBeDeleted(null);
      setShowCancelModal(false);
    }
    else {
      setErrors(intl.formatMessage({ id: 'error.cancelExistingInvite' }, { company }))
    }
  };

  const editVendor = (user) => {
    navigate(`/vendors/${user.username}`);
  };

  if (!currentUser) {
    return <PageContentContainer><h3 style={{ "color": "#990000" }}> {t('error.notAuthorized')}</h3></PageContentContainer>
  }

  return (
    <>
      <PageContentContainer>

        <Title title={t('VendorDashboard.mainTitle')} />

        {errors.length > 0 ?
          <div style={{ "marginTop": "20px" }}>
            <Alerts messages={errors} close={() => setErrors([])} />
          </div>
          : null}

        {/* multi home company list */}
        {
          companyOptions.length > 0 ?
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('VendorDashboard.company.title')}
                  description={intl.formatMessage({ id: 'VendorDashboard.company.description' }, { displayCompany })}
                  style={!largeView ? { maxWidth: '100%' } : {}}
                />
              </LeftColumn>
              <RightColumn >
                <AdminSelect
                  id="companySelect"
                  propStyles={styles.adminSelect}
                  options={companyOptions}
                  value={{ label: displayCompany, value: company }}
                  onChange={(c) => {
                    setCompany(c.value)
                    setDisplayCompany(c.label)
                  }}
                  isLoading={false} />
              </RightColumn>
            </TwoColumnContent>
            : null
        }

        <TitleDescription
          title={t('VendorDashboard.invitations.title')}
          description={intl.formatMessage({ id: 'VendorDashboard.invitations.description' })}
        />
        {
          currentUserActions.includes('idcloud:invites:add') ?
            <BtnLight title={'Invite Vendor'} onClick={() => navigate('/vendor-invite')} className='mr-3 mb-3' />
            : null
        }
        {
          currentUserActions.includes('vendors:list') ?
            <BtnLight title={'Vendor Search'} onClick={() => navigate('/vendor-search')} className='mb-3' />
            : null
        }
        {resendInviteSuccess ?
          <Alerts variant='success' close={() => setResendInviteSuccess(false)} messages={[t('VendorDashboard.resendInvite.success')]} showIcon={true} dismissible />
          : null
        }
        {
          !loading && !currentUserActions.includes('idcloud:invites:list') ?
            <Alerts messages={intl.formatMessage({ id: 'error.listInvites' }, { displayCompany })} close={() => null} />
            : null
        }
        <Table
          key="invitesTable"
          idField={'name'}
          label={'Current outstanding invitations'}
          items={invites}
          sortFields={['sentStatus', 'name']}
          searchFilters={['name', 'email', 'sentStatus']}
          fields={[
            { id: 'name', type: 'details', label: t('term.vendor'), options: { circleTitle: true } },
            { id: 'email', type: 'details', label: 'E-mail' },
            {
              id: 'sentStatus', type: 'status', label: t('SearchTable.status'), statusColors: [
                { condition: invite => invite.sentStatus === 'Expired', color: { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: 'rgb(215,1,1)' } },
                { condition: invite => invite.sentStatus !== 'Expired', color: { backgroundColor: 'rgba(55, 182, 91, 0.1)', color: '#37b65b' } }
              ]
            },
            { id: 'resend', type: 'button', buttonLabel: 'Resend', click: resendInvite, options: { buttonType: 'underline', color: '#757575' } },
            { id: 'cancel', type: 'button', buttonLabel: 'Cancel', click: (_) => { setInviteToBeDeleted(_); setShowCancelModal(true); }, options: { buttonType: 'underline', color: '#D72C0D' } }
          ]}
          isLoading={loading}
          hideSearch={false}
          hideTableHeader={false}
          hidePaginate={invites?.length <= 10}
          customWidths={['40%', '30%', '20%', '10%', '10%']}
          customStyle={`mb-5`}
          borderBottomLastItem={true}
          rowPlaceholderHeight={'71px'}
          scrollHeight={'300px'}>

        </Table>
        <hr />
        {
          !loading && !currentUserActions.includes('vendors:list') ?
            <Alerts messages={intl.formatMessage({ id: 'error.listVendors' }, { displayCompany })} close={() => null} />
            : null
        }
        <Table
          key="vendorsTable"
          idField={'username'}
          searchFilters={['fname', 'lname', 'email']}
          sortFields={['fname', 'city', 'company', 'status']}
          itemsPerPage={4}
          showFields={
            (smallMobileView && ['fname', 'edit']) ||
            (mobileView && ['fname', 'company', 'edit']) ||
            ['fname', 'company', 'status', 'edit']
          }
          detailsDropdown={
            (smallMobileView && ['company', 'city', 'status']) ||
            (mobileView && ['city', 'status']) ||
            ['city']
          }
          fields={[
            { id: 'fname', label: t('term.vendor'), type: 'details', options: { concatValues: { keys: ['lname'], separator: ' ' }, photo: { key: 'profilePicture', objectFit: 'cover' }, style: styles.vendorCell } },
            { id: 'city', label: t('term.location'), type: 'details', options: { concatValues: { keys: ['state'], separator: ', ' } } },
            { id: 'company', label: t('term.company'), type: 'details', options: { photo: { key: 'logo', noBorder: true }, style: styles.companyCell } },
            {
              id: 'status', label: t('SearchTable.status'), type: 'status', statusColors: [
                { condition: _ => _.status === 'active', color: { backgroundColor: 'rgba(55, 182, 91, 0.1)', color: '#37b65b' } }
              ]
            },
            {
              id: 'edit', label: t('VendorSearch.actions.label'), type: 'actions', actions: [
                { label: t('UsersList.edit'), click: editVendor }
              ]
            }
          ]}
          label={t('VendorDashboard.myVendors')}
          placeholder={t('Team.filterMembers.placeholder')}
          items={vendors}
          itemLabels={{
            status: {
              'active': t('SearchTable.active'),
              'pending': t('SearchTable.pending'),
              'inactive': t('SearchTable.inactive')
            }
          }}
          customWidths={
            (smallMobileView && ['100%', '100%']) ||
            (mobileView && ['50%', '50%', '50%']) ||
            (!xLargeView && ['33%', '33%', '33%', '33%']) ||
            ['33%', '33%', '33%', '33%']
          }
          customStyle={'mt-5'}
          isLoading={loading}
          rowPlaceholderHeight={'71px'}
        />

      </PageContentContainer>
      <AdminModal
        show={showCancelModal}
        onHide={cancelDeleteInviteHandler}
        noSave={true}
        onSave={() => { }}
        customFooterStyle={"justify-content-between"}
        onDelete={_ => cancelInvite(inviteToBeDeleted)}
        deleteButtonText={t('ui.yesCancel')}
        deleteModeOverride={true}
        isSaving={isDeletingInvite}
        customCancellationLabel={t('ui.close')}>
        <div className={`${styles.cancelModal} w-100`}>
          <div className={styles.cancelButtonModal}>
            <div onClick={cancelDeleteInviteHandler}><Cancel /></div>
          </div>
          <div className={styles.cancelInviteModalTitle}>
            {t('VendorDashboard.deleteInvite.title')}
          </div>
          <div className={styles.cancelInviteModalDetails}>
            {t('VendorDashboard.deleteInvite.message')}
          </div>
        </div>
      </AdminModal>
    </>
  );
};

export default VendorDashboard;
