import React, { useEffect } from 'react';
import { RgbaColorPicker, HexColorPicker } from "react-colorful";
import styles from './ColorPicker.module.css';
import AdminInput from '../AdminInput/AdminInput';
import { useIntl } from 'react-intl';

const classNames = require('classnames');

const ColorPicker = ({ label, color, setColor, opacity, setOpacity, colorInput = true, isInvalid, setIsInvalid, showPicker, setShowPicker }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  useEffect(() => {
    const isValidHex = (hex) => {
      return hex.length === 0 || hex.length === 3 || hex.length === 6;
    };

    let colorClean = color && color.split('').map(_ => {
      return /[0-9a-f]/.test(_.toLowerCase()) ? _ : 'f'
    }).join('');

    setIsInvalid(!isValidHex(color));

    if (color !== colorClean) {
      setColor(colorClean);
    }
  }, [color, setColor, setIsInvalid]);

  useEffect(() => {
    if (opacity < 0) {
      setOpacity(0);
    } else if (opacity > 1) {
      setOpacity(1);
    }
  }, [opacity, setOpacity])

  const hexToRGBA = (color, opacity) => {
    let r, g, b;

    if (color.length >= 6) {
      r = parseInt(color.slice(0, 2), 16);
      g = parseInt(color.slice(2, 4), 16);
      b = parseInt(color.slice(4, 6), 16);
    } else if (color.length >= 3) {
      r = parseInt(`${color.slice(0, 1)}${color.slice(0, 1)}`, 16);
      g = parseInt(`${color.slice(1, 2)}${color.slice(1, 2)}`, 16);
      b = parseInt(`${color.slice(2, 3)}${color.slice(2, 3)}`, 16);
    } else {
      r = 255;
      g = 255;
      b = 255;
    }

    return { r, g, b, a: opacity };
  }

  const rgbaToHex = (rgba) => {
    if (!rgba) return rgba;
    let r = rgba.r.toString(16);
    r = r.length === 2 ? r : `0${r}`;
    let g = rgba.g.toString(16);
    g = g.length === 2 ? g : `0${g}`;
    let b = rgba.b.toString(16);
    b = b.length === 2 ? b : `0${b}`;

    let a = rgba.a;

    setOpacity(a);
    setColor(`${r}${g}${b}`);
  }

  const rgbObjectToString = (rgba) => {
    return `rgb(${rgba.r}, ${rgba.g}, ${rgba.b})`;
  }

  return (
    <div className={classNames(styles.colorPicker, !colorInput ? "text-center" : null)}>
      <h6>{label}</h6>
      <div className={`d-flex position-relative align-items-start ${!colorInput ? 'justify-content-center' : ''}`}>
        {colorInput ?
          <AdminInput
            value={`#${color}`}
            onChange={(e) => setColor(e.target.value.slice(1))}
            type="text" isInvalid={isInvalid}
            label={label}
            customstyle={classNames("mb-0 mr-3", styles.colorInput)} /> : null}
        <div className={styles.colorCircle}>
          <div onClick={() => setShowPicker(!showPicker)} style={{ backgroundColor: rgbObjectToString(hexToRGBA(color)), opacity: opacity }}></div>
        </div>
        {showPicker ?
          <div className={styles.picker} onClick={(e) => { e.stopPropagation(); setShowPicker(true); }}>
            {setOpacity ?
              <RgbaColorPicker color={hexToRGBA(color, opacity)} onChange={rgbaToHex} /> :
              <HexColorPicker color={`#${color}`} onChange={(e) => setColor(e.slice(1))} />}
            <div className={classNames(styles.pickerInput, "d-flex justify-content-between")}>
              <div>
                <AdminInput value={`#${color}`} onChange={(e) => setColor(e.target.value.slice(1))} type="text" isInvalid={isInvalid} customstyle={styles.pickerInput} label={t('ColorPicker.hex') || "HEX#"} />
              </div>
              {setOpacity ?
                <div>
                  <AdminInput value={opacity} onChange={(e) => setOpacity(e.target.value)} type="text" customstyle={styles.pickerInput} label={t('ColorPicker.opacity') || "Opacity"} />
                </div>
                : null}
            </div>
          </div> : null}
      </div>
      <p>{t('ColorPicker.chooseHex') || 'Choose HEX#'}</p>
    </div>
  );
};

export default ColorPicker;