import React, {useRef, useState} from 'react';
import styles from './DragAndDrop.module.css';
import Upload from '../assets/images/upload-solid.svg'
import { useIntl } from 'react-intl';

const DragAndDrop = ({ children, dropHandler, isReadOnly }) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [dragging, setDragging] = useState(false);
  
  const dragCounter = useRef(0);
  
  const handleDrag = (e) => {
    if (isReadOnly) return;
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e) => {
    if (isReadOnly) return;
    e.preventDefault()
    e.stopPropagation()
    
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true)
    }
  }

  const handleDragOut = (e) => {
    if (isReadOnly) return;
    e.preventDefault()
    e.stopPropagation()

    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragging(false)
    }
  }

  const handleDrop = (e) => {
    if (isReadOnly) return;
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      dropHandler(e.dataTransfer.files)
      e.dataTransfer.clearData()
      dragCounter.current = 0;
    }
  }

  return (
    <div
      className={styles.dragAndDrop}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}>
      {dragging &&
        <div className={styles.container}>
          <div className={`${styles.messageContainer} d-flex flex-column justify-content-center align-items-center`}>
            <div className={styles.upload}><img src={Upload} alt={t('DragDropImageUpload.drop')} /></div>
            <div>{t('DragDropImageUpload.drop')}</div>
          </div>
        </div>
      }
      {children}
    </div>
  )
}

export default DragAndDrop;