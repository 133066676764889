import React from 'react';
import { Toast } from 'react-bootstrap';
import styles from './AdminToast.module.css';

const AdminToast = ({ onClose, show, message, autohide = true, variant = "success", style }) =>
  <div aria-live="polite" aria-atomic="true" className={`position-absolute ${styles.toastDiv}`}>
    <div className={`position-absolute ${styles.toastContainer}`} style={style}>
      <Toast onClose={() => onClose(false)} show={show} delay={3000} autohide={autohide}>
        <Toast.Body className={`font-md ${styles.toastBody} ${styles[variant]}`}>{message}</Toast.Body>
      </Toast>
    </div>
  </div>

export default AdminToast;