/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdminInput, AdminSelect, Alerts, Table } from '@project/components';
import DefaultLeasingImage from '../../assets/images/unknown-unit.png';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { RootContext } from "../../hoc/RootContext/RootContext";
import Title from '../../components/Title/Title';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import TwoColumnContent from '../../components/TwoColumnContent/TwoColumnContent';
import LeftColumn from '../../components/TwoColumnContent/LeftColumn/LeftColumn';
import RightColumn from '../../components/TwoColumnContent/RightColumn/RightColumn';
import axios from "../../utils/axios";
import { isValidEmail } from "../../utils/utils";
import { getImageUrl } from '@project/components/src/utils/image-location-utils'
import styles from './InviteVendor.module.css';

const InviteVendor = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();

  const { smallMobileView, mobileView, xLargeView, currentUser, largeView } = useContext(RootContext);

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState(null);
  const [displayCompany, setDisplayCompany] = useState(null)
  const [properties, setProperties] = useState([]);
  const [sections, setSections] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [emailValidated, setEmailValidated] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [currentUserActions, setCurrentUserActions] = useState([]);

  useEffect(() => {

    const fetch = async () => {
      try {

        const result = await axios.get(`/sections`);
        if (result.status === 200 && result.status === 200) {
          setSections(result.data.data);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }

    };

    fetch();

  }, []);

  useEffect(() => {

    if (currentUser) {
      setCompany(currentUser.company);
      setDisplayCompany(currentUser.companyDisplayName)
    }

    if (currentUser && currentUser.displayCompanies.length > 1) {
      let options = currentUser.displayCompanies.map(_ => ({ label: _.displayName, value: _.name }));
      setCompanyOptions(options);
    }

  }, [currentUser]);

  useEffect(() => {

    const fetch = async () => {
      try {
        setLoading(true);
        setErrors({});

        const encodedCompany = encodeURIComponent(company)
        const promises = [
          axios.get(`/role?company=${encodedCompany}`),
          axios.get(`/properties/${encodedCompany}?status=active`)
        ]

        const results = await Promise.all(promises)

        if (results[0].status === 200) {
          const actions = results[0].data.record.actions
          setCurrentUserActions(actions)
        }

        if (results[1].status === 200) {

          let props = results[1].data.data.map((p) => {
            if (!p.displayName) p.displayName = p.name;
            p.integrationIds = (p.integrations || []).map(_ => _.integrationId).join('');
            p.sections = ['all']
            return p;
          }).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);

          setProperties(props);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }

    };

    if (company) {
      fetch();
    }

  }, [company]);

  const preSaveInvite = async () => {
    setLoading(false);
    setErrors([]);

    const saveInvite = async (postobj) => {
      try {
        const result = await axios.post(`/vendor-invites`, postobj);

        if (result.status === 200) {
          if (result.data.record.existed) {
            toast(t('Invitations.sent.existingVendor'));
          }
          else {
            toast(t('Invitations.sent.vendor'));
          }
          navigate(`/vendors`);
        } else {
          setErrors(`${t('error.sendInvite')}`);
        }


      } catch (e) {
        if (e.response && e.response.data) {
          const apiErrors = e.response.data.errors.map((error) => error.message);
          setErrors(`${t('error.sendInvite')} (${apiErrors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
      finally {
        setLoading(false);
      }
    };

    if (currentUserActions.includes('idcloud:invites:add')) {
      try {
        setLoading(true);

        // validation
        if (!isValidEmail(email)) {
          setErrors([t('error.email.valid')]);
          setLoading(false);
          return;
        }

        // set property roles
        const access = selectedProperties
          .map((p) => {
            return {
              name: p.name,
              sections: p.sections
            };
          });

        const postobj = {
          company,
          email,
          access,
          name
        };

        if (access.length > 0) return await saveInvite(postobj);

        if (window.confirm(t('InviteVendor.propertyRole.noPermsWarning'))) return await saveInvite(postobj);

        setLoading(false);

      } catch (e) {
        setErrors(e.message);
      }
    }
    else {
      setErrors(intl.formatMessage({ id: 'error.addInvite' }, { displayCompany: company }))
    }


  };

  const checkEmail = async (email) => {

    try {
      setErrors([]);
      setLoading(true);
      setEmailValidated(false);
      setEmailInUse(false);

      // validation
      if (!isValidEmail(email)) {
        setErrors([t('error.email.valid')]);
        setLoading(false);
        return;
      }

      const result = await axios.post(`/vendors/check-email`, { email });

      if (result.status === 200) {
        if (result.data.exists === true) {
          setEmailValidated(false);
          setEmailInUse(true);
        }
        else {
          setEmailInUse(false);
          setEmailValidated(true);
        }
      }

    } catch (e) {
      if (e.response && e.response.data) {
        setErrors(`${t('error.sendInvite')} (${e.response.data.errors})`);
      } else {
        setErrors((e && e.message) || t('error.server'));
      }
    }
    finally {
      setLoading(false);
    }

  };

  const selectSection = (section, property) => {
    if (section === 'all') {
      property.sections = ['all'];
    } else {
      property.sections = (property.sections || []).filter(_ => _ !== 'all');
      property.sections.push(section);
    }
    setProperties([...properties]);
  }

  const unselectSection = (section, property) => {
    property.sections = (property.sections || []).filter(_ => _ !== section);

    setProperties([...properties]);
  }

  properties.forEach(_ => {
    _.leasing_image = getImageUrl(_, ['leasing_image.thumbnail.S', 'leasing_image'], DefaultLeasingImage, 'leasing_image')
  })

  let sectionOptions = sections.sort((a, b) => a - b).map(_ => ({ value: _.id, label: _.section_name }));

  sectionOptions = [{ value: 'all', label: t('ui.all') }, ...sectionOptions]

  let selectedPropertiesKeys = selectedProperties.map(_ => _.slug);

  let unselectedProperties = [];
  properties.forEach(_ => { if (!selectedPropertiesKeys.includes(_.slug)) unselectedProperties.push(_.slug) });

  return (
    <>
      <PageContentContainer>
        <Title title={t('InviteVendor.mainTitle')} backRoute={'/vendors'}
          button={{ click: preSaveInvite, label: t('InviteVendor.inviteMember') }} />

        {errors.length > 0 ?
          <div style={{ "marginTop": "20px" }}>
            <Alerts messages={errors} close={() => setErrors([])} />
          </div>
          : null}

        {/* EMAIL */}
        <TwoColumnContent bottomBorder={true} >
          <LeftColumn>
            <TitleDescription
              title={t('InviteVendor.email.title')}
              description={t('InviteVendor.email.description')}
              style={!largeView ? { maxWidth: '100%' } : {}}
            />
          </LeftColumn>
          <RightColumn padded={true} >

            {

              emailValidated && !emailInUse ?
                <div style={{ marginTop: 20 }}>
                  <Alert variant='success' onClose={() => { setEmail(''); setEmailValidated(false); }} dismissible>
                    <AiFillCheckCircle size={36} /> {email}
                  </Alert>
                </div>
                : null
            }

            {
              !emailValidated && emailInUse ?
                <div style={{ marginTop: 20 }}>
                  <Alert variant='danger' onClose={() => { setEmail(''); setEmailInUse(false); }} dismissible>
                    <BsExclamationTriangleFill size={36} /> {email}
                  </Alert>
                  <div>Email already exists in vendor database.</div>
                </div>
                : null
            }

            {
              !emailValidated && !emailInUse ?

                <div>
                  <AdminInput
                    type="email"
                    placeholder={t('InviteVendor.email.placeholder')}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onBlur={e => checkEmail(e.target.value)}
                    customstyle={styles.input}
                  />
                </div>
                : null
            }

          </RightColumn>
        </TwoColumnContent>

        {/* name */}
        <TwoColumnContent bottomBorder={true}>
          <LeftColumn>
            <TitleDescription
              title={t('InviteVendor.name.title')}
              description={t('InviteVendor.name.description')}
              style={!largeView ? { maxWidth: '100%' } : {}} />
          </LeftColumn>
          <RightColumn padded={true} >
            <div>
              <AdminInput
                type="text"
                placeholder={t('InviteVendor.name.placeholder')}
                value={name}
                onChange={e => setName(e.target.value)}
                customstyle={styles.input}
              />
            </div>
          </RightColumn>
        </TwoColumnContent>

        {/* multi home company list */}
        {
          companyOptions.length > 0 ?
            <TwoColumnContent bottomBorder={true}>
              <LeftColumn>
                <TitleDescription
                  title={t('InviteVendor.newVendor.title')}
                  description={intl.formatMessage({ id: 'InviteVendor.newVendor.description' }, { company })}
                  style={!largeView ? { maxWidth: '100%' } : {}}
                />
              </LeftColumn>
              <RightColumn padded={true} >
                <AdminSelect
                  propStyles={styles.adminSelect}
                  options={companyOptions}
                  value={{ label: displayCompany, value: company }}
                  onChange={(c) => {
                    setDisplayCompany(c.label)
                    setCompany(c.value)
                  }}
                  isLoading={false} />
              </RightColumn>
            </TwoColumnContent>
            : null
        }

        {/* PROPERTY ROLES */}
        <Container fluid className="px-0 pb-5 mt-4">
          <Row>
            <Col xs={12}>
              <TitleDescription
                title={t('InviteVendor.propertyRole.title')}
                description={t('InviteVendor.propertyRole.details')}
                style={{ maxWidth: '100%' }} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table
                idField={'slug'}
                searchFilters={['name', 'displayName', 'address1', 'market', 'integrationIds', 'state']}
                itemsPerPage={8}
                fields={[
                  { id: 'displayName', label: t('term.property'), type: 'details', options: !smallMobileView ? { photo: { key: 'leasing_image', objectFit: 'cover' }, style: styles.propertyCell } : { style: styles.propertyCell } },
                  { id: 'address1', label: t('term.location'), type: 'details', options: { concatValues: { keys: ['city', 'state'], separator: ', ' } } },
                  {
                    id: 'sections', label: 'Sections', dropdownLabel: 'Section permissions', type: 'dropdown', select: selectSection, unselect: unselectSection, selectOptions: sectionOptions
                  }
                ]}
                showFields={
                  (smallMobileView && ['displayName', 'sections']) ||
                  (mobileView && ['displayName', 'sections']) ||
                  (!xLargeView && ['displayName', 'address1', 'sections'])
                }
                customWidths={(mobileView && ['65%', '35%']) || ['40%', '40%', '30%']}
                label={t('InviteVendor.propertyRole.label')}
                placeholder={t('InviteVendor.propertyRole.placeholder')}
                items={properties}
                checkSelectable={true}
                checkSelectedItems={selectedProperties}
                checkSelectAll={[
                  { label: t('SearchTable.all'), filter: _ => true }
                ]}
                setCheckSelectedItems={setSelectedProperties}
                disabled={unselectedProperties}
                isLoading={loading}
              />
            </Col>
          </Row>
        </Container>

      </PageContentContainer>
    </>
  );
};

export default InviteVendor;
