import moment from 'moment';
import { intl } from './intl';

/**
 * Safely accesses an object using a flattened key
 *
 * @param {Object} obj base object
 * @param {String} key dot delimited access key for the object
 * @param {Object} defaultReturn default return when a match isn't found
 */
export const get = (obj, key, defaultReturn = null) => {
  const res = key
    .split('.')
    .reduce((returnVal, currentKey) => (returnVal ? returnVal[currentKey] : defaultReturn), obj);
  return res !== null && res !== undefined ? res : defaultReturn;
}

/**
 * Provide cookie options based on environment
 */
export const getCookieOptions = () => {
  /*
  path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
  expires (Date): absolute expiration date for the cookie
  maxAge (number): relative max age of the cookie from when the client receives it in seconds
  domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
  secure (boolean): Is only accessible through HTTPS?
  httpOnly (boolean): Is only the server can access the cookie?
  sameSite (boolean|none|lax|strict): Strict or Lax enforcement
  */
  let opts = {
    path: '/',
    domain: 'localhost',
    secure: false
  };

  let date = new Date();
  date.setDate(date.getDate() + 30);
  opts.expires = date;

  if (process.env.NODE_ENV !== 'development') {
    opts.domain = ".idplans.com";
    opts.secure = true;
  }

  return opts;
};

export const formatDate = (dateString) => {
  //Formats date in mm/dd/yyyy format
  let cleaned = ('' + dateString).replace(/\D/g, '').slice(0, 8)

  let date = cleaned.slice(0, 2)

  if (cleaned.length > 2) {
    date += '/'
    date += cleaned.slice(2, 4)
  }

  if (cleaned.length > 4) {
    date += '/'
    date += cleaned.slice(4, 8)
  }

  return date
}

export const formatLocalDate = (date) => {
  if (!date) return date;
  let formattedDate = new Date(date).toISOString().split(':');
  return `${formattedDate[0]}:${formattedDate[1]}`;
}

export const isExpiring = (date, days = 6) => {
  const daysUntilDue = ((moment(date).diff(new Date().getTime(), 'minutes') + 1439) / 1440);

  return daysUntilDue < days;
}

/**
 * Slugify function equivalent to the one we use in the
 * API. It takes a `str` string and turns it into a
 * URL-friendly value.
 *
 * @param {String} str string to slugify
 * @param {Integer} len max response length
 */
export const slugify = (str, len = 64) => {
  if (str === undefined || str === null) str = '';
  return str.toString().trim().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .substr(0, len);
}

export const formatDueDate = (date, addDue, showPastDueDate) => {
  let orginalLabel = `${addDue ? 'Due ' : ''}${moment(date).locale(intl.locale).format('MM/DD/yyyy')}`;
  let label = orginalLabel;

  if ((moment(date).diff(new Date().getTime(), 'minutes') + 1439) < 1440) {
    label = 'Due Today';
  }

  if ((moment(date).diff(new Date().getTime(), 'minutes') + 1439) < 0) {
    if (!showPastDueDate) {
      label = 'Past Due';
    } else {
      label = orginalLabel;
    }
  }

  return label;
}

/**
 * function identifies and replace HTML tags
 *
 * @param {String} str 
 */
export const removeTags = (str) => {
  return str.toString().replace(/(<([^>]+)>)/ig, '');
}


/**
 * @param {string} value 
 * @returns a string with no spaces and all lowercase
 */
export function removeSpacesAndMakeLowerCase(value) {
  if (!value) return value;
  if (typeof value !== 'string') return value;

  return value.replace(/\s/g, '').toLowerCase()
}