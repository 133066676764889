/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PageContentContainer from './../../components/PageContentContainer/PageContentContainer';
import { AdminCheckbox, AdminInput, Alerts, PasswordInput, Spinner, inputValidationRules } from "@project/components";
import BtnPrime from "../../components/BtnPrime/BtnPrime";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import { useLocation, useNavigate } from 'react-router-dom';
import { isValidPhone } from './../../utils/utils';
import axios from '../../utils/axios';
import { useIntl } from 'react-intl';
import styles from './Invitations.module.css';


const classNames = require('classnames');

const Invitations = () => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(null);
  // const [invite, setInvite] = useState({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState('');
  const [useMfa, setUseMfa] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  // validate invitation exists and is active
  useEffect(() => {
    setLoading(false);
    setErrors([]);

    const val = async () => {

      try {
        setLoading(true);

        let result = await axios.post(`/validate-invite`, { token });
        if (result.status === 200) {
          // setInvite(result.data.invite);
          setEmail(result.data.invite.email);
          setUsername(result.data.invite.email);
        }

      } catch (e) {
        if (e.response && e.response.data) {
          setErrors([`${t('error.sendInvite')} (${e.response.data.errors})`]);
        } else {
          setErrors([(e && e.message) || t('error.server')]);
        }
      }
      finally {
        setLoading(false);
      }
    };

    if (token) val()

  }, [token]);

  useEffect(() => {
    const t = query.get("t");
    if (t) setToken(t);
  }, []);

  const registerUser = async () => {
    setLoading(false);
    setErrors([]);

    try {
      setLoading(true);

      let errs = [];

      if (!confirmedPassword) errs.push(t('error.password.match'));
      if (password.trim().length < 12) errs.push(t('error.password.length'));
      if (fname.trim().length < 1) errs.push(t('error.firstName.empty'));
      if (lname.trim().length < 1) errs.push(t('error.lastName.empty'));
      if (!privacyPolicy) errs.push(t('error.privacyPolicy'));
      if (phone && !isValidPhone(phone)) errs.push(t('error.phone.invalid'));

      if (errs.length > 0) {
        return setErrors(errs);
      }

      const postobj = {
        token,
        username,
        email,
        fname,
        lname,
        password,
        phone,
        useMfa,
        privacyPolicy
      };

      let result = await axios.post(`/redeem-invitation`, postobj);

      if (result.status === 200) {
        navigate(`login`);
      } else {
        throw new Error(result?.data?.errors[0]?.message || t('error.server'));
      }
    } catch (e) {
      setErrors(e.message)
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageContentContainer>

        <div className={styles.invitationContainer}>
          <div style={{ width: '70vw' }}>

            {errors.length > 0 ?
              <div style={{ "marginTop": "20px" }}><Alerts messages={errors} close={() => setErrors([])} /></div>
              : null}

            <div className="d-flex justify-content-between align-items-center">
              <div className={classNames('font26')}>
                {t('Invitations.mainTitle')}
                <TitleDescription
                  title={t('Invitations.title')}
                  description={''}
                />
              </div>
              <BtnPrime title={t('Invitations.complete')} onClick={registerUser} />
            </div>

            <div>
              <form>
                <AdminInput
                  id="email"
                  label={t('Invitations.email')}
                  type="email"
                  placeholder={t('Invitations.email.placeholder')}
                  value={email}
                  readOnly={true} />

                <AdminInput
                  id="username"
                  label={t('Invitations.username')}
                  type="text"
                  placeholder={t('Invitations.username.placeholder')}
                  value={username}
                  onChange={e => setUsername(e.target.value)} />

                <PasswordInput
                  create={true}
                  strength={true}
                  password={password}
                  setPassword={setPassword}
                  setValidPassword={setConfirmedPassword}
                  validPassword={confirmedPassword}
                  labelStyle={styles.label}
                  rules={[
                    inputValidationRules.minLengthRule(12),
                    inputValidationRules.hasNumberRule(),
                    inputValidationRules.hasUpperCaseRule(),
                    inputValidationRules.hasLowerCaseRule(),
                    inputValidationRules.passwordStrengthRule(3)
                  ]}
                  tooltipPlacement={'top'}
                  className={styles.passwordInputStyle}
                />

                <AdminInput
                  id="fname"
                  label={t('Invitations.firstName')}
                  type="text"
                  placeholder={t('Invitations.firstName.placeholder')}
                  value={fname}
                  onChange={e => setFname(e.target.value)} />

                <AdminInput
                  id="lname"
                  label={t('Invitations.lastName')}
                  type="text"
                  placeholder={t('Invitations.lastName.placeholder')}
                  value={lname}
                  onChange={e => setLname(e.target.value)} />

                <AdminInput
                  id="phone"
                  label={t('Invitations.phone')}
                  type="text"
                  placeholder={t('Invitations.phone.placeholder')}
                  value={phone}
                  onChange={e => setPhone(e.target.value)} />

                <AdminInput
                  id='useMfa'
                  label={""}
                >
                  <AdminCheckbox
                    id='useMfaCheckbox'
                    label={t('Invitations.mfa')}
                    justifyContent={false}
                    removeMargins={true}
                    checked={useMfa}
                    alignLabel='right'
                    onChange={(e) => setUseMfa(e.currentTarget.checked)} />
                </AdminInput>

                <AdminInput
                  id='privacyPolicy'
                  label={""}
                >
                  <AdminCheckbox
                    id='privacyPolicyCheckbox'
                    label={t('Invitations.privacyPolicy')}
                    justifyContent={false}
                    removeMargins={true}
                    checked={privacyPolicy}
                    alignLabel='right'
                    onChange={(e) => setPrivacyPolicy(e.currentTarget.checked)}
                  />
                </AdminInput>
                <div>
                  <a href="https://images2.idplans.com/documents/IDP_Privacy_Policy.pdf" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                </div>

              </form>
            </div>

          </div>
        </div>

      </PageContentContainer>

      {loading ?
        <Spinner />
        : null
      }
    </>
  );
};

export default Invitations;
