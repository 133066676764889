import { locale } from './intl';

export function fullNameToUserName(fullName) {
  const [firstName, lastName] = fullName.split(' ')
  const username = firstName.substring(0, 1) + lastName
  return username.toLowerCase()
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  // Enum with ordered sized
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  // Constant for base 2 conversion
  const k = 1024;
  // Ratio for each unit convertion
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // Unit picked based on the ratio
  const preferredUnit = units[i];
  // Constrain that number to a short version
  const constrainedValue = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));
  // Value with proper decimal point
  const localizedValue = (Intl && Intl.NumberFormat) ?
    new Intl.NumberFormat(locale).format(constrainedValue) : constrainedValue;

  return `${localizedValue} ${preferredUnit}`;
}