import React from 'react';

const Image = ({count}) => {
  return count ?
    <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.9354 4.3307C30.2966 3.66149 29.4145 3.26606 28.4106 3.26606H23.3612V3.20522C23.3612 2.44476 23.057 1.71472 22.5399 1.22803C22.0228 0.710921 21.3232 0.406738 20.5627 0.406738H11.4373C10.6464 0.406738 9.94677 0.710921 9.42966 1.22803C8.91255 1.74514 8.60837 2.44476 8.60837 3.20522V3.26606H3.58935C2.58555 3.26606 1.70342 3.66149 1.06464 4.3307C0.425856 4.96948 0 5.88203 0 6.85541V22.0037C0 23.0075 0.395437 23.8896 1.06464 24.5284C1.70342 25.1672 2.61597 25.5931 3.58935 25.5931H28.4106C29.4145 25.5931 30.2966 25.1976 30.9354 24.5284C31.5741 23.8896 32 22.9771 32 22.0037V6.85541C32 5.85161 31.6046 4.96948 30.9354 4.3307ZM30.4183 22.0037H30.3878C30.3878 22.5512 30.1749 23.0379 29.8099 23.403C29.4449 23.768 28.9582 23.9809 28.4106 23.9809H3.58935C3.04183 23.9809 2.55513 23.768 2.19011 23.403C1.8251 23.0379 1.61217 22.5512 1.61217 22.0037V6.85541C1.61217 6.30788 1.8251 5.82119 2.19011 5.45617C2.55513 5.09115 3.04183 4.87822 3.58935 4.87822H9.46008C9.91635 4.87822 10.2814 4.51321 10.2814 4.05693V3.1748C10.2814 2.8402 10.403 2.53602 10.616 2.32309C10.8289 2.11016 11.1331 1.98849 11.4677 1.98849H20.5627C20.8973 1.98849 21.2015 2.11016 21.4144 2.32309C21.6274 2.53602 21.7491 2.8402 21.7491 3.1748V4.05693C21.7491 4.51321 22.1141 4.87822 22.5703 4.87822H28.4411C28.9886 4.87822 29.4753 5.09115 29.8403 5.45617C30.2053 5.82119 30.4183 6.30788 30.4183 6.85541V22.0037Z" fill="#4068CF" />
      <path d="M10.2909 2V5H2V23.5H30.5V5H22V2H10.2909Z" fill="#4068CF" stroke="#4068CF" />
      <path d="M16 21C19.866 21 23 17.866 23 14C23 10.134 19.866 7 16 7C12.134 7 9 10.134 9 14C9 17.866 12.134 21 16 21Z" fill="white" />
      <text x="50%" y="58%" dominantBaseline="middle" textAnchor="middle" fill='#4068CF' fontSize='11px'>{count}</text>
    </svg>
    :
    <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.9354 4.3307C30.2966 3.66149 29.4145 3.26606 28.4106 3.26606H23.3612V3.20522C23.3612 2.44476 23.057 1.71472 22.5399 1.22803C22.0228 0.710921 21.3232 0.406738 20.5627 0.406738H11.4373C10.6464 0.406738 9.94677 0.710921 9.42966 1.22803C8.91255 1.74514 8.60837 2.44476 8.60837 3.20522V3.26606H3.58935C2.58555 3.26606 1.70342 3.66149 1.06464 4.3307C0.425856 4.96948 0 5.88203 0 6.85541V22.0037C0 23.0075 0.395437 23.8896 1.06464 24.5284C1.70342 25.1672 2.61597 25.5931 3.58935 25.5931H28.4106C29.4145 25.5931 30.2966 25.1976 30.9354 24.5284C31.5741 23.8896 32 22.9771 32 22.0037V6.85541C32 5.85161 31.6046 4.96948 30.9354 4.3307ZM30.4183 22.0037H30.3878C30.3878 22.5512 30.1749 23.0379 29.8099 23.403C29.4449 23.768 28.9582 23.9809 28.4106 23.9809H3.58935C3.04183 23.9809 2.55513 23.768 2.19011 23.403C1.8251 23.0379 1.61217 22.5512 1.61217 22.0037V6.85541C1.61217 6.30788 1.8251 5.82119 2.19011 5.45617C2.55513 5.09115 3.04183 4.87822 3.58935 4.87822H9.46008C9.91635 4.87822 10.2814 4.51321 10.2814 4.05693V3.1748C10.2814 2.8402 10.403 2.53602 10.616 2.32309C10.8289 2.11016 11.1331 1.98849 11.4677 1.98849H20.5627C20.8973 1.98849 21.2015 2.11016 21.4144 2.32309C21.6274 2.53602 21.7491 2.8402 21.7491 3.1748V4.05693C21.7491 4.51321 22.1141 4.87822 22.5703 4.87822H28.4411C28.9886 4.87822 29.4753 5.09115 29.8403 5.45617C30.2053 5.82119 30.4183 6.30788 30.4183 6.85541V22.0037Z" fill="#B4B4B4" />
      <path d="M10.2909 2V5H2V23.5H30.5V5H22V2H10.2909Z" fill="#B4B4B4" stroke="#B4B4B4" />
      <path d="M16 21C19.866 21 23 17.866 23 14C23 10.134 19.866 7 16 7C12.134 7 9 10.134 9 14C9 17.866 12.134 21 16 21Z" fill="white" />
      <path d="M13.5457 13.51V14.6H15.4357V16.56H16.5557V14.6H18.4557V13.51H16.5557V11.56H15.4357V13.51H13.5457Z" fill="#B4B4B4" />
    </svg>;
}

export default Image;