import React from 'react'
import { useTranslations } from '../../utils/hooks'
import ToggleWithLabel from './../../components/ToggleWithLabel/ToggleWithLabel'

const CompanyRPMTab = ({ companySettings, updateCompanySettings }) => {
  const [t] = useTranslations()
  return (
    <ToggleWithLabel 
      titleLabel={t('CompanyRPMTab.disableRPM.title')} 
      titleDescriptions={[t('CompanyRPMTab.disableRPM.description')]} 
      ids={['disableRPM']}
      getObject={companySettings}
      getKeys={['rpmSettings.disableRPM']}
      updateFunction={updateCompanySettings} 
    />
  )
}

export default CompanyRPMTab