import { useState } from 'react';
import Cookies from 'universal-cookie';
import { getCookieOptions } from '../utils/utils';

/**
 * Name for the auth token cookie.
 * (!)
 *   Changing this at any time will
 *   invalidate all previous sessions.
 * (!)
 */
const AUTH_TOKEN_COOKIE_NAME = 'auth';

/**
 * Saves an auth token in "cold" storage (cookie) and keeps a
 * "hot" reference available (hook state).
 *
 * To delete the cookie and purge the state, omit the argument
 * passed to the `set` function or pass `null`, `false`,
 * or any "falsy" value.
 *
 * @example
 * ```js
 * const [authToken, setAuthToken] = useAuth();
 * setAuthToken('asdasdasd'); // Updates authToken and stored a cookie
 * setAuthToken(); // Removes the cookie and authToken is now undefined
 * ```
 */
const useAuth = () => {
  const cookies = new Cookies();
  const defaultCookieOptions = getCookieOptions();
  const [authToken, setAuthToken] = useState(cookies.get(AUTH_TOKEN_COOKIE_NAME));

  const updateCookie = (newAuthTokenValue, extraOpts = {}) => {
    const mergedCookieOptions = Object.assign({}, defaultCookieOptions, extraOpts);

    if (newAuthTokenValue) {
      cookies.set(AUTH_TOKEN_COOKIE_NAME, newAuthTokenValue, mergedCookieOptions);
    } else {
      cookies.remove(AUTH_TOKEN_COOKIE_NAME, {
        path: mergedCookieOptions.path,
        domain: mergedCookieOptions.domain
      });
    }

    setAuthToken(newAuthTokenValue);
  };

  return [authToken, updateCookie];
};

export default useAuth;
