import React from 'react';

const HomeMajor = () => {
  return (
    <svg width="21.33" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7778 6.95711V18.3333C18.7778 19.2678 18.0316 20 17.1111 20H14.8889C13.9684 20 13.2222 19.2678 13.2222 18.3333V13.3333H6.55556V18.3081C6.55556 19.2425 5.80936 20 4.88889 20H2.66667C1.74619 20 1 19.2425 1 18.3081V6.95711C1 6.4254 1.2537 5.9256 1.6829 5.61175L9.00556 0.257178C9.54601 -0.0857256 10.2318 -0.0857261 10.7722 0.257177L18.0949 5.61175C18.5241 5.9256 18.7778 6.4254 18.7778 6.95711Z" fill="currentColor" />
    </svg>
  );
}

export default HomeMajor;