import React from 'react';

const Remove = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.800003 0.799805L4 3.9998M4 3.9998L7.2 0.799805M4 3.9998L7.2 7.1998M4 3.9998L0.800003 7.1998" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  )
}

export default Remove;