import { useEffect, useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useSearchParams from './useSearchParams'
import useEffectOnce from './useEffectOnce'
import useNavigateWithParams from './useNavigateWithParams'
import { PROPERTY_SEARCH_PARAM_KEY } from '../constants'
import { INSPECTION_INSTANCES_FILTER_TYPE } from '../constants'
import PropTypes from 'prop-types'

//Use query params in the context of inspect. This is used to manage query params 
//in the workOrder table. This could also be applied to dashboard if things were refactored
//to the resuable component format that the work order table follows. Although
//right now this logic is found in dashboard
const useInspectQueryParams = ({
  propertySelected,
  filter,
  setFilter,
  setPropertySelected,
  isAdmin,
  hide = true,
  route,
  companySlug
}) => {
  const { section } = useParams()
  const query = useSearchParams()
  const navigate = useNavigate()
  const navigateWithQueryParams = useNavigateWithParams()

  const propertyQueryParamValue = useMemo(() => query?.get(PROPERTY_SEARCH_PARAM_KEY), [query])
  /**
   * This effect observes for changes in the dashboard filters (like switching tabs or filtering by property)
   * and updates the URL accordingly.
   */
  useEffect(() => {
    if (hide) return
    const isDashboardChangingToNewTab = filter && filter !== section
    const isSelectedPropertyDifferentThanBefore = propertyQueryParamValue !== propertySelected

    // Nothing happened, basically - Don't bother disturbing the URL state
    if (!isDashboardChangingToNewTab && !isSelectedPropertyDifferentThanBefore) {
      return
    }

    const targetRoute = `/${companySlug}/${route}/${filter}`

    if (propertySelected) {
      navigateWithQueryParams(targetRoute, {
        [PROPERTY_SEARCH_PARAM_KEY]: propertySelected
      })
      return
    }

    navigate(targetRoute, {replace: true})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, propertyQueryParamValue, propertySelected, section, route, companySlug, hide])

  /**
   * Updates the internal `filter` state based on the
   * `section` (React Router param defined in `app.js`) or, if no section
   * has been stored in the URL previouly, takes the default filter value
   * based on the user's `isAdmin` status.
   * This is a memoized function so that we can pass it to `useEffectOnce`
   * to ensure this logic will run onlysection once, as soon as the app renders for the
   * first time (initial state setup based on the route param). This is useful to
   * preserve the selected dashboard tab across hard refreshes or link sharing.
   */
  const memoizedUpdateStateBasedOnSectionUrlParam = useCallback(() => {
    if (hide) return
    const defaultFilter = isAdmin
      ? INSPECTION_INSTANCES_FILTER_TYPE.ALL
      : INSPECTION_INSTANCES_FILTER_TYPE.ASSIGNED
    const isSectionUrlParamValid = Object.values(INSPECTION_INSTANCES_FILTER_TYPE).some(allowedTypes => allowedTypes === section)
    const targetFilter = isSectionUrlParamValid ? section : defaultFilter

    setFilter(targetFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, section, setFilter])

  /**
   * Update the internal state with the route param only on the first render/page load.
   */
  useEffectOnce(memoizedUpdateStateBasedOnSectionUrlParam)

  /**
   * Updates the internal `propertySelected` state if there's a
   * `propertyQueryParamValue` (query param) and `propertySelected` hasn't
   * been assigned yet. In other words, grabs a fresh ID from the URL
   * and keep the state up-to-date.
   * This is a memoized function so that we can pass it to `useEffectOnce`
   * to ensure this logic will run only once, as soon as the app renders for the
   * first time (initial state setup based on the query param). This is useful to
   * preserve the selected property across hard refreshes or link sharing.
   */
  const memoizedUpdateStateBasedOnPropertyQueryParam = useCallback(() => {
    if (propertySelected || hide) {
      return
    }

    if (propertyQueryParamValue) {
      setPropertySelected(propertyQueryParamValue)
    }
  }, [propertySelected, hide, propertyQueryParamValue, setPropertySelected])

  /**
   * Update the internal state with the query param only on the first render/page load.
   */
  useEffectOnce(memoizedUpdateStateBasedOnPropertyQueryParam)

  useEffectOnce(() => {
    if (hide) setFilter(isAdmin ? INSPECTION_INSTANCES_FILTER_TYPE.ALL : INSPECTION_INSTANCES_FILTER_TYPE.ASSIGNED)
  })
}

useInspectQueryParams.propTypes = {
  propertySelected: PropTypes.string,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  setPropertySelected: PropTypes.func,
  isAdmin: PropTypes.bool,
  hide: PropTypes.bool,
  route: PropTypes.string,
  companySlug: PropTypes.string
}

useInspectQueryParams.defaultProps = {
  hide: true
}

export default useInspectQueryParams