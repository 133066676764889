import { INSPECTION_STATUS, INSPECT_TYPE } from '../utils/constants'
import { WORK_ORDER_STATUS } from '@project/components'

const STATUS_COLORS_BY_INSPECTION_TYPE = {
    [INSPECT_TYPE.WORK_ORDER]: {
      [WORK_ORDER_STATUS.VENDOR_ASSIGNED]: 'rgba(15, 163, 177, 0.3)',
      [WORK_ORDER_STATUS.PAUSED]: 'rgba(15, 163, 177, 0.3)',
      [WORK_ORDER_STATUS.CANCELLED]: 'rgba(255, 89, 94, 0.3)',
      [WORK_ORDER_STATUS.WORK_IN_PROGRESS]: 'rgba(255, 202, 58, 0.4)',
      [WORK_ORDER_STATUS.SCHEDULED]: 'rgba(255, 202, 58, 0.4)',
      [WORK_ORDER_STATUS.CREATED]: 'rgba(51, 190, 109, 0.4)',
      [WORK_ORDER_STATUS.ASSIGNED]: 'rgba(51, 190, 109, 0.4)',
      [WORK_ORDER_STATUS.PENDING_APPROVAL]: 'rgba(125, 122, 188, 0.3)',
      [WORK_ORDER_STATUS.APPROVED]: 'rgba(131, 56, 236, 0.3)'
    },
    [INSPECT_TYPE.INSPECTION]: {
      [INSPECTION_STATUS.PAUSED]: 'rgba(15, 163, 177, 0.3)',
      [INSPECTION_STATUS.DENIED]: 'rgba(255, 89, 94, 0.3)',
      [INSPECTION_STATUS.IN_PROGRESS]: 'rgba(255, 202, 58, 0.4)',
      [INSPECTION_STATUS.OPEN]: 'rgba(51, 190, 109, 0.4)',
      [INSPECTION_STATUS.COMPLETE]: 'rgba(15, 163, 177, 0.3)',
      [INSPECTION_STATUS.APPROVED]: 'rgba(131, 56, 236, 0.3)',
    }
  }

  export default STATUS_COLORS_BY_INSPECTION_TYPE