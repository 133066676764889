import React from 'react';
import Dot from '../assets/images/dot';
import styles from './StatusLegend.module.css';

const StatusLegend = ({statuses = [], className, mobileView}) => {
  let statusLegends = statuses.map((status, i) => {
    return <div 
      key={status.label}
      style={{width: mobileView ? 'w-100' : `${(1/statuses.length)*100}%`, color: status.color, borderRight: i === statuses.length - 1 || mobileView ? 'none' : undefined, borderBottom: i === statuses.length - 1 || !mobileView ? 'none' : undefined}} 
      className={`d-flex justify-content-center align-items-center ${styles.status}`}>
        <div className="d-flex align-items-center justify-content-center"><div className="mr-2 d-flex align-items-center"><Dot /></div><div>{status.label}</div></div>
      </div>;
  })
  return <div className={`${className} ${mobileView ? '' : 'd-flex'} ${styles.statusLegend}`}>
    {statusLegends}
  </div>
}

export default StatusLegend;