import React from 'react';
import { useIntl } from 'react-intl';
import AdminSelect from '../../components/AdminSelect/AdminSelect';
import styles from './StringTimeSelector.module.css';
import Calendar from './../../assets/images/Calendar.svg';

const StringTimeSelector = ({ selectedTime, setSelectedTime, loading, timeOption, ...props }) => {

  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const pastTimeOptions = [
    {
      label: t('Amp.monthToDate'),
      value: "mtd"
    },
    {
      label: t('Amp.yearToDate'),
      value: "ytd"
    },
    {
      label: t('Amp.prevMonth'),
      value: "prev1"
    },
    {
      label: t('Amp.prev3Month'),
      value: "prev3"
    },
    {
      label: t('Amp.prev6Month'),
      value: "prev6"
    },
    {
      label: t('Amp.prev2Years'),
      value: "last2year"
    }
  ];

  const futureTimeOptions = [
    {
      label: t('Amp.1Week'),
      value: "plus1w"
    },
    {
      label: t('Amp.2Week'),
      value: "plus2w"
    },
    {
      label: t('Amp.3Week'),
      value: "plus3w"
    },
    {
      label: t('Amp.1Month'),
      value: "plus1m"
    },
    {
      label: t('Amp.3Month'),
      value: "plus3m"
    },
    {
      label: t('Amp.6Month'),
      value: "plus6m"
    },
    {
      label: t('Amp.1Year'),
      value: "plus1y"
    }
  ];

  const timeOptions = timeOption === 'future' ? futureTimeOptions : pastTimeOptions;

  const component = <div className={styles.dropdownArrow}><img src={Calendar} alt='select options' /></div>;

  return (
    <AdminSelect
      id="timePeriod"
      customDropdownComponent={component}
      reverseDropdownIcon={true}
      options={timeOptions}
      value={selectedTime}
      onChange={(e) => setSelectedTime(e)}
      isLoading={loading}
      {...props}
    />
  )

};

export default StringTimeSelector;
