import { useEffect } from "react"

export const useDelete = ({
  axiosInstance, axiosCancelTokenFactory, deleteRoute, fileToDelete, mediaCache, onDelete, onUpdate,
  setIsDeleting, setErrorMessage, setFileToDelete, setMediaCache
}) => {
  /** Delete handler */
  useEffect(() => {
    const source = axiosCancelTokenFactory.source();

    const deleteFile = async () => {
      setIsDeleting(true);
      setErrorMessage(null);

      try {
        await axiosInstance.delete(`${deleteRoute}/${fileToDelete.id}`, {
          cancelToken: source.token
        });
        setFileToDelete(null);
        const updatedMedia = mediaCache.filter(file => file.id !== fileToDelete.id);

        setMediaCache(updatedMedia);
        onUpdate(updatedMedia);
        onDelete(fileToDelete);
      } catch (e) {
        setErrorMessage(e.message);
        setFileToDelete(null);
      } finally {
        setIsDeleting(false);
      }
    };

    if (fileToDelete) {
      deleteFile();
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToDelete]);
}
