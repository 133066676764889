import React, {useState} from 'react';
import styles from './ReportCard.module.css';
import { IoMdInformationCircle } from 'react-icons/io';
import { useIntl } from 'react-intl';

const ReportCard = ({children, mobileView, details, click}) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  const [showInfo, setShowInfo] = useState(false);

  return <div className={`${styles.reportCardContainer}`}>
    <div className={`${styles.header} w-100 d-flex justify-content-between align-items-center`}>
      <div className="d-flex justify-content-center align-items-center position-relative">
        <div className="mr-2">Asset Managment</div>
        <div className={styles.infoIcon} onMouseOver={() => setShowInfo(true)} onMouseOut={() => setShowInfo(false)}>
          <IoMdInformationCircle />
          {!mobileView && details && showInfo &&
            <div className={styles.infoPopup}>
              {details}
            </div>}
        </div>
        {mobileView && details && showInfo &&
          <div className={styles.infoPopup}>
            {details}
          </div>}
      </div>
      <div className={styles.viewReportButton} onClick={click}>
        {t('ReportCard.viewReport')}
      </div>
    </div>
    <div className={styles.reportData}>
      {children}
    </div>
  </div>
}

export default ReportCard;