import { v4 as uuidv4 } from 'uuid';
import { formatBytes } from '../utils/strings';

export default class FileListFile {
  constructor({
    id,
    name,
    size,
    uploadDate,
    uploadUser,
    url,
  } = {}) {
    this.id = id || uuidv4();
    this.name = name;
    this.size = size;
    this.uploadDate = uploadDate;
    this.uploadUser = uploadUser;
    this.url = url;
  }

  get humanizedSize() {
    return formatBytes(this.size, 2);
  }

  get extension() {
    return this.name.split('.').pop();
  }
}