import { useContext } from 'react';
import { withAuth } from '@project/components';
import { RootContext } from '../../hoc/RootContext/RootContext';
import { useEffect } from 'react'

const PrivateRoute = ({
  authToken,
  children
}) => {
  const { setAuthToken } = useContext(RootContext);

  useEffect(() => {
    setAuthToken(authToken);
  }, [authToken, setAuthToken]);

  return children;
};

export default withAuth(PrivateRoute, {
  kickToInternalUrl: '/login'
});
