import { FEATURES } from "@project/components/src/FeatureFlags/Features";

/**
 * This module configures a very basic feature flag system for IDCloud's frontend.
 * Each feature is listed on the `FEATURES` constant, and then configured by environment
 * using `feature enablers`, such as `useCompanyIDPlansFeatureEnabler`.
 */

/**
 * @type {FeatureFlagConfiguration}
 */
export default {
  [FEATURES.IDINSPECT_VENDOR_FLOW]: {
    development: true,
    staging: true,
    production: true
  }
}