import React from 'react';

const SettingsBold = () => {
  return (
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0942919 10.0691L1.50137 12.431C1.6691 12.7124 2.01848 12.84 2.3361 12.7359L4.40658 12.0571C4.58954 12.174 4.77927 12.2804 4.97452 12.3755L5.40303 14.4519C5.46878 14.7706 5.75756 15 6.09292 15H8.90708C9.24244 15 9.53122 14.7706 9.59697 14.4519L10.0255 12.3755C10.2207 12.2804 10.4104 12.174 10.5934 12.0571L12.6639 12.7359C12.9815 12.84 13.3309 12.7125 13.4986 12.431L14.9057 10.069C15.0734 9.78759 15.0128 9.43055 14.7609 9.216L13.1188 7.8175C13.1251 7.71155 13.1283 7.6055 13.1283 7.5C13.1283 7.39445 13.1251 7.28841 13.1188 7.18246L14.7608 5.784C15.0127 5.56945 15.0733 5.21241 14.9056 4.93095L13.4985 2.56905C13.3309 2.28759 12.9814 2.15991 12.6638 2.26409L10.5934 2.94291C10.4105 2.826 10.2207 2.71964 10.0255 2.62445L9.59697 0.548091C9.53122 0.229409 9.24244 0 8.90708 0H6.09292C5.75756 0 5.46878 0.229409 5.40303 0.548091L4.97452 2.62445C4.77927 2.71964 4.58959 2.82605 4.40662 2.94291L2.3361 2.26409C2.01857 2.16 1.6691 2.28759 1.50137 2.56905L0.0942919 4.93095C-0.0733854 5.21241 -0.012787 5.56945 0.239128 5.784L1.8811 7.18246C1.87481 7.28827 1.87167 7.39432 1.87167 7.5C1.87167 7.60568 1.87481 7.71177 1.8811 7.81755L0.239128 9.216C-0.012787 9.43055 -0.0733854 9.78759 0.0942919 10.0691ZM7.5 4.77273C9.05173 4.77273 10.3142 5.99618 10.3142 7.5C10.3142 9.00382 9.05173 10.2273 7.5 10.2273C5.94827 10.2273 4.68584 9.00382 4.68584 7.5C4.68584 5.99618 5.94827 4.77273 7.5 4.77273Z" fill="currentColor" />
    </svg>
  );
}

export default SettingsBold;