import React from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from '../../utils/hooks'
import ButtonWithDescTwoColumn from '../ButtonWithDescTwoColumn/ButtonWithDescTwoColumn'
import styles from './SettingsTab.module.css'

/**
 * Component that renders the basic structure and funcionality of our tabs
 * that hold and save company settings.
 * It includes a big title, a description, and a Save button.
 *
 * @example
 * ```jsx
 * const handleSaveButtonClick = () => {
 *   // do something...
 * }
 *
 * <Tab eventKey="something" title="Something Something">
 *   <SettingsTab
 *     description="Lorem Ipsum settings settings..."
 *     onSaveButtonClick={handleSaveButtonClick}
 *     title="Something Something"
 *   >
 *     <h2>Setting setting:</h2>
 *     <p>Lorem ipsum</p>
 *     <AdminCheckbox checked={true} />
 *   </SettingsTab>
 * </Tab>
 * ```
 */
const SettingsTab = ({
  children,
  description,
  onSaveButtonClick,
  title
}) => {
  const [t] = useTranslations()

  return (
    <>
      {!!onSaveButtonClick && (
        <div className={styles.Save}>
          <ButtonWithDescTwoColumn
            onClick={onSaveButtonClick}
            buttonDescription={description}
            buttonText={t('Settings.Save')}
          />
        </div>
      )}
      <h4 className='is-main-heading pb-3'>{t('Settings.Tab.Title', { title })}</h4>
      {children}
    </>
  )
}

SettingsTab.propTypes = {
  /** Inner content. */
  children: PropTypes.node.isRequired,
  /** A general description for this tab's content. */
  description: PropTypes.string,
  /** To be called when the main save button is clicked. */
  onSaveButtonClick: PropTypes.func,
  /** Main title for this tab. */
  title: PropTypes.string.isRequired
}

SettingsTab.defaultProps = {
  description: ''
}

export default SettingsTab
