/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Table } from "@project/components"
import WorkOrders from '../../components/WorkOrders/WorkOrders'
import { RootContext } from '../../hoc/RootContext/RootContext'
import DashboardFilters from './../../components/DashboardFilters/DashboardFilters'
import { useIntl } from 'react-intl'
import DefaultLeasingImage from '../../assets/images/unknown-unit.png'
import { get } from '../../utils/utils'
import { getImageUrl } from '@project/components/src/utils/image-location-utils'
import permissions from './permissions.js'
import styles from './Dashboard.module.css'
import { useLeaseRadarData, useAmpReportData } from './hooks.js'
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer'
import InfoCard from '../../components/InfoCard/InfoCard'
import infoCardsSchema from './infoCardsSchema'
import DataCards from '../../components/DataCards/DataCards'
import Alertables from '../../components/Alertables/Alertables'
import SpaceProfileStatusTracker from '../../components/SpaceProfileStatusTracker/SpaceProfileStatusTracker'
import Inspections from '../../components/Inspections/Inspections'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import SubscriptionsTracker from '../../components/SubscriptionsTracker/SubscriptionsTracker'
import { useCompanySettings } from '../../utils/hooks'

const Dashboard = () => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({ id })

  const { globalProperties, currentProperty, currentUser, mobileView, currentCompany, largeView, smallMobileView, xLargeView, isLoadingGlobalProperties } = useContext(RootContext)
  const userType = get(currentUser, 'type')

  const [isLoading, setIsLoading] = useState(true)
  const [dashboardData, setDashboardData] = useState([])
  const [userProperties, setUserProperties] = useState([])
  const [, setErrors] = useState([])
  const [leaseRadarLoading, setLeaseRadarLoading] = useState(true)
  const [reportsBody, setReportsBody] = useState([])
  const [isAmpReportLoading, setIsAmpReportLoading] = useState(true)
  const [isUserPropertiesLoading, setIsUserPropertiesLoading] = useState(true)

  const companyName = useMemo(() => (
    get(currentCompany, 'value')
  ), [currentCompany])

  const propertyName = useMemo(() => (
    get(currentProperty, 'name')
  ), [currentProperty])

  const [companySettings,, companySettingsErrors] = useCompanySettings(companyName, true)
  let leaseRadarData = useLeaseRadarData({ setErrors, currentCompany, currentUser, currentProperty, setLoading: setLeaseRadarLoading })
  let ampReportData = useAmpReportData({
    setErrors,
    companies: currentCompany,
    currentCompany: currentCompany,
    currentUser,
    property: currentProperty,
    setLoading: setIsAmpReportLoading,
    reportsBody
  })

  useEffect(() => (
    setErrors([companySettingsErrors])
  ), [companySettingsErrors])

  useEffect(() => {
    if (dashboardData.length) {
      const fetchData = async (dashboardData) => await getReportBody(dashboardData)
      fetchData(dashboardData)
    }
  }, [dashboardData])

  useEffect(() => {
    if (permissions.myProperties.includes(userType) || permissions.leaseRadar.includes(userType)) {

      const userProperties = globalProperties.filter(_ => _.guid).map(property => {
        return {
          name: property.name,
          companyDisplayName: property.company_display_name,
          company: property.company_name,
          companySlug: property.companySlug,
          leasing_image: getImageUrl(property, ['leasing_image.thumbnail.S', 'leasing_image'], DefaultLeasingImage, 'leasing_image'),
          guid: property.guid,
          address1: property.address1,
          state: property.state,
          city: property.city
        }
      })
      setIsUserPropertiesLoading(false)
      setUserProperties(userProperties)
    }
  }, [globalProperties])

  /**
   * Function to fetch the dashboard data
   * @param {Array} list The property list
   */
  const getReportBody = async (list) => {
    let body
    if (list.length === 1) {
      if (list[0].name === 'All') return
      body = list.map(_ => ({ "company": _.company_name, "property": _.name }))
    } else {
      // retain only unique company names
      body = [...new Map(list.map(item => [item.company_name, item.company_name])).values()]
      body = body.map(_ => ({ "company": _ }))
    }
    setReportsBody(body)
    setIsLoading(false)
  }

  let infoCards = infoCardsSchema.map(_ => _.status === 'active' ? <div className="mb-3" key={_.title}><InfoCard info={_} /></div> : null)

  let leaseRadarCards
  let ampReportCards

  if (permissions.leaseRadar.includes(userType)) {
    let leaseStatus = ['occupied', 'vacant', 'expiring']
    let leaseRadarIconStyles = {
      'occupied': styles.leaseRadarIconOccupied,
      'vacant': styles.leaseRadarIconVacant,
      'expiring': styles.leaseRadarIconExpiring
    }

    leaseRadarCards = <DataCards
      title={t('LeaseRadar.title')}
      dataCollection={leaseRadarData}
      dataStyles={leaseRadarIconStyles}
      status={leaseStatus}
      mobileView={mobileView}
      isLoading={leaseRadarLoading}
      isDataLoading={leaseRadarLoading} />
  }

  if (permissions.ampReports.includes(userType)) {
    let ampReportKeys = ['cost', 'revenue']
    let ampReportIconStyles = {
      'cost': styles.ampSavingsIcon,
      'revenue': styles.ampRevenueIcon
    }

    ampReportCards = <DataCards
      title={t('Dashboard.amp.title')}
      details={t('Dashboard.amp.details')}
      dataCollection={ampReportData}
      dataStyles={ampReportIconStyles}
      status={ampReportKeys}
      mobileView={mobileView}
      isLoading={isLoading}
      isDataLoading={isAmpReportLoading} />
  }

  // Controls whether to show or hide the inspections card
  const hasInspectionPermissions = useMemo(() => (
    get(companySettings, 'inspections.enabled')
  ), [companySettings])

  const isPropertiesLoading = isLoadingGlobalProperties || isUserPropertiesLoading

  return (
    <PageContentContainer>
      <div className={`${styles.infoCards} d-flex flex-wrap w-100`}>{infoCards}</div>
      <div className={styles.filtersContainer}>
        <div className="d-flex justify-content-between w-100 pt-3 ">
          <div className={styles.filtersTitle}>Dashboard View</div>
        </div>
        <DashboardFilters setDashboardData={setDashboardData} show={true} propertySelectAll={true} hidePropertySelect={userType === 'VENDOR'} />
      </div>
      {permissions.leaseRadar.includes(userType) ?
        leaseRadarCards
        : null}
      {permissions.ampReports.includes(userType) && ampReportData ?
        ampReportCards
        : null}
      {permissions.myProperties.includes(userType) && (
        <DashboardCard
          title={t('Dashboard.myProperties.label')}
        >
          <Table
            idField={'guid'}
            searchFilters={['name', 'company']}
            sortFields={['name', 'company', 'address1']}
            placeholder={t('Dashboard.myProperties.placeholder')}
            fields={[
              { id: 'name', label: t('term.property'), type: 'details', options: { photo: { key: 'leasing_image', objectFit: 'cover' }, style: styles.propertyCell } },
              { id: 'companyDisplayName', label: t('term.company'), type: 'details' },
              { id: 'address1', label: t('term.location'), type: 'details', options: { concatValues: { keys: ['city', 'state'], separator: ', ' } } },
              { id: 'rpmLink', buttonLabel: t('ui.view'), type: 'button', click: () => { }, options: { buttonType: 'underline', color: '#D41E15', link: _ => `${process.env.REACT_APP_RPM_HOST}/${_.companySlug}/${_.name}/index` } }
            ]}
            itemsPerPage={5}
            items={userProperties.filter(_ => (_.company === currentCompany.value) || !currentCompany)}
            showFields={(smallMobileView && ['name', 'rpmLink']) || (mobileView && ['name', 'company', 'rpmLink'])}
            customWidths={(smallMobileView && ['100%']) || (mobileView && ['50%', '50%']) || ['33%', '33%', '33%']}
            isLoading={isPropertiesLoading}
            customStyle={styles.table}
            detailsDropdown={(smallMobileView && ['company', 'address1']) || (mobileView && ['address1'])}
          />
        </DashboardCard>
      )}
      {permissions.subscriptionsTracker.includes(userType) &&
        <SubscriptionsTracker
          currentCompany={currentCompany}
          currentProperty={currentProperty}
          view={({smallMobileView, mobileView, largeView})}
        />
      }
      {permissions.recentProfiles.includes(userType) &&
        <SpaceProfileStatusTracker
          currentCompany={companyName}
          currentProperty={propertyName}
          currentUser={currentUser}
          view={({smallMobileView, mobileView, largeView})}
        />
      }
      {permissions.dashboardData.includes(userType) ?
        <Alertables
          user={currentUser}
          property={currentProperty || null}
          company={currentCompany}
          view={{smallMobileView, mobileView, xLargeView}}
          setErrors={setErrors}
        />
        : null}
      {hasInspectionPermissions &&
        <>
          <Inspections
            setErrors={setErrors}
          />
          <WorkOrders
            setErrors={setErrors}
            currentCompany={currentCompany}
            currentProperty={currentProperty}
            companySettings={companySettings}
            currentUser={currentUser}
          />
        </>
      }
    </PageContentContainer>
  )
}

export default Dashboard
