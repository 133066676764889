import {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef
} from 'react';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';
import { RootContext } from '../hoc/RootContext/RootContext';
import axios, {isCancel} from './axios';
import { CancelToken } from 'axios'
import { useIntl } from 'react-intl'
import { ACL, INSPECTION_INSTANCES_FILTER_TYPE } from '../constants/inspections';
import { getCookieOptions, parseBooleanString, toastError } from './utils'
import { intl as intlUtil } from './intl';
import { get } from './utils';

const t = (id) => intlUtil.formatMessage({ id });

const useInspectionInstances = ({
  setIsLoading = () => {},
  redirectOnFail,
  type,
  isAdmin
}) => {
  const { currentCompany, currentProperty, currentUser } = useContext(RootContext)
  const navigate = useNavigate();
  const [inspectionInstances, setInspectionInstances] = useState([]);
  const isMountedRef = useRef(true);
  const companyGuid = get(currentCompany, 'guid');
  const propertyGuid = get(currentProperty, 'guid');

  useEffect(() => {
    const source = CancelToken.source();

    const fetchInspectionInstances = async () => {
      const params = new URLSearchParams();
      params.set('companyGuid', companyGuid);

      // `null` OR `"All"` if "All" is selected, full object otherwise
      if (currentProperty && currentProperty !== 'All') {
        // This only applies if filtering by an specific property
        params.set('propertyGuid', propertyGuid);
      }

      if (type !== INSPECTION_INSTANCES_FILTER_TYPE.ALL || !isAdmin) {
        if (!(type === INSPECTION_INSTANCES_FILTER_TYPE.COMPLETED && isAdmin)) {
          params.set('username', currentUser.username);
        }
        params.set('type', type);
      }

      try {
        setIsLoading(true);

        const result = await axios.get(`/inspect/instances?${params.toString()}`, {
          cancelToken: source.token,
          headers: {
            [ACL.HTTP_HEADERS.IDP_COMPANY]: currentCompany?.name
          }
        });

        if (get(result, 'data.success')) {
          setInspectionInstances(get(result, 'data.records', []));
        } else {
          toastError(t('hooks.useInspectionInstances.error'));
          if (redirectOnFail) navigate(redirectOnFail);
        }
      } catch (e) {

        if (isCancel(e)) {
          // request is cancelled
        } else {
          if (isMountedRef.current) {
            toastError(t('hooks.useInspectionInstances.error'));
            if (redirectOnFail) navigate(redirectOnFail);
          }

        }
      } finally {
        setIsLoading(false);
      }
    }
    if (companyGuid && currentUser && (type === 'all' ? isAdmin : true)) fetchInspectionInstances();

    return () => {
      isMountedRef.current = false;
      source.cancel();
    }
  }, [companyGuid, currentCompany, currentProperty, currentUser, isAdmin, navigate, propertyGuid, redirectOnFail, setIsLoading, type])

  return [inspectionInstances];
}

const useUserActions = (company, property, action, setErrors, ignoreError = false) => {
  const [userHasAction, setUserHasAction] = useState(false);

  useEffect(() => {
    async function checkUserCompanyAction() {

      const result = await axios.get(`/role?company=${encodeURIComponent(company)}`);

      if (result.status === 200) {
        const data = result.data.record;
        if (data.actions.includes(action)) {
          setErrors(null);
          setUserHasAction(true);
        } else {
          if (ignoreError) setErrors(null)
          else setErrors("You are not authorized to edit this company's properties");
          setUserHasAction(false);
        }
      } else {
        setErrors(`There was an error retrieving the user's available actions: ${result.errors}`);
        setUserHasAction(false);
      }
    };

    async function checkUserPropertyAction() {
      const result = await axios.get(`/role?company=${encodeURIComponent(company)}&property=${encodeURIComponent(property)}`);

      if (result.status === 200) {
        const data = result.data.record;
        if (data.actions.includes(action)) {
          setErrors(null);
          setUserHasAction(true);
        } else {
          setErrors("You are not authorized to edit this property");
          setUserHasAction(false);
        }
      } else {
        setErrors(`There was an error retrieving the user's available actions: ${result.errors}`);
        setUserHasAction(false);
      }
    };

    if (company && action && property === null) checkUserCompanyAction();
    if (company && action && property) checkUserPropertyAction();
  }, [company, property, action, setErrors]);

  return userHasAction;
};

const useCompany = (company, user, setCompany, setErrors) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  useEffect(() => {
    const source = CancelToken.source()
    const fetchCompany = async () => {
      try {
        let result = await axios.get(`/companies/${company}`, { cancelToken: source.token })
        if (result.status === 200) {
          if (!result.data.record.requireMfa) result.data.record.requireMfa = false
          if (!result.data.record.contacts) result.data.record.contacts = {}
          if (!result.data.record.slug) result.data.record.slug = null
          setCompany(result.data.record)
        }
      } catch (e) {
        if (!setErrors) return
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      }
    }

    if (!company || !user) return
    fetchCompany()

    return () => {
      source.cancel()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [company, user, setCompany, setErrors])
};

const useCompanySettings = (company, publicMode = false) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({id});

  const [companyName, setCompanyName] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [settings, setCompanySettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  let targetName;

  if (company && typeof company === 'object' && company.value) {
    targetName = company.value;
  } else if (company && typeof company === 'string') {
    targetName = company;
  }

  if (targetName !== companyName) {
    setCompanyName(targetName);
    setTriggerFetch(true);
  }

  useEffect(() => {
    const fetchSettings = async () => {
      setTriggerFetch(false);
      setIsLoading(true);
      setErrors(null);

      try {
        const result = await axios.get(`${publicMode ? '/public' : ''}/company-settings?name=${companyName}`);

        if (result.status === 200) {
          setCompanySettings(result.data.record);
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setErrors(`${t('error.serverData')} (${e.response.data.errors})`);
        } else {
          setErrors((e && e.message) || t('error.server'));
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && companyName && triggerFetch) {
      fetchSettings();
    }
  }, [companyName, isLoading, triggerFetch]);

  return [settings, isLoading, errors];
};

const useTranslations = () => {
  const intl = useIntl();
  const t = useCallback(
    (id, ...args) => intl.formatMessage({ id }, ...args),
    [intl]
  );
  return [t, intl];
};

const useFilteredProperties = ({filter, properties}) => {
  const [filteredProperties, setFilteredProperties] = useState([])
  useEffect(() => {

    if (properties?.length && filter?.length) {
      const ps = properties.filter(property => {
        const integrationIds = (property.integrations || []).map(integration => integration.integrationId).join('')
        return [property.name, property.displayName, property.address1, property.state, property.market, property.idpRecordId, integrationIds].filter(Boolean).join('').toLowerCase().indexOf(filter.toLowerCase()) >= 0
      });
      setFilteredProperties(ps)
    }
    else {
      setFilteredProperties(properties)
    }

  }, [filter, properties])

  return [filteredProperties]
}

/**
 * Custom hook that stores a `useState` value in a cookie so it can persist page refreshes.
 *
 * @example
 * ```js
 * const [showCaptcha, setShowCaptcha] = usePersistentStateViaCookie('idp-login-captcha', { maxAge: 300 })
 *
 * setShowCaptcha(true)
 * ```
 * @param {string} cookieName Name of the cookie where the internal state will be stored
 * @param {Object} cookieOptionsOverride `universal-cookie` options for `.set`
 * @returns `[cookieState, setCookieState]`
 */
const usePersistentStateViaCookie = (cookieName, cookieOptionsOverride = {}) => {
  const cookies = new Cookies()
  const initialCookieValue = cookies.get(cookieName)
  const cookieOptions = {
    ...getCookieOptions(1),
    ...cookieOptionsOverride
  }

  const [cookieState, setCookieState] = useState(parseBooleanString(initialCookieValue))

  useEffect(() => {
    if (!cookieState) {
      cookies.remove(cookieName, cookieOptions)
      return
    }

    cookies.set(cookieName, cookieState, cookieOptions)
  }, [cookieState])

  return [cookieState, setCookieState]
}

/**
 * Custom hook that fetches images and returns them as an object.
 * @param {Array<string>} imageUrls Array of image URLs to fetch
 * @returns {Object} Object with the images fetched
 */
const useImages = imageUrls => {
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const imageList = {};
      for (const imageUrl of imageUrls) {
        imageList[imageUrl] = await import(`../assets/images/${imageUrl}`);
      }

      setImages(imageList);
    };

    if (imageUrls.length > 0) {
      fetchImages();
    }
  }, [imageUrls]);

  const getImage = useCallback((imageUrl) => {
    return images[imageUrl];
  }, [images]);

  return getImage;
};

export {
  useUserActions,
  useCompany,
  useCompanySettings,
  usePersistentStateViaCookie,
  useInspectionInstances,
  useTranslations,
  useFilteredProperties,
  useImages
};
