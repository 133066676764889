import React, { useMemo } from 'react'
import { AdminSelect } from '@project/components'
import { get } from '../../utils/utils'
import { CUSTOM_WEB_FONTS, CUSTOM_RENDERER_FONTS } from '../../constants/fonts'
import PropTypes from 'prop-types'

const FontSelectorWithPreview = ({ companySettings, updateCompanySettings, settingsAttribute, selectorLabel, defaultValueLabel, previewLabel, fontType }) => {

  const CUSTOM_FONT_OPTIONS = useMemo(() => {
    const defaultFontOption = { label: defaultValueLabel, value: 'Default' }
    const CUSTOM_FONTS = fontType === 'WEB' ? CUSTOM_WEB_FONTS : CUSTOM_RENDERER_FONTS
    const builtInFonts = CUSTOM_FONTS.map(fontName => {
      return { label: fontName, value: fontName }
    })

    return [defaultFontOption, ...builtInFonts]

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [defaultValueLabel])

  let customFont = get(companySettings, settingsAttribute)
  let customFontFamily = customFont
  if (customFontFamily === 'Default') customFontFamily = 'Roboto'
  if (customFontFamily === 'Sans Serif') customFontFamily = 'sans-serif'
  if (customFontFamily === 'Serif') customFontFamily = 'serif'

  return (
    <>
      <AdminSelect label={selectorLabel} defaultValue={{value: 'Default', label: defaultValueLabel}}
        value={CUSTOM_FONT_OPTIONS.find(_ => _.value === get(companySettings, settingsAttribute))}
        onChange={(selectedValue) => updateCompanySettings({target: {value: get(selectedValue, 'value')}}, companySettings, settingsAttribute)} options={CUSTOM_FONT_OPTIONS} />
      <div>
        <label>{previewLabel}</label>
        <div style={{ fontFamily: customFontFamily || 'inherit', fontSize: '24px' }}>{customFont || 'Default'}</div>
      </div>
    </>
  )
}

FontSelectorWithPreview.propTypes = {
  companySettings: PropTypes.object,
  settingsAttribute: PropTypes.string.isRequired,
  updateCompanySettings: PropTypes.func.isRequired,
  selectorLabel: PropTypes.string,
  defaultValueLabel: PropTypes.string,
  previewLabel: PropTypes.string
}

export default FontSelectorWithPreview