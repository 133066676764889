import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';
import {
  MdGridView as GridIcon,
  MdViewCarousel as CarouselIcon,
  MdRotateLeft as RotateLeftIcon,
  MdRotateRight as RotateRightIcon,
} from 'react-icons/md';
import { useIntl } from 'react-intl';
import { Slide } from 'react-slideshow-image';
import ChevronLeft from '../assets/images/chevron-left';
import ChevronRight from '../assets/images/chevron-right';
import Lightbox from '../Lightbox/Lightbox';
import PhotoSlide from './carousel/PhotoSlide';
import VideoSlide from './carousel/VideoSlide';
import {
  ACCEPTED_FILES,
  UPLOADER_TYPE,
  ROTATE_LEFT,
  ROTATE_RIGHT
} from './constants';
import defaultImage from './default-profile-cover.png';
import PhotoThumbnail from './gallery/PhotoThumbnail';
import VideoThumbnail from './gallery/VideoThumbnail';
import styles from './MultipleMediaUpload.module.css';
import upload from './upload.png';
import { getMediaUrl } from './utils';
import 'react-slideshow-image/dist/styles.css';
import exifr from "exifr";

const SlideShow = memo(({ slideRef, handleSelect, slides = [], defaultIndex = 0 }) => {
  const isEmpty = useMemo(() => slides[0].key === 'default-image', [slides])
  const LeftArrow = () => {
    return (
      <div
        className={`${styles.LeftArrow} position-absolute`}
        onClick={() => slideRef.current.goBack()}
      >
        <ChevronLeft />
      </div>
    );
  };

  const RightArrow = () => {
    return (
      <div
        className={`${styles.RightArrow} position-absolute`}
        onClick={() => slideRef.current.goNext()}
      >
        <ChevronRight />
      </div>
    );
  };

  return (
    <div data-testid="MultipleMediaUploadSlides">
      <Slide
        prevArrow={!isEmpty ? <LeftArrow /> : <></>}
        nextArrow={!isEmpty ? <RightArrow /> : <></>}
        ref={slideRef}
        autoplay={false}
        onChange={handleSelect}
        transitionDuration={100}
        defaultIndex={defaultIndex}
      >
        {slides}
      </Slide>
    </div>
  );
});

const attachExifData = async (files) => {
  const filesWithExif = [...files];
  let index = 0;
  for (const file of filesWithExif) {
    try {
      const exifData = await exifr.parse(file, true);
      if ("PrimaryPlatform" in exifData && exifData.PrimaryPlatform.includes("Apple") && "JFIFVersion" in exifData) {
        filesWithExif[index].nativePlatform = "ios";
      } else {
        filesWithExif[index].nativePlatform = "android";
      }
      switch (exifData.Orientation) {
        case "Rotate 90 CW":
          filesWithExif[index].orientation = 90;
          break;
        case "Rotate 180":
          filesWithExif[index].orientation = 180;
          break;
        case "Rotate 270 CW":
          filesWithExif[index].orientation = 270;
          break;
        default:
          break;
      }
    } catch (error) {
      if (error.message === "Unknown file format") {
        continue;
      }
    } finally {
      index += 1;
    }
  }

  return filesWithExif;
}

const MultipleMediaUpload = ({
  className,
  hideButtons,
  isSaving,
  isDeleting,
  isRotating,
  mediaUrls,
  onDeleteMedia,
  onSelectedMediaFiles,
  readOnly,
  type,
  cachedMedia,
  canRotate = false,
  onRotateMedia = () => { }
}) => {
  const intl = useIntl();
  const t = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const [mediaCache, setMediaCache] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayAll, setDisplayAll] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const slideRef = useRef();
  const fullscreenSlideRef = useRef();
  const fileUploadRef = useRef(null);

  const totalMediaCount = mediaCache.length;
  const fileInputAccept = ACCEPTED_FILES[type];

  useEffect(() => {
    if (mediaCache?.length === 0) {
      setMediaCache(mediaUrls)
    }
  }, [mediaCache, mediaUrls]);

  const mainWrapperClasses = useMemo(() => classNames(
    styles.MediaUpload,
    {
      [styles.Loading]: isSaving || isDeleting || isRotating
    },
    className
  ), [className, isSaving, isDeleting, isRotating]);

  const buttonWrapperClasses = useMemo(() => classNames(
    {
      [styles.Loading]: isSaving || isDeleting || isRotating
    }
  ), [isSaving, isDeleting, isRotating]);

  const uploadButtonClasses = useMemo(() => classNames(
    styles.uploadButton,
    {
      [styles.Loading]: isSaving || isDeleting || isRotating
    }
  ), [isSaving, isDeleting, isRotating]);

  const galleryGridWrapperClasses = useMemo(() => classNames(
    {
      'd-flex': displayAll,
      'd-none': !displayAll
    },
    styles.displayAllContainer,
    'flex-wrap position-relative'
  ), [displayAll]);

  const galleryCarouselWrapperClasses = useMemo(() => classNames(
    {
      'd-none': displayAll
    },
    styles.imageCarousel,
    'position-relative image-carousel-custom'
  ), [displayAll]);

  const carouselSlideCounter = useMemo(() => {
    const currentSlideNumber = totalMediaCount > 0 ? currentIndex + 1 : currentIndex;
    return `${currentSlideNumber} / ${totalMediaCount}`;
  }, [currentIndex, totalMediaCount]);

  const deleteButtonEnabled = useMemo(() => (
    !readOnly && !isSaving && !isDeleting && !isRotating
  ), [readOnly, isSaving, isDeleting, isRotating]);

  const deleteButtonLabel = useMemo(() => {
    if (isDeleting) {
      return `${t('ui.deleting')}...`;
    }

    if (type === UPLOADER_TYPE.IMAGE) {
      return t('MultipleMediaUpload.deletePhoto');
    }

    if (type === UPLOADER_TYPE.VIDEO) {
      return t('MultipleMediaUpload.deleteVideo');
    }

    return t('MultipleMediaUpload.delete');
  }, [isDeleting, t, type]);

  const uploadButtonLabel = useMemo(() => {
    if (isSaving) {
      return `${t('ui.saving')}...`;
    }

    if (type === UPLOADER_TYPE.IMAGE) {
      return t('MultipleMediaUpload.uploadPhotos');
    }

    if (type === UPLOADER_TYPE.VIDEO) {
      return t('MultipleMediaUpload.uploadVideos');
    }

    return t('MultipleMediaUpload.upload');
  }, [isSaving, t, type]);

  const uploadButtonEnabled = useMemo(() => (
    !readOnly && !isSaving && !isDeleting && !isRotating
  ), [readOnly, isDeleting, isSaving, isRotating]);

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.goTo(currentIndex);
    }

    if (fullscreenSlideRef.current) {
      fullscreenSlideRef.current.goTo(currentIndex);
    }
  }, [currentIndex]);

  const handleSlideSelect = (prevIndex, currIndex) => {
    setCurrentIndex(currIndex);
  };

  const handleFilePickerChange = ({ target }) => {
    // FileList iterable to standard array
    const files = Array.from(target.files);

    // Includes external URLs for already uploaded files + internal file blobs for
    // files that have just been selected and are not uploaded yet.
    const temporaryMediaCache = [...mediaCache, ...files];
    setMediaCache(temporaryMediaCache);

    // Move the slider to the last item in the URL array
    setCurrentIndex(temporaryMediaCache.length - 1);

    // Add orientation information to the files.
    attachExifData(files).then(onSelectedMediaFiles);

    // Reset HTML element
    target.value = '';
  };

  const onDelete = useCallback(
    (deleteIndex) => {
      if (!mediaCache.length) {
        return;
      }

      // Splice removes and returns the removed element,
      // and also mutates the original array
      const [targetMedia] = mediaCache.splice(deleteIndex, 1);
      const targetMediaUrl = getMediaUrl(targetMedia);
      const newMediaCacheLength = mediaCache.length;
      const maxIndex = newMediaCacheLength - 1;
      // If we remove the last item, we should adjust for the smaller array
      const newIndex = deleteIndex > maxIndex ? maxIndex : deleteIndex;

      setCurrentIndex(newIndex);
      setMediaCache(mediaCache);
      onDeleteMedia(targetMediaUrl, deleteIndex);
    },
    [mediaCache, onDeleteMedia]
  );

  const openFullscreenView = () => setShowImageModal(true);
  const closeFullscreenView = () => setShowImageModal(false);

  /**
   * Overrides the default z-index value of the imagelightbox to
   * surpass the `!important` z-index value that was thrown to some other
   * components sharing the same DOM tree. Will not tackle specificity,
   * so if any new component gets rendered on top of the lightbox again,
   * this value should be increased.
   */
  const lightboxZIndex = 10001

  const mediaSlides = useMemo(() => {
    if (totalMediaCount === 0) {
      return [<PhotoSlide url={defaultImage} contain={false} key="default-image" />]
    }

    return mediaCache.map((media, index) => {
      const url = getMediaUrl(media);
      const key = `${url}-${index}`;
      let slideElement = null;

      if (type === UPLOADER_TYPE.IMAGE) {
        slideElement = <PhotoSlide url={url} key={key} contain={true} onClick={openFullscreenView} />;
      }

      if (type === UPLOADER_TYPE.VIDEO) {
        slideElement = <VideoSlide url={url} key={key} />;
      }

      return slideElement;
    });
  }, [mediaCache, totalMediaCount, type]);

  const mediaThumbnails = useMemo(() => mediaCache.map((media, index) => {
    const url = getMediaUrl(media);
    const key = `${url}-${index}`;
    const onClick = () => {
      slideRef.current.goTo(index);
      setDisplayAll(false);
    };
    const onRemoveButtonClick = () => onDelete(index);
    const selected = index === currentIndex;
    const showRemoveButton = !readOnly;

    const thumbnailProps = {
      key,
      onClick,
      onRemoveButtonClick,
      selected,
      showRemoveButton,
      url,
      media
    };

    let thumbnailElement = null;

    if (type === UPLOADER_TYPE.IMAGE) {
      thumbnailElement = <PhotoThumbnail {...thumbnailProps} />;
    }

    if (type === UPLOADER_TYPE.VIDEO) {
      thumbnailElement = <VideoThumbnail {...thumbnailProps} />;
    }

    return thumbnailElement;
  }), [currentIndex, mediaCache, onDelete, readOnly, type]);

  const lightboxPrevIndex = useMemo(() => (
    (currentIndex + totalMediaCount - 1) % totalMediaCount
  ), [currentIndex, totalMediaCount]);

  const lightboxNextIndex = useMemo(() => (
    (currentIndex + 1) % totalMediaCount
  ), [currentIndex, totalMediaCount]);

  const [mainLightboxSrc, nextLightboxSrc, prevLightboxSrc] = useMemo(() => {
    const currentMedia = mediaCache[currentIndex];
    const nextMedia = mediaCache[lightboxNextIndex];
    const prevMedia = mediaCache[lightboxPrevIndex];

    return [
      getMediaUrl(currentMedia),
      getMediaUrl(nextMedia),
      getMediaUrl(prevMedia)
    ];
  }, [mediaCache, currentIndex, lightboxPrevIndex, lightboxNextIndex]);

  const handleLightboxClose = closeFullscreenView;
  const handleLightboxPrev = () => setCurrentIndex(lightboxPrevIndex);
  const handleLightboxNext = () => setCurrentIndex(lightboxNextIndex);

  const rotateLeftClicked = useCallback(() => {
    const currentCachedFile = mediaCache[currentIndex]
    const targetMedia = currentCachedFile instanceof File ? cachedMedia[currentIndex] : currentCachedFile
    const [targetMediaUrl] = getMediaUrl(targetMedia).split('?');
    onRotateMedia(targetMediaUrl, ROTATE_LEFT, (updatedMedia) => {
      if (updatedMedia) {
        setMediaCache(updatedMedia)
      }
    })
  }, [cachedMedia, mediaCache, onRotateMedia, currentIndex])

  const rotateRightClicked = useCallback(() => {
    const currentCachedFile = mediaCache[currentIndex]
    const targetMedia = currentCachedFile instanceof File ? cachedMedia[currentIndex] : currentCachedFile
    const [targetMediaUrl] = getMediaUrl(targetMedia).split('?');
    onRotateMedia(targetMediaUrl, ROTATE_RIGHT, (updatedMedia) => {
      if (updatedMedia) {
        setMediaCache(updatedMedia)
      }
    })
  }, [cachedMedia, mediaCache, onRotateMedia, currentIndex])

  return (
    <>
      <div className={mainWrapperClasses}>
        {/* Grid view */}
        <div className={galleryGridWrapperClasses}>
          {totalMediaCount > 0 && <><CarouselIcon
            className={`position-absolute ${styles.displayAllButton}`}
            onClick={() => setDisplayAll(false)}
          />
            <div
              className={`position-absolute ${styles.displayAllBackground}`}
              onClick={() => setDisplayAll(false)}
            /></>}
          <div className={`${styles.displayAllWrapper}`}>
            <div className={`d-flex flex-wrap`}>
              {mediaThumbnails}
            </div>
          </div>
        </div>
        {/* Carousel view */}
        <div className={galleryCarouselWrapperClasses}>
          {totalMediaCount > 0 && <><GridIcon
            className={`position-absolute ${styles.displayAllButton}`}
            onClick={() => setDisplayAll(true)}
          />
            <div
              className={`position-absolute ${styles.displayAllBackground}`}
              onClick={() => setDisplayAll(true)}
            /></>}
          {canRotate && type === UPLOADER_TYPE.IMAGE && <div className={`position-absolute ${styles.rotateButtons}`}>
            <button disabled={isRotating} className={styles.rotateButton} onClick={rotateLeftClicked}>
              <RotateLeftIcon size={24} />
            </button>
            <button disabled={isRotating} className={styles.rotateButton} onClick={rotateRightClicked}>
              <RotateRightIcon size={24} />
            </button>
          </div>}
          <div className={`position-absolute ${styles.displayIndex}`}>
            <div>
              <div>{carouselSlideCounter}</div>
            </div>
          </div>
          <SlideShow
            slides={mediaSlides}
            slideRef={slideRef}
            handleSelect={handleSlideSelect}
          />
        </div>
        {/* Action buttons */}
        {!hideButtons && (
          <div className={buttonWrapperClasses}>
            {/* Delete button only if there's something to delete */}
            {totalMediaCount > 0 && (
              <Button
                disabled={!deleteButtonEnabled}
                variant="danger"
                size="lg"
                onClick={() => onDelete(currentIndex)}
                block
                className={uploadButtonClasses}
              >{deleteButtonLabel}</Button>
            )}
            <Button
              disabled={!uploadButtonEnabled}
              variant="secondary"
              size="lg"
              onClick={() => fileUploadRef.current.click()}
              block
              className={uploadButtonClasses}
            >
              <Image
                src={upload}
                rounded
                style={{ marginRight: '.5rem' }}
                alt={uploadButtonLabel}
              />
              {uploadButtonLabel}
            </Button>
            <input
              data-testid="MultipleMediaUploadInput"
              type="file"
              ref={fileUploadRef}
              className={styles['form-group']}
              onChange={handleFilePickerChange}
              multiple
              style={{ display: 'none' }}
              accept={fileInputAccept}
            />
          </div>
        )}
      </div>
      {showImageModal && (
        <Lightbox
          mainSrc={mainLightboxSrc}
          nextSrc={nextLightboxSrc}
          prevSrc={prevLightboxSrc}
          onCloseRequest={handleLightboxClose}
          onMovePrevRequest={handleLightboxPrev}
          onMoveNextRequest={handleLightboxNext}
          zIndex={lightboxZIndex}
        />
      )}
    </>
  );
};

MultipleMediaUpload.propTypes = {
  /** Classes for the wrapper element. */
  className: PropTypes.string,
  /** Flag to show/hide the Upload/Delete buttons. */
  hideButtons: PropTypes.bool,
  /** Flag that signals if the files sent via `onDeleteMedia` are being removed. */
  isDeleting: PropTypes.bool,
  /** Flag that signals if the files sent via `onSelectedMediaFile` are being uploaded */
  isSaving: PropTypes.bool,
  /** Array of image or video URLs. */
  mediaUrls: PropTypes.arrayOf(PropTypes.string),
  /** To be called when a file is deleted. Receives a URL that was previously passed via `mediaUrls`. */
  onDeleteMedia: PropTypes.func.isRequired,
  /** To be called when files have been selected from the filesystem. Receives an array of `File` instances. */
  onSelectedMediaFiles: PropTypes.func.isRequired,
  /** Controls the read-only state of the component. */
  readOnly: PropTypes.bool,
  /** Uploader type (Video or Image) */
  type: PropTypes.oneOf([UPLOADER_TYPE.IMAGE, UPLOADER_TYPE.VIDEO]).isRequired,
  /** Array of cached media objects that were previously uploaded, or empty array and does not contain File object */
  cachedMedia: PropTypes.arrayOf(PropTypes.object),
};

MultipleMediaUpload.defaultProps = {
  hideButtons: false,
  isDeleting: false,
  isSaving: false,
  mediaUrls: [],
  readOnly: false
};

export default MultipleMediaUpload;
